<template>
    <div id="all">
        <dv-full-screen-container class="bg">
            <div  class="host-body">
                        <div class="title">
                            <span class="title-text">兵团设计院EPC项目管理平台</span>
                        </div>
            </div>
            <div  class="host-left">
                <div class="left-content">
                    <dv-border-box-12 class="lccenter">
                        <div class="d-flex pt-2 pl-2">
                            <div class="d-flex lctitle" >
                                <img src='../assets/img/tj.png' class="lcimg" >
                                <span class="fs-xl text mx-2 lcspan" >项目整体进度</span>
                                <!--<dv-decoration-3 class="lcdiv" />-->
                            </div>
                        </div>
                        <dv-scroll-ranking-board :config="chuqinData" style="width:460px;height:250px;margin-left: 20px;color: black" />
                        <!--<div style="" >-->
                            <!--<ec1/>-->
                        <!--</div>-->
                    </dv-border-box-12>
                </div>
                <div class="left-content2">
                    <dv-border-box-12 class="lccenter">
                        <div class="d-flex pt-2 pl-2">
                            <div class="d-flex lctitle" >
                                <img src='../assets/img/tj.png'class="lcimg" >
                                <span class="fs-xl text mx-2 lcspan"  >项目财务情况</span>
                              <!--  <dv-decoration-3 class="lcdiv"/>-->
                            </div>
                        </div>
                        <div style="" >
                        <ecchanzhi/>
                    </div>
                    </dv-border-box-12>
                </div>
                <div class="left-content3">
                    <dv-border-box-12 class="lccenter">
                        <div class="d-flex pt-2 pl-2">
                            <div class="d-flex lctitle" >
                                <img src='../assets/img/tj.png' class="lcimg" >
                                <span class="fs-xl text mx-2 lcspan" >项目安全问题</span>
                              <!--  <dv-decoration-3 class="lcdiv"  />-->
                            </div>
                        </div>
                        <div style="" >
                            <ecanquan/>

                        </div>
                    </dv-border-box-12>
                </div>
            </div>
            <div class="host-center">
             <!--   <dv-border-box-8></dv-border-box-8>-->
                <dv-border-box-12 ></dv-border-box-12>
            </div>
            <div class="host-right">
                <!--<dv-scroll-board :config="config" class="rightlist" style="" />-->
                <dv-border-box-11 :title="'EPC项目列表（'+count+'）'" :color="['#1E50AE', '#1E50AE']">
                    <div class="proall">
                    <div class="prolist">
                        <div  class="d-flex pro" @click="proInfo(item.id)" v-for="item in  proData" :key="item.id">
                        <div class="propic">
                            <img src="../assets/img/pro.jpg" >
                        </div>
                        <div class="protext">
                            <div class="prospan"> <img v-if="item.tianshu=='还未开始'" src="../assets/img/marker-cheng.png" class="promarker" @click.stop="dingwei(item)" >
                                <img v-else-if="item.tianshu=='已完成'" src="../assets/img/marker-ccc.png" class="promarker" @click.stop="dingwei(item)" >
                                <img v-else-if="item.tianshu=='已超期'" src="../assets/img/marker.png" class="promarker" @click.stop="dingwei(item)" >
                                <img v-else src="../assets/img/marker-green.png" class="promarker" @click.stop="dingwei(item)" >
                                <span :title=item.projectName>{{item.projectName}}（EPC）</span></div>
                            <div class="proaddress"><span> {{item.projectAddress}}</span></div>
                            <div class="prorukou"> <span> {{item.projectType.typeName}} </span> <div><img src="../assets/img/ru.png"> </div> </div>
                        </div>
                        </div>
                    </div>
                    </div>
                </dv-border-box-11>
            </div>
            <div class="host-bottom">
                    <dv-border-box-9>
                        <div class="d-flex" style="justify-content: space-between;">
                        <div class="bottomDiv">
                            <div class="bcci-header">质量问题</div>
                            <dv-active-ring-chart :config="config1" style="width:280px;height:250px;color: black" />
                        </div>
                            <dv-decoration-2 :reverse="true" style="width:5px;height:250px;" />
                        <div class="bottomDiv">
                            <div class="bcci-header">安全问题</div>
                            <dv-active-ring-chart :config="config1" style="width:280px;height:250px;color: black" />
                        </div>
                            <dv-decoration-2 :reverse="true" style="width:5px;height:250px;" />
                        <div class="bottomDiv2">
                            <div class="bcci-header">今日出勤率</div>
                            <dv-water-level-pond :config="config3" style="width:175px;height:175px;border: 10px solid #19c3eb;    border-radius: 50%;max-width: 90%;margin-left: 40px " />
                        </div>
                            <dv-decoration-2 :reverse="true" style="width:5px;height:250px;" />
                            <div class="bottomDiv">
                                <div class="bcci-header">项目支出</div>
                                <dv-charts :option="option1"  />
                            </div>
                            <dv-decoration-2 :reverse="true" style="width:5px;height:250px;" />
                            <div class="bottomDiv" style="width: 400px">
                                <div class="bcci-header">项目类型占比</div>
                                <dv-charts :option="option3"  />
                            </div>
                        <!--    <div id="cards">
                                <div
                                        class="card-item"
                                        v-for="(card, i) in cards"
                                        :key="card.title"
                                >
                                    <div class="card-header">
                                        <div class="card-header-left">{{ card.title }}</div>
                                        <div class="card-header-right">{{ '0' + (i + 1) }}</div>
                                    </div>
                                    <dv-charts class="ring-charts" :option="card.ring" />
                                    <div class="card-footer">
                                        <div class="card-footer-item">
                                            <div class="footer-title">累计金额</div>
                                            <div class="footer-detail">
                                                <dv-digital-flop :config="card.total" style="width:70%;height:35px;" />元
                                            </div>
                                        </div>
                                        <div class="card-footer-item">
                                            <div class="footer-title">巡查病害</div>
                                            <div class="footer-detail">
                                                <dv-digital-flop :config="card.num" style="width:70%;height:35px;" />处
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>-->
                        </div>

                    </dv-border-box-9>
            </div>
        </dv-full-screen-container>
        <div id="popup2" class="ol-popup"  v-show="popupshow" >
            <a href="#" @click="closePop" class="popx">x</a>
            <div class="media">
                <div class="media-body">
                    <table class="poptable">
                        <tbody>
                        <tr>
                            <th width="28%">项目名称</th>
                            <td colspan="2">{{pro.projectName}}</td>
                        </tr>
                        <tr>
                            <th>项目类别</th>
                            <td colspan="2">{{pro.projectType.typeName}}</td>

                        </tr>
                        <tr>
                            <th>项目地址</th>
                            <td colspan="2">{{pro.projectAddress}}</td>
                        </tr>
                        <tr>
                            <th>计划结束时间</th>
                            <td colspan="2">{{pro.planEndDate}}</td>
                        </tr>
                        <tr>
                            <th>项目照片</th>
                            <td colspan="2">   <el-image
                                    style="width: 100px; height: 100px"
                                    :src="pro.picmapurl"
                                    :preview-src-list="pro.srcmapList">
                            </el-image></td>

                        </tr>

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
        <div id="map" style="">

        </div>
        <div class="mapTongji">
            <div class="zidiv ">
                <div class="img_div" style="background-color: #1e50ae">
                    <img src="../assets/img/icon/icon_1.png">
                </div>
                <div class="text_div">
                    <p> 项目总数</p>
                    <span>1个</span>
                </div>
            </div>

            <div class="zidiv ">
                <div class="img_div" style="background-color: #1e50ae">
                    <img src="../assets/img/icon/icon-3.png" style="width: 55px;height: 55px;margin-top: 10px;margin-left: 12px">
                </div>
                <div class="text_div">
                    <p> 建成项目</p>
                    <span>0个</span>
                </div>
            </div>
            <div class="zidiv ">
                <div class="img_div" style="background-color: #1e50ae">
                    <img src="../assets/img/icon/icon_4.png">
                </div>
                <div class="text_div">
                    <p> 在建项目</p>
                    <span>1个</span>
                </div>
            </div>
            <div class="zidiv ">
                <div class="img_div" style="background-color: #1e50ae">
                    <img src="../assets/img/icon/icon_2.png">
                </div>
                <div class="text_div">
                    <p> 项目投资</p>
                    <span>2亿</span>
                </div>
            </div>
        </div>

        <el-button type="primary" style="position: absolute;bottom: 300px;left: 533px;z-index: 99999999" v-show="tlbtnshow" @click="tuliShow" size="small">显示图例</el-button>
        <div class="tuli" v-show="!tlbtnshow">
            <a href="#" @click="closeTuli" class="popx" style="color: #0BFADA">x</a>
           <h2 style="text-align: center;color: white">图例</h2>
            <div style="margin-top: 10px">
                <img src="../assets/img/marker-green.png" style="vertical-align: middle;height: 25px">
                <span style="font-size: 16px;margin-left: 10px"> 正在进行中</span>
            </div>
            <div style="margin-top: 10px">
                <img src="../assets/img/marker-ccc.png" style="vertical-align: middle;height: 25px">
                <span style="font-size: 16px;margin-left: 10px"> 已完成项目</span>
            </div>
            <div  style="margin-top: 10px">
                <img src="../assets/img/marker-cheng.png" style="vertical-align: middle;height: 25px">
                <span style="font-size: 16px;margin-left: 10px"> 未开始项目</span>
            </div>
            <div  style="margin-top: 10px">
                <img src="../assets/img/marker.png" style="vertical-align: middle;height: 25px">
                <span style="font-size: 16px;margin-left: 10px"> 已超期项目</span>
            </div>
        </div>
        <i class="el-icon-s-tools" @click="goxitong"></i>
    </div>
</template>

<script>
    import "../assets/css/ol1.css";
    import "../assets/css/ol2.css";
    import Map from 'ol/Map';
    import View from 'ol/View';
    import TileLayer from 'ol/layer/Tile'
    import * as control from 'ol/control';
    import * as olProj from 'ol/proj';
    import * as olExtent from 'ol/extent';
    import WMTS from 'ol/source/WMTS';
    import WMTSTileGrid from 'ol/tilegrid/WMTS';
    import VectorSource from 'ol/source/Vector';
    import VectorLayer from 'ol/layer/Vector';
    import Style from 'ol/style/Style';
    import GeoJSON from 'ol/format/GeoJSON';
    import Stroke from 'ol/style/Stroke';
    import Text from 'ol/style/Text';
    import Fill from 'ol/style/Fill';
    import Icon from 'ol/style/Icon';
    import Point  from 'ol/geom/Point';
    import Feature from 'ol/Feature';
    import Overlay from 'ol/Overlay';
    import {fromExtent} from 'ol/geom/Polygon';
    import LinearRing  from 'ol/geom/LinearRing';
    import ecanquan from "./echarts/ecanquan";
    import ecchanzhi from "./echarts/ecchanzhi";

    var map,pop1;
    var gjSource= new VectorSource();
    var gjLayer = new VectorLayer({
        source: gjSource,
        style: new Style({
            image: new  Icon(({
                src: require('../assets/img/marker-cheng.png')
            }))
        })
    });
    var createguojiaStyle = function(feature) {
        return new  Style({
            text: new Text({
                //位置
                textAlign: 'center',
                //基准线
                textBaseline: 'middle',
                //文字样式
                font: 'normal 12px 微软雅黑',
                //文本内容
                text: feature.get('name'),
                backgroundStroke:new Stroke({
                    color: '#1e50ae',
                    width: 2
                }),
                offsetY:25,
                backgroundFill:new Fill({
                    color: '#1e50ae'
                }),
                padding: [1, 3, 0, 3],
                //文本填充样式（即文字颜色）
                fill:new Fill({
                    color: 'white'
                }),
            }),
            image: new  Icon(({
                src: require('../assets/img/marker-cheng.png')
            }))
        });
    };
    var mystyle = new Style({
        fill: new Fill({
            color:"rgba(255,255,255, 0.9)",
        }),
        stroke: new Stroke({
            color:"#BDBDBD",
            width:2
        })
    });
    export default {
        name: "homepage",
        data(){
            return{
                chuqinData:{
                    data: [
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 55
                        },
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 100
                        },
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 78
                        },
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 66
                        },
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 80
                        },
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 45
                        },
                        {
                            name: '图木舒克市第三师图木舒克达坂山工业园区',
                            value: 29
                        }
                    ],
                    unit:'%'
                },
                config1:{
                    radius: '65%',
                    activeRadius: '70%',
                    digitalFlopStyle: {
                        fill: '#000'

                    },
                    data:[{
                        name: '钢材',
                        value: 55
                     },
                        {
                            name: '技术',
                            value: 120
                        },
                        {
                            name: '水泥',
                            value: 78
                        },
                        {
                            name: '软件',
                            value: 66
                        },
                        {
                            name: '人工',
                            value: 80
                        }]
                },
                config3:{
                    data: [65],
                    shape: 'round'
                },
                option1:{
                    radar: {
                        indicator: [
                            { name: '钢材', max: 300 },
                            { name: '水泥', max: 300 },
                            { name: '玻璃', max: 300 },
                            { name: '板砖', max: 300 },
                            { name: '叉车', max: 300 },
                            { name: '人工', max: 300 }
                        ],

                        name:{
                            textStyle: {
                                color: 'black' // 文字颜色
                            }
                        },
                        axisLabel:{
                            color: ['black'] // 文字颜色
                        },
                        polygon: true,
                        axisLine: {
                            style: {
                                stroke: 'rgba(251, 114, 147, .3)'
                            }
                        },
                        splitLine: {
                            style: {
                                stroke: 'rgba(251, 114, 147, .5)',
                                fill: 'rgba(251, 114, 147, .2)'
                            }
                        }
                    },
                    series: [
                        {
                            type: 'radar',
                            data: [111, 256, 178, 152, 266, 132]
                        }
                    ]
                },
                option2: {
                    radar: {
                        indicator: [
                            { name: '用电', max: 300 },
                            { name: '交通', max: 300 },
                            { name: '食品', max: 300 },
                            { name: '施工', max: 300 },
                            { name: '生产', max: 300 },
                            { name: '建筑', max: 300 }
                        ],
                        name:{
                            textStyle: {
                                color: 'black' // 文字颜色
                            }
                        },
                        axisLabel:{
                            color: 'black' // 文字颜色
                        },
                        polygon: true,
                        axisLine: {
                            style: {
                                stroke: 'rgba(230, 114, 209, .3)'
                            }
                        },
                        splitLine: {
                            style: {
                                stroke: 'rgba(230, 114, 209, .5)',
                                fill: 'rgba(230, 114, 209, .2)'
                            }
                        }
                    },
                    series: [
                        {
                            type: 'radar',
                            data: [223, 189, 214, 265, 178, 155]
                        }
                    ]
                },
                option3:{
              /*  legend: {
                    data: ['同比', '环比'],
                        orient: 'vertical'
                },*/
                series: [
                    {
                        name: '同比',
                        type: 'pie',
                        data: [
                            { name: '园区', value: 93 },
                            { name: '设计', value: 93 },
                            { name: '水利', value: 32 },
                            { name: '电力', value: 65 },
                            { name: '公路', value: 44 },
                            { name: '其它', value: 52 },
                        ],
                        radius: '30%',
                        outsideLabel: {
                            show: false
                        },
                        insideLabel: {
                            show: true
                        }
                    },
                    {
                        name: '环比',
                        type: 'pie',
                        data: [
                            { name: '园区', value: 93 },
                            { name: '设计', value: 93 },
                            { name: '水利', value: 32 },
                            { name: '电力', value: 65 },
                            { name: '公路', value: 44 },
                            { name: '其它', value: 52 },
                        ],
                        radius: ['40%', '50%']
                    }
                ]
                },
                cards: [],
                proData:[{id:1,projectName:'图木舒克市农副产品精深加工标准化厂房项目（EPC）',address:'新疆维吾尔自治区自治区直辖县级行政区划图木舒克市第三师图木舒克达坂山工业园区',"projectType": {
                        "id": 1,"code": "222", "typeName": "园区" },longitude:'79.071925',latitude:'39.867719444',time:28}],
                count:1,
                popupshow:false,
                pro:{
                    projectName:'',
                    projectType:{
                        typeName:''
                    },
                    projectAddress:'',
                    planEndDate:'',
                    picmapurl:'',
                    srcmapList:[],
                },
                tlbtnshow:true,
            }
        },
        mounted(){
            this.initMap();
            const { createData } = this
            createData()
        },
        components: {
            ecanquan,
            ecchanzhi
        },
        created(){
            this.getProInfo();
            this.proMap();
        },
        methods:{
           async initMap(){
                map = new Map({
                    target: 'map',
                    view: new View({
                        center: [100.28838135,38.209817352],
                        zoom: 4,
                        projection: 'EPSG:4326'
                    }),
                });
                var projection = olProj.get('EPSG:4326');
                var projectionExtent = projection.getExtent();
                var size = olExtent.getWidth(projectionExtent) / 256;
                var resolutions = new Array(19);
                var matrixIds = new Array(19);
                for(var z = 0; z < 19; z++) {
                    // generate resolutions and matrixIds arrays for this WMTS
                    //resolutions[z] = size / Math.pow(2, z);
                    matrixIds[z] = z;
                }
                resolutions = [1.40625, 0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125, 0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125, 0.001373291015625, 0.0006866455078125, 0.00034332275390625, 0.000171661376953125,
                    0.0000858306884765625, 0.00004291534423828125, 0.000021457672119140625, 0.000010728836059570312, 0.000005364418029785156, 0.000002682209014892578, 0.000001341104507446289
                ];
                var webKey = "b0f80d188ee3100e0327398d8afb39cd";
                var tdtsldtLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/vec_c/wmts?tk=" + webKey,
                        layer: 'vec',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtslzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cva_c/wmts?tk=" + webKey,
                        layer: 'cva',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxzjLayer =new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/cia_c/wmts?tk=" + webKey,
                        layer: 'cia',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                var tdtyxLayer = new TileLayer({
                    opacity: 1,
                    source: new WMTS({
                        url: "http://t0.tianditu.gov.cn/img_c/wmts?tk=" + webKey,
                        layer: 'img',
                        matrixSet: 'c',
                        format: 'tiles',
                        style: 'default',
                        projection: projection,
                        tileGrid: new WMTSTileGrid({
                            origin: olExtent.getTopLeft(projectionExtent),
                            extent: [-180.0, -90.0, 180.0, 90.0],
                            resolutions: resolutions,
                            matrixIds: matrixIds
                        }),
                        wrapX: false
                    })
                });
                map.addLayer(tdtsldtLayer)
               map.addLayer(tdtslzjLayer)
               //  map.addLayer(tdtyxLayer);
               //map.addLayer(tdtyxzjLayer);
                var converLayer = new VectorLayer({
                    source: new VectorSource(),
                    style: mystyle
                });
              //  map.addLayer(converLayer)
                const {data:result_xj}= await this.$httpaxios.get('./china_R.json')
                var fts = new GeoJSON().readFeatures(result_xj);

                var ft = fts[0];
                var converGeom = this.erase(ft.getGeometry());
                var convertFt = new Feature({
                    geometry: converGeom
                })
                converLayer.getSource().addFeature(convertFt);
                var xjSource = new VectorSource({
                    url: 'http://localhost:8086/china_R.json',
                    format: new GeoJSON(),
                    wrapX: false
                });
                var xjLayer = new VectorLayer({
                    source: xjSource,
                    style: new Style({
                        stroke: new Stroke({
                            //color: '#0033ff',
                            color: 'rgb(0, 186 ,255)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(0, 186 ,255, 0.01)'
                        })
                    })
                });
                map.addLayer(xjLayer)
                map.addLayer(gjLayer);
                pop1 = new Overlay({
                    element: document.getElementById('popup2'),
                    autoPan: true,
                    autoPanAnimation: {
                        duration: 250
                    },
                    offset: [0, -20]
                });
                map.on('click',evt=> {
                    var coordinate = evt.coordinate;
                        map.forEachFeatureAtPixel(evt.pixel, (cxfeature, layerVetor)=> {
                            if(layerVetor == gjLayer) {
                                this.pro.projectName=cxfeature.values_.projectName;
                                this.pro.projectType.typeName=cxfeature.values_.typeName;
                                this.pro.projectAddress=cxfeature.values_.projectAddress;
                                this.pro.planEndDate=cxfeature.values_.planEndDate;
                                this.pro.picmapurl=cxfeature.values_.picmapurl;
                                this.pro.srcmapList=cxfeature.values_.srcmapList;
                                this.popupshow=true;
                                map.addOverlay(pop1);
                                pop1.setPosition(coordinate);
                                map.getView().setCenter(coordinate)
                            }
                        })
               })
            },
            erase(geom) {
                var extent = [-180,-90,180,90];
                var polygonRing =new fromExtent(extent);
                var coords = geom.getCoordinates();
                for(let i in coords){
                    var linearRing = new LinearRing(coords[i][0]);
                    polygonRing.appendLinearRing(linearRing);
                }
                console.log(polygonRing)
                return polygonRing;
            },
            dingwei(item){
                this.pro.projectName=item.projectName;
                this.pro.projectType.typeName=item.projectType.typeName;
                this.pro.projectAddress=item.projectAddress;
                this.pro.planEndDate=item.planEndDate;
                this.pro.picmapurl=item.picmapurl;
                this.pro.srcmapList=item.srcmapList;
                this.popupshow=true;
                map.addOverlay(pop1);
                pop1.setPosition([Number(item.longitude),Number(item.latitude)]);
                map.getView().setCenter([Number(item.longitude),Number(item.latitude)])
                map.getView().setZoom(6)
            },
            closePop(){
                this.popupshow=false;
            },
            createData () {
                const { randomExtend } = this
            this.cards = new Array(1).fill(0).map((foo, i) => ({
                title: '测试路段' + (i + i),
                total: {
                    number: [randomExtend(9000, 10000)],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#ea6027',
                        fontWeight: 'bold'
                    }
                },
                num: {
                    number: [randomExtend(30, 60)],
                    content: '{nt}',
                    textAlign: 'right',
                    style: {
                        fill: '#26fcd8',
                        fontWeight: 'bold'
                    }
                },
                ring: {
                    series: [
                        {
                            type: 'gauge',
                            startAngle: -Math.PI / 2,
                            endAngle: Math.PI * 1.5,
                            arcLineWidth: 13,
                            radius: '80%',
                            data: [
                                { name: '资金占比', value: randomExtend(40, 60) }
                            ],
                            axisLabel: {
                                show: false
                            },
                            axisTick: {
                                show: false
                            },
                            pointer: {
                                show: false
                            },
                            backgroundArc: {
                                style: {
                                    stroke: '#224590'
                                }
                            },
                            details: {
                                show: true,
                                formatter: '资金占比{value}%',
                                style: {
                                    fill: '#1ed3e5',
                                    fontSize: 20
                                }
                            }
                        }
                    ],
                    color: ['#03d3ec']
                }
            }))
        },
            randomExtend (minNum, maxNum) {
              if (arguments.length === 1) {
                return parseInt(Math.random() * minNum + 1, 10)
              } else {
                return parseInt(Math.random() * (maxNum - minNum + 1) + minNum, 10)
              }
             },
            async  proMap(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAll')
                this.proData=res.data;
                 var tianshu;
               for(let i in this.proData){
                   if(this.proData[i].actualEndDate){
                       tianshu='已完成'
                   }else {
                       if(new Date(this.proData[i].planStartDate.replace('-','/'))-new Date()>0){
                           tianshu='未开始'
                       }else {
                           var time=((new Date(this.proData[i].planEndDate.replace('-','/'))-new Date())/(1*24*60*60*1000)).toFixed(0);
                           if(time<0){
                               tianshu='已超期'+time+'天'
                           }else {
                               tianshu='计划还剩'+time+'天'
                           }
                       }
                   }
                   var gjFeature = new Feature({
                       geometry: new Point([Number(this.proData[i].longitude),Number(this.proData[i].latitude)]), //几何信息
                       name:this.proData[i].projectName+'--'+tianshu,
                       projectName:this.proData[i].projectName,
                       typeName:this.proData[i].projectType.typeName,
                       projectAddress:this.proData[i].projectAddress,
                       planEndDate:this.proData[i].planEndDate,
                       picmapurl:this.proData[i].projectImagePath.split(';')[0],
                       srcmapList:this.proData[i].projectImagePath.split(';')
                   });
                   gjFeature.setStyle(createguojiaStyle(gjFeature));
                   gjSource.addFeature(gjFeature)
               }
            },
            async getProInfo(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAll')
                if(res.status!='3000'){
                    return this.$message.error('查询EPC项目失败！'+res.message)
                }
                this.proData=res.data;
                for(let i in this.proData){
                    if(this.proData[i].actualEndDate){
                        this.proData[i].tianshu='已完成'
                    }else {
                        if(new Date(this.proData[i].planStartDate.replace('-','/'))-new Date()>0){
                            this.proData[i].tianshu='未开始'
                        }else {
                            var time=((new Date(this.proData[i].planEndDate.replace('-','/'))-new Date())/(1*24*60*60*1000)).toFixed(0);
                            if(time<0){
                                this.proData[i].tianshu='已超期'
                            }else {
                                this.proData[i].tianshu='正在进行中'
                            }
                        }
                    }}
            },
            proInfo(id){
                window.sessionStorage.setItem("current",1)
                window.sessionStorage.setItem('proId',id)
                this.$root.proId=id
                this.$router.push({ path: "/prohome" });
            },
            goxitong(){
                this.$router.push({ path: "/system" });
            },
            closeTuli(){
                this.tlbtnshow=true
            },
            tuliShow(){
                this.tlbtnshow=false
            }
        }
    }
</script>

<style scoped lang="less">
#all{
    width: 100%;height:100vh;
    .bg{
        background-color: white;
        .host-body{
                .title{
                    height: 35px;
                    position: relative;
                    width:100%;
                    text-align: center;
                    margin-top:10px;
                    background-size: cover;
                    background-repeat: no-repeat;
                    .title-text {
                        font-size: 34px;
                        position: absolute;
                        bottom: 0;
                        color: black;
                        font-family: SourceHanSansCN-Bold;
                        font-weight: bold;
                        letter-spacing: 2px;
                        transform: translate(-50%);
                    }
                    .title-bototm {
                        position: absolute;
                        bottom: -10px;
                        left: 50%;
                        transform: translate(-50%);
                        width:300px;
                        height:10px;
                    }
                }
        }
        .host-left{
            margin-top: -10px;
            height: 920px;
            width: 510px;
            float: left;
            .left-top{
                width: 100%;
                height: 20px;
                text-align: center;
                span{
                    font-size: 18px;
                }
            }
            .left-content{
                width: 100%;
                height: 300px;
                text-align: center;

            }
            .left-content2{
                width: 100%;
                height: 300px;
                text-align: center;
            }
            .left-content3{
                width: 100%;
                height: 300px;
                text-align: center;
            }
            .lccenter{
                height: 300px
            }
            .lctitle{
                margin-top: 8px;margin-left: 10px
            }
            .lcimg{
                vertical-align: middle;width: 30px;height: 28px
            }
            .lcspan{
                color: black;width: 100px;vertical-align: middle;margin-top: 5px;font-size: 15px;
            }
            .lcdiv{
                width:200px;height:30px; position:relative;top:0.5px;
            }
        }
        .host-center{
            height: 590px;
            width: 875px;
            margin-left: 14px;
            margin-top: 8px;
            float: left;
        }
        .host-right{
            margin-top: -25px;
            height:630px;
            overflow: hidden;
            width: 510px;
            float: right;
            .proall{
                padding-top: 20px;
            }
            .prolist{
                height: 590px;
                max-height:590px;
                overflow:auto;
                margin-top: 20px;
            }
            .pro{
                cursor: pointer;
                margin-top: 20px;
            }
            .propic{
                margin-left: 20px;
                width: 100px;height: 100px;
                img{
                    width: 100%;
                    height: 100%;
                }
            }
            .protext{
                margin-left: 15px;
                max-width: 350px;
                .promarker{
                    vertical-align:middle;
                    display: inline-block;
                }
                .prospan{
                    width: 330px;
                    max-width:330px;
                    font-size: 18px;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    -o-text-overflow: ellipsis;
                    overflow:hidden;
                    color:black;
                }
                .proaddress{
                    margin-top: 10px;
                    width: 340px;
                    max-width:340px;
                    font-size: 16px;
                    white-space:nowrap;
                    text-overflow:ellipsis;
                    -o-text-overflow: ellipsis;
                    overflow:hidden;
                    color:black;
                }
                .prorukou{
                    display: flex;
                    justify-content: space-between;
                    margin-top: 12px;
                    span{
                        font-size: 16px;
                        border: 1px solid rgba(54,93,186,.88);
                        color: #365dba;
                        border-radius: 2px;
                        padding: 0 6px;
                    }
                    img{
                        width: 22px;
                        height: 24px;
                        vertical-align:middle;
                    }
                }
            }
        }
        .host-bottom{
            width: 1390px;
            height: 280px;
            margin-left: 12px;
            float: left;
            .bottomDiv{
                .bcci-header{
                    color: black;
                    height: 30px;
                    text-align: center;
                    line-height: 50px;
                    font-size: 20px;
                }
                width: 300px;
                height: 260px;
            }
            .bottomDiv2{
                .bcci-header{
                    color: black;
                    height: 60px;
                    text-align: center;
                    line-height: 50px;
                    font-size: 20px;
                }
                width: 300px;
                height: 270px;
            }
           .bleft{
                width: 600px;
                height: 280px;
                float: left;
            }
            .bright{
                width: 700px;
                height: 280px;
                float: left;
            }
        }
    }
    ::-webkit-scrollbar {
        width: 1px;
    }
    .d-flex {
        display: flex;
    }
    #map{
        position: absolute;width: 862px;height:575px;margin-top: 62px;margin-left: 530px;z-index: 999999;
    }
    .mapTongji{
        position: absolute;
        z-index: 999999;
        //border: 1px solid red;
        width: 800px;
        height: 110px;
        margin-top: 70px;
        margin-left: 575px;
        display: flex;
        justify-content: space-between;
        .zidiv{
            color: white;
            padding-left: 10px;
            width: 176px;
            height: 100px;
            background-color: #1e50ae;
            border:1px solid #1e50ae;
            display: flex;
            justify-content: space-between;
            img{
                width: 60px;
                height: 60px;
                margin-top: 5px;
                margin-left: 10px;
            }
            p{
                color: white;
                font-size: 18px;
                font-weight: bold;
            }
            span{
              text-align: center;
                margin-left: 10px;
            }
        }
          .img_div {
            margin-top: 10px;
            vertical-align: middle;
            height: 80px;
            width: 80px;
            background-color: #76F1F8;
            border-radius: 50%;
        }
          .text_div {
            padding-top: 12px;
            height: 80px;
            width: 75px;
            min-width: 80px;
            vertical-align: middle;
            margin: 0px 0px;
            line-height: 33px;
            font-size: 18px;

        }
    }
    .tuli{
        position: absolute;
        color: white;
        width: 140px;
        height: 180px;
        border-right: 1px solid rgb(30,80,174);
        border-top: 1px solid rgb(30,80,174);
        background-color: rgba(30,80,174,0.5);
        margin-left: 533px;
        z-index: 999999;
        margin-top: 460px;
    }
}
    /deep/ .inside-column{
      background-color: rgb(0, 186 ,255) !important;
    }

#cards {
    width: 400px;
    height: 280px;
    .card-item {
        background-color: rgba(6, 30, 93, 0.5);
        border-top: 2px solid rgba(1, 153, 209, .5);
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .card-header {
        display: flex;
        height: 20%;
        align-items: center;
        justify-content: space-between;
        .card-header-left {
            font-size: 18px;
            font-weight: bold;
            padding-left: 20px;
        }
        .card-header-right {
            padding-right: 20px;
            font-size: 40px;
            color: #03d3ec;
        }
    }
    .ring-charts {
        height: 55%;
    }
    .card-footer {
        height: 25%;
        display: flex;
        align-items: center;
        justify-content: space-around;
    }
    .card-footer-item {
        padding: 5px 10px 0px 10px;
        box-sizing: border-box;
        width: 40%;
        background-color: rgba(6, 30, 93, 0.7);
        border-radius: 3px;
        .footer-title {
            font-size: 15px;
            margin-bottom: 5px;
        }
        .footer-detail {
            font-size: 20px;
            color: #1294fb;
            display: flex;
            font-size: 18px;
            align-items: center;
            .dv-digital-flop {
                margin-right: 5px;
            }
        }
    }
}
    .el-icon-s-tools{
        color:#03d3ec ;
        font-size: 25px;
        position: absolute;
        cursor: pointer;
        top:15px;
        right: 10px;
        z-index: 9999;
    }
.ol-popup {
    position: absolute;
    background-color: white;
    width: 200px;
    -webkit-filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0 1px 4px rgba(0, 0, 0, 0.2));
    padding: 18px;
    padding-bottom:5px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    bottom: 12px;
    left: -50px;
    min-width: 300px;
    /* background: rgba(59,88,119,1);
    color: #04F7F9;*/
    background: rgba(255,255,255,1);
    color: #000000;
}
.poptable{
    font-size: 14px;
    font-family: "\5FAE\8F6F\96C5\9ED1";
    border: 1px solid #ccc;
    border-width: 0 1px 1px 0;
    border-collapse: collapse;
    border-spacing: 0;
    background-color: transparent;
    text-align: center;
}
.poptable tr td,.poptable tr th{
    line-height: 36px;
    font-size: 14px;
    padding: 0 8px;
    border: 1px solid #ccc;
    border-width: 1px 0 0 1px;
}
.ol-popup:after,
.ol-popup:before {
    top: 100%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;

}
.ol-popup:after {
    border-top-color: rgba(255,255,255,1);
    border-width: 10px;
    left: 48px;
    margin-left: -10px;
}

.ol-popup:before {
    border-top-color: #cccccc;
    border-width: 11px;
    left: 48px;
    margin-left: -11px;
}
.ol-popup-closer {
    text-decoration: none;
    position: absolute;
    top: 2px;
    right: 8px;
}

.ol-popup-closer:after {
    content: "888";
}

.popx {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px 4px 0 0;
    text-align: center;
    width: 18px;
    height: 14px;
    font: 16px/14px Tahoma, Verdana, sans-serif;
    color: #c3c3c3;
    text-decoration: none;
    font-weight: bold;
    background: transparent;
}
/deep/ .el-button{
    background-color: #1E50Ae !important;
    color: white !important;
    border: 1px solid #1E50Ae !important;
}

</style>
