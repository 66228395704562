<template>
  <div class="bg">
    <hou-left></hou-left>
  <div class="mainpart">
    <el-card class="box-card" style="height: 100%">
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input placeholder="请输入操作人员名称查询" size="medium" v-model="queryInfo.userName" class="input-with-select" clearable @clear="getRiZhiList">
              <el-button size="medium"  type="goon"  slot="append" icon="el-icon-search" @click="getRiZhiList" ></el-button>
            </el-input>
          </el-col>
          <el-col :span="8">
            <el-select size="medium" v-model="queryInfo.operationName"  clearable placeholder="请选择操作类型" @change="getRiZhiList">
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
      </div>
      <div class="serverTable">
      <el-table
        :data="riZhiData"
        border
        height="100%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        style="width: 100%;background-color: #142245">
        <el-table-column
                type="index"
                label="序号"
                width="50"
        >
          <template v-slot="scope">
            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
          prop="userName"
          label="用户姓名"
          min-width="8%"
        >
        </el-table-column>
        <el-table-column
          prop="roleName"
          label="所属角色"
          min-width="8%"
        >
        </el-table-column>
        <el-table-column
          prop="operationName"
          label="操作"
          min-width="6%"
        >
        </el-table-column>
        <el-table-column
          prop="logMessage"
          label="日志信息"
          min-width="30%"
        >
        </el-table-column>
        <el-table-column
          prop="objMessage"
          label="操作对象"
          min-width="30%"
        >
        </el-table-column>
        <el-table-column
          prop="module"
          label="所属模块"
          min-width="8%"
        >
        </el-table-column>
        <el-table-column
          prop="creatDateTime"
          label="操作时间"
          min-width="16%"
        >
          <template slot-scope="scope">
            {{formatDateTime(scope.row.creatDateTime)}}
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNumber"
        :page-sizes="[5,10, 20, 50]"
        :page-size="queryInfo.pagesize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      </div>
    </el-card>
  </div>
  </div>
</template>

<script>
  import houLeft from "./houTaiLeft";
  export default {
    name: 'riZhiList',
    data(){
      return{
        riZhiData:[],
        queryInfo:{
          userName:'',
          operationName:'',
          pageNumber:1,
          pageSize:10,
        },
        total:0,
        options: [{
          value: '查询',
          label: '查询'
        }, {
          value: '保存',
          label: '保存'
        }, {
          value: '修改',
          label: '修改'
        }, {
          value: '删除',
          label: '删除'
        }, {
          value: '登录',
          label: '登录'
        },{
          value: '退出',
          label: '退出'
        },{
          value: '异常',
          label: '异常'
        }],
      }
    },
    created () {
      this.getRiZhiList()
    },
    methods:{
      async getRiZhiList(){
        const {data:res}=await  this.$http18.get('/v1.0/systemLog/findPages',{
          params:this.queryInfo
        })
        if(res.status!=="3000"){
          this.riZhiData=[];
          this.total=0;
          return this.$message.error('获取日志列表失败！'+res.message)
        }
        this.riZhiData=res.data.result;
        this.total=res.data.totalCount;
      },
      handleSizeChange(newSize){
        this.queryInfo.pageSize=newSize;
        this.getRiZhiList()
      },
      handleCurrentChange(newPage){
        this.queryInfo.pageNumber=newPage;
        this.getRiZhiList()
      },
      formatDateTime(date){
        let d = new Date(date);
        let month = (d.getMonth() + 1) < 10 ? '0'+(d.getMonth() + 1) : (d.getMonth() + 1);
        let day = d.getDate()<10 ? '0'+d.getDate() : d.getDate();
        let hours = d.getHours()<10 ? '0'+d.getHours() : d.getHours();
        let min = d.getMinutes()<10 ? '0'+d.getMinutes() : d.getMinutes();
        let sec = d.getSeconds()<10 ? '0'+d.getSeconds() : d.getSeconds();
        let times=d.getFullYear() + '-' + month + '-' + day + ' ' + hours + ':' + min + ':' + sec;
        return times
      },

    },
    components:{
      houLeft
    }
  }
</script>

<style scoped lang="less">

</style>
