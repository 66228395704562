<template>
    <div class="main">
        <x-daohang></x-daohang>
        <div class="middle">
            <div class="tool">
                <!--功能按钮-->
                <el-row>
                <!--    <el-button icon="el-icon-download" type="primary" size="small"
                               @click="handleDownloadRule()" round>表单设计页面</el-button>-->
                    <h2 style="text-align: center;color: #000">流程表单设计</h2>
                    <!--<el-button icon="el-icon-upload2" type="success" size="small"-->
                               <!--@click="handleUploadRule()" round>导入表单JSON</el-button>-->
                    <!--<el-button icon="el-icon-download" type="primary" size="small"-->
                               <!--@click="handleDownloadOption()" round>生成表单配置</el-button>-->
                    <!--<el-button icon="el-icon-upload2" type="success" size="small"-->
                               <!--@click="handleUploadOption()" round>导入表单配置</el-button>-->
                </el-row>
            </div>
            <!--表单构建器-->
            <fc-designer class="form-build" ref="designer">
                <template slot="handle">
                    <!--<ElButton @click="creatreform">创建表单</ElButton>-->
                    <ElButton @click="handleDownloadRule">创建表单</ElButton>
                    <ElButton @click="fanhui">返回</ElButton>
                </template>
            </fc-designer>

        </div>
        <el-dialog
                title="请输入业务动态表单相关信息"
                :visible.sync="centerDialogVisible"
                width="30%"
                center>
            <el-form ref="addFormRef" :model="addForm" label-width="100px"  :rules="addFormRule">
                <el-form-item label="表单名称" prop="input">
                    <el-input v-model="addForm.input" placeholder="请输入内容(只能2个字母以上)"  @keyup.native="addForm.input=addForm.input.replace(/[^a-zA-Z]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="表单描述">
                    <el-input
                            type="textarea"
                            :rows="2"
                            placeholder="请输入内容"
                            v-model="addForm.formDescription">
                    </el-input>
                </el-form-item>
                <el-form-item label="所属类别"  prop="categoryName">
                    <el-select v-model="addForm.categoryName" placeholder="请选择">
                        <el-option
                                v-for="item in categoryOptions"
                                :key="item.id"
                                :label="item.name"
                                :value="item.name">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
    <el-button @click="centerDialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="add">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import  eventBus from '../../assets/js/eventBus'
    export default {
        name: "formsheji",
        data(){
            var validateSurnmae = (rule, value, callback) => {
                let reg = /^[A-Za-z]+$/
                if (!reg.test(value)) {
                    callback(new Error(this.$t('joinUs.surnameErr3')))
                } else {
                    callback()
                }
            };
            return {
                addForm:{
                    categoryName:'',
                    input:'',
                    formDescription:''
                },
                centerDialogVisible:false,
                addFormRule:{
                    input:[{required:true,message:'请输入表单名称',trigger:'blur'},{min:2,max:40,message:"长度再2-40个字符之间",trigger:'blur'}],
                    categoryName:[{required:true,message:'请输入表单名称',trigger:'blur'}]
                },
                categoryOptions:[],
            }
        },
        methods:{
            async getALLcategory(){
                const {data:result}=await this.$http3.get('/v1.0/activiti/category/findAll');
                this.categoryOptions=result.data;
            },
             handleDownloadRule(){
                this.centerDialogVisible=true;
            },
            async add(){
                this.$refs.addFormRef.validate(async valid =>{
                    if(!valid)
                        return
                    const json = this.$refs.designer.getJson()
                    // const json1 = JSON.parse(json.replace(/title/g,"description"));
                    // const json2 = JSON.parse(JSON.stringify(json1).replace(/field/g,"key"));
                    console.log(typeof json)
                    var list=JSON.parse(json);
                    for(let i in list){
                        list[i]['tableName']=this.addForm.input
                    }
                    var conf={config: {tableName: this.addForm.input},
                        formDescription:this.addForm.formDescription,
                        categoryName:this.addForm.categoryName,
                        list:  list}
                    /* var form = new FormData();
                     form.append("dynamic",JSON.stringify(conf))*/
                    const {data:result}=await this.$http3.post('/v1.0/activiti/form/save/dynamicForm?',conf);
                    if(result.status!='6000'){
                       return this.$message.error("保存失败"+result.message)
                    }
                    this.centerDialogVisible=false;
                    this.$message.success("成功保存")
                    this.$router.push({ path: "/activiti" });
                })

            },
            handleUploadRule(){
                const option = this.$refs.designer.getOption()
                console.log(option)
            },
            handleDownloadOption(){

            },
            handleUploadOption(){

            },
           /* async creatreform(){
                const {data:result}=await this.$http4.get('/findById?id=891a5d5c29ad48b3a040a2367f4cb4b4');
                console.log(result)
            },*/
            fanhui(){
                this.$router.push({ path: "/activiti" });
            }
        },
        created(){
          this.getALLcategory();
        },
        mounted () {
            eventBus.$on('formId',(message)=>{
              console.log(message)
            })
        }
    }
</script>

<style scoped lang="less">
    .tool{
        background-color: #f0f3fa;
    }/*
    ._fc-designer{
        height: 845px !important;
        background-color: #142245;
    }
    /deep/ ._fc-l-group{
        background-color: #142245;
        color: #eef5ff;
        /deep/ div{
            background-color: #142245;
            color: #eef5ff;
        }
    }*/
    /*/deep/ .el-container{
        background-color: #142245;
    }
    /deep/ ._fc-m{
        /deep/ .el-main{
            background-color: #05081B !important;
        }
        /deep/ ._fc-m-drag{
            background-color: #2862b7;
        }
         .draggable-drag{
             background-color: #142245;
         }
    }*/
   ._fc-designer{

       height: 835px !important;
   }
</style>
