<template>
    <div class="bg">
        <hou-left></hou-left>
  <div class="mainpart">
    <el-card class="box-card" style="height: 100%;" >
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input placeholder="请输入角色名查询" v-model="queryInfo.roleName" class="input-with-select" clearable @clear="getJueseList">
              <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getJueseList" ></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" class="btnright"><el-button type="goon"  @click="dialogVisible = true"  v-has="'addrole'">创建角色</el-button></el-col>
        </el-row>
      </div>
      <div class="serverTable">
      <el-table
        :data="tableData"
        height="100%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        style="width: 100%;">
        <el-table-column
          type="expand"
        >
          <template v-slot="scope">
       <el-row  :class="['bdbottom',i1===0?'bdtop' :'','vcenter']"   v-for="(item1,i1) in scope.row.menuDtoList" :key="item1.menuId">
          <el-col :span="5">
            <el-tag closable @close="removeRole(scope.row,item1.menuId)">{{item1.name}}</el-tag>
            <i class="el-icon-caret-right"></i>
          </el-col>
          <el-col :span="19">
            <el-row :class="[i2===0? '':'bdtop','vcenter']"  v-for="(item2,i2) in item1.children" :key="item2.menuId">
              <el-col :span="6">
                <el-tag type="success" closable @close="removeRole(scope.row,item2.menuId,item1)">{{item2.name}}</el-tag>
                <i class="el-icon-caret-right"></i>
              </el-col>
              <el-col :span="18">
                <el-tag type="warning" v-for="(item3,i3) in item2.children" :key="item3.menuId" closable @close="removeRole(scope.row,item3.menuId,item2)">{{item3.name}}</el-tag>
              </el-col>
            </el-row>
          </el-col>
        </el-row>
          </template>
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="50"
        >
          <template v-slot="scope">
            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
          prop="role.roleName"
          label="角色名"
          min-width="16%"
        >
        </el-table-column>
        <el-table-column
          prop="role.roleCode"
          label="角色编码"
          min-width="16%"
        >
        </el-table-column>
      <el-table-column
          prop="role.description"
          label="角色职责"
          min-width="50%"
        >
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          min-width="15%"
        >
          <template v-slot="scope">
            <el-tooltip effect="dark" content="修改角色" placement="top" :enterable="false" >
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.role.id)"  v-has="'updaterole'" ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除角色" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.role.id)"  v-has="'deleterole'"></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="分配资源" placement="top" :enterable="false">
              <el-button type="warning" icon="el-icon-setting" size="mini" @click="showRole(scope.row)"  v-has="'fenpei'"></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNumber"
        :page-sizes="[5,10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="添加角色"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addFormRule">
        <el-form-item label="角色名" prop="roleName">
          <el-input v-model="addForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode">
          <el-input v-model="addForm.roleCode"></el-input>
        </el-form-item>
     <!--   <el-form-item label="角色描述" prop="description">
          <el-input v-model="addForm.description"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
      <el-button type="goon" round size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button type="goon" round size="medium" @click="addJuese" >确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="修改角色信息"
      :visible.sync="editdialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="editdialogClose"
    >
      <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="editFormRules">
        <el-form-item label="角色名" prop="roleName">
          <el-input v-model="editForm.roleName"></el-input>
        </el-form-item>
        <el-form-item label="角色编码" prop="roleCode" >
          <el-input v-model="editForm.roleCode" disabled></el-input>
        </el-form-item>
       <!-- <el-form-item label="角色描述" prop="description">
          <el-input v-model="editForm.description"></el-input>
        </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
     <el-button type="goon" round size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button type="goon" round size="medium" @click="editJueseInfo">确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="分配资源"
      :visible.sync="setRoledialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="setRoleClose"
      >
     <!-- <el-tree
        :data="roleList" :props="roleProps"
        node-key="menuId"
        ref="treeRef"
        check-strictly
        :default-checked-keys="defKeys"
        show-checkbox
        @check-change = "checkChange"
        @node-click="nodeClick"
        default-expand-all
        >-->
      <el-tree
        :data="roleList" :props="roleProps"
        node-key="menuId"
        ref="treeRef"
        :default-checked-keys="defKeys"
        show-checkbox
        default-expand-all
      >
      </el-tree>
      <span slot="footer" class="dialog-footer">
    <el-button @click="setRoledialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="saveRole">确 定</el-button>
  </span>
    </el-dialog>
  </div>
    </div>
</template>

<script>
    import houLeft from "./houTaiLeft";
  export default {
    name: 'jueseList',
    data(){
      return{
        queryInfo:{
          roleName:'',
          name:'',
          pageNumber:1,
          pageSize:10,
        },
        total:10,
        tableData:[],
        dialogVisible:false,
        addForm:{
          roleCode:'',
          roleName:'',
          description:''
        },
        addFormRule:{
          roleName:[{required:true,message:'请输入角色名',trigger:'blur'}],
          roleCode:[{required:true,message:'请输入角色编码',trigger:'blur'}],
        },
        editdialogVisible:false,
        editForm:{
        },
        editFormRules:{
          roleName:[{required:true,message:'请输入角色名',trigger:'blur'}],
          roleCode:[{required:true,message:'请输入角色编码',trigger:'blur'}],
        },
        setRoledialogVisible:false,
        roleList:[],
        roleProps:{
          children: 'children',
          label: 'name'
        },
        defKeys:[],
        roleId:'',
        jishu:0
      }
    },
    created(){
      this.getJueseList();
    },
    methods:{
      async getJueseList(){
        const {data:res}=await  this.$http0.get('/v1.0/role/findPages',{
          params:this.queryInfo
        })
        if(res.status!=="3000"){
          return this.$message.error('获取角色列表失败！'+res.message)
        }
        this.tableData=res.data.result;
        this.total=res.data.totalCount
        //this.total=res.data.result.length
      },
      async addJuese(){
        this.$refs.addFormRef.validate( async valid =>{
          if(!valid)
            return
          const {data:res}= await this.$http0.post('/v1.0/role/save', this.addForm)
          if(res.status!=="6000"){
           return this.$message.error('添加角色失败！'+res.message)
          }
          this.$message.success('添加角色成功！');
          this.getJueseList();
          this.dialogVisible = false;
        })
      },
      handleSizeChange(newSize){
        this.queryInfo.pageSize=newSize;
        this.getJueseList()
      },
      handleCurrentChange(newPage){
        this.queryInfo.pageNumber=newPage;
        this.getJueseList()
      },
      dialogClose(){
        this.$refs.addFormRef.resetFields();
      },
      async editDialog(id){
        //请求查询
        const {data:res}=await  this.$http0.get('/v1.0/role/findById/'+id);
        if(res.status!=="3000"){
          return 0;
        }
        this.editForm=res.data;
        this.editdialogVisible = true
      },
      editdialogClose(){
        this.$refs.editFormRef.resetFields()
      },
      editJueseInfo(){
        this.$refs.editFormRef.validate(async valid =>{
          if(!valid) return
          const {data:res}= await this.$http0.post('/v1.0/role/update', this.editForm)
          if(res.status!=="6000"){
           return this.$message.error('修改角色失败！' +res.message)
          }
          this.$message.success('修改角色成功！');
          this.editdialogVisible = false;
          this.getJueseList();
        })
      },
      async deleteMsg(id){
        const confirmResult = await this.$confirm('此操作将永久删除该角色, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(confirmResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
        //请求
        const {data:res}= await this.$http0.delete('/v1.0/role/delete/'+id)
        if(res.status!="4000"){
          return this.$message.error('删除角色失败！' +res.message)
        }
        this.$message.success('删除角色成功！');
        this.getJueseList();
      },
      async showRole(row){
        this.roleId=row.role.id
       const {data:res}= await this.$http0.get('/v1.0/role/menu/findAllMenuDtos')
        if(res.status!=="3000"){
          return this.$message.error('获取资源列表失败！' +res.message)
        }
        this.roleList=res.data;
        console.log(this.defKeys)
        for(let i=0;i<row.menuDtoList.length;i++){
          this.getDefluatKeys(row.menuDtoList[i],this.defKeys)
        }
        this.setRoledialogVisible=true;
      },
      //获取角色下3级权限默认节点
      getDefluatKeys(node,arr){
        if(!node){
          return
        }
      if(node.children.length==0){
       return arr.push(node.menuId)
       }else {
        //arr.push(node.menuId)
      }

      node.children.forEach(item11=>this.getDefluatKeys(item11,arr))
      },
      setRoleClose(){
       this.defKeys=[];
      },
      async saveRole(){
        const keys=[
       ...this.$refs.treeRef.
       getCheckedKeys(),
       ...this.$refs.treeRef.
       getHalfCheckedKeys()
         ]
        const {data:res}= await this.$http0.post('/v1.0/role/menu/updateMenuRoles',{
          menuIdList:keys,
          roleId:this.roleId
        })
        if(res.status!=="6000"){
          return this.$message.error('分配资源列表失败！' +res.message)
        }
        this.$message.success('分配资源列表成功！');
        this.getJueseList();
        this.setRoledialogVisible=false;
      },
      async removeRole(row,menuId,data){
        var mid=menuId;
        const roleResult = await this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(roleResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }

        if(data.children.length==1){
          mid=data.menuId;
        }
        const {data:res}= await this.$http0.delete('/v1.0/role/menu/deleteMenuRolesConnection/'+row.role.id+"/"+mid)
        if(res.status!="4000"){
        return  this.$message.error('删除资源失败！' +res.message)
        }else {
          this.$message.success('删除资源成功！');
          const {data:res1}=await  this.$http0.get('/v1.0/role/menu/findAllByRoleId/'+row.role.id);
          if(res1.status!=="3000"){
            return 0;
          }
          row.menuDtoList=res1.data
        }

        //this.getJueseList();
      },
      checkChange(a,b,c){
        if(b === false){
          //如果当前节点有子集
          if(a.children){
            //循环子集将他们的选中取消
            a.children.map(item => {
              this.$refs.treeRef.setChecked(item.menuId,false);
            })
          }
        }else{
          //否则(为选中状态)
          //判断父节点id是否为空
       /*   if(this.jishu==0){
            if(a.children){
              a.children.map(item => {
                this.$refs.treeRef.setChecked(item.menuId,true);
              })
            }
          }*/
          if(a.parent.parentId!== 0){
            //如果不为空则将其选中
            this.$refs.treeRef.setChecked(a.parent.parentId,true);
            this.jishu++
          }


        }
        //this.tableData = this.$refs.treeRef.getCheckedNodes();
      },
      nodeClick(a,b,c){
        console.log(a)
        console.log(b)
        console.log(c)
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      }
    },
      components:{
          houLeft
      }
  }
</script>

<style lang="less" scoped>

.el-tag{
  margin: 7px;
}
  .bdtop{
    border-top: 1px solid #eeeeee;
  }
  .bdbottom{
    border-bottom: 1px solid #eeeeee;
  }
  .vcenter{
    display: flex;
    align-items: center;
  }
/*#jueseList{
    background-color: #111224;
    position: absolute;
    left: 290px;
    top: 70px;
    bottom: 72px;
    width: 1620px;
    border-radius: 8px;
    box-shadow: 0 0 8px #acc4fe;
}*/
.btnright{
    padding-left: 910px !important;
}
</style>
