<template>
    <div class="site-wrapper">
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div class="addActBtCategory">
                    <div class="catleft">
                        <span class="title-left">流程定义</span>
                    </div>
                    <div style="display: flex;justify-content: flex-end;z-index: 999999999;">
                        <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                        </el-button>
                    </div>
                </div>
                <div>
                    <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                        <template>
                            <el-empty :image-size="100" description="暂无数据"></el-empty>
                        </template>
                        <el-table-column prop="processDefinitionKey" label="流程关键字" width="200">
                            <template #default="scope">
                                <el-tag>{{ scope.row.processDefinitionKey }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="category" label="流程类别" width="180" />
                        <el-table-column prop="processDefinitionId" label="流程编号" width="330" />
                        <el-table-column prop="processDefinitionName" label="流程名称" width="220">
                        </el-table-column>
                        <el-table-column prop="version" label="流程版本" width="90">
                            <template #default="scope">
                                <el-tag type="success">V{{ scope.row.version }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="categoryStatus" label="流程状态" width="150">
                            <template #default="scope">
                                <el-tag type="success"
                                    v-if="scope.row.suspended == 1 && scope.row.modelId != null">激活状态/新版本</el-tag>
                                <el-tag type="success"
                                    v-if="scope.row.suspended == 1 && scope.row.modelId == null">激活状态/<span
                                        style="color: #ffb340">旧版本</span></el-tag>
                                <el-tag type="danger"
                                    v-if="scope.row.suspended == 2 && scope.row.modelId != null">挂起状态/<span
                                        style="color: #67c23a">新版本</span></el-tag>
                                <el-tag type="danger"
                                    v-if="scope.row.suspended == 2 && scope.row.modelId == null">挂起状态/旧版本</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-button size="small" :icon="EditPen" @click="getInfo(scope.row)">预览</el-button>
                                <el-button size="small" type="success" plain :icon="Promotion"
                                    @click="upload(scope.row)">下载</el-button>
                                <el-button size="small" type="warning" plain :icon="Promotion"
                                    title="当流程定义被挂起时已经发起的该流程定义的流程实例不受影响" @click="
                                        processDefinitionStatusByProcessDefinitionId(scope.row)
                                        ">普通挂起|激活</el-button>
                                <el-button size="small" type="danger" title="如果选择级联挂起则流程实例也会被挂起|激活" plain :icon="Promotion"
                                    @click="processDefinitionCascadeStatus(scope.row)">级联挂起|激活</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                        <el-pagination background @current-change="handleChangePageNum" @size-change="handleChangePageSize"
                            layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="流程预览" width="50%">
            <p v-html="dataSvg"></p>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "./proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                proDefCategory: "",
                pageNum: 1,
                pageSize: 10,
                proDefVersion: "",
                processDefinitionKey: "",
                processDefinitionName: "",
                suspended: "",
                whetherFilter: false,
            },
            total: 0,
            tableData: [],
            dialogVisible: false,
            dataSvg: '',
        }
    },
    methods: {
        async getModel() {
            const { data: res } = await this.$http2.post('/ActReProcDef/query/ProcessDefinitionPageList', this.queryPage)
            if (res.code == 200) {
                this.tableData= res.data.list;
                this.total = res.data.total;
            }
        },
        async getInfo(row) {
            const { data: res } = await this.$http2.get('/extension/act-re-deployment/query/ProcessModelSvgImageByProcessDefinitionId/' + row.processDefinitionId)
            this.dataSvg = res;
            this.dialogVisible = true;
        },
        async upload(row) {
            const { data: res } = await this.$http2.get('/extension/act-re-deployment/query/ProcessModelSvgImage/' + row.deploymentId)
            const dataTrack = "svg";
            const a = document.createElement("a");
            const name = row.processDefinitionName + `.${dataTrack}`;
            a.setAttribute(
                "href",
                `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(res)}`
            );
            a.setAttribute("target", "_blank");
            a.setAttribute("dataTrack", `diagram:download-${dataTrack}`);
            a.setAttribute("download", name);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        async processDefinitionStatusByProcessDefinitionId(row) {
            const { data: res } = await this.$http2.put('/ActReProcDef/update/ProcessDefinitionStatusByProcessDefinitionId/' + row.processDefinitionId + '/' + row.suspended === 1 ? 2 : 1)
            if (res.businessCode == 4300) {
                this.$message.success("更新成功");
                this.getModel();
            }
        },
        async processDefinitionCascadeStatus(row) {
            // suspensionDate: 指定挂起|激活时间|为null则为立即挂起|激活
            let param = {
                processDefinitionId: row.processDefinitionId,
                suspensionDate: null,
                suspensionStatus: row.suspended === 1 ? 2 : 1,
            };
            const { data: res } = await this.$http2.put('/ActReProcDef/update/ProcessDefinitionCascadeStatus', param)
            if (res.businessCode == 4300) {
                this.$message.success("更新成功");
                this.getModel();
            }
        },
        handleChangePageSize (val)  {
            this.queryPage.pageSize = val;
            this.getModel();
        },
         handleChangePageNum (val)  {
            this.queryPage.pageNum = val;
            this.getModel();
        }
    },
    created(){
       this.getModel()
    },
    components: {
        promeau,
    },
    watch: {

    },
    mounted() {
    },
}

</script>

<style scoped lang="less">

.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;

        .addActBtCategory {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;

            .catleft {
                margin-top: 10px;

                .title-left {
                    font-size: 18px;
                    font-weight: 700;
                    padding-left: 10px;
                    width: 140px;
                    height: 22px;
                    border-left: 5px solid #569ee3;
                    text-align: left;
                }
            }
        }
    }

}
</style>