<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入年度查询" v-model="queryInfo.procurementTime" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="15">
                            <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">添加采购信息</el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            @expand-change="handledetail"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column type="expand">
                            <template slot-scope="props">
                               <el-table
                                        :data="tableData2[props.row.afetyProcurementId]"
                                        border
                                        :key="keybolen"
                                        class="monthTable"
                                        :header-cell-style="{color:'black'}"
                                >
                                    <el-table-column
                                            type="index"
                                            label="序号"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="afetyProductTypeId"
                                            label="采购类别"
                                            width="240"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="amountProduct"
                                            label="采购数量"
                                            width="180"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="singlePrice"
                                            label="采购单价"
                                            width="180"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="productionCount"
                                            label="采购总价"
                                            width="180"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            label="操作"
                                            align="center"
                                    >
                                        <template v-slot="scope">
                                            <el-button  type="primary" @click="deleteMsg2(scope.row.afetyProcurementTableId)" icon="el-icon-delete" size="mini">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>

                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="procurementTime"
                                label="采购时间"
                                width="400"
                        >

                        </el-table-column>
                        <el-table-column
                                prop="totalAmount"
                                label="采购金额"
                                width="400"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="addMoth(scope.row.afetyProcurementId)" icon="el-icon-document-add" size="mini">新增详细采购种类</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.afetyProcurementId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增采购信息"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="140px" :rules="addRules">
                <el-form-item label="采购时间" prop="procurementTime">
                    <el-date-picker
                            v-model="addForm.procurementTime"
                            type="year"
                            placeholder="选择计划年度">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="采购人员" prop="userId">
                    <el-cascader
                            :options="yonghu"
                            :props="props"
                            @change="handleChange2"
                            v-model="addForm.userId"
                            clearable>
                    </el-cascader>
                </el-form-item>
                <el-form-item label="采购金额(元)" prop="totalAmount">
                    <el-input v-model="addForm.totalAmount" type="number"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增预算种类"
                :visible.sync="dialogVisible2"
                width="50%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-row>
                <div class="addTypeDiv">
                    <el-button  type="primary" @click="addType()" icon="el-icon-document-add" size="mini">新增预算种类</el-button>
                </div>
            </el-row>
            <el-row >
                <el-table
                        :data="tableDataPru"
                        border
                        :header-cell-style="{background:'#e3ecfb',color:'black'}"
                >
                    <el-table-column
                            type="index"
                            label="序号"
                            width="50">
                    </el-table-column>
                    <el-table-column
                            prop="afetyProductTypeId"
                            label="采购类别"
                            width="200"
                    >
                        <template v-slot="scope">
                            <el-input   v-model="scope.row.afetyProductTypeId"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="amountProduct"
                            label="采购数量"
                            width="120"

                    >
                        <template v-slot="scope">
                            <el-input   v-model="scope.row.amountProduct"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="singlePrice"
                            label="采购单价"
                            width="120"

                    >
                        <template v-slot="scope">
                            <el-input   v-model="scope.row.singlePrice"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="productionCount"
                            label="采购总价"
                            width="120"

                    >
                        <template v-slot="scope">
                            <el-input   v-model="scope.row.productionCount"></el-input>
                        </template>
                    </el-table-column>
                    <el-table-column
                            label="操作"
                            align="center"
                    >
                        <template v-slot="scope">
                            <el-button  type="primary" @click="savePru(scope.row)" icon="el-icon-document-add" size="mini">保存</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible2 = false">关 闭</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "notice",
        data(){
            return{
                tableData:[],
                tableData2:[],
                keybolen:false,
                queryInfo:{
                    procurementTime:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    departmentId:"",
                },
                total:0,
                dialogVisible:false,
                dialogVisible2:false,
                addForm:{
                    procurementTime:"",
                    whetherReport:null,
                    totalAmount:null,
                    userId:'',
                    afetyProcurementId:"",
                    departmentId:'',
                    projectId:this.$root.proId,
                },
                addForm2:{
                    afetyProcurementId :'',
                    afetyProductTypeId:'',
                    afetyProcurementTableId:"",
                    amountProduct:'',
                    productionCount:0,
                    singlePrice:''
                },
                yonghu:[],
                props: {
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                fileList:[],
                addRules:{

                },
                row:'',
                expandedRows:'',
                tableDataPru:[]
            }
        },
        created(){
            this.getFiles()
            this.getUsers()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/procurement/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询产品采购信息失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            async handledetail(row,expandedRows){
                this.row=row
                console.log(expandedRows)
                this.expandedRows=expandedRows
                if(expandedRows.length>0){
                    const {data:res}= await this.$http14.post('/v1.0/security/procurement/table/findAllByAfetyProcurementId?afetyProcurementId='+row.afetyProcurementId )
                    if(res.status!='3000'){
                        return  this.tableData2[row.afetyProcurementId]=[]
                    }else {
                        this.tableData2[row.afetyProcurementId]=res.data

                    }
                }else{
                    this.tableData2[row.afetyProcurementId]=[]
                }
               this.keybolen=!this.keybolen

            },
            handleChange(value){
                this.addForm2.userId=value[value.length-1]
            },
            handleChange2(value){
                this.addForm.userId=value[value.length-1]
            },
            sendNotice(){
                this.dialogVisible=true
            },
            async addFile(){
                const {data:res}= await this.$http14.post('/v1.0/security/procurement/save',this.addForm)
                if(res.status!='6000'){
                    return this.$message.error('新增采购信息失败！'+res.message)
                }
                this.$message.success('新增采购信息成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async addFile2(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/planTable/save',this.addForm2)
                if(res.status!='6000'){
                    return this.$message.error('新增月度会议计划失败！'+res.message)
                }
                this.$message.success('新增月度会议计划成功！');
                this.dialogVisible2=false;
                if(this.row.meetingPlanId==this.addForm2.meetingPlanId){
                    this.handledetail(this.row,this.expandedRows);
                }

            },
            addMoth(id){
                this.addForm2.afetyProcurementId =id;
                this.tableDataPru=[]
                this.dialogVisible2=true
            },
            addType(){
                this.tableDataPru.push({
                    "afetyProcurementTableId": "",
                    "afetyProcurementId": this.addForm2.afetyProcurementId,
                    "afetyProductTypeId": "",
                    "amountProduct": null,
                    "productionCount":null,
                    "singlePrice": null
                })
            },
            async  savePru(row){
                console.log(row)
                const {data:res}= await this.$http14.post('/v1.0/security/procurement/table/save',row)
                if(res.status!='6000'){
                    return this.$message.error('新增采购种类失败！'+res.message)
                }
                this.$message.success('新增采购种类成功！');
                //this.dialogVisible2=false;
                if(this.row.afetyProcurementId==this.addForm2.afetyProcurementId){
                    this.handledetail(this.row,this.expandedRows);
                }
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该采购信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/procurement/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除采购信息失败！'+res.message)
                }
                this.$message.success('删除采购信息成功！');
                this.getFiles();
            },
            async deleteMsg2(id){
                const confirmResult = await this.$confirm('此操作将永久删除该采购种类, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/procurement/table/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除采购种类失败！'+res.message)
                }
                this.$message.success('删除采购种类成功！');
                this.handledetail(this.row,this.expandedRows);
            },
            async showfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getFiles()
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">
    /deep/ .el-table__expanded-cell{
        padding-left: 120px;
        padding-right: 120px;
    }
    /deep/ .el-date-editor{
        width: 100% !important;
    }
    /deep/ .el-table--border,  /deep/.el-table--group{
        border: 2px solid #ccc;
    }
    .addTypeDiv{
        margin-bottom: 20px;
    }
    .monthTable{
        max-height: 420px;
        overflow-y: auto;
    }
</style>
