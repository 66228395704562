<template>
  <div class="site-wrapper">
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div id="CategoryTree">
          <el-input
            @clear="insertActBtCategory_clear"
            placeholder="输入关键字进行过滤"
            clearable
            v-model="filterText"
          >
          </el-input>
          <el-tree
            class="filter-tree"
            :data="dataCategory"
            :props="defaultCategoryProps"
            default-expand-all
            :filter-node-method="filterCategoryNode"
            ref="tree"
            show-checkbox
            node-key="categoryId"
            :check-strictly="true"
            @check-change="handleCheckChange"
            @node-click="nodeClick"
          >
            <!-- 处理菜单内容过长 -->
            <span class="custom-tree-node" slot-scope="{ node }">
              <el-tooltip
                class="item"
                effect="dark"
                :content="node.label"
                placement="top-start"
              >
                <span> {{ node.label | ellipsis(8) }} </span>
              </el-tooltip>
            </span>
          </el-tree>
        </div>
        <div id="pageList">
          <div class="addActBtCategory">
            <div class="catleft">
              <span class="title-left">待办流程</span>
            </div>
            <div style="display: flex; justify-content: flex-end">
              <el-button type="primary" @click="resetCondition"
                ><i class="el-icon-remove"></i><span>重置</span>
              </el-button>
            </div>
          </div>
          <!-- 列表 -->
          <div>
            <el-table
              :data="tableData"
              border
              size="large"
              style="height: 100%"
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            >
              <template>
                <el-empty :image-size="100" description="暂无数据"></el-empty>
              </template>
              <el-table-column
                prop="procdefInfoVO.processDefinitionName"
                label="流程名称"
                width="150"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <el-tag
                    @click="getInfo2(scope.row)"
                    style="cursor: pointer"
                    >{{ scope.row.procdefInfoVO.processDefinitionName }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="taskName"
                label="任务名称"
                width="120"
                show-overflow-tooltip
              />
              <el-table-column
                prop="processExecutionVO.processInstanceName"
                label="实例名称"
                width="150"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <span
                    v-if="scope.row.processExecutionVO.processInstanceName"
                    >{{
                      scope.row.processExecutionVO.processInstanceName.split(
                        "_"
                      )[1]
                    }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column prop="taskType" label="任务类型" width="102">
                <template #default="scope">
                  <el-tag type="success"
                    ><span>{{ scope.row.taskType }}</span></el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column
                prop="assignee"
                label="签收者"
                width="80"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <span v-if="scope.row.assignee">{{
                    scope.row.assignee.split("_")[1]
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="businessType"
                label="业务类型"
                width="115"
                show-overflow-tooltip
              />
              <el-table-column
                prop="createTime"
                label="开始时间"
                width="180"
                show-overflow-tooltip
              >
                <template #default="scope">
                  <div style="display: flex; align-items: center">
                    <span>{{
                      convertUTCTimeToLocalTime(scope.row.createTime)
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column prop="dueDate" label="截止时间" width="180">
                <template #default="scope">
                  <div style="display: flex; align-items: center">
                    <span>{{
                      convertUTCTimeToLocalTime(scope.row.dueDate)
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作" :fixed="'right'">
                <template #default="scope">
                  <el-button
                    size="small"
                    :icon="View"
                    @click="getInfo(scope.row)"
                    >详情</el-button
                  >
                  <el-button
                    size="small"
                    type="success"
                    plain
                    :icon="Promotion"
                    :disabled="scope.row.suspended == 2"
                    :title="
                      scope.row.suspended == 2
                        ? '审批流程挂起'
                        : '流程办理高级操作(驳回|终止|委派|转办)'
                    "
                    @click="dealProcess(scope.row)"
                    >办理</el-button
                  >
                  <el-button
                    size="small"
                    :icon="RefreshLeft"
                    type="warning"
                    plain
                    :disabled="scope.row.taskDefinitionKey !== 'startUserKey'"
                    v-show="router.currentRoute.value.fullPath !== '/todo'"
                    @click="cancelProcess(scope.row)"
                    >取消提交</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div
              style="display: flex; justify-content: flex-end; margin-top: 8px"
            >
              <el-pagination
                background
                @current-change="currentChange"
                @size-change="sizeChange"
                layout="sizes, prev, pager, next, jumper, ->, total, slot"
                :total="total"
              >
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      v-model="dialogVisible"
      title="流程详情"
      width="70%"
      :show-close="true"
    >
      <template #header="{ titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">流程通过</h4>
        </div>
      </template>
      <p v-html="dataSvg" style="text-align: center"></p>
      <el-table
        :data="formData"
        border
        size="large"
        style="height: 80%"
        :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
      >
        <template>
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>
        <el-table-column prop="taskId" label="任务编号" width="180">
          <template #default="scope">
            <el-tag>{{ scope.row.taskId }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="审批人员" width="120" />
        <el-table-column prop="taskName" label="审批节点" width="120" />
        <el-table-column prop="startTime" label="开始时间" width="210">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{
                convertUTCTimeToLocalTime(scope.row.startTime)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="taskName" label="审批进度" width="120">
          <template #default="scope">
            <span>{{ scope.row.endTime != null ? "结束" : "审批中" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="审批类型" width="120" />
        <el-table-column prop="time" label="审批时间" width="210">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{
                convertUTCTimeToLocalTime(scope.row.time)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="message" label="批注"> </el-table-column>
      </el-table>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogVisible = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="dialogPassVisible"
      title="流程通过"
      width="50%"
      :show-close="true"
    >
      <template #header="{ titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">流程通过</h4>
        </div>
      </template>
      <el-form :model="passForm" ref="ruleFormRef" :rules="rules" status-icon>
        <el-form-item
          label="下一节点"
          label-width="100px"
          prop="assignee"
          v-show="isEnd"
        >
          <el-cascader
            @change="userChange"
            ref="userRef"
            v-model="passForm.assignee"
            :show-all-levels="false"
            :options="userOptions"
            :props="props"
            class="cascader"
            placeholder="请选择下一节点审批人员"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="批注意见" label-width="100px" prop="notation">
          <el-input
            v-model="passForm.notation"
            :rows="3"
            type="textarea"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogPassVisible = false">关 闭</el-button>
          <el-button type="primary" @click="submitForm(ruleFormRef)">
            提交
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      v-model="dialogDealVisible"
      title="流程办理"
      width="70%"
      top="5%"
      @closed="getInitiation"
      :show-close="true"
    >
      <template #header="{ titleId, titleClass }">
        <div class="my-header">
          <h4 :id="titleId" :class="titleClass">流程办理</h4>
        </div>
      </template>
      <el-timeline class="time">
        <el-timeline-item
          center
          :timestamp="convertUTCTimeToLocalTime(item.startTime)"
          placement="top"
          v-for="(item, index) in taskCommentList"
          :key="index"
          :icon="item.icon"
          :type="item.typePage"
          :color="item.color"
          :size="item.size"
        >
          <!-- 时间线流程图 -->
          <el-card
            class="el-card-custom"
            v-if="item.type == 'init_type'"
            style="background-color: #f4f4f4; color: #909399"
          >
            <div style="float: left; margin-top: 30px; margin-right: 10px">
              <el-button
                @click="viewProcess(item)"
                type="info"
                :icon="InfoFilled"
                circle
              ></el-button>
            </div>
            <div style="float: left">
              <h3 align="left">{{ item.taskName }}</h3>
              <h4 align="left">{{ item.message }}</h4>
            </div>
          </el-card>
          <!-- 历史批注 -->
          <el-card class="el-card-custom" v-if="item.time != null">
            <div style="float: left; margin-top: 30px; margin-right: 10px">
              <el-button type="success" :icon="Check" circle></el-button>
            </div>
            <div style="float: left">
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                审批节点：{{ item.taskName }}
              </h4>
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                审批进度：结束
              </h4>
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                审批类型：{{ item.type }}
              </h4>
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                批注信息：{{ item.message }}
              </h4>
              <h4
                align="left"
                style="
                  margin: 0;
                  padding: 0;
                  margin-top: 4px;
                  margin-bottom: 4px;
                "
              >
                {{ item.userId }} 提交于
                {{ convertUTCTimeToLocalTime(item.time) }}
              </h4>
            </div>
          </el-card>
          <!-- 当前任务 -->
          <el-card
            class="el-card-custom"
            v-if="item.time == null && item.type != 'init_type'"
          >
            <div class="current_task">
              <!-- 审批选项 -->
              <el-tabs type="border-card" v-model="activeName">
                <el-tab-pane name="first"
                  ><!-- 父组件传参数递给子组件 -->
                  <template #label>
                    <span class="audit_pass"
                      ><el-icon>
                        <SuccessFilled /> </el-icon
                      >审批通过</span
                    >
                  </template>
                  <auditPass
                    :taskId="item.taskId"
                    :isEndTrue="isEnd"
                    :assig="goUser"
                    :users="userOptions"
                    @diaShow="diaShow"
                  ></auditPass>
                </el-tab-pane>
                <el-tab-pane name="third">
                  <template #label>
                    <span class="audit_overrule"
                      ><el-icon>
                        <Sort /> </el-icon
                      >审批驳回</span
                    >
                  </template>
                  <auditOverrule
                    :taskId="item.taskId"
                    :users="userOptions"
                  ></auditOverrule>
                </el-tab-pane>
                <el-tab-pane name="fifth">
                  <template #label>
                    <span class="audit_delegate"
                      ><el-icon>
                        <UserFilled /> </el-icon
                      >审批委派</span
                    >
                  </template>
                  <auditDelegate
                    :taskId="item.taskId"
                    :users="userOptions"
                  ></auditDelegate>
                </el-tab-pane>
                <el-tab-pane name="sixth">
                  <template #label>
                    <span class="audit_turn"
                      ><el-icon>
                        <Avatar /> </el-icon
                      >审批转办</span
                    >
                  </template>
                  <auditTurn
                    :taskId="item.taskId"
                    :users="userOptions"
                  ></auditTurn>
                </el-tab-pane>
                <el-tab-pane label="审批终止" name="seventh">
                  <template #label>
                    <span class="audit_termination"
                      ><el-icon>
                        <CircleClose /> </el-icon
                      >审批终止（审批不通过）</span
                    >
                  </template>
                  <auditTermination :taskId="item.taskId"></auditTermination>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDealVisible = false">关 闭</el-button>
          <!-- <el-button type="primary" @click="deal">办 理</el-button> -->
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import promeau from "../proMeau.vue";
export default {
  data() {
    return {
      queryPage: {
        deploymentCategory: [],
        pageNum: 1,
        pageSize: 10,
        proDefVersion: "",
        processDefinitionKey: "",
        processDefinitionName: "",
        taskName: "",
      },
      total: 0,
      tableData: [],
      passForm: {
        assignee: "",
        taskId: "",
        type: "",
        other: {},
        notation: "情况属实，同意！",
      },
    };
  },
  methods: {
    async getInitiation() {
      const { data: res } = await this.$http2.post(
        "/WaitingDealWithTask/query/WaitingDealWithTaskOptimizePageList",
        this.queryPage
      );
      if (res.code == 200) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    },
  },
  created() {
    this.getInitiation();
  },
  components: {
    promeau,
  },
  watch: {},
  mounted() {},
};
</script>

<style scoped lang="less">
.content {
  padding-top: 50px;
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(92vh);
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .insertActBtCategory {
    width: 300px;
    margin-right: 8px;
  }

  .addActBtCategory {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .catleft {
      margin-top: 10px;
    }
  }

  #CategoryTree {
    width: 14%;
    border: 1px solid #e7e7e7;
    height: 96%;
  }

  #pageList {
    width: 85%;
  }

  .filter-tree {
    overflow: auto;
    flex: 1;
    width: 170px;
    height: 600px;
    margin: 10px;
  }

  .title-left {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    width: 140px;
    height: 22px;
    border-left: 5px solid #569ee3;
    text-align: left;
  }
}
</style>