<template>
    <div class="bg">
        <pro-left></pro-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                    <el-button   @click="dialogVisible = true;isAdd=true;someData={}" v-has="'addevent'">新增经理任命</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column 
                                type="index"
                                label="序号"
                                width="50"
                        >
                        </el-table-column>
                        <el-table-column v-for="(item,index) in generatorBusinessList" :key="index" :prop="item.fieldName"  :label="item.showName"  width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                width="250"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="getInfo(scope.row.id)"  size="mini">详情</el-button>
                                <el-button  type="primary" @click="faqi(scope.row.id)"  size="mini" v-has="'editevent'">发起</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)"  size="mini" v-has="'delevent'">删除</el-button>
                                <el-button  type="primary" @click="upFile(scope.row.id)"  size="mini" v-has="'delevent'">附件上传</el-button>
                                <el-button  type="primary" @click="getFiles(scope.row.id)"  size="mini" v-has="'delevent'">附件下载</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
    </div>
  </template>
  
  <script>
    import proLeft from './proInfoLeft'
    export default {
        name: "commonTable",
        data(){
            return{
                tableData:[],
                generatorBusinessList:[]
            }
        },
        created(){
            this.getProApp()
        },
        methods:{
            async getProApp(){
                const {data:res}= await this.$http9.get('/stencils/findStencilsFromByMenuId?menuId=26')
                if(res.businessCode!='3000'){
                    return this.$message.error('查询失败！'+res.message)
                }
                this.generatorBusinessList=[]
                for(let i in res.data.generatorBusinessList){
                    if(res.data.generatorBusinessList[i].showStatus==1){
                        this.generatorBusinessList.push(res.data.generatorBusinessList[i])
                    }
                }
                this.tableData=res.data.objectMap;
            },
        },
        components:{
            proLeft
        }
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
  </style>
  