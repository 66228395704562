<template>
    <div class="bg">
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-button size="medium" type="goon"  @click="dialogVisible = true"  >创建单位</el-button>
                    <span style="" class="fanhui" type="text" @click="goback">返回</span>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            style="width: 100%;">
                        <el-table-column
                                type="selection"
                        >
                        </el-table-column>
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50"
                        >
                            <template v-slot="scope">
                                {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="unitName"
                                label="单位名称"
                                min-width="30%"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="unitAddress"
                                label="单位地址"
                                min-width="35%"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="isShow"
                                label="是否展示"
                                min-width="10%"
                        >
                        <template v-slot="scope">
                            <span v-if="scope.row.isShow==1">是</span>
                            <span v-else>否</span>
                        </template>
                        </el-table-column>
                     
                        <el-table-column
                                label="操作"
                                min-width="13%"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="修改单位" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="删除单位" placement="top" :enterable="false" >
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)"  ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>

                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
            <el-dialog
                    title="添加单位"
                    :visible.sync="dialogVisible"
                    width="25%"
                    center
                    :close-on-click-modal="false"
                    @close="dialogClose"
            >
                <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addFormRule">
                    <el-form-item label="单位名称" prop="unitName">
                        <el-input size="medium" v-model="addForm.unitName"></el-input>
                    </el-form-item>
                    <el-form-item label="单位地址" prop="unitAddress">
                        <el-input size="medium" v-model="addForm.unitAddress"></el-input>
                    </el-form-item>
                    <el-form-item label="是否展示" prop="isShow">
                        <el-radio v-model="addForm.isShow" label="1">展示</el-radio>
                        <el-radio v-model="addForm.isShow" label="0">隐藏</el-radio>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button size="medium" type="goon" round @click="dialogVisible = false">取 消</el-button>
    <el-button size="medium" type="goon" round @click="addDanwei" >确 定</el-button>
  </span>
            </el-dialog>
            <el-dialog
                    title="修改单位信息"
                    :visible.sync="editdialogVisible"
                    width="25%"
                    center
                    :close-on-click-modal="false"
                    @close="editdialogClose"
            >
                <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="editFormRules">
                    <el-form-item label="单位名称" prop="unitName">
                        <el-input size="medium" v-model="editForm.unitName"></el-input>
                    </el-form-item>
                    <el-form-item label="单位地址" prop="unitAddress">
                        <el-input size="medium" v-model="editForm.unitAddress"></el-input>
                    </el-form-item>
                    <el-form-item label="是否展示" prop="isShow">
                        <el-radio v-model="editForm.isShow" label="1">展示</el-radio>
                        <el-radio v-model="editForm.isShow" label="0">隐藏</el-radio>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button type="goon" size="medium" round @click="editdialogVisible = false">取 消</el-button>
    <el-button type="goon" size="medium" round @click="editDanweiInfo">确 定</el-button>
  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'danweiList',
        data(){
            return{
                value:[],
                city:null,
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                total:10,
                tableData:[],
                dialogVisible:false,
                addForm:{
                    unitName:'',
                    unitAddress:'',
                    isShow:'1'
                },
                addFormRule:{
                    unitName:[{required:true,message:'请输入单位名',trigger:'blur'}],
                },
                editdialogVisible:false,
                editForm:{
                },
                editFormRules:{
                    unitName:[{required:true,message:'请输入单位名',trigger:'blur'}],
                   // cityName:[{required:true,message:'请选择所属师',trigger:'blur'}],
                },
                optionProps:{
                    checkStrictly: true,
                    value: 'id',
                    label: 'name',
                    children: 'children'
                },
            }
        },
        created () {
            this.getDanweiList();
        },
        methods:{
            async getDanweiList(){
                const {data:res}=await  this.$http0.get('/v1.0/account/unit/findAll')
                if(res.businessCode!==3000){
                    this.$message.error('获取单位列表失败！' +res.message);
                    return this.tableData=[];
                }
                this.tableData=res.data;
                this.total=res.data.length
            },
            async addDanwei(){
                this.$refs.addFormRef.validate( async valid =>{
                    if(!valid)
                        return
                    const {data:res}= await this.$http0.post('/v1.0/account/unit/save', this.addForm)
                    if(res.businessCode!==4000){
                        return  this.$message.error('添加单位失败！')
                    }
                    this.$message.success('添加单位成功！');
                    this.getDanweiList();
                    this.dialogVisible = false;
                })
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            async editDialog(id){
                //请求查询
                const {data:res}=await  this.$http0.get('/v1.0/account/unit/findById/'+id);
                if(res.businessCode!==3000){
                    return 0;
                }
                this.editForm=res.data;
                this.editdialogVisible = true
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            editDanweiInfo(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    const {data:res}= await this.$http0.post('/v1.0/account/unit/update', this.editForm)
                    if(res.businessCode!==4300){
                        return  this.$message.error('修改单位失败！')
                    }
                    this.$message.success('修改单位成功！');
                    this.editdialogVisible = false;
                    this.getDanweiList();
                })
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该单位, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http0.delete('/v1.0/account/unit/delete/'+id)
                if(res.businessCode!=5000){
                    return  this.$message.error('删除单位失败！')
                }
                this.$message.success('删除单位成功！');
                this.getDanweiList();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getDanweiList()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getDanweiList()
            },
            goback(){
                this.$router.push({ path: "/system" });
            },
        },

    }
</script>

<style scoped lang="less">

    /*#danweiList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }*/
    .mainpart{
        left: 10px;
        top: 70px;
        bottom: 10px;
        right: 10px;
        width: 1900px;

    }
    .fanhui{
        float: right; padding:  0;border: 0 !important;color: black !important;margin-top: 10px;cursor: pointer;
    }

    /deep/ .cell{
        max-height: 70px;
        overflow-y: auto !important;
    }
</style>
