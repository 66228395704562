<template>
  <div class="bg">
    <div class="mainpart">
      <el-card class="box-card" style="height: 100%">
        <div slot="header" class="clearfix">
          <el-button size="medium" type="goon" @click="addrole"
            >创建角色组</el-button
          >
          <span style="" class="fanhui" type="text" @click="goback">返回</span>
        </div>
        <div class="serverTable">
          <el-table
            :data="tableData"
            height="100%"
            :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            style="width: 100%"
          >
            <el-table-column type="selection"> </el-table-column>
            <el-table-column type="index" label="序号" width="50">
              <template v-slot="scope">
                {{
                  (pageFrom.pageNum - 1) * pageFrom.pageSize + scope.$index + 1
                }}
              </template>
            </el-table-column>
            <el-table-column prop="code" label="角色组编码" min-width="20%">
            </el-table-column>
            <el-table-column prop="name" label="角色组名" min-width="20%">
            </el-table-column>
            <el-table-column
              prop="description"
              label="角色组描述"
              min-width="30%"
            >
            </el-table-column>
            <el-table-column label="操作" min-width="25%">
              <template slot-scope="scope">
                <el-button
                  @click="editRole(scope.row)"
                  type="text"
                  class="caozuo"
                  icon="el-icon-edit"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="color: red"
                  icon="el-icon-delete"
                  class="caozuo"
                  @click="delRole(scope.row)"
                  >删除</el-button
                >
                <el-button
                  @click="getRol(scope.row)"
                  type="text"
                  class="caozuo"
                  icon="el-icon-cpu"
                  >关联角色</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            layout="total"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
      <el-dialog
        :title="title"
        :visible.sync="dialogFormVisible"
        width="25%"
        class="dialog"
        size="small"
      >
        <el-form :model="form" :rules="rules" ref="ruleForm">
          <el-form-item label="角色组名" label-width="100px" prop="name">
            <el-input v-model="form.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="英文编号" label-width="100px" prop="code">
            <el-input v-model="form.code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="角色组描述" label-width="100px" prop="description">
            <el-input v-model="form.description"></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addRole('ruleForm')"
            >保 存</el-button
          >
        </div>
      </el-dialog>
      <el-drawer
        title="关联角色"
        :visible.sync="drawer2"
        direction="rtl"
        size="30%"
      >
        <el-transfer
          filterable
          filter-placeholder="请输入角色名"
          v-model="resvalue"
          size="small"
          :props="{ key: 'roleId', label: 'name' }"
          :data="resdata"
          :titles="['角色列表', '已拥有角色']"
        ></el-transfer>
        <div class="demo-drawer__footer">
          <el-button @click="drawer2 = false" size="small">取 消</el-button>
          <el-button @click="saveRol" size="small" type="primary"
            >确 定</el-button
          >
        </div>
      </el-drawer>
    </div>
  </div>
</template>

<script>
export default {
  name: "danweiList",
  data() {
    return {
      tableData: [],
      total: null,
      pageFrom: {
        pageNum: 1,
        pageSize: 10,
        name: "",
        code: "",
        description: "",
      },
      dialogFormVisible: false,
      title: "新增角色组",
      form: {
        name: "",
        description: "",
        enabled: 1,
      },
      rules: {
        name: [{ required: true, message: "请输入角色组名", trigger: "blur" }],
        description: [
          { required: true, message: "请输入角色组描述", trigger: "blur" },
        ],
      },
      drawer: false,
      menuData: [],
      filterText: "",
      defaultProps: {
        children: "children",
        label: "name",
      },
      roleId: "",
      drawer2: false,
      resvalue: [],
      resdata: [],
    };
  },
  created() {
    this.getRoleList();
  },
  methods: {
    async getRoleList() {
      const { data: res } = await this.$http0.get(
        "/v1/account/roleGroup/query/findAll"
      );
      if (res.businessCode !== 3000) {
        this.$message.error("获取角色组列表失败！" + res.message);
        return (this.tableData = []);
      }
      this.tableData = res.data;
      this.total = res.data.length;
    },
    addrole(row) {
      this.form = {
        name: "",
        description: "",
        enabled: 1,
      };
      this.title = "新增角色组";
      this.dialogFormVisible = true;
    },
    editRole(row) {
      this.form = JSON.parse(JSON.stringify(row));
      this.title = "修改角色组";
      this.dialogFormVisible = true;
    },
    addRole(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.title == "新增角色组") {
            const { data: res } = await this.$http0.post(
              "/v1/account/roleGroup/insert",
              this.form
            );
            if (res.businessCode == 4000) {
              this.$message.success("新增成功！");
            } else {
              this.$message.error("新增失败！" + res.message);
            }
            this.getRoleList();
            this.dialogFormVisible = false;
          } else {
            const { data: res } = await this.$http0.put(
              "/v1/account/roleGroup/update",
              this.form
            );
            if (res.businessCode == 4300) {
              this.$message.success("修改成功！");
            } else {
              this.$message.error("修改失败！" + res.message);
            }
            this.getRoleList();
            this.dialogFormVisible = false;
          }
        } else {
          this.$message.error("新增失败！" + res.message);
          return false;
        }
      });
    },
    async delRole(row) {
      console.log(row);
      const confirmResult = await this.$confirm(
        "此操作将永久删除该角色组, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => {
        return err;
      });
      if (confirmResult !== "confirm") {
        return this.$message({
          type: "info",
          message: "已取消删除",
        });
      }
      //请求
      const { data: res } = await this.$http0.delete(
        "/v1/account/roleGroup/delete?id=" + row.id
      );
      if (res.businessCode != "5000") {
        return this.$message.error("删除角色组失败！" + res.message);
      }
      this.$message.success("删除角色组成功！");
      this.getRoleList();
    },
    async getRol(row) {
      this.roleId = row.id;
      const { data: res } = await this.$http0.get(
        "/v1/account/role/query/findAll"
      );
      const { data: res2 } = await this.$http0.get(
        "/v1/account/role/query/findAllByGroupId?groupId=" + row.id
      );
      this.resvalue = [];
      this.resdata = res.data;
      for (let i in res2.data) {
        this.resvalue.push(res2.data[i].roleId);
      }
      console.log(this.resvalue);
      this.drawer2 = true;
    },
    async saveRol() {
      const { data: res } = await this.$http0.put(
        "/v1/account/roleGroup/update/updateRoleGroupRole",
        {
          roleIds: this.resvalue,
          groupId: this.roleId,
        }
      );
      if (res.businessCode == 4300) {
        this.$message.success("更新成功！");
        this.drawer2 = false;
      }
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    handleChange(value) {
      console.log(value);
    },
    filterNode(value, data) {
      console.log(data);
      if (!value) return true;
      return data.name.indexOf(value) !== -1;
    },
    handleCheckChange(data, checked, indeterminate) {
      console.log(data, checked, indeterminate);
    },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },
  watch: {
    filterText(val) {
      this.$refs.tree.filter(val);
    },
  },
};
</script>

<style scoped lang="less">
/*#danweiList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }*/
.mainpart {
  left: 10px;
  top: 70px;
  bottom: 10px;
  right: 10px;
  width: 1900px;
}
.fanhui {
  float: right;
  padding: 0;
  border: 0 !important;
  color: black !important;
  margin-top: 10px;
  cursor: pointer;
}
.caozuo {
  background: transparent !important;
}
.caozuo:hover {
  color: rgb(97, 111, 240) !important;
}
/deep/ .cell {
  max-height: 70px;
  overflow-y: auto !important;
}
/deep/ .el-tree {
  margin-top: 10px;
}

/deep/ .el-drawer__header {
  font-size: 22px;

  .el-drawer__close-btn {
    margin-right: 30px;
  }
}
/deep/ .demo-drawer__footer {
  text-align: center !important;
}
/deep/ .el-transfer__buttons {
  padding: 0 15px !important;
}
</style>
