<template>
    <div class="bg">
        <kaoqing-left></kaoqing-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="clearfix">
                    <span>我的月度考勤</span>
                    <el-date-picker
                            v-model="selectdate"
                            type="month"
                            popper-class="popperClass"
                            @change="datechange"
                            placeholder="选择月">
                    </el-date-picker>
                </div>
                <div class="text item tongji" style="">
                    <div class="zhengc" @click="zhengchang">
                        <p class="yd">正常</p>
                        <p class="ydr">{{count.normalCount}}</p>
                    </div>
                    <div class="queqin" @click="kuanggong">
                        <p class="yd">旷工</p>
                        <p class="ydr">{{count.absenteeismCount}}</p>
                    </div>
                    <div class="chidao" @click="chidao">
                        <p class="yd">迟到</p>
                        <p class="ydr">{{count.lateCount}}</p>
                    </div>
                    <div class="zaotui" @click="zaotui">
                        <p class="yd">早退</p>
                        <p class="ydr">{{count.leaveCount}}</p>
                    </div>
                    <div class="zaotui" @click="wangdaka">
                        <p class="yd">忘打卡</p>
                        <p class="ydr">{{count.forgetCount}}</p>
                    </div>
                    <!--<div class="shidao" @click="xiujia">
                        <p class="yd">休假</p>
                        <p class="ydr">{{count.xiujia}}</p>
                    </div>-->
                </div>
                <div  class="text item" style="display: flex;justify-content: space-between;">
                    <div class="tableleft">
                        <el-calendar  v-model="value">
                            <template
                                    slot="dateCell"
                                    slot-scope="{date, data}">
                                <div style="width: 100%;height: 100%" @click="riqiclick(data)">
                                    <p :class="data.isSelected ? 'is-selected' : ''" >
                                        {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}
                                    </p>
                                </div>

                            </template>
                        </el-calendar>
                    </div>
                    <div class="tableright">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="100%"
                                :header-cell-style="{background:'#e3ecfb',color:'black'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="startDate"
                                    label="上班打卡时间"
                                    align="center"
                                    width="160">
                            </el-table-column>
                            <el-table-column
                                    prop="endDate"
                                    label="下班打卡时间"
                                    align="center"
                                    width="160">
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    label="状态"
                                    align="center"
                                    width="120">
                            </el-table-column>
                            <el-table-column
                                prop="remark"
                                label="备注"
                                align="center"
                                width="150">
                           </el-table-column>
                            <el-table-column
                                    label="操作"
                                    align="center"
                            >
                                <template v-slot="scope">
                                    <el-button  type="primary" @click="Dialog(scope.row.id)" icon="el-icon-view" size="mini">查看详情</el-button>
                                    <el-button  type="primary" @click="buka(scope.row.id)" icon="el-icon-s-claim" size="mini">补打卡</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="查看打卡详情"
                :visible.sync="dialog"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="120px" >
                <el-form-item label="考勤人">
                    <el-input v-model="editForm.attendanceUserName"></el-input>
                </el-form-item>
                <el-form-item label="上班打卡时间">
                    <el-input v-model="editForm.startDate"></el-input>
                </el-form-item>
                <el-form-item label="下班打卡时间">
                    <el-input v-model="editForm.endDate"></el-input>
                </el-form-item>
                <el-form-item label="上班打卡地点">
                    <el-input v-model="editForm.firstLocation"></el-input>
                </el-form-item>
                <el-form-item label="下班打卡地点">
                    <el-input v-model="editForm.secondLocation"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="editForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="打卡照片">
                    <el-image
                            v-for="item in editForm.projectImagePath"
                            style="width: 100px; height: 100px"
                            :src=item
                            :preview-src-list="editForm.projectImagePath">
                    </el-image>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="dialog = false">取 消</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="补打卡"
                :visible.sync="budialog"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="bueditForm" label-width="80px" >
                <el-form-item label="补卡理由">
                    <el-input v-model="bueditForm.liyou"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                   <el-button round type="goon" size="medium" @click="buka">确 定</el-button>
                   <el-button round type="goon" size="medium" @click="budialog = false">取 消</el-button>
           </span>
        </el-dialog>
    </div>
</template>

<script>
    import kaoqingLeft from './kaoqingLeft'
    export default {
        name: "mykaoqing",
        data(){
            return{
                tableData:[],
                selectdate: new Date(),
                value:new Date(),
                datetime:'',
                count:{
                  xiujia:2,
                    lateCount:0,
                    leaveCount:0,
                    forgetCount:0,
                    absenteeismCount:0,
                    normalCount:0
                },
                dialog:false,
                budialog:false,
                editForm:{
                },
                bueditForm:{

                }
            }
        },
        methods:{
            async datechange(){
                this.datetime=this.selectdate.getFullYear()+"-"+(this.selectdate.getMonth()+1)+"-"+this.selectdate.getDate()+" 00:00:00";
                this.getMyCount(this.datetime)
            },
            async getMyCount(datetime){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/querySelfMonthAttendanceStatistics?projectId='+this.$root.proId+'&attendanceUserId=3&month='+datetime);
                if(res.status=='3000'){
                    this.count=res.data
                    this.count.xiujia=0
                }else {
                    this.count={
                        xiujia:0,
                        absenteeismCount:0,
                        lateCount:0,
                        leaveCount:0,
                        forgetCount:0,
                        normalCount:0,
                    }
                }

            },
            async getMyList(datetime){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/querySelfAttendanceRecord?projectId='+this.$root.proId+'&day='+datetime);
                if(res.status=='3000'){
                    this.tableData=[]
                    this.tableData.push(res.data)
                    for(let i in this.tableData){
                        var status=[];
                        if(this.tableData[i].status.indexOf("LATE")!=-1){
                            status.push('迟到')
                        }
                        if(this.tableData[i].status.indexOf("LEAVEEARLY")!=-1){
                            status.push('早退')
                        }
                        if(this.tableData[i].status.indexOf("FORGET")!=-1){
                            status.push('缺卡')
                        }
                        if(this.tableData[i].status.indexOf("ABSENTEEISM")!=-1){
                            status.push('旷工')
                        }
                        if(this.tableData[i].status.indexOf("VACATION")!=-1){
                            status.push('休假')
                        }
                        if(this.tableData[i].status.indexOf("NORMAL")!=-1){
                            status.push('正常')
                        }
                        this.tableData[i].status=status.toString()
                    }
                }else {
                    this.tableData=[]
                }
            },
            async riqiclick(data){
                var datetime=data.day+" 00:00:00";
                this.getMyList(datetime)
            },
            async getStatusList(status1,datetime){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/querySelfMonthAttendanceByStatus?projectId='+this.$root.proId+'&status='+ status1+'&month='+datetime);
                if(res.status=='3000'){
                    this.tableData=res.data
                    for(let i in this.tableData){
                        var status=[];
                        if(this.tableData[i].status.indexOf("LATE")!=-1){
                            status.push('迟到')
                        }
                        if(this.tableData[i].status.indexOf("LEAVEEARLY")!=-1){
                            status.push('早退')
                        }
                        if(this.tableData[i].status.indexOf("FORGET")!=-1){
                            status.push('缺卡')
                        }
                        if(this.tableData[i].status.indexOf("ABSENTEEISM")!=-1){
                            status.push('旷工')
                        }
                        if(this.tableData[i].status.indexOf("VACATION")!=-1){
                            status.push('休假')
                        }
                        if(this.tableData[i].status.indexOf("NORMAL")!=-1){
                            status.push('正常')
                        }
                        this.tableData[i].status=status.toString()
                    }
                }else {
                    this.$message.error('查询失败'+res.message)
                }
            },
            async xiujia(){
                this.tableData=[]
            },
            async kuanggong(){
                this.getStatusList('ABSENTEEISM',this.datetime)
            },
            async chidao(){
                this.getStatusList('LATE',this.datetime)
            },
            async zaotui(){
                this.getStatusList('LEAVEEARLY',this.datetime)
            },
            async wangdaka(){
                this.getStatusList('FORGET',this.datetime)
            },
            async zhengchang(){
                this.getStatusList('NORMAL',this.datetime)
            },
            async Dialog(id){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/record/findById?id='+id);
                if(res.status=='3000'){
                    this.editForm=res.data;
                    if(res.data.fileGroupId==''){
                        this.editForm.projectImagePath=[]
                    }else {
                        const {data:res1}=await  this.$http2.get('/v1.0/attendance/evidence/findAllByGroupId/'+res.data.fileGroupId);
                        if(res1.status=='3000'){
                            this.editForm.projectImagePath=[];
                            this.editForm.projectImagePath.push("http://localhost:8809/"+res1.data.file_path)
                        }
                    }
                    this.dialog=true
                }else {
                    this.$message.error('查询详情失败'+res.message)
                }
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            beforebuka(id){
              this.budialog=true
                this.bueditForm.id=id
            },
            async buka(id){
                this.$confirm('确认发起补卡流程？')
                    . then(async(_) => {
                        const {data:res}=await  this.$http2.get('/v1.0/attendance/record/makeUpRecord?id='+id);
                        done();
                    })
                    .catch(_ => {});
            },

            handleClose(){
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {
                        this.dialog=false
                        this.bedialog=false
                    });
            }
        },
        created(){
            this.datetime=new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()+" 00:00:00";
            this.getMyCount(this.datetime);
            this.getMyList(this.datetime)
        },
        components:{
            kaoqingLeft
        }
    }
</script>

<style scoped lang="less">
  /*
    #kaoList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        right: 10px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }*/

    /deep/ .el-card__header{
        border-bottom:1px solid #2862b7;
        padding-left: 10px !important;
        .clearfix{
            margin-top: -8px !important;
            padding-left: 5px !important;
            height: 30px !important;
            border-left:5px solid #1e50ae !important;
            color: #000 !important;
            font-size: 14px !important;
        }
        span{
            color: #000 !important;
            font-size: 14px !important;
        }
    }


    /deep/ .el-calendar-day{
        height: 80px;
    }
    /deep/ .el-calendar-table{
        width: 100%;
        height: 100%;
        &:not(.is-range){
            //使不是本月的日期不可点击，不会跳转到其他月份
            td.next{
                pointer-events: none;
            }
            td.prev{
                pointer-events: none;

            }
        }
    }
    .tongji{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 105px;
    }
    .yingdao{
        cursor: pointer;
        float: left;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #268DF6;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }

    }
    .shidao{
        cursor: pointer;
        width: 120px;
        height: 65px;
        float: left;
        border-radius: 5px;
        background-color: #909399;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .queqin{
        cursor: pointer;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #DE494D;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .chidao{
        cursor: pointer;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .zaotui{
        cursor: pointer;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .zhengc{
        cursor: pointer;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #67c23a;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    /deep/ .el-date-editor{
        margin-left: 10px;
        margin-top: -5px;
    }
    /deep/ .el-input__icon{
        line-height: 30px;
    }
    .tableleft{
        width: 500px;
        height: 60%;
    }
    .tableright{
        width: 960px;
        height: 620px;
    }

</style>
<style lang="less">


</style>

