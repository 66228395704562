<template>
  <div class="bg">
    <pro-left></pro-left>
    <div class="mainpart">
      <div class="card" id="card">
        <div class="alldiv ">
          <h2>工程量清单汇总表</h2>
          <el-table :data="tableData" border show-summary size="medium">
            <el-table-column prop="serialNum" label="序号" width="60">
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="项目名称"
              width="280"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.parentId == 'init_level'"
                  style="cursor: pointer; font-weight: 555;display:block;width: 100%;"
                  @click="gonext(scope.row)"
                  >{{ scope.row.itemName }}</span
                >
                <span v-else style="cursor: pointer;display:block;width: 100%;"  @click="gonext(scope.row)">{{
                  scope.row.itemName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              label="金额（人民币元）"
              width="240"
              align="center"
            >
              <template slot-scope="scope">
                <span
                  v-if="scope.row.parentId == 'init_level'"
                  style="font-weight: 555"
                  >{{ scope.row.amount }}</span
                >
                <span v-else>{{ scope.row.amount }}</span>
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注" align="center">
              <template slot-scope="scope">
                <span
                  v-if="scope.row.parentId == 'init_level'"
                  style="font-weight: 555"
                  >{{ scope.row.remark }}</span
                >
                <span v-else>{{ scope.row.remark }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="alldiv alldiv2">
          <h2>工程量清单分项汇总表</h2>
          <el-button icon="el-icon-d-arrow-left" circle class="fanhui" @click="fanhui"></el-button>
          <el-button   class="selectall" @click="selectall">下级所有</el-button>
          <el-table :data="tableData2" border show-summary size="medium" >
            <el-table-column prop="serialNum" label="序号" width="60">
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="工程名称"
              width="320"
              align="center"
            >  <template slot-scope="scope">
            <span v-if="scope.row.parentId == 'init_level'"
                  style="cursor: pointer; font-weight: 555;display:block;width: 100%;"
                  @click="gonext2(scope.row)"
                  >{{ scope.row.itemName }}</span
                >
                <span v-else style="cursor: pointer;display:block;width: 100%;"  @click="gonext2(scope.row)">{{
                  scope.row.itemName
                }}</span>
                </template>
            </el-table-column>
            <el-table-column
              prop="amount"
              label="金额（元）"
              align="center"
            >
            </el-table-column>
          </el-table>
        </div>
        <div class="alldiv alldiv2">
          <h2>工程量清单分部分项汇总表--{{lastname}}</h2>
          <el-button icon="el-icon-d-arrow-left" circle class="fanhui fanhui2" @click="fanhui2"></el-button>
          <el-table :data="tableData3" border show-summary size="medium" style="width:68% !important" ref="ddtable">
            <el-table-column prop="serialNum" label="序号" width="60">
            </el-table-column>
            <el-table-column
              prop="itemName"
              label="工程或费用名称"
              width="320"
              align="center"
            >
              <template slot-scope="scope">
                <span v-if="scope.row.parentId == 'init_level'"
                  style=" font-weight: 555;width: 100%;">{{ scope.row.itemName }}</span
                >
                <span v-else>{{
                  scope.row.itemName
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="itemUnit"
              label="单位"
              width="50"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="itemNum"
              label="数量"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="price"
              label="单价(元)"
              width="100"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="totalAmount"
              label="合价(万元)"
              width="200"
              align="center"
            >
            </el-table-column>
            <el-table-column
              label="确认"
              align="center"
            >
            <template v-slot="scope">
                    <!-- <el-button  type="primary" @click="editDialog(scope.row.id)" size="mini">确认</el-button> -->
                    <img  class="zhengqueimg" v-show="scope.row.isRead==1" :src="url" @click="changeUrl(scope.$index,scope.row)">
                    <img  class="zhengqueimg" v-show="scope.row.isRead==0" :src="url2" >
               </template>
            </el-table-column>

          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import proLeft from "./proInfoLeft";
export default {
  name: "prodashiji",
  data() {
    return {
      tableData: [],
      tableData2: [],
      tableData3: [],
      lastname:'',
      url:require('../../assets/images/正确2.png'),
      url2:require('../../assets/images/正确.png')
    };
  },
  created() {
    this.getProres();
  },
  methods: {
    changeUrl(index,row){
       row.isRead=0;
      this.$set(this.tableData3, index, row)
      this.$message.suceess("工程量清单确认成功！");
    },
    async getProres() {
      const { data: res } = await this.$http1.get(
        "/v1.0/project/quantitiesBill/findAllByProjectId?projectId=1"
      );
      if (res.businessCode != "3000") {
        return this.$message.error("查询项目工程量清单失败！" + res.message);
      }
      this.tableData = res.data;
    },
    async gonext(row) {
        console.log(row)
        const { data: res } = await this.$http1.get(
        "/v1.0/project/quantitiesBillSubitem/findAllByQuantitiesBillId?quantitiesBillId="+row.id
        );
      if (res.businessCode != "3000") {
        return this.$message.error("查询项目工程量清单失败！" + res.message);
      }
      this.lastname=row.itemName
      this.tableData2=res.data
      document.getElementById("card").style.transform = `translate(-1410px)`;
    },
    async selectall(){
      const { data: res } = await this.$http1.get(
        "/v1.0/project/quantitiesBillSubitemBranch/findAll"
        );
      if (res.businessCode != "3000") {
        return this.$message.error("查询项目工程量清单失败！" + res.message);
      }
      this.tableData3=res.data
      for(let i in this.tableData3){
        this.tableData3[i]['isRead']=1
      }
      document.getElementById("card").style.transform = `translate(-2820px)`;
    },
    fanhui(){
        document.getElementById("card").style.transform = `translate(0)`;
    },
    async gonext2(row) {
        const { data: res } = await this.$http1.get(
        "/v1.0/project/quantitiesBillSubitemBranch/findAllByQuantitiesBillSubitemId?quantitiesBillSubitemId="+row.id
        );
      if (res.businessCode != "3000") {
        return this.$message.error("查询项目工程量清单失败！" + res.message);
      }
      this.tableData3=res.data
      for(let i in this.tableData3){
        this.tableData3[i]['isRead']=1
      }
      document.getElementById("card").style.transform = `translate(-2820px)`;
    },
    fanhui2(){
        document.getElementById("card").style.transform = `translate(-1410px)`;
    }
  },
  components: {
    proLeft,
  },
};
</script>

<style scoped lang="less">
/deep/ .el-table thead {
  color: #000;
}
/deep/ .el-table th, .el-table tr{
  background-color: rgba(204, 204, 204, 0.7);
}
/deep/ .el-table__footer-wrapper .el-table__cell {
  background-color: #f5f7fa !important;
  font-weight: 555 !important;
  color: #000 !important;
  font-size: 16px;
}
/deep/ .el-table__body-wrapper{
  max-height: 660px;
  overflow-y: auto;
}
.mainpart {
  display: block;
  overflow: hidden;
}
.card {
  width: 1000%;
  display: flex;
  overflow: hidden;
  transition: all 0.7s linear;
  .alldiv {
    width: 1400px;
    height: 100%;
    text-align: center;

    .el-table {
      margin: auto;
      margin-top: 10px;
      width: 52% !important;
    }
  }
}
.alldiv2{
    position: relative;
}
.fanhui{
    position:absolute;
    left: 200px;
    top: 20px;
}
.fanhui2{
    position:absolute;
    left: 150px;
    top: 20px;
}
.selectall{
  position:absolute;
    right: 120px;
    top: 20px;
}
.zhengqueimg{
  width: 32px;
  height: 32px;
  cursor: pointer;
}
</style>
