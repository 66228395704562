<template>
    <div class="bg">
        <work-left ref="filenum"></work-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="4.5">
                            <el-input placeholder="请输入发文描述查询" v-model="queryInfo.description" class="input-with-select" clearable @clear="getPages">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getPages" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="16">
                            <el-date-picker
                                    v-model="selectdate"
                                    @change="dateChange"
                                    value-format="yyyy-MM-dd HH:mm:ss"
                                    type="daterange"
                                    range-separator="至"
                                    start-placeholder="开始日期"
                                    end-placeholder="结束日期">
                            </el-date-picker>
                        </el-col>

                        <el-col :span="1">
                            <el-button icon="el-icon-search" @click="sendFile" style="margin-left: 10px">发送文件</el-button>
                            <!-- <el-button icon="el-icon-search">编辑公告</el-button>
                             <el-button  icon="el-icon-search">删除公告</el-button>
                             <el-button  icon="el-icon-search">下载公告</el-button>-->
                        </el-col>
                    </el-row>
                   <!-- <el-button  icon="el-icon-search" style="margin-left: 10px">接收文件</el-button>-->

                    <!--<el-button  icon="el-icon-search">预览文件</el-button>
                    <el-button  icon="el-icon-search">下载文件</el-button>-->
                </div>
                <el-tabs v-model="activeName" type="card" @tab-click="handleClick">
                    <el-tab-pane label="接收" name="recv">
                            <el-table
                                    :data="tableData"
                                    border
                                    height="100%"
                                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
                                   >
                                <el-table-column
                                        type="expand"
                                >
                                    <template  v-slot="scope">
                                        <a v-for="item in scope.row.files" :href=" ['http://localhost:8805/'+item.filePath]" target="_blank">{{item.fileName}} </a>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        type="index"
                                        label="序号"
                                        width="50"
                                >
                                    <template v-slot="scope">
                                        {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="files"
                                        label="文件个数"
                                        width="100"
                                >
                                    <template  v-slot="scope">
                                        <span>{{scope.row.files.length}}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        prop="description"
                                        label="描述"
                                        width="400"
                                        :show-overflow-tooltip="true"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="sendUserName"
                                        label="发送人"
                                        width="240"
                                        :show-overflow-tooltip="true"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="sendDate"
                                        label="发送时间"
                                        width="170"
                                >
                                </el-table-column>
                                <el-table-column
                                        prop="isRecv"
                                        label="是否已经接收"
                                        width="140"
                                >
                                    <template v-slot="scope">
                                    <span v-if="scope.row.isRecv==0">
                                        未接收
                                    </span>
                                        <span v-else="">
                                        已接收
                                    </span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                        label="操作"
                                        align="center"
                                >
                                    <template v-slot="scope">
                                     <!--   <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini">修改</el-button>-->
                                       <!-- <el-tooltip effect="dark" content="修改区域" placement="top" :enterable="false" >
                                            <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
                                        </el-tooltip>-->
                                        <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                                      <!--  <el-tooltip effect="dark" content="删除区域" placement="top" :enterable="false">
                                            <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                        </el-tooltip>-->
                                        <el-button  type="primary" @click="moveFile(scope.row)" icon="el-icon-star-on" size="mini">存储</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                            <el-pagination
                                    @size-change="handleSizeChange"
                                    @current-change="handleCurrentChange"
                                    :current-page="queryInfo.pageNumber"
                                    :page-sizes="[5,10, 20, 50]"
                                    :page-size="queryInfo.pageSize"
                                    layout="total, sizes, prev, pager, next, jumper"
                                    :total="total">
                            </el-pagination>
                    </el-tab-pane>
                    <el-tab-pane label="发送" name="send">
                        <el-table
                                :data="tableDataSend"
                                border
                                height="100%"
                                :header-cell-style="{background:'#e3ecfb',color:'black'}"
                              >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50"
                            >
                                <template v-slot="scope">
                                    {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                    prop="description"
                                    label="描述"
                                    width="330"
                                    :show-overflow-tooltip="true"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="sendUserName"
                                    label="接收人名称"
                                    width="240"
                                    :show-overflow-tooltip="true"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="recvDate	"
                                    label="接收日期"
                                    width="160"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="sendDate"
                                    label="发送日期"
                                    width="160"
                            >
                            </el-table-column>
                            <el-table-column
                                    prop="isRoot"
                                    label="文件名"
                                    width="300"
                            >
                                <template v-slot="scope">
                                    <a v-for="item in scope.row.files" :href=" ['http://localhost:8805/'+item.filePath]" target="_blank">{{item.fileName}}; </a>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    align="center"
                            >
                                <template v-slot="scope">
                                   <!-- <el-tooltip effect="dark" content="接收文件" placement="top" :enterable="false" >
                                        <el-button type="primary" icon="el-icon-download" size="mini" @click="recvBefore(scope.row.id)" ></el-button>
                                    </el-tooltip>
                                    <el-tooltip effect="dark" content="删除文件" placement="top" :enterable="false">
                                        <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                    </el-tooltip>-->
                                    <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination
                                @size-change="handleSizeChange2"
                                @current-change="handleCurrentChange2"
                                :current-page="queryInfo.pageNumber"
                                :page-sizes="[5,10, 20, 50]"
                                :page-size="queryInfo.pageSize"
                                layout="total, sizes, prev, pager, next, jumper"
                                :total="total">
                        </el-pagination>
                    </el-tab-pane>
                </el-tabs>

            </el-card>
        </div>
        <el-dialog
                title="发送文件"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addrules">
                <el-form-item label="收发文描述" prop="description">
                    <el-input v-model="addForm.description"></el-input>
                </el-form-item>
                <el-form-item label="接收人" prop="recvUser">
                    <el-cascader
                            :options="yonghu"
                            :props="props"
                            v-model="recvUser"
                            collapse-tags
                            clearable></el-cascader>
                </el-form-item>
                <el-form-item label="文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            multiple
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile"  :disabled="isDisable">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="存储到项目文件夹"
                :visible.sync="dialogVisiblelmove"
                width="25%"
                center
                :close-on-click-modal="false"

                @close="dialogClose"
        >
            <el-form ref="moveFormReffile"  label-width="100px" >
                <el-tree
                        :data="folderTree"
                        node-key="id"
                        ref="tree"
                        :props="defaultProps"
                        show-checkbox
                        check-strictly
                        default-expand-all
                        @check="moveclick"
                        @check-change="tempChange"
                        :expand-on-click-node="false">
         <span class="custom-tree-node" slot-scope="{ node, data }">
         <span v-if="data.parent.isFolder==1"><i class="el-icon-folder-opened"></i> {{ data.parent.name }}</span>
         </span>
                </el-tree>

            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelmove = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="movefile" >确 定</el-button>
  </span>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
    </div>
</template>

<script>
    import workLeft from './workLeft'
    export default {
        name: "document",
        data(){
            return{
                queryInfo:{
                    pageNumber:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    description:'',
                    sendOrRecv:"",
                    startDate:'',
                    endDate:"",
                },
                proshow:false,
                isDisable:false,
                selectdate:[],
                total:0,
                tableData:[],
                tableDataSend:[],
                dialogVisible:false,
                addForm:{
                    description:'',
                    fileName:'',
                    recvUserId:'',
                    multiRecvId:'',
                    projectId:this.$root.proId,
                    sendType:0,
                    users:[]
                },
                recvUser:[],
                yonghu:[],
                props: {
                    multiple: true,
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                fileList:[],
                activeName:'recv',
                dialogVisiblelmove:false,
                filenum:0,
                folderTree:[],
                selectMoveTreeId:'',
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                movefrom:{

                },
                addrules:{
                    description:[{required:true,message:'请输入收发文描述',trigger:'blur'}],
                }
            }
        },
        created(){
            this.getRecv()
        },
        methods:{
            async getPages(){
                const {data:res}= await this.$http3.post('/v1.0/activiti/sendRecv/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    this.tableDataSend=[]
                    return this.$message.error(res.message)
                }
                this.tableData=res.data.result;
                this.tableDataSend=res.data.result;
                this.total=res.data.totalCount
            },
             getRecv(){
                 this.queryInfo.sendOrRecv=1;
                 this.getPages();
            },
            async getSend(){
                this.queryInfo.sendOrRecv=0;
                this.getPages();
            },
            dateChange(){
                console.log(this.selectdate)
                if(this.selectdate){
                    this.queryInfo.startDate=this.selectdate[0]
                    this.queryInfo.endDate=this.selectdate[1]
                }else {
                    this.queryInfo.startDate=''
                    this.queryInfo.endDate=''
                }
                this.getPages()
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http3.delete('/v1.0/activiti/sendRecv/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除收发文失败！'+res.message)
                }
                this.$message.success('删除收发文成功！');
                this.getPages();
            },
            handleClick(tab,event){
                this.queryInfo.pageNumber=1
                if(tab.index==0){

                    this.getRecv()
                }else if(tab.index==1){
                    this.getSend();
                }
            },
            async sendFile(){

                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                this.yonghu=[]
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
                console.log(this.yonghu)
                this.dialogVisible=true
            },
            async addFile(){
               // this.addForm.users=[]
                this.proshow=true
                this.isDisable=true
                this.addForm.recvUserId=''
                if(this.recvUser.length==1){
                   this.addForm.recvUserId =this.recvUser[0][1].toString()
                    //  this.addForm.users.push({id:this.recvUser[0][1]})
                    this.addForm.sendType=0
                }else if(this.recvUser.length>1){
                   for(let i in this.recvUser){
                        if(i==this.recvUser.length-1){
                            this.addForm.recvUserId =this.addForm.recvUserId + (this.recvUser[i][1].toString())
                        }else {
                            this.addForm.recvUserId =this.addForm.recvUserId + (this.recvUser[i][1].toString()+',')
                        }
                    }
                    /*   for(let i in this.recvUser){
                          this.addForm.users.push({id:this.recvUser[i][1]})
                      }*/
                    this.addForm.sendType=1
                }
                var formData=new FormData();
                if(this.filelist){
                    for(let i in this.filelist){
                        formData.append("multipartFiles",this.filelist[i].raw);
                    }
                }else {
                    formData.append("multipartFiles",'');
                }
                formData.append("description",this.addForm.description);
                formData.append("projectId",this.$root.proId);
                formData.append("recvUserId",this.addForm.recvUserId);
                //   console.log(this.addForm.users)
                //   formData.append("users",this.addForm.users);
                formData.append("sendType",this.addForm.sendType);

                const {data:res}= await this.$http3.post('/v1.0/activiti/sendRecv/saveAll',formData)
                if(res.status!='6000'){
                    this.isDisable=false
                    return this.$message.error('发送失败'+res.message)
                }
                this.$message.success('发送成功！')
                this.isDisable=false
                this.dialogVisible=false;
                this.proshow=false
                this.activeName='send';
                this.$refs.filenum.getMsg('jiayi');
                this.getSend();
            },
            async moveFile(from){
                const {data:res}=await  this.$http1.get('/v1.0/project/document/findProjectDocumentOtherTreeByProjectId?projectId='+this.$root.proId)
                if(res.status !='3000'){
                   return this.$message.error('查询项目文件夹失败！'+res.message)
                }
                var arr=[];
                this.folderTree=this.filterTree(res.data,arr)
                this.movefrom=from
                this.dialogVisiblelmove=true
            },
            filterTree(tree, arr = []){
                if (!tree.length) return []
                for (let item of tree) {
                    if (item.parent.isFolder !== 1) continue
                    let node = {...item, children: []}
                    arr.push(node)
                    if (item.children && item.children.length) {
                        this.filterTree(item.children, node.children)
                    }
                }
                return arr
            },
            moveclick(data,isSe){
                this.selectMoveTreeId=data.parent.id
                this.$refs.tree.setCheckedKeys([data.id])
            },
            tempChange(data,checked,node){
              /*  let id = data.parent.id
                let index = this.selectId.indexOf(id)
                if (index < 0 && this.selectId.length && checked) {
                    this.$message.warning('只能选中一个节点')
                    this.$refs.tree.setChecked(data, false) // 取消当前节点的选中状态
                    this.$refs.tree.setCheckedKeys([]);
                    return
                }
                if (!checked && index >= 0 && this.selectId.length) {
                    this.selectId = []
                    return
                }
                // 当前节点不在this.checked(长度为0)中,当前节点为选中状态,this.checked中存储当前节点id
                if (index < 0 && !this.selectId.length && checked) {
                    this.selectId.push(id)
                }*/

                if(checked){
                    this.selectMoveTreeId=data.parent.id
                    this.$refs.tree.setCheckedKeys([data.id])
                }else {
                    if(this.selectMoveTreeId==data.parent.id){
                        this.$refs.tree.setCheckedKeys([data.id])
                    }
                }
            },
            async movefile(){
                console.log(this.movefrom)
                let filesList=[]
                for(let i in this.movefrom.files){
                  /*  const {data:res}=await  this.$http1.post('/v1.0/project/document/receive/recvSendDocument?fileName='+this.movefrom.files[i].fileName+'&filePath='+this.movefrom.files[i].filePath+
                    '&fileType='+this.movefrom.files[i].fileType+'&fileSize='+this.movefrom.files[i].fileSize+'&parentId='+this.selectMoveTreeId+'&projectId='+this.$root.proId)
                  */  filesList.push({
                        "fileName": this.movefrom.files[i].fileName,
                        "filePath": this.movefrom.files[i].filePath,
                        "fileSize": this.movefrom.files[i].fileSize,
                        "fileType": this.movefrom.files[i].fileType,
                        "parentId": this.selectMoveTreeId,
                        "projectId": this.$root.proId,
                        "sendRecvId":this.movefrom.id
                    })
                }
                console.log(filesList)
                const {data:res}=await  this.$http1.post('/v1.0/project/document/receive/recvSendDocument',filesList)
                console.log(res)
                if(res.status=='6000'){
                    this.$message.success('文档移动成功！')
                }else {
                    return this.$message.error(res.message)
                }
                this.dialogVisiblelmove=false
                this.getPages()
                this.$refs.filenum.getMsg('jianyi');
             /*  if(res.status!='1010'){
                    return this.$message.error(res.message)
                }*/
              /*  this.$message.success('文档移动成功！')
                this.getfileTree();
                this.dialogVisiblelmove=false*/
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
                this.recvUser=[]
                this.fileList=[]
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=filelist
            },
            handlePreview(file) {
                console.log(file)
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getRecv()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getRecv()
            },
            handleSizeChange2(newSize){
                this.queryInfo.pageSize=newSize;
                this.getSend()
            },
            handleCurrentChange2(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getSend()
            },
        },
        components:{
            workLeft
        }
    }
</script>

<style scoped lang="less">

   /deep/ .el-table__body-wrapper{
       height: 620px;
   }
    .input-with-select{
        width: 280px;
        .el-button{
            padding: 10px 15px;
        }
    }

    a{
        color: blue;
        text-decoration: none;
    }

</style>
