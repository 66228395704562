<template>

</template>

<script>
    const proId="123";
    export default {
        proId
    }
</script>

<style scoped>

</style>
