<template>
    <div class="bg">
        <kaoqing-left></kaoqing-left>
        <div class="mainpart">
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <span>考勤配置</span>
                    <el-button style="float: right; padding:  0;" type="text" icon="el-icon-edit" @click="dialogVisiblekao=true"><span > 新增考勤配置</span></el-button>
                </div>

                <div  class="text item">
                    <div class="serverTable">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="startTime"
                                align="center"
                                label="上班打卡时间"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="endTime"
                                label="下班打卡时间"
                                align="center"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="areaRange"
                                label="打卡区域范围(km)"
                                align="center"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="roleCode"
                                label="角色"
                                align="center"
                                width="220">
                        </el-table-column>
                        <el-table-column
                                prop="weekRule"
                                label="每周打卡规则"
                                align="center"
                                width="160">
                        </el-table-column>
                        <el-table-column
                                prop="createUserId"
                                label="创建人"
                                align="center"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini">修改配置</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini">删除配置</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    </div>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="添加考勤配置"
                :visible.sync="dialogVisiblekao"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormTypeRef" :model="addFormkao" label-width="130px" >
                <el-form-item label="上班打卡时间">
                    <el-time-picker
                            v-model="addFormkao.startTime"
                            placeholder="选择上班打卡时间">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="下班打卡时间">
                    <el-time-picker
                            v-model="addFormkao.endTime"
                            placeholder="选择下班打卡时间">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="打卡区域范围(km)">
                    <el-input v-model="addFormkao.areaRange"></el-input>
                </el-form-item>
                <el-form-item label="角色">
                    <el-select v-model="addFormkao.roleCode" multiple   placeholder="请选择角色" popper-class="select">
                        <el-option
                                v-for="item in juese"
                                :key="item.role.id"
                                :label="item.role.roleName"
                                :value="item.role.roleCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="每周打卡规则">
                    <el-select v-model="xingqi" multiple   placeholder="请选择哪几天打卡" popper-class="select">
                        <el-option
                                v-for="item in option"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblekao = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addkao" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改考勤配置"
                :visible.sync="dialogVisiblekaoEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="editFormTypeRef" :model="editFormkao" label-width="130px" >
                <el-form-item label="上班打卡时间">
                    <el-time-picker
                            v-model="editFormkao.startTime"
                            placeholder="选择上班打卡时间">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="下班打卡时间">
                    <el-time-picker
                            v-model="editFormkao.endTime"
                            placeholder="选择下班打卡时间">
                    </el-time-picker>
                </el-form-item>
                <el-form-item label="打卡区域范围(km)">
                    <el-input v-model="editFormkao.areaRange"></el-input>
                </el-form-item>
                <el-form-item label="角色">
                    <el-select v-model="editFormkao.roleCode" multiple   placeholder="请选择角色" popper-class="select">
                        <el-option
                                v-for="item in juese"
                                :key="item.role.roleCode"
                                :label="item.role.roleName"
                                :value="item.role.roleCode">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="每周打卡规则">
                    <el-select v-model="xingqi" multiple   placeholder="请选择哪几天打卡" popper-class="select">
                        <el-option
                                v-for="item in option"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblekaoEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editkao" >确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import kaoqingLeft from './kaoqingLeft'
    export default {
        name: "kaomanage",
        data(){
            return{
                dialogVisiblekao:false,
                dialogVisiblekaoEdit:false,
                tableData:[],
                addFormkao:{
                    startTime:new Date(2021, 7, 20, 9, 0,0),
                    endTime:new Date(2021, 7, 20, 18, 0,0),
                    areaRange:null,
                    roleCode:'',
                    weekRule:'',
                    projectId:this.$root.proId
                },
                editFormkao:{

                },
                juese:[],
                option:[{
                    value: '一',
                    label: '星期一'
                }, {
                    value: '二',
                    label: '星期二'
                }, {
                    value: '三',
                    label: '星期三'
                }, {
                    value: '四',
                    label: '星期四'
                }, {
                    value: '五',
                    label: '星期五'
                }, {
                    value: '六',
                    label: '星期六'
                }, {
                    value: '七',
                    label: '星期日'
                }],
                xingqi:[]
            }
        },
        methods:{
            async getList(){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/config/findAllByProjectId?projectId='+this.$root.proId);
                if(res.status!='3000'){
                    return this.$message.error('考勤配置列表查询失败！'+res.message)
                }
                this.tableData=res.data
            },
            async getJuese(){
                const {data:res}=await  this.$http.get('/v1.0/role/findAll');
                this.juese=res.data
            },
            async  addkao(){
                this.addFormkao.weekRule=this.xingqi.toString();
                this.addFormkao.roleCode=this.addFormkao.roleCode.toString();
                this.addFormkao.startTime=this.checkTime(this.addFormkao.startTime.getHours())+ ':' +this.checkTime(this.addFormkao.startTime.getMinutes())+ ':' +this.checkTime(this.addFormkao.startTime.getSeconds());
                this.addFormkao.endTime=this.checkTime(this.addFormkao.endTime.getHours())+ ':' +this.checkTime(this.addFormkao.endTime.getMinutes())+ ':' +this.checkTime(this.addFormkao.endTime.getSeconds());
                const {data:res}=await  this.$http2.post('/v1.0/attendance/config/save',this.addFormkao);
                if(res.status!='6000'){
                    return this.$message.error('考勤配置失败！'+res.message)
                }
                this.$message.success('考勤配置成功！')
                this.dialogVisiblekao=false
                this.getList()
            },
            async editDialog(id){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/config/findById?id='+id);
                if(res.status!=="3000"){
                    return 0;
                }
                this.editFormkao=res.data;
                var start=this.editFormkao.startTime.split(':');
                this.editFormkao.startTime=new Date(2021, 7, 20, start[0], start[1],start[2])
                var end=this.editFormkao.endTime.split(':');
                this.editFormkao.endTime=new Date(2021, 7, 20, end[0], end[1],end[2])
                this.editFormkao.roleCode=this.editFormkao.roleCode.split(',')
                this.xingqi=this.editFormkao.weekRule.split(',')
                this.dialogVisiblekaoEdit=true
            },
            async editkao(){
                this.editFormkao.weekRule=this.xingqi.toString();
                this.editFormkao.roleCode=this.editFormkao.roleCode.toString();
                this.editFormkao.startTime=this.checkTime(this.editFormkao.startTime.getHours())+ ':' +this.checkTime(this.editFormkao.startTime.getMinutes())+ ':' +this.checkTime(this.editFormkao.startTime.getSeconds());
                this.editFormkao.endTime=this.checkTime(this.editFormkao.endTime.getHours())+ ':' +this.checkTime(this.editFormkao.endTime.getMinutes())+ ':' +this.checkTime(this.editFormkao.endTime.getSeconds());
                const {data:res}=await  this.$http2.put('/v1.0/attendance/config/update',this.editFormkao);
                if(res.status!='6000'){
                    return this.$message.error('考勤配置失败！'+res.message)
                }
                this.$message.success('考勤配置成功！')
                this.dialogVisiblekaoEdit=false
                this.getList()
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http2.delete('/v1.0/attendance/config/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除考勤配置失败！'+res.message)
                }
                this.$message.success('删除考勤配置成功！');
                this.getList();
            },
            checkTime(i) {
                if (i < 10) {
                    i = "0" + i
                }
                return i;
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
        },
        created(){
          this.getList();
          this.getJuese();
        },
        components:{
            kaoqingLeft
        }
    }
</script>

<style scoped lang="less">

  /*  #kaoManage{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        right: 10px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }*/

    #klheader{
        width: 99%;
        height: 48%;
        padding-top: 5px;
        border-bottom: 1px solid #273F76   ;
        padding-left: 15px;
    }
    #klbottom{
        width: 99%;
        height: 50%;
        padding-top: 5px;
        border-bottom: 1px solid #273F76   ;
        padding-left: 15px;
    }
  /deep/ .el-card__header{
      border-bottom:1px solid #2862b7;
      padding-left: 10px !important;
      .clearfix{
          margin-top: -8px !important;
          padding-left: 5px !important;
          height: 30px !important;
          border-left:5px solid #1e50ae !important;
          color: #000 !important;
          font-size: 14px !important;
      }
      span{
          color: #000 !important;
          line-height: 32px;
          font-size: 14px !important;
      }
      /deep/ .el-button{
          background-color: #fff !important;
          color: black !important;
          font-weight: normal !important;
          border: 0 !important;
          span{
              font-size: 15px !important;
          }
      }
  }

    /deep/ .el-date-editor{
        margin-left: 0 !important;
        width: 100%;
    }
    /deep/ .el-select{
        width: 100%;
    }

</style>
<style lang="less">

</style>
