<template>
  <div id="asiderBar">
    <el-menu
      class="el-menu-vertical-demo"
      text-color="black"
      active-text-color="#fff"
      :collapse="isZhank"
      :collapse-transition="false"
      :router="true"
      :default-active="$route.path"
    >
      <el-submenu index="/toDo" v-if="this.daiban.length>0">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>待办事项</span>
        </template>
        <el-menu-item-group>
          <el-menu-item  v-for="item in this.daiban" :index="item.path" :key="item.path">
              <i :class="item.iconCls"></i>
              <span>{{item.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
        <el-submenu index="/myFlow">
            <template slot="title">
                <i class="el-icon-location"></i>
                <span>个人流程</span>
            </template>
            <el-menu-item-group>
                <el-menu-item  v-for="item in this.geren" :index="item.path" :key="item.path">
                    <i :class="item.iconCls"></i>
                    <span>{{item.name}}</span>
                </el-menu-item>
            </el-menu-item-group>
        </el-submenu>
        <el-menu-item  v-for="item in this.qita" :index="item.path" :key="item.path">
            <el-badge :value="filenumber" class="item" v-if="item.name=='收发文'">
                <i :class="item.iconCls" ></i>
                <span>{{item.name}}</span>
            </el-badge>
            <div v-else>
                <i :class="item.iconCls" ></i>
                <span>{{item.name}}</span>
            </div>
        </el-menu-item>


    </el-menu>

  </div>
</template>

<script>
  export default {
    name: 'workLeft',
    data(){
      return {
        isZhank: false,
        shiList: [],
        menuLevel2:[],
        daiban:[],
        geren:[],
          qita:[],
          filenumber:0
      }
    },
      created () {
          this.menuLevel2=JSON.parse(window.sessionStorage.getItem('menulevel2'))[1];
          for(let i in this.menuLevel2){
              if(this.menuLevel2[i].name=='个人待办'|| this.menuLevel2[i].name=='组待办'){
                     this.daiban.push(this.menuLevel2[i])
              }else if(this.menuLevel2[i].name=='发起的流程'|| this.menuLevel2[i].name=='参与的流程'){
                     this.geren.push(this.menuLevel2[i])
              }else {
                  this.qita.push(this.menuLevel2[i])
              }
          }
        //  this.getNum();
      },
    methods: {
      toggleZhankai () {
        this.isZhank = !this.isZhank
      },
      async getNum(){
          const {data:res}= await this.$http3.get('/v1.0/activiti/sendRecv/findAll/byRecvId?projectId='+this.$root.proId)
          console.log(res)
          if(res.data){
              this.filenumber=res.data.length
          }else {
              this.filenumber=0
          }
      },
        getMsg(msg){
            this.getNum();
        }
    },
  }
</script>

<style lang="less" scoped>

  .toggle-button {
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #eef5ff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
  .el-aside {
    background-color: #333744;
  }
    /deep/ .el-badge__content.is-fixed{
        top:12px;
        right: 1px;
    }

</style>
