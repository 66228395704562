export var xmlStr3 = `<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL"
xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" 
xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI"
xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" 
xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
xmlns:activiti="http://activiti.org/bpmn" 
id="sid-38422fae-e03e-43a3-bef4-bd33b32041b2"
targetNamespace="http://bpmn.io/bpmn"
exporter="bpmn-js (https://demo.bpmn.io)"
exporterVersion="5.1.2">
  <process id="Process_1" name="流程" isExecutable="true">
  <extensionElements>
    <camunda:executionListener class="com.btks.activiti.listener.ProcessInstanceStartExecutionListener" event="start" />
    <camunda:executionListener class="com.btks.activiti.listener.ProcessInstanceEndExecutionListener" event="end" />
  </extensionElements>
    <startEvent id="StartEvent_1">
      <outgoing>Flow_03aazoi</outgoing>
    </startEvent>
    <sequenceFlow id="Flow_03aazoi" sourceRef="StartEvent_1" targetRef="startUserKey" />
    <userTask id="startUserKey" name="发起人" camunda:assignee="\${startUser}">
      <extensionElements>
        <camunda:executionListener class="com.btks.activiti.listener.ProcessInstanceFirstTaskEndListener" event="end" />
      </extensionElements>
      <incoming>Flow_03aazoi</incoming>
      <outgoing>Flow_061lyv9</outgoing>
    </userTask>
    <sequenceFlow id="Flow_1imnohi" sourceRef="startUserKey" targetRef="Activity_094ernx" />
    <userTask id="Activity_094ernx" name="审批" camunda:assignee="\${startUser2}">
      <incoming>Flow_1imnohi</incoming>
      <outgoing>Flow_0ox5ap1</outgoing>
    </userTask>
    <endEvent id="Event_1xzfz1i">
      <incoming>Flow_0ox5ap1</incoming>
    </endEvent>
    <sequenceFlow id="Flow_0ox5ap1" sourceRef="Activity_094ernx" targetRef="Event_1xzfz1i" />
  </process>
  <bpmndi:BPMNDiagram id="BPMNDiagram_1">
  <bpmndi:BPMNPlane id="BPMNPlane_1" bpmnElement="Process_1">
    <bpmndi:BPMNEdge id="Flow_03aazoi_di" bpmnElement="Flow_03aazoi">
      <omgdi:waypoint x="209" y="120" />
      <omgdi:waypoint x="260" y="120" />
    </bpmndi:BPMNEdge>
    <bpmndi:BPMNEdge id="Flow_1imnohi_di" bpmnElement="Flow_1imnohi">
      <omgdi:waypoint x="360" y="120" />
      <omgdi:waypoint x="420" y="120" />
    </bpmndi:BPMNEdge>
    <bpmndi:BPMNEdge id="Flow_0ox5ap1_di" bpmnElement="Flow_0ox5ap1">
      <omgdi:waypoint x="520" y="120" />
      <omgdi:waypoint x="582" y="120" />
    </bpmndi:BPMNEdge>
    <bpmndi:BPMNShape id="_BPMNShape_StartEvent_2" bpmnElement="StartEvent_1">
      <omgdc:Bounds x="173" y="102" width="36" height="36" />
    </bpmndi:BPMNShape>
    <bpmndi:BPMNShape id="Activity_0vhvt0h_di" bpmnElement="startUserKey">
      <omgdc:Bounds x="260" y="80" width="100" height="80" />
    </bpmndi:BPMNShape>
    <bpmndi:BPMNShape id="Activity_1x6au9j_di" bpmnElement="Activity_094ernx">
      <omgdc:Bounds x="420" y="80" width="100" height="80" />
    </bpmndi:BPMNShape>
    <bpmndi:BPMNShape id="Event_1xzfz1i_di" bpmnElement="Event_1xzfz1i">
      <omgdc:Bounds x="582" y="102" width="36" height="36" />
    </bpmndi:BPMNShape>
  </bpmndi:BPMNPlane>
</bpmndi:BPMNDiagram>
</definitions>`;
