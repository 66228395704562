export var  xmlStr3=`<?xml version="1.0" encoding="UTF-8"?>
<definitions xmlns="http://www.omg.org/spec/BPMN/20100524/MODEL" xmlns:bpmndi="http://www.omg.org/spec/BPMN/20100524/DI" xmlns:omgdi="http://www.omg.org/spec/DD/20100524/DI" xmlns:omgdc="http://www.omg.org/spec/DD/20100524/DC" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:camunda="http://camunda.org/schema/1.0/bpmn" id="sid-38422fae-e03e-43a3-bef4-bd33b32041b2" targetNamespace="http://bpmn.io/bpmn" exporter="bpmn-js (https://demo.bpmn.io)" exporterVersion="5.1.2">
  <process id="testkeyfffffffff" name="name" isExecutable="true">
    <extensionElements>
      <camunda:executionListener class="cn.btks.epcactiviti.listener.ActivitiExecutionListener" event="end" />
    </extensionElements>
    <startEvent id="StartEvent_1y45yut" name="开始">
      <outgoing>SequenceFlow_0h21x7r</outgoing>
    </startEvent>
    <sequenceFlow id="SequenceFlow_0h21x7r" sourceRef="StartEvent_1y45yut" targetRef="selftask" />
    <userTask id="selftask" name="本人发起流程" camunda:assignee="\${self}">
      <incoming>SequenceFlow_0h21x7r</incoming>
      <outgoing>Flow_0rrn6be</outgoing>
    </userTask>
    <sequenceFlow id="Flow_0rrn6be" sourceRef="selftask" targetRef="Activity_0hv29om" />
    <userTask id="Activity_0hv29om" name="333">
      <incoming>Flow_0rrn6be</incoming>
      <outgoing>Flow_0z3cwhf</outgoing>
    </userTask>
    <endEvent id="Event_0d26hky">
      <incoming>Flow_0z3cwhf</incoming>
    </endEvent>
    <sequenceFlow id="Flow_0z3cwhf" sourceRef="Activity_0hv29om" targetRef="Event_0d26hky" />
  </process>
  <bpmndi:BPMNDiagram id="BpmnDiagram_1">
    <bpmndi:BPMNPlane id="BpmnPlane_1" bpmnElement="testkeyfffffffff">
      <bpmndi:BPMNEdge id="SequenceFlow_0h21x7r_di" bpmnElement="SequenceFlow_0h21x7r">
        <omgdi:waypoint x="188" y="120" />
        <omgdi:waypoint x="240" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0rrn6be_di" bpmnElement="Flow_0rrn6be">
        <omgdi:waypoint x="340" y="120" />
        <omgdi:waypoint x="400" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNEdge id="Flow_0z3cwhf_di" bpmnElement="Flow_0z3cwhf">
        <omgdi:waypoint x="500" y="120" />
        <omgdi:waypoint x="562" y="120" />
      </bpmndi:BPMNEdge>
      <bpmndi:BPMNShape id="StartEvent_1y45yut_di" bpmnElement="StartEvent_1y45yut">
        <omgdc:Bounds x="152" y="102" width="36" height="36" />
        <bpmndi:BPMNLabel>
          <omgdc:Bounds x="160" y="145" width="22" height="14" />
        </bpmndi:BPMNLabel>
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_1k1nnpg_di" bpmnElement="selftask">
        <omgdc:Bounds x="240" y="80" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Activity_0ouyq8i_di" bpmnElement="Activity_0hv29om">
        <omgdc:Bounds x="400" y="80" width="100" height="80" />
      </bpmndi:BPMNShape>
      <bpmndi:BPMNShape id="Event_0d26hky_di" bpmnElement="Event_0d26hky">
        <omgdc:Bounds x="562" y="102" width="36" height="36" />
      </bpmndi:BPMNShape>
    </bpmndi:BPMNPlane>
  </bpmndi:BPMNDiagram>
</definitions>\``
