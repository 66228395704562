<template>
    <div style="width: 490px;height: 250px;" id="chanzhi">

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "ecchanzhi",
        mounted(){
            this.echart();
        },
        methods:{
            echart(){
                var category = [];
                var dottedBase = +new Date();
                var lineData = [];
                var barData = [];
                var yusuanData = [];
                var date=new Date();
                var xdata=[];
                var cnum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
                for(var i=0;i<=date.getMonth();i++){
                    xdata.push(cnum[i]+'月')
                }
                for (var i = 0; i < xdata.length; i++) {
                    var date = new Date(dottedBase += 3600 * 24 * 1000);
                    category.push([
                        date.getFullYear(),
                        date.getMonth() + 1,
                        date.getDate()
                    ].join('-'));
                    var b = Math.random() * 200;
                    var d = Math.random() * 200;
                    var yusuan = Math.random() * 200;
                    barData.push(b)
                    yusuanData.push(yusuan)
                    lineData.push(d + b);
                }
                console.log(xdata)
                var myChart2 = echarts.init(document.getElementById('chanzhi'));
                var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        },
                        formatter: function(params){
                            return `${params[0].seriesName} : ${params[0].data+'(万元)'}<br>
                                    ${params[1].seriesName} : ${params[1].data+'(万元)'}<br>
                                     ${'预算：'} : ${params[0].data-params[1].data+'(万元)'}
                                   `
                        }
                    },
                    legend: {
                        data: ['合同额', '合同支出','预算'],
                        textStyle: {
                            color: '#000'
                        }
                    },
                    grid: {
                        top:'13%',
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        data: xdata,
                        axisLine: {
                            lineStyle: {
                                color: '#000'
                            }
                        }
                    },

                    yAxis: {
                        splitLine: {show: false},
                        axisLine: {
                            lineStyle: {
                                color: '#000'
                            }
                        }
                    },
                    series: [{
                        name: '合同额',
                        type: 'line',
                        smooth: true,
                        showAllSymbol: true,
                        symbol: 'emptyCircle',
                        symbolSize: 15,
                        data: lineData
                    }, {
                        name: '合同支出',
                        type: 'bar',
                        barWidth: 10,
                        itemStyle: {
                            barBorderRadius: 5,
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: '#14c8d4'},
                                    {offset: 1, color: '#1e50ae'}
                                ]
                            )
                        },
                        data: barData
                    }, {
                        name: '预算',
                        type: 'bar',
                        barGap: '-100%',
                        barWidth: 10,
                        itemStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 0, 0, 1,
                                [
                                    {offset: 0, color: 'rgba(20,200,212,0.5)'},
                                    {offset: 0.2, color: 'rgba(20,200,212,0.2)'},
                                    {offset: 1, color: 'rgba(20,200,212,0)'}
                                ]
                            )
                        },
                        z: -12,
                        data: yusuanData
                    }, {
                        name: 'dotted',
                        type: 'pictorialBar',
                        symbol: 'rect',
                        itemStyle: {
                            color: '#0f375f'
                        },
                        symbolRepeat: true,
                        symbolSize: [12, 4],
                        symbolMargin: 1,
                        z: -10,
                        data: lineData
                    }]
                };
                myChart2.setOption(option);
            }
        }
    }
</script>

<style scoped>

</style>
