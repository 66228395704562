<template>
  <div class="bg">
    <div class="mainpart">
      <el-card class="box-card" style="height: 100%">
        <div slot="header" class="clearfix">
          <span style="" class="fanhui" type="text" @click="goback">返回</span>
        </div>
        <div class="serverTable">
          <el-table
            :data="tableData"
            style="width: 50%; margin-bottom: 20px"
            row-key="parent.id"
            :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            border
            highlight-current-row
            @current-change="meauclick"
            :tree-props="{ children: 'children', hasChildren: 'hasChildren' }"
          >
            <el-table-column
              prop="parent.name"
              label="菜单名"
              sortable
              width="280"
            >
            </el-table-column>
            <el-table-column
              prop="parent.component"
              label="路由地址"
              sortable
              width="380"
            >
            </el-table-column>
            <el-table-column prop="parent.enabled" label="状态">
            </el-table-column>
          </el-table>
          <div style="width: 50%">
            <div style="float: right">
              <el-button type="primary" @click="addBrother">新增同级</el-button>
              <el-button type="primary" @click="addChild">新增子级</el-button>
              <el-button type="primary" @click="saveEdit">保存修改</el-button>
              <el-button type="primary" @click="deleteMeau">删除</el-button>
            </div>
            <el-form :model="meauform" :inline="true" style="margin-top: 60px">
              <el-form-item label="菜单名称" :label-width="formLabelWidth">
                <el-input v-model="meauform.name" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="组件名称" :label-width="formLabelWidth">
                <el-input v-model="meauform.path" autocomplete="off"></el-input>
              </el-form-item>
              <el-form-item label="路由地址" :label-width="formLabelWidth">
                <el-input
                  v-model="meauform.component"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="菜单图标" :label-width="formLabelWidth">
                <el-input
                  v-model="meauform.meta.icon"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="重定向地址" :label-width="formLabelWidth">
                <el-input
                  v-model="meauform.redirect"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="描述信息" :label-width="formLabelWidth">
                <el-input
                  v-model="meauform.description"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="网页标题" :label-width="formLabelWidth">
                <el-input
                  v-model="meauform.meta.title"
                  autocomplete="off"
                ></el-input>
              </el-form-item>
              <el-form-item label="是否启用" :label-width="formLabelWidth">
                <el-radio v-model="meauform.enabled" :label="1">启用</el-radio>
                <el-radio v-model="meauform.enabled" :label="2">禁用</el-radio>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "danweiList",
  data() {
    return {
      tableData: [],
      formLabelWidth:'120px',
      meauform: {
        belongOrg: "0",
        component: "",
        name: "",
        description: "",
        enabled: null,
        menuId: "",
        menuKey: "",
        name: "",
        menuSort: 0,
        meta: {
          hidden: 0,
          icon: "",
          noCache: 0,
          title: "",
        },
        parentId: "",
        path: null,
        redirect: "",
      },
    };
  },
  created() {
    this.getMeauList();
  },
  methods: {
    async getMeauList() {
      const { data: res } = await this.$http0.get(
        "/v1/account/menu/query/findMenuTree"
      );
      this.tableData = res.data;
    },
    addBrother() {
            this.meauform = {
                belongOrg: "0",
                component: '',
                name: '',
                description: '',
                enabled: null,
                menuKey: '',
                name: '',
                id: "",
                menuSort: 0,
                meta: {
                    "hidden": 1,
                    "icon": "",
                    "noCache": null,
                    "title": ""
                },
                parentId: this.meauform.parentId,
                path: null,
                redirect: "",
            }
            console.log(this.meauform)
            this.meaustatus = 'addBrother'
        },
        addChild() {
            this.meauform = {
                belongOrg: "0",
                component: '',
                name: '',
                description: '',
                enabled: null,
                menuKey: '',
                name: '',
                id: "",
                meta: {
                    "hidden": 1,
                    "icon": "",
                    "noCache": '',
                    "title": ""
                },
                parentId: this.meauform.menuId,
                path: null,
                redirect: "",
            }
            this.meaustatus = 'addChild'
        },
        async saveEdit() {
            if (this.meaustatus == 'addBrother' || this.meaustatus == 'addChild') {
                const { data: res } = await this.$http0.post('/v1/account/menu/insert', this.meauform)
                if (res.businessCode == 4000) {
                    this.$message.success('保存成功！');
                    this.getMeauList()
                }
            } else {
                const { data: res } = await this.$http0.put('/v1/account/menu/update', this.meauform)
                if (res.businessCode == 4300) {
                    this.$message.success('修改成功！');
                    this.getMeauList()
                }
            }
        },
        async deleteMeau() {
            const { data: res } = await this.$http0.delete('/v1/account/menu/delete?id=' + this.meauform.id)
            if (res.businessCode == 5000) {
                this.$message.success('删除成功！');
                this.getMeauList()
            }
        },
        meauclick(val) {
            this.meauform = JSON.parse(JSON.stringify(val.parent));
            this.meaustatus = ''
            console.log(val)
        },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },
};
</script>

<style scoped lang="less">
/*#danweiList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }*/

.mainpart {
  left: 10px;
  top: 70px;
  bottom: 10px;
  right: 10px;
  width: 1900px;
}

.fanhui {
  float: right;
  padding: 0;
  border: 0 !important;
  color: black !important;
  margin-top: 10px;
  cursor: pointer;
}

/deep/ .cell {
  max-height: 70px;
  overflow-y: auto !important;
}

.caozuo {
  background: transparent !important;
}

.caozuo:hover {
  color: rgb(97, 111, 240) !important;
}
.serverTable{
  display: flex;
  justify-content: space-between;
}
/deep/ .el-table{
  overflow-y: auto;
}
</style>
