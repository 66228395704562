<template>
  <div class="site-wrapper">
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div class="canvas" ref="canvas" id="container"></div>
        <div id="js-properties-panel" class="panel"></div>
        <ul class="buttons">
          <li>
            <el-button type="success" @click="saveBpmn">保存</el-button>
          </li>
          <li>
            <el-button type="warning" @click="toback">后退</el-button>
          </li>
          <li>
            <el-button type="warning" @click="toright">前进</el-button>
          </li>
          <li>
            <el-button type="info" @click="uploadBpmn">下载bpmn</el-button>
          </li>
          <li>
            <el-button type="primary" @click="goback">返回</el-button>
          </li>
        </ul>
      </div>
    </div>

  </div>
</template>

<script>
import promeau from "./proMeau.vue";
import BpmnModeler from 'bpmn-js/lib/Modeler'
import {
  xmlStr3
} from './mock/xmlStr3' // 这里是直接引用了xml字符串
import propertiesPanelModule from 'bpmn-js-properties-panel'
import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda'
import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda'
import customTranslate from './mock/customTranslate'
import  AES  from "../../../utils/aes.js";
var customTranslateModule = {
  translate: ['value', customTranslate]
};
export default {
  data() {
    return {
      defaultXmlStr: xmlStr3,
      modelId: this.$route.query.modelId,
      initXml:'',
    }
  },
  methods: {
    init(xmlStr) {
      // 获取到属性ref为“canvas”的dom节点
      const canvas = this.$refs.canvas
      // 建模
      this.bpmnModeler = new BpmnModeler({
        container: canvas,
        propertiesPanel: {
          parent: '#js-properties-panel'
        },
        additionalModules: [
          // 右边的属性栏
          propertiesProviderModule,
          propertiesPanelModule,
          customTranslateModule
        ],
        moddleExtensions: {
          camunda: camundaModdleDescriptor
        }
      })
      this.createNewDiagram(xmlStr)
    },
    createNewDiagram(xmlStr) {
      // 将字符串转换成图显示出来
      this.bpmnModeler.importXML(xmlStr, (err) => {
        if (err) {
          console.log(err)
          // console.error(err)
        } else {
          // 这里是成功之后的回调, 可以在这里做一系列事情
          this.success()
        }
      })
    },
    success() {
      console.log(123)
      this.addBpmnListener()
      this.addModelerListener()
    },
    addBpmnListener() {
      const that = this
      // 获取a标签dom节点
      const downloadLink = this.$refs.saveDiagram
      const downloadSvgLink = this.$refs.saveSvg
      // 给图绑定事件，当图有发生改变就会触发这个事件
      this.bpmnModeler.on('commandStack.changed', function () {
        /*  that.saveSVG(function(err, svg) {
              that.setEncoded(downloadSvgLink, 'diagram.svg', err ? null : svg)
          })*/
        that.saveDiagram(function (err, xml) {
          that.setEncoded(downloadLink, 'diagram.bpmn', err ? null : xml)
        })
      })
    },
    addModelerListener() {
      // 监听 modeler
      const bpmnjs = this.bpmnModeler
      const that = this
      // 'shape.removed'
      const events = ['shape.added', 'shape.move.end', 'connect.end', 'connection.create', 'connection.move']
      events.forEach(function (event) {
        that.bpmnModeler.on(event, e => {
          console.log(event, e)
          var elementRegistry = bpmnjs.get('elementRegistry')
          var shape = e.element ? elementRegistry.get(e.element.id) : e.shape
          console.log(shape)

        })
      })
    },
    saveSVG(done) {
      // 把传入的done再传给bpmn原型的saveSVG函数调用
      this.bpmnModeler.saveSVG(done)
    },
    // 下载为bpmn格式,done是个函数，调用的时候传入的
    saveDiagram(done) {
      // 把传入的done再传给bpmn原型的saveXML函数调用
      this.bpmnModeler.saveXML({ format: true }, function (err, xml) {
        done(err, xml)
      })
    },
    // 当图发生改变的时候会调用这个函数，这个data就是图的xml
    setEncoded(link, name, data) {
      // 把xml转换为URI，下载要用到的
      //  console.log( typeof  data)

      const encodedData = encodeURIComponent(data)
      //console.log(encodedData)
      // 下载图的具体操作,改变a的属性，className令a标签可点击，href令能下载，download是下载的文件的名字
      //console.log(link, name, data)
      // this.bpmnfile = new File([data], 'test.bpmn')
      this.bpmndata = data;

      /*console.log(xmlFile)*/
      if (data) {
        link.className = 'active'
        //link.href = 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData
        link.download = name
      }
    },
    saveBpmn() {
      this.bpmnModeler.saveXML({ format: true }, (err, data) => {
        this.bpmnModeler.saveSVG({ format: true }, async (err, data2) => {
          console.log(AES.encrypt('asd'))
          const { data: res } = await this.$http2.put('/extension/act-re-model/update/DesignProcessModel', {
            actReModelId: this.modelId,
            bpmnXml: AES.encrypt(data.replace(new RegExp("camunda:", "g"), "activiti:"),
              "",
              ""
            ),
            // bpmnXml: data,
            svgXml: AES.encrypt(data2),
          })
          if (res.businessCode == 4300) {
            this.$message.success('保存成功')
          } else {
            this.$message.error('保存失败')
          }
        });
      });
    },
    toback() {
      this.bpmnModeler.get("commandStack").undo();
    },
    toright() {
      this.bpmnModeler.get("commandStack").redo();
    },
    uploadBpmn() {
      this.bpmnModeler.saveXML({ format: true }, (err, data) => {
        console.log(data)
        // 替换camunda:和activiti:
        const dataXml = data.replace(new RegExp("camunda:", "g"), "activiti:");
        const dataTrack = "bpmn";
        const a = document.createElement("a");
        const name = `diagram.${dataTrack}`;
        a.setAttribute(
          "href",
          `data:application/bpmn20-xml;charset=UTF-8,${encodeURIComponent(dataXml)}`
        );
        a.setAttribute("target", "_blank");
        a.setAttribute("dataTrack", `diagram:download-${dataTrack}`);
        a.setAttribute("download", name);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      });
    },
    goback() {
      this.$router.push("/modelManage");
    },
  },
  components: {
    promeau,
  },
  created() {
  },
  watch: {

  },
  async mounted() {
    const { data: res } = await this.$http2.get('/extension/act-re-model/query/ProcessModelXMLInformationByModelId/'+ this.modelId )
      if (res.data) {
        //xml.value = res.data
        // xml2 = xml.value.replaceAll(/\$/g, "\\$");
        // xml2=res.data
 
        this.initXml = res.data.replace(/activiti:/g, "camunda:");
        console.log(this.initXml);
        //  xml2 = xml2.replace('camunda', "activiti");
        //  xml2 = xml2.replace('camunda', "activiti");
      } else {
        this.initXml = this.defaultXmlStr;
      }
      this.init(this.initXml);
    }
}

</script>
<style lang="less">
.bpp-textfield input {
  padding-right: 0 !important;
}
</style>
<style scoped lang="less">

.content {
  padding-top: 50px;
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(89vh);
    padding-top: 50px;
  }

  .canvas {
    width: 100%;
    height: 90%;
  }

  .panel {
    position: absolute;
    right: 30px;
    top: 0;
    width: 300px;
    overflow-y: auto;
    max-height: 100%;
  }

  .buttons {
    position: absolute;
    right: 330px;
    top: 100px;
  }

  .buttons li {
    display: inline-block;
    margin: 5px;
  }

  .buttons li a {
    color: #999;
    background: #eee;
    /* cursor: not-allowed;*/
    padding: 8px;
    border: 1px solid #ccc;
    text-decoration: none;
  }

  .buttons li a.active {
    color: #333;
    background: #fff;
    cursor: pointer;
  }

  #js-properties-panel {
    z-index: 696699;
    top: 55px;
    right: 0;
    max-height: calc(100vh - 55px);

  }

}
</style>