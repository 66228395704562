<!--
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2023-01-09 16:23:13
 * @LastEditors: 龙雨欢 932838577@qq.com
 * @LastEditTime: 2023-04-08 15:54:27
 * @FilePath: \pages-demo\src\views\process\pages\work\dealprocess\auditPass.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="containers">
    <el-form :model="passForm" ref="ruleFormRef" :rules="rules" status-icon>
      <el-form-item label="被转办人员" label-width="100px" prop="assignee">
        <el-cascader
          @change="userChange"
          ref="userRef"
          v-model="passForm.delegate"
          :show-all-levels="false"
          :options="users"
          :props="props2"
          class="cascader"
          placeholder="请选择被转办人员"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="转办事由" label-width="100px" prop="notation">
        <el-input
          v-model="passForm.notation"
          :rows="3"
          type="textarea"
          placeholder=""
        />
      </el-form-item>
    </el-form>
    <span class="footer">
      <el-button type="primary" @click="submitForm(ruleFormRef)">
        提交
      </el-button>
    </span>
  </div>
</template>
<script>
export default {
  props:{
    taskId:{},
    users:{}
  },
  data() {
    return {
      props:{
        taskId: String,
        users: Array,
      },
      passForm:{
        delegate: "",
        taskId: this.taskId,
        notation: "",
      },
      props2:{
        label: "realName",
        value: "assignee",
      },
      rules:{
        notation: [{ required: true, message: "请输入转办事由", trigger: "blur" }],
        delegate: [
    {
      required: true,
      message: "请选择被转办人员",
      trigger: "change",
    },
  ],
      }
    }
  },
  methods:{
    userChange(res){
      this.passForm.delegate = res[res.length - 1];
    },
    submitForm(formName){
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            taskComplaintOther(passForm.value).then((res) => {
              if (res.businessCode == 4300) {
                ElMessage.success("转办成功");
              } else {
                ElMessage.error("转办失败");
              }
            });
       } else {
        console.log("error submit!", fields);
      }
      });
    }
  }
}
</script>
<style lang="less">
.containers {
  text-align: center;
}
</style>
