<!--
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2023-01-09 16:23:13
 * @LastEditors: 龙雨欢 932838577@qq.com
 * @LastEditTime: 2023-04-10 17:00:43
 * @FilePath: \pages-demo\src\views\process\pages\work\dealprocess\auditPass.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="containers">
    <el-form :model="passForm" ref="ruleFormRef" :rules="rules" status-icon>
      <el-form-item
        label="下一节点"
        label-width="100px"
        prop="assignee"
        v-show="!isEnd"
      >
        <el-cascader
          @change="userChange"
          ref="userRef"
          v-model="passForm.assignee"
          :show-all-levels="false"
          :options="userOptions"
          
          class="cascader"
          placeholder="请选择下一节点审批人员"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="批注意见" label-width="100px" prop="notation">
        <el-input
          v-model="passForm.notation"
          :rows="3"
          type="textarea"
          placeholder=""
        />
      </el-form-item>
    </el-form>
    <span class="footer">
      <el-button type="primary" @click="submitForm('ruleFormRef')">
        提交
      </el-button>
    </span>
  </div>
</template>
<script>
export default {
  props: {
    assig:{

    },
    isEnd:{
    },
    taskId:{
      type: String,
    },
    users:{

    }
  },
  data() {
    return {
      passForm:{
        assignee: "23011074S7GY94M8_李俊433692_新疆生产建设兵团第七师国有资产监督管理委员会",
        taskId: this.taskId,
        type: "通过",
        other: {},
        notation: "情况属实，同意！",
      },
      userOptions:[
        {label:"李俊433692",value:'23011074S7GY94M8_李俊433692_新疆生产建设兵团第七师国有资产监督管理委员会'}
      ],
      // props2:{
      //   label: "realName",
      //   value: "assignee",
      // },
      rules:{
        notation: [{ required: true, message: "请输入批注意见", trigger: "blur" }],
        delegate: [ { required: true,message: "请选择审批人",  trigger: "change" },
        ],
      }
    }
  },
  methods:{
    userChange(res){
      this.passForm.delegate = res[res.length - 1];
    },
    submitForm(formName){
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            const { data: res } = await this.$http2.put(
            "/WaitingDealWithTask/update/CompleteTaskAndNoSpecifiedExecutorAssign",
            this.passForm
          );
          if (res.businessCode == 4300) {
            this.$message.success("通过成功");
            this.$emit('passShow', false);
           // this.getInitiation();
          } else {
            this.$message.error("通过失败");
          }
          } else {
            console.log("error submit!", fields);
          }
          });
    }
  }
}
</script>
<style lang="less">
.containers {
  text-align: center;
}
 .cascader{
  text-align: left !important;
}
</style>
