<template>
    <div style="width: 100%;height: 90%;" id="bottomEchartsjindu">

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "ecanquan",
        mounted(){
            this.echart();
        },
        methods:{
           async echart(){
                var xdata=[],ydata=[];
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAll')
                if(res.status!='3000'){
                    return
                }
                for(let i=0;i<res.data.length;i++){
                    xdata.push(res.data[i].projectName)
                }
               const {data:res2}= await this.$http6.get('/v1.0/schedule/statistics/queryAvgCompleteRateStatistics')
               if(res2.status!='3000'){
                   return this.$message.error(res2.message)
               }
               for(let i=0;i<res2.data.length;i++){
                   ydata.push(Number(res2.data[i].avgCompleteRate)*100)
               }

                var myChart1 = echarts.init(document.getElementById('bottomEchartsjindu'));
                var option = {
                    legend: {
                        data: ['完成率(%)']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: xdata
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    grid: {
                        top:'18%',
                        left: '4%',
                        right: '4%',
                        bottom: '0px',
                        containLabel: true
                    },
                    series: [
                        {
                            name: '完成率(%)',
                            type: 'bar',
                            barGap: 0,
                            emphasis: {
                                focus: 'series'
                            },
                            label: {
                                show: true,
                                position: 'top'
                            },
                            data: ydata
                        },
                    ]
                };
                myChart1.setOption(option);
                window.addEventListener("resize", function () {
                    myChart1.resize();
                });
            }
        }
    }
</script>

<style scoped>

</style>
