<template>
  <div class="bg">
    <daohanglan></daohanglan>
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div id="pageList">
          <div class="addActBtCategory">
            <!-- 筛选条件 -->
            <div class="catleft">
              <span class="title-left">模型类别</span>
            </div>
            <div
              style="
                display: flex;
                justify-content: flex-end;
                z-index: 999999999;
              "
            >
              <el-button type="primary" @click="addModel"
                ><i class="el-icon-add-location"></i><span>新增模型</span>
              </el-button>
            </div>
          </div>
        </div>
        <div class="card-content">
          <div class="org-tree" style="">
            <el-input v-model="filterText" placeholder="输入关键字过滤" />
            <el-tree
              ref="treeRef"
              :data="typedata"
              show-checkbox
              default-expand-all
              node-key="categoryId"
              :check-strictly="true"
              highlight-current
              :props="defaultProps"
              :filter-node-method="filterNode"
              @check="treeChange"
            />
          </div>
          <div class="model-table">
            <!-- 列表区域 -->
            <el-table
              :data="tableData"
              border
              size="large"
              style="height: 98%"
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            >
              <template>
                <el-empty :image-size="100" description="暂无数据"></el-empty>
              </template>
              <el-table-column prop="modelName" label="模型名称" width="240">
                <template #default="scope">
                  <el-tag>{{ scope.row.modelName }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="categoryName"
                label="模型类别"
                width="150"
              />
              <el-table-column prop="tenantId" label="所属单位" width="350">
                <template #default="scope">
                  {{ scope.row.tenantId.split("_")[1] }}
                </template>
              </el-table-column>
              <el-table-column prop="version" label="模型版本" width="80">
                <template #default="scope"> V{{ scope.row.version }} </template>
              </el-table-column>
              <el-table-column prop="revision" label="修订版本" width="80">
                <template #default="scope">
                  V{{ scope.row.revision }}
                </template>
              </el-table-column>
              <el-table-column
                prop="operationActivity"
                label="创建者名"
                width="100"
              >
                <template #default="scope"
                  >{{ scope.row.creatorName }}
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="120">
                <template #default="scope">
                  <div style="display: flex; align-items: center">
                    <span></span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button size="small" @click="editModel(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="success"
                    plain
                    @click="deployModel(scope.row)"
                    >部署</el-button
                  >
                  <el-button
                    size="small"
                    type="success"
                    plain
                    @click="drawModel(scope.row)"
                    >流程设计</el-button
                  >
                  <el-button
                    size="small"
                    type="danger"
                    plain
                    @click="deleteModel(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <div
              style="display: flex; justify-content: flex-end; margin-top: 8px"
            >
              <el-pagination
                background
                layout="prev, pager, next"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 新增模型|编辑模型弹框 -->
      <el-dialog
        :visible.sync="dialogModelVisible"
        @close="beforeCloseDialog"
        :show-close="false"
      >
        <template #header="{ close, titleId, titleClass }">
          <div class="my-header">
            <h4 :id="titleId" :class="titleClass">{{ title }}</h4>
            <div>
              <el-button @click="close" link>
                <span class="iconfont icon-guanbi"> </span>
              </el-button>
            </div>
          </div>
        </template>
        <el-form :model="form" :rules="rules" ref="ruleFormRef">
          <el-form-item
            label="模型名称"
            prop="actReModelName"
            label-width="100px"
          >
            <el-input v-model="form.actReModelName" />
          </el-form-item>
          <el-form-item label="模型编码" prop="processKey" label-width="100px">
            <el-input v-model="form.processKey" />
          </el-form-item>
          <el-form-item
            label="模型类别"
            prop="categoryName"
            label-width="100px"
          >
            <el-cascader
              v-model="form.categoryName"
              :options="typedata"
              :props="props"
              class="cascader"
              placeholder="请选择所属类别"
              @change="getForm"
            />
          </el-form-item>
          <el-form-item label="使用表单" prop="generatorId" label-width="100px">
            <el-select v-model="form.generatorId" placeholder="选择对应表单">
              <el-option
                v-for="item in formOptions"
                :key="item.generatorId"
                :label="item.formDescription"
                :value="item.generatorId"
              />
            </el-select>
          </el-form-item>
          <!-- <el-form-item label="所属单位" prop="tenantId" label-width="100px">
                        <el-cascader @change="unitChange" ref="unitRef" v-model="form.tenantId" :show-all-levels="false"
                            :options="organizationOptions" :props="props2" class="cascader" placeholder="请选择所属单位">
                        </el-cascader>
                    </el-form-item> -->
          <el-form-item label="描述信息" prop="metaInfo" label-width="100px">
            <el-input
              v-model="form.metaInfo"
              :rows="3"
              type="textarea"
              placeholder="请输入模型描述信息"
            />
          </el-form-item>
        </el-form>
        <template #footer>
          <span class="dialog-footer">
            <el-button @click="dialogModelVisible = false">取消</el-button>
            <el-button type="primary" @click="saveForm()"> 确定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import promeau from "./proMeau.vue";
import daohanglan from "../../daohanglan.vue";
export default {
  name: "modelmanage",
  data() {
    return {
      typedata: [],
      tableData: [],
      queryPage: {
        modelName: "",
        pageNum: 1,
        pageSize: 10,
        tenantId: "",
        tenantIds: [],
        categoryId: "",
      },
      total: 0,
      title: "",
      dialogModelVisible: false,
      form: {
        actReModelName: "",
        processKey: "",
        categoryName: "",
        metaInfo: "",
        tenantId: "BTKS",
        tenantIds: ["BTKS"],
        actReModelId: "",
        generatorId: "",
      },
      categoryOptions: [],
      props: {
        label: "categoryName",
        value: "categoryId",
        checkStrictly: true,
      },
      filterText: "",
      organizationOptions: [],
      props2: {
        checkStrictly: true,
        multiple: true,
      },
      formOptions: [],
      orgData: [],
      rules: {
        actReModelName: [
          { required: true, message: "请输入模型名称", trigger: "blur" },
        ],
        processKey: [
          { required: true, message: "请输入模型编码", trigger: "blur" },
        ],
        categoryName: [
          { required: true, message: "请选择模型类别", trigger: "change" },
        ],
        generatorId: [
          { required: true, message: "请选择表单类别", trigger: "change" },
        ],
        metaInfo: [
          { required: true, message: "请输入模型描述信息", trigger: "blur" },
        ],
        tenantId: [
          { required: true, message: "请选择所属单位", trigger: "change" },
        ],
      },
      defaultProps: {
        children: "children",
        label: "categoryName",
      },
    };
  },
  created() {},
  methods: {
    async getModel() {
      const { data: res } = await this.$http2.post(
        "/extension/act-re-model/query/ProcessModelPageList",
        this.queryPage
      );
      if (res.businessCode == 3000) {
        this.tableData = res.data.list;
        this.total = res.data.total;
      }
    },
    async getTree() {
      const { data: res } = await this.$http2.get(
        "/extension/act-bt-category/query/ActBtCategoryTree"
      );
      if (res.businessCode == 3000) {
        this.typedata = res.data;
      }
    },
    treeChange(data, checked, node) {
      if (checked.checkedKeys.length !== 0) {
        this.$refs.treeRef.setCheckedNodes([data]);
        this.queryPage.categoryId = data.categoryId;
      } else {
        this.queryPage.categoryId = "";
        this.$refs.treeRef.setCheckedNodes([], false);
      }
      this.getModel();
    },
    addModel() {
      this.title = "新增模型";
      this.props2.multiple = true;
      this.dialogModelVisible = true;
    },
    async editModel(row) {
      this.title = "编辑模型";
      this.props2.multiple = false;
      const { data: res } = await this.$http2.get(
        "/extension/generator-form/query/BusinessTableDeployedByCategoryId?categoryId=" +
          row.category
      );
      if (res.code == 200) {
        this.formOptions = res.data;
      }
      this.form.actReModelName = row.modelName;
      this.form.processKey = row.modelKey;
      this.form.categoryName = row.category;
      this.form.metaInfo = JSON.parse(row.metaInfo).modelDescriptio;
      this.form.tenantId = row.tenantId;
      this.form.generatorId = row.generatorId;
      this.form.actReModelId = row.modelId;
      this.dialogModelVisible = true;
    },
    async saveForm() {
      if (this.title == "新增模型") {
        const { data: res } = await this.$http2.post(
          "/extension/act-re-model/insert/ProcessModel",
          this.form
        );
        if (res.businessCode == 4000) {
          this.$message.success("保存成功！");
          this.dialogModelVisible = false;
          this.getModel();
        } else {
          this.$message.error("保存失败！");
        }
      } else {
        const { data: res2 } = await this.$http2.put(
          "/extension/act-re-model/update/ProcessModel",
          this.form
        );
        if (res2.businessCode == 4300) {
          this.$message.success("保存成功！");
          this.dialogModelVisible = false;
          this.getModel();
        } else {
          this.$message.error("保存失败！");
        }
      }
    },
    async getForm() {
      this.form.categoryName =
        this.form.categoryName[this.form.categoryName.length - 1];
      // const { data: res } = await this.$http2.get(
      //   "/extension/generator-form/query/BusinessTableDeployedByCategoryId?categoryId=" +
      //     this.form.categoryName
      // );
      // if (res.code == 200) {
      //   this.formOptions = res.data;
      // }
      const { data: res } = await this.$http9.get(
        "/stencils/query/BusinessTableDeployedByCategoryId?categoryId=" +
          this.form.categoryName
      );
      if (res.code == 200) {
        this.formOptions = res.data;
      }
    },
    unitChange(res) {
      this.form.tenantIds = [];
      for (let i in res) {
        this.form.tenantIds.push(res[i][res[i].length - 1]);
      }
      this.form.tenantId = "ssssss";
      //  = res[res.length - 1]
    },
    beforeCloseDialog() {
      this.form = {
        actReModelName: "",
        processKey: "",
        categoryName: "",
        metaInfo: "",
        tenantId: "BTKS",
        tenantIds: ["BTKS"],
        actReModelId: "",
        generatorId: "",
      };
    },
    filterNode(value, data) {
      if (!value) return true;
      return data.categoryName.indexOf(value) !== -1;
    },
    async deployModel(row) {
      const { data: res } = await this.$http2.put(
        "/extension/act-re-model/deploy/ProcessModelXMLInformationByModelId/" +
          row.modelId
      );
      if (res.businessCode == 4300) {
        this.$message.success("部署成功");
        this.getModel();
      } else {
        this.$message.error("部署失败");
      }
    },
    drawModel(row) {
      this.$router.push({ path: "/drawPro", query: { modelId: row.modelId } });
    },
    deleteModel(row) {
      this.$confirm(
        "此操作将永久删除模型【" + row.modelName + "】, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      )
        .then(async () => {
          const { data: res } = await this.$http2.delete(
            "/extension/act-re-model/delete/ProcessModel/" + row.modelId
          );
          if (res.businessCode == 5000) {
            this.$message.success("删除成功！");
            this.getModel();
          } else {
            this.$message.error("删除失败！");
          }
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    handleCurrentChange(val) {
            this.queryPage.pageNum = val;
            this.getModel();
        },
    handleSizeChange(val) {
            this.queryPage.pageSize = val;
            this.getModel();
    },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },
  watch: {
    filterText(val) {
      this.$refs.treeRef.filter(val);
    },
  },
  components: {
    promeau,
    daohanglan,
  },
  created() {
    this.getTree();
    this.getModel();
  },
};
</script>

<style scoped lang="less">
.content {
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(92vh);
    padding-top: 20px;
  }

  .addActBtCategory {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .catleft {
      margin-top: 10px;
    }
  }

  .title-left {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    width: 140px;
    height: 22px;
    border-left: 5px solid #569ee3;
    text-align: left;
  }
}
.card-content {
  display: flex;
  justify-content: space-between;
  height: 91%;

  .org-tree {
    width: 15%;
    height: 97.8%;
    overflow: auto;
    border: 1px solid #ebeef5;
  }

  .model-table {
    width: 85%;
    height: 100%;

    .pagination-wrapper {
      margin-top: 5px !important;
    }
  }
}
</style>
