  export default {
      convertUTCTimeToLocalTime(UTCDateString){
      if (!UTCDateString) {
        return "-";
      }
      function formatFunc(str) {
        //格式化显示
        return str > 9 ? str : "0" + str;
      }
      const date2 = new Date(UTCDateString); //这步是关键
      const year = date2.getFullYear();
      const mon = formatFunc(date2.getMonth() + 1);
      const day = formatFunc(date2.getDate());
      const hour = date2.getHours();
      const min = formatFunc(date2.getMinutes());
      const miao = formatFunc(date2.getSeconds());
      const dateStr =
        year + "-" + mon + "-" + day + " " + hour + ":" + min + ":" + miao;
      return dateStr;
    }
}
  