<template>
    <div class="bg">
        <hou-left></hou-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" style="text-align: right">
                    <el-button size="medium" type="goon"  @click="dialogVisible = true"   v-has="'addunit'">创建单位</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            style="width: 100%;">
                        <el-table-column
                                type="selection"
                        >
                        </el-table-column>
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50"
                        >
                            <template v-slot="scope">
                                {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="unitName"
                                label="单位名称"
                                min-width="30%"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="unitAddress"
                                label="单位地址"
                                min-width="45%"
                        >
                        </el-table-column>
                <!--        <el-table-column
                                label="所属师级"
                                min-width="25%"
                        >
                            <template  v-slot="scope">
                                {{scope.row.cityName}}{{scope.row.countyName}}
                            </template>
                        </el-table-column>-->
                        <el-table-column
                                label="操作"
                                min-width="13%"
                        >
                            <template v-slot="scope">
                              <!--  <el-tooltip effect="dark" content="修改单位" placement="top" :enterable="false" v-has="'editunit'">
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
                                </el-tooltip>-->
                                <el-tooltip effect="dark" content="删除单位" placement="top" :enterable="false" v-has="'delunit'">
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)"  ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>

                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
            <el-dialog
                    title="添加单位"
                    :visible.sync="dialogVisible"
                    width="25%"
                    center
                    :close-on-click-modal="false"
                    @close="dialogClose"
            >
                <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addFormRule">
                    <el-form-item label="单位名称" prop="unitName">
                        <el-input size="medium" v-model="addForm.unitName"></el-input>
                    </el-form-item>
                    <el-form-item label="单位地址" prop="unitAddress">
                        <el-input size="medium" v-model="addForm.unitAddress"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button size="medium" type="goon" round @click="dialogVisible = false">取 消</el-button>
    <el-button size="medium" type="goon" round @click="addDanwei" >确 定</el-button>
  </span>
            </el-dialog>
            <el-dialog
                    title="修改单位信息"
                    :visible.sync="editdialogVisible"
                    width="25%"
                    center
                    :close-on-click-modal="false"
                    @close="editdialogClose"
            >
                <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="editFormRules">
                    <el-form-item label="单位名称" prop="unitName">
                        <el-input size="medium" v-model="editForm.unitName"></el-input>
                    </el-form-item>
                    <el-form-item label="单位地址" prop="unitAddress">
                        <el-input size="medium" v-model="editForm.unitAddress"></el-input>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
    <el-button type="goon" size="medium" round @click="editdialogVisible = false">取 消</el-button>
    <el-button type="goon" size="medium" round @click="editDanweiInfo">确 定</el-button>
  </span>
            </el-dialog>
        </div>
    </div>
</template>

<script>
    import houLeft from "./houTaiLeft";
    export default {
        name: 'danweiList',
        data(){
            return{
                value:[],
                city:null,
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                total:10,
                tableData:[],
                dialogVisible:false,
                addForm:{
                    unitName:'',
                    unitAddress:'',
                },
                addFormRule:{
                    unitName:[{required:true,message:'请输入单位名',trigger:'blur'}],
                },
                editdialogVisible:false,
                editForm:{
                },
                editFormRules:{
                    unitName:[{required:true,message:'请输入单位名',trigger:'blur'}],
                   // cityName:[{required:true,message:'请选择所属师',trigger:'blur'}],
                },
                optionProps:{
                    checkStrictly: true,
                    value: 'id',
                    label: 'name',
                    children: 'children'
                },
            }
        },
        created () {
            this.getDanweiList();
        },
        methods:{
            async getDanweiList(){
                const {data:res}=await  this.$http.get('/v1.0/unit/findPages',{
                    params:this.queryInfo
                })
                if(res.status!=="3000"){
                    this.$message.error('获取单位列表失败！' +res.message);
                    return this.tableData=[];
                }
                this.tableData=res.data.result;
                this.total=res.data.totalCount
            },
            async addDanwei(){
                this.$refs.addFormRef.validate( async valid =>{
                    if(!valid)
                        return
                    const {data:res}= await this.$http.post('/v1.0/unit/save', this.addForm)
                    if(res.status!=="6000"){
                        return  this.$message.error('添加单位失败！')
                    }
                    this.$message.success('添加单位成功！');
                    this.getDanweiList();
                    this.dialogVisible = false;
                })
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            async editDialog(id){
                //请求查询
                const {data:res}=await  this.$http.get('/v1.0/unit/findById/'+id);
                if(res.status!=="3000"){
                    return 0;
                }
                this.editForm=res.data;
                this.editdialogVisible = true
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            editDanweiInfo(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    const {data:res}= await this.$http.post('/v1.0/unit/update', this.editForm)
                    if(res.status!=="6000"){
                        return  this.$message.error('修改单位失败！')
                    }
                    this.$message.success('修改单位成功！');
                    this.editdialogVisible = false;
                    this.getDanweiList();
                })
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该单位, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http.delete('/v1.0/unit/delete/'+id)
                if(res.status!="4000"){
                    return  this.$message.error('删除单位失败！')
                }
                this.$message.success('删除单位成功！');
                this.getDanweiList();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getDanweiList()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getDanweiList()
            },
        },
        components:{
            houLeft
        }
    }
</script>

<style scoped lang="less">

    /*#danweiList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }*/

</style>
