<template>
    <div class="cont">
      <div class="leftWord" id='cediv'>
        <!-- <iframe  ref="iframe"  id='ffff'   scrolling="auto"></iframe> -->
      </div>
      <div class="rightop">
        <el-button type="primary" icon="el-icon-search">预览</el-button>
        <el-button type="primary" icon="el-icon-search" @click="saveForm">保存</el-button>
      </div>
      <div class="rightFrom">
           <h1>表格配置---{{yuansu}}</h1>
           <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane label="类型" name="first">
              <div class="typeDiv"  :class="{ active: typehtml == 1 }">输入框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 2 }" @click="changeSelect">下拉选择框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 3 }">多选框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 4 }">单选框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 5 }">日期选择框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 6 }">签章</div>
              <div id='ssss'></div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="second">
              <el-form  label-width="140px">
                <el-form-item label="默认值">
                  <el-input v-model="morenzhi"></el-input>
                </el-form-item>
                <el-form-item label="数据关联">
                </el-form-item>
                <el-form-item label="空值提示">
                </el-form-item>
                <el-form-item label="必填">
                  <el-switch v-model="bitian"></el-switch>
                </el-form-item>
                <el-form-item label="标签">
                </el-form-item>
                <el-form-item label="标签序号">
                </el-form-item>
                <el-form-item label="标签值">
                </el-form-item>
                <el-form-item label="是否多选">
                </el-form-item>
                <el-form-item label="多选个数限制">
                  <el-input v-model="duoxuangeshu"></el-input>
                </el-form-item>
                <el-form-item label="开启筛选">
                  <el-input type="textarea" v-model="shaixuan"></el-input>
                </el-form-item>
                <el-form-item label="填充数据">
                  <el-button type="primary" @click="tianchong">配置</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="样式" name="third">角色管理</el-tab-pane>
          </el-tabs>
      </div>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisibleTianchong"
        width="30%"
        >
        <el-form :model="tianchongform" label-position='left'>
        <el-form-item label="数据类型" label-width="120px">
            静态数据
        </el-form-item>
        <el-form-item label="值" label-width="120px">
          <el-input type="textarea" v-model="tianchongform.value"></el-input>
          <p>参考格式：["张三","李四","王五"]</p>
        </el-form-item>
      </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTianchong = false">取 消</el-button>
          <el-button type="primary" @click="istianchong">确 定</el-button>
        </span>
    </el-dialog>
    </div>
   
  </template>
  <script>
  import Vue from 'vue'
   
  const inputCom = Vue.component('input-component', {
    data() {
      return {
        inputvalue: "",
      }
    },
    template: '<el-input v-model="inputvalue"></el-input>'
  })
  const selectCom = Vue.component('select-component', {
    data() {
      return {
        options: [{
        }],
        selectvalue: "",
      }
    },
    template: '<el-select v-model="selectvalue"><el-option v-for="item in options" :key="item.value"   :label="item.label"  :value="item.value"></el-option></el-select>'
  })
  const radioCom = Vue.component('radio-component', {
    data() {
      return {
        radio: "",
        word1:'',
        word2:''
      }
    },
    template: '<el-radio v-model="radio" label="1">{{word1}}</el-radio><el-radio v-model="radio" label="2">{{word2}}</el-radio>'
  })
  let commNew;
  export default {
    data() {
      return {
        iframeSrc: 'static/ce.html',
        yuansu:'',
        shouzhiForm:'',
        activeName:'first',
        typehtml:1,
        selectedValue:'',
        comList:[],
        morenzhi:'',
        bitian:'',
        duoxuangeshu:'',
        shaixuan:"",
        index:'',
        dialogVisibleTianchong:false,
        tianchongform:{
          value:''
        },
        optobj:[]
      }
    },
    methods:{
        async getinfo(){
          const {data:res2}=await  this.$http9.get('/stencils/findById?id=1727633906096242688');
          this.shouzhiForm=res2.data.htmls.htmlBody
          //const iframe = document.getElementById('iframeBox')
          let  ce=document.getElementById('cediv')
          ce.innerHTML = this.shouzhiForm;
          this.comList=res2.data.componentList
          var spans=document.querySelectorAll(".s")
          for(let i in res2.data.componentList){
              let div= document.createElement("div");
              div.className='input-div'
              div.id=spans[i].id
              div.addEventListener('click', ()=> {
                this.yuansu=spans[i].id
              });
              console.log(res2.data.componentList[i].type)
              if(res2.data.componentList[i].type=='input'){
                let inputcom=Vue.extend({
                  extends:inputCom,
                })
                let inputel= new inputcom().$mount()
                div.appendChild(inputel.$el);
              }else if(res2.data.componentList[i].type=='select'){
                var selecttcom=Vue.extend({
                  extends:selectCom,
                })
                let commSel=new selecttcom()
                let selectel= commSel.$mount() 
                div.appendChild(selectel.$el);
                let zhidata=res2.data.componentList[i].optionalValue.split(',')
                let optobj=[]
                for(let i in zhidata){
                  optobj.push({
                    label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')
                  })
                }
                this.optobj=optobj
                commSel.$set(commSel, 'options', optobj)
              }
              if(spans[i].parentNode){
                spans[i].parentNode.replaceChild(div, spans[i]);
              }
          }
          // iframe.height = document.documentElement.clientHeight;
        //  this.$refs.iframe.contentWindow.document.write(this.shouzhiForm)
        //  var spans=this.$refs.iframe.contentWindow.document.querySelectorAll(".s")
        //  for(let i in spans){
        //   var div= document.createElement("div");
        //   div.className='input-div'
        //   var input = document.createElement("input");
        //   input.id=spans[i].id
        //   input.type = "text";
        //   input.addEventListener('click', ()=> {
        //     this.yuansu=spans[i].id
        //   });
        //   div.appendChild(input);
        //   if(spans[i].parentNode){
        //     spans[i].parentNode.replaceChild(div, spans[i]);
        //   }
        //  }
          
        },
        handleClick(tab, event){
          console.log(tab, event)
        },
        changeSelect(){
          this.typehtml=2;
          // 创建一个el-select元素
          // var selectElement = document.createElement("select");
 
          // // 添加选项
          // var option1 = document.createElement("option");
          // option1.text = "选项1";
          // selectElement.add(option1);
            
          // var option2 = document.createElement("option");
          // option2.text = "选项2";
          // selectElement.add(option2);
          // // let Vx=this.$refs.iframe.contentWindow.document.getElementById(this.yuansu)
          // // 将el-select元素添加到页面中
          // Vx.parentNode.replaceChild(selectElement, Vx);
          // var Profile = Vue.extend({
          //   template: '<el-select></el-select>',
          //   data: function () {
          //     return {
          //       firstName: 'Walter',
          //       lastName: 'White',
          //       alias: 'Heisenberg'
          //     }
          //   }
          // })
          // new Profile().$mount('#ssss')
          // var selectElement = document.createElement('el-select');
          // // 添加选项
          // let Vx=document.getElementById('ssss')
          // console.log(Vx)
          // Vx.parentNode.replaceChild(selectElement, Vx);
          // let Vx=document.getElementById('ssss')
          // console.log(inputEle)
          // Vx.appendChild(inputEle.$el)
          this.index=Number(this.yuansu.substring(1))-1
          console.log(this.comList[this.index])
          this.comList[this.index].type='select'
          let comm=Vue.extend({
             extends:selectCom,
          })
          commNew=new comm()
          let inputel= commNew.$mount()
          let Vx=document.getElementById(this.yuansu)
          Vx.parentNode.replaceChild(inputel.$el, Vx);
        },
        tianchong(){
          this.dialogVisibleTianchong=true
        },
        istianchong(){
          let zhidata=this.tianchongform.value.substring(1,this.tianchongform.value.length-1).split(',')
          let optobj=[],optdata=[]
          for(let i in zhidata){
            optobj.push({ label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')})
            optdata.push(zhidata[i].replace(/"/g, ''))
          }
          commNew.$set(commNew, 'options', optobj)
          this.$message.success('保存成功！')
          this.dialogVisibleTianchong=false
          this.comList[this.index].optionalValue=this.tianchongform.value.substring(1,this.tianchongform.value.length-1)
          let stylejson=JSON.parse(this.comList[this.index].style)
          stylejson.config.select.options.data=optdata
          this.comList[this.index].style=JSON.stringify(stylejson)
        },
        async saveForm(){
          const {data:res}=await  this.$http9.post('/stencils/insert/BatchComponents',{
            components:this.comList,
            stencilsId:'1727633906096242688'
          });
        }
    },
    created(){
         this.getinfo()
    }
  }
  </script>
  <style scoped lang='less'>
  .cont {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
  }
  .leftWord{
    width: 67%;
    height: 100%;
    text-align: center;
    background-color: #f5f5f5;
    font-size: 16px;
  }
  .rightFrom{
    width: 30%;
    height: 100%;
    background-color: #fff;
    padding:0 1%;
    h1{
      margin-top: 10px;
      width: 100%;
      text-align: center;
    }
    /deep/ .el-tabs__item{
      font-size: 18px;
    }
    .typeDiv{
      line-height: 48px;
      cursor: pointer;
      color: #000;
      font-size: 18px;
    }
    .active{
      background: #66b1ff;
      padding-left: 16px;
      font-weight: 700;
      border-radius: 4px;
      color: #fff;
    }
  }
 .rightop{
  position:absolute;
  left: 20px;
  display: flex;
 }
  </style>