<template>
    <el-menu :default-active="$route.path" class="el-meau" :router="true">
        <el-submenu index="/modelManage">
            <template slot="title">
                <i class="el-icon-location"></i>
                <span>流程管理</span>
            </template>
            <el-menu-item index="/modelManage"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>模型管理</span>
                </template></el-menu-item>
            <el-menu-item index="/modelType"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>模型类别</span>
                </template></el-menu-item>
            <el-menu-item index="/deployManage"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>部署管理</span>
                </template></el-menu-item>
            <el-menu-item index="/processDefinition"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>流程定义</span>
                </template></el-menu-item>
            <el-menu-item index="/processExample"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>流程实例</span>
                </template></el-menu-item>
            <el-menu-item index="/processTask"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>流程任务</span>
                </template></el-menu-item>
            <el-menu-item index="/historyProcess"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>历史流程</span>
                </template></el-menu-item>
            <el-menu-item index="/historyTask"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>历史任务</span>
                </template></el-menu-item>
        </el-submenu>
         <el-submenu index="2">
            <template slot="title">
                <i class="el-icon-s-data"></i>
                <span>办公管理</span>
            </template>
            <span slot="title"></span>
            <el-menu-item index="/initiationProcess"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>发起流程</span>
                </template></el-menu-item>
            <el-menu-item index="/myProcess"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>我的流程</span>
                </template></el-menu-item>
            <el-menu-item index="/todoProcess"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>待办流程</span>
                </template></el-menu-item>
            <el-menu-item index="/doneProcess"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>已办任务</span>
                </template></el-menu-item>
        </el-submenu>
        <el-submenu index="/formHome">
            <template slot="title">
                <i class="el-icon-s-data"></i>
                <span>业务表单管理</span>
            </template>
            <el-menu-item index="/wordDictionary"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>业务字典管理</span>
                </template></el-menu-item>
            <el-menu-item index="/formHome"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>业务表单管理</span>
                </template></el-menu-item>
            <el-menu-item index="/formCreate"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>业务表单设计</span>
                </template></el-menu-item>
            <el-menu-item index="/wordList"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>业务报表列表</span>
            </template></el-menu-item>
            <el-menu-item index="/wordCreate"> <template slot="title">
                    <i class="el-icon-location"></i>
                    <span>业务报表设计</span>
            </template></el-menu-item>
        </el-submenu>
    </el-menu>
</template>

<script>
export default{
    data() {
        return {
            defaultactive: window.sessionStorage.getItem('defaultactive2'),
        }
    },
    methods: {
        goModelMan() {
            window.sessionStorage.setItem('defaultactive', '1-1');
            this.$router.push({ path: "/modelManage" });
        },
        goModelType() {
            console.log(123)
            window.sessionStorage.setItem('defaultactive', '1-2');
            this.$router.push({ path: "/modelType" });
        },
        goRole() {
            window.sessionStorage.setItem('defaultactive', '2');
            this.$router.push({ path: "/roleManage" });
        },
        goAuth() {
            window.sessionStorage.setItem('defaultactive', '3');
            this.$router.push({ path: "/authManage" });
        },
        goLog() {
            window.sessionStorage.setItem('defaultactive', '4');
            this.$router.push({ path: "/logManage" });
        },
        goHelp() {
            window.sessionStorage.setItem('defaultactive', '5');
            this.$router.push({ path: "/helpManage" });
        },
    },
    created(){
        console.log(window.sessionStorage.getItem('defaultactive2'))
        this.defaultactive=window.sessionStorage.getItem('defaultactive2')
    },
    mounted() {
    },
}

</script>

<style scoped lang="less">
.el-meau{
    
    height: 100%;
    z-index: 999999;
   /deep/ .el-submenu__title{
    font-size: 18px !important;
    color: #000;
    font-weight: 555;
   }
    /deep/ .el-menu-item{
        font-size: 16px !important;
    }

}

</style>