<template>
    <div class="bg">
        <!-- <process-left></process-left> -->
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
        <el-card class="box-card"  style="height: 100%;">
            <div slot="header" class="clearfix" >
                <el-button size="small"  icon="el-icon-delete"  @click="importPro" >导入进度</el-button>
                <el-button size="small"  icon="el-icon-delete"  @click="deletePlan" >删除计划</el-button>
                <el-button size="small"  icon="el-icon-star-on" style="margin-left: 10px"   @click="lichengbei" >设为里程碑</el-button>
                <el-button size="small"  icon="el-icon-star-off" style="margin-left: 10px"   @click="noelichengbei" >取消里程碑</el-button>
                <el-button size="small"  icon="el-icon-search" style="margin-left: 10px"   @click="anri">按日显示</el-button>
                <el-button size="small"  icon="el-icon-search" @click="anyue">按月份显示</el-button>
                <el-button size="small"  icon="el-icon-search" @click="annian">按年份显示</el-button>
                <el-button size="small"  icon="el-icon-s-promotion" @click="exportPdf">导出pdf</el-button>
                <el-button size="small"  icon="el-icon-s-promotion" @click="exportExcel">模板下载</el-button>
                <el-button size="small"  icon="el-icon-paperclip"  @click="tongbu">同步实施计划</el-button>
            </div>
            <div ref="gantt" class="left-container"/>
        </el-card>
        </div>
        <el-dialog
                title="添加任务"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="125px" :rules="addFormRules">
                <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model="addForm.taskName"></el-input>
                </el-form-item>
               <!-- <el-form-item label="任务标识号码" prop="serialNum">
                    <el-input v-model="addForm.serialNum" onkeyup="value=value.replace(/[^\d]/g,'')"  placeholder="请输入数字"></el-input>
                </el-form-item>-->
                <el-form-item label="计划开始时间" prop="startDate">
                    <el-date-picker
                            v-model="addForm.startDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="计划完成时间" prop="endDate">
                    <el-date-picker
                            v-model="addForm.endDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
              <!--  <el-form-item label="是否是里程碑">
                    <el-select v-model="addForm.isMileStone" placeholder="请选择">
                        <el-option
                                v-for="item in qianzhioptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item label="是否有前置任务" prop="isFrontTask">
                    <el-select v-model="addForm.isFrontTask" placeholder="请选择" @change="qianzhiChange">
                        <el-option
                                v-for="item in qianzhioptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="前置任务标识码" v-show="qianzhiShow">
                    <el-input v-model="addForm.frontSerialNum"></el-input>
                </el-form-item>
                <el-form-item label="任务类型">
                    <el-select v-model="addForm.configType" placeholder="请选择">
                        <el-option
                                v-for="item in taskTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="责任人">
                    <el-cascader
                            :options="yonghuOptions"
                            :props="yonghuprops"
                            ref="cascaderZeren"
                            v-model="addForm.principalId"
                            collapse-tags
                            @change="zerenChange"
                            clearable></el-cascader>
                </el-form-item>
               <!-- <el-form-item label="审批人">
                    <el-cascader
                            :options="yonghuOptions"
                            :props="yonghuprops"
                            ref="cascaderShenpi"
                            v-model="addForm.approveId"
                            collapse-tags
                            @change="shenpiChange"
                            clearable></el-cascader>
                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addTask" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改任务"
                :visible.sync="dialogVisibleEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="editForm" label-width="125px" :rules="addFormRules">
                <el-form-item label="任务名称" prop="taskName">
                    <el-input v-model="editForm.taskName"></el-input>
                </el-form-item>
                <!--<el-form-item label="任务标识号码" prop="serialNum">
                    <el-input v-model="editForm.serialNum" onkeyup="value=value.replace(/[^\d]/g,'')"  placeholder="请输入数字"></el-input>
                </el-form-item>-->
                <el-form-item label="计划开始时间" prop="startDate">
                    <el-date-picker
                            v-model="editForm.startDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="计划完成时间" prop="endDate">
                    <el-date-picker
                            v-model="editForm.endDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
              <!--  <el-form-item label="是否是里程碑">
                    <el-select v-model="editForm.isMileStone" placeholder="请选择">
                        <el-option
                                v-for="item in qianzhioptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>-->
                <el-form-item label="是否有前置任务" prop="isFrontTask">
                    <el-select v-model="editForm.isFrontTask" placeholder="请选择" @change="qianzhiChange">
                        <el-option
                                v-for="item in qianzhioptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="前置任务标识码" v-show="qianzhiShow">
                    <el-input v-model="editForm.frontSerialNum"></el-input>
                </el-form-item>
                <el-form-item label="任务类型">
                    <el-select v-model="editForm.configType" placeholder="请选择">
                        <el-option
                                v-for="item in taskTypeOptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="责任人">
                    <el-cascader
                            :options="yonghuOptions"
                            :props="yonghuprops"
                            ref="cascaderZeren"
                            v-model="editForm.principalId"
                            collapse-tags
                            @change="zerenChangeEdit"
                            clearable></el-cascader>
                </el-form-item>
        <!--        <el-form-item label="审批人">
                    <el-cascader
                            :options="yonghuOptions"
                            :props="yonghuprops"
                            ref="cascaderShenpi"
                            v-model="editForm.approveId"
                            collapse-tags
                            @change="shenpiChangeEdit"
                            clearable></el-cascader>
                </el-form-item>-->
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editTask" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog :visible.sync="writeForm" title="进度导入" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" >
                <el-form-item label="附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                              </el-upload>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel">保 存</el-button>
                              </span>
                  </template>
        </el-dialog>
  </div>
</template>

<script>
    import processLeft from './processLeft'
    import gantt from 'dhtmlx-gantt'  // 引入模块
    //import 'dhtmlx-gantt/codebase/dhtmlxgantt.css'
    import 'dhtmlx-gantt/codebase/skins/dhtmlxgantt_terrace.css' //皮肤
    // import 'dhtmlx-gantt/codebase/export'
    export default {
        name: "planproces",
        data() {
            return {
                tasks: {
                    data: [
                   /*     {
                            id: 1,
                            text: '标准化厂房项目计划',
                            start_date: '10-03-2021',
                            end_date:'10-06-2017',
                            allDate:100,
                            zeren:'小龙',
                            shenpi:'李俊',
                            progress: 0,
                            open: true,
                            //项目任务和常规任务的区别在于项目任务的工期取决于其子任务，并分别更改。
                            type:gantt.config.types.project,
                        },
                        {
                            id: 2,
                            text: '厂房',
                            start_date: '10-03-2021',
                            end_date:'10-06-2017',
                            zeren:'小龙',
                            shenpi:'李俊',
                            progress: 0,
                            allDate:100,
                            type:gantt.config.types.project,
                            open: true,
                            parent: 1
                        },
                        {
                            id: 3,
                            text: '基础工程',
                            start_date: '10-03-2021',
                            end_date:'18-03-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 9,
                            allDate:9,
                            progress: 0,
                            parent: 2
                        },
                        {
                            id: 14,
                            text: '基础工程检测',
                            start_date: '18-03-2021',
                            end_date:'19-03-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 1,
                            allDate:1,
                            progress: 0,
                            parent: 2,
                            type:gantt.config.types.milestone,
                        },
                        {
                            id: 4,
                            text: '钢结构工程',
                            start_date: '18-03-2021',
                            end_date:'05-04-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 17,
                            allDate:17,
                            progress: 0,
                            parent: 2
                        },
                        {
                            id: 5,
                            text: '砌砖与门窗',
                            start_date: '05-04-2021',
                            end_date:'27-04-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 23,
                            allDate:23,
                            progress: 0,
                            parent: 2
                        },
                        {
                            id: 6,
                            text: '水电消防及辅助设施',
                            start_date: '27-04-2021',
                            end_date:'13-05-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 16,
                            allDate:16,
                            progress: 0,
                            parent: 2
                        },
                        {
                            id: 7,
                            text: '2号厂房',
                            start_date: '10-03-2021',
                            end_date:'10-06-2017',
                            zeren:'小龙',
                            shenpi:'李俊',
                            progress: 0,
                            open: true,
                            allDate:100,
                            type:gantt.config.types.project,
                            parent: 1
                        },
                        {
                            id: 8,
                            text: '基础工程',
                            start_date: '10-03-2021',
                            end_date:'18-03-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 9,
                            allDate:9,
                            progress: 0,
                            parent: 7
                        },
                        {
                            id: 13,
                            text: '基础工程检测',
                            start_date: '18-03-2021',
                            end_date:'19-03-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 1,
                            allDate:1,
                            progress: 0,
                            parent: 7,
                            type:gantt.config.types.milestone,
                        },
                        {
                            id: 9,
                            text: '钢结构工程',
                            start_date: '19-03-2021',
                            end_date:'18-04-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 30,
                            allDate:30,
                            pprogress: 0,
                            parent: 7
                        },
                        {
                            id: 10,
                            text: '砌砖与门窗',
                            start_date: '19-04-2021',
                            end_date:'11-05-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 20,
                            allDate:20,
                            progress: 0,
                            parent: 7
                        },
                        {
                            id: 11,
                            text: '水电消防及辅助设施',
                            start_date: '11-05-2021',
                            end_date:'10-06-2021',
                            zeren:'小龙',
                            shenpi:'李俊',
                            duration: 30,
                            allDate:30,
                            progress: 0,
                            parent: 7
                        },*/
                    ],
                    links: [
                        /*{id: 1, source: 1, target: 2, type: '0'},
                        {id: 2, source: 1, target: 7, type: '0'},
                        {id: 3, source: 3, target: 4, type: '0'},
                        {id: 4, source: 8, target: 9, type: '0'}*/
                    ]
                },
                writeForm:false,
                menuTable:[],
                isZhank: false,
                dialogVisible:false,
                addForm:{
                    taskName:'',
                    duration:'',
                    startDate:'',
                    principal:'',
                    principalId:'',
                    approve:'',
                    approveId:'',
                    endDate:'',
                    isMileStone:'',
                    serialNum:null,
                    isFrontTask:'',
                    frontSerialNum:'',
                    parentId:"",
                    projectId:this.$root.proId,
                    configType:'',
                },
                qianzhiShow:false,
                qianzhioptions:[
                    {
                        value: 0,
                        label: '否'
                    }  ,
                    {
                        value: 1,
                        label: '是'
                    }
                ],
                taskTypeOptions:[
                    {
                        value: 'project',
                        label: '项目(工期取决子任务的工期)'
                    },
                    {
                        value: 'task',
                        label: '任务(工期不取决子任务的工期)'
                    },
                ],
                yonghuOptions:[],
                yonghuprops: {
                    multiple: false,
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                pickerOptions:{
                    disabledDate:(time)=> {
                       return time.getTime() < new Date(this.addForm.startDate).getTime();
                    }
                },
                addFormRules:{
                    taskName:[{required:true,message:'请输入任务名称',trigger:'blur'}],
                    startDate:[{required:true,message:'请选择开始时间',trigger:'blur'}],
                    endDate:[{required:true,message:'请选择完成时间',trigger:'blur'}],
                  //  serialNum:[{required:true,message:'请输入标识号',trigger:'blur'}],
                    isFrontTask:[{required:true,message:'请选择是否有前置任务',trigger:'blur'}],
                },
                dialogVisibleEdit:false,
                editForm:{

                },
                fileList:[],
                selectId:"",
                isLiChengbei:1
            }
        },
        methods:{
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=42')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            async uploadModel(){
                var formData = new FormData();
                for(let i in this.fileList){
                    formData.append("file", this.fileList[i].raw);
                }
                formData.append("projectId", this.$root.proId);
                const { data: res2 } = await this.$http6.post('/v1.0/schedule/planning/batchImport', formData)
                if(res2.businessCode==4000){
                    this.$message.success("提交成功");
                    this.getTaskAll();
                    this.writeForm = false;
                }else{
                this.$message.error("提交失败！");
                }
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async getTaskAll(){
                const {data:res}= await this.$http6.get('/v1.0/schedule/planning/findAll?projectId='+this.$root.proId)
                if(res.businessCode!='3000'){
                    return this.$message.error(res.message)
                }
               this.tasks.data=res.data;
                console.log(this.tasks.data)
                for(let i in this.tasks.data){
                    this.tasks.data[i].start_date=this.tasks.data[i].startDate.split("-").reverse().join("-")
                    this.tasks.data[i].end_date=this.tasks.data[i].endDate.split("-").reverse().join("-")
                    this.tasks.data[i].parent=this.tasks.data[i].parentId
                    this.tasks.data[i].open=true
                    if(this.tasks.data[i].configType=='project'){
                        this.tasks.data[i].type=gantt.config.types.project
                    }else {
                        this.tasks.data[i].type=gantt.config.types.task
                    }
                }
                gantt.clearAll();
                gantt.parse(this.tasks)
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.businessCode!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghuOptions.push(res.data[i])
                }
            },
            qianzhiChange(data){
                    this.qianzhiShow=data;
            },
            zerenChange(data){
                this.addForm.principal=this.$refs["cascaderZeren"].getCheckedNodes()[0].label
                this.addForm.principalId=this.$refs["cascaderZeren"].getCheckedNodes()[0].value
            },
            shenpiChange(data){
                this.addForm.approve=this.$refs["cascaderShenpi"].getCheckedNodes()[0].label
                this.addForm.approveId=this.$refs["cascaderShenpi"].getCheckedNodes()[0].value
            },
            zerenChangeEdit(data){
                this.editForm.principal=this.$refs["cascaderZeren"].getCheckedNodes()[0].label
                this.editForm.principalId=this.$refs["cascaderZeren"].getCheckedNodes()[0].value
            },
            shenpiChangeEdit(data){
                this.editForm.approve=this.$refs["cascaderShenpi"].getCheckedNodes()[0].label
                this.editForm.approveId=this.$refs["cascaderShenpi"].getCheckedNodes()[0].value
            },
            async addTask(){
                this.addForm.duration=(new Date(this.addForm.endDate).getTime() - new Date(this.addForm.startDate).getTime())/(1*24*60*60*1000);
                const {data:res}=await this.$http6.post('/v1.0/schedule/planning/save',this.addForm);
               if(res.businessCode!='6000'){
                   return this.$message.error('新增计划进度失败！'+res.message)
               }
                this.$message.success('新增计划进度成功！')
                this.getTaskAll();
                this.dialogVisible=false
            },
            async findByid(id){
                const {data:res}=await this.$http6.get('/v1.0/schedule/planning/findById?id='+id);
                if(res.businessCode!='3000'){
                    return this.$message.error('查询计划失败！'+res.message)
                }
                this.editForm=res.data
            },
            async editTask(){
                this.editForm.duration=(new Date(this.editForm.endDate).getTime() - new Date(this.editForm.startDate).getTime())/(1*24*60*60*1000);
                const {data:res}=await this.$http6.put('/v1.0/schedule/planning/update',this.editForm);
                if(res.businessCode!='4300'){
                    return this.$message.error('修改计划进度失败！'+res.message)
                }
                this.$message.success('修改计划进度成功！')
                this.getTaskAll();
                this.dialogVisibleEdit=false
            },
            async lichengbei(){
                this.isLiChengbei=1;
                const {data:res}= await this.$http6.put('/v1.0/schedule/planning/updateIsMileStone?id='+this.selectId+'&isMileStone='+this.isLiChengbei)
                console.log(res)
                if(res.businessCode!=6010){
                    return this.$message.error('设为里程碑失败！')
                }
                this.$message.success('设为里程碑成功！')
                this.getTaskAll();
            },
            async noelichengbei(){
                this.isLiChengbei=0;
                const {data:res}= await this.$http6.put('/v1.0/schedule/planning/updateIsMileStone?id='+this.selectId+'&isMileStone='+this.isLiChengbei)
                if(res.businessCode!=6010){
                    return this.$message.error('取消里程碑失败！')
                }
                 this.$message.success('取消里程碑成功！')
                 this.getTaskAll();
            },
            anri(){
                gantt.config.date_scale = '%Y-%m-%d'
                gantt.config.scale_unit = "day";
                gantt.init(this.$refs.gantt)
            },
            anyue(){
                gantt.config.date_scale = '%Y-%m'
                gantt.config.scale_unit = "month";
                gantt.init(this.$refs.gantt)
            },
            annian(){
                gantt.config.date_scale = '%Y'
                gantt.config.scale_unit = "year";
                gantt.init(this.$refs.gantt)
            },
            exportPdf(){
                gantt.exportToPDF()
            },
            exportPng(){
                gantt.exportToPNG()
            },
           async exportExcel(){
               // gantt.exportToExcel()
               const {data:res}= await this.$http6.post('/v1.0/schedule/planning/exportScheduleTemplate',{ },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = '进度计划';
                      elink.style.display = 'none';
                      var blob = new Blob([res],{ type:"application/vnd.ms-excel" });
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            async tongbu(){
                const {data:res}= await this.$http6.put('/v1.0/schedule/planning/releasePlanningSchedule?projectId='+this.$root.proId)
                 if(res.businessCode!='4300'){
                     return this.$message.error('同步到实施计划失败！')
                 }
                this.$message.success('同步到实施计划成功！')
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            importPro(){
                this.fileList=[]
              this.writeForm=true
            },
            async deletePlan(){
                const confirmResult = await this.$confirm('此操作将永久删除该计划节点, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const {data:res}= await this.$http6.delete('/v1.0/schedule/planning/delete/'+this.selectId)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除计划节点失败！'+res.message)
                }
                this.$message.success('删除计划节点成功！');
                this.getTaskAll();
            }
        },
        created(){
            this.getLeftMenu()
            gantt.clearAll()
            this.getTaskAll();
            //this.getUsers();
        },
        mounted() {
            // 初始化
            gantt.i18n.setLocale("cn");
            gantt.config.readonly = false
            gantt.config.date_scale = '%Y-%m-%d'
            gantt.config.autofit = true
            /*    gantt.config.autofit = true // 表格列宽自适应
                gantt.config.autoscroll = true// 自动滚动
                gantt.config.autoscroll_speed = 50 // 定义将任务或链接拖出当前浏览器屏幕时自动滚动的速度(以毫秒为单位)
                gantt.config.autosize = true // 自适应甘特图的尺寸大小, 使得在不出现滚动条的情况下, 显示全部任务
                gantt.config.readonly = true // 只读模式
                gantt.config.fit_tasks = true // 当task的长度改变时，自动调整图表坐标轴区间用于适配task的长度
                gantt.config.round_dnd_dates = true // 将任务开始时间和结束时间自动“四舍五入”, 从而对齐坐标轴刻度
                gantt.config.select_task = false // 任务是否可以点击选中

                gantt.config.smart_scales = true // 仅仅渲染在屏幕可见的那部分时间轴。在处理时间轴非常长的时候，可以提升性能

                gantt.config.smart_rendering = true // 按需渲染, 仅仅渲染在屏幕可见的那部分任务和依赖线。这个在显示大量的任务时，性能比较高。

                gantt.config.date_scale = '%Y-%m-%d' // 设置x轴的日期格式

                gantt.config.duration_unit = 'day' // 工期计算的基本单位

                gantt.config.scale_unit = 'day' // 设置时间坐标轴单位*/

// gantt.config.start_date = new Date(2020, 9, 1);

// gantt.config.end_date = new Date(2020, 9, 31);

            //   gantt.config.show_tasks_outside_timescale = true

            gantt.plugins({
                tooltip: true// 启用 tooltip 插件
            })
            gantt.config.columns = [ // 配置左边的表格项
                {
                    name: 'taskName',
                    tree:true,
                    label: '任务名',
                    width: 180
                },
                {
                    name: 'serialNum',
                    label: '标识码',
                    align:'center',
                    width: 40
                },
                {
                    name: 'isMileStone',
                    align:'center',
                    label: '里程碑',
                    width: 50,
                    template: function (task) {
                        if(task.isMileStone==1){
                            return (
                                '<i style="color: red" class="el-icon-star-on"></i>'
                            );
                        }

                    }
                },
                {
                    name: 'duration',
                    label: '工期',
                    align:'center',
                    width: 40
                },
                {
                    name: 'startDate',
                    label: '计划开始时间',
                    align:'center',
                    width: 100
                },
                {
                    name: 'endDate',
                    label: '计划完成时间',
                    align:'center',
                    width: 100
                },
                {
                    name: 'principal',
                    label: '责任人',
                    align:'center',
                    width: 60
                },
                {
                    name: 'approve',
                    label: '审批人',
                    align:'center',
                    width: 60
                },

                {name:"add",        label:"" },
            ]
            gantt.templates.tooltip_text = (start, end, task) => {
                            return "<b>任务名:</b> "+task.taskName+"<br/><b>工期:</b> " + task.duration+"<br/><b>计划开始时间:</b> " + task.startDate+"<br/><b>计划结束时间:</b> " + task.endDate;
                          //  return `${task[fields.user]}${task[fields.text]}计划开始时间 ${format(task[fields.start_date], 'YYYY-MM-DD')}计划结束时间 ${format(task[fields.end_date], 'YYYY-MM-DD')}`
            }
            gantt.templates.task_text=function(start,end,task){
                return "<b>"+task.taskName+"</b> ";
            };

            gantt.config.scale_unit = "day";
            gantt.init(this.$refs.gantt)
            gantt.clearAll()
            gantt.parse(this.tasks)
            gantt.attachEvent("onTaskCreated", (task)=>{
                console.log(task)
                if(task.parent){
                    this.addForm.parentId=task.parent
                }else {
                    this.addForm.parentId=0
                }
                this.dialogVisible=true
                return false;
            });
            gantt.attachEvent("onTaskClick", (id, e) =>{
                this.selectId=id
                return true;
            });

            gantt.attachEvent("onTaskDblClick", (id, e) =>{
                var i=0;
                if(i==0){
                    this.findByid(id)
                    this.dialogVisibleEdit=true
                }
                i++
                return false;
            });
        },
        components:{
            processLeft
        }
    }
</script>

<style scoped lang="less">

    #gante{
        display: flex;
        justify-content: space-between;
    }

    .left-container {
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 110px);
    }



    //加号图标样式
    /deep/ .gantt_add{
        opacity: 0.8;
    }

</style>
