<template>
  <div class="bg">
    <daohanglan></daohanglan>
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div id="pageList">
          <div class="addActBtCategory">
            <div class="catleft">
              <span class="title-left">报表模板管理</span>
            </div>
            <div
              style="display: flex; justify-content: flex-end; z-index: 999999"
            >
              <el-button type="primary" @click="addWord"
                ><i class="el-icon-remove"></i><span>新增报表模板</span>
              </el-button>
            </div>
          </div>
          <!-- 列表 -->
          <div>
            <el-table
              :data="tableData"
              border
              style="width: 100%; height: 100%"
              size="large"
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            >
              <template>
                <el-empty :image-size="100" description="暂无数据"></el-empty>
              </template>
              <el-table-column prop="name" label="模板名称" width="250">
              </el-table-column>
              <el-table-column
                prop="id"
                label="模板编号"
                width="250"
              />
              <el-table-column
                prop="formType"
                label="类别"
                width="220"
              />
              <el-table-column
                prop="remarks"
                label="备注"
                width="500"
              />
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="editmodel(scope.row)"
                    >编辑模板</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="formDesign(scope.row)"
                    >业务表设计</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    :disabled="scope.row.isRelease !== 1"
                    :title="
                      scope.row.isRelease !== 1
                        ? '撤销部署将删除业务表结构记录、删除普通表单和流程模型关系'
                        : '尚未部署请前往业务表设计部署'
                    "
                    @click="deployForm(scope.row)"
                    >{{
                      scope.row.isRelease === 1 ?  "撤销部署" : "尚未部署"
                    }}</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="editWord(scope.row)"
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="deleteForm(scope.row)"
                    >删除</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[10, 20, 30, 50]"
              @current-change="handleCurrentChange"
              :total="total"
            />
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="新增业务动态表单相关信息" :visible.sync="dialogFormVisible" width="30%" center>
            <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addFormRule">
                <el-form-item label="模版名称" prop="name">
                    <el-input v-model="addForm.name" placeholder="请输入模版名称" ></el-input>
                </el-form-item>
                <el-form-item label="表名称" prop="tableName">
                    <el-input v-model="addForm.tableName" placeholder="请输入表名称" ></el-input>
                </el-form-item>
                <el-form-item label="模版编码" prop="code">
                    <el-input placeholder="请输入内容" v-model="addForm.code">
                    </el-input>
                </el-form-item>
                <el-form-item label="模版类别" prop="categoryName">
                    <el-cascader v-model="addForm.categoryId" :options="categoryOptions" :props="props" class="cascader"
                        @change="changeCategoryId" placeholder="请选择所属类别" />
                </el-form-item>
                <el-form-item label="菜单类别" prop="menuId">
                  <el-cascader
                       :options="menuData"
                       v-model="addForm.menuId"
                       @change="menuChange"
                       :props="defaultProps"
                    >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="附件类别" prop="fileType">
                  <el-cascader
                       :options="filetypeData"
                       v-model="addForm.fileType"
                       @change="filetypeChange"
                       :props="filetypeProps"
                    >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input  :rows="2" type="textarea"  placeholder="请输入备注" v-model="addForm.remarks">
                    </el-input>
                </el-form-item>
                <el-form-item label="模版文件">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :on-change="filechange"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="faqiFrom">确 定</el-button>
            </span>
    </el-dialog>
    <el-dialog title="修改业务动态表单相关信息" :visible.sync="dialogFormVisibleEdit" width="30%" center>
            <el-form ref="addFormRef" :model="editForm" label-width="100px" :rules="addFormRule">
                <el-form-item label="模版名称" prop="name">
                    <el-input v-model="editForm.name" placeholder="请输入模版名称" ></el-input>
                </el-form-item>
                <el-form-item label="表名称" prop="tableName">
                    <el-input v-model="editForm.tableName" placeholder="请输入表名称" ></el-input>
                </el-form-item>
                <el-form-item label="模版编码" prop="code">
                    <el-input placeholder="请输入内容" v-model="editForm.code">
                    </el-input>
                </el-form-item>
                <el-form-item label="模版类别" prop="categoryName">
                    <el-cascader v-model="editForm.categoryId" :options="categoryOptions" :props="props" class="cascader"
                        @change="changeCategoryId" placeholder="请选择所属类别" />
                </el-form-item>
                <el-form-item label="菜单类别" prop="menuId">
                  <el-cascader
                       :options="menuData"
                       v-model="editForm.menuId"
                       @change="menuChange"
                       :props="defaultProps"
                    >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="附件类别" prop="fileType">
                  <el-cascader
                       :options="filetypeData"
                       v-model="editForm.fileType"
                       @change="filetypeChange"
                       :props="filetypeProps"
                    >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="备注" prop="remarks">
                    <el-input  :rows="2" type="textarea"  placeholder="请输入备注" v-model="addForm.remarks">
                    </el-input>
                </el-form-item>
                <el-form-item label="模版文件">
                    <el-upload
                        class="upload-demo"
                        ref="upload"
                        action=""
                        :on-change="filechange"
                        :on-remove="handleRemove"
                        :file-list="fileList"
                        :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisibleEdit = false">取 消</el-button>
                <el-button type="primary" @click="faqiFrom">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import promeau from "../proMeau.vue";
import daohanglan from "../../../daohanglan.vue";
export default {
  name: "formHome",
  data() {
    return {
      formJson: {
        nodalId: "",
        pageNum: 1,
        pageSize: 10,
      },
      tableData: [],
      total: 0,
      dialogFormVisible: false,
      dialogFormVisibleEdit:false,
      rules: {
        categoryName: [
          { required: true, message: "请输入类别名称", trigger: "blur" },
        ],
        parentId: [
          { required: true, message: "请选择所属类别", trigger: "blur" },
        ],
      },
      addForm:{
          attacheId: '1727633906096242688',
          categoryId: "act_bt_category",
          code: "",
          formType:"",
          name:"",
          nodalId:"formula_nodal",
          tableName: '',
          remarks: '',
          fileType:''
      },
      editForm:{},
      props: {
          label: "categoryName",
          value: "categoryId",
          checkStrictly: true,
      },
      fileList:[],
      addFormRule: {
            tableName: [{ required: true, message: '请输入表单名称', trigger: 'blur' }, { min: 2, max: 40, message: "长度再2-40个字符之间", trigger: 'blur' }],
            code: [{ required: true, message: '请输入模板编码', trigger: 'blur' }],
            menuId: [{ required: true, message: '请选择菜单类别', trigger: 'blur' }],
            categoryName: [{ required: true, message: '请选择类别名称', trigger: 'blur' }]
        },
      categoryOptions:[],
      menuData:[],
      defaultProps: {
        children: "children",
        label: "name",
        value:'menuId'
      },
      filetypeData:[],
      filetypeProps:{
        children: "childList",
        label: "name",
        value:'id',
        checkStrictly: true
      }
    };
  },
  methods: {
    async getForm() {
      const { data: res } = await this.$http9.post(
        "/stencils/findPages",
        this.formJson
      );
        this.total = res.total;
        this.tableData = res.list;
    },
    async getfiletype(){
      const { data: res } = await this.$http9.get("/dictionary/query/DictionaryTreeListByCode?code=file_type");
        this.filetypeData = res.data;
    },
    async getActBtCategoryTree() {
        const { data: res } = await this.$http2.get('/extension/act-bt-category/query/ActBtCategoryTree')
        if (res.code == 200) {
          this.categoryOptions = res.data;
        }
    },
    async getMeauList() {
      const { data: res } = await this.$http0.get(
        "/v1/account/menu/query/findMenuTree"
      );
      this.menuData = res.data;
      this.getResMeau(this.menuData);
    },
    async deployForm(row){
      this.$confirm('此操作将取消部署, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await this.$http9.delete('/stencils/delete/DeployStencilsForm/'+row.id)
          if (res.businessCode == 5000) {
                  this.$message.success('保存成功！')
                  this.getForm()
              } else {
                  this.$message.error('保存失败！')
              }    
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    async deleteForm(row){
      this.$confirm('此操作将删除表单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await this.$http9.delete('/stencils/delete/'+row.id)
          if (res.businessCode == 5000) {
                  this.$message.success('删除成功！')
                  this.getForm()
              } else {
                  this.$message.error('删除失败！')
              }    
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    getResMeau(arr) {
      for (let i in arr) {
        let obj = arr[i].children;
        arr[i] = arr[i].parent;
        arr[i].children = obj;
        if (arr[i].children.length !== 0) {
          this.getResMeau(arr[i].children);
        } else {
          arr[i].children = null;
        }
      }
    },
    addWord() {
      this.dialogFormVisible=true
    },
    editWord(row){
      this.editForm=row
      this.dialogFormVisibleEdit=true
    },
    editmodel(row){
       window.sessionStorage.setItem("attacheId", row.id);
     //  window.sessionStorage.setItem("wordId", row.id);
       this.$router.push({ path: "/wordCreate"})
    },
    formDesign(row) {
      console.log(row)
      this.$router.push({
        path: "/wordField",
        query: {
          tableName: row.tableName,
          generatorId: row.id,
          isRelease: row.isRelease,
        },
      });
    },
    handleCurrentChange(val) {
       this.formJson.pageNum=val
       this.getForm()
    },
    filechange(file,List){
        this.fileList=List
    },
    handleRemove(file, List) {
        this.fileList=List
    },
    changeCategoryId(e){
      this.isChange = true;
      console.log(e)
      console.log(e[e.length-1])
      this.addForm.categoryId=e[e.length-1]
    },
    async faqiFrom(){
          var formData = new FormData();
          console.log(this.fileList)
          for(let i in this.fileList){
             formData.append("file", this.fileList[i].raw);
          }
          const {data:res}= await this.$http9.post('/file/upload/AttacheInfo',formData)
          if(res.businessCode!='1000'){
              return this.$message.error('上传失败！'+res.message)
          }
          this.addForm.attacheId=res.data.attacheId
          const { data: res2 } = await this.$http9.post('/stencils/save', this.addForm)
              if (res2.businessCode == 4000) {
                  this.$message.success('保存成功！')
                  this.dialogFormVisible = false;
              } else {
                  this.$message.error('保存失败！')
          }    
          this.getForm()
          //this.$message.success('上传成功！')
    },
    filetypeChange(val) {
      this.addForm.fileType=val[val.length-1]
    },
    menuChange(e){
      this.addForm.menuId=e[e.length-1]
      console.log(this.addForm.menuId)
    }
  },
  components: {
    promeau,
    daohanglan
  },
  created() {
    this.getfiletype()
    this.getForm();
    this.getMeauList();
    this.getActBtCategoryTree()
  },
};
</script>

<style scoped lang="less">
.content {
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(92vh);
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .insertActBtCategory {
    width: 300px;
    margin-right: 8px;
  }

  .addActBtCategory {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .catleft {
      margin-top: 10px;
    }
  }

  #pageList {
    width: 98%;
  }

  .title-left {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    width: 140px;
    height: 22px;
    border-left: 5px solid #569ee3;
    text-align: left;
  }
}
</style>
