<template>
  <div id="asiderBar">
    <el-menu
      class="el-menu-vertical-demo"
      text-color="black"
      active-text-color="#fff"
      :collapse="isZhank"
      :collapse-transition="false"
      :router="true"
      :default-active="$route.path"
    >
    <el-submenu index="/proInfo">
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>项目概况</span>
        </template>
        <el-menu-item-group>
          <el-menu-item  v-for="item in this.proMenu" :index="item.path" :key="item.path">
              <i :class="item.iconCls"></i>
              <span>{{item.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
  <!-- <el-menu-item index="/proInfo">
        <i class="el-icon-user"></i>
        <span>项目详情</span>
      </el-menu-item>
      <el-menu-item index="/proFile">
        <i class="el-icon-s-custom"></i>
        <span>项目资料</span>
      </el-menu-item>
      <el-menu-item index="/proArea">
         <i class="el-icon-s-flag"></i>
        <span>项目区域</span>
      </el-menu-item>
      <el-menu-item index="/proDiary">
        <i class="el-icon-s-flag"></i>
        <span>施工日志</span>
      </el-menu-item>
      <el-menu-item index="/proEvent">
        <i class="el-icon-s-flag"></i>
        <span>项目大事记</span>
      </el-menu-item>-->
      <!-- <el-submenu index='/proAppointment'>
        <template slot="title">
          <i class="el-icon-location"></i>
          <span>启动及策划管理</span>
        </template>
        <el-menu-item-group>
          <el-menu-item  v-for="item in this.proMenu2" :index="item.path" :key="item.path">
              <i :class="item.iconCls"></i>
              <span>{{item.name}}</span>
          </el-menu-item>
        </el-menu-item-group>
      </el-submenu> -->
     <!-- <el-menu-item v-for="item in menuLevel2" :index="item.path" :key="item.path">
        <i :class="item.iconCls"></i>
        <span>{{item.name}}</span>
      </el-menu-item> -->
    </el-menu>

  </div>
</template>

<script>
  export default {
    name: 'tianBaoLeft',
    data(){
      return {
        isZhank: false,
        shiList: [],
        menuLevel2:[],
        proMenu:[],
        proMenu2:[]
      }
    },
    created () {
      this.menuLevel2=JSON.parse(window.sessionStorage.getItem('menulevel2'))[0];
      for(let i in this.menuLevel2){
        console.log(this.menuLevel2[i].name)
              if(this.menuLevel2[i].name=='项目详情'|| this.menuLevel2[i].name=='项目组部门及成员'){
                     this.proMenu.push(this.menuLevel2[i])
              }else if(this.menuLevel2[i].name=='项目经理任命'|| this.menuLevel2[i].name=='项目部组建'||this.menuLevel2[i].name=='项目启动管理'|| this.menuLevel2[i].name=='项目目标责任书管理'|| this.menuLevel2[i].name=='项目策划管理'){
                     this.proMenu2.push(this.menuLevel2[i])
              }
          }
    },
    methods: {
      toggleZhankai () {
        this.isZhank = !this.isZhank
      },

    },
  }
</script>

<style lang="less" scoped>

  .toggle-button {
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #eef5ff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
  .el-aside {
    background-color: #333744;
  }
  /deep/ .el-submenu{
    margin-left: 0 !important;
  }
</style>
