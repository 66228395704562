<template>
  <div class="bg">
    <x-daohang></x-daohang>
    <div class="mainpart">
      <el-card class="box-card">
        <div slot="header" class="clearfix">
          <el-button
            icon="el-icon-circle-plus-outline"
            @click="beforeAddUser"
            >添加用户</el-button
          >
          <el-input placeholder="请输入用户名查询"  v-model="pageFrom.realName"  clearable @clear="getUserList" class="selectyh">
              <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getUserList" ></el-button>
            </el-input>
          <span style="" class="fanhui" type="text" @click="goback">返回</span>
        </div>
        <div class="text item alltable">
          <el-table
            :data="tableData"
            :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            max-height="770"
            :default-sort="{ prop: 'date', order: 'descending' }"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" width="80">
              <template v-slot="scope">
                {{
                  (pageFrom.pageNum - 1) * pageFrom.pageSize + scope.$index + 1
                }}
              </template>
            </el-table-column>
            <el-table-column prop="realName" label="姓名" width="200">
            </el-table-column>
            <el-table-column prop="username" label="账号" width="200">
            </el-table-column>
            <el-table-column prop="phone" label="联系电话" width="200">
            </el-table-column>
            <el-table-column
              prop="organization.organizationName"
              label="所属单位"
              width="450"
            >
            </el-table-column>
            <el-table-column prop="enabled" label="启用状态" width="200">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.enabled">启用中</el-tag>
                <el-tag type="info" v-else>禁用中</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="creatorName" label="创建者" width="200">
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="editUser(scope.row)"
                  type="text"
                  class="caozuo"
                  icon="el-icon-edit"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  class="caozuo"
                  style="color: red !important"
                  icon="el-icon-delete"
                  @click="delUser(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageFrom.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
    </div>
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      class="dialog"
      size="small"
    >
      <el-form :model="form" :rules="rules" ref="ruleForm">
        <el-form-item label="用户账号" label-width="100px" prop="username">
          <el-input v-model="form.username" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="用户姓名" label-width="100px">
          <el-input v-model="form.realName"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" label-width="100px" prop="phone">
          <el-input v-model="form.phone"></el-input>
        </el-form-item>
        <el-form-item label="所属角色" label-width="100px" >
          <el-select
            v-model="form.roleGroup"
            value-key="roleGroupId"
            placeholder="请选择所属角色组"
            @change="roleChange"
          >
            <el-option
              v-for="item in rGroupOptions"
              :key="item.id"
              :label="item.name"
              :value="{ roleGroupId: item.id, roleGroupName: item.name }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="所属单位" label-width="100px">
          <el-select
            v-model="form.organization"
            value-key="id"
            placeholder="请选择所属单位"
            @change="unitChange"
          >
            <el-option
              v-for="item2 in unitOptions"
              :key="item2.id"
              :label="item2.unitName"
              :value="{ id: item2.id, unitName: item2.unitName }"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户密码" label-width="100px" prop="password" v-show="title=='新增用户'">
          <el-input v-model="form.password" show-password></el-input>
        </el-form-item>
        <el-form-item label="确认密码" label-width="100px" prop="ispassword" v-show="title=='新增用户'">
          <el-input v-model="form.ispassword" show-password></el-input>
        </el-form-item>
        <el-form-item label="是否启用" label-width="100px" prop="enabled">
          <el-radio v-model="form.enabled" :label="1">启 用 </el-radio>
          <el-radio v-model="form.enabled" :label="2">禁 用</el-radio>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="addUser('ruleForm')">新 增</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "userMangage",
  data() {
    var checkEmail = (rule, value, cb) => {
      const regEmail = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
      if (value == "") {
        return cb();
      } else if (regEmail.test(value)) {
        return cb();
      }
      cb(new Error("请输入合法的邮箱！"));
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatorPhone = function (rule, value, callback) {
      if (value === "") {
        callback(new Error("手机号不能为空"));
      } else if (!/^1\d{10}$/.test(value)) {
        callback(new Error("手机号格式错误"));
      } else {
        callback();
      }
    };
    return {
      tableData: [],
      pageFrom: {
        pageNum: 1,
        pageSize: 10,
        username: "",
        realName: "",
        phone: "",
        orgIdList: [],
        roleGroupId: "",
        department: "",
      },
      total: 0,
      dialogVisible: false,
      dialogVisible2: false,
      form: {
        id: "",
        username: "",
        realName: "",
        phone: "",
        roleGroup: {
          roleGroupId: "",
          roleGroupName: "",
        },
        organization: {
          id: "",
          unitName: "",
        },
        enabled: 1,
        password: "",
        ispassword: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
        ],
        phone: [{ required: true, message: "请输入联系电话", trigger: "blur" }],
        password: [
          { required: true, message: "请输入用户密码", trigger: "blur" },
        ],
        ispassword: [
          { validator: validatePass2, trigger: "blur" },
          { required: true, message: "请输入密码", trigger: "blur" },
        ],
        roles: [
          { required: true, message: "请选择所属角色组", trigger: "change" },
        ],
        organizations: [
          { required: true, message: "请选择所在单位", trigger: "change" },
        ],
      },
      rGroupOptions: [],
      unitOptions: [],
      title: "新增用户",
    };
  },
  created() {
    this.getUserList();
    this.getRoleList();
    this.getUnitList();
  },
  methods: {
    async getUserList() {
      const { data: res } = await this.$http0.post(
        "/v1/account/user/query/findPageList",
        this.pageFrom
      );
      if (res.businessCode !== 3000) {
        this.tableData =[];
      this.total = 0;
        return this.$message.error("获取用户列表失败！" + res.message);
      }
      this.tableData = res.data.list;
      this.total = res.data.total;
    },
    async getRoleList() {
      const { data: res } = await this.$http0.get(
        "/v1/account/roleGroup/query/findAll"
      );
      if (res.businessCode == 3000) {
        this.rGroupOptions = res.data;
      }
    },
    async getUnitList() {
      const { data: res } = await this.$http0.get("/v1.0/account/unit/findAll");
      if (res.businessCode == 3000) {
        this.unitOptions = res.data;
      }
    },
    handleSizeChange(val) {
      this.pageFrom.pageSize = val;
      this.getUserList();
    },
    handleCurrentChange(val) {
      this.pageFrom.pageNum = val;
      this.getUserList();
    },
    handleChange(value) {
      console.log(value);
    },
    roleChange(value) {
      console.log(value);
      this.form.roleGroup.roleGroupId = value.roleGroupId;
      this.form.roleGroup.roleGroupName = value.roleGroupName;
    },
    unitChange(value) {
      console.log(value);
      this.form.organization.organizationName = value.unitName;
      this.form.organization.organizationId = value.id;
    },
    beforeAddUser(){
      this.title = "新增用户";
      this.form={
        id: "",
        username: "",
        realName: "",
        phone: "",
        roleGroup: {
          roleGroupId: "",
          roleGroupName: "",
        },
        organization: {
          id: "",
          unitName: "",
        },
        enabled: 1,
        password: "",
        ispassword: "",
      }
      this.dialogVisible = true
    },
    addUser(formName) {
      console.log(this.form);
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          if (this.title == "新增用户") {
            const { data: res } = await this.$http0.post(
              "/v1/account/user/insert",
              this.form
            );
            if (res.businessCode == 4000) {
              this.$message.success("新增成功！");
              this.getUserList();
              this.dialogVisible = false;
            } else {
              this.$message.error("新增失败！" + res.message);
            }
          } else {
            const { data: res } = await this.$http0.put(
              "/v1/account/user/update",
              this.form
            );
            if (res.businessCode == 4300) {
              this.$message.success("修改成功！");
            } else {
              this.$message.error("修改失败！" + res.message);
            }
            this.getUserList();
            this.dialogVisible = false;
          }
        } else {
          return false;
        }
      });
    },
    editUser(row) {
    //   if (row.department == null) {
    //     row.department = {
    //       departmentName: "",
    //       departmentId: "",
    //     };
    //   }
      console.log(row);
      this.form = row;
      this.form.id = row.userId;
      this.form.ispassword=row.password
      this.form.organization.id=row.organization.organizationId
      this.form.organization.unitName=row.organization.organizationName
      this.title = "修改用户";
      this.dialogVisible = true;
    },
    async delUser(row) {
      console.log(row);
      const confirmResult = await this.$confirm(
        "此操作将永久删除该用户, 是否继续?",
        "提示",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).catch((err) => {
        return err;
      });
      if (confirmResult !== "confirm") {
        return this.$message({
          type: "info",
          message: "已取消删除",
        });
      }
      //请求
      const { data: res } = await this.$http0.delete(
        "/v1/account/user/delete?id=" + row.userId
      );
      if (res.businessCode != "5000") {
        return this.$message.error("删除用户失败！" + res.message);
      }
      this.$message.success("删除用户成功！");
      this.getUserList();
    },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },
};
</script>

<style scoped lang="less">
/*  .bg{
        height: 100vh;
    }*/
.mainpart {
  left: 10px;
  top: 70px;
  bottom: 10px;
  right: 10px;
  width: 1900px;
}
.fanhui {
  float: right;
  padding: 0;
  border: 0 !important;
  color: black !important;
  margin-top: 10px;
  cursor: pointer;
}

/deep/ .cell {
  max-height: 70px;
  overflow-y: auto !important;
}
.caozuo {
  background: transparent !important;
}
.caozuo:hover {
  color: rgb(97, 111, 240) !important;
}
.selectyh{
  width: 280px;
  margin-left: 50px;
}
</style>

