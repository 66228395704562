<!--
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2023-01-09 16:23:13
 * @LastEditors: @zhang-hongZ 15681179637@163.com
 * @LastEditTime: 2023-01-11 16:52:25
 * @FilePath: \pages-demo\src\views\process\pages\work\dealprocess\auditFlow.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="containers">
    <el-form :model="passForm" ref="ruleFormRef" :rules="rules" status-icon>
      <el-form-item label="审批人员" label-width="100px" prop="assignee">
        <!-- <el-select
          v-model="passForm.assignee"
          class="m-2"
          placeholder="Select"
          size="large"
        >
          <el-option
            v-for="item in peopleOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select> -->
        <el-cascader
          @change="userChange"
          ref="userRef"
          v-model="passForm.assignee"
          :show-all-levels="false"
          :options="userOptions"
          :props="props2"
          class="cascader"
          placeholder="请选择审批人员"
        >
        </el-cascader>
      </el-form-item>
      <el-form-item label="批注意见" label-width="100px" prop="notation">
        <el-input
          v-model="passForm.notation"
          :rows="3"
          type="textarea"
          placeholder=""
        />
      </el-form-item>
    </el-form>
    <span class="dialog-footer">
      <el-button type="primary" @click="submitForm(ruleFormRef)">
        提交
      </el-button>
    </span>
  </div>
</template>
<script>
export default {
  data() {
    return {
      passForm :{
        assignee: "",
        taskId: "",
        type: "",
        other: {},
        notation: "情况属实，同意！",
      },
      userOptions:[],
      props2 :{
        label: "realName",
        value: "assignee",
      },
      rules:{
        notation: [{ required: true, message: "请输入批注意见", trigger: "blur" }],
        delegate: [ { required: true,message: "请选择审批人",  trigger: "change" },
        ],
      }
    }
  },
  methods:{
    userChange(res){
      passForm.value.assignee = res[res.length - 1];
    },
    submitForm(formName){
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            console.log("submit!");
          } else {
            console.log("error submit!");
          }
        });
    }
  }
}
</script>
