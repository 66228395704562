<template>
    <div class="site-wrapper">
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div class="addActBtCategory">
                    <div class="catleft">
                        <span class="title-left">历史任务</span>
                    </div>
                    <div style="display: flex;justify-content: flex-end;z-index: 999999999;">
                        <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                        </el-button>
                    </div>
                </div>
                <!-- 列表 -->
                <div>
                    <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                        <template>
                            <el-empty :image-size="100" description="暂无数据"></el-empty>
                        </template>
                        <el-table-column prop="id" label="任务编号" width="350">
                            <template #default="scope">
                                <el-tag>{{ scope.row.id }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="name" label="任务名称" width="200" />
                        <el-table-column prop="taskDefinitionKey" label="任务key" width="200" />
                        <el-table-column prop="assignee" label="签收者" width="160" />
                        <el-table-column prop="startTime" label="开始时间" width="220">
                            <template #default="scope">
                                <div style="display: flex; align-items: center">
                                    <el-icon>
                                        <timer />
                                    </el-icon>
                                    <span style="margin-left: 10px">{{
                                        times.convertUTCTimeToLocalTime(scope.row.startTime)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endTime" label="结束时间" width="220">
                            <template #default="scope">
                                <div style="display: flex; align-items: center">
                                    <el-icon>
                                        <timer />
                                    </el-icon>
                                    <span style="margin-left: 10px">{{
                                        times.convertUTCTimeToLocalTime(scope.row.endTime)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-button size="small" :icon="EditPen" @click="getInfo2(scope.row)">详情</el-button>
                                <el-button size="small" type="danger" plain :icon="Delete"
                                    @click="deleteModel(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                        <el-pagination background @current-change="handleChangePageNum" @size-change="handleChangePageSize"
                            layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible2" width="70%">
            <template #header="{ close, titleId, titleClass }">
                <div class="my-header">
                    <h4 :id="titleId" :class="titleClass">流程详情</h4>
                </div>
            </template>
            <p v-html="dataSvg2" style="text-align: center"></p>
            <el-table :data="formData2" border size="large" style="height: 80%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                <template>
                    <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column prop="taskId" label="任务编号" width="180">
                    <template #default="scope">
                        <el-tag>{{ scope.row.taskId }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="userId" label="审批人员" width="120" />
                <el-table-column prop="taskName" label="审批节点" width="120" />
                <el-table-column prop="startTime" label="开始时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                times.convertUTCTimeToLocalTime(scope.row.startTime)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="taskName" label="审批进度" width="120">
                    <template #default="scope">
                        <span>{{ scope.row.endTime != null ? "结束" : "审批中" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="审批类型" width="120" />
                <el-table-column prop="time" label="审批时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                times.convertUTCTimeToLocalTime(scope.row.time)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="message" label="批注"> </el-table-column>
            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "./proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                pageNum: 1,
                pageSize: 10,
                processDefinitionName: "",
                startTime: "",
                endTime: "",
                taskName: "",
            },
            total: 0,
            tableData: [],
            dialogVisible2: false,
            dataSvg2: '',
            formData2: []
        }
    },
    methods: {
        async getModel() {
            const { data: res } = await this.$http2.post('/ActHiTaskInst/query/ProcessHiTaskInstPageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
        async getInfo(row) {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/' + row.processInstanceId)
            this.dataSvg2 = res;
            const { data: res2 } = await this.$http2.get('/ActRuTask/query/TaskCommentByProcessInstanceId/' + row.processInstanceId)
            this.formData2 = res2.data;
            this.dialogVisible2 = true;
        },
        deleteModel(row) {
            this.$confirm('此操作将永久删除编码为【' + row.executionId + '】的历史任务, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await this.$http2.delete('/ActHiTaskInst/delete/ProcessHiTaskInstByTaskId/' + row.id)
                if (res.businessCode == 5000) {
                    this.$message.success('删除成功！')
                    this.getModel();
                } else {
                    this.$message.error('删除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleChangePageSize(val) {
            this.queryPage.pageSize = val;
            this.getModel();
        },
        handleChangePageNum(val) {
            this.queryPage.pageNum = val;
            this.getModel();
        }
    },
    created() {
        this.getModel()
    },
    components: {
        promeau,
    },
    watch: {

    },
    mounted() {
    },
}

</script>

<style scoped lang="less">


.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;

        .addActBtCategory {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;

            .catleft {
                margin-top: 10px;

                .title-left {
                    font-size: 18px;
                    font-weight: 700;
                    padding-left: 10px;
                    width: 140px;
                    height: 22px;
                    border-left: 5px solid #569ee3;
                    text-align: left;
                }
            }
        }
    }

}
</style>