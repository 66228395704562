var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"probom"},[_c('ul',[_c('li',{staticClass:"dhome",class:{activiehome:_vm.current==1},on:{"click":_vm.gohome}},[_vm._m(0)]),_c('li',{staticClass:"dproinfo",class:{activiepro:_vm.current==2},on:{"click":_vm.goPro}},[_vm._m(1)]),_c('li',{staticClass:"dprowork",class:{activiework:_vm.current==11},on:{"click":_vm.goWork}},[_vm._m(2)]),_c('li',{staticClass:"dprocess",class:{activieprocess:_vm.current==5},on:{"click":_vm.goprocess}},[_vm._m(3)]),_c('li',{staticClass:"dquality",class:{activiequality:_vm.current==4},on:{"click":_vm.goquality}},[_vm._m(4)]),_c('li',{staticClass:"dhetong",class:{activiehetong:_vm.current==8},on:{"click":_vm.gohetong}},[_vm._m(5)]),_c('li',{staticClass:"dcost",class:{activiecost:_vm.current==7},on:{"click":_vm.gocost}},[_vm._m(6)]),_c('li',{staticClass:"dsafe",class:{activiesafe:_vm.current==3}},[_vm._m(7)]),_c('li',{staticClass:"djiankong",class:{activiemonitor:_vm.current==12},on:{"click":_vm.gomonitor}},[_vm._m(8)]),_c('li',{staticClass:"dkaoqing",class:{activiekaoqing:_vm.current==9},on:{"click":_vm.gokaoqing}},[_vm._m(9)]),_c('li',{staticClass:"dsystem",class:{activieback:_vm.current==10},on:{"click":_vm.backmanage}},[_vm._m(10)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("首页")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("项目详情")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("工作台")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("进度管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("质量管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("合同管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("费用控制")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("安全管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("视频监控")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("考勤管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_c('span',[_vm._v("后台管理")])])
}]

export { render, staticRenderFns }