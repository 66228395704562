<template>
    <div style="width: 690px;height: 250px;" id="cost">

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "costEcharts",
        mounted(){
            this.echart();
        },
        methods:{
            echart(){
                var date=new Date();
                var xdata=[];
                var cnum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
                for(var i=0;i<=date.getMonth();i++){
                    xdata.push(cnum[i]+'月')
                }
                var myChart1 = echarts.init(document.getElementById('anquan'));
                var option = {
                    tooltip: {
                        trigger: 'axis'
                    },
                    legend: {
                        data: ['已处理问题', '未处理问题'],
                        textStyle:{
                            fontSize: 12,//字体大小
                            color: '#000'//字体颜色
                        },
                    },
                    grid: {
                        top:'13%',
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#000'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel : {
                            textStyle: {
                                color: '#000'
                            }
                        }
                    },
                    series: [
                        {
                            name: '已处理问题',
                            type: 'line',
                            stack: '总数',
                            lineStyle:{ color:'#1e50ae'},
                            data: [220, 182, 191, 234, 290, 330, 310, 110]
                        },
                        {
                            name: '未处理问题',
                            type: 'line',
                            stack: '总数',
                            lineStyle:{ color:'black'},
                            data: [120, 132, 101, 134, 90, 230, 210, 90]
                        }
                    ]
                };
                myChart1.setOption(option);
            }
        }
    }
</script>

<style scoped>

</style>
