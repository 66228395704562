<template>
    <div class="bg">
        <process-left></process-left>
        <div class="mainpart">
            <div class="time-line">
                <div style="" class="lcbtitle"><h2 style="color: black;">项目里程碑</h2></div>
                <div class="left-btn"></div>
                <div class="right-btn"></div>
               <div class="time-box">
                <ul>
                    <li v-for="(item,index) in liData" :key="index" class="li1 active" @click="imgclick(item.filePath)" style="background-size:100%" :style="{background:'url('+item.filePath+') no-repeat center '}">
                        <div v-if="index%2" style="position: absolute; margin-top: -250px;" class="toptext" @click.stop="">
                            <el-input
                                    type="textarea"
                                    :rows="8"
                                    :disabled="true"
                                    @click.stop=""
                                    v-model=item.description>
                            </el-input>
                            <div style="text-align: center" @click.stop="beforeEdit(item)">
                                <a style="font-size: 19px; color: #000; ">&nbsp;{{item.title}}<br>{{item.milestoneDate}}<br></a>
                            </div>
                        </div>
                        <div v-else  style="position: absolute; margin-top: 240px;" >
                            <div style="text-align: center" @click.stop="beforeEdit(item)">
                                <a style="font-size: 19px; color: #000; ">&nbsp;{{item.title}}<br>{{item.milestoneDate}}<br></a>
                            </div>
                            <div style="padding: 10px;"  @click.stop="">
                                <el-input
                                        type="textarea"
                                        :disabled="true"
                                        @click.stop=""
                                        :rows="7"
                                        v-model=item.description>
                                </el-input>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
            </div>
        </div>
        <el-dialog
                title="修改里程碑信息"
                :visible.sync="dialogVisibleEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="editForm" label-width="125px" >
                <el-form-item label="里程碑任务名称" >
                    <el-input v-model="editForm.title"   :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="里程碑任务描述" >
                    <el-input
                            type="textarea"
                            :rows="5"
                            placeholder="请输入内容"
                            v-model="editForm.description">
                    </el-input>
                </el-form-item>
                <el-form-item label="里程碑形象">
                        <el-upload
                                action="#"
                                list-type="picture-card"
                                :on-preview="handlePreviewEdit"
                                :on-change="filechangeEdit"
                                :limit="1"
                                :on-remove="handleRemoveEdit"
                                :file-list="fileListEdit"
                                :auto-upload="false">
                            <i class="el-icon-plus"></i>
                        </el-upload>
                        <el-dialog :visible.sync="dialogVisible1">
                            <img width="100%" :src="dialogImageUrl" alt="">
                        </el-dialog>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="edit" v-has="'editlcb'" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog :visible.sync="imgshow">
            <img width="100%" :src="imgurl" alt="">
        </el-dialog>
    </div>
</template>

<script>
    import $ from 'jquery'
    var index = 0;
    var timeLength = 7;
    timeliWidth = 280;
    var  timeliWidth;
    function  slideOne(i) {
        var scrollVal = i * timeliWidth; //每次切换的数量
        $('.time-box').stop().animate({
            scrollLeft: scrollVal
        }, 300);
    }

    import processLeft from './processLeft'
    export default {
        name: "lichengbie",
        data(){
            return{
                liData:[],
                dialogVisibleEdit:false,
                fileListEdit:[],
                dialogImageUrl: '',
                dialogVisible1: false,
                editForm:{},
                imgshow:false,
                imgurl:'',
                src:require('../../assets/img/process/shigong.gif')
            }
        },
        components:{
            processLeft
        },
        created(){
            this.getAll();
        },
        methods:{
            async getAll(){
                    const {data:res}= await this.$http6.get('/v1.0/schedule/milestone/findAll?projectId='+this.$root.proId)
                    if(res.businessCode!='3000'){
                        return this.$message.error(res.message)
                     }
                this.liData=res.data;
                    for(let i in this.liData){
                        const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+this.liData[i].filePath)
                        this.liData[i].filePath=res2.data

                    }
                timeLength=this.liData.length
                $('.time-box ul').width(timeLength * timeliWidth);
            },
            async beforeEdit(item){
                console.log(item)
                const {data:res}=await  this.$http6.get('/v1.0/schedule/milestone/findById?id='+item.id);
                if(res.businessCode!=="3000"){
                    return this.$message.error(res.message);
                }
                this.editForm=res.data;
                this.fileListEdit=[];
                if(!this.editForm.description){
                    this.editForm.description=''
                }
                if(this.editForm.filePath){
                    const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+this.editForm.filePath)
                    this.fileListEdit.push({url:res2.data})
                }
                this.dialogVisibleEdit=true
            },
            async edit(){
                var formData=new FormData();
                formData.append("multipartFile",this.fileListEdit[0].raw);
                for (let key in this.editForm) {
                    formData.append(key,this.editForm[key]);
                }
                const {data:res}= await this.$http6.put('/v1.0/schedule/milestone/update', formData)
                if(res.businessCode!='6010'){
                    return this.$message.error('修改里程碑信息失败！'+res.message)
                }
                this.$message.success('修改里程碑信息成功！');
                this.dialogVisibleEdit=false;
                this.getAll();
            },
            async imgclick(path){
                this.imgshow=true;
                //this.imgurl='http://localhost:8811/'+path
                this.imgurl=path
            },
            handleRemoveEdit(file, fileList) {
                console.log(file, fileList);
                this.fileListEdit=fileList
            },
            handlePreviewEdit(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible1 = true;
            },
            filechangeEdit(file,filelist){
                this.fileListEdit=filelist
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            } ,
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
        },
        mounted() {


            $('.right-btn').click(() =>{
                console.log(1)
                index = index >= (timeLength-4) ? 0 : index + 1;
                slideOne(index);
            })
            $('.left-btn').click(function() {
                index = index <= 0 ? (timeLength-4) : index - 1;
                slideOne(index);
            })
            $('.time-box li').click( function(){
                $('.xxtp').css("background",$('.active').css('background'));
                $('.xxtp').show();
            });
        }
    }
</script>

<style scoped lang="less">
    .lcbtitle{
        position: absolute; margin-top: -110px;
    }
    .li1{
       // background: url('../../assets/img/process/lixiang.gif') no-repeat center;
        background-size: cover
    }
    .li2{
        background: url('../../assets/img/process/shigong.gif') no-repeat center;background-size: cover
    }
    .li3{
        background: url('../../assets/img/process/jiegou.gif') no-repeat center;background-size: cover
    }
    .li4{
        background: url('../../assets/img/process/diaoyan.gif') no-repeat center;background-size: cover
    }
    .time-line{width:1200px;margin:130px auto;position: relative;}
    .time-line .left-btn,.time-line .right-btn{width: 40px;height: 30px;background: url('../../assets/img/process/arrow.png') no-repeat;position: absolute;margin-top:-15px;cursor: pointer;}
    .time-line .left-btn{left:0;top:50%;}
    .time-line .right-btn{right:0;top:50%;transform: rotate(180deg);}
    .time-line .right-btn:hover{background: url('../../assets/img/process/arrow-hover.png') no-repeat;transform: rotate(180deg);}
    .time-line .left-btn:hover{background: url('../../assets/img/process/arrow-hover.png') no-repeat;}
    .time-line .time-box{width: 1120px;margin: 0 auto;margin-top:0px;overflow: hidden;}
    .time-line .time-box ul{overflow: hidden;position: relative;height: 455px;width: 1140px}
    .time-line .time-box ul:after{content: "";background: #d2d2d2;height: 1px;width: 100%;position: absolute;left:0;top:50%;}
    .time-line .time-box li{width:280px;float: left;box-sizing: border-box;border: 1px solid #d2d2d2;position: relative; cursor: pointer;height: 190px;}
    .time-line .time-box li:nth-child(2n){margin-top: 265px;}
    .time-line .time-box li:before{content: "";border-left: 1px solid #d2d2d2;border-bottom: 1px solid #d2d2d2;position: absolute;width: 16px;height: 16px;background: #fff;   bottom: -9px; transform: rotate(-45deg);left: 50%;margin-left: -8px;}
    .time-line .time-box li:after{content: "";width: 15px;height: 15px;background: url('../../assets/img/process/point.png') no-repeat;position: absolute;left:50%;margin-left:-7px;bottom: 0;    bottom: -48px; z-index: 1;}
    .time-line .time-box li:nth-child(2n):before{bottom: auto;top: -10px;transform: rotate(-225deg);}
    .time-line .time-box li:nth-child(2n):after{bottom: auto;top:-46px;}
    .time-line .time-box li:hover,.time-line .time-box li.active{color:#fff;border-color: #1b96ff;}
    .time-line .time-box li:hover:before,.time-line .time-box li.active:before{border-color: #1b96ff;background: #1b96ff;}
    .time-line .time-box li:hover:after,.time-line .time-box li.active:after{background: url('../../assets/img/process/point-hover.png') no-repeat;}


    li{list-style: none;}
   /deep/ .el-textarea{
       width: 260px !important;
   }
    /deep/ .el-textarea__inner{
        padding:2px 5px;
    }
   .toptext{
       /deep/  .el-textarea{
           margin-top: -20px;
       }
   }
</style>
