<template>
    <div class="left">
        <el-menu
                class="el-menu-vertical-demo"
                text-color="#000"
                active-text-color="#fff"
                :collapse-transition="false"
                :router="true"
                :default-active="cupath"
        >
        <el-submenu index='/cccc'>
                <template slot="title">
                <i class="el-icon-location"></i>
                <span>无纸化办公</span>
                </template>
                <el-menu-item-group>
                <el-menu-item  v-for="item in this.wuzhihuamenu" :index="item.path" :key="item.path" @click="getMenuId(item)">
                    <i :class="item.iconCls"></i>
                    <span>{{item.name}}</span>
                </el-menu-item>
                </el-menu-item-group>
                    </el-submenu>
           <el-menu-item v-for="item in meau"  :index="item.path" :key="item.menuId" @click="getcupath(item)">  <i :class="item.icon"></i>{{item.name}}</el-menu-item>
           
           <!--<el-menu-item index="/project" @click="goprohome">
                <i class="el-icon-s-home"></i>
                <span>项目首页</span>
            </el-menu-item>
            <el-menu-item index="/projectInfo" @click="goproInfo">
                <i class="el-icon-info"></i>
                <span>项目详情</span>
            </el-menu-item>
            <el-menu-item index="/work" @click="gowork">
                <i class="el-icon-s-tools"></i>
                <span>工作台</span>
            </el-menu-item>
            <el-menu-item index="/proprocess" @click="goprocess">
                <i class="el-icon-s-operation"></i>
                <span>进度管理</span>
            </el-menu-item>
            <el-menu-item index="/proquality" @click="goquality">
                <i class="el-icon-star-on"></i>
                <span>质量管理</span>
            </el-menu-item>
            <el-menu-item index="/procontract" @click="gocontract">
                <i class="el-icon-s-order"></i>
                <span>合同管理</span>
            </el-menu-item>
            <el-menu-item index="/procost" @click="gocost">
                <i class="el-icon-money"></i>
                <span>费用控制</span>
            </el-menu-item>
            <el-menu-item  @click="goAnquan">
                <i class="el-icon-s-opportunity"></i>
                <span>安全管理</span>
            </el-menu-item>
            <el-menu-item index="/promonitor" @click="gomonitor">
                <i class="el-icon-video-camera-solid"></i>
                <span>视频监控</span>
            </el-menu-item>
            <el-menu-item index="/proAttendance" @click="goAttendance">
                <i class="el-icon-s-order"></i>
                <span>考勤管理</span>
            </el-menu-item>
            <el-menu-item index="/proBackManage"@click="goback">
                <i class="el-icon-s-tools"></i>
                <span>后台管理</span>
            </el-menu-item>-->
        </el-menu>
        <el-button type="danger" plain @click="quit">返回主页</el-button>
    </div>

</template>

<script>
    export default {
        name: "commonleft",
        data(){
            return{
                cupath:window.sessionStorage.getItem('current'),
                meau:[],
                wuzhihuamenu:[]
            }
        },
        methods:{
            goprohome(){
                window.sessionStorage.setItem('current','/project')
            }  ,
            goproInfo(){
                window.sessionStorage.setItem('current','/projectInfo')
            },
            gowork(){
                window.sessionStorage.setItem('current','/work')
            },
            goprocess(){
                window.sessionStorage.setItem('current','/proprocess')
            },
            goquality(){
                window.sessionStorage.setItem('current','/proquality')
            },
            gocontract(){
                window.sessionStorage.setItem('current','/procontract')
            },
            gocost(){
                window.sessionStorage.setItem('current','/procost')
            },
            gomonitor(){
                window.sessionStorage.setItem('current','/promonitor')
            },
            goAttendance(){
                window.sessionStorage.setItem('current','/proAttendance')
            },
            goback(){
                window.sessionStorage.setItem('current','/proBackManage')
            },
            goAnquan(){
                window.open('https://sematem.xjbtgis.cn/')
            },
            getPath(){
                this.cupath=this.$route.matched[0].path
            },
            quit(){
                this.$router.push({ path: "/home" });
            },
            getcupath(item){
               if(item.name=='安全管理'){
                window.sessionStorage.setItem('menuId',10004)
                }else {
                    
                }
                window.sessionStorage.setItem('current',item.path)
            },
            async getMenuId(item){
                window.sessionStorage.setItem('parentmenuId',item.menuId)
                window.sessionStorage.setItem('isPar',1)
             //   const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId='+window.sessionStorage.getItem('parentmenuId'))
              //  window.sessionStorage.setItem('menuId',res.data[0].menuId)
               // console.log(window.sessionStorage.getItem('menuId'))
            }
        },
        created(){
            this.meau=[];
            for(let i in JSON.parse(window.sessionStorage.getItem('menulevel1'))){
                if(JSON.parse(window.sessionStorage.getItem('menulevel1'))[i].name!='考勤管理'){
                    this.meau.push(JSON.parse(window.sessionStorage.getItem('menulevel1'))[i])
                }
            }
            console.log(this.meau)
            this.wuzhihuamenu=JSON.parse(window.sessionStorage.getItem('wuzhihuamenu'));
            if(window.sessionStorage.getItem('activePath')){
                this.cupath= window.sessionStorage.getItem('activePath')
            }else {
                this.cupath=this.meau[0].path;
            }
        },
        mounted() {
            this.cupath=window.sessionStorage.getItem('current')
                // 检测浏览器路由改变页面不刷新问题,hash模式的工作原理是 hashchange事件
        },
        watch:{
            $route:'getPath'
        }
    }
</script>

<style scoped lang="less">
   .left{
       width: 200px;
       top: 76px;
       bottom: 0;
       background-color: white;
       max-height: 820px;
       overflow-y: auto;
       overflow-x: hidden;
   }
   /deep/  .el-menu{
       width: 100%;
       top: 4px;
       bottom: 0;
   }
    /deep/ .el-menu-item{
       color: #B6BBC6 !important;
       font-size: 18px !important;
    }
    /deep/ .el-submenu__title{
       font-size: 18px !important;
    }
    /deep/ .el-menu-item-group__title{
        padding: 0 !important
    }
    /deep/ .is-active{
        color: black !important;
        font-weight: bold;
      //  border-right: 5px solid #4988e3;
        i{
            color: #4988e3;
        }
    }
    /deep/ .el-button{
        background: #FCE8E9 !important;
        border-color: #FCE8E9 !important;
        color: red !important;
        width: 170px;
        margin-left: 10px;
        margin-top: 20px;
    }
</style>
