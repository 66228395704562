<template>
    <div class="site-wrapper">
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div class="addActBtCategory">
                    <div class="catleft">
                        <span class="title-left">流程实例</span>
                    </div>
                    <div style="display: flex;justify-content: flex-end;z-index: 99999;">
                        <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                        </el-button>
                    </div>
                </div>
                <!-- 列表 -->
                <div>
                    <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                        <template>
                            <el-empty :image-size="100" description="暂无数据"></el-empty>
                        </template>
                        <el-table-column prop="processDefinitionKey" label="流程关键字" width="220">
                            <template #default="scope">
                                <el-tag>{{ scope.row.processDefinitionKey }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="processDefinitionName" label="流程名称" width="220" />
                        <el-table-column prop="processInstanceName" label="实例名称" width="220" />
                        <el-table-column prop="startUserId" label="实例发起者" width="140">
                        </el-table-column>
                        <el-table-column prop="processDefinitionVersion" label="流程版本" width="90">
                            <template #default="scope">
                                <el-tag type="success">V{{ scope.row.processDefinitionVersion }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="suspended" label="实例状态" width="150">
                            <template #default="scope">
                                <el-tag type="success" v-if="scope.row.suspended == 1">激活状态</el-tag>
                                <el-tag type="danger" v-else>挂起状态</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="startTime" label="开始时间" width="220">
                            <template #default="scope">
                                <div style="display: flex; align-items: center">
                                    <el-icon>
                                        <timer />
                                    </el-icon>
                                    <span style="margin-left: 10px">{{
                                        times.convertUTCTimeToLocalTime(scope.row.startTime)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-button size="small" :icon="EditPen" title="流程实例节点跟踪(流程执行到哪一步)"
                                    @click="getInfo(scope.row)">跟踪</el-button>
                                <el-button size="small" type="success" plain :icon="Promotion" title="通过流程实例编号挂起或激活流程实例"
                                    @click="updateProcess(scope.row)">激活|挂起</el-button>
                                <el-button size="small" type="danger" plain title="通过流程实例编号删除运行时流程实例" :icon="Promotion"
                                    @click="deleteOne(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                        <el-pagination background @current-change="handleChangePageNum" @size-change="handleChangePageSize"
                            layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="70%">
            <template #header="{ close, titleId, titleClass }">
                <div class="my-header">
                    <h4 :id="titleId" :class="titleClass">流程跟踪</h4>
                </div>
            </template>
            <p v-html="dataSvg" style="text-align: center"></p>
            <el-table :data="formData" border size="large" style="height: 60%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                <template>
                    <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column prop="processInstanceId" label="实例编号" width="320">
                    <template #default="scope">
                        <el-tag>{{ scope.row.processInstanceId }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="activityId" label="节点编号" width="300" />
                <el-table-column prop="name" label="执行名称" width="300" />
                <el-table-column prop="suspended" label="是否挂起" width="160">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.suspended == false">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="ended" label="是否结束" width="160">
                    <template #default="scope">
                        <el-tag type="success" v-if="scope.row.ended == false">否</el-tag>
                        <el-tag type="danger" v-else>是</el-tag>
                    </template>
                </el-table-column>
            </el-table>
            <common-pagination :total="innertotal" @sizeChange="handleChangePageSize2"
                @currentChange="handleChangePageNum2"></common-pagination>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "./proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                pageNum: 1,
                pageSize: 10,
                proDefVersion: "",
                processDefinitionKey: "",
                processDefinitionName: "",
                startUserId: "",
                suspended: "",
                processInstanceName: "",
            },
            queryPage2: {
                pageNum: 1,
                pageSize: 10,
                processInstanceId: "",
            },
            total: 0,
            tableData: [],
            dialogVisible: false,
            dataSvg: '',
            formData: [],
            innertotal: 0
        }
    },
    methods: {
        async getModel() {
            const { data: res } = await this.$http2.post('/ActRuExecution/query/ProcessExecutionPageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
        async getInfo(row) {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/' + row.processInstanceId)
            this.dataSvg = res;
            this.queryPage2.processInstanceId = row.processInstanceId;
            this.getPath();
            this.dialogVisible = true;
        },
        async getPath() {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/ProcessExecutionPathByProcessInstanceId/' + this.queryPage2.processInstanceId + '?pageNum=' + this.queryPage2.pageNum + "&pageSize=" + this.queryPage2.pageSize)
            this.formData = res.data.list;
            this.innertotal = res.data.total;
        },
        async updateProcess(row) {
            const { data: res } = await this.$http2.put('/ActRuExecution/update/ProcessExecution', {
                processInstanceId: row.processInstanceId,
                suspensionState: row.suspended === 1 ? 2 : 1,
            })
            if (res.businessCode == 4300) {
                this.$message.success("更新成功");
                this.getModel();
            }
        },
        deleteOne(row) {
            this.$confirm('此操作将永久删除【' + row.processInstanceName + '】, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let param = {
                    deletionReason:
                        "" +
                        JSON.parse(window.localStorage.getItem("userinfoRoles") || "{}")
                            .realName +
                        "删除了流程实例",
                    processInstanceId: row.processInstanceId,
                };
                const { data: res } = await this.$http2.delete('/ActRuExecution/delete/ProcessExecution' ,param)
                if (res.businessCode == 5000) {
                    this.$message.success('删除成功！')
                    this.getModel();
                } else {
                    this.$message.error('删除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        resetCondition(){
          this.queryPage= {
                pageNum: 1,
                pageSize: 10,
                proDefVersion: "",
                processDefinitionKey: "",
                processDefinitionName: "",
                startUserId: "",
                suspended: "",
                processInstanceName: "",
            }
            this.getModel();
        },
        handleChangePageSize(val) {
            this.queryPage.pageSize = val;
            this.getModel();
        },
        handleChangePageNum(val) {
            this.queryPage.pageNum = val;
            this.getModel();
        },
        handleChangePageSize2(val) {
            this.queryPage2.pageSize = val;
            this.getPath();
        },
        handleChangePageNum2(val) {
            this.queryPage2.pageNum = val;
            this.getPath();
        },

    },
    created() {
        this.getModel()
    },
    components: {
        promeau,
    },
    watch: {

    },
    mounted() {
    },
}

</script>

<style scoped lang="less">


.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;

        .addActBtCategory {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;

            .catleft {
                margin-top: 10px;

                .title-left {
                    font-size: 18px;
                    font-weight: 700;
                    padding-left: 10px;
                    width: 140px;
                    height: 22px;
                    border-left: 5px solid #569ee3;
                    text-align: left;
                }
            }
        }
    }

}
</style>