<template>

    <div class="bg">
      <x-daohang></x-daohang>
      <commonleft></commonleft>
      <div id="asiderBar">
        <el-menu
          class="el-menu-vertical-demo"
          text-color="black"
          active-text-color="#fff"
          :collapse="isZhank"
          :collapse-transition="false"
          :router="true"
          :default-active="$route.path"
        >
          <el-submenu index="/monitor">
            <template slot="title">
              <i class="el-icon-location"></i>
              <span>物联感知</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/monitor">
                <i class="el-icon-s-custom"></i>
                <span>视频类设备</span>
              </el-menu-item>
              <el-menu-item index="/monitorPerception">
                <i class="el-icon-s-custom"></i>
                <span>感知类设备</span>
              </el-menu-item>
              <el-menu-item index="/monitorInteractive">
                <i class="el-icon-s-custom"></i>
                <span>交互类设备</span>
              </el-menu-item>
              <el-menu-item
                v-for="item in this.proMenu"
                :index="item.path"
                :key="item.path"
              >
                <i :class="item.iconCls"></i>
                <span>{{ item.name }}</span>
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </div>
      <div class="mainpart">
          <el-card id="box-card">
            <div class="title">
                <el-button @click="addDevice"><span >添加设备</span></el-button>
            </div>
            <div style="display: flex; justify-content: space-between">
            <div class="areaTable">
                <el-table
                :data="tableData"
                height="100%"
                :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
                style="width: 100%; background-color: #142245"
                >
                <el-table-column prop="deviceName" align="center" label="设备名称" width="110" >
                    <template v-slot="scope">
                    {{ scope.row.deviceName }}
                    </template>
                </el-table-column>

                <el-table-column prop="deviceSerial"  align="center" label="设备序列号" width="110" >
                    <template v-slot="scope">
                    {{scope.row.deviceSerial }}
                    </template>
                </el-table-column>
                <el-table-column prop="description"  align="center" label="设备描述" width="110" >
                    <template v-slot="scope">
                    {{scope.row.description }}
                    </template>
                </el-table-column>
                <el-table-column prop="intervalTime" align="center" label="设备健康检测间隔时间" width="150" >
                    <template v-slot="scope">
                    {{ scope.row.intervalTime }}
                    </template>
                </el-table-column>
                <el-table-column label="操作" align="center">
                    <template v-slot="scope">
                    <el-tooltip
                        effect="dark"
                        content="修改设备"
                        placement="top"
                        :enterable="true"
                    >
                        <el-button
                        type="primary"
                        icon="el-icon-edit"
                        size="mini"
                        @click="beforeEdit(scope.row.id)"
                        ></el-button>
                    </el-tooltip>
                    <el-tooltip
                        effect="dark"
                        content="删除设备"
                        placement="top"
                        :enterable="false"
                    >
                        <el-button
                        type="primary"
                        icon="el-icon-delete"
                        size="mini"
                        @click="deleteYingshi(scope.row.id)"
                        ></el-button>
                    </el-tooltip>
                    </template>
                </el-table-column>
                </el-table>
            </div>
            </div>

          </el-card>

      </div>
      <!-- 添加设备 -->
      <el-dialog title="添加监控设备"
        :visible.sync="dialogVisible"
        width="30%"
        center
        :close-on-click-modal="false"
        @close="dialogClose">
        <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="addFormRule" >
        <el-form-item label="设备名称" prop="deviceName">
          <el-input size="medium" v-model="addForm.deviceName"></el-input>
        </el-form-item>
        <el-form-item label="设备序列号" prop="deviceSerial">
          <el-input size="medium" v-model="addForm.deviceSerial"></el-input>
        </el-form-item>
        <el-form-item label="设备描述" prop="description">
          <el-input size="medium" v-model="addForm.description"></el-input>
        </el-form-item>
        <el-form-item label="设备健康检测间隔时间" prop="intervalTime">
          <el-input size="medium" v-model="addForm.intervalTime"></el-input>
        </el-form-item>
        </el-form>

        <span slot="footer" class="dialog-footer">
          <el-button size="medium" type="goon" round @click="dialogVisible = false" >取 消</el-button >
          <el-button size="medium" type="goon" round @click="addInteractiveDevice">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 修改监控设备 -->
      <el-dialog
        title="修改监控设备"
        :visible.sync="dialogVisibleEdit"
        width="25%"
        center
        :close-on-click-modal="false"
        @close="dialogClose"
      >
        <el-form
          ref="addFormRef"
          :model="editForm"
          label-width="100px"
          :rules="addFormRule"
        >
        <el-form-item label="设备名称" prop="deviceName">
          <el-input size="medium" v-model="editForm.deviceName"></el-input>
        </el-form-item>
        <el-form-item label="设备序列号" prop="deviceSerial">
          <el-input size="medium" v-model="editForm.deviceSerial"></el-input>
        </el-form-item>
        <el-form-item label="设备描述" prop="description">
          <el-input size="medium" v-model="editForm.description"></el-input>
        </el-form-item>
        <el-form-item label="设备健康检测间隔时间" prop="intervalTime">
          <el-input size="medium" v-model="editForm.intervalTime"></el-input>
        </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button size="medium" type="goon" round  @click="dialogVisibleEdit = false" >取 消</el-button >
          <el-button size="medium" type="goon" round @click="editYingshi" >确 定</el-button>
        </span>
      </el-dialog>
    </div>
  </template>

  <script>
  import commonleft from "../commonleft.vue";
  import EZUIKit from "ezuikit-js";
  var player;
  export default {
    name: "monitorInteractive",
    data() {
      return {
        //设备类型
        deviceType: "interactive_device",
        data: [],
        defaultProps: {
          children: "children",
          label: "name",
        },
        tableData: [],
        dialogVisible: false,
        addForm: {
            deviceName: "",
            deviceSerial: "",
            deviceType: "interactive_device",
            description:" ",
            intervalTime:" ",
            maxThreshold: "",
            threshold: "",
            validateCode: "",
            projectId: this.$root.proId,
            x:" ",
            y:" ",
            },
        addFormRule: {
          deviceSerial: [
            {
              required: true,
              message: "请输入设备序列号,存在英文字母的设备序列号，字母需为大写",
              trigger: "blur",
            },
          ],
        },
        dialogVisibleEdit: false,
        editForm: {},
      };
    },
    props: {
      msg: String,
    },
    methods: {
      //初始化获取项目下的监控设备列表
      async getDeviceList() {
        this.addForm.projectId = this.$root.proId;
        const { data: result } = await this.$http4.get(
          "/findAllByProjectId?projectId=" +
            this.$root.proId +
            "&deviceType=interactive_device"
        );
        if (result.businessCode == "3000") {
          this.tableData = result.data;
        } else {
          this.tableData = [];
          this.$message.error(result.message);
        }
      },
      addDevice() {
        this.dialogVisible = true;
      },
      async addInteractiveDevice() {
        //默认参数
        this.addForm.projectId = this.$root.proId;
        this.addForm.deviceType = "interactive_device";
        //调用接口添加
        const { data: res } = await this.$http4.post("/addDevice", this.addForm);
        if (res.businessCode == "4000") {
          this.getDeviceList();
          this.dialogVisible = false;
        } else {
          return this.$message.error(res.message + "!" + res.data);
        }
      },
      async beforeEdit(id) {
        const { data: res } = await this.$http4.get("/findById?id=" + id);
        if (res.businessCode == "3000") {
          this.editForm = res.data;
          this.dialogVisibleEdit = true;
        } else {
          this.$message.error(res.message);
        }
      },
      async editYingshi() {
        this.editForm.projectId = this.$root.proId;
        this.editForm.deviceType = "interactive_device";
        const { data: res } = await this.$http4.put(
          "/updateDeviceName",
          this.editForm
        );
        if (res.businessCode == "4300") {
          this.getDeviceList();
          this.dialogVisibleEdit = false;
        } else {
          this.$message.error(res.message);
        }
      },
      async deleteYingshi(id) {
        const confirmResult = await this.$confirm(
          "此操作将永久删除该设备, 是否继续?",
          "提示",
          {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }
        ).catch((err) => {
          return err;
        });
        if (confirmResult !== "confirm") {
          return this.$message({
            type: "info",
            message: "已取消删除",
          });
        }
        //请求
        const { data: res } = await this.$http4.delete(
          "/deleteDevice?deviceId=" + id + "&projectId=" + this.$root.proId
        );
        if (res.businessCode != "5000") {
          return this.$message.error("删除该设备失败！");
        }
        this.$message.success("删除该设备成功！");
        this.getDeviceList();
      },
      handleClose(done) {
        this.$confirm("确认关闭？")
          .then((_) => {
            done();
          })
          .catch((_) => {});
      },
      dialogClose() {
        this.$refs.addFormRef.resetFields();
      },
    },
    mounted() {

    },

    created() {
      this.getDeviceList();
    },
    components: {
      commonleft

    },
  };
  </script>

  <style scoped lang="less">

  .title {
    font-weight: 700;
    margin-bottom: 16px;
    span {
        font-size: 15px;
        }
    }
//   .monitorDiv {
//     background-color: #f0f3fa;
//     position: absolute;
//     top: 80px;
//     bottom: 20px;
//     left: 500px;
//     right: 10px;
//     border-radius: 8px;
//     box-shadow: 0 0 8px #acc4fe;
//     display: flex;
//     justify-content: space-between;
//   }
//   #leftcard {
//     width: 900px;
//   }
//   #video-container {
//     width: 860px;
//     height: 740px;
//   }
//   .divright {
//     width: 500px;
//   }
  .content {
    width: 96.35%;
    margin: 0 auto;
    -webkit-box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25);
    box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25);
    border: none;
    border-collapse: collapse;
    margin-top: 20px;
  }
//   .cor4 {
//     background-image: url("../../assets/img/kaoqing/kq1.png"),
//       url("../../assets/img/kaoqing/kq2.png"),
//       url("../../assets/img/kaoqing/kq3.png"),
//       url("../../assets/img/kaoqing/kq4.png") !important;
//     background-repeat: no-repeat, no-repeat, no-repeat, no-repeat !important;
//     background-position: 0 0, 100% 0, 0 100%, 100% 100% !important;
//     padding: 2px !important;
//     border: 0 !important;
//     background-color: transparent !important;
//     cursor: pointer;
//   }

//   .content tr td {
//     width: 33%;
//   }
//   .content tr td .grid-content {
//     height: 108px;
//     -webkit-box-sizing: content-box;
//     box-sizing: content-box;
//     padding: 33px 5px;
//     color: #000;
//     background-color: #e3ecfb;
//     text-align: center;
//   }
//   .content tr td .grid-content:hover {
//     background-color: #004e8c;
//   }
//   .content tr td .grid-content .icon-btn {
//     height: 42px;
//     margin-bottom: 20px;
//   }
//   .content tr td .grid-content .title {
//     font-weight: 700;
//     margin-bottom: 16px;
//     span {
//       font-size: 15px;
//     }
//   }
//   .content tr td .grid-content .slogan {
//     font-size: 16px;
//     color: #000;
//   }
  /deep/ .el-card__body {
    padding: 20px;
  }
  .divright {
    /deep/ .el-card__body {
      padding: 0;
    }
  }

  .areaTree {
    width: 40%;
    height: 570px;
  }
  .areaTable {
    width: 100%;
    height: 570px;
  }

  </style>
