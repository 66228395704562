<template>
    <div class="bg">
        <!-- <monitorinfoleft></monitorinfoleft> -->
        <div id="asiderBar">
            <el-menu
                    class="el-menu-vertical-demo"
                    text-color="black"
                    active-text-color="#fff"
                    :collapse="isZhank"
                    :collapse-transition="false"
                    :router="true"
                    :default-active="$route.path"
            >
                <el-submenu index="/monitor">
                    <template slot="title">
                        <i class="el-icon-location"></i>
                        <span>物联感知</span>
                    </template>
                    <el-menu-item-group>
                        <el-menu-item index="/monitor">
                            <i class="el-icon-s-custom"></i>
                            <span>视频类设备</span>
                        </el-menu-item>
                        <el-menu-item index="/monitorPerception">
                            <i class="el-icon-s-custom"></i>
                            <span>感知类设备</span>
                        </el-menu-item>
                        <el-menu-item index="/monitorInteractive">
                            <i class="el-icon-s-custom"></i>
                            <span>交互类设备</span>
                        </el-menu-item>
                        <el-menu-item
                                v-for="item in this.proMenu"
                                :index="item.path"
                                :key="item.path"
                        >
                            <i :class="item.iconCls"></i>
                            <span>{{ item.name }}</span>
                        </el-menu-item>
                    </el-menu-item-group>
                </el-submenu>
            </el-menu>
        </div>
        <div class="monitorDiv">
            <el-card id="leftcard">
                <div slot="header" class="clearfix">
                    <span>视频监控</span>
                </div>
                <div class="text item">
                    <div id="video-container"></div>
                </div>
            </el-card>
            <div class="divright">
                <el-card id="rightcard">
                    <div slot="header" class="clearfix">
                        <span>视频操作</span>
                    </div>
                    <div class="text item">
                        <table class="content">
                            <tr>
                                <td class="cor4">
                                    <div class="grid-content">
                                        <div class="icon-btn">
                                            <img
                                                    src="../../assets/img/daohang/project-info-selected.png"
                                            />
                                        </div>
                                        <div class="title">
                                            <span>查看设备</span>
                                        </div>
                                        <div class="slogan"><span>查看所有设备在线情况</span></div>
                                    </div>
                                </td>
                                <td class="cor4" @click="addDevice">
                                    <div class="grid-content">
                                        <div class="icon-btn">
                                            <img
                                                    src="../../assets/img/daohang/project-info-selected.png"
                                            />
                                        </div>
                                        <div class="title">
                                            <span>添加设备</span>
                                        </div>
                                        <div class="slogan"><span>添加设备到账号下</span></div>
                                    </div>
                                </td>
                                <td class="cor4">
                                    <div class="grid-content">
                                        <div class="icon-btn">
                                            <img
                                                    src="../../assets/img/daohang/project-info-selected.png"
                                            />
                                        </div>
                                        <div class="title">
                                            <span>查看设备回放</span>
                                        </div>
                                        <div class="slogan">
                      <span data-v-3286e6af=""
                      >通过本地/云存储录像回放录像</span
                      >
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <!--  <tr>

                                              <td class="cor4">
                                                  <div class="grid-content">
                                                      <div class="icon-btn">
                                                          <img src="../../assets/img/daohang/project-info-selected.png">
                                                      </div>
                                                      <div  class="title">
                                                          <span>设备1</span>
                                                      </div>
                                                      <div  class="slogan"><span>设备1画面</span>
                                                      </div>
                                                  </div>
                                              </td>
                                              <td class="cor4">
                                                  <div  class="grid-content">
                                                      <div  class="icon-btn">
                                                          <img  src="../../assets/img/daohang/project-info-selected.png">
                                                      </div>
                                                      <div  class="title">
                                                          <span >设备2</span>
                                                      </div>
                                                      <div  class="slogan"><span>设备2画面</span>
                                                      </div>
                                                  </div>
                                              </td>
                                          </tr>-->
                        </table>
                    </div>
                    <div class="text item">
                        <h2 style="text-align: center">设备列表</h2>
                        <div style="display: flex; justify-content: space-between">
                            <div class="areaTable">
                                <el-table :data="tableData" height="100%"
                                          :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
                                          style="width: 100%; background-color: #142245">
                                    <el-table-column prop="deviceName" align="center" label="设备名称" width="150">
                                        <template v-slot="scope">
                                            <a href="#" @click="chakan(scope.row.deviceSerial,scope.row.channel)">{{
                                                scope.row.deviceName
                                                }}</a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column prop="deviceSerial" align="center" label="设备序列号" width="150">
                                        <template v-slot="scope">
                                            <a href="#" @click="chakan(scope.row.deviceSerial,scope.row.channel)">{{scope.row.deviceSerial
                                                }}</a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column label="操作" align="center">
                                        <template v-slot="scope">
                                            <el-tooltip effect="dark" content="修改设备" placement="top" :enterable="true">
                                                <el-button type="primary" size="mini" icon="el-icon-edit"
                                                           @click="beforeEdit(scope.row.id)"></el-button>
                                            </el-tooltip>
                                            <el-tooltip effect="dark" content="删除设备" placement="top" :enterable="false">
                                                <el-button type="primary" icon="el-icon-delete" size="mini"
                                                           @click="deleteYingshi(scope.row.id)"></el-button>
                                            </el-tooltip>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </div>
                        </div>
                    </div>
                </el-card>
            </div>
        </div>
        <!-- 添加设备 -->
        <el-dialog title="添加监控设备" :visible.sync="dialogVisible" width="25%" center
                   :close-on-click-modal="false" @close="dialogClose">
            <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addFormRule">
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input size="medium" v-model="addForm.deviceName"></el-input>
                </el-form-item>
                <el-form-item label="设备序列号" prop="deviceSerial">
                    <el-input size="medium" v-model="addForm.deviceSerial"></el-input>
                </el-form-item>
                <el-form-item label="设备验证码" prop="validateCode">
                    <el-input size="medium" placeholder="设备机身上的六位大写字母" v-model="addForm.validateCode"></el-input>
                </el-form-item>
                <el-form-item label="设备描述" prop="description">
                    <el-input size="medium" v-model="addForm.description"></el-input>
                </el-form-item>
                <el-form-item label="设备播放通道" prop="channel">
                    <el-input size="medium" v-model="addForm.channel"></el-input>
                </el-form-item>
                <el-form-item label="x坐标" prop="x">
                    <el-input size="medium" v-model="addForm.x"></el-input>
                </el-form-item>
                <el-form-item label="y坐标" prop="y">
                    <el-input size="medium" v-model="addForm.y"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="goon" round @click="dialogVisible = false">取 消</el-button>
        <el-button size="medium" type="goon" round @click="addYingshi">确 定</el-button>
      </span>
        </el-dialog>
        <!-- 修改监控设备 -->
        <el-dialog title="修改监控设备" :visible.sync="dialogVisibleEdit" width="25%" center
                   :close-on-click-modal="false" @close="dialogClose">
            <el-form ref="addFormRef" :model="editForm" label-width="100px" :rules="addFormRule">
                <el-form-item label="设备名称" prop="deviceName">
                    <el-input size="medium" v-model="editForm.deviceName"></el-input>
                </el-form-item>
                <el-form-item label="设备序列号" prop="deviceSerial">
                    <el-input size="medium" v-model="editForm.deviceSerial"></el-input>
                </el-form-item>
                <el-form-item label="设备验证码" prop="validateCode">
                    <el-input size="medium" placeholder="设备机身上的六位大写字母" v-model="editForm.validateCode"></el-input>
                </el-form-item>
                <el-form-item label="设备描述" prop="description">
                    <el-input size="medium" v-model="editForm.description"></el-input>
                </el-form-item>
                <el-form-item label="设备播放通道" prop="channel">
                    <el-input size="medium" v-model="editForm.channel"></el-input>
                </el-form-item>
                <el-form-item label="x坐标" prop="x">
                    <el-input size="medium" v-model="editForm.x"></el-input>
                </el-form-item>
                <el-form-item label="y坐标" prop="y">
                    <el-input size="medium" v-model="editForm.y"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button size="medium" type="goon" round @click="dialogVisibleEdit = false">取 消</el-button>
        <el-button size="medium" type="goon" round @click="editYingshi">确 定</el-button>
      </span>
        </el-dialog>
    </div>
</template>

<script>
    // import monitorinfoleft from "./monitorInfoLeft"
    import EZUIKit from "ezuikit-js";

    var player;
    export default {
        name: "monitor",
        data() {
            return {
                //设备类型
                deviceType: "video_device",
                accessToken: "at.2j4fsufsd2zmapqxcj84a8jr3w885p4n-1sez47tb21-0mjmxzf-dr6k2cotx",
                qingxidu: "流畅",
                url: "ezopen://open.ys7.com/FA3433497/1.live",
                hasPlayer: false,
                //默认播放第一个设备视频，暂存第一个设备的序列号
                serialCode: "FA3433497",
                channel: "1",
                kaiqi: "开启",
                data: [],
                defaultProps: {
                    children: "children",
                    label: "name",
                },
                tableData: [],
                dialogVisible: false,
                addForm: {
                    deviceName: "",
                    deviceSerial: "",
                    deviceType: this.deviceType,
                    validateCode: "",
                    description: "",
                    projectId: this.$root.proId,
                    channel: "",
                    x: "",
                    y: ""
                },
                addFormRule: {
                    deviceSerial: [{required: true, message: "请输入设备序列号,存在英文字母的设备序列号，字母需为大写", trigger: "blur"},],
                    validateCode: [{required: true, message: '请输入设备验证码，设备机身上的六位大写字母', trigger: 'blur'}],
                    deviceName: [{required: true, message: '设备名称不能为空', trigger: 'blur'}],
                    channel: [{required: true, message: '设备播放通道不能为空', trigger: 'blur'}],
                },
                dialogVisibleEdit: false,
                editForm: {},
            };
        },
        props: {
            msg: String,
        },
        methods: {
            async getAccessToken() {
                const {data: result} = await this.$http4.get(
                    "/monitor/token/findByProjectId?projectId=" + this.$root.proId
                );
                if (result.businessCode == "3000") {
                    this.accessToken = result.data;
                } else {
                    this.$message.error(result.message);
                }
            },
            //初始化获取项目下的监控设备列表
            async getDeviceList() {
                this.addForm.projectId = this.$root.proId;
                const {data: result} = await this.$http4.get(
                    "/findAllByProjectId?projectId=" + this.$root.proId + "&deviceType=video_device");
                if (result.businessCode == "3000") {
                    this.tableData = result.data;
                    //默认播放第一个设备视频
                    if (result.data.length) {
                        this.serialCode = result.data[0].deviceSerial;
                        this.channel = result.data[0].channel;
                        this.chakan(this.serialCode, this.channel);
                    }
                } else {
                    this.tableData = [];
                    this.$message.error(result.message);
                }
            },
            addDevice() {
                this.addForm.monitorType = this.options[0].value;
                this.dialogVisible = true;
            },
            async addYingshi() {
                //默认参数
                this.addForm.projectId = this.$root.proId;
                this.addForm.deviceType = "video_device";
                //调用接口添加
                const {data: res} = await this.$http4.post("/addDevice", this.addForm);
                if (res.businessCode == "4000") {
                    this.getDeviceList();
                    this.dialogVisible = false;
                } else {
                    return this.$message.error(res.message + "!" + res.data);
                }
            },
            async beforeEdit(id) {
                const {data: res} = await this.$http4.get("/findById?id=" + id);
                if (res.businessCode == "3000") {
                    this.editForm = res.data;
                    this.dialogVisibleEdit = true;
                } else {
                    this.$message.error(res.message);
                }
            },
            async editYingshi() {
                this.editForm.projectId = this.$root.proId;
                this.editForm.deviceType = "video_device";
                const {data: res} = await this.$http4.put(
                    "/updateDeviceName",
                    this.editForm
                );
                if (res.businessCode == "4300") {
                    this.getDeviceList();
                    this.dialogVisibleEdit = false;
                } else {
                    this.$message.error(res.message);
                }
            },
            async deleteYingshi(id) {
                const confirmResult = await this.$confirm(
                    "此操作将永久删除该设备, 是否继续?",
                    "提示",
                    {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "warning",
                    }
                ).catch((err) => {
                    return err;
                });
                if (confirmResult !== "confirm") {
                    return this.$message({
                        type: "info",
                        message: "已取消删除",
                    });
                }
                //请求
                const {data: res} = await this.$http4.delete(
                    "/deleteDevice?deviceId=" + id + "&projectId=" + this.$root.proId
                );
                if (res.businessCode != "5000") {
                    return this.$message.error("删除该设备失败！");
                }
                this.$message.success("删除该设备成功！");
                this.getDeviceList();
            },

            async chakan(deviceSerial, channel) {
                // console.log("开始播放")
                if (this.hasPlayer) {
                    player.stop();
                }
                const {data: res} = await this.$http4.post("/getDeviceAddress", {
                    accessToken: this.accessToken,
                    channelNo: channel,
                    code: "",
                    deviceSerial: deviceSerial,
                    expireTime: 0,
                    quality: 2,
                    supportH265: 0,
                });
                if (res.businessCode == "3000") {
                    this.url = res.data.url;
                    player = new EZUIKit.EZUIKitPlayer({
                        autoplay: true,
                        id: "video-container",
                        accessToken: this.accessToken,
                        url: this.url,
                        template: "simple", // simple - 极简版;standard-标准版;security - 安防版(预览回放);voice-语音版；
                        // 视频上方头部控件
                        header: ["capturePicture", "save", "zoom"], // 如果templete参数不为simple,该字段将被覆盖
                        //plugin: ['talk'],                       // 加载插件，talk-对讲
                        // 视频下方底部控件
                        footer: ["talk", "broadcast", "hd", "fullScreen"], // 如果template参数不为simple,该字段将被覆盖
                        // audio: 1, // 是否默认开启声音 0 - 关闭 1 - 开启
                        // openSoundCallBack: data => console.log("开启声音回调", data),
                        // closeSoundCallBack: data => console.log("关闭声音回调", data),
                        // startSaveCallBack: data => console.log("开始录像回调", data),
                        // stopSaveCallBack: data => console.log("录像回调", data),
                        // capturePictureCallBack: data => console.log("截图成功回调", data),
                        // fullScreenCallBack: data => console.log("全屏回调", data),
                        // getOSDTimeCallBack: data => console.log("获取OSDTime回调", data),
                        width: 1135,
                        height: 630,
                    });
                    this.hasPlayer = true;
                } else {
                    this.$message.error(res.message);
                }
            },
            handleClose(done) {
                this.$confirm("确认关闭？")
                    .then((_) => {
                        done();
                    })
                    .catch((_) => {
                    });
            },
            dialogClose() {
                this.$refs.addFormRef.resetFields();
            },
            /*     changeQingxi(){
                        if(this.qingxidu=='流畅'){
                            this.qingxidu='高清'
                            this.url='ezopen://open.ys7.com/F94843961/1.hd.live'
                            player.play();

                        }else {
                            this.qingxidu='流畅'
                            this.url='ezopen://open.ys7.com/F94843961/1.live'
                            player.play();
                        }
                    }*/
        },
        mounted() {
            this.getDeviceList();
        },
        created() {
            this.getAccessToken();
        },
        components: {
            // monitorinfoleft,
        },
    };
</script>

<style scoped lang="less">
    .monitorDiv {
        background-color: #f0f3fa;
        position: absolute;
        top: 80px;
        bottom: 20px;
        left: 500px;
        right: 10px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        justify-content: space-between;
    }

    #leftcard {
        width: 900px;
    }

    #video-container {
        width: 860px;
        height: 740px;
    }

    .divright {
        width: 500px;
    }

    .content {
        width: 96.35%;
        margin: 0 auto;
        -webkit-box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25);
        box-shadow: 0 12px 24px -6px rgba(9, 30, 66, 0.25);
        border: none;
        border-collapse: collapse;
        margin-top: 20px;
    }

    .cor4 {
        background-image: url("../../assets/img/kaoqing/kq1.png"),
        url("../../assets/img/kaoqing/kq2.png"),
        url("../../assets/img/kaoqing/kq3.png"),
        url("../../assets/img/kaoqing/kq4.png") !important;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat !important;
        background-position: 0 0, 100% 0, 0 100%, 100% 100% !important;
        padding: 2px !important;
        border: 0 !important;
        background-color: transparent !important;
        cursor: pointer;
    }

    .content tr td {
        width: 33%;
    }

    .content tr td .grid-content {
        height: 108px;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        padding: 33px 5px;
        color: #000;
        background-color: #e3ecfb;
        text-align: center;
    }

    .content tr td .grid-content:hover {
        background-color: #004e8c;
    }

    .content tr td .grid-content .icon-btn {
        height: 42px;
        margin-bottom: 20px;
    }

    .content tr td .grid-content .title {
        font-weight: 700;
        margin-bottom: 16px;

        span {
            font-size: 15px;
        }
    }

    .content tr td .grid-content .slogan {
        font-size: 16px;
        color: #000;
    }

    /deep/ .el-card__body {
        padding: 20px;
    }

    .divright {
        /deep/ .el-card__body {
            padding: 0;
        }
    }

    /*/deep/ .el-tree{
        background-color: #142245;
        color: #eef5ff;
        font-size: 16px;

    }
    /deep/ .el-tree-node:focus > .el-tree-node__content {
        background-color: #66b1ff87 !important;
    }
    /deep/ .el-tree-node__content:hover {    background-color: #66b1ff87;
    }
    /deep/ .el-tree--highlight-current .el-tree-node.is-current>.el-tree-node__content {
        background-color: #66b1ff87;
    }*/
    .areaTree {
        width: 40%;
        height: 570px;
    }

    .areaTable {
        width: 100%;
        height: 570px;
    }

    /*/deep/ .el-table, /deep/.el-table__expanded-cell{
        background-color: #142245;
    }
    /deep/ .el-table td,   /deep/.el-table th.is-leaf{
        border-bottom: 1px solid #273F76;
    }
    /deep/ .el-table__row {
        background-color: #142245 !important;
        color: white;
    }
    /deep/ .el-table tbody tr:hover>td {
        background-color: #3B5988;
    }*/
</style>
