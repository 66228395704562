<template>
    <div class="site-wrapper" >
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div id="CategoryTree">
                    <el-input @clear="insertActBtCategory_clear" placeholder="输入关键字进行过滤" clearable v-model="filterText">
                    </el-input>
                    <el-tree class="filter-tree" :data="dataCategory" :props="defaultCategoryProps" default-expand-all
                        :filter-node-method="filterCategoryNode" ref="tree" show-checkbox node-key="categoryId"
                        :check-strictly="true" @check-change="handleCheckChange" @node-click="nodeClick">
                        <!-- 处理菜单内容过长 -->
                        <span class="custom-tree-node" slot-scope="{ node }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <span> {{ node.label | ellipsis(8) }} </span>
                            </el-tooltip>
                        </span>
                    </el-tree>
                </div>
                <div id="pageList">
                    <div class="addActBtCategory">
                        <div class="catleft">
                            <span class="title-left">我的流程</span>
                        </div>
                        <div style="display: flex;justify-content: flex-end">
                            <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                            </el-button>
                        </div>
                    </div>
                    <!-- 列表 -->
                    <div>
                        <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                            <template>
                                <el-empty :image-size="100" description="暂无数据"></el-empty>
                            </template>
                            <el-table-column prop="processDefinitionName" label="流程名称" width="200" show-overflow-tooltip>
                                <template #default="scope">
                                    <el-tag @click="getInfo2(scope.row)" style="cursor: pointer">{{
                                        scope.row.processDefinitionName
                                    }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="processDefinitionKey" label="流程编码" width="180" show-overflow-tooltip />
                            <el-table-column prop="processInstanceName" label="实例名称" width="180" show-overflow-tooltip>
                                <template #default="scope">
                                    <span v-if="scope.row.processInstanceName">{{
                                        scope.row.processInstanceName.split("_")[1]
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="processDefinitionVersion" label="流程版本" width="110">
                                <template #default="scope">
                                    <el-tag type="success"><span>V{{ scope.row.processDefinitionVersion }}</span></el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="processStatus" label="流程状态" width="100">
                            </el-table-column>
                            <el-table-column prop="startTime" label="开始时间" width="210">
                                <template #default="scope">
                                    <div style="display: flex; align-items: center">
                                        <el-icon>
                                            <timer />
                                        </el-icon>
                                        <span style="margin-left: 10px">{{
                                            convertUTCTimeToLocalTime(scope.row.startTime)
                                        }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="endTime" label="结束时间" width="210">
                                <template #default="scope">
                                    <div style="display: flex; align-items: center">
                                        <el-icon>
                                            <timer />
                                        </el-icon>
                                        <span style="margin-left: 10px">{{
                                            convertUTCTimeToLocalTime(scope.row.endTime)
                                        }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template #default="scope">
                                    <el-button size="small" :icon="EditPen" @click="getInfo(scope.row)">详情</el-button>
                                    <el-button size="small" v-if="scope.row.activitiInstanceVO" :icon="EditPen"
                                        @click="beBack(scope.row)">强制驳回</el-button>
                                    <!-- <el-button
                  size="small"
                  :icon="View"
                  @click="getInfo2(scope.row)"
                  >表单详情</el-button
                > -->
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                            <el-pagination background @current-change="handleChangePageNum"
                                @size-change="handleChangePageSize"
                                layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="流程详情" width="70%">
            <p v-html="dataSvg" style="text-align: center"></p>
            <el-table :data="formData" border size="large" style="height: 80%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                <template>
                    <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column prop="taskId" label="任务编号" width="180">
                    <template #default="scope">
                        <el-tag>{{ scope.row.taskId }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="userId" label="审批人员" width="120" />
                <el-table-column prop="taskName" label="审批节点" width="120" />
                <el-table-column prop="startTime" label="开始时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                convertUTCTimeToLocalTime(scope.row.startTime)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="taskName" label="审批进度" width="120">
                    <template #default="scope">
                        <span>{{ scope.row.endTime != null ? "结束" : "审批中" }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="type" label="审批类型" width="120" />
                <el-table-column prop="time" label="审批时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                convertUTCTimeToLocalTime(scope.row.time)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="message" label="批注"> </el-table-column>
            </el-table>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisibleForm" title="表单详情" width="50%" destroy-on-close :show-close="true">
            <template #header="{ titleId, titleClass }">
                <div class="my-header">
                    <h4 :id="titleId" :class="titleClass">表单详情</h4>
                </div>
            </template>
            <template #default>
                <form-create :rule="rule" :option="option" v-if="!isCustomProcess" v-model="fromvalue"></form-create>
                <Suspense v-else>
                    <component :is="AsyncComp" :id="id"></component>
                    <template #fallback> <page-loading /> </template>
                </Suspense>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "../proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                finishedBeforeDate: "",
                pageNum: 1,
                pageSize: 10,
                deploymentCategory: [],
                processDefinitionKey: "",
                processDefinitionName: "",
                processDefinitionVersion: null,
                startedAfterDate: "",
            },
            total: 0,
            tableData: [],
            dialogVisible: false,
            dataSvg: '',
            formData: [],
            rule: [],
            fromvalue: {},
            option: {
                submitBtn: false,
            },
            isCustomProcess: false,
            dialogVisibleForm: false,
            id: null,
            AsyncComp: null,

        }
    },
    methods: {
        async getInitiation() {
            const { data: res } = await this.$http2.post('/ProcessesRelate/query/ProcessesRelatePageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
        async  getInfo(row) {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/' + row.processInstanceId)
            this.dataSvg = res;
            const { data: res2 } = await this.$http2.get('/ActRuTask/query/TaskCommentByProcessInstanceId/' + row.processInstanceId)
            this.formData = res2.data;
            this.dialogVisible = true;
        },
        async  beBack(row) {
            const params = new URLSearchParams();
            params.append("businessCode", row.activitiInstanceVO.businessCode); // 封装对象
            params.append("instanceId", row.activitiInstanceVO.instanceId);
            const { data: res } = await this.$http2.post('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/', params)
            if (res.businessCode == 4000) {
                ElMessage.success("提交成功");
                this.getInitiation();
            } else {
                ElMessage.error("提交失败");
            }
        },
        handleChangePageSize  (val)  {
            this.queryPage.pageSize = val;
            this.getInitiation();
        },
        handleChangePageNum (val){
            this.queryPage.pageNum = val;
            this.getInitiation();
        }
    },
    created() {
        this.getInitiation()
    },
    components: {
        promeau,
    },
    watch: {

    },
    mounted() {
    },
}

</script>

<style scoped lang="less">
.site-wrapper {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100vh;
}

.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .insertActBtCategory {
        width: 300px;
        margin-right: 8px;
    }

    .addActBtCategory {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .catleft {
            margin-top: 10px;
        }
    }

    #CategoryTree {
        width: 14%;
        border: 1px solid #E7E7E7;
        height: 96%
    }

    #pageList {
        width: 85%
    }

    .filter-tree {
        overflow: auto;
        flex: 1;
        width: 170px;
        height: 600px;
        margin: 10px;
    }

    .title-left {
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
        width: 140px;
        height: 22px;
        border-left: 5px solid #569ee3;
        text-align: left;
    }
}
</style>