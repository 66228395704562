<template>
  <el-container class="contain">
    <el-header>
      <daohangnav></daohangnav>
    </el-header>
    <el-container>
      <router-view name='left'></router-view>
      <el-main>
        <router-view name='main'></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
  import daohang from './daohanglan.vue';
  export default {
    data () {
      return {
      }
    },
    methods: {

    },
    components:{
      daohangnav: daohang,
    },

  }
</script>

<style lang="less" scoped>
  .contain {
    height: 100%;
    background-color: #eef3f9;
  }

  .el-header {
    height: 74px !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 0;
    align-items: center;
    color: #fff;
  }
  .el-main {
    width: 1760px;
    background-color: #eff6fb ;
    padding: 0px !important;
  }
  /deep/ .el-container{
    background-color: #eff6fb !important;
  }

</style>
