<template>
    <div style="width: 100%;height: 90%;" id="bottomEcharts">

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    var myChart1;
    export default {
        name: "ecanquan",
        data(){
          return{
              zhichudata:[],
              shourudata:[]
          }
        },
        mounted(){
            this.echart();
        },
        methods:{
            echart(){
                var date=new Date();
                var xdata=[];
                var cnum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
                for(var i=0;i<=11;i++){
                    xdata.push(cnum[i]+'月')
                }
                xdata.push('总计')
                myChart1 = echarts.init(document.getElementById('bottomEcharts'));
                var option = {
                    legend: {
                       // data: ['合同额(亿)', '支付额(亿)', '收款额(亿)']
                        data: [ '支付额(万)', '收款额(万)']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: xdata
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    grid: {
                        top:'19%',
                        left: '4%',
                        right: '4%',
                        bottom: '0px',
                        containLabel: true
                    },
                    series: [
                       /* {
                            name: '合同额(亿)',
                            type: 'bar',
                            barGap: 0,
                            emphasis: {
                                focus: 'series'
                            },
                            data: [320, 332, 301, 334, 390, 311, 320, 290, 190, 90, 290, 240]
                        },*/
                        {
                            name: '支付额(万)',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.zhichudata,
                            label: {
                                show: true,
                                position: 'inside',
                                color:'black'
                            },
                        },
                        {
                            name: '收款额(万)',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: this.shourudata,
                            label: {
                                show: true,
                                position: 'inside',
                                color:'black'
                            },
                        }

                    ]
                };
                myChart1.setOption(option);
                window.addEventListener("resize", function () {
                    myChart1.resize();
                });
            },
            getMsg(zhichu,shouru){
                console.log(zhichu)
                for(let i in zhichu){
                    zhichu[i]=(zhichu[i] / 10000).toFixed(0);
                    shouru[i]=(shouru[i] / 10000).toFixed(0);
                }
                this.zhichudata=zhichu
                this.shourudata=shouru
                this.echart()
            }
        }
    }
</script>

<style scoped>

</style>
