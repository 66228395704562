<template>
    <div class="bg">
        <pro-left></pro-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                    <el-button   @click="addbumen">新增项目组部门</el-button>
                </div>
                <div class="serverTable">
                    <!-- <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="60"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="teamName"
                                label="成员名"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="duties"
                                label="成员职务"
                                width="250"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="phoneNumber"
                                label="成员电话"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="tenant"
                                label="成员信息"
                                width="310"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini" v-has="'editrizhi'">修改</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delrizhi'">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table> -->
                    <el-table
                            :data="bumenData"
                            row-key="id"
                            height="100%"
                            border
                            :tree-props="{children: 'childrenList'}"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="60"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="departmentName"
                                label="部门及成员"
                                width="450"
                        >
                        </el-table-column>
                        <el-table-column prop="feedBack"  label="类型" sortable  width="200" >  
                            <template v-slot="scope">
                                <el-tag v-if="scope.row.vshow==undefined" type="success">部门</el-tag>
                                <el-tag  v-else-if="scope.row.vshow == 1" >成员</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="addperson(scope.row)" icon="el-icon-edit" size="mini" v-if="scope.row.vshow == undefined">添加项目组成员</el-button>
                                <el-button  type="primary" @click="editDialog(scope.row)" icon="el-icon-edit" size="mini"  v-if="scope.row.vshow == undefined">部门修改</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini"  v-if="scope.row.vshow == undefined">删除</el-button>
                                <el-button  type="primary" @click="editDialog_Ry(scope.row)" icon="el-icon-edit" size="mini" v-if="scope.row.vshow == 1">成员修改</el-button>
                                <el-button  type="primary" @click="deleteMsg_Ry(scope.row.id)" icon="el-icon-delete" size="mini"  v-if="scope.row.vshow == 1">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增项目组部门"
                :visible.sync="dialogVisiblebumen"
                width="45%"
                center
                :close-on-click-modal="false"
        >
             <el-form ref="addFormRef2" :model="addForm2" label-width="120px" :rules="bumenFormRules" >
                <el-form-item label="部门名称" prop="departmentName">
                    <el-input  v-model="addForm2.departmentName"></el-input>
                </el-form-item>
                <el-form-item label="上级部门" prop="parentId">
                    <el-cascader
                       :options="treeData"
                       v-model="addForm2.parentId"
                       @change="bumenChange"
                       :props="bumenProps"
                    >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="排序字段" prop="sort">
                    <el-input-number v-model="addForm2.sort"  :min="0" :max="10000" label=""></el-input-number>
                </el-form-item>
            </el-form> 
            <span slot="footer" class="dialog-footer">
            <el-button round type="goon" size="medium" @click="dialogVisiblebumen = false">取 消</el-button>
            <el-button round type="goon" size="medium"  @click="isaddBumen" >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="修改项目组部门"
                :visible.sync="editdialogVisible"
                width="45%"
                center
                :close-on-click-modal="false"
        >
             <el-form ref="addFormRef2" :model="editForm" label-width="120px" :rules="bumenFormRules" >
                <el-form-item label="部门名称" prop="departmentName">
                    <el-input  v-model="editForm.departmentName"></el-input>
                </el-form-item>
                <el-form-item label="排序字段" prop="sort">
                    <el-input-number v-model="editForm.sort"  :min="0" :max="10000" label=""></el-input-number>
                </el-form-item>
            </el-form> 
            <span slot="footer" class="dialog-footer">
            <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
            <el-button round type="goon" size="medium"  @click="editbumen" >确 定</el-button>
            </span>
        </el-dialog>
      
        <el-dialog
                title="新增项目组成员"
                :visible.sync="dialogVisible"
                width="45%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
             <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="editFormRules" >
                <el-form-item label="成员名称" prop="teamName">
                    <el-input  v-model="addForm.teamName"></el-input>
                </el-form-item>
                <el-form-item label="成员职务" prop="duties">
                    <el-input v-model="addForm.duties"></el-input>
                </el-form-item>
                <el-form-item label="成员电话" prop="phoneNumber">
                    <el-input v-model="addForm.phoneNumber"></el-input>
                </el-form-item>
                <el-form-item label="成员信息" prop="tenant">
                    <el-input   type="textarea" :rows="3" v-model="addForm.tenant"></el-input>
                </el-form-item>
            </el-form> 
            <span slot="footer" class="dialog-footer">
            <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
            <el-button round type="goon" size="medium"  @click="addprooDiary" >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="修改项目组成员"
                :visible.sync="editdialogVisible_Ry"
                width="40%"
                center
                :close-on-click-modal="false"
        >
            <el-form ref="editFormRef" :model="editForm_Ry" label-width="100px" :rules="editFormRules_Ry">
                <el-form-item label="成员名称" prop="teamName">
                    <el-input  v-model="editForm_Ry.teamName"></el-input>
                </el-form-item>
                <el-form-item label="成员所属部门" prop="departmentId">
                    <el-cascader
                       :options="treeData"
                       v-model="editForm_Ry.departmentId"
                       @change="bumenChange2"
                       :props="bumenProps"
                    >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="成员职务" prop="duties">
                    <el-input v-model="editForm_Ry.duties"></el-input>
                </el-form-item>
                <el-form-item label="成员电话" prop="phoneNumber">
                    <el-input v-model="editForm_Ry.phoneNumber"></el-input>
                </el-form-item>
                <el-form-item label="成员信息" prop="tenant">
                    <el-input   type="textarea" :rows="3" v-model="editForm_Ry.tenant"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible_Ry = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditProDiary">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import proLeft from './proInfoLeft'
    export default {
        name: "proshigong",
        data(){
            return{
                tableData:[],
                bumenData:[],
                treeData:[],
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                proshow:false,
                dialogVisible:false,
                editdialogVisible:false,
                editdialogVisible_Ry:false,
                addForm:{
                    departmentId:'',
                    teamName :'',
                    duties :'',
                    phoneNumber :'',
                    tenant:'',
                    projectId:this.$root.proId,
                },
                addForm2:{
                    departmentName:'',
                    parentId:'',
                    sort:0,
                    projectId:this.$root.proId,
                },
                editForm:{},
                editForm_Ry:{},
                editFormRules:{
                    teamName:[{required:true,message:'请输入成员名称',trigger:'blur'}],
                    duties:[{required:true,message:'请输入成员职务',trigger:'blur'}]
                },
                editFormRules_Ry:{
                    teamName:[{required:true,message:'请输入成员名称',trigger:'blur'}],
                    departmentId:[{required:true,message:'请选择部门',trigger:'blur'}]
                },
                bumenFormRules:{
                    departmentName:[{required:true,message:'请输入部门名称',trigger:'blur'}],
                    parentId:[{required:true,message:'请选择上级部门',trigger:'blur'}]
                },
                bumenProps:{
                    label: "departmentName",
                    value:'id',
                    checkStrictly: true,
                    children:'childrenList'
                },
                fileList:[],
                fileListEdit:[],
                fileListEditDeleteID:[],
                fileListEditAdd:[],
                dialogVisiblebumen:false,
                // someData:{
                //     input:'测试',
                //     input1:'2020',
                // }
            }
        },
        created(){
            this.getbumen()
            this.getbumen1()
        },
        methods:{
            async getbumen(){
                const {data:res}= await this.$http1.get('/v1.0/enterprises/project-department/query/ProjectDepartmentById?projectId='+this.$root.proId)
                if(res.businessCode==3000){
                        this.bumenData=res.data;
                        for (let i = 0; i < this.bumenData.length; i++) {
                            if(this.bumenData[i].teams.length > 0){
                                for(let m = 0; m < this.bumenData[i].teams.length; m++){
                                    this.bumenData[i].childrenList.push({"departmentName":this.bumenData[i].teams[m].teamName,"id":this.bumenData[i].teams[m].id,"vshow": "1","departmentId":this.bumenData[i].teams[m].departmentId,
                                "duties":this.bumenData[i].teams[m].duties,"phoneNumber":this.bumenData[i].teams[m].phoneNumber,"projectId":this.bumenData[i].teams[m].projectId
                                ,"teamName":this.bumenData[i].teams[m].teamName,"tenant":this.bumenData[i].teams[m].tenant})

                                }
                            }
                            if(this.bumenData[i].childrenList.length > 0){
                                for(let m = 0; m < this.bumenData[i].childrenList.length; m++){
                                    if(this.bumenData[i].childrenList[m].teams !== undefined){
                                        for(let j = 0; j < this.bumenData[i].childrenList[m].teams.length; j++){
                                        if(this.bumenData[i].childrenList[m].teams.length > 0){
                                            this.bumenData[i].childrenList[m].childrenList.push({"departmentName":this.bumenData[i].childrenList[m].teams[j].teamName,"id":this.bumenData[i].childrenList[m].teams[j].id,"vshow": "1"
                                        ,"departmentId":this.bumenData[i].childrenList[m].teams[j].departmentId,"duties":this.bumenData[i].childrenList[m].teams[j].duties,"phoneNumber":this.bumenData[i].childrenList[m].teams[j].phoneNumber
                                    ,"projectId":this.bumenData[i].childrenList[m].teams[j].projectId,"teamName":this.bumenData[i].childrenList[m].teams[j].teamName,"tenant":this.bumenData[i].childrenList[m].teams[j].tenant})
                                        }
                                    }
                                    }
                                }
                            }
                        }
                        }
            },
            async getbumen1(){
                const {data:res}= await this.$http1.get('/v1.0/enterprises/project-department/query/ProjectDepartmentById?projectId='+this.$root.proId)
                if(res.businessCode==3000){
                        this.treeData=[{
                            "id": "init_level",
                            "departmentName": '部门',
                            parentId:'init_level',
                            "sort": 0,
                            childrenList:res.data
                        }]
                        }
            },
            bumenChange(val) {
                this.addForm2.parentId=val[val.length-1]
            },
            bumenChange2(val) {
                this.editForm_Ry.departmentId=val[val.length-1]
            },
            addbumen(){
                this.dialogVisiblebumen = true
            },
            async isaddBumen(){
                const {data:res}= await this.$http1.post('/v1.0/enterprises/project-department/insert/ProjectDepartment',this.addForm2, {
                    'Content-Type': 'application/json'
                })
                if(res.businessCode!='4000'){
                    return this.$message.error('添加项目组部门失败！'+res.message)
                }
                this.$message.success('添加项目组部门成功！');
                this.dialogVisiblebumen=false;
                this.getbumen();
            },
            addperson(row){
              this.addForm.departmentId=row.id
              this.dialogVisible=true
            },
            addrizhi(){
                this.dialogVisible = true
            },
            async addprooDiary(){
                const {data:res}= await this.$http1.post('/v1.0/enterprises/project-team/save',this.addForm)
                if(res.businessCode!='4000'){
                    return this.$message.error('添加项目项目组成员失败！'+res.message)
                }
                this.$message.success('添加项目项目组成员成功！');
                this.dialogVisible=false;
                this.getbumen();
            },
            async editDialog(row){
                this.editForm=row;
                this.editdialogVisible=true
            },
            async editDialog_Ry(row){
                console.log(row)
                this.editForm_Ry=row;
                this.editdialogVisible_Ry=true
            },
            async editbumen(){
                const {data:res}= await this.$http1.put('/v1.0/enterprises/project-department/update/ProjectDepartment',this.editForm, {
                    'Content-Type': 'application/json'
                })
                if(res.businessCode!='4000'){
                    return this.$message.error('修改项目组部门失败！'+res.message)
                }
                this.$message.success('修改项目组部门成功！');
                this.editdialogVisible=false;
                this.getbumen();
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目部门, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-department/delete/ProjectDepartmentById?id='+id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除项目部门失败！'+res.message)
                }
                this.$message.success('删除项目部门成功！');
                this.getbumen();
            },
            async deleteMsg_Ry(id){
                const confirmResult = await this.$confirm('此操作将永久删除该部门成员, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-team/delete/'+id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除部门成员失败！'+res.message)
                }
                this.$message.success('删除部门成员成功！');
                this.getbumen();
            },
            handleRemove(file, fileList) {
                this.fileList=fileList
            },
            filechange(file,fileList){
                this.fileList=fileList
            },
            handleRemoveEdit(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd.length; i++) {
                        if (this.fileListEditAdd[i] == file) {
                            this.fileListEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            filechangeEdit(file,fileList){
                this.fileListEditAdd.push(file)
                this.fileListEdit=fileList
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            async iseditProDiary(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    this.proshow=true
                    const {data:res}= await this.$http1.put('/v1.0/enterprises/project-team/update', this.editForm_Ry)
                    if(res.businessCode!='4300'){
                        return this.$message.error('修改项目组成员失败！'+res.message)
                    }
                    this.$message.success('修改项目组成员成功！');
                    this.getbumen()
                    this.editdialogVisible_Ry=false;
                })
            },
        },
        components:{
            proLeft,
        }
    }
</script>

<style scoped lang="less">
    /deep/ .cell{
        max-height: 90px;
        overflow-y: auto !important;
    }
</style>
