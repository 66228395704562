<template>
    <div class="bg">
        <div id="hetong">
            <div id="htheader">
                <el-button  icon="el-icon-circle-plus-outline"  @click="addRootType" v-show="hasroot">添加根目录</el-button>
                <el-button  icon="el-icon-circle-plus-outline"  @click="addHetong" v-has="'addht'">添加合同</el-button>
                <el-button  icon="el-icon-circle-plus-outline"  @click="addHetongPlan">新增合同订立流程</el-button>
               <!-- <el-button icon="el-icon-search">编辑合同</el-button>-->
               <!-- <el-button  icon="el-icon-search">删除合同</el-button>
                <el-button  icon="el-icon-search">下载合同</el-button>
                <el-button  icon="el-icon-search">导出合同清单</el-button>
                <el-button icon="el-icon-search">导入清单</el-button>-->
            </div>
            <el-tabs v-model="activeName"   type="border-card">
                    <el-tab-pane label="合同文件" name="first">
                        <div class="hetongDiv">
                            <div id="httree">
                                    <el-tree
                                        :data="data"
                                        node-key="id"
                                        ref="tree"
                                        :props="defaultProps"
                                        default-expand-all
                                        @node-click="handleCheckChange"
                                        :expand-on-click-node="false">
                            <div class="custom-tree-node" slot-scope="{ node, data }">
                                <el-tooltip class="item" effect="dark" :content="data.parent.name" placement="top-start">
                                    <span><i class="el-icon-folder-opened"></i> {{ data.parent.name | ellipsis(12) }}</span>
                                </el-tooltip>

                                <span>
                        <el-button
                                type="text"
                                size="mini"
                                @click="() => append(data)" v-has="'edithttree'">
                        <i class="el-icon-circle-plus-outline"></i>
                        </el-button>
                        <el-button
                                type="text"
                                size="mini"
                                @click="() => remove(node, data)" v-has="'edithttree'">
                            <i class="el-icon-remove-outline"></i>
                        </el-button>
                        </span>
                        </div>
                                    </el-tree>
                                </div>
                                <div id="htright">
                                <div class="serverTable">
                                <el-table
                                        :data="fileData"
                                        :header-cell-style="{background:'#e3ecfb',color:'black'}"
                                        height="100%"
                                    >
                                    <el-table-column
                                            type="index"
                                            label="序号"
                                            width="50">
                                        <template v-slot="scope">
                                            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="contractNum"
                                            label="编号"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="name"
                                            label="合同名称"
                                            width="140">
                                        <template v-slot="scope">
                                        <a href="#" @click="xiangqing(scope.row.id)">{{scope.row.name}} </a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="signDate"
                                            label="签订日期"
                                            width="98">
                                    </el-table-column>
                                    <el-table-column
                                            prop="totalAmount"
                                            label="总金额(元)"
                                            width="90">
                                        <template v-slot="scope">
                                            {{scope.row.totalAmount}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="cooperationUnit"
                                            label="合作单位"
                                            width="120">
                                    </el-table-column>
                                    <el-table-column
                                            prop="isSubContract"
                                            label="分包合同"
                                            width="80">
                                        <template v-slot="scope">
                                            <span v-if="scope.row.isSubContract">是</span>
                                            <span v-else>否</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="securityDeposit"
                                            label="履约保证金(元)"
                                            width="115">
                                        <template v-slot="scope">
                                            {{scope.row.securityDeposit}}
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="warrantyAmount"
                                            label="质保金(元)"
                                            width="90">
                                        <template v-slot="scope">
                                            {{scope.row.warrantyAmount}}
                                        </template>
                                    </el-table-column>
                                    <!--<el-table-column
                                            prop="createUserName"
                                            label="创建人"
                                            width="70">
                                    </el-table-column>-->
                                    <el-table-column
                                            prop="createUserName"
                                            label="变更审批状态"
                                            width="70">
                                            <template v-slot="scope">
                                            <span v-if="scope.row.changeStatus==0">未审批</span>
                                            <span v-else-if="scope.row.changeStatus==1">审批完成</span>
                                            <span v-else>无变更</span>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            prop="files"
                                            label="合同文件"
                                            width="140">
                                        <template v-slot="scope">
                                            <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                                        </template>
                                    </el-table-column>
                                    <el-table-column
                                            label="操作"
                                            align="center"
                                    >
                                        <template v-slot="scope">
                                            <el-button  type="primary" @click="editDialog(scope.row.id)"  size="mini" v-has="'editht'">修改</el-button>
                                            <el-button  type="primary" @click="deleteMsg(scope.row.id)" size="mini" v-has="'delht'">删除</el-button>
                                            <el-button  type="primary" @click="changeDialog(scope.row)"  size="mini" v-has="'changeht'">变更</el-button>
                                            <el-button  type="primary" @click="changList(scope.row.id)" size="mini" v-has="'selectchangeht'">变更记录</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                                <el-pagination
                                        @size-change="handleSizeChange"
                                        @current-change="handleCurrentChange"
                                        :current-page="queryInfo.pageNumber"
                                        :page-sizes="[5,10, 20, 50]"
                                        :page-size="queryInfo.pageSize"
                                        layout="total, sizes, prev, pager, next, jumper"
                                        :total="total">
                                </el-pagination>
                            </div>
                        </div>
                        </div>   
                       
                    </el-tab-pane>
                    <el-tab-pane label="合同订立流程" name="second">
                        <el-table
                          :data="tableData2"
                          height="100%"
                          border
                          :header-cell-style="{background:'#e3ecfb',color:'black'}"
                        >
                      <el-table-column
                              type="index"
                              label="序号"
                              width="50"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="contractName"
                              label="合同名称"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="contractAmount"
                              label="合同金额(万)"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="contractSerial"
                              label="合同序号"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="dateSign"
                              label="合同签订日期"
                              width="200"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="contractType"
                              label="合同类型"
                              width="200"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="partyUnit"
                              label="甲方单位"
                              align="center"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="reciprocity"
                              label="乙方单位"
                              align="center"
                              width="120"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="createdTime"
                              label="创建时间"
                              align="center"
                              width="200"
                      >
                      </el-table-column>
                      <el-table-column
                              prop="audits"
                              label="流程状态"
                              align="center"
                              width="120"
                      >
                          <template v-slot="scope">
                            <span v-if="scope.row.audits==null||scope.row.audits==0">未发起</span>
                          </template>
                      </el-table-column>
                      <el-table-column
                              label="操作"
                              align="center"
                      >
                          <template v-slot="scope">
                              <el-button  type="primary" @click="getInfo(scope.row.id)" icon="el-icon-edit" size="mini">详情</el-button>
                              <el-button  type="primary" @click="faqi(scope.row.id)" icon="el-icon-edit" size="mini" v-has="'editevent'">发起</el-button>
                              <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delevent'">删除</el-button>
                          </template>
                      </el-table-column>
                  </el-table>
                    </el-tab-pane>
             </el-tabs>
        </div>
        <el-dialog
                title="创建合同类别"
                :visible.sync="dialogVisiblefolder"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="addFormfolder" label-width="100px" >
                <el-form-item label="合同类别名称">
                    <el-input v-model="addFormfolder.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblefolder = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addfolder" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="添加合同"
                :visible.sync="dialogVisiblelfile"
                width="44%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="addFormfile" label-width="120px" :inline="true" :rules="addFormRule">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="合同名称" prop="name">
                            <el-input v-model="addFormfile.name" style="width: 600px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同编号" prop="name">
                            <el-input v-model="addFormfile.contractNum"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="定标时间">
                            <el-date-picker
                                    v-model="addFormfile.bidDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同类别" prop="categoryName">
                            <el-input v-model="addFormfile.categoryName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同签订日期" prop="signDate">
                            <el-date-picker
                                    v-model="addFormfile.signDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="总金额（元）" prop="totalAmount">
                            <el-input v-model="addFormfile.totalAmount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同开始时间" prop="startDate">
                            <el-date-picker
                                    v-model="addFormfile.startDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同单位社会统一信用代码">
                            <el-input v-model="addFormfile.creditCode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同结束时间" prop="endDate">
                            <el-date-picker
                                    v-model="addFormfile.endDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="履约保证金(元)" prop="securityDeposit">
                            <el-input type="number" v-model="addFormfile.securityDeposit"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="履约保证金时间"  prop="securityDate" >
                            <el-date-picker
                                    v-model="addFormfile.securityDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="质保金(元)"  prop="warrantyAmount">
                            <el-input  type="number" v-model="addFormfile.warrantyAmount"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="质保金时间" prop="warrantyDate">
                            <el-date-picker
                                    v-model="addFormfile.warrantyDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作单位名称">
                            <el-input v-model="addFormfile.cooperationUnit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作联系人">
                            <el-input v-model="addFormfile.cooperUser"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作联系人电话">
                            <el-input v-model="addFormfile.cooperTelephone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作联系人邮箱">
                            <el-input v-model="addFormfile.cooperEmail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否分包合同" prop="isSubContract">
                            <el-select v-model="addFormfile.isSubContract" clearable    style="padding-right: 0 !important;"  >
                                <el-option
                                        v-for="item in hetongType"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="上传文件">
                            <el-upload
                                    class="upload-demo"
                                    ref="upload"
                                    action="#"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :on-change="filechange"
                                    :file-list="fileList"
                                    :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelfile = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="add" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改合同"
                :visible.sync="dialogVisiblelfileEdit"
                width="44%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="editFormfile" label-width="120px" :inline="true" :rules="addFormRule">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="合同名称" prop="name">
                            <el-input v-model="editFormfile.name" style="width: 600px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同编号" prop="name">
                            <el-input v-model="editFormfile.contractNum"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="定标时间">
                            <el-date-picker
                                    v-model="editFormfile.bidDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同类别" prop="categoryName">
                            <el-input v-model="editFormfile.categoryName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同签订日期" prop="signDate">
                            <el-date-picker
                                    v-model="editFormfile.signDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="总金额（元）" prop="totalAmount">
                            <el-input v-model="editFormfile.totalAmount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同开始时间" prop="startDate">
                            <el-date-picker
                                    v-model="editFormfile.startDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同单位社会统一信用代码">
                            <el-input v-model="editFormfile.creditCode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同结束时间" prop="endDate">
                            <el-date-picker
                                    v-model="editFormfile.endDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="履约保证金(元)" prop="securityDeposit">
                            <el-input type="number" v-model="editFormfile.securityDeposit" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="履约保证金时间"  prop="securityDate">
                            <el-date-picker
                                    v-model="editFormfile.securityDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="质保金(元)"  prop="warrantyAmount">
                            <el-input type="number"  v-model="editFormfile.warrantyAmount" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="质保金时间"  prop="warrantyDate">
                            <el-date-picker
                                    v-model="editFormfile.warrantyDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作单位名称">
                            <el-input v-model="editFormfile.cooperationUnit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作联系人">
                            <el-input v-model="editFormfile.cooperUser"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作联系人电话">
                            <el-input v-model="editFormfile.cooperTelephone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作联系人邮箱">
                            <el-input v-model="editFormfile.cooperEmail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否分包合同" prop="isSubContract">
                            <el-select v-model="editFormfile.isSubContract" clearable    style="padding-right: 0 !important;"  >
                                <el-option
                                        v-for="item in hetongType"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="上传文件">
                            <el-upload
                                    class="upload-demo"
                                    ref="upload"
                                    action="#"
                                    :on-preview="handlePreviewEdit"
                                    :on-remove="handleRemoveEdit"
                                    :on-change="filechangeEdit"
                                    :file-list="fileListEdit"
                                    :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelfileEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="变更合同"
                :visible.sync="dialogVisiblelfileChange"
                width="44%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="addFormfile" label-width="120px" :inline="true" :rules="addFormRule">
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="合同名称" prop="name">
                            <el-input v-model="addFormfile.name" style="width: 600px"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同编号" prop="name">
                            <el-input v-model="addFormfile.contractNum"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="定标时间">
                            <el-date-picker
                                    v-model="addFormfile.bidDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同类别" prop="categoryName">
                            <el-input v-model="addFormfile.categoryName" :disabled="true"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同签订日期" prop="signDate">
                            <el-date-picker
                                    v-model="addFormfile.signDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="总金额（元）" prop="totalAmount">
                            <el-input v-model="addFormfile.totalAmount"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同开始时间" prop="startDate">
                            <el-date-picker
                                    v-model="addFormfile.startDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合同单位社会统一信用代码">
                            <el-input v-model="addFormfile.creditCode"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同结束时间" prop="endDate">
                            <el-date-picker
                                    v-model="addFormfile.endDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="履约保证金(元)" prop="securityDeposit">
                            <el-input type="number" v-model="addFormfile.securityDeposit" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="履约保证金时间" prop="securityDate">
                            <el-date-picker
                                    v-model="addFormfile.securityDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="质保金(元)"  prop="warrantyAmount">
                            <el-input type="number" v-model="addFormfile.warrantyAmount" oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="质保金时间" prop="warrantyDate">
                            <el-date-picker
                                    v-model="addFormfile.warrantyDate"
                                    value-format="yyyy-MM-dd"
                                    type="date"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作单位名称">
                            <el-input v-model="addFormfile.cooperationUnit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作联系人">
                            <el-input v-model="addFormfile.cooperUser"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="合作联系人电话">
                            <el-input v-model="addFormfile.cooperTelephone"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合作联系人邮箱">
                            <el-input v-model="addFormfile.cooperEmail"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="是否分包合同" prop="isSubContract">
                            <el-select v-model="addFormfile.isSubContract" clearable    style="padding-right: 0 !important;"  >
                                <el-option
                                        v-for="item in hetongType"
                                        :key="item.value"
                                        :label="item.name"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="上传文件">
                            <el-upload
                                    class="upload-demo"
                                    ref="upload"
                                    action="#"
                                    :on-preview="handlePreview"
                                    :on-remove="handleRemove"
                                    :on-change="filechange"
                                    :file-list="fileList"
                                    :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                            </el-upload>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelfileChange = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="change" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="变更合同记录"
                :visible.sync="dialogVisibleList"
                width="85%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
                <el-table
                        :data="listData"
                        :header-cell-style="{background:'#e3ecfb',color:'black'}"
                        height="100%"
                        style="width: 100%">
                    <el-table-column
                            type="index"
                            label="序号"
                            width="55">
                        <template v-slot="scope">
                            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="contractNum"
                            label="编号"
                            width="70">
                    </el-table-column>
                    <el-table-column
                            prop="name"
                            label="合同名称"
                            width="220">
                    </el-table-column>
                    <el-table-column
                            prop="signDate"
                            label="签订日期"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="startDate"
                            label="开始时间"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="endDate"
                            label="结束时间"
                            width="100">
                    </el-table-column>
                    <el-table-column
                            prop="totalAmount"
                            label="总金额"
                            width="80">
                        <template v-slot="scope">
                            {{scope.row.totalAmount}}元
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="cooperationUnit"
                            label="合作单位"
                            width="130">
                    </el-table-column>
                    <el-table-column
                            prop="isSubContract"
                            label="分包合同"
                            width="80">
                        <template v-slot="scope">
                            <span v-if="scope.row.isSubContract">是</span>
                            <span v-else>否</span>
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="securityDeposit"
                            label="履约保证金"
                            width="100">
                        <template v-slot="scope">
                            {{scope.row.securityDeposit}}元
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="warrantyAmount"
                            label="质保金"
                            width="80">
                        <template v-slot="scope">
                            {{scope.row.warrantyAmount}}元
                        </template>
                    </el-table-column>
                    <el-table-column
                            prop="createUserName"
                            label="创建人"
                            width="70">
                    </el-table-column>
                    <el-table-column
                            prop="files"
                            label="合同文件"
                            align="center"
                            >
                        <template v-slot="scope">
                            <a style="text-decoration: underline"  v-for="item in scope.row.files" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                        </template>
                    </el-table-column>
                </el-table>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleList = false">关 闭</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="合同详情"
                :visible.sync="dialogVisibletable"
                width="50%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
         <table id="hetongTable">
             <tbody>
             <tr>
                 <th>合同名称</th>
                 <td colspan="5">{{tableform.name}}</td>
             </tr>
             <tr>
                 <th>合同编号</th>
                 <td>{{tableform.contractNum}}</td>
                 <th>签订日期</th>
                 <td>{{tableform.signDate}}</td>
                 <th>定标时间</th>
                 <td>{{tableform.bidDate}}</td>
             </tr>
             <tr>
                 <th>合同类别</th>
                 <td>{{tableform.categoryName}}</td>
                 <th>开始时间</th>
                 <td>{{tableform.startDate}}</td>
                 <th>结束时间</th>
                 <td>{{tableform.endDate}}</td>
             </tr>
             <tr>
                 <th>总金额</th>
                 <td>{{tableform.totalAmount}}</td>
                 <th>合同单位社会统一信用代码</th>
                 <td>{{tableform.creditCode}}</td>
                 <th>是否分包合同</th>
                 <td>
                     <span v-if="tableform.isSubContract">是</span>
                     <span v-else>否</span>
                 </td>
             </tr>
             <tr>
                 <th>履约保证金(元)</th>
                 <td>{{tableform.securityDeposit}}</td>
                 <th>履约保证金时间</th>
                 <td colspan="3">{{tableform.securityDate}}</td>
             </tr>
             <tr>
                 <th>质保金(元)</th>
                 <td>{{tableform.warrantyAmount}}</td>
                 <th>质保金时间</th>
                 <td colspan="3">{{tableform.warrantyDate}}</td>
             </tr>
             <tr>
                 <th>合作单位名称</th>
                 <td colspan="5">{{tableform.cooperationUnit}}</td>
             </tr>
             <tr>
                 <th>合作联系人</th>
                 <td>{{tableform.cooperUser}}</td>
                 <th>合作联系人电话</th>
                 <td>{{tableform.cooperTelephone}}</td>
                 <th>合作联系人邮箱</th>
                 <td>{{tableform.cooperEmail}}</td>
             </tr>
             <tr>
                 <th rowspan="3">文件</th>
                 <td colspan="5" rowspan="3">
                     <a style="text-decoration: underline;cursor: pointer"  v-for="item in tableform.files" target="_blank"  @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                 </td>
             </tr>
             <tr>
             </tr>
             <tr>
             </tr>
             <tr>
                 <th>创建人</th>
                 <td>{{tableform.createUserName}}</td>
                 <th>创建时间</th>
                 <td colspan="3">{{tableform.cooperEmail}}</td>
             </tr>
             </tbody>

         </table>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibletable = false">关 闭</el-button>
  </span>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
        <el-dialog
              title="合同审批单"
              :visible.sync="dialogVisibleAdd"
              width="40%"
              center
              :close-on-click-modal="false"
      >
        <contractGetAdd ref="childProAppRef" :someData="someData" :isAdd="isAdd"></contractGetAdd>
            <span slot="footer" class="dialog-footer">
                <el-button round type="goon" size="medium" @click="dialogVisibleAdd = false">取 消</el-button>
                <el-button round type="goon" size="medium"  @click="addproApp" >确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import contractGetAdd from '../actChildCom/contractGetAdd'
    import Tab from "@form-create/designer/src/config/rule/tab";
    export default {
        name: "hetong",
        components: {contractGetAdd,Tab},
        data(){
            return{
                dialogVisibleAdd:false,
                 isAdd:true,
                 someData:{
                
                 },
                 tableData2:[],
                 pageInfo:{
                    audits:null,
                    contractName:'',
                    pageNum:1,
                    pageSize:99,
                    contractSerial:''
                 },
                data:[{
                   "parent": {
                       "id": 1,
                       "name": "图木舒克市农副产品精深加工标准化厂房项目",
                       "parentId": 0,
                       "projectId": "4028802f7b28b470017b29c4fda50000"
                    },
                   "children": []}],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                },
                proshow:false,
                fileData:[],
                activeName:'first',
                dialogVisiblefolder:false,
                dialogVisiblelfile:false,
                dialogVisiblelfileEdit:false,
                addFormfolder:{
                    name:'',
                    projectId:this.$root.proId,
                    parentId:1
                },
                queryInfo:{
                    pageNumber:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    categoryId:'',
                    name:'',
                    contractNum:'',
                    cooperationUnit:''
                },
                total:0,
                addFormfile:{
                    name:'',
                    signDate:'',
                    bidDate:'',
                    contractNum:'',
                    startDate:'',
                    endDate:'',
                    totalAmount:'',
                    categoryId:'',
                    categoryName:'',
                    projectId:this.$root.proId,
                    creditCode:'',
                    cooperationUnit:'',
                    cooperUser:'',
                    cooperTelephone:'',
                    cooperEmail:'',
                    typename:'',
                    isSubContract:'',
                    warrantyDate:'',
                    warrantyAmount:'',
                    securityDate:'',
                    securityDeposit:'',
                    sourceId:''
                },
                fileList:[],
                addFormRule: {
                    name: [{required: true, message: '请输入合同名称', trigger: 'blur'}],
                    signDate: [{required: true, message: '请输入合同签订日期', trigger: 'blur'}],
                    contractNum: [{required: true, message: '请输入合同编号', trigger: 'blur'}],
                    startDate: [{required: true, message: '请输入合同开始时间', trigger: 'blur'}],
                    endDate: [{required: true, message: '请输入合同结束时间', trigger: 'blur'}],
                    totalAmount: [{required: true, message: '请输入总金额（元）', trigger: 'blur'}],
                    categoryName: [{required: true, message: '请输入所属类别名称', trigger: 'blur'}],
                    isSubContract: [{required: true, message: '请选择是否是分包合同', trigger: 'blur'}],
                    /*   warrantyAmount : [{type:'number', message: '请输入质保金', trigger: 'blur'}],
                      securityDeposit : [{type:'number', message: '请输入履约保证金', trigger: 'blur'}],
                       warrantyDate : [{required: true, message: '请输入质保金时间', trigger: 'blur'}],
                        securityDate : [{required: true, message: '请输入履约保证金时间', trigger: 'blur'}],*/
                },
                editFormfile:{},
                fileListEdit:[],
                fileListEditDeleteID:[],
                fileListEditAdd:[],
                hetongType:[{
                    value:0,
                    name:'否'
                },{
                    value:1,
                    name:'是'
                }],
                dialogVisiblelfileChange:false,
                dialogVisibleList:false,
                listData:[],
                dialogVisibletable:false,
                tableform:{

                },
                hasroot:true
            }
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http5.post('/v1.0/costs/contract-report/findPages',this.pageInfo)
              this.tableData2=res.list;
           },
           addHetongPlan(){
                this.dialogVisibleAdd = true;
                this.isAdd=true;
                this.someData={}
            },
            async faqi(id){
            let assi=window.sessionStorage.getItem('userid')+'_'+window.sessionStorage.getItem('name')+'_'+window.sessionStorage.getItem('organizationName')
            const {data:res}= await this.$http2.post('/InitiateProcess/start/InitiatingProcess',{
              assignee:assi,
              businessCode:'PROJECT_MANAGER_APPOINTMENT',
              instanceIds:[id],
              "universalProcess": true
            })
            if(res.businessCode!='3000'){
                return this.$message.error('发起失败！'+res.message)
            }
          },
            async addproApp(){
            let childData = this.$refs.childProAppRef.addFrom;
            console.log(childData)
            const {data:res}= await this.$http5.post('/v1.0/costs/contract-report/insert/ContractReport',childData)
             if(res.businessCode!='4000'){
                  return this.$message.error(' 合同审批单报存失败！'+res.message)
             }
             this.$message.success(' 合同审批单保存成功！')
             this.dialogVisibleAdd=false
             this.getProApp()
             this.activeName='second'
           },
            async getHtTree(){
              const {data:res}=await  this.$http5.get('/v1.0/cost/contract/category/findContractCategoryTree?projectId='+this.$root.proId)
              if(res.businessCode!='3000'){
                 this.data=[]
                  this.hasroot=true
                 return this.$message.error('获取合同类别失败'+res.message)
              }
              if(res.data.length==0){
                  this.hasroot=true
              }else {
                  this.hasroot=false
              }
              this.data=res.data
            },
            append(data){
                this.addFormfolder.parentId=data.parent.id
                this.dialogVisiblefolder=true
            },
            addRootType(){
                this.addFormfolder.parentId=0
                this.dialogVisiblefolder=true
            },
            async addfolder(){
                const {data:res}= await this.$http5.post('/v1.0/cost/contract/category/save?name='+this.addFormfolder.name+'&projectId='+this.$root.proId+'&parentId='+this.addFormfolder.parentId)
                if(res.businessCode!='6000'){
                    return this.$message.error('添加类别失败！'+res.message)
                }
                this.$message.success('添加类别成功！');
                this.dialogVisiblefolder=false;
                this.getHtTree()
            },
            async remove(node,data){
                const confirmResult = await this.$confirm('此操作将永久删除该合同类别, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const {data:res}= await this.$http5.delete('/v1.0/cost/contract/category/delete/'+data.parent.id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除类别失败！')
                }
                this.$message.success('删除类别成功！');
                this.getHtTree();
            },
            async handleCheckChange(data) {
                this.queryInfo.categoryId=data.parent.id;
                this.addFormfile.categoryName=data.parent.name;
                this.CheckChange();
            },
            async  CheckChange(){
                const {data:res}=await  this.$http5.post('/v1.0/cost/contract/manage/findPages',this.queryInfo)
                if(res.businessCode!='3000'){
                  return  this.fileData=[]
                }
                this.total=res.data.totalCount;
                this.fileData=res.data.result
            },
            addHetong(){
                if(this.queryInfo.categoryId==''){
                    return this.$message.error('请先选择合同类别！')
                }
                this.addFormfile.categoryId=this.queryInfo.categoryId;
                this.dialogVisiblelfile=true
            },
            async add(){
                this.proshow=true
                var formData=new FormData();
                for(let x in this.fileList){
                    formData.append("multipartFiles",this.fileList[x].raw);
                }
                console.log(this.addFormfile.startDate)
             /*   this.addFormfile.startDate=this.addFormfile.startDate.getFullYear()+"-"+(this.addFormfile.startDate.getMonth()+1)+"-"+this.addFormfile.startDate.getDate();
                this.addFormfile.endDate=this.addFormfile.endDate.getFullYear()+"-"+(this.addFormfile.endDate.getMonth()+1)+"-"+this.addFormfile.endDate.getDate();
                this.addFormfile.signDate=this.addFormfile.signDate.getFullYear()+"-"+(this.addFormfile.signDate.getMonth()+1)+"-"+this.addFormfile.signDate.getDate();
            */  /* if(this.addFormfile.bidDate!=''){
                   this.addFormfile.bidDate=this.addFormfile.bidDate.getFullYear()+"-"+(this.addFormfile.bidDate.getMonth()+1)+"-"+this.addFormfile.bidDate.getDate();
                }*/
                for (let key in this.addFormfile) {
                    formData.append(key,this.addFormfile[key]);
                }
                const {data:res}= await this.$http5.post('/v1.0/cost/contract/manage/save', formData)
                if(res.businessCode!='6000'){
                    return this.$message.error('添加合同失败！'+res.message)
                }
                this.$message.success('添加合同成功！');
                this.dialogVisiblelfile=false;
                this.proshow=false;
                this.CheckChange();
            },
            changeDialog(row){
                this.fileList=[];
                for (let key in this.addFormfile) {
                    if(key=='projectId'){

                    }else {
                        this.addFormfile[key]=''
                    }
                }
                this.addFormfile.categoryName=row.categoryName
                this.addFormfile.categoryId=row.categoryId
                this.addFormfile.sourceId=row.id
                this.dialogVisiblelfileChange=true;
            },
            async changList(id){
                this.listData=[]
                this.dialogVisibleList=true
                const {data:res}= await this.$http5.get('/v1.0/cost/contract/manage/findByChangedId?changedId='+id)
                if(res.businessCode!='3000'){
                    return this.$message.error('查询历史变更失败！'+res.message)
                }
                this.listData.push(res.data)
                console.log(this.listData)
            },
            async fileupload(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open (res2.data) ;
            },
           async xiangqing(id){
                this.dialogVisibletable=true
                const {data:res}=await  this.$http5.get('/v1.0/cost/contract/manage/findById?id='+id);
               if(res.businessCode!=="3000"){
                   return 0;
               }
               this.tableform=res.data
            },
           async change(){
               this.proshow=true
                var formData=new FormData();
               this.addFormfile.categoryId=this.queryInfo.categoryId;
                for(let x in this.fileList){
                    formData.append("multipartFiles",this.fileList[x].raw);
                }
           /*      if(this.addFormfile.bidDate!=''){
                    this.addFormfile.bidDate=this.addFormfile.bidDate.getFullYear()+"-"+(this.addFormfile.bidDate.getMonth()+1)+"-"+this.addFormfile.bidDate.getDate();
                }*/
                for (let key in this.addFormfile) {
                    formData.append(key,this.addFormfile[key]);
                }
                const {data:res}= await this.$http5.post('/v1.0/cost/contract/manage/changeContract', formData)
                if(res.businessCode!='6000'){
                    return this.$message.error('变更合同失败！'+res.message)
                }
                this.$message.success('变更合同成功！');
                this.dialogVisiblelfileChange=false;
               this.proshow=false
                this.CheckChange();
            },
            async editDialog(id){
                const {data:res}=await  this.$http5.get('/v1.0/cost/contract/manage/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return 0;
                }
                this.editFormfile=res.data;
                this.fileListEdit=[];
                for(let i in res.data.files){
                    this.fileListEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8807/'+res.data.files[i].filePath})
                }
                this.fileListEditDeleteID=[];
                this.fileListEditAdd=[];
                this.dialogVisiblelfileEdit=true
            },
            async editFile(){
                this.proshow=true
                var formData=new FormData();
              /*  if(typeof this.editFormfile.bidDate=='object'){
                    this.editFormfile.bidDate=this.editFormfile.bidDate.getFullYear()+"-"+(this.editFormfile.bidDate.getMonth()+1)+"-"+this.editFormfile.bidDate.getDate();
                }
                if(typeof this.editFormfile.startDate=='object'){
                    this.editFormfile.startDate=this.editFormfile.startDate.getFullYear()+"-"+(this.editFormfile.startDate.getMonth()+1)+"-"+this.editFormfile.startDate.getDate();
                }
                if(typeof this.editFormfile.endDate=='object'){
                    this.editFormfile.endDate=this.editFormfile.endDate.getFullYear()+"-"+(this.editFormfile.endDate.getMonth()+1)+"-"+this.editFormfile.endDate.getDate();
                }
                if(typeof this.editFormfile.signDate=='object'){
                    this.editFormfile.signDate=this.editFormfile.signDate.getFullYear()+"-"+(this.editFormfile.signDate.getMonth()+1)+"-"+this.editFormfile.signDate.getDate();
                }*/
                for (let key in this.editFormfile) {
                    if(key=='changedId' && this.editFormfile[key]!='null'){
                       formData.append(key, '');
                    }
                    if(key!='files' && key!='changedId'  ) {
                        formData.append(key, this.editFormfile[key]);
                    }
                }
                this.editFormfile.files=[]
                const {data:res}= await this.$http5.put('/v1.0/cost/contract/manage/update', this.editFormfile)
                if(res.businessCode!='6010'){
                    return this.$message.error('修改合同信息失败！'+res.message)
                }
                for(let i in this.fileListEditDeleteID){
                    const {data:res2}= await this.$http5.delete('/v1.0/cost/contract/file/delete/'+this.fileListEditDeleteID[i])
                    if(res2.businessCode!='4000'){
                        return this.$message.error('删除合同文件失败！'+res2.message)
                    }
                }
                if(this.fileListEditAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileListEditAdd){
                        formData2.append("multipartFiles",this.fileListEditAdd[j].raw);
                    }
                    formData2.append("contractId",this.editFormfile.id)
                    const {data:res3}= await this.$http5.post('/v1.0/cost/contract/file/saveAll', formData2)
                    if(res3.businessCode!='6000'){
                        return this.$message.error('添加合同文件失败！'+res3.message)
                    }
            }
                this.$message.success('修改合同成功！');
                this.CheckChange()
                this.proshow=false
                this.dialogVisiblelfileEdit=false;
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该合同, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http5.delete('/v1.0/cost/contract/manage/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除合同失败！'+res.message)
                }
                this.$message.success('删除合同成功！');
                this.CheckChange();
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.CheckChange()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.CheckChange()
            },
            handleClick(){

            },
            handleRemove(file, fileList) {
                this.fileList=fileList
            },
            handlePreview(file) {
            },
            filechange(file,fileList){
                this.fileList=fileList
            },
            handleRemoveEdit(file, fileList) {
                if(file.id) {
                    this.fileListEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd.length; i++) {
                        if (this.fileListEditAdd[i] == file) {
                            this.fileListEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            handlePreviewEdit(file) {
            },
            filechangeEdit(file,fileList){
                this.fileListEditAdd.push(file)
                this.fileListEdit=fileList
            },
            dialogClose(){
                this.addFormfolder.name=''
                this.$refs.addFormReffile.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
        },
        created(){
            this.getProApp()
             this.getHtTree();
            this.CheckChange();
        },
    }
</script>

<style scoped lang="less">

    #hetong{
        background-color: #fff;
        position: absolute;
        left: 220px;
        top: 80px;
        bottom: 20px;
        right: 20px;
        width: 1680px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }
    #htheader{
        width: 100%;
        height: 46px;
        padding-top: 5px;
        border-bottom: 1px solid #273F76   ;
        padding-left: 15px;

    }
    #httree{
        width: 270px;
        height:715px;
        background-color: #fff;
        /deep/ .el-button{
            background-color: white !important;
            color: #1e50ae !important;
            border: 0 !important;
        }
    }
    #htright{
        width: 1390px;
        height: 715x;
        border-left: 2px solid #f0f3fa;
    }
    .serverTable{
        width: 82.5%;
        height: 680px;
    }
    .custom-tree-node {
        flex: 1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-size: 14px;
        padding-right: 8px;
    }

    /deep/ .el-tabs__header{
        margin: 0;
    }
    /deep/ .el-tabs__item{
        color: #000;
    }
    /deep/ .el-tabs__item:hover {
        color: #409EFF;
        cursor: pointer;
    }
    /deep/ .el-tabs__item.is-active {
        color: #409EFF;
    }
  /deep/ .el-select{
      padding-right: 0 !important;
  }
    /deep/ .el-input--suffix{
        padding-right: 0 !important;
    }
    /deep/ .el-input__inner{
        padding-right: 0 !important;
    }
    #hetongTable{
        tr{
            height: 50px;
        }
        th{
            border: 1px solid #cacaca;
            width: 120px;
        }
        td{
            width: 160px;
            text-align: left;
            padding: 7px 6px;
            font-family: Sans-Serif;
            border: 1px solid #cacaca;
            color: #444;
        }
    }
    /deep/ .el-icon-circle-plus-outline{
        font-size: 16px;
    }
    /deep/ .el-icon-remove-outline{
        font-size: 16px;
    }
    /deep/ .hetongDiv{
        display: flex;
        justify-content: space-between;
    }
</style>
