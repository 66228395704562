<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入制度文件名查询" v-model="queryInfo.rapexName" class="input-with-select" clearable @clear="getnotice">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getnotice" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="16">
                           <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">上传制度文件</el-button>

                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="filePath"
                                label="文件"
                                width="250"
                        >
                            <template v-slot="scope">
                                <a v-if="scope.row.filePath" @click="uploadfile(scope.row.filePath)">   {{scope.row.filePath.substring(scope.row.filePath.lastIndexOf("_")+1)}}</a>
                                <span v-else>
                                    未上传附件
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="title"
                                label="文件级别"
                                width="240"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="description"
                                label="描述"
                                width="300"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createUserName"
                                label="创建人"
                                width="80"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createDate"
                                label="创建时间"
                                width="160"
                        >
                        </el-table-column>


                        <el-table-column
                                prop="isShow"
                                align="center"
                                label="是否显示"
                                width="80"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.isShow" @click="isShow(scope.row.id,scope.row.isShow)" ><i class="el-icon-view" style="font-size: 22px"></i></span>
                                <span v-else @click="isShow(scope.row.id,scope.row.isShow)" >
                                   <i class="el-icon-noshowyincang iconfont" style="font-size: 22px"></i>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <!--    <el-tooltip effect="dark" content="是否显示" placement="top" :enterable="false" >
                                        <el-button v-if="scope.row.isShow" type="primary"  size="small" @click="isShow(scope.row.id,scope.row.isShow)" > <i class="el-icon-video-pause"></i></el-button>
                                        <el-button v-else type="primary" icon="el-icon-video-play" size="small" @click="isShow(scope.row.id,scope.row.isShow)" ></el-button>
                                    </el-tooltip>-->
                                <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini">修改</el-button>
                                <!--    <el-tooltip effect="dark" content="修改公告" placement="top" :enterable="false" >
                                        <el-button type="primary" icon="el-icon-edit" size="small" @click="editDialog(scope.row.id)" ></el-button>
                                    </el-tooltip>-->
                                <!-- <el-tooltip effect="dark" content="删除公告" placement="top" :enterable="false">
                                     <el-button type="danger" icon="el-icon-delete" size="small" @click="deleteMsg(scope.row.id)" ></el-button>
                                 </el-tooltip>-->
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="上传制度文件"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addRules">

                <el-form-item label="文件类型" prop="regulateRapexTypeId">
                    <el-select v-model="addForm.regulateRapexTypeId" placeholder="请选择文件类型">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="文件级别" prop="isEnabled">
                    <el-select v-model="addForm.isEnabled" placeholder="请选择文件级别">
                        <el-option
                                v-for="item in options2"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="附件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :limit="1"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                                </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>

    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "notice",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    rapexName:'',
                    pageNumber:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    isEnabled:'',
                    departmentId:"",
                    regulateRapexTypeId:''
                },
                total:0,
                dialogVisible:false,
                addForm:{
                    isEnabled:'',
                    regulateRapexTypeId:'',
                    projectId:this.$root.proId,
                    rapexName:''
                },
                fileList:[],
                options:[{
                    value: 1,
                    label: '规章制度'
                },{
                    value: 0,
                    label: '通知公告'
                }],
                options2:[{
                    value: 1,
                    label: '国家发文'
                },{
                    value: 2,
                    label: '兵团发文'
                },{
                    value: 3,
                    label: '集团发文'
                },{
                    value: 4,
                    label: '分院发文'
                },{
                    value: 5,
                    label: '其它'
                }],
                addRules:{

                }
            }
        },
        created(){
            this.getFiles()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/regulate/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询文件制度失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addFile(){
                var formData=new FormData();
                if(this.filelist){
                    formData.append("file",this.filelist[0].raw);
                }else {
                    formData.append("file",'');
                    return this.$message.error('请选择文件！')
                }
                formData.append("isEnabled",this.addForm.isEnabled);
                formData.append("regulateRapexTypeId",this.addForm.regulateRapexTypeId);
                formData.append("projectId",this.addForm.projectId);
                const {data:res}= await this.$http14.post('/v1.0/security/regulate/save',formData)
                if(res.status!='6000'){
                    return this.$message.error('上传制度文件失败！'+res.message)
                }
                this.$message.success('上传制度文件成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该通知公告, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http3.delete('/v1.0/activiti/announcement/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除通知公告失败！'+res.message)
                }
                this.$message.success('删除通知公告成功！');
                this.getnotice();
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=filelist
            },
            handlePreview(file) {
                console.log(file)
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getnotice()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getnotice()
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">


</style>
