<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <kaoqing-left></kaoqing-left>
        <div id="kaoList">
            <div class="box-card">
                <el-card class="box-card" >
                    <div slot="header" class="clearfix">
                        <span>打卡列表</span>
                        <el-date-picker
                                v-model="selectdate"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                @change="datechange"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                        <!--     <el-button style="float: right; padding: 3px 0" type="text" @click="fals=!fals">切换双表</el-button>-->
                    </div>
                    <div class="text item tongji" style="">
                        <div class="yingdao">
                            <p class="yd">应到</p>
                            <p class="ydr">150</p>
                        </div>
                        <div class="zhengc">
                            <p class="yd">实到</p>
                            <p class="ydr">148</p>
                        </div>
                        <div class="shidao">
                            <p class="yd">休假</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="queqin">
                            <p class="yd">旷工</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="chidao">
                            <p class="yd">迟到</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="zaotui">
                            <p class="yd">早退</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="zhengc">
                            <p class="yd">正常</p>
                            <p class="ydr">2</p>
                        </div>
                    </div>
                    <div  class="text item">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="100%"
                                :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="attendanceUserId"
                                    align="center"
                                    label="工号"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="attendanceUserName"
                                    label="考勤人"
                                    sortable
                                    align="center"
                                    width="100">
                            </el-table-column>
                            <!--  <el-table-column
                                      prop="date"
                                      label="考勤日期"
                                      sortable
                                      align="center"
                                      width="120">
                              </el-table-column>-->
                            <!--<el-table-column
                                    prop="bumen"
                                    label="所在部门"
                                    sortable
                                    align="center"
                                    width="150">
                            </el-table-column>-->
                            <el-table-column
                                    prop="startDate"
                                    label="上班打卡时间"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="endDate"
                                    label="下班打卡时间"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="firstLocation"
                                    label="上班打卡地点"
                                    align="center"
                                    sortable
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="secondLocation"
                                    label="下班打卡地点"
                                    align="center"
                                    sortable
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    label="状态"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <!--   <el-table-column
                                       prop="yingqin"
                                       label="应勤"
                                       align="center"
                                       sortable
                                       width="180">
                               </el-table-column>
                               <el-table-column
                                       prop="shiqin"
                                       label="实勤"
                                       align="center"
                                       sortable
                                       width="180">
                               </el-table-column>-->
                            <el-table-column
                                    prop="remark"
                                    label="备注"
                                    align="center"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    align="center"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
            <div class="box-card1">
                <el-card class="box-card" >
                    <div slot="header" class="clearfix">
                        <span>打卡列表</span>
                        <el-date-picker
                                v-model="selectdate"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                @change="datechange"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                        <!--     <el-button style="float: right; padding: 3px 0" type="text" @click="fals=!fals">切换双表</el-button>-->
                    </div>
                    <div class="text item tongji" style="">
                        <div class="yingdao">
                            <p class="yd">应到</p>
                            <p class="ydr">150</p>
                        </div>
                        <div class="zhengc">
                            <p class="yd">实到</p>
                            <p class="ydr">148</p>
                        </div>
                        <div class="shidao">
                            <p class="yd">休假</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="queqin">
                            <p class="yd">旷工</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="chidao">
                            <p class="yd">迟到</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="zaotui">
                            <p class="yd">早退</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="zhengc">
                            <p class="yd">正常</p>
                            <p class="ydr">2</p>
                        </div>
                    </div>
                    <div  class="text item">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="100%"
                                :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="attendanceUserId"
                                    align="center"
                                    label="工号"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="attendanceUserName"
                                    label="考勤人"
                                    sortable
                                    align="center"
                                    width="100">
                            </el-table-column>
                            <!--  <el-table-column
                                      prop="date"
                                      label="考勤日期"
                                      sortable
                                      align="center"
                                      width="120">
                              </el-table-column>-->
                            <!--<el-table-column
                                    prop="bumen"
                                    label="所在部门"
                                    sortable
                                    align="center"
                                    width="150">
                            </el-table-column>-->
                            <el-table-column
                                    prop="startDate"
                                    label="上班打卡时间"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="endDate"
                                    label="下班打卡时间"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="firstLocation"
                                    label="上班打卡地点"
                                    align="center"
                                    sortable
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="secondLocation"
                                    label="下班打卡地点"
                                    align="center"
                                    sortable
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    label="状态"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <!--   <el-table-column
                                       prop="yingqin"
                                       label="应勤"
                                       align="center"
                                       sortable
                                       width="180">
                               </el-table-column>
                               <el-table-column
                                       prop="shiqin"
                                       label="实勤"
                                       align="center"
                                       sortable
                                       width="180">
                               </el-table-column>-->
                            <el-table-column
                                    prop="remark"
                                    label="备注"
                                    align="center"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    align="center"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
            <div class="box-card2">
                <el-card class="box-card" >
                    <div slot="header" class="clearfix">
                        <span>打卡列表</span>
                        <el-date-picker
                                v-model="selectdate"
                                align="right"
                                type="date"
                                placeholder="选择日期"
                                @change="datechange"
                                :picker-options="pickerOptions">
                        </el-date-picker>
                        <!--     <el-button style="float: right; padding: 3px 0" type="text" @click="fals=!fals">切换双表</el-button>-->
                    </div>
                    <div class="text item tongji" style="">
                        <div class="yingdao">
                            <p class="yd">应到</p>
                            <p class="ydr">150</p>
                        </div>
                        <div class="zhengc">
                            <p class="yd">实到</p>
                            <p class="ydr">148</p>
                        </div>
                        <div class="shidao">
                            <p class="yd">休假</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="queqin">
                            <p class="yd">旷工</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="chidao">
                            <p class="yd">迟到</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="zaotui">
                            <p class="yd">早退</p>
                            <p class="ydr">2</p>
                        </div>
                        <div class="zhengc">
                            <p class="yd">正常</p>
                            <p class="ydr">2</p>
                        </div>
                    </div>
                    <div  class="text item">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="100%"
                                :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="attendanceUserId"
                                    align="center"
                                    label="工号"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="attendanceUserName"
                                    label="考勤人"
                                    sortable
                                    align="center"
                                    width="100">
                            </el-table-column>
                            <!--  <el-table-column
                                      prop="date"
                                      label="考勤日期"
                                      sortable
                                      align="center"
                                      width="120">
                              </el-table-column>-->
                            <!--<el-table-column
                                    prop="bumen"
                                    label="所在部门"
                                    sortable
                                    align="center"
                                    width="150">
                            </el-table-column>-->
                            <el-table-column
                                    prop="startDate"
                                    label="上班打卡时间"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="endDate"
                                    label="下班打卡时间"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="firstLocation"
                                    label="上班打卡地点"
                                    align="center"
                                    sortable
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="secondLocation"
                                    label="下班打卡地点"
                                    align="center"
                                    sortable
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    label="状态"
                                    align="center"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <!--   <el-table-column
                                       prop="yingqin"
                                       label="应勤"
                                       align="center"
                                       sortable
                                       width="180">
                               </el-table-column>
                               <el-table-column
                                       prop="shiqin"
                                       label="实勤"
                                       align="center"
                                       sortable
                                       width="180">
                               </el-table-column>-->
                            <el-table-column
                                    prop="remark"
                                    label="备注"
                                    align="center"
                                    width="200">
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    align="center"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
        </div>
        <x-dbottom></x-dbottom>
    </div>
</template>

<script>
    export default {
        name: "kaotongji"
    }
</script>

<style scoped>

</style>
