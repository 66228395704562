<template>
  <div class="bg">
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div id="pageList">
          <div class="addActBtCategory">
            <div class="catleft">
              <span class="title-left">表单管理</span>
            </div>
            <div
              style="display: flex; justify-content: flex-end; z-index: 999999"
            >
              <el-button type="primary" @click="addForm"
                ><i class="el-icon-remove"></i><span>新增表单</span>
              </el-button>
            </div>
          </div>
          <!-- 列表 -->
          <div>
            <el-table
              :data="tableData"
              border
              style="width: 100%; height: 100%"
              size="large"
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            >
              <template>
                <el-empty :image-size="100" description="暂无数据"></el-empty>
              </template>
              <el-table-column prop="tableName" label="表名称" width="250">
                <template #default="scope">
                  <el-tag>{{ scope.row.tableName }}</el-tag>
                </template>
              </el-table-column>
              <el-table-column
                prop="actBtCategory.categoryName"
                label="所属类别"
                width="180"
              />
              <el-table-column
                prop="formDescription"
                label="表单描述"
                width="300"
              />
              <el-table-column prop="isRelease" label="是否部署" width="100">
                <template #default="scope">
                  <el-tag
                    :type="scope.row.isRelease == '1' ? 'danger' : 'success'"
                  >
                    {{
                      scope.row.isRelease == "1" ? "未部署" : "已部署"
                    }}</el-tag
                  >
                </template>
              </el-table-column>
              <el-table-column prop="createTime" label="创建时间" width="200">
                <template #default="scope">
                  <div style="display: flex; align-items: center">
                    <el-icon>
                      <timer />
                    </el-icon>
                    <span style="margin-left: 10px">{{
                      convertUTCTimeToLocalTime(scope.row.createTime)
                    }}</span>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    size="small"
                    @click="handleEdit(scope.row)"
                    :disabled="scope.row.isRelease !== 1"
                    :title="
                      scope.row.isRelease !== 1
                        ? '表单已经部署无法操作'
                        : '编辑该表单'
                    "
                    >编辑</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="formDesign(scope.row)"
                    >业务表设计</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="visualForm(scope.row)"
                    >表单可视化</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    :disabled="scope.row.isRelease === 1"
                    :title="
                      scope.row.isRelease !== 1
                        ? '撤销部署将删除业务表结构记录、删除普通表单和流程模型关系'
                        : '尚未部署请前往业务表设计部署'
                    "
                    @click="deployForm(scope.row)"
                    >{{
                      scope.row.isRelease === 1 ? "撤销部署" : "尚未部署"
                    }}</el-button
                  >
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="deleteForm(scope.row)"
                    >删除表单</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
            <el-pagination
              background
              layout="total, sizes, prev, pager, next, jumper"
              :page-sizes="[10, 20, 30, 50]"
              :total="total"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import promeau from "../proMeau.vue";
export default {
  name: "formHome",
  data() {
    return {
      formJson: {
        categoryId: "",
        formContent: "",
        formDescription: "",
        isRelease: null,
        pageNum: 1,
        pageSize: 10,
        tableName: "",
      },
      tableData: [],
      total: 0,
      dialogVisible: false,
      rules: {
        categoryName: [
          { required: true, message: "请输入类别名称", trigger: "blur" },
        ],
        parentId: [
          { required: true, message: "请选择所属类别", trigger: "blur" },
        ],
      },
    };
  },
  created() {},
  methods: {
    async getForm() {
      const { data: res } = await this.$http2.post(
        "/extension/generator-form/query/GeneratorFormPageList",
        this.formJson
      );
      if (res.code == 200) {
        this.total = res.data.total;
        this.tableData = res.data.list;
      }
    },
    addForm() {
      this.$router.push({ path: "/formCreate" });
    },
    convertUTCTimeToLocalTime(UTCDateString){
      if (!UTCDateString) {
        return "-";
      }
      function formatFunc(str) {
        //格式化显示
        return str > 9 ? str : "0" + str;
      }
      const date2 = new Date(UTCDateString); //这步是关键
      const year = date2.getFullYear();
      const mon = formatFunc(date2.getMonth() + 1);
      const day = formatFunc(date2.getDate());
      const hour = date2.getHours();
      const min = formatFunc(date2.getMinutes());
      const miao = formatFunc(date2.getSeconds());
      const dateStr =
        year + "-" + mon + "-" + day + " " + hour + ":" + min + ":" + miao;
      return dateStr;
    },
    handleEdit(row) {
      // ProcessStore.formEditJson=row.formContent
      localStorage.setItem("formRow", JSON.stringify(row));
      this.$router.push({ path: "/formCreate" });
    },
    formDesign(row) {
      this.$router.push({
        path: "/formField",
        query: {
          tableName: row.tableName,
          generatorId: row.generatorId,
          isRelease: row.isRelease,
        },
      });
    },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },

  components: {
    promeau,
  },
  created() {
    this.getForm();
  },
};
</script>

<style scoped lang="less">
.content {
  padding-top: 50px;
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(92vh);
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .insertActBtCategory {
    width: 300px;
    margin-right: 8px;
  }

  .addActBtCategory {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .catleft {
      margin-top: 10px;
    }
  }

  #pageList {
    width: 98%;
  }

  .title-left {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    width: 140px;
    height: 22px;
    border-left: 5px solid #569ee3;
    text-align: left;
  }
}
</style>
