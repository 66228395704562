<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header">
                    <h1 class="headerh1">项目资金情况汇总</h1>
                </div>
                <div class="serverTable">
                    <el-descriptions title="项目资金情况汇总（单位:元。 注：其中付款比例以及相关的履约金信息来源于合同管理中的工程总承包合同表）" border :column="4" direction="vertical">
                        <el-descriptions-item label="合同总额">{{tableData.contractSum}}</el-descriptions-item>
                        <el-descriptions-item label="付款比例">{{tableData.paymentRatio}}</el-descriptions-item>
                        <el-descriptions-item label="履约保证金/保函">{{tableData.performanceBond}}</el-descriptions-item>
                        <el-descriptions-item label="履约保函期限">{{tableData.performanceDeadlines}}</el-descriptions-item>
                        <el-descriptions-item label="工程进度计量">{{tableData.progressMeasurement}}</el-descriptions-item>
                        <el-descriptions-item label="项目应收">{{tableData.projectReceivable}}</el-descriptions-item>
                        <el-descriptions-item label="项目实际收款">{{tableData.actualCollection}}</el-descriptions-item>
                        <el-descriptions-item label="项目拨款">{{tableData.projectAppropriation}}</el-descriptions-item>
                        <el-descriptions-item label="账上余额">{{tableData.balanceAccount}}</el-descriptions-item>
                        <el-descriptions-item label="剩余应收款项">{{tableData.remainingReceivables}}</el-descriptions-item>
                        <el-descriptions-item label="质量保证金/保函">{{tableData.qualityAssurance}}</el-descriptions-item>
                        <el-descriptions-item label="质量保函期限">{{tableData.qualityDeadlines}}</el-descriptions-item>
                    </el-descriptions>
                </div>
            </el-card>
        </div>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                tableData:{
                    "contractSum": null,
                    "paymentRatio": null,
                    "progressMeasurement": null,
                    "projectReceivable": null,
                    "actualCollection": null,
                    "projectAppropriation": null,
                    "balanceAccount": null,
                    "remainingReceivables": null,
                    performanceBond:null,
                    performanceDeadlines:null,
                    qualityAssurance:null,
                    qualityDeadlines:null,
                },
                isZhank: false,
                menuTable:[],
                menuId:window.sessionStorage.getItem('menuId'),
            }
        },
        created(){
            this.getLeftMenu()
            this.getProApp()
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http1.get('/v1.0/enterprises/project-progress/statisticsByProjectId?projectId='+this.$root.proId)
              this.tableData=res.data
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=64')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/enterprises/project-progress/delete/'+row.id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
             async yulan(path){
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async downFile(path,name){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(){
                this.fileList=[]
                this.writeForm=true
            },
            isPDF(file) {
            return file.name.toLowerCase().endsWith('.pdf');
            },
           async uploadModel(){
            var formData = new FormData();
            for(let i in this.fileList){
                if(this.isPDF(this.fileList[i])){
                    formData.append("files", this.fileList[i].raw);
                 }else{
                    this.$message.error("文件格式为PDF");
                    return
                 }
            }
            const { data: res2 } = await this.$http1.post('/v1.0/enterprises/project-file/upload/AttacheInfoBatch', formData)
            if(res2.businessCode==1000){
            this.addForm.attacheIds=[]
            for(let j in res2.data){
                this.addForm.attacheIds.push(res2.data[j].attacheId)
            }
            const { data: res } = await this.$http1.post('/v1.0/enterprises/project-progress/save', this.addForm)
            if(res.businessCode==4000){
                this.$message.success("提交成功");
                this.getProApp()
                this.writeForm = false;
            }else{
              this.$message.error("提交失败！");
            }
           }
          }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
.headerh1{
    text-align: center;
    font-size: 24px;
}
/deep/ .el-descriptions-item__label{
    color: #fff;
    font-size: 18px;
    font-weight: 777 !important;
    background: #e99f6e;
}
/deep/ .el-descriptions-item__content{
    font-size: 16px;
}
  </style>
  