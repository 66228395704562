var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bg"},[_c('div',{attrs:{"id":"kaoqinghome"}},[_c('table',{staticClass:"content",attrs:{"data-v-3286e6af":""}},[_c('tr',{attrs:{"data-v-3286e6af":""}},[_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.promanage}},[_vm._m(0)]),_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.user}},[_vm._m(1)]),_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.goRole}},[_vm._m(2)]),_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.goRgroup}},[_vm._m(3)])]),_c('tr',{attrs:{"data-v-3286e6af":""}},[_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.unitmanage}},[_vm._m(4)]),_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.goRes}},[_vm._m(5)]),_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.goMenu}},[_vm._m(6)]),_c('td',{staticClass:"cor4",attrs:{"data-v-3286e6af":""},on:{"click":_vm.goFlow}},[_vm._m(7)])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("系统项目管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("维护平台所有项目信息")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("平台用户管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("用来维护平台的管理员用户")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("平台角色管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("维护平台所有人员所属角色")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("平台角色组管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("维护平台所有角色关联的角色组")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("平台单位管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("用来维护平台的统一单位")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("平台资源管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("用来维护平台的统一资源")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("平台菜单管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("用来维护平台的统一菜单")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"grid-content",attrs:{"data-v-3286e6af":""}},[_c('div',{staticClass:"icon-btn",attrs:{"data-v-3286e6af":""}},[_c('img',{attrs:{"data-v-3286e6af":"","src":require("../../assets/img/daohang/project-info-selected.png")}})]),_c('div',{staticClass:"title",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("流程管理")])]),_c('div',{staticClass:"slogan",attrs:{"data-v-3286e6af":""}},[_c('span',{attrs:{"data-v-3286e6af":""}},[_vm._v("维护平台所有流程信息...")])])])
}]

export { render, staticRenderFns }