import { render, staticRenderFns } from "./historyProcess.vue?vue&type=template&id=60e7c1b3&scoped=true"
import script from "./historyProcess.vue?vue&type=script&lang=js"
export * from "./historyProcess.vue?vue&type=script&lang=js"
import style0 from "./historyProcess.vue?vue&type=style&index=0&id=60e7c1b3&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "60e7c1b3",
  null
  
)

export default component.exports