<template>
    <div class="bg">
        <hou-left></hou-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" style=" ">
                    <el-button size="medium" type="goon"  @click="admin" >管理员部署系统流程</el-button>
                    <el-button size="medium" type="goon"  @click="addform"  style="float: right">添加业务表单</el-button>

        <!--            <el-button size="medium" type="goon" round @click="daiban" >查询本人待办业务</el-button>-->
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            style="width: 100%;background-color: #142245">
                        <el-table-column
                                type="index"
                                label="序号"
                                width="60">
                        </el-table-column>
                        <el-table-column
                                prop="formName"
                                label="表单名称"
                                width="200">
                        </el-table-column>
                        <el-table-column
                                prop="categoryName"
                                label="表单流程类别"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="formDescription"
                                label="表单描述"
                                width="360">
                        </el-table-column>
                        <el-table-column
                                align="center"
                                label="操作"
                              >
                            <template slot-scope="scope">
                                <el-button  type="primary" @click="chakan(scope.row)" icon="el-icon-info" size="mini">查看</el-button>
                                <el-button @click="drawliucheng(scope.row)" type="primary" size="mini" icon="el-icon-info">流程绘制</el-button>
                                <el-button @click="qidongliucheng(scope.row)" type="primary" size="mini" icon="el-icon-info">查看流程</el-button>
                                <el-button @click="editliucheng(scope.row)" type="primary" size="mini" icon="el-icon-info">修改流程</el-button>
                                <el-button v-if="scope.row.isDeploy==0" @click="liuchengbushu(scope.row)" type="primary" size="mini" icon="el-icon-video-play">流程部署</el-button>
                                <el-button v-if="scope.row.isDeploy==1" @click="quxiaobushu(scope.row)" type="primary" size="mini" icon="el-icon-video-pause">取消部署</el-button>
                               <el-button @click="deleteMsg(scope.row)" type="primary" size="mini" icon="el-icon-info">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="业务表单"
                :close-on-click-modal="false"
                :visible.sync="formDialogVisible"
                width="30%"
                center>
            <form-create v-model="fApi" :rule="rule" :option="option" :value.sync="value"></form-create>

        </el-dialog>
        <el-dialog
                title="流程查看"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                center
        >
            <div slot="footer" class="dialog-footer">
                <div id="sbgimg" style="width: 100%;max-width: 950px;overflow:auto;max-height: 400px ">
                </div>
            </div>
        </el-dialog>
        <el-dialog
                title="管理员部署流程-通用"
                :visible.sync="adminDialogVisible"
                width="30%"
                center>
            <el-form ref="addFormRef" :model="addForm" label-width="100px">
                <el-form-item label="文件名称" prop="fileName">
                    <el-input size="medium" v-model="addForm.fileName"></el-input>
                </el-form-item>
                <el-form-item label="流程图中的key" prop="processKey">
                    <el-input size="medium" v-model="addForm.processKey"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
    <el-button size="medium" type="goon" round @click="adminDialogVisible = false">取 消</el-button>
    <el-button size="medium" type="goon" round @click="addAdmin" >确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
    import {
        xmlStr3
    } from './mock/xmlStr3'
    import  eventBus from '../../assets/js/eventBus'
    import houLeft from "./houTaiLeft";
    export default {
        name: "allliucheng",
        data() {
            return {
                tableData: [],
                formDialogVisible:false,
                dialogVisible:false,
                fApi: {},
                //表单数据
                value: {},
                //表单生成规则
                rule: [
                ],
                //组件参数配置
                option: {
                    resetBtn: true,
                    //表单提交事件

                },
                adminDialogVisible:false,
                addForm:{
                    fileName:'',
                    processKey:''
                }
            }
        },
        created(){
            this.getAll();
        },
        methods:{
            async getAll() {
                const {data:result} = await this.$http3.get('/v1.0/activiti/form/findAll')
                if(result.status!=3000){
                    this.$message.error('查询失败'+result.message)
                }
                this.tableData=result.data;
            },
            addform(){
                this.$router.push({ path: "/createForm" });
            },
           async chakan(row){
              // eventBus.$emit('formId',row.id)
               //this.$router.push({ path: "/from" });
               this.option.onSubmit=''
               this.generateFormFn(row.id)
            },
            async generateFormFn(id) {
                //   this.rule = [{}];
                const {data:result}=await this.$http3.get('/v1.0/activiti/form/findById?id='+id);
                console.log(JSON.parse(result.data.formJson))
                var rul=[]
               /* for(let i in  JSON.parse(result.formJson).list){
                    rul.push({type:JSON.parse(result.formJson).list[i].type,field:JSON.parse(result.formJson).list[i].key,title:JSON.parse(result.formJson).list[i].description})
                }*/
                rul=JSON.parse(result.data.formJson).list
                console.log(rul)
                this.rule=rul
                this.formDialogVisible=true
                /*       this.rule.push(
                           {
                               type: "slider",
                               field: "volume",
                               title: "音量大小：",
                               value: 60,
                               //value: [25, 80],  // 和range: true 时一起使用
                               props: {
                                   min: 0,
                                   max: 100,
                                   showTip: "always",
                                   range: false // 起始分段
                               }
                           },

                           {
                               type: "InputNumber",
                               field: "jiajie",
                               title: "商品增减：",
                               value: 0,
                               col: {
                                   md: { span: 12 }
                               },
                               validate: [
                                   {
                                       required: true,
                                       //   min: 1,
                                       message: "商品数里至少为1",
                                       trigger: "change"
                                   }
                               ]
                           },

                           {
                               type: "ColorPicker",
                               field: "color",
                               title: "喜欢颜色：",
                               value: "#00b102",
                               col: {
                                   md: { span: 12 }
                               },
                               props: {
                                   alpha: true
                               }
                           },

                           {
                               type: "DatePicker",
                               field: "dateTime",
                               title: "起止日期：",
                               value: ["2020-04-20", new Date()],
                               props: {
                                   type: "daterange",
                                   // format: "yyyy-MM-dd HH:mm:ss",
                                   format: "yyyy-MM-dd",
                                   placeholder: "请选择起止日期"
                               }
                           },

                           {
                               type: "rate",
                               field: "rateNumber",
                               title: "评分效果：",
                               value: 3,
                               props: {
                                   count: 8,
                                   allowHalf: true // 是否可选半星
                               },
                               validate: [
                                   {
                                       required: true,
                                       type: "number",
                                       min: 4,
                                       message: "请大于3颗星",
                                       trigger: "change"
                                   }
                               ],
                               control: [
                                   {
                                       handle: function(val) {
                                           console.log(val + "颗星");
                                       }
                                   }
                               ]
                           },

                           {
                               type: "switch",
                               field: "offon",
                               title: "打开关闭：",
                               value: true,
                               col: {
                                   md: { span: 6 }
                               },
                               props: {
                                   trueValue: true,
                                   falseValue: false,
                                   slot: {
                                       open: "开启",
                                       close: "关闭"
                                   }
                               }
                           },

                           {
                               type: "upload",
                               field: "imgFile",
                               title: "图片上传：",
                               value: [
                                   "https://inews.gtimg.com/newsapp_ls/0/11673471712_295195/0",
                                   "http://file.lotkk.com/form-create.png",
                                   "https://inews.gtimg.com/newsapp_ls/0/11673643537_295195/0"
                               ],
                               col: {
                                   md: { span: 18 }
                               },
                               validate: [
                                   {
                                       required: true,
                                       type: "array",
                                       min: 1,
                                       message: "请上传1张图片",
                                       trigger: "change"
                                   }
                               ],
                               props: {
                                   type: "select",
                                   uploadType: "image", // file
                                   name: "userPhoto", // name属性
                                   multiple: true, // 是否可多选
                                   allowRemove: true,
                                   accept: "image/!*", // 上传文件类型
                                   format: ["jpg", "jpeg", "png", "gif"], // 上传文件格式
                                   maxSize: 2048, // 上传文件大小最大值
                                   maxLength: 5, // 上传文件数量最大值
                                   action: "http://www.upimage.com/imguploadApi", // 上传后端接收API接口
                                   onSuccess: function(res) {
                                       return ""; // 上传成功回调函数
                                   }
                               }
                           }
                       );*/
            },
            drawliucheng(row){
                this.$root.liuchengxml=xmlStr3
                window.sessionStorage.setItem("formId",row.id);
                this.$router.push({ path: "/drawLc" });
            },
            async liuchengbushu(row){
                const {data:result}=await this.$http3.get('/v1.0/activiti/process/deploy/byFormId?formId='+row.id);
                if(result.status!=8040){
                    return this.$message.error('流程部署失败！')
                }
               /* var formbs=result;
                formbs.fileSuffix='';
                console.log(formbs)*/
                //const {data:result1}=await this.$http2.post('/deploy',formbs);
                this.$message.success('流程部署成功！')
                this.getAll();
            },
            async quxiaobushu(row){
                const {data:result}=await this.$http3.post('/v1.0/activiti/process/deploy/cancel?formId='+row.id);
                if(result.status!=8030){
                    return this.$message.error('取消流程部署失败！')
                }
                this.$message.success('取消流程部署成功！')
                this.getAll();
            },
            async qidongliucheng(row){
                console.log(row)
                    const {data:result}=await this.$http3.get('/v1.0/activiti/process/queryFlowImgByProcInstId?procInstId='+row.id);
                    var index=result.lastIndexOf("<svg");
                    this.dialogVisible=true
                    document.getElementById('sbgimg').innerHTML=(result.substring(index,result.length-1))
            },
            daiban(){
                this.$router.push({ path: "/daiban" });
            },
            async editliucheng(row){
                const {data:res}= await this.$http3.get('/v1.0/activiti/depoly/file/queryBpmnFile?formId='+row.id)
                var xml=res.replace(/activiti/g,"camunda");
                xml=xml.replace(/epccamunda/g,"epcactiviti");
                xml=xml.replace(/org\/bpmn/g,"org/schema/1.0/bpmn");
                var xml2=xml.replace(/\$/g,"\\$");
                console.log(xml2)
                this.$root.liuchengxml=xml2
                //eventBus.$emit('xml',xml2)
                this.$router.push({ path: "/drawLc" });
            },
            async deleteMsg(row){
                console.log(row)
                const confirmResult = await this.$confirm('此操作将永久删除该流程表单且同步删除其流程定义及部署，已经启动的流程也会删除, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http3.delete('/v1.0/activiti/form/delete/'+row.id)
                if(res.status!="4000"){
                    return this.$message.error('删除流程表单失败！')
                }
                this.$message.success('删除流程表单成功！');
                this.getAll();
            },
            admin(){
               this.adminDialogVisible=true
            },
            async addAdmin(){
                const {data:res}= await this.$http3.post('/v1.0/activiti/process/deploy/general',this.addForm)
                if(res.status!=6000){
                    return this.$message.error('流程部署失败！')
                }else {
                    return this.$message.success('流程部署成功！')
                }
            }
        },
        components:{
            houLeft
        }
    }
</script>

<style scoped lang="less">

</style>
