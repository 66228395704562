<template>
  <div class="bg" style="">
    <div class="bgform">
      <div>
        <el-radio v-model="radio" label="1">账号密码登录</el-radio>
        <form class="form1" autocomplete="off" @submit.prevent="onSubmit">
          <div class="inputdiv">
            <el-input placeholder="请输入您的账号" v-model="UserName">
              <i slot="prefix" class="el-icon-login-user"></i>
            </el-input>
            <el-input
              placeholder="请输入您的密码"
              v-model="PassWord"
              show-password
              class="passwordInput"
            >
              <i slot="prefix" class="el-icon-login-password"></i>
            </el-input>
            <div class="rem">
              <el-checkbox v-model="checked">记住密码</el-checkbox>
            </div>
            <button
              class="btn"
              slot="reference"
              @click="btn"
              :disabled="isDisable"
            >
              登 录
            </button>
          </div>
        </form>
      </div>
    </div>
    <div class="erweima">
      <el-image
        style="width: 155px; height: 155px"
        :src="url"
        :preview-src-list="srcList"
      >
      </el-image>
    </div>
    <div class="logoInfo">
      <a href="#" style="color: #252b39; text-decoration: none"
        >@2023 &copy;新疆兵团勘测设计院集团股份有限公司</a
      >
      <br />数字工程院
    </div>
  </div>
</template>

<script>
import AES from "../utils/aes.js";
export default {
  name: "login",
  data() {
    return {
      UserName: "",
      PassWord: "",
      checked: false,
      radio: "1",
      isDisable: false,
      btnData: [],
      menuLevel1: [],
      wuzhihuamenu: [],
      menuLevel2: [[], [], [], [], [], []],
      url: require("../assets/img/EPC.png"),
      srcList: [require("../assets/img/EPC.png")],
    };
  },
  methods: {
    onSubmit() {
      return false;
    },
    async btn() {
      this.isDisable = true;
      this.btnData = [];
      this.menuLevel2 = [[], [], [], [], [], []];
      console.log(this.$root.NET);
      let userForm = new URLSearchParams();
      userForm.append('grant_type', 'password');
      userForm.append('client_id', 'client-app');
      userForm.append('client_secret', '123456');
      userForm.append("username", this.UserName);
      userForm.append("password", AES.encrypt(this.PassWord));
    //   const { data: res } = await this.$httpAuto.post("/token", userForm);
    //   if (res.businessCode === 2000) {
    //     localStorage.setItem("userinfo", JSON.stringify(res.data));
    //     const token =res.data.access_token !== undefined && res.data.access_token !== null ? res.data.access_token : null;
    //     const startTime = Date.now();
    //     const expiresTime = res.data.expires_in ? res.data.expires_in : null;
    //     const expiresInfo = {
    //       token: token,
    //       start_time: startTime,
    //       expires_time: expiresTime,
    //     };
    //     localStorage.setItem("token", token);
    //     localStorage.setItem("expiresInfo", JSON.stringify(expiresInfo));
    //     await this.getUserAuthorization(res.data.id);
    //     return true;
    //   } else {
    //     return false;
    //   }
      const { data: res2 } = await this.$httpAuto.post("/token", userForm);
      if (res2.businessCode === 2000) {
      //  const token =res.data.access_token !== undefined && res.data.access_token !== null ? res.data.access_token : null;
        window.sessionStorage.setItem("token", res2.data.access_token);
        window.sessionStorage.setItem("userid", res2.data.id);
        window.sessionStorage.setItem("organizationName", res2.data.organizationName);
        window.sessionStorage.setItem("name", res2.data.realName);
      }else{
        this.isDisable = false;
        return this.$message.error('登陆失败!' + res2.message)
      }
    // const { data: res } = await this.$httpaxios.post('http://8.130.180.224:8813/doLogin/login?username=' + this.UserName + '&password=' + this.PassWord)
          const { data: res } = await this.$http0.get('/v1/account/user/query/findComplexUserEntity?userId=' + res2.data.id)
          if (res.businessCode != '3000') {
            this.isDisable = false;
                return this.$message.error('登陆失败!' + res.message)
            } else {
                if(this.checked){
                    localStorage.setItem('userID',this.UserName)
                    localStorage.setItem('passID',this.PassWord)
                }else{
                    localStorage.removeItem('userID')
                    localStorage.removeItem('passID')
                }
                this.menuLevel1 = [];
                this.wuzhihuamenu=[]
                this.getMenu1(res.data.roleGroup.roles[0].menus);
                for (let i in res.data.roleGroup.roles[0].menus) {
                    if (res.data.roleGroup.roles[0].menus[i].parent.path == '/projectInfo') {
                        this.getMenu2(0, res.data.roleGroup.roles[0].menus[i])
                    } else if (res.data.roleGroup.roles[0].menus[i].parent.path == '/work') {
                        this.getMenu2(1, res.data.roleGroup.roles[0].menus[i])
                    } else if (res.data.roleGroup.roles[0].menus[i].parent.path == '/proprocess') {
                        this.getMenu2(2, res.data.roleGroup.roles[0].menus[i])
                    } else if (res.data.roleGroup.roles[0].menus[i].parent.path == '/proAttendance') {
                        this.getMenu2(3, res.data.roleGroup.roles[0].menus[i])
                    } else if (res.data.roleGroup.roles[0].menus[i].parent.path == '/proBackManage') {
                        this.getMenu2(4, res.data.roleGroup.roles[0].menus[i])
                    } else if (res.data.roleGroup.roles[0].menus[i].parent.path == '/regulation') {
                        this.getMenu2(5, res.data.roleGroup.roles[0].menus[i])
                    } 
                }
                //需要验证权限，用户id,用户菜单
               // window.sessionStorage.setItem("token", res.data.token);
                window.sessionStorage.setItem("menu", JSON.stringify(res.data.roleGroup.roles[0].menus));
                window.sessionStorage.setItem("wuzhihuamenu", JSON.stringify(this.wuzhihuamenu));
                window.sessionStorage.setItem("menulevel1", JSON.stringify(this.menuLevel1));
                window.sessionStorage.setItem("menulevel2", JSON.stringify(this.menuLevel2));
                // window.sessionStorage.setItem("name", res.data.user.name);
                // window.sessionStorage.setItem("userid", res.data.user.id);
                window.sessionStorage.setItem("username", res.data.username);
                window.sessionStorage.setItem("btnData", JSON.stringify(this.btnData));
                this.$router.push({ path: "/home" });
                this.isDisable = false
            }
    },
    async getUserAuthorization(userId){
        const { data: res } = await this.$http1.get("/user/query/findComplexUserEntity?userId="+userId);
    },
    getMenu1(data) {
      for (let index in data) {
        // if (data[index].parent.unitElement) {
        //   this.btnData.push(data[index].parent.unitElement);
        // }
        if (data[index].parent.parentId == 'init_level' && data[index].parent.name!='项目总览' && data[index].parent.name!='平台系统管理'  && data[index].parent.description!='无纸化办公') {
          this.menuLevel1.push({
            name: data[index].parent.name,
            path: data[index].parent.path,
            icon: data[index].parent.meta.icon,
            redirect: data[index].parent.redirect,
          });
        }
        if (data[index].parent.parentId == 'init_level' && data[index].parent.description == '无纸化办公' && data[index].parent.name !== '无纸化办公' ) {
          this.wuzhihuamenu.push({
            name: data[index].parent.name,
            path: data[index].parent.path,
            icon: data[index].parent.meta.icon,
            menuId:data[index].parent.menuId,
            redirect: data[index].parent.redirect,
          });
        }
        if (data[index].children.length > 0) {
          this.getMenu1(data[index].children);
        }
      }
    },
    getMenu2(index, data) {
      console.log(data)
      if (data.children.length > 0) {
        for (let x in data.children) {
          if (data.children[x].parent.parentId !== 'init_level' && data.children[x].parent.parentId !== '1000') {
            this.menuLevel2[index].push({
              name: data.children[x].parent.name,
              path: data.children[x].parent.path,
              iconCls: data.children[x].parent.meta.icon,
              unit: data.children[x].parent.unitElement,
            });
          }
        }
      }
    },
  },
 
  mounted() {
    if (localStorage.hasOwnProperty("userID")) {
      this.UserName = localStorage.getItem("userID");
      this.PassWord = localStorage.getItem("passID");
      this.checked = true;
    }
  },
};
</script>

<style scoped lang="less">
.bg {
  width: 100%;
  height: 100vh;
  background: url("../assets/img/login/loginback.jpg");
  background-repeat: no-repeat;
  background-size: 100% 110%;
}

.bgform {
  position: absolute;
  width: 290px;
  height: 350px;
  left: 1080px;
  top: 340px;
  text-align: center;
}

.erweima {
  position: absolute;
  left: 1472px;
  top: 598px;
}

/deep/ .el-radio__label {
  color: #a7a7a7 !important;
  font-size: 18px;
}

.form1 {
  text-align: left;
  background: #fff;
  width: 280px;
}

.inputdiv {
  overflow: hidden;
  height: 100%;
  padding-top: 70px;
}

/deep/ .el-input__inner {
  background-color: #ffffff;
  height: 38px;
  padding-left: 50px;
}

/deep/ .el-input--prefix .el-input__inner {
  padding-left: 50px;
}

/deep/ .el-input__inner::placeholder {
  color: #a4a4ae;
  font-size: 14px;
}

.passwordInput {
  margin-top: 32px;
}

.rem {
  margin-top: 52px;
  float: left;

  /deep/ .el-checkbox__label {
    font-size: 16px;
  }
}

.btn {
  background-image: linear-gradient(90deg, #51a1fa 0%, #4b88e3 74%);
  border-radius: 4px;
  width: 110px;
  border: 1px solid var(--blue);
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  padding: 10px 31px;
  text-transform: uppercase;
  color: white;
  transition: transform 80ms ease-in;
  margin-top: 42px;
  transition: 0.8s !important;
  height: 40px;
  margin-left: 70px;
  float: left;
}

.el-icon-login-user {
  background: url(../assets/img/login/用户名.png) center no-repeat;
  margin-top: 11px;
  margin-left: 8px;
}

.el-icon-login-user:before {
  content: "替";
  font-size: 16px;
  visibility: hidden;
}

.el-icon-login-password {
  background: url(../assets/img/login/密码.png) center no-repeat;
  margin-top: 11px;
  margin-left: 8px;
}

.el-icon-login-password:before {
  content: "替";
  font-size: 16px;
  visibility: hidden;
}

.logoInfo {
  position: absolute;
  text-align: center;
  bottom: 2%;
  width: 100%;
  font-size: 14px;
  color: #252b39;
}
</style>
