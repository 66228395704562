<template>
    <div class="main">
    <div class="containers">
        <div class="canvas" ref="canvas">
        </div>
        <div id="js-properties-panel" class="panel"></div>
        <ul class="buttons">
            <li>

                <el-button type="" round @click="bcbpmn">保存</el-button>
            </li>
            <li>
                <el-button type="" round @click="fanhui">返回</el-button>
            </li>
            <!--<li>-->
                <!--<a ref="saveSvg" href="javascript:" title="保存为svg">保存为svg</a>-->
            <!--</li>-->
            <!--<li>-->
                <!--<a @click="bushu" title="部署">部署</a>-->
            <!--</li>-->
            <!--<li>-->
                <!--<a @click="qidong" title="qidong">qidong</a>-->
            <!--</li>-->
        </ul>
        <el-dialog
                title="输入id"
                :close-on-click-modal="false"
                :visible.sync="dialogVisible"
                width="30%"
              >
            <el-input v-model="input" placeholder="请输入内容"></el-input>
            <span slot="footer" class="dialog-footer">
    <el-button @click="dialogVisible = false">取 消</el-button>
    <el-button type="primary" @click="bushu2">确 定</el-button>
  </span>
        </el-dialog>
    </div>
    </div>
</template>

<script>
    // 引入相关的依赖
    import  eventBus from '../../assets/js/eventBus'
    import BpmnModeler from 'bpmn-js/lib/Modeler'
    import {
        xmlStr3
    } from './mock/xmlStr3' // 这里是直接引用了xml字符串
    import propertiesPanelModule from 'bpmn-js-properties-panel'
    import propertiesProviderModule from 'bpmn-js-properties-panel/lib/provider/camunda'
    import camundaModdleDescriptor from 'camunda-bpmn-moddle/resources/camunda'
    import customTranslate from './mock/customTranslate'


    var customTranslateModule = {
        translate: ['value', customTranslate]
    };
    export default {
        name: "drawliuCheng",
        data() {
            return {
                // bpmn建模器
                bpmnModeler: null,
                container: null,
                canvas: null,
                xmlUrl: '',
                defaultXmlStr: xmlStr3,
                bpmndata:'',
                bpmnfile:'',
                input:'',
                dialogVisible:false,
                formId:'',
                xmlStr:this.$root.liuchengxml,
            }
        },
        mounted() {
            // eventBus.$on('dda',(message)=>{
            //     console.log(message)
            //     this.formId=message
            // })
            /*this.$nextTick(() => {
                eventBus.$on('xml',(message)=>{
                    console.log(2222222222222)
                    this.xmlStr=message
                })
            });*/
            console.log(this.xmlStr)
            this.init(this.xmlStr);
        },
        beforeDestory() {
            eventBus.$off('bmpnxml', (message) => console.log(message))
        },
        methods: {
            init(xmlStr) {
                // 获取到属性ref为“canvas”的dom节点
                const canvas = this.$refs.canvas
                // 建模
                this.bpmnModeler = new BpmnModeler({
                    container: canvas,
                     propertiesPanel: {
                         parent: '#js-properties-panel'
                     },
                     additionalModules: [
                         // 右边的属性栏
                         propertiesProviderModule,
                         propertiesPanelModule,
                         customTranslateModule
                     ],
                    moddleExtensions: {
                        camunda: camundaModdleDescriptor
                    }
                })
                console.log(xmlStr)
                this.createNewDiagram(xmlStr)
            },
            createNewDiagram(xmlStr) {
                // 将字符串转换成图显示出来
                this.bpmnModeler.importXML(xmlStr, (err) => {
                    if (err) {
                        // console.error(err)
                    } else {
                        // 这里是成功之后的回调, 可以在这里做一系列事情
                        this.success()
                    }
                })
            },
            success() {
                this.addBpmnListener()
                this.addModelerListener()
            },
            addBpmnListener () {
                const that = this
                // 获取a标签dom节点
                const downloadLink = this.$refs.saveDiagram
                const downloadSvgLink = this.$refs.saveSvg
                // 给图绑定事件，当图有发生改变就会触发这个事件
                this.bpmnModeler.on('commandStack.changed', function () {
                  /*  that.saveSVG(function(err, svg) {
                        that.setEncoded(downloadSvgLink, 'diagram.svg', err ? null : svg)
                    })*/
                    that.saveDiagram(function(err, xml) {
                        that.setEncoded(downloadLink, 'diagram.bpmn', err ? null : xml)
                    })
                })
            },
            addModelerListener() {
                // 监听 modeler
                const bpmnjs = this.bpmnModeler
                const that = this
                // 'shape.removed'
                const events = ['shape.added', 'shape.move.end', 'connect.end', 'connection.create', 'connection.move']
                events.forEach(function(event) {
                    that.bpmnModeler.on(event, e => {
                        console.log(event, e)
                        var elementRegistry = bpmnjs.get('elementRegistry')
                        var shape = e.element ? elementRegistry.get(e.element.id) : e.shape
                        console.log(shape)

                    })
                })
            },
            saveSVG(done) {
                // 把传入的done再传给bpmn原型的saveSVG函数调用
                this.bpmnModeler.saveSVG(done)
            },
            // 下载为bpmn格式,done是个函数，调用的时候传入的
            saveDiagram(done) {
                // 把传入的done再传给bpmn原型的saveXML函数调用
                this.bpmnModeler.saveXML({ format: true }, function(err, xml) {
                    done(err, xml)
                })
            },
            // 当图发生改变的时候会调用这个函数，这个data就是图的xml
            setEncoded(link, name, data) {
                // 把xml转换为URI，下载要用到的
               //  console.log( typeof  data)

                const encodedData = encodeURIComponent(data)
                //console.log(encodedData)
                // 下载图的具体操作,改变a的属性，className令a标签可点击，href令能下载，download是下载的文件的名字
                //console.log(link, name, data)
                // this.bpmnfile = new File([data], 'test.bpmn')
                 this.bpmndata=data;

                /*console.log(xmlFile)*/
                if (data) {
                    link.className = 'active'
                    //link.href = 'data:application/bpmn20-xml;charset=UTF-8,' + encodedData
                    link.download = name
                }
            },
            async  bcbpmn(){
                console.log(this.bpmndata)
                const elementRegistry = this.bpmnModeler.get('elementRegistry');
               var processKey='',processName='';
                for(let i in elementRegistry.getAll()){
                    if(elementRegistry.getAll()[i].type=="bpmn:Process"){
                        processKey=elementRegistry.getAll()[i].id;
                        processName=elementRegistry.getAll()[i].businessObject.name
                    }
                }
                if(this.bpmndata.indexOf('selftask')==-1){
                    return  this.$message.error('本人发起流程默认节点不允许修改！')
                }
                this.bpmndata=this.bpmndata.replace(/camunda/g,"activiti");
                this.bpmndata=this.bpmndata.replace(/schema\/1.0\//,"");
                this.bpmnfile = new File([this.bpmndata], 'test.bpmn')
                var formData1=new FormData();
                formData1.append("multipartFile", this.bpmnfile);
                formData1.append("processName",processName);
                formData1.append("processKey",processKey);
                formData1.append("formId",window.sessionStorage.getItem("formId"));
                formData1.append("processDescription",'流程描述');
                console.log(formData1)
               const {data:result}=await this.$http3.post('/v1.0/activiti/depoly/file/save',formData1);
                if(result.status!=6000){
                    return this.$message.error('流程保存失败！'+result.message)
                }
                this.$message.success('流程保存成功！')
                this.$router.push({ path: "/activiti" });
            },
            async bushu(){
              // this.dialogVisible=true;
                this.setNodeColor();
            },
            async bushu2(){
                this.dialogVisible = false
                //const {data:result}=await this.$http4.get('/findById?id='+this.input);
                //console.log(result)
                const {data:result}=await this.$http2.post('/deploy',{
                    id:'3b4bcf622cc74e089b1dfc7e4bb63ec6',
                    fileName:'71d7efd6fe714fe4af42ab14fe93a028_test.bpmn',
                    filePath:'res/process/test.bpmn',
                    fileSuffix:'',
                    processName:'test',
                    processKey:'key1',
                    processDescription:'流程描述',
                    isStart:1,
                });
                console.log(result)
            },
            async qidong(){
                const {data:result}=await this.$http2.get('/startProcess?formKey=key1'+'&bussinessKey='+'152'+'&id=5');
                console.log(result)
            },
            setNodeColor(){
                const elementRegistry = this.bpmnModeler.get('elementRegistry');
                console.log(elementRegistry)
                this.nodeList = elementRegistry.filter (
                    (item) => item.type === 'bpmn:UserTask' || item.type === 'bpmn:ServiceTask' || item.type==='bpmn:SequenceFlow' || item.type==='bpmn:StartEvent'
                );
                // 此时得到的userTaskList 便是流程图中所有的节点的集合
               // console.log(this.nodeList);
                // 步骤2 ：为节点添加颜色
                // 方式1 ：modeling.setColor(参数1：节点，可以是单个元素实例，也可是多个节点组成的数组，参数2：class类);
                let modeling = this.bpmnModeler.get('modeling');
                for(let i in this.nodeList){
                    modeling.setColor(this.nodeList[i], {
                        stroke: '#0eff57',
                        fill: 'white'
                    });
                }

            } ,
            fanhui(){
                this.$router.push({ path: "/activiti" });
            }



        }
    }
</script>

<style scoped>
    .containers{
        position: absolute;
        background-color: #ffffff;
        width: 100%;
        height: 90%;
    }
    .canvas{
        width: 100%;
        height: 90%;
    }
    .panel{
        position: absolute;
        right: 0;
        top: 0;
        width: 300px;
    }
    .buttons {
        position: absolute;
        right: 330px;
        top: 20px;
    }
    .buttons li {
        display: inline-block;
        margin: 5px;
    }
    .buttons li a {
        color: #999;
        background: #eee;
       /* cursor: not-allowed;*/
        padding: 8px;
        border: 1px solid #ccc;
        text-decoration: none;
    }
    .buttons li a.active {
        color: #333;
        background: #fff;
        cursor: pointer;
    }
   /deep/ .bpp-textfield input{
        padding-right: 5px !important;
    }
</style>
