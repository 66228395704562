<template>
    <div class="site-wrapper">
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div id="CategoryTree">
                    <el-input @clear="insertActBtCategory_clear" placeholder="输入关键字进行过滤" clearable v-model="filterText">
                    </el-input>
                    <el-tree class="filter-tree" :data="dataCategory" :props="defaultCategoryProps" default-expand-all
                        :filter-node-method="filterCategoryNode" ref="tree" show-checkbox node-key="categoryId"
                        :check-strictly="true" @check-change="handleCheckChange" @node-click="nodeClick">
                        <!-- 处理菜单内容过长 -->
                        <span class="custom-tree-node" slot-scope="{ node }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <span> {{ node.label | ellipsis(8) }} </span>
                            </el-tooltip>
                        </span>
                    </el-tree>
                </div>
                <div id="pageList">
                    <div class="addActBtCategory">
                        <div class="catleft">
                            <span class="title-left">已办流程</span>
                        </div>
                        <div style="display: flex;justify-content: flex-end">
                            <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                            </el-button>
                        </div>
                    </div>
                    <!-- 列表 -->
                    <div>
                        <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                            <template>
                                <el-empty :image-size="100" description="暂无数据"></el-empty>
                            </template>
                            <el-table-column prop="procdefInfoVO.processDeploymentPageVO.actBtCategory.categoryName"
                                label="类别名称" width="200" show-overflow-tooltip>
                                <template #default="scope">
                                    <el-tag @click="getInfo2(scope.row)" style="cursor: pointer">{{
                                        scope.row.procdefInfoVO.processDeploymentPageVO.actBtCategory
                                            .categoryName
                                    }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="procdefInfoVO.processDefinitionName" label="流程名称" width="200"
                                show-overflow-tooltip />
                            <el-table-column prop="taskName" label="任务名称" width="200" show-overflow-tooltip>
                            </el-table-column>
                            <el-table-column prop="assignee" label="签收者" width="110" show-overflow-tooltip>
                                <template #default="scope">
                                    <span v-if="scope.row.assignee">{{
                                        scope.row.assignee.split("_")[1]
                                    }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column prop="startTime" label="开始时间" width="210">
                                <template #default="scope">
                                    <div style="display: flex; align-items: center">
                                        <el-icon>
                                            <timer />
                                        </el-icon>
                                        <span style="margin-left: 10px">{{
                                            convertUTCTimeToLocalTime(scope.row.startTime)
                                        }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column prop="endTime" label="结束时间" width="210">
                                <template #default="scope">
                                    <div style="display: flex; align-items: center">
                                        <el-icon>
                                            <timer />
                                        </el-icon>
                                        <span style="margin-left: 10px">{{
                                            convertUTCTimeToLocalTime(scope.row.endTime)
                                        }}</span>
                                    </div>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作" :fixed="'right'">
                                <template #default="scope">
                                    <el-button size="small" :icon="EditPen" @click="getInfo(scope.row)">详情</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                            <el-pagination background @current-change="currentChange" @size-change="sizeChange"
                                layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <!-- 表单页面 -->
                <div>
                    <el-drawer :visible.sync="drawer" ref="drawer" :show-close="false" :before-close="handleClose">
                        <div slot="title" class="demo-drawer__header">
                            <span>填写表单</span>
                        </div>
                        <div class="demo-drawer__content">
                            <!-- 表单页面-->
                            <div v-if="flag">
                                <parser :form-conf="formContent" ref="parser"></parser>
                            </div>
                            <div class="DepartmentGroupViewUserList">
                                <div style="margin-left: -35px;font-size: 16px;">
                                    审批人员：<el-select v-model="assignee" placeholder="请选择审批人员" style="width: 375px;" clearable
                                        multiple>
                                        <el-option-group v-for="group in DepartmentGroupViewUserList"
                                            :key="group.institutionsId" :label="group.institutionsName">
                                            <el-option v-for="item in group.children" :key="item.userId"
                                                :label="item.realName" :value="item.realName">
                                            </el-option>
                                        </el-option-group>
                                    </el-select>
                                </div>
                            </div>
                        </div>
                        <div class="demo-drawer__footer">
                            <el-button @click="cancelForm">取 消</el-button>
                            <el-button type="primary" @click="$refs.drawer.closeDrawer()" :loading="loading">{{ loading ?
                                '提交中 ...' : '确 定' }}</el-button>
                        </div>
                    </el-drawer>
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import promeau from "../proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                deploymentCategory: [],
                pageNum: 1,
                pageSize: 10,
                processDefinitionName: "",
                taskName: "",
            }
        }
    },
    methods: {
        async  getInitiation() {
            const { data: res } = await this.$http2.post('/TaskAlreadyHandle/query/TaskAlreadyHandleTaskOptimizePageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
    },
    created() {
        this.getInitiation()
    },
    components: {
        promeau,
    },
    watch: {

    },
    mounted() {
    },
}

</script>

<style scoped lang="less">


.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .insertActBtCategory {
        width: 300px;
        margin-right: 8px;
    }

    .addActBtCategory {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .catleft {
            margin-top: 10px;
        }
    }

    #CategoryTree {
        width: 14%;
        border: 1px solid #E7E7E7;
        height: 96%
    }

    #pageList {
        width: 85%
    }

    .filter-tree {
        overflow: auto;
        flex: 1;
        width: 170px;
        height: 600px;
        margin: 10px;
    }

    .title-left {
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
        width: 140px;
        height: 22px;
        border-left: 5px solid #569ee3;
        text-align: left;
    }
}
</style>