<template>
    <div class="bg">
        <div id="kaoqinghome">
            <table data-v-3286e6af="" class="content">
                <tr data-v-3286e6af="">
                    <td data-v-3286e6af="" class="cor4" @click="promanage" >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">系统项目管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">维护平台所有项目信息</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4" @click="user" >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台用户管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">用来维护平台的管理员用户</span>
                            </div>
                        </div>
                    </td>
                  
                    <!-- <td data-v-3286e6af="" class="cor4" @click="departmentmanage"  v-has="'departmentmanage'">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台部门管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">维护平台所有管理员单位下的部门</span>
                            </div>
                        </div>
                    </td> -->
                    <td data-v-3286e6af="" class="cor4" @click="goRole" >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台角色管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">维护平台所有人员所属角色</span>
                            </div>
                        </div>
                    </td> 
                    <td data-v-3286e6af="" class="cor4" @click="goRgroup" >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台角色组管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">维护平台所有角色关联的角色组</span>
                            </div>
                        </div>
                    </td> 
                </tr>
                <tr data-v-3286e6af="">
                    <td data-v-3286e6af="" class="cor4" @click="unitmanage"  >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台单位管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">用来维护平台的统一单位</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4" @click="goRes"  >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台资源管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">用来维护平台的统一资源</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4" @click="goMenu" >
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">平台菜单管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">用来维护平台的统一菜单</span>
                            </div>
                        </div>
                    </td>
                    <!-- <td data-v-3286e6af="" class="cor4" @click='goOrg'>
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title"><span data-v-3286e6af="">项目组织管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">查看各个EPC项目的组织架构</span>
                            </div>
                        </div>
                    </td> -->
                    <!-- <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">发布平台公告</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">发布平台级别公告给各个EPC项目组</span>
                            </div>
                        </div>
                    </td> -->
                    <!-- <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">移动二维码管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">更换查看移动端二维码</span>
                            </div>
                        </div>
                    </td> -->
                    <td data-v-3286e6af="" class="cor4" @click="goFlow">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">流程管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">维护平台所有流程信息...</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</template>

<script>
    export default {
        name: "systemhome",
        methods:{
            kaoqing(){
                //this.$router.push({ path: "/kaoList" });
            },
            promanage(){
                this.$router.push({ path: "/promanage" });
            },
            unitmanage(){
                this.$router.push({ path: "/unitmanage" });
            },
            departmentmanage(){
                this.$router.push({ path: "/departmentmanage" });
            },
            user(){
                this.$router.push({ path: "/usermanage" });
            },
            goOrg(){
                this.$router.push({ path: "/orgmanage" });
            },
            goRole(){
                this.$router.push({ path: "/roleMan" });
            },
            goRes(){
                this.$router.push({ path: "/resMan" });
            },
            goMenu(){
                this.$router.push({ path: "/menuMan" });
            },
            goRgroup(){
                this.$router.push({ path: "/rgroupMan" });
            },
            goFlow(){
                this.$router.push({ path: "/modelmanage" });
            },
        }
    }
</script>

<style scoped lang="less">
    .bg{
        background-color: #f0f3fa;
        height: calc( 100vh -60px);
    }
    #kaoqinghome{
        background-color: white;
        position: absolute;
        left: 5px;
        top: 70px;
        bottom: 10px;
        right: 5px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .content[data-v-3286e6af] {
        width: 83.35%;
        margin: 0 auto;
        -webkit-box-shadow: 0 12px 24px -6px rgba(9, 30, 66, .25);
        box-shadow: 0 12px 24px -6px rgba(9, 30, 66, .25);
        border: none;
        border-collapse: collapse;
        margin-top: 10px;
    }
    .cor4 {
        background-image: url('../../assets/img/kaoqing/kq1.png'), url('../../assets/img/kaoqing/kq2.png'), url('../../assets/img/kaoqing/kq3.png'), url('../../assets/img/kaoqing/kq4.png') !important;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat !important;
        background-position: 0 0, 100% 0, 0 100%, 100% 100% !important;
        padding: 2px !important;
        border: 0 !important;
        background-color: transparent !important;
        cursor: pointer;
    }

    .content tr td[data-v-3286e6af] {
        width: 25%;
    }
    .content tr td .grid-content[data-v-3286e6af] {
        height: 168px;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        padding: 63px 5px;
        color: #000;
        background-color: #f0f3fa;
        text-align: center;
    }
    .content tr td .grid-content[data-v-3286e6af]:hover{
        background-color: #4B88E3;
    }
    .content tr td .grid-content .icon-btn[data-v-3286e6af] {
        height: 52px;
        margin-bottom: 20px;
    }
    .content tr td .grid-content .title[data-v-3286e6af] {
        font-weight: 700;
        margin-bottom: 16px;
        span{
            font-size: 20px;
        }

    }
    .content tr td .grid-content .slogan[data-v-3286e6af] {
        font-size: 16px;
        color: #000;
    }
</style>
