<template>
     <div class="bg" style="padding: 20px;">
        <!-- <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div> -->
        <div>
            <!-- <el-card class="box-card" > -->
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">新增会议资料</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :empty-text="loadInfo"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column prop="meetingName"  label="会议名称"  width="180" align="center">  
                        </el-table-column>
                        <el-table-column prop="meetingType"  label="会议类型"  align="center">  
                        </el-table-column>
                        <el-table-column prop="meetingTime"  label="会议时间" align="center">  
                        </el-table-column>
                        <el-table-column prop="initiator"  label="发起人" align="center">  
                        </el-table-column>
                        <el-table-column prop="files"  label="会议附件" align="center">  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.meetingFileInfo.filePath)" v-if="scope.row.meetingFile != null">  {{scope.row.meetingFileInfo.fileName}}</a>
                                <span v-else> 暂无附件</span>
                             </template>
                        </el-table-column>
                        <el-table-column prop="feedBack"  label="是否反馈"  width="100" align="center">  
                            <template v-slot="scope">
                                <el-tag v-if="scope.row.feedBack==1" type="success">是</el-tag>
                                <el-tag  v-else-if="scope.row.feedBack==0" type="danger">否</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="feedBackTime"  label="反馈时间"  align="center">  
                        </el-table-column>
                        <el-table-column prop="feedBackInfo"  label="反馈内容" align="center">  
                        </el-table-column>
                        <el-table-column prop="files"  label="反馈附件"  width="200" >  
                            <template v-slot="scope">
                                <a href="#" @click="yulan(scope.row.feedBackFileInfo.filePath)" v-if="scope.row.feedBackFile != null">  {{scope.row.feedBackFileInfo.fileName}}</a>
                                <span v-else> 暂无附件</span>
                             </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button v-if="scope.row.feedBack==0"  type="primary" @click="xiangqing_fk(scope.row)"  size="mini">反馈</el-button>
                                <el-button v-if="scope.row.feedBack==1"  type="info" disabled  size="mini">已反馈</el-button>
                                <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            <!-- </el-card> -->
        </div>
   
        <el-dialog :visible.sync="writeForm" title="新增项目会议资料" width="50%" >
            <el-form ref="addFormRef" :model="addForm" label-width="140px" :inline="true" >
                <el-form-item label="会议名称" prop="meetingName" >
                    <el-input  v-model="addForm.meetingName"></el-input>
                </el-form-item>
                <el-form-item label="发起人" prop="initiator">
                    <el-input  v-model="addForm.initiator"></el-input>
                </el-form-item>
                <el-form-item label="会议时间" prop="meetingTime">
                    <el-date-picker
                        v-model="addForm.meetingTime"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="会议类型" prop="meetingType">
                    <el-select multiple v-model="meetingType" @change="meetingTpyeChange" placeholder="请选择">
                        <el-option
                            v-for="item in meetingTypes"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                               </el-upload>
                     <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>

        <el-dialog :visible.sync="writeForm4" title="新增会议反馈信息" width="50%" >
            <el-form ref="addFormRef" :model="addForm1" label-width="140px" :inline="true" >
                <el-form-item label="反馈时间" prop="feedBackTime">
                    <el-date-picker
                        v-model="addForm1.feedBackTime"
                        value-format="yyyy-MM-dd HH:mm:ss" 
                        align="right"
                        type="date"
                        placeholder="选择日期"
                        :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="反馈内容" prop="feedBackInfo">
                    <el-input  v-model="addForm1.feedBackInfo"></el-input>
                </el-form-item>
                <el-form-item label="反馈附件" prop="tenant">
                    <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechangeFk"
                                  :on-remove="handleRemoveFk"
                                  :file-list="fileListFk"
                                  :auto-upload="false">
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                              </el-upload>
                     <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                </el-form-item>
            </el-form>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm4 = false">关 闭</el-button>
                                  <el-button @click="uploadModelFk" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...</div>
        </div>
    </div>
    
</template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                proshow:false,
                loadInfo:"数据加载中...",
                tableData:[],
                meetingType:[],
                meetingTypes: [{
                    value: '进度会议',
                    label: '进度会议'
                }, {
                    value: '质量会议',
                    label: '质量会议'
                }, {
                    value: '安全会议',
                    label: '安全会议'
                }, {
                    value: '其他会议',
                    label: '其他会议'
                }],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                writeForm4:false,
                fileList:[],
                fileListFk:[],
                menuId:window.sessionStorage.getItem('menuId'),
                editform:{

                },
                loadbut:false,
                loadbutext:"保 存",
                progress: 0,
                percentage:0,
                addForm:{
                    projectId:this.$root.proId,
                    meetingTime:null,
                    initiator:'',
                    meetingName:'',
                    meetingType:'',
                    meetingFile:'',
                    isFeedBack:1
                },
                addForm1:{
                    id: "",
                    feedBackTime: null,
                    feedBackInfo: "",
                    feedBackFile:"",
                    feedBack:1
                },
                pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                },
                shortcuts: [{
                    text: '今天',
                    onClick(picker) {
                    picker.$emit('pick', new Date());
                    }
                }, {
                    text: '昨天',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24);
                    picker.$emit('pick', date);
                    }
                }, {
                    text: '一周前',
                    onClick(picker) {
                    const date = new Date();
                    date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                    picker.$emit('pick', date);
                    }
                }]
                },
                editrow:{}
            }
        },
        created(){
            // this.getLeftMenu()
            this.getProApp()
            const load = this.$loading({
            lock:true,
            text:'Loading',
            spinner:'el-icon-loading',
            background:'rgba(0,0,0,0.7)'
            });
            load.close();
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http1.get('v1.0/project/meeting/findAllByProjectId?projectId='+this.$root.proId)
              this.tableData=res.data
              this.loadInfo = ''
            },
            // async getLeftMenu(){
            //     const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=2409262CZG9R130H')
            //     this.menuTable=[]
            //     for(let i in res.data){
            //       if(res.data[i].belongOrg==1){
            //         this.menuTable.push(res.data[i])
            //       }
            //     }
            // },
            meetingTpyeChange(){
                this.addForm.meetingType = this.meetingType.toString();
            },
            initFromData(){
                this.addForm.projectId=this.$root.proId,
                this.addForm.projectIdmeetingTime=null,
                this.addForm.projectIdinitiator='',
                this.addForm.projectIdmeetingName='',
                this.addForm.projectIdmeetingType='',
                this.addForm.projectIdmeetingFile='',
                this.addForm.projectIdisFeedBack=1
            },
            initFKFromData(){
                this.addForm1.id= "",
                this.addForm1.feedBackTime= null,
                this.addForm1.feedBackInfo= "",
                this.addForm1.feedBackFile="",
                this.addForm1.feedBack=1
            },
            xiangqing_fk(row){
                console.log(row)
                this.writeForm4=true
                this.addForm1.id = row.id
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
                this.fileList=List
            },
            handleRemove(file, List) {
                this.fileList=List
              },
            filechangeFk(file,List){
                this.fileListFk=List
            },
            handleRemoveFk(file, List) {
                this.fileListFk=List
            },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                var data = {"ids":[row.id]}
                const {data:res}= await this.$http1.post('v1.0/project/meeting/delete',data)
                if(res.code!= 200){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
            async yulan(path){
                console.log(path)
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async deleteModel(){

            },
            async downFile(row){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:row.files[0].filePath },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = row.files[0].fileName;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(row){
                this.fileList=[]
                this.writeForm=true
                this.editrow=row
            },
            beforeuploadModel_fk(){
                this.fileListFk=[]
                this.writeForm4=true
            },
            isPDF(file) {
            return file.name.toLowerCase().endsWith('.pdf');
        },
           async uploadModel(){
           
            var formData = new FormData();
            for(let i in this.fileList){
                if(this.isPDF(this.fileList[i])){
                    formData.append("files", this.fileList[i].raw);
                 }else{
                    this.$message.error("文件格式为PDF");
                    return
                 }
            }
            this.loadbut = true
            this.loadbutext = "提交中..."
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(this.percentage)
                    }
                    }
                };
            if(this.fileList!=[]){
                const { data: res2 } = await this.$http1.post('v1.0/project/meeting/upload/file', formData,config)
                if(res2.businessCode==1000){
                this.addForm.meetingFile=[]
                this.addForm.meetingFile = res2.data
            }
            const { data: res } = await this.$http1.post('v1.0/project/meeting/save', this.addForm)
            if(res.code==200){
                this.$message.success("提交成功");
                this.loadbut = false
                this.loadbutext = "保 存"
                this.getProApp()
                this.percentage = 0
                this.writeForm = false;
                this.initFromData();
            }else{
              this.loadbut = false
              this.$message.error("提交失败！");
            }
           }
          },
          async uploadModelFk(){
            var formData = new FormData();
            for(let i in this.fileListFk){
                if(this.isPDF(this.fileListFk[i])){
                    formData.append("files", this.fileListFk[i].raw);
                 }else{
                    this.$message.error("文件格式为PDF");
                    return
                 }
            }
            this.loadbut = true
            this.loadbutext = "提交中..."
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        // console.log(this.percentage)
                    }
                    }
                };
            if(this.fileListFk!=[]){
                const { data: res2 } = await this.$http1.post('v1.0/project/meeting/upload/file', formData,config)
                if(res2.businessCode==1000){
                this.addForm1.feedBackFile=[]
                this.addForm1.feedBackFile = res2.data
            }
            const { data: res } = await this.$http1.post('v1.0/project/meeting/feedBack/save', this.addForm1)
            if(res.code==200){
                this.$message.success("提交成功");
                this.getProApp()
                this.loadbut = false
                this.loadbutext = "保 存"
                this.percentage = 0
                this.writeForm4 = false;
                this.initFKFromData();
            }else{
              this.loadbut = false
              this.$message.error("提交失败！");
            }
           }
          }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                // this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 88%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(99% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 90.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
.btnheader{
    display: flex;
    .tag{
        height: 30px;
        margin-left:20px;
        margin-top: 8px;
    }
}
  </style>
  