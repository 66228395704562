import Vue from 'vue'
import VueRouter from 'vue-router'
import homepage from '../components/homepage.vue'
import homepage_new from '../components/homepage_new.vue'
import login from '../components/login_new.vue'
import prohome from '../components/prohome_new.vue'
import yonghu from '../components/htgl/yhList'
import juese from '../components/htgl/jueseList'
import danwei from '../components/htgl/danweiList'
import bumen from '../components/htgl/buMenList'
import ziyuan from '../components/htgl/quanxianList'
import rizhi from '../components/htgl/riZhiList'
import allliucheng from '../components/htgl/allliucheng'
import createForm from '../components/htgl/formsheji'
import drawLc from '../components/htgl/drawliuCheng'
import proinfo from '../components/proInfo/proInfo'
import profile from '../components/proInfo/profile'
import proarea from '../components/proInfo/proarea'
import prodashiji from '../components/proInfo/prodashiji'
import proshigong from '../components/proInfo/proshigong'
import process from '../components/process/progantee'
import licheng from '../components/process/lichengbie'
import planprocess from '../components/process/planproces'
import quality from '../components/proquality/quality'
import cost from '../components/cost/chengben'
import hetong from '../components/hetong/hetong'
import kaoqing from '../components/kaoqing/kaoqinghome'
import kaolist from '../components/kaoqing/kaoList'
import kaomanage from '../components/kaoqing/kaomanage'
import mykaoqing from '../components/kaoqing/mykaoqing'
import teamkaoqing from '../components/kaoqing/teamkaoqing'
import system from  '../components/system/systemhome'
import promanage from  '../components/system/proManage'
import notice from  '../components/work/notice'
import document from  '../components/work/document'
import liucheng from  '../components/work/liuchengList'
import daiban from  '../components/work/daiban'
import myLiucheng from  '../components/work/myLiucheng'
import grouptodo from  '../components/work/groupdaiban'
import join from  '../components/work/jojnLiucheng'
import monitor from  '../components/monitor/monitor'
import monitorperception from  '../components/monitor/monitorPerception'
import monitorinteractive from  '../components/monitor/monitorInteractive'
import equipment from  '../components/htgl/yingshi'
import Home from '../components/Home'
import commonleft from '../components/commonleft'
Vue.use(VueRouter)


/*const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: {title:'EPC项目管理平台-登陆页面'}
  },
  {
    path: '/login',
    name: 'login',
    meta: {title:'EPC项目管理平台-登陆页面'},
    component: login,
  },
  {
    path: '/home',
    name: 'homepage_new',
    component: homepage_new,
    meta: {title:'EPC项目管理平台-项目总览'}
  },
  {
    path: '/system',
    name: 'system',
    component: system,
    meta: {title:'EPC项目管理平台-平台管理'}
  },
  {
    path: '/promanage',
    name: 'promanage',
    component: promanage,
    meta: {title:'EPC项目管理平台-平台项目管理'}
  },
  {
    path:'/project',
    redirect: '/prohome',
    component: Home,
    children: [
      {
        path: '/prohome',
        components: {
          'left': commonleft,
          'main': prohome,
        },
        meta: {title:'EPC项目管理平台-项目情况'}
      },
    ],
  },
  {
    path:'/projectInfo',
    redirect: '/proInfo',
    component: Home,
    children: [
      {
        path: '/proInfo',
        name: 'proinfo',
        components: {
          'left': commonleft,
          'main': proinfo,
        },
        meta: {title:'EPC项目管理平台-项目详情'}
      },
      {
        path: '/proFile',
        name: 'profile',
        components: {
          'left': commonleft,
          'main': profile,
        },
        meta: {title:'EPC项目管理平台-项目资料'}
      },
      {
        path: '/proArea',
        name: 'proarea',
        components: {
          'left': commonleft,
          'main': proarea,
        },
        meta: {title:'EPC项目管理平台-项目区域'}
      },
      {
        path: '/proEvent',
        name: 'prodashiji',
        components: {
          'left': commonleft,
          'main': prodashiji,
        },
        meta: {title:'EPC项目管理平台-项目大事记'}
      },
      {
        path: '/proDiary',
        name: 'proshigong',
        components: {
          'left': commonleft,
          'main': proshigong,
        },
        meta: {title:'EPC项目管理平台-项目施工日志'}
      },
    ],
  },
  {
    path:'/work',
    redirect: '/toDo',
    component: Home,
    children: [
      {
        path: '/toDo',
        name: 'daiban',
        components: {
          'left': commonleft,
          'main': daiban,
        },
        meta: {title:'EPC项目管理平台-个人待办'}
      },
      {
        path: '/groupTodo',
        name: 'groupTodo',
        components: {
          'left': commonleft,
          'main': grouptodo,
        },
        meta: {title:'EPC项目管理平台-组待办流程'}
      },
      {
        path: '/myFlow',
        name: 'myLiucheng',
        components: {
          'left': commonleft,
          'main': myLiucheng,
        },
        meta: {title:'EPC项目管理平台-个人发起的流程'}
      },
      {
        path: '/joinFlow',
        name: 'join',
        components: {
          'left': commonleft,
          'main': join,
        },
        meta: {title:'EPC项目管理平台-个人参与的流程'}
      },
      {
        path: '/initFlow',
        name: 'liucheng',
        components: {
          'left': commonleft,
          'main': liucheng,
        },
        meta: {title:'EPC项目管理平台-发起流程'}
      },
      {
        path: '/notice',
        name: 'notice',
        components: {
          'left': commonleft,
          'main': notice,
        },
        meta: {title:'EPC项目管理平台-通知公告'}
      },
      {
        path: '/document',
        name: 'document',
        components: {
          'left': commonleft,
          'main': document,
        },
        meta: {title:'EPC项目管理平台-收发文'}
      },
    ],
  },
  {
    path:'/proprocess',
    redirect: '/planprocess',
    component: Home,
    children: [
      {
        path: '/planprocess',
        name: 'planprocess',
        components: {
          'left': commonleft,
          'main': planprocess,
        },
        meta: {title:'EPC项目管理平台-进度计划'}
      },
      {
        path: '/process',
        name: 'process',
        components: {
          'left': commonleft,
          'main': process,
        },
        meta: {title:'EPC项目管理平台-项目进度'}
      },
      {
        path: '/milepost',
        name: 'licheng',
        components: {
          'left': commonleft,
          'main': licheng,
        },
        meta: {title:'EPC项目管理平台-里程碑'}
      },
    ],
  },
  {
    path: '/proquality',
    redirect: '/quality',
    component: Home,
    children: [
      {
        name: 'quality',
        path: '/quality',
        components: {
          'left': commonleft,
          'main': quality,
        },
        meta: {title:'EPC项目管理平台-质量管理'}
      },
    ],
  },
  {
    path: '/procontract',
    redirect: '/contract',
    component: Home,
    children: [
      {
        path: '/contract',
        name: 'hetong',
        components: {
          'left': commonleft,
          'main': hetong,
        },
        meta: {title:'EPC项目管理平台-合同管理'}
      },
    ],
  },
  {
    path: '/procost',
    redirect: '/cost',
    component: Home,
    children: [
      {
        path: '/cost',
        name: 'cost',
        components: {
          'left': commonleft,
          'main': cost,
        },
        meta: {title:'EPC项目管理平台-费用管理'}
      },
    ],
  },
  {
    path: '/promonitor',
    component: Home,
    redirect: '/monitor',
    children: [
      {
        path: '/monitor',
        name: 'monitor',
        components: {
          'left': commonleft,
          'main': monitor,
        },
        meta: {title:'EPC项目管理平台-视频监控'}
      },
    ],
  },
  {
    path: '/proAttendance',
    component: Home,
    redirect: '/attendanceList',
    children: [
      {
        path: '/attendanceList',
        name: 'kaolist',
        components: {
          'left': commonleft,
          'main': kaolist,
        },
        meta: {title:'EPC项目管理平台-考勤列表'}
      },
      {
        path: '/attendanceManage',
        name: 'kaomanage',
        components: {
          'left': commonleft,
          'main': kaomanage,
        },
        meta: {title:'EPC项目管理平台-考勤配置'}
      },
      {
        path: '/myAttendance',
        name: 'mykaoqing',
        components: {
          'left': commonleft,
          'main': mykaoqing,
        },
        meta: {title:'EPC项目管理平台-我的考勤'}
      },
      {
        path: '/teamAttendance',
        name: 'teamAttend',
        components: {
          'left': commonleft,
          'main': teamkaoqing,
        },
        meta: {title:'EPC项目管理平台-团队考勤'}
      },
    ],
  },
  {
    path: '/proBackManage',
    component: Home,
    redirect: '/userList',
    children: [
      {
        path: '/userList',
        name: 'yonghu',
        components: {
          'left': commonleft,
          'main': yonghu,
        },
        meta: {title:'EPC项目管理平台-人员管理'}
      },
      {
        path: '/roleList',
        name: 'juese',
        components: {
          'left': commonleft,
          'main': juese,
        },
        meta: {title:'EPC项目管理平台-角色管理'}
      },
      {
        path: '/companyList',
        name: 'danwei',
        components: {
          'left': commonleft,
          'main': danwei,
        },
        meta: {title:'EPC项目管理平台-单位管理'}
      },
      {
        path: '/departmentList',
        name: 'bumen',
        components: {
          'left': commonleft,
          'main': bumen,
        },
        meta: {title:'EPC项目管理平台-部门管理'}
      },
      {
        path: '/resourcesList',
        name: 'ziyuan',
        components: {
          'left': commonleft,
          'main': ziyuan,
        },
        meta: {title:'EPC项目管理平台-资源管理'}
      },
      {
        path: '/journalList',
        name: 'rizhi',
        components: {
          'left': commonleft,
          'main': rizhi,
        },
        meta: {title:'EPC项目管理平台-日志管理'}
      },

      {
        path: '/activiti',
        name: 'activiti',
        components: {
          'left': commonleft,
          'main': allliucheng,
        },
        meta: {title:'EPC项目管理平台-流程管理'}
      },
      {
        path: '/equipment',
        name: 'equipment',
        components: {
          'left': commonleft,
          'main': equipment,
        },
        meta: {title:'EPC项目管理平台-设备管理'}
      },
    ],
  },
  {
    path: '/createForm',
    name: 'createForm',
    component: createForm,
    meta: {title:'EPC项目管理平台-流程表单设计'}
  },
  {
    path: '/drawLc',
    name: 'drawLc',
    component: drawLc,
    meta: {title:'EPC项目管理平台-流程绘制'}
  },
]*/

const routes = [
  {
    path: '/',
    redirect: '/login',
    meta: {title:'EPC项目管理云平台-登陆页面'}
  },
  {
    path: '/login',
    component: () => import('@/components/login_new'),
    meta: {title:'EPC项目管理云平台-登陆页面'}
  },
  {
    path: '/largescreen',
    component: () => import('@/components/largescreen'),
    meta: {title:'EPC项目管理云平台-大屏展示'}
  },
  {
    path: '/largepro',
    component: () => import('@/components/largescreenpro'),
    meta: {title:'EPC项目管理云平台-项目展示'}
  },
  {
    path: '/roleMan',
    component: () => import('@/components/system/rolemanage'),
    meta: {title:'EPC项目管理云平台-角色管理'}
  },
  {
    path: '/resMan',
    component: () => import('@/components/system/resmanage'),
    meta: {title:'EPC项目管理云平台-资源管理'}
  },
  {
    path: '/menuMan',
    component: () => import('@/components/system/menumanage'),
    meta: {title:'EPC项目管理云平台-菜单管理'}
  },
  {
    path: '/rgroupMan',
    component: () => import('@/components/system/rgroupmanage'),
    meta: {title:'EPC项目管理云平台-角色组管理'}
  },
  {
    path: '/modelmanage',
    component: () => import('@/components/system/flow/modelmanage'),
    meta: {title:'EPC项目管理云平台-模型管理'}
  },
  {
    path: '/modeltype',
    name: 'modeltype',
    meta: {title:'EPC项目管理云平台-模型类别'},
    component: () => import('@/components/system/flow/modeltype'),
  },
  {
    path: '/deploymanage',
    name: 'deploymanage',
    meta: {title:'EPC项目管理云平台-部署管理'},
    component: () => import('@/components/system/flow/deploymanage'),
  },
  {
    path: '/processDefinition',
    name: 'processDefinition',
    meta: {title:'EPC项目管理云平台-流程定义'},
    component: () => import('@/components/system/flow/processDefinition'),
  },
  {
    path: '/processExample',
    name: 'processExample',
    meta: {title:'EPC项目管理云平台-流程实例'},
    component: () => import('@/components/system/flow/processExample'),
  },
  {
    path: '/processTask',
    name: 'processTask',
    meta: {title:'EPC项目管理云平台-流程任务'},
    component: () => import('@/components/system/flow/processTask'),
  },
  {
    path: '/historyProcess',
    name: 'historyProcess',
    meta: {title:'EPC项目管理云平台-历史流程'},
    component: () => import('@/components/system/flow/historyProcess'),
  },
  {
    path: '/historyTask',
    name: 'historyTask',
    meta: {title:'EPC项目管理云平台-历史任务'},
    component: () => import('@/components/system/flow/historyTask'),
  },
  {
    path: '/initiationProcess',
    name: 'initiationProcess',
    meta: {title:'EPC项目管理云平台-发起流程'},
    component: () => import('@/components/system/flow/work/initiationProcess'),
  },
  {
    path: '/myProcess',
    name: 'myProcess',
    meta: {title:'EPC项目管理云平台-我的流程'},
    component: () => import('@/components/system/flow/work/myProcess'),
  },
  {
    path: '/todoProcess',
    name: 'todoProcess',
    meta: {title:'EPC项目管理云平台-待办流程'},
    component: () => import('@/components/system/flow/work/todoProcess'),
  },
  {
    path: '/doneProcess',
    name: 'doneProcess',
    meta: {title:'EPC项目管理云平台-已办流程'},
    component: () => import('@/components/system/flow/work/doneProcess'),
  },
  {
    path: '/formHome',
    component: () => import('@/components/system/flow/form/formHome'),
    meta: {title:'EPC项目管理云平台-表单管理'},
  },
  {
    path: '/formCreate',
    name: 'formCreate',
    meta: {title:'EPC项目管理云平台-表单设计'},
    component: () => import('@/components/system/flow/form/formCreate'),
  },
  {
    path: '/formField',
    name: 'formField',
    meta: {title:'EPC项目管理云平台-业务表设计'},
    component: () => import('@/components/system/flow/form/formField'),
  },
  {
    path: '/drawPro',
    name: 'drawPro',
    meta: {title:'EPC项目管理云平台-绘制流程图'},
    component: () => import('@/components/system/flow/draw'),
  },
  {
    path: '/wordCreate',
    meta: {title:'EPC项目管理云平台-绘制报表图'},
    component: () => import('@/components/system/flow/word/wordCreate'),
  },
  {
    path: '/wordList',
    meta: {title:'EPC项目管理云平台-报表模板展示'},
    component: () => import('@/components/system/flow/word/wordList'),
  },
  {
    path: '/wordField',
    name: 'wordField',
    meta: {title:'EPC项目管理云平台-业务表设计'},
    component: () => import('@/components/system/flow/word/wordField'),
  },
  {
    path: '/wordDictionary',
    name: 'wordDictionary',
    meta: {title:'EPC项目管理云平台-业务表字典'},
    component: () => import('@/components/system/flow/word/wordDictionary'),
  },
  // {
  //   path: '/monitor',
  //   name: 'monitor',
  //   meta: {title:'EPC项目管理云平台-物联感知'},
  //   component: () => import('@/components/monitor/monitor'),
  // },
  {
    path: '/monitorInteractive',
    name: 'monitorInteractive',
    meta: {title:'EPC项目管理云平台-交互类设备'},
    component: () => import('@/components/monitor/monitorInteractive'),
  },
  {
    path: '/monitorPerception',
    name: 'monitorPerception',
    meta: {title:'EPC项目管理云平台-感知类设备'},
    component: () => import('@/components/monitor/monitorPerception'),
  },

]

const router = new VueRouter({
  routes
})
const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error=> error)
}
var arr = []
router.beforeEach((to, from, next) => {
  if (sessionStorage.token) {
    if (sessionStorage.menu) {
      if(arr.length==0){
        var me=JSON.parse(sessionStorage.menu)
        me.forEach((item,index) => {
          if (item.children.length!==0) {
            let newArr = {
              path: item.parent.path,
              name: item.parent.name,
              component:  require('@/components/'+item.parent.component).default,
              meta: {title:item.parent.name},
              /*   redirect:item.parent.redirectPath,*/
              redirect:item.children[0].parent.path,
              children: []
            };
            /* if(index==0){
               window.sessionStorage.setItem('activePath',item.parent.path)
             }*/
            arr.push(newArr);
            item.children.forEach(child => {
              if (me[index].children) {
                me[index].children.find(userItem => {
                  if(userItem.parent.enabled==1){
                  let newArr2 = {
                    path: userItem.parent.path,
                    name: userItem.parent.name,
                    components:userItem.parent.component.split('||').length==2?{'main':require('@/components/'+userItem.parent.component.split('||')[1]).default,'left':require('@/components/'+userItem.parent.component.split('||')[0]).default}:{'main':require('@/components/'+userItem.parent.component.split('||')[0]).default},
                    meta: {title:userItem.parent.meta.title},
                  };
                  userItem.parent.path == child.parent.path ? arr[arr.length ? arr.length - 1 : arr.length].children.push(newArr2) : arr = arr;
                  }
                })
              }
            })
          }
        })
        arr.push(  {
          path: "*",
          redirect: "/login"
        })
        router.options.routes = router.options.routes.concat(arr)
        console.log(arr)
        router.addRoutes(arr)
        next({
          ...to,
          replace: true
        })
      }else {
        next();
      }
    }else {
      next({
        ...to,
        replace: true
      })
    }
  }else {
    if(to.path !== '/login'){
      next({ path: '/login' })
      next()
    }else {
      next()
    }
  }
  if (to.path === '/login') return next()
  const tokenStr = window.sessionStorage.getItem('token')
  if (!tokenStr) return  next({ path: '/login' })
  next()
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()

})
export default router
