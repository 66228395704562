<template>
    <div class="bg">
        <pro-left></pro-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                    <el-button   @click="dialogVisible = true" v-has="'addevent'">新增大事记</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="title"
                                label="大事记标题"
                                width="250"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="content"
                                label="大事记内容"
                                width="650"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="nodeDate"
                                label="大事记日期"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createUserName"
                                label="创建人"
                                align="center"
                                width="80"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini" v-has="'editevent'">修改</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delevent'">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增大事记"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="editFormRules" >
                <el-form-item label="标题" prop="title">
                    <el-input v-model="addForm.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input   type="textarea" :rows="3" v-model="addForm.content"></el-input>
                </el-form-item>
                <el-form-item label="节点日期" prop="nodeDate">
                    <el-date-picker
                            v-model="addForm.nodeDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择节点日期">
                    </el-date-picker>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addproEvent" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改大事记"
                :visible.sync="editdialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="100px" :rules="editFormRules">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="editForm.title"></el-input>
                </el-form-item>
                <el-form-item label="内容" prop="content">
                    <el-input   type="textarea" :rows="3" v-model="editForm.content"></el-input>
                </el-form-item>
                <el-form-item label="节点日期" prop="nodeDate">
                    <el-date-picker
                            v-model="editForm.nodeDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择节点日期">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditProEvent">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import proLeft from './proInfoLeft'
    export default {
        name: "prodashiji",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                dialogVisible:false,
                editdialogVisible:false,
                addForm:{
                    content :'',
                    title :'',
                    nodeDate :'',
                    projectId:this.$root.proId,
                },
                editForm:{},
                editFormRules:{
                    title:[{required:true,message:'请输入大事记标题',trigger:'blur'}],
                    content:[{required:true,message:'请输入大事记内容',trigger:'blur'}],
                    nodeDate:[{required:true,message:'请输入大事记日期',trigger:'blur'}],
                },
            }
        },
        created(){
            this.getProEvent()
        },
        methods:{
            async getProEvent(){
                const {data:res}= await this.$http1.get('/v1.0/project/memorabilia/findAll?projectId='+this.$root.proId)
                if(res.businessCode!='3000'){
                    return this.$message.error('查询项目大事记失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async addproEvent(){
                const {data:res}= await this.$http1.post('/v1.0/project/memorabilia/save',this.addForm)
                if(res.businessCode!='6000'){
                    return this.$message.error('添加项目大事记失败！'+res.message)
                }
                this.$message.success('添加项目大事记成功！');
                this.dialogVisible=false;
                this.getProEvent();
            },
            async editDialog(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/memorabilia/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return 0;
                }
                this.editForm=res.data;
                this.editdialogVisible=true
            },
            async iseditProEvent(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    const {data:res}=await  this.$http1.put('/v1.0/project/memorabilia/update',this.editForm);
                    if(res.businessCode!=="6010"){
                        return this.$message.error('修改项目大事记失败！'+res.message)
                    }
                    this.$message.success('修改项目大事记成功！');
                    this.getProEvent();
                    this.editdialogVisible = false
                })
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目大事记, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/memorabilia/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除项目大事记失败！'+res.message)
                }
                this.$message.success('删除项目大事记成功！');
                this.getProEvent();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields();
            }
        },
        components:{
            proLeft
        }
    }
</script>

<style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }

</style>
