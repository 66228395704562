<template>
  <div class="bg">
    <hou-left></hou-left>
  <div class="mainpart">
    <el-card class="box-card" style="height: 100%">
      <div slot="header" class="clearfix" style="text-align: right">
       <!-- <el-button type="primary" @click="dialogVisible = true" style="">创建资源</el-button>-->
      </div>
      <div class="serverTable">
      <el-table
        :data="tableData"
        height="100%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        style="width: 100%;background-color: #142245">

        <el-table-column
                type="index"
                label="序号"
                width="50"
        >
          <template v-slot="scope">
            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="资源名"
          min-width="15%"
        >
        </el-table-column>
        <el-table-column
          prop="path"
          label="资源路径"
          min-width="10%"
        >
        </el-table-column>
        <el-table-column
          prop="url"
          label="资源接口"
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          prop="menuName"
          label="菜单名称"
          min-width="15%"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="15%"
        >
          <template v-slot="scope">
            <el-tooltip effect="dark" content="修改资源" placement="top" :enterable="false" v-has="'updateziyuan'">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除资源" placement="top" :enterable="false" v-has="'deleteziyuan'">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryInfo.pageNumber"
          :page-sizes="[5,10, 20, 50]"
          :page-size="queryInfo.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="添加资源"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addFormRule">
        <el-form-item label="资源名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="资源路径" prop="path">
          <el-input v-model="addForm.path"></el-input>
        </el-form-item>
        <el-form-item label="资源接口" prop="url">
          <el-input v-model="addForm.url"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
     <el-button type="goon" size="medium" round @click="dialogVisible = false">取 消</el-button>
    <el-button type="goon" size="medium" round @click="addZiYuan" >确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="修改资源信息"
      :visible.sync="editdialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="editdialogClose"
    >
      <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="editFormRules">
        <el-form-item label="资源名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="资源路径" prop="path">
          <el-input v-model="editForm.path"></el-input>
        </el-form-item>
        <el-form-item label="资源接口" prop="url">
          <el-input v-model="editForm.url"></el-input>
        </el-form-item>
        <el-form-item label="菜单名称" prop="menuName">
          <el-input v-model="editForm.menuName"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button size="medium"  type="goon" round @click="editdialogVisible = false">取 消</el-button>
    <el-button size="medium"  type="goon" round @click="editZiYuanInfo">确 定</el-button>
  </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
  import houLeft from "./houTaiLeft";
  export default {
    name: 'quanxianList',
    data(){
      return{
        tableData:[],
        dialogVisible:false,
        queryInfo:{
          name:'',
          pageNumber:1,
          pageSize:10,
        },
        total:10,
        addForm:{
          name:'',
          path:'',
          url:''
        },
        addFormRule:{
          name:[{required:true,message:'资源名',trigger:'blur'}],
        },
        editdialogVisible:false,
        editForm:{
        },
        editFormRules:{
          name:[{required:true,message:'资源名',trigger:'blur'}],
        },
      }
    },
    created(){
      this.getZiYuanList();
    },
    methods:{
      async getZiYuanList(){
        const {data:res}=await  this.$http.get('/v1.0/menu/findPages?pageNumber='+this.queryInfo.pageNumber+"&pageSize="+this.queryInfo.pageSize+"&name="+this.queryInfo.name)
        if(res.status!=="3000"){
          return this.$message.error('获取资源列表失败！'+res.message)
        }
        this.tableData=res.data.result;
        this.total=res.data.totalCount;
      },
      async addZiYuan(){
        this.$refs.addFormRef.validate( async valid =>{
          if(!valid)
            return
          const {data:res}= await this.$http.post('/v1.0/menu/save', this.addForm)
          if(res.status!=="6000"){
            this.$message.error('添加资源失败！')
          }
          this.$message.success('添加资源成功！');
          this.getZiYuanList();
          this.dialogVisible = false;
        })

      },
      dialogClose(){
        this.$refs.addFormRef.resetFields();
      },
      async editDialog(id){
        //请求查询
        const {data:res}=await  this.$http.get('/v1.0/menu/findById/'+id);
        if(res.status!=="3000"){
          return 0;
        }
        this.editForm=res.data;
        this.editdialogVisible = true
      },
      editdialogClose(){
        this.$refs.editFormRef.resetFields()
      },
      editZiYuanInfo(){
        this.$refs.editFormRef.validate(async valid =>{
          if(!valid) return
          const {data:res}= await this.$http.post('/v1.0/menu/update', this.editForm)
          if(res.status!=="6000"){
            this.$message.error('修改资源失败！')
          }
          this.$message.success('修改资源成功！');
          this.editdialogVisible = false;
          this.getZiYuanList();
        })
      },
      async deleteMsg(id){
        const confirmResult = await this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(confirmResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
        //请求
        const {data:res}= await this.$http.delete('/v1.0/menu/delete/'+id)
        if(res.status!="4000"){
          this.$message.error('删除资源失败！')
        }
        this.$message.success('删除资源成功！');
        this.getJueseList();
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      handleSizeChange(newSize){
        this.queryInfo.pageSize=newSize;
        this.getZiYuanList()
      },
      handleCurrentChange(newPage){
        this.queryInfo.pageNumber=newPage;
        this.getZiYuanList()
      },
    },
    components:{
      houLeft
    }
  }
</script>

<style scoped lang="less">

</style>
