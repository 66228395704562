<template>
    <div id="asiderBar">
        <el-menu
                class="el-menu-vertical-demo"
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >

            <el-menu-item v-for="item in qita" :index="item.path" :key="item.path">
                <i :class="item.iconCls"></i>
                <span class="titlelable">{{item.name}}</span>
            </el-menu-item>
            <el-submenu index="/meeting" v-if="this.meet.length>0">
                <template slot="title">
                    <i class="el-icon-chat-line-round"></i>
                    <span>会议管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item  v-for="item in this.meet" :index="item.path" :key="item.path">
                        <i :class="item.iconCls"></i>
                        <span>{{item.name}}</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/training" v-if="this.train.length>0">
                <template slot="title">
                    <i class="el-icon-notebook-1"></i>
                    <span>培训管理</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item  v-for="item in this.train" :index="item.path" :key="item.path">
                        <i :class="item.iconCls"></i>
                        <span>{{item.name}}</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-submenu index="/trainPlan" v-if="this.train.length>0">
                <template slot="title">
                    <i class="el-icon-money"></i>
                    <span>安全投入</span>
                </template>
                <el-menu-item-group>
                    <el-menu-item  v-for="item in this.product" :index="item.path" :key="item.path">
                        <i :class="item.iconCls"></i>
                        <span>{{item.name}}</span>
                    </el-menu-item>
                </el-menu-item-group>
            </el-submenu>
            <el-menu-item v-for="item in yanlian" :index="item.path" :key="item.path">
                <i :class="item.iconCls"></i>
                <span class="titlelable">{{item.name}}</span>
            </el-menu-item>
        </el-menu>

    </div>
</template>

<script>
    export default {
        name: 'securityLeft',
        data(){
            return {
                isZhank: false,
                qita: [],
                menuLevel2:[],
                meet:[],
                train:[],
                product:[],
                yanlian:[]
            }
        },
        created () {
            this.menuLevel2=JSON.parse(window.sessionStorage.getItem('menulevel2'))[5];
            for(let i in this.menuLevel2){
                if(this.menuLevel2[i].name=='项目会议'|| this.menuLevel2[i].name=='会议计划'|| this.menuLevel2[i].name=='月度会议计划'){
                    this.meet.push(this.menuLevel2[i])
                }else if(this.menuLevel2[i].name=='项目培训'|| this.menuLevel2[i].name=='培训计划'|| this.menuLevel2[i].name=='月度培训计划'){
                    this.train.push(this.menuLevel2[i])
                }else if(this.menuLevel2[i].name=='产品预算'|| this.menuLevel2[i].name=='产品采购'|| this.menuLevel2[i].name=='产品登记'){
                    this.product.push(this.menuLevel2[i])
                }else if(this.menuLevel2[i].name=='安全演练'){
                    this.yanlian.push(this.menuLevel2[i])
                }else {
                    this.qita.push(this.menuLevel2[i])
                }
            }
            console.log(this.menuLevel2)
        },
        methods: {
            toggleZhankai () {
                this.isZhank = !this.isZhank
            },

        },
    }
</script>

<style lang="less" scoped>


    .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    /deep/ .el-badge__content.is-fixed{
        top:12px;
        right: 1px;
    }
    /deep/ .el-menu-item-group__title{
        padding: 0 !important;
    }
    .titlelable{
        font-size: 18px;
        font-weight: 700;
    }
</style>
