<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入年度查询" v-model="queryInfo.annualTime" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="15">
                            <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">添加年度预算</el-button>

                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            @expand-change="handledetail"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table
                                        :data="tableData2[props.row.afetyProductionBudgetId]"
                                        border
                                        :key="keybolen"
                                        class="monthTable"
                                        :header-cell-style="{color:'black'}"
                                >
                                    <el-table-column
                                            type="index"
                                            label="序号"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="afetyProductTypeId"
                                            label="产品类别"
                                            width="220"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="productionCount"
                                            label="产品数量"
                                            width="140"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="singlePrice"
                                            label="产品单价"
                                            width="140"

                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="productRemarks"
                                            label="备注"
                                            width="280"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            label="操作"
                                            align="center"
                                    >
                                        <template v-slot="scope">
                                            <el-button  type="primary" @click="deleteMsg2(scope.row.afetyProductionBudgetTableId)" icon="el-icon-delete" size="mini">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="annualTime"
                                label="预算年度"
                                width="200"
                        >
                            <template v-slot="scope">
                                <span>{{Number(scope.row.annualTime.substring(0,scope.row.annualTime.indexOf('-')))+1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="budgetPlan"
                                label="预算计划内容"
                                width="600"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="addMoth(scope.row.afetyProductionBudgetId)" icon="el-icon-document-add" size="mini">新增产品预算</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.afetyProductionBudgetId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增年度预算计划"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="addRules">
                <el-form-item label="预算年度" prop="annualTime">
                    <el-date-picker
                            v-model="addForm.annualTime"
                            type="year"
                            placeholder="选择计划年度">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="预算计划内容" prop="budgetPlan">
                    <el-input  type="textarea" :rows="3"  v-model="addForm.budgetPlan"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增月度计划"
                :visible.sync="dialogVisible2"
                width="50%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-row>
                <div class="addTypeDiv">
                    <el-button  type="primary" @click="addType()" icon="el-icon-document-add" size="mini">新增预算种类</el-button>
                </div>
            </el-row>
            <el-row >
            <el-table
                    :data="tableDataPru"
                    border
                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
            >
                <el-table-column
                        type="index"
                        label="序号"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="afetyProductTypeId"
                        label="产品类别"
                        width="200"
                >
                    <template v-slot="scope">
                        <el-input   v-model="scope.row.afetyProductTypeId"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="productionCount"
                        label="产品数量"
                        width="120"

                >
                    <template v-slot="scope">
                        <el-input   v-model="scope.row.productionCount"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="singlePrice"
                        label="产品单价"
                        width="120"

                >
                    <template v-slot="scope">
                        <el-input   v-model="scope.row.singlePrice"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="productRemarks"
                        label="备注"
                        width="120"

                >
                    <template v-slot="scope">
                        <el-input   v-model="scope.row.productRemarks"></el-input>
                    </template>
                </el-table-column>
                <el-table-column
                        label="操作"
                        align="center"
                >
                    <template v-slot="scope">
                        <el-button  type="primary" @click="savePru(scope.row)" icon="el-icon-document-add" size="mini">保存</el-button>
                    </template>
                </el-table-column>
            </el-table>
            </el-row>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible2 = false">关 闭</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "notice",
        data(){
            return{
                tableData:[],
                tableData2:[],
                keybolen:false,
                queryInfo:{
                    annualTime:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    departmentId:"",
                },
                total:0,
                dialogVisible:false,
                dialogVisible2:false,
                addForm:{
                    annualTime:"",
                    budgetPlan:'',
                    afetyProductionBudgetId:'',
                    departmentId:'',
                    projectId:this.$root.proId,
                },
                addForm2:{
                    afetyProductionBudgetId :'',
                    afetyProductTypeId:'',
                    afetyProductionBudgetTableId:"",
                    productRemarks:'',
                    productionCount:0,
                    singlePrice:''
                },
                yonghu:[],
                props: {
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                fileList:[],
                addRules:{

                },
                row:'',
                expandedRows:'',
                tableDataPru:[]
            }
        },
        created(){
            this.getFiles()
            this.getUsers()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/production/budget/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询年度预算计划失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            async handledetail(row,expandedRows){
                this.row=row
                this.expandedRows=expandedRows
                if(expandedRows.length>0){
                    const {data:res}= await this.$http14.post('/v1.0/security/production/budgetTable/findAllByAfetyProductionBudgetId?afetyProductionBudgetId='+row.afetyProductionBudgetId )
                    if(res.status!='3000'){
                        return  this.tableData2[row.afetyProductionBudgetId]=[]
                    }else {
                        this.tableData2[row.afetyProductionBudgetId]=res.data
                    }
                }else{
                    this.tableData2[row.afetyProductionBudgetId]=[]
                }
                this.keybolen=!this.keybolen
            },
            handleChange(value){
                this.addForm2.userId=value[value.length-1]
            },
            sendNotice(){
                this.dialogVisible=true
            },
            async addFile(){
                const {data:res}= await this.$http14.post('/v1.0/security/production/budget/save',this.addForm)
                if(res.status!='6000'){
                    return this.$message.error('新增年度预算计划失败！'+res.message)
                }
                this.$message.success('新增年度预算计划成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async addFile2(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/planTable/save',this.addForm2)
                if(res.status!='6000'){
                    return this.$message.error('新增月度会议计划失败！'+res.message)
                }
                this.$message.success('新增月度会议计划成功！');
                this.dialogVisible2=false;
                if(this.row.meetingPlanId==this.addForm2.meetingPlanId){
                    this.handledetail(this.row,this.expandedRows);
                }

            },
            addMoth(id){
                this.addForm2.afetyProductionBudgetId =id;
                this.tableDataPru=[]
                this.dialogVisible2=true
            },
            addType(){
                this.tableDataPru.push({
                    "afetyProductTypeId": "",
                    "afetyProductionBudgetId": this.addForm2.afetyProductionBudgetId,
                    "afetyProductionBudgetTableId": "",
                    "productRemarks": "",
                    "productionCount": 0,
                    "singlePrice": ''
                })
            },
          async  savePru(row){
              console.log(row)
                const {data:res}= await this.$http14.post('/v1.0/security/production/budgetTable/save',row)
                if(res.status!='6000'){
                    return this.$message.error('新增预算种类失败！'+res.message)
                }
                this.$message.success('新增预算种类成功！');
                //this.dialogVisible2=false;
                if(this.row.afetyProductionBudgetId==this.addForm2.afetyProductionBudgetId){
                    this.handledetail(this.row,this.expandedRows);
                }
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该年度预算计划, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/production/budget/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除年度预算计划失败！'+res.message)
                }
                this.$message.success('删除年度预算计划成功！');
                this.getFiles();
            },
            async deleteMsg2(id){
                const confirmResult = await this.$confirm('此操作将永久删除该预算种类, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/production/budgetTable/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除预算种类失败！'+res.message)
                }
                this.$message.success('删除预算种类成功！');
                this.handledetail(this.row,this.expandedRows);
            },
            async showfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getFiles()
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">
    /deep/ .el-table__expanded-cell{
        padding-left: 120px;
        padding-right: 120px;
    }
    /deep/ .el-date-editor{
        width: 100% !important;
    }
    /deep/ .el-table--border,  /deep/.el-table--group{
        border: 2px solid #ccc;
    }
    .addTypeDiv{
        margin-bottom: 20px;
    }
    .monthTable{
        max-height: 420px;
        overflow-y: auto;
    }
</style>
