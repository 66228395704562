<template>
    <div class="bg">
        <pro-left></pro-left>
         <div id="profile">
         <div id="fileheader">
             <el-button  icon="el-icon-folder-add" @click="addfolderbefore" v-has="'addfiles'">添加文件夹</el-button>
             <el-button  icon="el-icon-folder-add" @click="delfolder" v-has="'delfile'">删除文件夹</el-button>
             <el-button  icon="el-icon-upload" @click="addfilebefore" v-has="'uploadfile'">上传文件</el-button>
             <el-button icon="el-icon-shopping-cart-full" @click="movefilebefore" v-has="'movefile'">移动</el-button>
             <el-button  icon="el-icon-folder-add" @click="addfolderNamebefore" >修改文件夹名</el-button>
         </div>
          <div id="filetree">
              <el-tree
                      :data="data"
                      node-key="id"
                      ref="tree1"
                      :props="defaultProps"
                      @node-click="handleCheckChange"
                      :expand-on-click-node="false">
        <span class="custom-tree-node" slot-scope="{ node, data }">
         <span v-if="data.parent.fileTypeId==1"><i class="el-icon-folder-opened"></i> {{ data.parent.name }}</span>
          <span v-else-if="data.parent.fileTypeId==2"><i class="el-icon-document"></i> {{ data.parent.name }}</span>
         <span v-else-if="data.parent.fileTypeId==3"><i class="el-icon-document"></i> {{ data.parent.name }}</span>
         </span>
              </el-tree>
          </div>
          <div id="fileright">
              <el-table
                      :data="fileData"
                      :header-cell-style="{background:'#e3ecfb',color:'black'}"
                      height="100%"
                      @row-dblclick="rowClick"
                      @select="rowSelect"
                      style="width: 100%">
                  <el-table-column
                          type="selection"
                          width="50">
                  </el-table-column>
                  <el-table-column
                          prop="name"
                          label="名称"
                          align="center"
                          width="180">
                      <template v-slot="scope">
                          <span v-if="scope.row.type=='folder'"><i class="el-icon-folder-opened"></i> {{ scope.row.name }}</span>
                          <span v-else-if="scope.row.type=='jpg'|| scope.row.type=='png'|| scope.row.type=='tif'|| scope.row.type=='gif'|| scope.row.type=='raw'|| scope.row.type=='webp' " @click="yulan(scope.row)"><i class="el-icon-picture"></i> <a href="#">{{ scope.row.name }}</a></span>
                          <span v-else><i class="el-icon-document"></i> {{ scope.row.name }}</span>
                      </template>
                  </el-table-column>
                  <el-table-column
                          prop="fileSerialNum"
                          label="文件编号"
                          align="center"
                          width="100">
                  </el-table-column>
                  <el-table-column
                          prop="fileTypeName"
                          label="文件类型名称"
                          align="center"
                          width="120">
                  </el-table-column>
                  <el-table-column
                          prop="fileTag"
                          label="文件标签"
                          align="center"
                          width="100">
                  </el-table-column>
                  <el-table-column
                          prop="creationTime"
                          label="创建时间"
                          align="center"
                          width="100">
                  </el-table-column>
                  <el-table-column
                          prop="lastModifierUserName"
                          label="最后更新人"
                          align="center"
                          width="100">
                  </el-table-column>
                  <el-table-column
                          prop="lastModificationTime"
                          label="最后更新时间"
                          align="center"
                          width="110">
                  </el-table-column>
                  <el-table-column
                          label="操作"
                          align="center"
                          >
                      <template v-slot="scope">
                          <el-tooltip effect="dark" content="修改文件" placement="top" :enterable="false" >
                              <el-button type="primary" icon="el-icon-edit" size="mini" @click="editFileBefore(scope.row.id)" v-has="'editfile'"></el-button>
                          </el-tooltip>
                          <el-tooltip effect="dark" content="下载" placement="top" :enterable="false" >
                              <el-button type="primary" icon="el-icon-download" size="mini" @click="download(scope.row)" v-has="'downfile'"></el-button>
                          </el-tooltip>
                          <el-tooltip effect="dark" content="删除文件" placement="top" :enterable="false" >
                              <el-button type="primary" icon="el-icon-delete" size="mini" @click="deletef(scope.row.id)" v-has="'delfile'"></el-button>
                          </el-tooltip>
                      </template>
                  </el-table-column>
              </el-table>
          </div>
        </div>
        <el-dialog
                title="创建文件夹"
                :visible.sync="dialogVisiblefolder"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffolder" :model="addFormfolder" label-width="100px" :rules="addfolderRules">
                <el-form-item label="文件夹名称" prop="name">
                    <el-input v-model="addFormfolder.name"></el-input>
                </el-form-item>
                <el-form-item label="文件类型名称" prop="fileTypeName">
                    <el-input v-model="addFormfolder.fileTypeName"></el-input>
                </el-form-item>
                <el-form-item label="文件标签" prop="fileTag">
                    <el-input v-model="addFormfolder.fileTag"></el-input>
                </el-form-item>
                <el-form-item label="文件编号" prop="fileSerialNum">
                    <el-input v-model="addFormfolder.fileSerialNum"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblefolder = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addfolder" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="上传文件"
                :visible.sync="dialogVisiblelfile"
                width="25%"
                center
                :close-on-click-modal="false"

                @close="dialogClose2"
        >
            <el-form ref="addFormReffile" :model="addFormfile" label-width="110px" :rules="addfileRules">
                <el-form-item label="文件标签" prop="fileTag">
                    <el-input v-model="addFormfile.fileTag"></el-input>
                </el-form-item>
                <el-form-item label="文件类型名称" prop="fileTypeName">
                    <el-input v-model="addFormfile.fileTypeName"></el-input>
                </el-form-item>
                <el-form-item label="文件编号" prop="fileSerialNum">
                    <el-input v-model="addFormfile.fileSerialNum"></el-input>
                </el-form-item>
                <el-form-item label="上传文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelfile = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addfile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="移动文件（夹）"
                :visible.sync="dialogVisiblelmove"
                width="25%"
                center
                :close-on-click-modal="false"

                @close="dialogClose"
        >
            <el-form ref="moveFormReffile" :model="addFormfile" label-width="110px" >
                <el-tree
                        :data="folderTree"
                        node-key="id"
                        ref="tree"
                        :props="defaultProps"
                        show-checkbox
                        check-strictly
                        default-expand-all
                        @check="moveclick"
                        @check-change="tempChange"
                        :expand-on-click-node="false">
         <span class="custom-tree-node" slot-scope="{ node, data }">
         <span v-if="data.parent.isFolder==1"><i class="el-icon-folder-opened"></i> {{ data.parent.name }}</span>
         </span>
                </el-tree>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelmove = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="movefile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改文件"
                :visible.sync="dialogVisiblelfileEdit"
                width="25%"
                :close-on-click-modal="false"
                center
                @close="dialogClose"
        >
            <el-form ref="editFormReffile" :model="editFormfile" label-width="110px" >
                <el-form-item label="文件类型名称">
                    <el-input v-model="editFormfile.fileTypeName"></el-input>
                </el-form-item>
                <el-form-item label="文件编号">
                    <el-input v-model="editFormfile.fileSerialNum"></el-input>
                </el-form-item>
                <el-form-item label="文件标签">
                    <el-input v-model="editFormfile.fileTag"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelfileEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editfile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改文件夹名"
                :visible.sync="dialogVisiblelfileEditName"
                width="25%"
                center
                :close-on-click-modal="false"
        >
            <el-form ref="addFormReffolder" :model="editFormName" label-width="100px" :rules="editFormName">
                <el-form-item label="原文件夹名称" prop="name">
                    <el-input v-model="editFormName.name" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item label="新文件夹名称" prop="newname">
                    <el-input v-model="editFormName.newname"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblelfileEditName = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editname" >确 定</el-button>
  </span>
        </el-dialog>
        <div class="demo-image__preview" v-show="imgshow">
            <span class="closex" @click="closeimg">X </span>
            <el-image
                    style="width: 1000px; height: 700px"
                    :src="imgurl"
                    :preview-src-list="imgurl"
                    :fit="fit">
            </el-image>
        </div>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
        </div>
</template>

<script>
    import proLeft from './proInfoLeft'
    export default {
        name: "profile",
        data(){
            return{
                data : [],
                    defaultProps: {
                        children: 'children',
                        label: 'name'
                    },
                fileData:[],
                selectId:[],
                fit: 'fill',
                imgurl:'',
                imgshow:false,
                proshow:false,
                selectTreeId:'',
                isfolder:0,
                dialogVisiblefolder:false,
                dialogVisiblelfile:false,
                dialogVisiblelmove:false,
                addFormfolder:{
                    name:'',
                    type:'folder',
                    fileTypeName:'',
                    fileTag:'',
                    fileSerialNum:'',
                    isFolder:1,
                    fileTypeId:1,
                    projectId:this.$root.proId,
                    parentId:''
                },
                addfolderRules:{
                    name:[{required:true,message:'请输入文件夹名称',trigger:'blur'}],
                    fileTag:[{required:true,message:'请输入文件夹标签',trigger:'blur'}],
                    fileSerialNum:[{required:true,message:'请输入文件编号',trigger:'blur'}],
                },
                addfileRules:{
                    fileTag:[{required:true,message:'请输入文件夹标签',trigger:'blur'}],
                    fileSerialNum:[{required:true,message:'请输入文件编号',trigger:'blur'}],
                    fileTypeName:[{required:true,message:'请输入文件类型名称',trigger:'blur'}],
                },
                editFormName:{
                    newname:[{required:true,message:'请输入新文件夹名称',trigger:'blur'}],
                },
                addFormfile:{
                    name:'',
                    type:'png',
                    fileTypeName:'',
                    fileTag:'',
                    fileSerialNum:'',
                    isFolder:0,
                    fileTypeId:2,
                    projectId:this.$root.proId,
                    parentId:''
                },
                editFormfile:{},
                dialogVisiblelfileEdit:false,
                closediashow:false,
                fileList:[],
                folderTree:[],
                selectMoveTreeId:'',
                editFormName:{
                },
                dialogVisiblelfileEditName:false,
            }
        },
        created(){
           this.getfileTree();
        },
        methods:{
            async getfileTree(){
              const {data:res}=await  this.$http1.get('/v1.0/project/document/findProjectDocumentOtherTreeByProjectId?projectId='+this.$root.proId)
              this.data=res.data
              var arr=[];
              this.folderTree=this.filterTree(res.data,arr)
            },
            //递归过滤只留文件夹
            filterTree(tree, arr = []){
                if (!tree.length) return []
                for (let item of tree) {
                    if (item.parent.isFolder !== 1) continue
                    let node = {...item, children: []}
                    arr.push(node)
                    if (item.children && item.children.length) {
                        this.filterTree(item.children, node.children)
                    }
                }
                return arr
            },
            async fileupload(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            async handleCheckChange(data) {
                console.log(data)
                this.selectTreeId=''
                this.selectTreeId=data.parent.id;
                this.isfolder=data.parent.isFolder
                console.log(this.isfolder)
                if(!this.isfolder){
                        const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+data.parent.path)
                        window.open(res2.data);
                }
               this.getFileList(this.selectTreeId)
            },
            async getFileList(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/document/findAllByParentId?parentId='+id)
                this.fileData=res.data
            },
            async rowClick(row,column){
               if(row.isFolder==1){
                   this.getFileList(row.id)
               }
            },
            async rowSelect(selection, row){
               console.log(selection)
                this.selectTreeId=row.id
                this.selectId=[]
                for(let i in selection){
                    this.selectId.push(selection[i].id)
                }
            },
            async download(row){
                if(row.isFolder){
                    return this.$message.warning('目前不支持文件夹整体下载！请点击文件夹下的文件')
                }else {
                    const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+row.path)
                    window.open(res2.data)
                }
               /* const {data:res}=await  this.$http1.get('/v1.0/project/document/downloadDocument?id='+id)
               if(res.status=='1020'){
                   console.log(res.data)
                 const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+res.data)
                   window.location.href = res2.data;
               }*/
            },
            async editFileBefore(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/document/findById?id='+id)
                if(res.status=='3000'){
                    this.editFormfile=res.data;
                    this.dialogVisiblelfileEdit=true
                }
                else {
                    this.$message.error(res.message)
                }
            },
            async editfile(){
                const {data:res}=await  this.$http1.put('/v1.0/project/document/update',this.editFormfile)
                if(res.status!='6000'){
                   return this.$message.error(res.message)
                }
                this.$message.success('修改文件成功')
                this.dialogVisiblelfileEdit=false
                this.getFileList(this.selectTreeId)
            },
            addfilebefore(){
                if(this.selectTreeId=='' || this.isfolder==0){
                    return this.$message.error('请先选择文件夹节点！')
                }
                this.fileList=[]
                this.dialogVisiblelfile=true
            },
            async addfile(){
                this.proshow=true
                var formData=new FormData();
                for(let x in this.filelist){
                    formData.append("multipartFiles",this.filelist[x].raw);
                }
                formData.append("name",this.addFormfile.name);
                formData.append("type",this.addFormfile.type);
                formData.append("fileTypeName",this.addFormfile.fileTypeName);
                formData.append("fileTag",this.addFormfile.fileTag);
                formData.append("fileSerialNum",this.addFormfile.fileSerialNum);
                formData.append("isFolder",this.addFormfile.isFolder);
                formData.append("fileTypeId",this.addFormfile.fileTypeId);
                formData.append("projectId",this.addFormfile.projectId);
                formData.append("parentId",this.selectTreeId);
                const {data:res}= await this.$http1.post('/v1.0/project/document/save', formData)
                if(res.status!='6000'){
                    return this.$message.error('添加文件失败！'+res.message)
                }
                this.$message.success('添加文件成功！');
                this.dialogVisiblelfile=false;
                this.getfileTree()
                this.getFileList(this.selectTreeId)
                this.proshow=false
            },
            handleRemove(file, fileList) {
                this.filelist=filelist
            },
            handlePreview(file) {
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            addfolderbefore(){
                if(this.selectTreeId==''){
                    //return this.$message.error('请先选择文件夹！')
                    this.selectTreeId=0
                }
              this.dialogVisiblefolder=true
            },
           async  delfolder(){
                if(this.selectTreeId==''){
                    return this.$message.error('请先选择文件夹！')
                }
                const confirmResult = await this.$confirm('此操作将永久删除该文件(夹), 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const {data:res}= await this.$http1.delete('/v1.0/project/document/delete/'+this.selectTreeId)
                if(res.status!="4000"){
                    return this.$message.error('删除文件失败！'+res.message)
                }
                this.$message.success('删除文件成功！');
                this.selectTreeId=''
                this.getfileTree();
            },
            async addfolder(){
                   var formData=new FormData();
                   formData.append("name",this.addFormfolder.name);
                   formData.append("type",this.addFormfolder.type);
                   formData.append("fileTypeName",this.addFormfolder.fileTypeName);
                   formData.append("fileTag",this.addFormfolder.fileTag);
                   formData.append("fileSerialNum",this.addFormfolder.fileSerialNum);
                   formData.append("isFolder",this.addFormfolder.isFolder);
                   formData.append("fileTypeId",this.addFormfolder.fileTypeId);
                   formData.append("projectId",this.addFormfolder.projectId);
                   formData.append("parentId",this.selectTreeId);
                   const {data:res}= await this.$http1.post('/v1.0/project/document/save', formData)
                   if(res.status!='6000'){
                       return this.$message.error('添加文件夹失败！'+res.message)
                   }
                   this.$message.success('添加文件夹成功！');
                   this.dialogVisiblefolder=false;
                   this.getfileTree()
            },
            async yulan(row){

                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+row.path)
                this.imgurl = res2.data;
                this.imgshow=true
            },
            closeimg(){
                this.imgurl = ''
                this.imgshow=false
            },
            async deletef(id){
                const confirmResult = await this.$confirm('此操作将永久删除该文件(夹), 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                const {data:res}= await this.$http1.delete('/v1.0/project/document/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除文件失败！'+res.message)
                }
                this.$message.success('删除文件成功！');
                this.getfileTree();
                this.getFileList(this.selectTreeId)
            },
            dialogClose(){
                    this.$refs.addFormReffolder.resetFields();
            },
            dialogClose2(){
                this.$refs.addFormReffile.resetFields();
            },
            movefilebefore(){
                if(this.selectTreeId==''){
                    return this.$message.error('请先选择文件或文件夹！')
                }
                this.dialogVisiblelmove=true
            },
            async addfolderNamebefore(){
                if(this.selectTreeId==''){
                    return this.$message.error('请先选择文件或文件夹！')
                }
                const {data:res}=await  this.$http1.get('/v1.0/project/document/findById?id='+this.selectTreeId)
                if(res.status=='3000'){
                    this.editFormName=res.data;
                    this.dialogVisiblelfileEditName=true
                }
            },
            async editname(){
                this.editFormName.name=this.editFormName.newname;
                console.log(this.editFormName.newname)
                const {data:res}=await  this.$http1.put('/v1.0/project/document/update',this.editFormName)
                if(res.status!='6000'){
                    return this.$message.error(res.message)
                }
                this.$message.success('修改文件名称成功')
                this.dialogVisiblelfileEditName=false
                this.getfileTree()
            },
            moveclick(data,isSe){
             this.selectMoveTreeId=data.parent.id
             this.$refs.tree.setCheckedKeys([data.id])
            },
            tempChange(data,checked,node){
                if(checked){
                    this.selectMoveTreeId=data.parent.id
                    this.$refs.tree.setCheckedKeys([data.id])
                }else {
                    if(this.selectMoveTreeId==data.parent.id){
                        this.$refs.tree.setCheckedKeys([data.id])
                    }
                }
                /*let id = data.parent.id
                let index = this.selectId.indexOf(id)
                if (index < 0 && this.selectId.length && checked) {
                    this.$message.warning('只能选中一个节点')
                   this.$refs.tree.setChecked(data, false) // 取消当前节点的选中状态
                   this.$refs.tree.setCheckedKeys([]);
                    return
                }
                if (!checked && index >= 0 && this.selectId.length) {
                    this.selectId = []
                    return
                }
                // 当前节点不在this.checked(长度为0)中,当前节点为选中状态,this.checked中存储当前节点id
                if (index < 0 && !this.selectId.length && checked) {
                    this.selectId.push(id)
                }*/
            },
            async movefile(){
                if(this.selectMoveTreeId==this.selectTreeId){
                    return this.$message.error('不能选择原文件目录！')
                }
                const {data:res}=await  this.$http1.get('/v1.0/project/document/moveDocument?id='+this.selectTreeId+'&destinationId='+this.selectMoveTreeId)
                if(res.status!='1010'){
                    return this.$message.error(res.message)
                }
                this.$message.success('文档移动成功！')
                this.getfileTree();
                this.dialogVisiblelmove=false
            }

        },
        components:{
            proLeft
        }
    }
</script>

<style scoped lang="less">
    #profile{
        background-color: white;
        position: absolute;
        left: 510px;
        top: 80px;
        bottom:20px;
        width: 1400px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    #fileheader{
        width: 100%;
        height: 46px;
        padding-top: 5px;
        border-bottom: 1px solid #e3ecfb   ;
        padding-left: 15px;
    }
    #filetree{
        height:735px;
        width: 300px;
        margin-top: 5px;
        margin-left: 10px;
    }
    #fileright{
        width: 1080px;
        height: 777px;
        margin-top: 5px;
        border-left: 2px solid #e3ecfb;
    }

   /deep/ .el-tree{
       height: 750px;overflow:auto;
   }

    .closex{
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 15px;
        z-index: 999999;
        cursor: pointer;
    }
    .demo-image__preview{
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin:  auto;
        z-index: 99999;
        width: 1000px;
        height: 700px;

    }
    /deep/ .el-image{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      margin:  auto;
      z-index: 99999;
  }


</style>
