<template>
    <div style="width: 100%;height: 90%;" id="bottomEchartsShi">

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "ecanquan",
        mounted(){
            this.echart();
        },
        methods:{
            echart(){
                var date=new Date();
                var xdata=[];
                var cnum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二','十三','十四'];
                for(var i=0;i<=13;i++){
                    xdata.push('第'+cnum[i]+'师')
                }
                var myChart1 = echarts.init(document.getElementById('bottomEchartsShi'));
                var option = {
                    legend: {
                        data: ['正在建设(个)', '投资额(亿)', '合同额(亿)']
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: xdata
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    grid: {
                        top:'18%',
                        left: '4%',
                        right: '4%',
                        bottom: '0px',
                        containLabel: true
                    },
                    series: [
                        {
                            name: '正在建设(个)',
                            type: 'bar',
                            barGap: 0,
                            emphasis: {
                                focus: 'series'
                            },
                            data: [320, 332, 301, 334, 390, 311, 320, 290, 190, 90, 290, 240,190, 290]
                        },
                        {
                            name: '投资额(亿)',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: [120, 182, 191, 234, 90,120, 52, 91, 134, 20,120, 82,100,220]
                        },
                        {
                            name: '合同额(亿)',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            data: [150, 232, 201, 154, 190,150, 152, 141, 124, 70,255,190,150,250]
                        }

                    ]
                };
                myChart1.setOption(option);
                window.addEventListener("resize", function () {
                    myChart1.resize();
                });
            }
        }
    }
</script>

<style scoped>

</style>
