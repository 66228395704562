<template>
    <div class="cont">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="leftWord" id='cediv'>
        <!-- <iframe  ref="iframe"  id='ffff'   scrolling="auto"></iframe> -->
      </div>
      <div class="rightop">
        <el-button type="primary" icon="el-icon-search" @click="saveForm">保存内容</el-button>
        <!-- <el-button type="primary" icon="el-icon-search" @click="goForm">提起报表</el-button> -->
      </div>
      <div class="rightFrom">
           <h1>表格配置---{{yuansu}}</h1>
           <el-tabs v-model="activeName" @tab-click="handleClick" >
            <el-tab-pane label="类型" name="first">
              <div class="typeDiv"  :class="{ active: typehtml == 1 }">输入框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 2 }" @click="changeSelect">下拉选择框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 3 }" @click="radioSelect">单选框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 4 }" @click="checkSelect">多选框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 5 }" @click="dateSelect">日期选择框</div>
              <div class="typeDiv"  :class="{ active: typehtml == 6 }" @click="imgSelect">签章</div>
              <div id='ssss'></div>
            </el-tab-pane>
            <el-tab-pane label="参数" name="second">
              <el-form  label-width="140px">
                <el-form-item label="默认值">
                  <el-input v-model="morenzhi"></el-input>
                </el-form-item>
                <el-form-item label="数据关联">
                </el-form-item>
                <el-form-item label="空值提示">
                </el-form-item>
                <el-form-item label="必填">
                  <el-switch v-model="bitian"></el-switch>
                </el-form-item>
                <el-form-item label="标签">
                </el-form-item>
                <el-form-item label="标签序号">
                </el-form-item>
                <el-form-item label="标签值">
                </el-form-item>
                <el-form-item label="是否多选">
                </el-form-item>
                <el-form-item label="多选个数限制">
                  <el-input v-model="duoxuangeshu"></el-input>
                </el-form-item>
                <el-form-item label="开启筛选">
                  <el-input type="textarea" v-model="shaixuan"></el-input>
                </el-form-item>
                <el-form-item label="填充数据">
                  <el-button type="primary" @click="tianchong">配置</el-button>
                </el-form-item>
              </el-form>
            </el-tab-pane>
            <el-tab-pane label="样式" name="third">
                宽度：  <el-input-number v-model="num" @change="handleChange" :min="1" :max="500"></el-input-number>
            </el-tab-pane>
          </el-tabs>
      </div>
      <el-dialog
        title="提示"
        :visible.sync="dialogVisibleTianchong"
        width="30%"
        >
        <el-form :model="tianchongform" label-position='left'>
        <el-form-item label="数据类型" label-width="120px">
            静态数据
        </el-form-item>
        <el-form-item label="值" label-width="120px">
          <el-input type="textarea" v-model="tianchongform.value"></el-input>
          <p>参考格式：["张三","李四","王五"]</p>
        </el-form-item>
      </el-form>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisibleTianchong = false">取 消</el-button>
          <el-button type="primary" @click="istianchong">确 定</el-button>
        </span>
    </el-dialog>
    </div>
   
  </template>
  <script>
  import promeau from "../proMeau.vue";
  import Vue from 'vue'
   
  const inputCom = Vue.component('input-component', {
    data() {
      return {
        inputvalue: "",
      }
    },
    template: '<el-input v-model="inputvalue"></el-input>'
  })
  const selectCom = Vue.component('select-component', {
    data() {
      return {
        options: [{
        }],
        selectvalue: "",
      }
    },
    template: '<el-select v-model="selectvalue"><el-option v-for="item in options" :key="item.value"   :label="item.label"  :value="item.value"></el-option></el-select>'
  })
  const radioCom = Vue.component('radio-component', {
    data() {
      return {
        radio: "",
        word1:'',
      }
    },
    template: '<el-radio v-model="radio" label="1">{{word1}}</el-radio>'
  })
  const checkboxCom = Vue.component('checkbox-component', {
    data() {
      return {
        checked: "",
        word:'',
      }
    },
    template: '<el-checkbox v-model="checked" label="1">{{word}}</el-checkbox>'
  })
  const dateCom = Vue.component('date-component', {
    data() {
      return {
        value: "",
        word:'',
      }
    },
    template: '<el-date-picker v-model="value" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">{{word}}</el-date-picker>'
  })
  const imgCom = Vue.component('img-component', {
    data() {
      return {
        url:"",
        fit: "fill",
      }
    },
    template: '<el-image  style="width: 100px; height: 40px" :src="url" :fit="fit"><div slot="error" class="image-slot">  <i class="el-icon-picture-outline"></i> </div></el-image>'
  })
  let commNew;
  export default {
    data() {
      return {
        iframeSrc: 'static/ce.html',
        yuansu:'',
        shouzhiForm:'',
        activeName:'first',
        typehtml:1,
        selectedValue:'',
        comList:[],
        morenzhi:'',
        bitian:'',
        duoxuangeshu:'',
        shaixuan:"",
        index:'',
        dialogVisibleTianchong:false,
        tianchongform:{
          value:''
        },
        optobj:[],
        dialogFormVisible:false,
        addForm:{
          attacheId: window.sessionStorage.getItem('attacheId'),
          categoryId: "act_bt_category",
          code: "",
          formType:"formula_dictionary",
          name:"",
          nodalId:"formula_nodal",
          tableName: '',
          remarks: ''
        },
        props: {
          label: "categoryName",
          value: "categoryId",
          checkStrictly: true,
        },
        isChange:false,
        categoryOptions:[],
        num:200,
      }
    },
    methods:{
        async getActBtCategoryTree() {
            const { data: res } = await this.$http2.get('/extension/act-bt-category/query/ActBtCategoryTree')
            if (res.code == 200) {
                this.categoryOptions = res.data;
            }
        },
        goForm(){
          this.dialogFormVisible=true
        },
        async getinfo(){
          const {data:res2}=await  this.$http9.get('/stencils/findById?id='+window.sessionStorage.getItem('attacheId'));
          this.shouzhiForm=res2.data.htmls.htmlBody
          //const iframe = document.getElementById('iframeBox')
          let  ce=document.getElementById('cediv')
          ce.innerHTML = this.shouzhiForm;
          this.comList=res2.data.componentList
          var spans=document.querySelectorAll(".s")
          for(let i in res2.data.componentList){
              let div= document.createElement("div");
              div.className='input-div'
              div.id=spans[i].id
              div.addEventListener('click', ()=> {
                this.yuansu=spans[i].id
                this.num=200
              });
           
              if(res2.data.componentList[i].type=='input'){
                let inputcom=Vue.extend({
                  extends:inputCom,
                })
                let inputel= new inputcom().$mount()
                div.appendChild(inputel.$el);
                console.log(res2.data.componentList[i])
                div.style.width=res2.data.componentList[i].style+'px'
                div.classList.add('hangnei');
              }else if(res2.data.componentList[i].type=='select'){
                var selecttcom=Vue.extend({
                  extends:selectCom,
                })
                let commSel=new selecttcom()
                let selectel= commSel.$mount() 
                div.appendChild(selectel.$el);
                let zhidata=res2.data.componentList[i].optionalValue.split(',')
                let optobj=[]
                for(let i in zhidata){
                  optobj.push({
                    label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')
                  })
                }
                this.optobj=optobj
                commSel.$set(commSel, 'options', optobj)
              }else if(res2.data.componentList[i].type=='radio'){
                var selecttcom=Vue.extend({
                  extends:radioCom,
                })
                let commSel=new selecttcom()
                let selectel= commSel.$mount() 
                div.appendChild(selectel.$el);
              }else if(res2.data.componentList[i].type=='check'){
                var selecttcom=Vue.extend({
                  extends:checkboxCom,
                })
                let commSel=new selecttcom()
                let selectel= commSel.$mount() 
                div.appendChild(selectel.$el);
              }else if(res2.data.componentList[i].type=='date'){
                var selecttcom=Vue.extend({
                  extends:dateCom,
                })
                let commSel=new selecttcom()
                let selectel= commSel.$mount() 
                div.appendChild(selectel.$el);
                div.classList.add('hangnei');
              }else if(res2.data.componentList[i].type=='img'){
                var selecttcom=Vue.extend({
                  extends:imgCom,
                })
                let commSel=new selecttcom()
                let selectel= commSel.$mount() 
                div.appendChild(selectel.$el);
                div.classList.add('hangnei');
              }
              if(spans[i].parentNode){
                spans[i].parentNode.replaceChild(div, spans[i]);
              }
          }
        },
        handleClick(tab, event){
          console.log(tab, event)
        },
        changeSelect(){
          this.typehtml=2;
          this.index=Number(this.yuansu.substring(1))-1
          this.comList[this.index].type='select'
          let comm=Vue.extend({
             extends:selectCom,
          })
          commNew=new comm()
          let inputel= commNew.$mount()
          let Vx=document.getElementById(this.yuansu)
          Vx.parentNode.replaceChild(inputel.$el, Vx);
        },
        radioSelect(){
          this.typehtml=3;
          this.index=Number(this.yuansu.substring(1))-1
          this.comList[this.index].type='radio'
          let comm=Vue.extend({
             extends:radioCom,
          })
          commNew=new comm()
          let inputel= commNew.$mount()
          let Vx=document.getElementById(this.yuansu)
          Vx.parentNode.replaceChild(inputel.$el, Vx);
        },
        checkSelect(){
          this.typehtml=4;
          this.index=Number(this.yuansu.substring(1))-1
          this.comList[this.index].type='check'
          let comm=Vue.extend({
             extends:checkboxCom,
          })
          commNew=new comm()
          let inputel= commNew.$mount()
          let Vx=document.getElementById(this.yuansu)
          Vx.parentNode.replaceChild(inputel.$el, Vx);
        },
        dateSelect(){
          this.typehtml=5;
          this.index=Number(this.yuansu.substring(1))-1
          this.comList[this.index].type='date'
          let comm=Vue.extend({
             extends:dateCom,
          })
          commNew=new comm()
          let inputel= commNew.$mount()
          let Vx=document.getElementById(this.yuansu)
          Vx.parentNode.replaceChild(inputel.$el, Vx);
        },
        imgSelect(){
          this.typehtml=6;
          this.index=Number(this.yuansu.substring(1))-1
          this.comList[this.index].type='img'
          let comm=Vue.extend({
             extends:imgCom,
          })
          commNew=new comm()
          let inputel= commNew.$mount()
          let Vx=document.getElementById(this.yuansu)
          Vx.parentNode.replaceChild(inputel.$el, Vx);
        },
        handleChange(e){
          this.index=Number(this.yuansu.substring(1))-1
          let stylejson=JSON.parse(this.comList[this.index].style)
          stylejson.style.width=e+'px'
          this.comList[this.index].style=JSON.stringify(stylejson)
          let Vx=document.getElementById(this.yuansu)
          Vx.style.width=e+'px'
        },
        tianchong(){
          this.dialogVisibleTianchong=true
        },
        istianchong(){
          let zhidata=this.tianchongform.value.substring(1,this.tianchongform.value.length-1).split(',')
          let optobj=[],optdata=[]
          for(let i in zhidata){
            optobj.push({ label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')})
            optdata.push(zhidata[i].replace(/"/g, ''))
          }
          commNew.$set(commNew, 'options', optobj)
          this.$message.success('保存成功！')
          this.dialogVisibleTianchong=false
          this.comList[this.index].optionalValue=this.tianchongform.value.substring(1,this.tianchongform.value.length-1)
          let stylejson=JSON.parse(this.comList[this.index].style)
          stylejson.config.select.options.data=optdata
          this.comList[this.index].style=JSON.stringify(stylejson)
        },
        async saveForm(){
          const {data:res}=await  this.$http9.post('/stencils/insert/BatchComponents',{
            components:this.comList,
            stencilsId:window.sessionStorage.getItem('attacheId')
          });
          if(res.businessCode==4300){
            this.$message.success('保存成功！')
          }else{
            this.$message.error('保存失败！'+res.message)
          }
        },
        async faqiFrom(){
          const { data: res } = await this.$http9.post('/stencils/save', this.addForm)
            if (res.businessCode == 4300) {
                  this.$message.success('保存成功！')
                  this.dialogFormVisible = false;
            } else {
                  this.$message.error('保存失败！')
          }     
        },
        changeCategoryId(e) {
          this.isChange = true;
          this.addForm.categoryId=e[0]
        },
    },
    components: {
        promeau,
    },
    created(){
         this.getinfo()
         this.getActBtCategoryTree()
    }
  }
  </script>
  <style lang='less'>
 .hangnei{
      display: inline-block;
    }
    .input-div{
      .el-input{
        width: 100%;
      }
    }
</style>
  <style scoped lang='less'>
  .cont {
    position: relative;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: space-between;
    background-color: #f5f5f5;
  }
  .leftMeau{
    width: 200px;
    height: 100%;
  }
  .leftWord{
    width: 60%;
    height: 100%;
    text-align: center;
    background-color: #f5f5f5;
    font-size: 16px;
  }
  .rightFrom{
    width:25%;
    height: 100%;
    background-color: #fff;
    padding:0 1%;
    h1{
      margin-top: 10px;
      width: 100%;
      text-align: center;
    }
    /deep/ .el-tabs__item{
      font-size: 18px;
    }
    .typeDiv{
      line-height: 48px;
      cursor: pointer;
      color: #000;
      font-size: 18px;
    }
    .active{
      background: #66b1ff;
      padding-left: 16px;
      font-weight: 700;
      border-radius: 4px;
      color: #fff;
    }
  }
    .rightop{
      position:absolute;
      left: 220px;
      display: flex;
    }
   
  </style>