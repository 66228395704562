<template>
    <div style="width: 800px;height: 170px;" id="createUserNum">
    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "qualitycreate",
        mounted(){
            this.echart();
        },
        methods:{
           async echart(){
                const {data:res}= await this.$http7.get('/v1.0/quality/statistics/queryEvaluationCountByDateStatistics?projectId='+this.$root.proId)
                if(res.status!='3000'){
                    return/* this.$message.error(res.message)*/
                }
                var ydata=[]
                var date=new Date();
                var xdata=[];
                var cnum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
                for(var i=0;i<=11;i++){
                   var jishu=0
                  for(let j in res.data[0].countByDateDtos) {
                      if(res.data[0].countByDateDtos[j].monthText==i+1){
                          jishu++
                          ydata.push(res.data[0].countByDateDtos[j].notQualifiedCount)
                      }
                   }
                  if(jishu==0){
                      ydata.push(0)
                  }
                   xdata.push(cnum[i]+'月')
               }
               console.log(ydata)
                var myChart1 = echarts.init(document.getElementById('createUserNum'));
                var option = {
                    title: {
                        text: '不合格质量检查数量',
                        left: 'center'
                    },
                    xAxis: [
                        {
                            type: 'category',
                            axisTick: {show: false},
                            data: xdata
                        }
                    ],
                    yAxis: [
                        {
                            type: 'value'
                        }
                    ],
                    grid: {
                        top:'30px',
                        left: '10px',
                        right: '10px',
                        bottom: '5px',
                        containLabel: true
                    },
                    series: [
                        {
                            type: 'bar',
                            barWidth: 26,
                            showBackground: true,
                            itemStyle: {
                                color: new echarts.graphic.LinearGradient(
                                    0, 0, 0, 1,
                                    [
                                        {offset: 0, color: '#83bff6'},
                                        {offset: 0.5, color: '#188df0'},
                                        {offset: 1, color: '#188df0'}
                                    ]
                                )
                            },
                            emphasis: {
                                itemStyle: {
                                    color: new echarts.graphic.LinearGradient(
                                        0, 0, 0, 1,
                                        [
                                            {offset: 0, color: '#2378f7'},
                                            {offset: 0.7, color: '#2378f7'},
                                            {offset: 1, color: '#83bff6'}
                                        ]
                                    )
                                }
                            },
                            data: ydata
                        }
                    ]
                };
                myChart1.setOption(option);
            }
        }
    }
</script>

<style scoped>

</style>
