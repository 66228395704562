<template>
    <div class="site-wrapper">
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div class="addActBtCategory">
                    <div class="catleft">
                        <span class="title-left">部署管理</span>
                    </div>
                    <div style="display: flex;justify-content: flex-end;z-index: 999999999;">
                        <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                        </el-button>
                        <el-button type="danger" title="如果此部署规则有实例正在使用则删除时抛出异常" @click="deleteOne"><i
                                class="el-icon-delete"></i><span>普通删除</span>
                        </el-button>
                        <el-button type="danger" title="删除流程相关所有历史数据、包括流程实例、流程定义" @click="deleteAll"><i
                                class="el-icon-delete"></i><span>级联删除</span>
                        </el-button>
                    </div>
                </div>

                <!-- 列表 -->
                <div>
                    <el-table :data="tableData" ref="multipleTableRef" border highlight-current-row size="large"
                        style="height: 100%" @select="selectTable"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                        <template>
                            <el-empty :image-size="100" description="暂无数据"></el-empty>
                        </template>
                        <el-table-column type="selection" width="55" />
                        <el-table-column prop="name" label="模型名称" width="250">
                            <template #default="scope">
                                <el-tag>{{ scope.row.name }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="modelCategory" label="模型类别" width="180" />
                        <el-table-column prop="deploymentId" label="部署编号" width="350" />
                        <el-table-column prop="tenantId" label="所属单位" width="220">
                            <template #default="scope">
                                {{ scope.row.tenantId.split("_")[1] }}
                            </template>
                        </el-table-column>
                        <el-table-column prop="categoryStatus" label="部署状态" width="110">
                            <template #default="scope">
                                <el-tag :type="scope.row.modelVersion != null ? 'success' : 'danger'">
                                    {{ scope.row.modelVersion != null ? "最新版本" : "旧版本" }}
                                </el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deploymentTime" label="部署时间" width="210">
                            <template #default="scope">
                                <div style="display: flex; align-items: center">
                                    <el-icon>
                                        <timer />
                                    </el-icon>
                                    <span style="margin-left: 10px">{{
                                        times.convertUTCTimeToLocalTime(scope.row.deploymentTime)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-button size="small" @click="getInfo(scope.row)">预览</el-button>
                                <el-button size="small" type="success" plain @click="upload(scope.row)">下载</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                        <el-pagination background @current-change="handleChangePageNum" @size-change="handleChangePageSize"
                            layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" title="流程预览" width="50%">
            <p v-html="dataSvg"></p>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "./proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                modelName: "",
                pageNum: 1,
                pageSize: 10,
                modelCategory: "",
            },
            // 初始化列表
            tableData: [],
            total: 0,
            multipleTableRef: '',
            selectId: '',
            selectName: '',
            dialogVisible: false,
            dataSvg: "",
            // 初始化多选复选框
        }
    },
    methods: {
        async getModel() {
            const { data: res } = await this.$http2.post('/extension/act-re-deployment/query/ProcessDeploymentPageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
        selectTable(selection, row) {
            console.log(selection);
            this.selectId = row.deploymentId;
            this.selectName = row.name;
            this.multipleTableRef.clearSelection();
            if (selection.length == 0) {
                this.selectId = "";
                this.selectName = "";
                return;
            }
            this.multipleTableRef.toggleRowSelection(row, true);
        },
        async deleteOne() {
            if (this.selectId) {
                this.$confirm('此操作将普通删除模型【' + this.selectName + '】, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const { data: res } = await this.$http2.delete('/extension/act-re-deployment/delete/ProcessDeploymentByDeploymentId/' + this.selectId)
                    if (res.businessCode == 5000) {
                        this.$message.success('删除成功！')
                        this.getModel();
                    } else {
                        this.$message.error('删除失败！')
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                this.$message({
                    message: "请选择一条操作记录！",
                    type: "warning",
                });
            }
        },
        async deleteAll() {
            if (this.selectId) {
                this.$confirm('此操作将级联删除模型【' + this.selectName + '】(删除流程相关所有历史数据、包括流程实例、流程定义), 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async () => {
                    const { data: res } = await this.$http2.delete('/extension/act-re-deployment/delete/ProcessDeploymentCascadeByDeploymentId/' + this.selectId)
                    if (res.businessCode == 5000) {
                        this.$message.success('删除成功！')
                        this.getModel();
                    } else {
                        this.$message.error('删除失败！')
                    }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                });
            } else {
                ElMessage({
                    message: "请选择一条操作记录！",
                    type: "warning",
                });
            }
        },
        async getInfo(row) {
            const { data: res } = await this.$http2.get('/extension/act-re-deployment/query/ProcessModelSvgImage/' + row.deploymentId)
            this.dataSvg = res;
            this.dialogVisible = true;
        },
        async upload(row) {
            const { data: res } = await this.$http2.get('/extension/act-re-deployment/query/ProcessModelSvgImage/' + row.deploymentId)
            const dataTrack = "svg";
            const a = document.createElement("a");
            const name = row.name + `.${dataTrack}`;
            a.setAttribute(
                "href",
                `data:image/svg+xml;charset=UTF-8,${encodeURIComponent(res)}`
            );
            a.setAttribute("target", "_blank");
            a.setAttribute("dataTrack", `diagram:download-${dataTrack}`);
            a.setAttribute("download", name);
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        },
        resetCondition() {
            this.queryPage = {
                modelName: "",
                pageNum: 1,
                pageSize: 10,
                modelCategory: "",
            }
            this.getModel()
        },
        handleChangePageSize(val) {
            this.queryPage.pageSize = val;
            this.getModel();
        },
        handleChangePageNum(val) {
            this.queryPage.pageNum = val;
            this.getModel();
        },
    },
    components: {
        promeau,
    },
    mounted() {
        this.getModel();
    },
}

</script>

<style scoped lang="less">

.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;
    }

    .addActBtCategory {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .catleft {
            margin-top: 10px;

            .title-left {
                font-size: 18px;
                font-weight: 700;
                padding-left: 10px;
                width: 140px;
                height: 22px;
                border-left: 5px solid #569ee3;
                text-align: left;
            }
        }
    }

}
</style>