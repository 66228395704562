<template>
  <div id="asiderBar">
    <el-menu
      class="el-menu-vertical-demo"
      text-color="#000"
      active-text-color="#fff"
      :collapse="isZhank"
      :collapse-transition="false"
      :router="true"
      :default-active="$route.path"
    >
      <el-menu-item index="/cost">
        <i class="el-icon-user"></i>
        <span>成本管理</span>
      </el-menu-item>
    </el-menu>

  </div>
</template>

<script>
  export default {
    name: 'costLeft',
    data(){
      return {
        isZhank: false,
        shiList: [],
        menuLevel2:[]
      }
    },
    created () {
    },
    methods: {
      toggleZhankai () {
        this.isZhank = !this.isZhank
      },

    },
  }
</script>

<style lang="less" scoped>

  .toggle-button {
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #eef5ff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
  .el-aside {
    background-color: #333744;
  }

</style>
