<template>
  <div class="bg">
    <div class="content">
      <div class="proInfo">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>项目信息</span>
          </div>
          <div class="crad-body">
            <el-tabs v-model="activeName">
              <el-tab-pane label="基本信息" name="first" id="first">
                <h2>{{ pro.projectName }}</h2>
                <p class="jibenxinxi">
                  项目地址：{{ pro.projectAddress }}<br />
                  项目类型：项目为{{ pro.projectType.typeName }}类项目<br />
                  项目规模：项目建设高度为：{{ pro.buildingHeight }}米 --
                  项目建设总面积为:{{ pro.buildingArea }}平方米<br />
                  投资规模：项目总投资共计{{
                    pro.totalInvestment
                  }}亿元人民币<br />
                  建设期限：{{ pro.planStartDate }}至 {{ pro.planEndDate
                  }}<br />
                  业主单位：{{ pro.ownerUnit }}<br />
                  总包单位：{{ pro.epcUnit }}<br />
                  项目现状：{{ pro.projectStatus }}
                </p></el-tab-pane
              >
              <el-tab-pane label="通知公告" name="second" id="second">
                <div class="text item">
                  <h3>{{ notice.title }}</h3>
                  <p>
                    <el-input
                      type="textarea"
                      :rows="14"
                      v-model="notice.description"
                    ></el-input>
                  </p>
                </div>
                <div class="gonggao">
                  <span
                    v-for="(item, index) in noticeData"
                    :key="index"
                    :class="{ active: current == index + 1 }"
                    @click="changeNotice(index)"
                    >{{ index + 1 }}</span
                  >
                </div>
              </el-tab-pane>
              <el-tab-pane label="项目形象" name="third">
                <div class="text item">
                  <div class="block">
                    <el-carousel height="310px">
                      <el-carousel-item
                        v-for="item in this.pro.files"
                        :key="item.id"
                      >
                        <img :src="item.filePath" />
                      </el-carousel-item>
                    </el-carousel>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
          </div>
        </el-card>
      </div>
      <div id="map" v-show="!bimormap"></div>
      <div id="bim" v-show="bimormap"></div>
      <div class="qiehuan">
        <div
          class="tools toolsMap"
          :class="{ activie: current2 == 2 }"
          @click="map"
        >
          地图
        </div>
        <div
          class="tools toolsBim"
          :class="{ activie: current2 == 1 }"
          @click="bim"
          v-has="'bimshow'"
        >
          BIM
        </div>
      </div>
      <div class="todo">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>个人待办</span>
          </div>
          <div class="crad-body">
            <el-table
              :data="tableData"
              style="width: 100%"
              height="420px"
              :header-cell-style="{ background: 'white', color: 'black' }"
            >
              <el-table-column
                type="index"
                label="序号"
                align="center"
                width="50"
              >
              </el-table-column>
              <el-table-column
                prop="processName"
                label="任务名称"
                align="center"
                :show-overflow-tooltip="true"
                width="130"
              >
              </el-table-column>
              <el-table-column
                prop="selfTaskToDealDto.createTime"
                label="创建时间"
                align="center"
                width="110"
              >
              </el-table-column>
              <el-table-column label="操作" align="center">
                <template v-slot="scope">
                  <el-tag type="primary" @click="daiban" effect="dark">
                    办理
                  </el-tag>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-card>
      </div>
      <div class="process">
        <el-card class="box-card">
          <div slot="header" class="clearfix">
            <span>项目进度</span>
            <span class="gongqi"
              >{{ endtitle }}<span style="">{{ ending }}</span
              >天</span
            >
          </div>
          <div class="crad-body">
            <!-- <div class="time-line-box">
    <div class="swiper-container">
      <ul class="layui-timeline swiper-wrapper">
        <li class="layui-timeline-item swiper-slide" v-for="progressTime in progressTimeList" >
          <div class="layui-timeline-content layui-text" @click="proConClick(progressTime)" style="cursor: pointer">
            <dl>
              <dt class="layui-timeline-title">{{progressTime.milestoneDate}}</dt>
              <dd>{{ progressTime.title }}</dd>
            </dl>
          </div>
        </li>
      </ul>
    </div>
  </div>-->
            <!-- <div class="product-section product-features">
            <div class="product-wrapper">
    <div class="product-body swiper-container">
      <ul class="features-slide swiper-wrapper">
        <li class="features-item swiper-slide" v-for="progressTime in progressTimeList" >
          <h3>{{progressTime.milestoneDate}}</h3>
          <i></i> <a class="features-info">
          <p class="features-info-i"></p>
          <p class="features-info-s"@click="proConClick(progressTime)"  >{{ progressTime.title }} </p>
          </a>
          </li>

      </ul>
    </div>
    <div class="swiper-button-prev"></div>
    <div class="swiper-button-next swiper-button-disabled"></div>
  </div>
  </div>
  </div>-->
            <!--  <div class="line-botton">
                         <div
                                 class="line-botton-one"
                                 v-for="progressTime in progressTimeList"
                                 :key="progressTime.seq"
                                 @click="proConClick(progressTime)"
                         >
                             <div class="line-left"></div>
                             <div class="line-middle"></div>
                             <div class="line-left"></div>
                         </div>
                     </div>-->
            <!-- 线的数据 -->
            <!--  <div class="data-botton">
                         <div v-for="progressTime in progressTimeList"
                                 :key="progressTime.id"
                                 :title="progressTime.id"
                         >
                             {{ progressTime.title }}<br />{{progressTime.milestoneDate}}
                         </div>
                     </div>-->
            <div class="about-history" id="fzlc">
              <div
                class="about-history-list wow zoomIn"
                data-wow-delay=".4s"
                style="
                  visibility: visible;
                  animation-delay: 0.4s;
                  animation-name: zoomIn;
                "
              >
                <div
                  class="flex-viewport"
                  style="overflow: hidden; position: relative"
                >
                  <ul
                    class="slides clearfix list"
                    style="
                      width: 2600%;
                      transition-duration: 0s;
                      transform: translate3d(0px, 0px, 0px);
                    "
                  >
                    <li
                      style="width: 253px; float: left; display: block"
                      v-for="progressTime in progressTimeList"
                    >
                      <div
                        class="item"
                        @click="proConClick(progressTime)"
                        style="cursor: pointer"
                      >
                        <h3>{{ progressTime.milestoneDate }}</h3>
                        <div class="desc">
                          <p>
                            {{ progressTime.title }}
                          </p>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
                <ul class="flex-direction-nav">
                  <li class="flex-nav-prev">
                    <a class="flex-prev" href="#">&lt;</a>
                  </li>
                  <li class="flex-nav-next">
                    <a class="flex-next" href="#">&gt;</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
    <el-dialog
      :title="lcbName"
      :visible.sync="lichengbeiShow"
      width="45%"
      :close-on-click-modal="false"
      center
    >
      <el-result :title="lcbdate" :subTitle="lcbdes">
        <template slot="icon">
          <el-image :src="lcbpic"></el-image>
        </template>
        <template slot="extra">
          <el-button
            type="primary"
            size="medium"
            @click="lichengbeiShow = false"
            >返回</el-button
          >
        </template>
      </el-result>
    </el-dialog>
  </div>
</template>

<script>
//  import allinfo from '../utils/timeline'
// import allinfo from '../utils/jquery_flexslider'
// import Swiper from 'swiper'
import "../assets/css/public.css";
import "../assets/css/time.css";
// import "../assets/css/swiper-3.4.2.min.css";
// import "../assets/css/style.css";
import "../assets/css/ol1.css";
import "../assets/css/ol2.css";
import Map from "ol/Map";
import View from "ol/View";
import TileLayer from "ol/layer/Tile";
import * as control from "ol/control";
import * as olProj from "ol/proj";
import * as olExtent from "ol/extent";
import WMTS from "ol/source/WMTS";
import WMTSTileGrid from "ol/tilegrid/WMTS";
import VectorSource from "ol/source/Vector";
import VectorLayer from "ol/layer/Vector";
import Style from "ol/style/Style";
import GeoJSON from "ol/format/GeoJSON";
import Stroke from "ol/style/Stroke";
import Text from "ol/style/Text";
import Fill from "ol/style/Fill";
import Icon from "ol/style/Icon";
import Point from "ol/geom/Point";
import Polygon from "ol/geom/Polygon";
import Feature from "ol/Feature";
var viewer;
var map;
var gjSource = new VectorSource();
var gjLayer = new VectorLayer({
  source: gjSource,
  style: new Style({
    image: new Icon({
      src: require("../assets/img/marker-cheng.png"),
    }),
  }),
});
var createguojiaStyle = function (feature) {
  return new Style({
    text: new Text({
      //位置
      textAlign: "center",
      //基准线
      textBaseline: "middle",
      //文字样式
      font: "normal 14px 微软雅黑",
      //文本内容
      text: feature.get("name"),
      backgroundStroke: new Stroke({
        color: "#d4ddec",
        width: 2,
      }),
      offsetY: 30,
      backgroundFill: new Fill({
        color: "#d4ddec",
      }),
      padding: [5, 3, 2, 3],
      //文本填充样式（即文字颜色）
      fill: new Fill({
        color: "black",
      }),
    }),
    image: new Icon({
      src: require("../assets/img/homepage_new/项目坐标1.png"),
    }),
  });
};
export default {
  name: "prohome_new",
  data() {
    return {
      activeName: "first",
      pro: {
        projectName: "",
        projectDetails: "",
        projectAddress: "",
        projectStatus: "",
        buildingHeight: "",
        buildingArea: "",
        totalInvestment: "",
        projectType: {
          typeName: "",
        },
        ownerUnit: "",
        epcUnit: "",
      },
      notice: {
        title: "",
        description: "",
      },
      current: 1,
      noticeData: [],
      progressTimeList: [],
      bimormap: false,
      current2: 2,
      tableData: [],
      lichengbeiShow: false,
      lcbName: "",
      lcbdate: "",
      lcbpic: "",
      lcbdes: "",
      endtitle: "",
      ending: 0,
      typeData:[]
    };
  },
  methods: {
    async getProinfo() {
      const { data: res } = await this.$http1.get(
        "/v1.0/project/basicInfo/findById?id=" + this.$root.proId
      );
      if (res.businessCode != "3000") {
        return this.$message.error("查询项目失败！" + res.message);
      }
      this.pro = res.data;
      console.log(this.typeData)
      console.log(this.pro.projectTypeId)
      console.log(this.typeData.find(o => o.id == this.pro.projectTypeId))
      this.pro['projectType']=this.typeData.find(o => o.id == this.pro.projectTypeId)
      var time;
      if (this.pro.actualEndDate) {
        this.endtitle = "项目已完成";
        time = (
          (new Date(this.pro.actualEndDate.replace("-", "/")) - new Date()) /
          (1 * 24 * 60 * 60 * 1000)
        ).toFixed(0);
      } else {
        time = (
          (new Date(this.pro.planEndDate.replace("-", "/")) - new Date()) /
          (1 * 24 * 60 * 60 * 1000)
        ).toFixed(0);
        if (time < 0) {
          this.endtitle = "已过项目计划日期";
        } else {
          this.endtitle = "距离项目计划还剩";
        }
      }
      this.ending = Math.abs(Number(time));
      this.ending = this.ending - 1;
      for (let i in this.pro.files) {
        // const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+this.pro.files[i].filePath)
        // this.pro.files[i].filePath=res2.data
      }
      gjSource.clear();
      var gjFeature = new Feature({
        geometry: new Point([
          Number(this.pro.longitude),
          Number(this.pro.latitude),
        ]), //几何信息
        name: this.pro.projectName,
      });
      map
        .getView()
        .setCenter([Number(this.pro.longitude), Number(this.pro.latitude)]);
      gjFeature.setStyle(createguojiaStyle(gjFeature));
      gjSource.addFeature(gjFeature);
    },
    async getProtype(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
                this.typeData=res.data
            },
    async getNotice() {
      const { data: res } = await this.$http1.get(
        "/v1.0/project/announcement/findAll?projectId=" + this.$root.proId
      );
      if (res.businessCode != "3000") {
        // return this.$message.error("查询通知公告失败！" + res.message);
        return
      }
      if (res.data.length >= 3) {
        this.noticeData = res.data.slice(0, 2);
        this.notice = this.noticeData[0];
      } else if (res.data.length > 0) {
        this.noticeData = res.data;
        this.notice = this.noticeData[0];
      } else {
        this.noticeData = res.data;
        this.notice = [];
      }
    },
    changeNotice(index) {
      this.current = index + 1;
      this.notice = this.noticeData[index];
    },
    async getToDoList() {
      const { data: res } = await this.$http2.get(
        "/v1.0/activiti/process/querySelfTaskByUserIdAndProjectId?projectId=" +
          this.$root.proId
      );
      if (res.businessCode != "3000") {
        return this.$message.error("查询待办列表失败！" + res.message);
      }
      this.tableData = res.data;
    },
    daiban() {
      window.sessionStorage.setItem("current", "/work");
      this.$router.push({ path: "/toDo" });
    },
    async getProcess() {
      const { data: res } = await this.$http6.get(
        "/v1.0/schedule/milestone/findAll?projectId=" + this.$root.proId
      );
      if (res.businessCode != "3000") {
        return this.$message.error(res.message);
      }
      this.progressTimeList = res.data;
      this.showTime();
    },
    async proConClick(list) {
      console.log(list);
      this.lcbName = list.title;
      this.lcbdate = list.milestoneDate;
      this.lcbdes = list.description;
      const { data: res2 } = await this.$http8.get(
        "/v1.0/ossFile/getFileUrl?filePath=" + list.filePath
      );
      this.lcbpic = res2.data;
      this.lichengbeiShow = true;
    },
    async showMap() {
      map = new Map({
        target: "map",
        view: new View({
          center: [85.28838135, 42.209817352],
          zoom: 6,
          projection: "EPSG:4326",
        }),
      });
      var projection = olProj.get("EPSG:4326");
      var projectionExtent = projection.getExtent();
      var size = olExtent.getWidth(projectionExtent) / 256;
      var resolutions = new Array(19);
      var matrixIds = new Array(19);
      for (var z = 0; z < 19; z++) {
        // generate resolutions and matrixIds arrays for this WMTS
        //resolutions[z] = size / Math.pow(2, z);
        matrixIds[z] = z;
      }
      resolutions = [
        1.40625, 0.703125, 0.3515625, 0.17578125, 0.087890625, 0.0439453125,
        0.02197265625, 0.010986328125, 0.0054931640625, 0.00274658203125,
        0.001373291015625, 0.0006866455078125, 0.00034332275390625,
        0.000171661376953125, 0.0000858306884765625, 0.00004291534423828125,
        0.000021457672119140625, 0.000010728836059570312,
        0.000005364418029785156, 0.000002682209014892578,
        0.000001341104507446289,
      ];
      var webKey = "b0f80d188ee3100e0327398d8afb39cd";
      var tdtsldtLayer = new TileLayer({
        opacity: 1,
        source: new WMTS({
          url: "http://t0.tianditu.gov.cn/vec_c/wmts?tk=" + webKey,
          layer: "vec",
          matrixSet: "c",
          format: "tiles",
          style: "default",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: olExtent.getTopLeft(projectionExtent),
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          wrapX: false,
        }),
      });
      var tdtslzjLayer = new TileLayer({
        opacity: 1,
        source: new WMTS({
          url: "http://t0.tianditu.gov.cn/cva_c/wmts?tk=" + webKey,
          layer: "cva",
          matrixSet: "c",
          format: "tiles",
          style: "default",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: olExtent.getTopLeft(projectionExtent),
            extent: [-180.0, -90.0, 180.0, 90.0],
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          wrapX: false,
        }),
      });
      var tdtyxzjLayer = new TileLayer({
        opacity: 1,
        source: new WMTS({
          url: "http://t0.tianditu.gov.cn/cia_c/wmts?tk=" + webKey,
          layer: "cia",
          matrixSet: "c",
          format: "tiles",
          style: "default",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: olExtent.getTopLeft(projectionExtent),
            extent: [-180.0, -90.0, 180.0, 90.0],
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          wrapX: false,
        }),
      });
      var tdtyxLayer = new TileLayer({
        opacity: 1,
        source: new WMTS({
          url: "http://t0.tianditu.gov.cn/img_c/wmts?tk=" + webKey,
          layer: "img",
          matrixSet: "c",
          format: "tiles",
          style: "default",
          projection: projection,
          tileGrid: new WMTSTileGrid({
            origin: olExtent.getTopLeft(projectionExtent),
            extent: [-180.0, -90.0, 180.0, 90.0],
            resolutions: resolutions,
            matrixIds: matrixIds,
          }),
          wrapX: false,
        }),
      });
      map.addLayer(tdtsldtLayer);
      map.addLayer(tdtslzjLayer);
      var xjSource = new VectorSource({
        url: "./xj.json",
        format: new GeoJSON(),
        wrapX: false,
      });
      var xjLayer = new VectorLayer({
        source: xjSource,
        style: new Style({
          stroke: new Stroke({
            //color: '#0033ff',
            color: "rgb(0, 186 ,255)",
            width: 3,
          }),
          fill: new Fill({
            color: "rgba(0, 186 ,255, 0.01)",
          }),
        }),
      });
      /*var proSource = new VectorSource({
                    wrapX: false
                });
                var proLayer = new VectorLayer({
                    source: proSource,
                    style: new Style({
                        stroke: new Stroke({
                            color: 'rgb(222, 126 ,255)',
                            width: 3
                        }),
                        fill: new Fill({
                            color: 'rgba(122, 126 ,155, 0.3)'
                        })
                    })
                });*/
      /* var gjFeature = new Feature({
                    geometry: new Polygon([[[76.15,39],[76.85,39.95],[77.49,39.81],[77.55,38.79],[76.15,39]]]), //几何信息
                });
                proSource.addFeature(gjFeature)*/
      map.addLayer(xjLayer);
      // map.addLayer(proLayer)

      map.addLayer(gjLayer);
    },
    async showBim() {
      var obj = [6378137.0, 6378137.0, 6356752.3142451793];
      // Cesium.Ellipsoid.WGS84=Object.freeze(new Cesium.Ellipsoid(obj[0],obj[1],obj[2]))
      Cesium.Ion.defaultAccessToken =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJqdGkiOiIyYWVkYjZkZS1hNDg5LTQ1MGQtOThlMy02OTJkYmQwOWFiODQiLCJpZCI6NDU4NjAsImlhdCI6MTYxNTUxOTE1MH0.KUGe0o74LmuTSygie8IFrClVKYTtaMoiauHCywm7aG0";

      viewer = new Cesium.Viewer("bim", {
        // infoBox : false,
        // selectionIndicator : false,
        geocoder: false,
        homeButton: false,
        sceneModePicker: false,
        baseLayerPicker: false,
        navigationHelpButton: false,
        animation: false,
        // creditContainer:"credit",
        timeline: false,
        fullscreenButton: false,
        vrButton: false,
      });
      //  viewer.imageryLayers.get(0).show = false;
      viewer._cesiumWidget._creditContainer.style.display = "none";
      viewer.scene.globe.depthTestAgainstTerrain = false;
      var tileset = viewer.scene.primitives.add(
        new Cesium.Cesium3DTileset({
          //  url:'http://localhost:8086/sanwei/duoziduan/tileset.json'
            url: "http://epc.xjbtgis.cn/sanwei/duoziduan/tileset.json",
        })
      );
      tileset.readyPromise
        .then(function (tileset) {
          console.log(tileset);
          var boundingSphere = tileset.boundingSphere;
          viewer.camera.viewBoundingSphere(
            boundingSphere,
            new Cesium.HeadingPitchRange(0.0, -0.5, boundingSphere.radius)
          );
          viewer.camera.lookAtTransform(Cesium.Matrix4.IDENTITY);
          /*viewer.zoomTo(
                        tileset,
                        new Cesium.HeadingPitchRange(
                            0.5,
                            -0.2,
                            tileset.boundingSphere.radius * 4.0
                        )
                    );*/
        })
        .otherwise(function (err) {
          throw err;
        });
      /* viewer.pickEvent.addEventListener(function(feature){
                    console.log(feature)
                })
                viewer.screenSpaceEventHandler.setInputAction(function onMouseMove(movement) {
                    var pickedFeature = viewer.scene.pick(movement.endPosition);
                    console.log(pickedFeature)
                })*/
    },
    bim() {
      this.current2 = 1;
      this.bimormap = true;
    },
    map() {
      this.current2 = 2;
      this.bimormap = false;
    },
    showTime() {
      $(function () {
        $(".about-history-list").flexslider({
          animation: "slide",
          slideshow: false,
          controlNav: false,
          itemWidth: 253,
          itemMargin: 31,
          prevText: "<",
          nextText: ">",
          move: 1,
        });
      });
      /* $(function () {
                    var w = window.innerWidth;
                    var i;
                    $(function () {
                        $(".swiper-container li").each(function (index) {
                            i = index + 1;
                            if (parseInt($(".layui-timeline").css('padding-top')) <= $(this).find('dl').height() && index % 2 != 0) {
                                $(".layui-timeline").css('padding-top', $(this).find('dl').height() + 10);
                            };
                        });
                        var swiper = new Swiper('.swiper-container', {
                            roundLengths: true,
                            slidesPerView: 'auto',
                            freeMode: true
                        });
                        // 跳转到最后一个
                        swiper.slideTo(i, 1000, false);
                    });
                });*/
      /*$(function() {
      var mySwiper = new Swiper(".swiper-container", {
          slidesPerView: 4, //默认是显示4个
          initialSlide: 0, //默认从第几个显示，999是为了让右边没有
          spaceBetween: 0, //间距
          speed: 1000,//速度
          prevButton: ".swiper-button-prev", //左右按钮
          nextButton: ".swiper-button-next"
      })
  })*/
    },
  },
  created() {
    this.getProtype()
    this.getProinfo();
    this.getProcess();
    this.getNotice();
    //this.getToDoList();
  },
  mounted() {
    this.showMap();
    this.showBim();
  },
};
</script>

<style scoped lang="less">
.content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 25px;
  .proInfo {
    border-radius: 15px;
    width: 390px;
    height: 520px;
    /deep/ .el-card__body {
      padding: 0 25px;
    }
    /deep/ .el-tabs__item {
      text-align: center;
      width: 110px;
    }
    #first {
      h2 {
        color: black;
        text-align: center;
      }
      p {
        margin-top: 5px;
        font-size: 14px;
        line-height: 25px;
      }
    }
    #second {
      h3 {
        color: #00d7fe;
        text-align: center;
        margin-top: 7px;
      }
      p {
        margin-top: 5px;
      }
    }
  }
  #map {
    border-radius: 15px;
    width: 860px;
    height: 520px;
    border: 1px solid #a2cafe;
    background-color: white;
  }
  #bim {
    border-radius: 15px;
    width: 860px;
    height: 520px;
    border: 1px solid #a2cafe;
  }
  .qiehuan {
    position: absolute;
    margin-left: 1130px;
    margin-top: 480px;
    z-index: 998;
    width: 140px;
    height: 35px;
  }
  .todo {
    border-radius: 15px;
    width: 390px;
    height: 520px;
    /deep/ .el-table {
      border: 0 !important;
    }
    .el-table::before {
      background-color: #e1e9e8;
    }
    /deep/ .el-card__body {
      padding: 0 0px;
    }
    /deep/ .el-tag {
      cursor: pointer;
    }
  }
  .process {
    border-radius: 15px;
    margin-top: 20px;
    width: 1720px;
    height: 280px;
    .line-botton {
      width: 1620px;
      height: 20px;
      margin: 60px 10px 0 10px;
      // background: #000;
      display: flex;
      justify-content: center;
      align-items: center;
      .line-botton-one {
        width: 20%;
        display: flex;
        justify-content: center;
        align-items: center;
        .line-left {
          width: 48%;
          border-bottom: 5px solid #a2cafe;
          margin-top: 15px;
        }
        .line-middle {
          cursor: pointer;
          width: 30px;
          height: 29px;
          margin-top: 15px;
          border-radius: 50%;
          border: 5px solid #4b88e3;
          background: #4b88e3;
        }
      }
    }
    .data-botton {
      width: 1620px;
      height: 20px;
      margin: 40px 0px 0px 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      div {
        width: 292px;
        height: 50px;
        margin: 0 18px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        overflow: hidden;
        white-space: nowrap;
      }
    }
    /deep/ .el-card__body {
      padding: 0 !important;
    }
  }
  .gonggao {
    background: rgba(20, 34, 69, 0.2);
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    span {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      margin: 0 10px;
      background: #949494;
      border-radius: 50%;
      cursor: pointer;
    }
    .active {
      background: #365dba;
      color: #fff;
    }
  }
  .el-carousel__item img {
    width: 100%;
    height: 100%;
  }
  .tools {
    background-color: #fff;
    width: 45%;
    height: 80%;
    text-align: center;
    border: 2px solid #fff;
    color: #b3b6bc;
    float: left;
    font-size: 15px;
    line-height: 170%;
    cursor: pointer;
  }
  .toolsBim {
    border-radius: 0px 15px 15px 0px;
  }
  .toolsMap {
    border-radius: 15px 0 0 15px;
  }
  .activie {
    border: 1px solid #509df7 !important;
    background-color: #509df7 !important;
    color: #fff !important;
  }
}
.gongqi {
  margin-left: 1270px;
  span {
    color: red;
    font-size: 26px;
    line-height: 16px;
  }
}
.jibenxinxi {
  max-height: 380px;
  overflow-y: auto;
}
</style>
