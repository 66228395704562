<template>
    <div class="site-wrapper">
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div class="addActBtCategory">
                    <div class="catleft">
                        <span class="title-left">历史流程</span>
                    </div>
                    <div style="display: flex;justify-content: flex-end;z-index: 999999999;">
                        <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                        </el-button>
                    </div>
                </div>
                <!-- 列表 -->
                <div>
                    <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                        <template>
                            <el-empty :image-size="100" description="暂无数据"></el-empty>
                        </template>
                        <el-table-column prop="processDefinitionKey" label="流程关键字" width="200">
                            <template #default="scope">
                                <el-tag>{{ scope.row.processDefinitionKey }}</el-tag>
                            </template>
                        </el-table-column>
                        <el-table-column prop="processDefinitionName" label="流程名称" width="200" />
                        <el-table-column prop="startUserId" label="发起者" width="140" />
                        <el-table-column prop="startTime" label="开始时间" width="220">
                            <template #default="scope">
                                <div style="display: flex; align-items: center">
                                    <el-icon>
                                        <timer />
                                    </el-icon>
                                    <span style="margin-left: 10px">{{
                                        times.convertUTCTimeToLocalTime(scope.row.startTime)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="endTime" label="结束时间" width="220">
                            <template #default="scope">
                                <div style="display: flex; align-items: center">
                                    <el-icon>
                                        <timer />
                                    </el-icon>
                                    <span style="margin-left: 10px">{{
                                        times.convertUTCTimeToLocalTime(scope.row.endTime)
                                    }}</span>
                                </div>
                            </template>
                        </el-table-column>
                        <el-table-column prop="deleteReason" label="结束类型" width="100">
                            <template #default="scope">
                                <span v-if="scope.row.deleteReason == null">审批结束</span>
                                <span v-else>删除结束</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作">
                            <template #default="scope">
                                <el-button size="small" :icon="EditPen" @click="prohistory(scope.row)">节点执行历史</el-button>
                                <el-button size="small" type="success" plain :icon="Promotion"
                                    @click="infoHistory(scope.row)">流程节点详细历史</el-button>
                                <el-button size="small" type="warning" plain :icon="CircleCheck"
                                    @click="doHistory(scope.row)">流程实例变量历史</el-button>
                                <el-button size="small" type="danger" plain :icon="Delete"
                                    @click="deleteHistory(scope.row)">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                        <el-pagination background @current-change="handleChangePageNum" @size-change="handleChangePageSize"
                            layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" width="76%" title="节点执行历史">
            <p v-html="dataSvg" style="text-align: center"></p>
            <el-table :data="formData" border size="large" style="height: 60%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                <template>
                    <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column prop="processInstanceId" label="实例编号" width="300">
                    <template #default="scope">
                        <el-tag>{{ scope.row.processInstanceId }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="taskId" label="任务编号" width="250" />
                <el-table-column prop="activityId" label="节点编号" width="140" />
                <el-table-column prop="activityName" label="节点名称" width="100" />
                <el-table-column prop="activityType" label="节点类型" width="100" />
                <el-table-column prop="assignee" label="签收者" width="100">
                    <template #default="scope">
                        <span v-if="scope.row.assignee">{{
                            scope.row.assignee.split("_")[1]
                        }}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="startTime" label="开始时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                times.convertUTCTimeToLocalTime(scope.row.startTime)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="endTime" label="结束时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                times.convertUTCTimeToLocalTime(scope.row.endTime)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <common-pagination :total="innertotal" @sizeChange="handleChangePageSize2"
                @currentChange="handleChangePageNum2"></common-pagination>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible2" width="70%" title="流程节点详详细历史">
            <p v-html="dataSvg2" style="text-align: center"></p>
            <el-table :data="formData2" border size="large" style="height: 60%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                <template>
                    <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column prop="id" label="唯一辨识" width="300">
                    <template #default="scope">
                        <el-tag>{{ scope.row.id }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="processInstanceId" label="实例编号" width="250" />
                <el-table-column prop="executionId" label="路径编号" width="250" />
                <el-table-column prop="detailType" label="事件类型" width="250" />
                <el-table-column prop="taskId" label="任务编号" width="250" />
            </el-table>
            <common-pagination :total="innertotal2" @sizeChange="handleChangePageSize3"
                @currentChange="handleChangePageNum3"></common-pagination>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible2 = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
        <el-dialog :visible.sync="dialogVisible3" width="70%" title="流程实例变量历史">
            <p v-html="dataSvg3" style="text-align: center"></p>
            <el-table :data="formData3" border size="large" style="height: 60%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                <template>
                    <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column prop="processInstanceId" label="实例编号" width="300">
                    <template #default="scope">
                        <el-tag>{{ scope.row.processInstanceId }}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="variableName" label="变量名称" width="250" />
                <el-table-column prop="variableTypeName" label="变量类型" width="100" />
                <el-table-column prop="value" label="变量赋值" width="250" />
                <el-table-column prop="revisionNext" label="变量版本" width="100" />
                <el-table-column prop="createTime" label="操作时间" width="210">
                    <template #default="scope">
                        <div style="display: flex; align-items: center">
                            <el-icon>
                                <timer />
                            </el-icon>
                            <span style="margin-left: 10px">{{
                                times.convertUTCTimeToLocalTime(scope.row.createTime)
                            }}</span>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
            <common-pagination :total="innertotal3" @sizeChange="handleChangePageSize4"
                @currentChange="handleChangePageNum4"></common-pagination>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible3 = false">关 闭</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "./proMeau.vue";
export default {
    data() {
        return {
            queryPage: {
                pageNum: 1,
                pageSize: 10,
                finishedBeforeDate: "",
                processDefinitionKey: "",
                processDefinitionName: "",
                processDefinitionVersion: "",
                processInstanceName: "",
                startUserId: "",
                startedAfterDate: "",
            },
            total: 0,
            tableData: [],
            title: '',
            dialogVisible: false,
            dataSvg: '',
            formData: [],
            innertotal: 0,
            queryPage2: {
                pageNum: 1,
                pageSize: 10,
                processInstanceId: "",
            },
            dialogVisible2: false,
            dataSvg2: '',
            formData2: [],
            innertotal2: 0,
            queryPage3: {
                pageNum: 1,
                pageSize: 10,
                processInstanceId: "",
            },
            dialogVisible3: false,
            dataSvg3: '',
            formData3: '',
            innertotal3: 0,
            queryPage4: {
                pageNum: 1,
                pageSize: 10,
                processInstanceId: "",
            },

        }
    },
    methods: {
        async getModel() {
            const { data: res } = await this.$http2.post('/ActHistoricalInstance/query/ProcessHistoricalInstancePageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
        async prohistory(row) {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/' + row.processInstanceId)
            this.dataSvg = res;
            this.queryPage2.processInstanceId = row.processInstanceId;
            this.getHistory3();
            this.dialogVisible = true;
        },
        async getHistory3() {
            const { data: res } = await this.$http2.get('/ActHistoricalInstance/query/HistoricActivityInstanceByProcessInstanceId/' + this.queryPage2.processInstanceId + '?pageNum=' + this.queryPage2.pageNum + "&pageSize=" + this.queryPage2.pageSize)
            this.formData = res.data.list;
            this.innertotal = res.data.total;
        },
        async infoHistory(row) {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/' + row.processInstanceId)
            this.dataSvg2 = res;
            this.queryPage3.processInstanceId = row.processInstanceId;
            this.getHistory2();
            this.dialogVisible2 = true;
        },
        async getHistory2() {
            const { data: res } = await this.$http2.get('/ActHistoricalInstance/query/HistoricDetailByProcessInstanceId/' + this.queryPage3.processInstanceId + '?pageNum=' + this.queryPage3.pageNum + "&pageSize=" + this.queryPage3.pageSize)
            this.formData2 = res.data.list;
            this.innertotal2 = res.data.total;
        },
        async doHistory(row) {
            const { data: res } = await this.$http2.get('/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/' + row.processInstanceId)
            this.dataSvg3 = res;
            this.queryPage4.processInstanceId = row.processInstanceId;
            this.getHistory();
            this.dialogVisible3 = true;
        },
        async getHistory() {
            const { data: res } = await this.$http2.get('/ActHistoricalInstance/query/HistoricVariableInstanceByProcessInstanceId/' + this.queryPage4.processInstanceId + '?pageNum=' + this.queryPage4.pageNum + "&pageSize=" + this.queryPage4.pageSize)
            this.formData3 = res.data.list;
            this.innertotal3 = res.data.total;
        },
        async deleteHistory() {
            this.$confirm('此操作将删除历史流程【' + row.processDefinitionName + '】, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await this.$http2.delete('/ActHistoricalInstance/delete/HistoricVariableInstance/' + row.id)
                if (res.businessCode == 5000) {
                    this.$message.success('删除成功！')
                    this.getModel();
                } else {
                    this.$message.error('删除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        handleChangePageSize(val) {
             this.queryPage.pageSize = val;
             this.getModel();
        },
        handleChangePageNum(val) {
             this.queryPage.pageNum = val;
             this.getModel();
        },
        handleChangePageSize2(val) {
             this.queryPage2.pageSize = val;
             this.getHistory3();
        },
        handleChangePageNum2(val) {
             this.queryPage2.pageNum = val;
             this.getHistory3();
        },
        handleChangePageSize3(val) {
             this.queryPage3.pageSize = val;
             this.getHistory2();
        },
        handleChangePageNum3(val) {
             this.queryPage3.pageNum = val;
             this.getHistory2();
        },
        handleChangePageSize4(val) {
             this.queryPage4.pageSize = val;
             this.getHistory();
        },
        handleChangePageNum4(val) {
             this.queryPage4.pageNum = val;
             this.getHistory();
        }

    },
    created() {
        this.getModel()
    },
    components: {
        promeau,
    },
    watch: {

    },
    mounted() {
    },
}

</script>

<style scoped lang="less">

.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;

        .addActBtCategory {
            display: flex;
            justify-content: space-between;
            margin-bottom: 6px;

            .catleft {
                margin-top: 10px;

                .title-left {
                    font-size: 18px;
                    font-weight: 700;
                    padding-left: 10px;
                    width: 140px;
                    height: 22px;
                    border-left: 5px solid #569ee3;
                    text-align: left;
                }
            }
        }
    }

}
</style>