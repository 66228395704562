<template>
  <div class="bg">
    <hou-left></hou-left>
     <div class="mainpart">
     <el-card class="box-card"  style="height: 100%;">
      <div slot="header" class="clearfix" style="text-align: right">
        <el-button size="medium" type="goon"  @click="dialogVisible = true" style="" v-has="'addunit'">添加萤石账号</el-button>
      </div>
      <div class="serverTable">
      <el-table
        :data="tableData"
        height="100%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        style="width: 100%;">
        <el-table-column
          type="selection"
        >
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
          width="50"
         >
        </el-table-column>
        <el-table-column
          prop="appKey"
          label="账号KEY"
          min-width="22%"
        >
        </el-table-column>
        <el-table-column
          prop="secret"
          label="账号secret"
          min-width="22%"
        >
        </el-table-column>
        <el-table-column
           prop="platformToken"
          label="平台token"
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          min-width="13%"
        >
          <template v-slot="scope">
            <el-tooltip effect="dark" content="刷新token" placement="top" :enterable="false" >
              <el-button type="primary" icon="el-icon-refresh" size="mini" @click="shuaxin" ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="更新信息" placement="top" :enterable="false">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除信息" placement="top" :enterable="false">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
      </div>
    </el-card>
    <el-dialog
      title="添加萤石账号"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addFormRule">
        <el-form-item label="账号KEY" prop="appKey">
          <el-input size="medium" v-model="addForm.appKey"></el-input>
        </el-form-item>
        <el-form-item label="账号secret" prop="secret">
          <el-input size="medium" v-model="addForm.secret"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button size="medium" type="goon" round @click="dialogVisible = false">取 消</el-button>
    <el-button size="medium" type="goon" round @click="addYingshi" >确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="修改萤石账号"
      :visible.sync="editdialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="editdialogClose"
    >
      <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="addFormRule">
        <el-form-item label="账号KEY" prop="appKey">
          <el-input size="medium" v-model="editForm.appKey"></el-input>
        </el-form-item>
        <el-form-item label="账号secret" prop="secret">
          <el-input size="medium" v-model="editForm.secret"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="goon" size="medium" round @click="editdialogVisible = false">取 消</el-button>
    <el-button type="goon" size="medium" round @click="editBumenInfo">确 定</el-button>
  </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
  import houLeft from "./houTaiLeft";
  export default {
    name: 'buMenList',
    data(){
      return{
        value:[],
        city:null,
        tableData:[],
        dialogVisible:false,
        addForm:{
          appKey:'',
          secret:'',
          projectId:this.$root.proId,
        },
        addFormRule:{
          appKey:[{required:true,message:'请输入账号KEY',trigger:'blur'}],
          secret:[{required:true,message:'请选择账号secret',trigger:'blur'}],
        },
        editdialogVisible:false,
        editForm:{
        },
        optionProps:{
          checkStrictly: true,
          value: 'id',
          label: 'name',
          children: 'children'
        },
      }
    },
    created () {
      this.getYingshiList();
    },
    methods:{
      async getYingshiList(){
        this.tableData=[]
        const {data:res}=await  this.$http4.get('/v1.0/monitor/device/token/findDetailByProjectId?projectId='+this.$root.proId)
        if(res.status!=="3000"){
           this.$message.error('获取token列表失败！' +res.message);
           return this.tableData=[];
        }
        this.tableData.push(res.data)
      },
      async addYingshi(){
        this.$refs.addFormRef.validate( async valid =>{
          if(!valid)
            return
          const {data:res}= await this.$http4.post('/v1.0/monitor/device/token/save', this.addForm)
          if(res.status!=="6000"){
            return  this.$message.error('添加监控token失败！')
          }
          this.$message.success('添加监控token成功！');
          this.getYingshiList();
          this.dialogVisible = false;
        })
      },
      dialogClose(){
        this.$refs.addFormRef.resetFields();
      },
      async editDialog(){
        //请求查询
        const {data:res}=await  this.$http4.get('/v1.0/monitor/device/token/findDetailByProjectId?projectId='+this.$root.proId)
        if(res.status!=="3000"){
          return 0;
        }
        this.editForm=res.data;
        this.editdialogVisible = true
      },
      editdialogClose(){
        this.$refs.editFormRef.resetFields()
      },
      editBumenInfo(){
        this.$refs.editFormRef.validate(async valid =>{
          if(!valid) return
          const {data:res}= await this.$http4.post('/v1.0/monitor/device/token/update', this.editForm)
          if(res.status!=="6000"){
            return  this.$message.error('修改平台Token失败！')
          }
          this.$message.success('修改平台Token成功！');
          this.editdialogVisible = false;
          this.getYingshiList();
        })
      },
      async deleteMsg(id){
        const confirmResult = await this.$confirm('此操作将永久删除该平台Token, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(confirmResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
        //请求
        const {data:res}= await this.$http4.delete('/v1.0/monitor/device/token/delete/'+id)
        if(res.status!="4000"){
          return  this.$message.error('删除平台Token失败！')
        }
        this.$message.success('删除平台Token成功！');
        this.getYingshiList();
      },
      async shuaxin(){
        const {data:res}= await this.$http4.get('/v1.0/monitor/device/token/getToken?projectId='+this.$root.proId)
        if(res.status!="6010"){
          return  this.$message.error('更新平台Token失败！')
        }
        this.$message.success('更新平台Token成功！');
        this.getYingshiList();
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },

    },
    components:{
      houLeft
    }
  }
</script>

<style scoped lang="less">


</style>
