<template>
  <div class="bg">
    <div class="mainpart">
      <el-card class="box-card" style="height: 100%">
        <div slot="header" class="clearfix">
          <el-button size="medium" type="goon" @click="dialogFormVisible = true"
            >创建资源</el-button
          >

          <span style="" class="fanhui" type="text" @click="goback">返回</span>
        </div>

        <div class="serverTable">
          <el-table
            :data="tableData"
            height="100%"
            :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            style="width: 100%"
          >
            <el-table-column type="index" label="序号" width="70">
              <template v-slot="scope">
            {{ (pageFrom.pageNum-1) * pageFrom.pageSize+ scope.$index + 1}}
          </template>
            </el-table-column>
            <el-table-column prop="resourcesName" label="资源名称" width="500">
            </el-table-column>
            <el-table-column prop="resourcesPath" label="资源路径" width="500">
            </el-table-column>
            <el-table-column prop="description" label="资源描述" width="200">
            </el-table-column>
            <el-table-column prop="menu.menuName" label="所属菜单" width="180">
            </el-table-column>
            <el-table-column prop="enabled" label="启用状态" width="100">
              <template slot-scope="scope">
                <el-tag type="success" v-if="scope.row.enabled==1">启用中</el-tag>
                <el-tag type="info" v-else>禁用中</el-tag>
              </template>
            </el-table-column>
            <el-table-column label="操作">
              <template slot-scope="scope">
                <el-button
                  @click="editRes(scope.row)"
                  type="text"
                  icon="el-icon-edit"
                  class="caozuo"
                  >编辑</el-button
                >
                <el-button
                  type="text"
                  style="color: red !important"
                  class="caozuo"
                  icon="el-icon-delete"
                  @click="deleteRes(scope.row)"
                  >删除</el-button
                >
              </template>
            </el-table-column>
          </el-table>
          <el-pagination
            background
            layout="total, sizes, prev, pager, next, jumper"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="pageFrom.pageNum"
            :page-sizes="[10, 20, 50, 100]"
            :page-size="10"
            :total="total"
          >
          </el-pagination>
        </div>
      </el-card>
      <el-dialog
        :title="restitle"
        :visible.sync="dialogFormVisible"
        width="30%"
      >
        <el-form :model="resouceform">
          <el-form-item label="资源名称" :label-width="formLabelWidth2">
            <el-input
              v-model="resouceform.resourcesName"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源路径" :label-width="formLabelWidth2">
            <el-input
              v-model="resouceform.resourcesPath"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="关联菜单" :label-width="formLabelWidth2">
            <el-cascader
              v-model="resouceform.menu.menuId"
              :options="resMeauData"
              :props="propsRes"
              @change="handleChange"
            ></el-cascader>
          </el-form-item>
          <el-form-item label="按钮权限" :label-width="formLabelWidth2">
            <el-input
              v-model="resouceform.buttonPermissions"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源描述" :label-width="formLabelWidth2">
            <el-input
              v-model="resouceform.description"
              autocomplete="off"
            ></el-input>
          </el-form-item>
          <el-form-item label="资源状态" :label-width="formLabelWidth2">
            <el-radio v-model="resouceform.enabled" :label="1">启用</el-radio>
            <el-radio v-model="resouceform.enabled" :label="2">禁用</el-radio>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="addResouceSure">确 定</el-button>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: "danweiList",
  data() {
    return {
      restitle: "新增资源信息",
      formLabelWidth2: "80px",
      tableData: [],
      total: 0,
      pageFrom: {
        pageNum: 1,
        pageSize: 10,
        resourcesName: "",
        enabled: "",
        description: "",
        menuId: "",
      },
      resouceform: {
        resourcesName: "",
        resourcesPath: "",
        menu: {
          menuId: "",
          menuName: "",
        },
        buttonPermissions: "",
        description: "",
        enabled: 0,
      },
      resMeauData: [],
      dialogFormVisible: false,
      propsRes: {},
    };
  },
  created() {
    this.getResourceList();
    this.getMeauList();
  },
  methods: {
    async getResourceList() {
      const { data: res } = await this.$http0.post(
        "/v1/account/resource/query/findPageList",
        this.pageFrom
      );
      if (res.businessCode !== 3000) {
        this.$message.error("获取资源列表失败！" + res.message);
        return (this.tableData = []);
      }
      this.tableData = res.data.list;
      this.total = res.data.total;
    },
    async getMeauList() {
      const { data: res } = await this.$http0.get(
        "/v1/account/menu/query/findMenuTree"
      );
      this.resMeauData = res.data;
      this.getResMeau(this.resMeauData);
    },
    getResMeau(arr) {
      for (let i in arr) {
        arr[i].label = arr[i].parent.name;
        arr[i].value = arr[i].parent.menuId;
        if (arr[i].children.length !== 0) {
          this.getResMeau(arr[i].children);
        } else {
          arr[i].children = null;
        }
      }
    },
    addResouce() {
      this.restitle = "新增资源信息";
      this.dialogFormVisible = true;
    },
    async addResouceSure() {
      console.log(this.resouceform);
      if (this.restitle == "新增资源信息") {
        const { data: res } = await this.$http0.post(
          "/v1/account/resource/insert",
          this.resouceform
        );
        if (res.businessCode == 4000) {
          this.$message.success("保存成功！");
          this.getResourceList();
          this.dialogFormVisible = false;
        }
      } else {
        const { data: res } = await this.$http0.put(
          "/v1/account/resource/update",
          this.resouceform
        );
        if (res.businessCode == 4300) {
          this.$message.success("修改成功！");
          this.getResourceList();
          this.dialogFormVisible = false;
        }
      }
    },
    editRes(row) {
      this.restitle = "修改资源信息";
      this.resouceform = JSON.parse(JSON.stringify(row));
      this.dialogFormVisible = true;
    },
    async deleteRes(row) {
      const confirmResult = await this.$confirm('此操作将永久删除该资源, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(confirmResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
      const { data: res } = await this.$http0.delete(
        "/v1/account/resource/delete?id=" + row.resourcesId
      );
      if (res.businessCode == 5000) {
        this.$message.success("删除成功！");
        this.getResourceList();
      }
    },
    handleSizeChange(val) {
      this.pageFrom.pageSize = val;
      this.getResourceList();
    },
    handleCurrentChange(val) {
      this.pageFrom.pageNum = val;
      this.getResourceList();
    },
    handleChange(value) {
      this.resouceform.menu.menuId = value[value.length - 1];
    },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },
};
</script>

<style scoped lang="less">
/*#danweiList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }*/

.mainpart {
  left: 10px;
  top: 70px;
  bottom: 10px;
  right: 10px;
  width: 1900px;
}

.fanhui {
  float: right;
  padding: 0;
  border: 0 !important;
  color: black !important;
  margin-top: 10px;
  cursor: pointer;
}

/deep/ .cell {
  max-height: 70px;
  overflow-y: auto !important;
}

.caozuo {
  background: transparent !important;
}

.caozuo:hover {
  color: rgb(97, 111, 240) !important;
}
</style>
