<template>
  <div id="asiderBar">
      <el-menu
              class="el-menu-vertical-demo"
              text-color="black"
              active-text-color="#fff"
              :collapse="isZhank"
              :collapse-transition="false"
              :router="true"
              :default-active="$route.path"
      >
          <el-menu-item v-for="item in menuLevel2" :index="item.path" :key="item.path">
              <i :class="item.iconCls"></i>
              <span>{{item.name}}</span>
          </el-menu-item>
      </el-menu>

  </div>
</template>

<script>
  export default {
      name: 'processLeft',
      data(){
          return {
              isZhank: false,
              shiList: [],
              menuLevel2:[]
          }
      },
      created () {
          this.menuLevel2=JSON.parse(window.sessionStorage.getItem('menulevel2'))[2];
          console.log(this.menuLevel2)
      },
      methods: {
          toggleZhankai () {
              this.isZhank = !this.isZhank
          },

      },
  }
</script>

<style lang="less" scoped>


  .toggle-button {
      background-color: #4a5064;
      font-size: 10px;
      line-height: 24px;
      color: #eef5ff;
      text-align: center;
      letter-spacing: 0.2em;
      cursor: pointer;
  }
  .el-aside {
      background-color: #333744;
  }

</style>
