<template>
  <div>
    <table class="formtable">
      <tr>
        <td class="td120">项目名称</td>
        <td class="td150">
          <el-input v-model="addFrom.projectSerialName"></el-input>
        </td>
        <td class="td120">项目编号</td>
        <td class="td220">
          <el-input v-model="addFrom.projectSerialNum"></el-input>
        </td>
      </tr>
      <tr>
        <td class="td120">任命生效日期</td>
        <td class="td150">
          <el-date-picker
            v-model="addFrom.appointmentDate"
            type="date"
          ></el-date-picker>
        </td>
        <td class="td120">项目类型</td>
        <td class="td220">
          <el-input v-model="addFrom.projectTypeName"></el-input>
        </td>
      </tr>
      <tr>
        <td colspan="4" rowspan="10">
          <p>
            <br />
            &emsp; &emsp;经公司研究决定，聘任<el-input
              v-model="addFrom.projectManagerName"
              class="tonghang"
            ></el-input
            >为“<el-input
              v-model="addFrom.projectSerialName"
              class="tonghang"
            ></el-input>
            ”项目的项目经理，全权负责该工程项目的组织协调与管理控制等工作，保障该项目工程各项预期目标的实现，具体权责见公司相关规定。<br />
            &emsp; &emsp;以上人员聘任自<el-date-picker
              v-model="addFrom.appointmentDate"
              type="date"
            ></el-date-picker>
            日起至项目结束止。<br />
            &emsp; &emsp;特此任命<br /><br /><br /><br /><br />
          </p>
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td colspan="4" rowspan="5">
          <p>
            工程总承包分公司负责人意见：
            &emsp;&emsp;<el-input
            type="textarea"
            :rows="5"
            v-model="addFrom.inChargeOpinion"
            class="inputleft"
            ></el-input
            >
            <span style="margin-left: 300px"
              >签字：<el-input v-model="addFrom.xx" class="tonghang2"></el-input
              >日期：<el-date-picker
                v-model="addFrom.dd"
                type="date"
              ></el-date-picker
            ></span>
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    someData: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdd: {
      type: Boolean,
    },
  },
  name: "",
  data() {
    return {
      addFrom: {
        projectSerialName: "",
        projectManagerId: "2",
        projectSerialNum: "",
        projectId: "",
        appointmentDate: "",
        projectManagerName: "",
        projectTypeName: "",
        inChargeOpinion: "",
        xx: "",
        dd: "",
      },
    };
  },
  mounted() {
    if (!this.isAdd) {
      this.addFrom = this.someData;
    }
    (this.addFrom.projectSerialName = window.sessionStorage.getItem("proName")),
      (this.addFrom.projectId = window.sessionStorage.getItem("proId"));
    // this.input=this.someData.input
    // this.input1=this.someData.input1
  },
  methods: {},
  components: {},
  watch: {
    someData(newVal, oldVal) {
      //对引用类型的值无效
      this.addFrom = newVal;
      (this.addFrom.projectSerialName =
        window.sessionStorage.getItem("proName")),
        (this.addFrom.projectId = window.sessionStorage.getItem("proId"));
    },
  },
};
</script>

<style scoped lang="less">
.formtable {
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  border-collapse: collapse;
  border-spacing: 0;
  tr td {
    line-height: 30px;
    font-size: 16px;
    padding: 0 4px;
    border: 1px solid #000;
    //border-width: 1px 0 0 1px;
  }
  .td120 {
    width: 120px;
  }
  .td150 {
    width: 150px;
  }
  .td220 {
    width: 220px;
  }
}
/deep/ .el-input__inner {
  padding: 0 !important;
  border: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: center;
}
.inputleft{
  text-align: left;
}
.tonghang {
  display: inline-block;
  width: 100px;
  height: 30px !important;
  border-bottom: 1px solid #000;
}
.tonghang2 {
  display: inline-block;
  width: 100px;
  height: 30px !important;
}
.tonghang3 {
  display: inline-block;
  width: 200px;
  height: 30px !important;
}
.td30 {
  width: 30px;
}
/deep/ .el-date-editor {
  padding-left: 25px;
  width: 100px !important;
}
/deep/ .el-input__prefix {
  top: -5px !important;
}
</style>
