<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入月度计划会议名查询" v-model="queryInfo.annual" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="15">
                            <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">添加月度会议计划</el-button>

                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="annual"
                                label="计划名称"
                                width="300"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="conventional"
                                label="常规会议次数"
                                width="160"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="safetyBoard"
                                label="安委会次数"
                                width="160"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="yearAnnual"
                                label="计划年度"
                                sortable
                                width="160"
                        >
                            <template v-slot="scope">
                                <span>{{scope.row.yearAnnual.substring(0,scope.row.yearAnnual.indexOf('-'))}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                label="创建时间"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="deleteMsg(scope.row.meetingPlanId)" icon="el-icon-document-add" size="mini">新增月度计划</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.meetingPlanId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增年度计划"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="addRules">
                <el-form-item label="年度计划名称" prop="annual">
                    <el-input v-model="addForm.annual"></el-input>
                </el-form-item>
                <el-form-item label="常规会议次数" prop="conventional">
                    <el-input v-model="addForm.conventional"></el-input>
                </el-form-item>
                <el-form-item label="安委会次数" prop="safetyBoard">
                    <el-input v-model="addForm.safetyBoard"></el-input>
                </el-form-item>
                <el-form-item label="计划年度" prop="yearAnnual">
                    <el-date-picker
                            v-model="addForm.yearAnnual"
                            type="year"
                            placeholder="选择计划年度">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>

    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "notice",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    annual:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    departmentId:"",
                },
                total:0,
                dialogVisible:false,
                addForm:{
                    annual:"",
                    conventional:'',
                    safetyBoard:'',
                    yearAnnual:'',
                    projectId:this.$root.proId,

                },
                yonghu:[],
                props: {
                    multiple: true,
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                fileList:[],
                addRules:{

                }
            }
        },
        created(){
            this.getFiles()
            this.getUsers()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/plan/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询项目会议失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addFile(){
                const {data:res}= await this.$http14.post('/v1.0/security/meeting/plan/save',this.addForm)
                if(res.status!='6000'){
                    return this.$message.error('上传年度会议计划失败！'+res.message)
                }
                this.$message.success('上传年度会议计划成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该年度会议计划, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/meeting/plan/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除年度会议计划失败！'+res.message)
                }
                this.$message.success('删除年度会议计划成功！');
                this.getFiles();
            },
            async showfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getFiles()
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">


</style>
