<template>
    <div class="bg">
        <pro-left></pro-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                    <el-button   @click="addrizhi"  v-has="'addrizhi'">新增施工日志</el-button>
                    <el-button   @click="exportrizhi" >导出施工日志</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="content"
                                label="施工内容"
                                width="230"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="diaryDate"
                                label="施工日期"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="location"
                                label="施工地点"
                                width="120"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="projectTeam"
                                label="所属项目组"
                                width="110"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="situation"
                                label="施工整体情况"
                                width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="files"
                                label="施工文件"
                                width="220"
                        >
                            <template v-slot="scope">
                                <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files" target="_blank" @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="createUserName"
                                label="创建人"
                                align="center"
                                width="80"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createDate"
                                label="创建时间"
                                align="center"
                                width="100"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini" v-has="'editrizhi'">修改</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini" v-has="'delrizhi'">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增施工日志"
                :visible.sync="dialogVisible"
                width="45%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
             <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="editFormRules" >
                <el-form-item label="内容" prop="content">
                    <el-input   type="textarea" :rows="3" v-model="addForm.content"></el-input>
                </el-form-item>
                <el-form-item label="施工日期" prop="diaryDate">
                    <el-date-picker
                            v-model="addForm.diaryDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择施工日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="施工地点" prop="location">
                    <el-input v-model="addForm.location"></el-input>
                </el-form-item>
                <el-form-item label="所属项目组" prop="projectTeam">
                    <el-input v-model="addForm.projectTeam"></el-input>
                </el-form-item>
                <el-form-item label="施工整体情况" prop="situation">
                    <el-input   type="textarea" :rows="3" v-model="addForm.situation"></el-input>
                </el-form-item>
                <el-form-item label="上传施工文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form> 
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addprooDiary" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改施工日志"
                :visible.sync="editdialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="100px" :rules="editFormRules">
                <el-form-item label="内容" prop="content">
                    <el-input   type="textarea" :rows="3" v-model="editForm.content"></el-input>
                </el-form-item>
                <el-form-item label="施工日期" prop="diaryDate">
                    <el-date-picker
                            v-model="editForm.diaryDate"
                            type="date"
                            value-format="yyyy-MM-dd"
                            placeholder="选择施工日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="施工地点" prop="location">
                    <el-input v-model="editForm.location"></el-input>
                </el-form-item>
                <el-form-item label="所属项目组" prop="projectTeam">
                    <el-input v-model="editForm.projectTeam"></el-input>
                </el-form-item>
                <el-form-item label="施工整体情况" prop="situation">
                    <el-input   type="textarea" :rows="3" v-model="editForm.situation"></el-input>
                </el-form-item>
                <el-form-item label="上传施工文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit"
                            :on-change="filechangeEdit"
                            :file-list="fileListEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditProDiary">确 定</el-button>
  </span>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
    </div>
</template>

<script>
    import proLeft from './proInfoLeft'
    export default {
        name: "proshigong",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                proshow:false,
                dialogVisible:false,
                editdialogVisible:false,
                addForm:{
                    content :'',
                    projectTeam :'',
                    diaryDate :'',
                    location:'',
                    situation:'',
                    projectId:this.$root.proId,
                },
                editForm:{},
                editFormRules:{
                    situation:[{required:true,message:'请输入施工整体情况',trigger:'blur'}],
                    content:[{required:true,message:'请输入施工内容',trigger:'blur'}],
                    diaryDate:[{required:true,message:'请输入施工日期',trigger:'blur'}],
                    projectTeam:[{required:true,message:'请输入所属项目组',trigger:'blur'}],
                    location:[{required:true,message:'请输入施工地点',trigger:'blur'}],
                },
                fileList:[],
                fileListEdit:[],
                fileListEditDeleteID:[],
                fileListEditAdd:[],
                // someData:{
                //     input:'测试',
                //     input1:'2020',
                // }
            }
        },
        created(){
            this.getProDiary()
        },
        methods:{
            async getProDiary(){
                const {data:res}= await this.$http1.get('/v1.0/project/diary/findAll?projectId='+this.$root.proId)
                if(res.businessCode!='3000'){
                    return this.$message.error('查询项目施工日志失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async addrizhi(){
                this.fileList=[]
                this.dialogVisible = true
            },
            async addprooDiary(){
                this.proshow=true
                var formData=new FormData();
                for(let x in this.fileList){
                    formData.append("multipartFiles",this.fileList[x].raw);
                }
                for (let key in this.addForm) {
                    formData.append(key,this.addForm[key]);
                }
                const {data:res}= await this.$http1.post('/v1.0/project/diary/save',formData)
                if(res.businessCode!='6000'){
                    return this.$message.error('添加项目施工日志失败！'+res.message)
                }
                this.$message.success('添加项目施工日志成功！');
                this.dialogVisible=false;
                this.getProDiary();
                this.proshow=false
            },
            async exportrizhi(){
                const {data:res}= await this.$http1.get('/v1.0/project/diary/findAll/export?projectId='+this.$root.proId,{responseType: 'blob'})
                console.log(res)
                let blob = res;
                const url = window.URL.createObjectURL(blob); //设置路径
                const link = window.document.createElement("a"); // 创建a标签
                link.href = url;
                link.setAttribute("download", '项目施工日志.xlsx'); // 给下载后的文件命名
                link.style.display = "none";
                link.click();
                URL.revokeObjectURL(url); // 释放内存
            },
            async fileupload(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)
            },
            async editDialog(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/diary/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return 0;
                }
                this.editForm=res.data;
                this.fileListEdit=[];
                for(let i in res.data.files){
                    this.fileListEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8807/'+res.data.files[i].filePath})
                }
                this.editdialogVisible=true
            },
            async iseditProDiary(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    this.proshow=true
                    const {data:res}= await this.$http1.put('/v1.0/project/diary/update', this.editForm)
                    if(res.businessCode!='6010'){
                        return this.$message.error('修改施工日志失败！'+res.message)
                    }
                    for(let i in this.fileListEditDeleteID){
                        const {data:res2}= await this.$http1.delete('/v1.0/project/diary/deleteDiary/'+this.fileListEditDeleteID[i])
                        if(res2.businessCode!='4000'){
                            return this.$message.error('删除施工日志文件失败！'+res2.message)
                        }
                    }
                    this.fileListEditDeleteID=[]
                    if(this.fileListEditAdd.length>0) {
                        for (let j in this.fileListEditAdd) {
                            var formData2 = new FormData();
                            console.log( this.fileListEditAdd[j].raw);
                            formData2.append("multipartFile", this.fileListEditAdd[j].raw);
                            formData2.append("diaryId", this.editForm.id)
                            const {data: res3} = await this.$http1.post('/v1.0/project/diary/saveDiary', formData2)
                            if (res3.businessCode != '6000') {
                                return this.$message.error('添加施工日志文件失败！' + res3.message)
                            }
                        }
                    }
                    this.fileListEditAdd=[]
                    this.$message.success('修改施工日志成功！');
                    this.getProDiary()
                    this.editdialogVisible=false;
                    this.proshow=false
                })
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目施工日志, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/diary/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除项目施工日志失败！'+res.message)
                }
                this.$message.success('删除项目施工日志成功！');
                this.getProDiary();
            },
            handleRemove(file, fileList) {
                this.fileList=fileList
            },
            filechange(file,fileList){
                this.fileList=fileList
            },
            handleRemoveEdit(file, fileList) {
                if(file.id){
                    this.fileListEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListEditAdd.length; i++) {
                        if (this.fileListEditAdd[i] == file) {
                            this.fileListEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            filechangeEdit(file,fileList){
                this.fileListEditAdd.push(file)
                this.fileListEdit=fileList
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
        },
        components:{
            proLeft,
        }
    }
</script>

<style scoped lang="less">
    /deep/ .cell{
        max-height: 90px;
        overflow-y: auto !important;
    }
</style>
