<template>
    <div class="bg">
        <kaoqing-left></kaoqing-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="clearfix">
                    <span>团队月度考勤</span>
                    <el-date-picker
                            v-model="selectdate"
                            type="month"
                            popper-class="popperClass"
                            @change="datechange"
                            placeholder="选择月">
                    </el-date-picker>
                </div>
                <div class="text item tongji" style="">
                 <!--   <div class="shidao" @click="qingjia">
                        <p class="yd">休假</p>
                        <p class="ydr">{{count.xiujia}}</p>
                    </div>-->
                    <div class="zhengc" >
                        <p class="yd">正常</p>
                        <p class="ydr">{{count.normalCount}}</p>
                    </div>
                    <div class="queqin" >
                        <p class="yd">旷工</p>
                        <p class="ydr">{{count.absenteeismCount}}</p>
                    </div>
                    <div class="chidao" >
                        <p class="yd">迟到</p>
                        <p class="ydr">{{count.lateCount}}</p>
                    </div>
                    <div class="zaotui" >
                        <p class="yd">早退</p>
                        <p class="ydr">{{count.leaveCount}}</p>
                    </div>
                    <div class="zhengc" >
                        <p class="yd">忘打卡</p>
                        <p class="ydr">{{count.forgetCount}}</p>
                    </div>
                </div>
                <div  class="text item">
                    <div class="serverTable">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            height="100%"
                            v-show="teamOrMy"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="attendanceUserName"
                                label="考勤人"
                                sortable
                                align="center"
                                width="300">
                        </el-table-column>
                        <el-table-column
                                prop="normalCount"
                                label="正常次数"
                                align="center"
                                sortable
                                width="210">
                        </el-table-column>
                        <el-table-column
                                prop="lateCount"
                                label="迟到次数"
                                align="center"
                                sortable
                                width="210">
                        </el-table-column>
                        <el-table-column
                                prop="leaveCount"
                                label="早退次数"
                                align="center"
                                sortable
                                width="210">
                        </el-table-column>
                        <el-table-column
                                prop="forgetCount"
                                label="忘打卡次数"
                                align="center"
                                width="210">
                        </el-table-column>
                        <el-table-column
                                prop="absenteeismCount"
                                label="旷工次数"
                                align="center"
                                >
                        </el-table-column>

                    </el-table>
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            height="100%"
                            v-show="!teamOrMy"
                            :header-cell-style="{background:'#1e50ae',color:'white'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="startDate"
                                label="上班打卡时间"
                                align="center"
                                sortable
                                width="160">
                        </el-table-column>
                        <el-table-column
                                prop="endDate"
                                label="下班打卡时间"
                                align="center"
                                sortable
                                width="160">
                        </el-table-column>
                        <el-table-column
                                prop="firstLocation"
                                label="上班打卡地点"
                                align="center"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="secondLocation"
                                label="下班打卡地点"
                                align="center"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="status"
                                label="状态"
                                align="center"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="remark"
                                label="备注"
                                align="center"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="详情" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-info" size="mini" @click="Dialog(scope.row.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="补打卡" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-s-claim" size="mini" @click="buka(scope.row.id)" ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    </div>
                </div>
            </el-card>
        </div>
    </div>
</template>

<script>
    import kaoqingLeft from './kaoqingLeft'
    export default {
        name: "teamkaoqing",
        data(){
            return{
                tableData:[],
                selectdate: new Date(),
                count:{
                    xiujia:0,
                    lateCount:0,
                    leaveCount:0,
                    forgetCount:0,
                    absenteeismCount:0
                },
                qingdanList:[],
                teamOrMy:true
            }
        },
        methods:{
            async datechange(){
                var datetime=this.selectdate.getFullYear()+"-"+(this.selectdate.getMonth()+1)+"-"+this.selectdate.getDate()+" 00:00:00";
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectMonthAttendanceStatisticsDetails?projectId='+this.$root.proId+'&month='+datetime);
                if(res.status!='3000'){
                    return this.$message.error('考勤列表查询失败！'+res.message)
                }
                this.tableData=res.data
              /*  for(let i in this.tableData){
                    var status=[];
                    if(this.tableData[i].status.indexOf("LATE")!=-1){
                        status.push('迟到')
                    }
                    if(this.tableData[i].status.indexOf("LEAVEEARLY")!=-1){
                        status.push('早退')
                    }
                    if(this.tableData[i].status.indexOf("FORGET")!=-1){
                        status.push('缺卡')
                    }
                    if(this.tableData[i].status.indexOf("ABSENTEEISM")!=-1){
                        status.push('旷工')
                    }
                    if(this.tableData[i].status.indexOf("VACATION")!=-1){
                        status.push('休假')
                    }
                    if(this.tableData[i].status.indexOf("NORMAL")!=-1){
                        status.push('正常')
                    }
                    this.tableData[i].status=status.toString()
                }*/
                const {data:res1}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectMonthAttendanceStatistics?projectId='+this.$root.proId+'&month='+datetime);
                if(res1.status!='3000'){
                    return this.$message.error(res.message)
                }
                this.count=res1.data
                this.count.xiujia=0
            },
            async getTeamList(){
                var datetime=new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()+" 00:00:00";
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectMonthAttendanceStatisticsDetails?projectId='+this.$root.proId+'&month='+datetime);
                if(res.status!='3000'){
                  return  this.$message.error('查询项目考勤统计失败'+res.message)
                }
                this.tableData=res.data
            },
            async getTeamCount(){
                var datetime=new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()+" 00:00:00";
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectMonthAttendanceStatistics?projectId='+this.$root.proId+'&month='+datetime);
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                this.count=res.data
                this.count.xiujia=0
            },
            async getTeanQingdan(datetime){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectAttendanceStatusUserStatistics?projectId='+this.$root.proId+'&day='+datetime);
                if(res.status=='3000'){
                    this.qingdanList=res.data;
                }else {
                    this.qingdanList=[]
                }
            },
            qingjia(){
                this.tableData=[]
            },
            kuanggong(){
                this.tableData=this.qingdanList.ABSENTEEISM;
            },
            zaotui(){
                this.tableData=this.qingdanList.LEAVEEARLY;
            },
            chidao(){
                this.tableData=this.qingdanList.LATE;
            },
            wangdaka(){
                this.tableData=this.qingdanList.FORGET;
            },
            zhengchang(){
                this.tableData=this.qingdanList.NORMAL;
            }
        },
        created(){
            this.getTeamCount();
            this.getTeamList();
            var datetime=new Date().getFullYear()+"-"+(new Date().getMonth()+1)+"-"+new Date().getDate()+" 00:00:00";
          //  this.getTeanQingdan(datetime)
        },
        components:{
            kaoqingLeft
        }
    }
</script>

<style scoped lang="less">

    #kaoList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        right: 10px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }


    /deep/ .el-card__header{
        border-bottom:1px solid #2862b7;
        padding-left: 10px !important;
        .clearfix{
            margin-top: -8px !important;
            padding-left: 5px !important;
            height: 30px !important;
            border-left:5px solid #1e50ae !important;
            color: #000 !important;
            font-size: 14px !important;
        }
        span{
            color: #000 !important;
            font-size: 14px !important;
        }
    }
    .tongji{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 105px;
    }
    .yingdao{
        float: left;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #268DF6;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }

    }
    .shidao{
        width: 120px;
        height: 65px;
        float: left;
        border-radius: 5px;
        background-color: #909399;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .queqin{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #DE494D;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .chidao{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .zaotui{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .zhengc{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #67c23a;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    /deep/ .el-date-editor{
        margin-left: 10px;
        margin-top: -5px;
    }
    /deep/ .el-input__icon{
        line-height: 30px;
    }
    .serverTable{
        height: calc(100% - 230px);
    }
</style>


