<template>
  <div class="bg">
   <hou-left></hou-left>
   <div class="mainpart">
    <el-card class="box-card" style="height: 100%;" >
      <div slot="header" class="clearfix">
        <el-row :gutter="20">
          <el-col :span="6">
            <el-input placeholder="请输入用户名查询" v-model="queryInfo.userName" class="input-with-select" clearable @clear="getUserList">
              <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getUserList" ></el-button>
            </el-input>
          </el-col>
          <el-col :span="6">
            <el-input placeholder="请输入姓名查询" v-model="queryInfo.name" class="input-with-select" clearable @clear="getUserList">
              <el-button slot="append" type="goon"  icon="el-icon-search" @click="getUserList" ></el-button>
            </el-input>
          </el-col>
          <el-col :span="4" class="btnright"><el-button type="goon"  @click="dialogVisible = true" v-has="'adduser'">添加用户</el-button></el-col>
        </el-row>
      </div>
      <div class="serverTable">
      <el-table
        :data="tableData"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        height="100%"
        style="width: 100%;">
        <el-table-column
          type="index"
          label="序号"
          width="50"
        >
          <template v-slot="scope">
            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
          prop="username"
          label="用户名"
          min-width="8%"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          label="姓名"
          min-width="6%"
        >
        </el-table-column>
        <el-table-column
          label="角色"
          min-width="6%"
        >
          <template v-slot="scope">
            <span v-for="item in scope.row.roles" :key="item.id">{{item.roleName}} </span>
          </template>
        </el-table-column>
        <el-table-column
          label="部门"
          min-width="12%"
        >
          <template v-slot="scope">
            <span v-if="scope.row.unit">{{scope.row.unit["unitName"]}} </span>
          </template>
        </el-table-column>
        <el-table-column
          prop="email"
          label="邮箱"
          min-width="10%"
        >
        </el-table-column>
        <el-table-column
          prop="post"
          label="职务"
          min-width="6%"
        >
        </el-table-column>

        <el-table-column
          prop="telephone"
          label="联系电话"
          min-width="6%"
        >
        </el-table-column>

        <el-table-column
          label="操作"
          align="center"
          min-width="9%"
        >
          <template v-slot="scope">
            <el-tooltip effect="dark" content="修改用户" placement="top" :enterable="false" v-has="'updateuser'">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除用户" placement="top" :enterable="false" v-has="'deleteuser'">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
            </el-tooltip>
            <!--  <el-tooltip effect="dark" content="分配角色" placement="top" :enterable="false">
                <el-button type="warning" icon="el-icon-setting" size="mini"></el-button>
              </el-tooltip>-->
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNumber"
        :page-sizes="[5,10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total">
      </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="添加用户"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addFormRule">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="addForm.username" @blur="checkUserName"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input type="password" v-model="addForm.password" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="确认密码" prop="checkPass">
          <el-input type="password" v-model="addForm.checkPass" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="name">
          <el-input v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-select v-model="juese" clearable  multiple placeholder="请选择角色" style="width: 100%" @change="jueseChange">
            <el-option
              v-for="item in roleList"
              :key="item.role.id"
              :label="item.role.roleName"
              :value="item.role.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位" prop="unitId">
          <el-select v-model="addForm.unitId" clearable  placeholder="请选择单位" style="width: 100%" @change="bumenChange">
            <el-option
              v-for="item in bumenList"
              :key="item.id"
              :label="item.unitName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" prop="unitId">
          <el-select v-model="addForm.departmentId" clearable  placeholder="请选择部门" style="width: 100%" @change="bumenChange3">
            <el-option
                    v-for="item in bumenList2"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email"  >
          <el-input v-model="addForm.email" > </el-input>
        </el-form-item>
        <el-form-item label="职责" prop="responsibility"  >
          <el-input v-model="addForm.responsibility" > </el-input>
        </el-form-item>
        <el-form-item label="职务" prop="post">
          <el-input v-model="addForm.post"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="addForm.telephone"></el-input>
        </el-form-item>
        <!--  <el-form-item label="性别" prop="sex">
            <el-radio-group v-model="addForm.sex">
              <el-radio label="男"></el-radio>
              <el-radio label="女"></el-radio>
            </el-radio-group>
          </el-form-item>-->
      </el-form>
      <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addUser" >确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="修改用户信息"
      :visible.sync="editdialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="editdialogClose"
    >
      <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="editFormRules">
        <el-form-item label="用户名" prop="username">
          <el-input v-model="editForm.username" disabled></el-input>
        </el-form-item>
        <el-form-item label="真实姓名" prop="name">
          <el-input v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="角色" prop="roles">
          <el-select v-model="jueseEdit"  clearable  multiple placeholder="请选择角色" style="width: 100%" @change="jueseChange2">
            <el-option
              v-for="item in roleList"
              :key="item.role.id"
              :label="item.role.roleName"
              :value="item.role.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="单位" prop="unitId">
          <el-select v-model="editForm.unitId" clearable  placeholder="请选择单位" style="width: 100%" @change="bumenChange2">
            <el-option
              v-for="item in bumenList"
              :key="item.id"
              :label="item.unitName"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="部门" prop="unitId">
          <el-select v-model="editForm.departmentId" clearable  placeholder="请选择单位" style="width: 100%" @change="bumenChange4">
            <el-option
                    v-for="item in bumenList2"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="邮箱" prop="email"  >
          <el-input v-model="editForm.email" > </el-input>
        </el-form-item>
        <el-form-item label="职责" prop="responsibility"  >
          <el-input v-model="editForm.responsibility" > </el-input>
        </el-form-item>
        <el-form-item label="职务" prop="post">
          <el-input v-model="editForm.post"></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="telephone">
          <el-input v-model="editForm.telephone"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="editUserInfo">确 定</el-button>
  </span>
    </el-dialog>
  </div>
   </div>
</template>

<script>
  import houLeft from "./houTaiLeft";
  export default {
    name: 'yhLish',
    data(){
      var checkEmail=(rule,value,cb)=>{
        const regEmail=/^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(\.[a-zA-Z0-9_-])+/;
        if(value==""){
          return cb();
        }
        else if(regEmail.test(value)){
          return cb()
        }
        cb(new Error('请输入合法的邮箱！'))
      }
      var validatePass2 = (rule, value, callback) => {
        if (value === '') {
          callback(new Error('请再次输入密码'));
        } else if (value !== this.addForm.password) {
          callback(new Error('两次输入密码不一致!'));
        } else {
          callback();
        }
      };
      var validatorPhone = function (rule, value, callback) {
        if (value === '') {
          callback(new Error('手机号不能为空'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      }
      return{
        tableData:[],
        queryInfo:{
          userName:'',
          name:'',
          pageNumber:1,
          pageSize:10,
          projectId:this.$root.proId
        },
        total:10,
        dialogVisible:false,
        addForm:{
          username:'',
          password:'',
          checkPass:'',
          name:'',
          email:'',
          post:'',
          roles:[],
          unitId:'',
          telephone:'',
          projectId:this.$root.proId,
          departmentId:'',
          responsibility:''
        },
        addFormRule:{
          username:[{required:true,message:'请输入用户名',trigger:'blur'},{min:3,max:20,message:"用户名的长度再3-20个字符之间",trigger:'blur'}],
        /*  password:[{required:true,message:'请输入密码',trigger:'blur'},{min:3,max:20,message:"密码的长度再8-30个字符之间",trigger:'blur'},{
            trigger: 'blur',
            validator: (rule, value, callback) => {
              var passwordreg = /^(?![A-Za-z]+$)(?![A-Z\d]+$)(?![A-Z\W]+$)(?![a-z\d]+$)(?![a-z\W]+$)(?![\d\W]+$)\S{8,30}$/
              console.log(passwordreg.test(value))
              if (!passwordreg.test(value)) {
                callback(new Error('密码必须由大写字母、小写字母、数字、特殊符号中的3种及以上类型组成!'))
              }
              else{
                callback()
              }
            } },
          ],*/
          checkPass: [{ validator: validatePass2, trigger: 'blur' },{required:true,message:'请输入密码',trigger:'blur'}],
          email:[{validator:checkEmail,trigger:'blur'}],
          name:[{required:true,message:'请输入真实姓名',trigger:'blur'}],
          roles:[{required:true,message:'请选择角色',trigger:'blur'}],
          telephone:[{required:true,validator: validatorPhone,trigger:'blur'}],
          unitId:[{required:true,message:'请选择部门',trigger:'blur'}],
        },
        editdialogVisible:false,
        editForm:{
        },
        editFormRules:{
          /*password:[{required:true,message:'请输入密码',trigger:'blur'},{min:3,max:20,message:"密码的长度再3-20个字符之间",trigger:'blur'}],*/
          ///username:[{required:true,message:'请输入用户名',trigger:'blur'},{min:3,max:20,message:"用户名的长度再3-20个字符之间",trigger:'blur'}],
          name:[{required:true,message:'请输入真实姓名',trigger:'blur'}],
          roles:[{required:true,message:'请选择角色',trigger:'blur'}],
          email:[{validator:checkEmail,trigger:'blur'}],
          unitId:[{required:true,message:'请选择部门',trigger:'blur'}],
          telephone:[{required:true,validator: validatorPhone,trigger:'blur'}],
        },
        roleList:[],
        bumenList:[],
        bumenList2:[],
        juese:[],
        jueseEdit:[],
        bumen:[],
        bumenEdit:'',
      }
    },
    created () {
      this.getUserList();
      this.getRole();
      this.getDanwei();
      this.getBumen();
    },
    methods:{
      async getUserList(){
        const {data:res}=await  this.$http.get('/v1.0/user/findPages',{
          params:this.queryInfo
        })
        if(res.status!=="3000"){
          return this.$message.error('获取用户列表失败！'+res.message)
        }
        this.tableData=res.data.result;
        this.total=res.data.totalCount
      },
      async getRole(){
        const {data:res}=await  this.$http.get('/v1.0/role/findAll');
        if(res.status!=="3000"){
          return 0;
        }
        this.roleList=res.data;
      },
      async getDanwei(){
        const {data:res}=await  this.$http.get('/v1.0/unit/findAll');
        if(res.status!=="3000"){
          return 0;
        }
        this.bumenList=res.data;
      },
      async getBumen(){
        const {data:res}=await  this.$http.get('/v1.0/department/findAllByProjectIdContain/'+this.$root.proId);
        if(res.status!=="3000"){
          return 0;
        }
        this.bumenList2=res.data;
      },
      async checkUserName(){
        if(this.dialogVisible){
          const {data:res}=await  this.$http.get('/v1.0/user/findUserNameIsExist/'+this.addForm.username+"/"+this.$root.proId)
          console.log(res)
          if(res.status==6030){
            this.$alert('该用户名已经存在于该项目', '用户已存在', {
              confirmButtonText: '确定',
              callback: action => {
                this.$message({
                  type: 'info',
                  message: `该用户名已经存在于该项目，请修改！`
                });
              }
            });
          }else {
            if(res.status==6031){
              this.$confirm('是否直接添加该用户名对应信息？', '该用户名在其他项目下已存在', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
              }).then(async() => {
                const {data:res}=await  this.$http.get('/v1.0/user/saveIsExistUser/'+this.addForm.username+"/"+this.$root.proId)
                if(res.status==6000){
                  this.$message({
                    type: 'success',
                    message: '添加成功!'
                  });
                  this.getUserList();
                  this.dialogVisible=false
                }else {
                  this.$message({
                    type: 'info',
                    message: '添加失败'
                  });
                }
              }).catch(() => {
                this.$message({
                  type: 'info',
                  message: '已取消默认添加'
                });
              });
            }
          }
        }


      },
      handleSizeChange(newSize){
        this.queryInfo.pageSize=newSize;
        this.getUserList()
      },
      handleCurrentChange(newPage){
        this.queryInfo.pageNumber=newPage;
        this.getUserList()
      },
      dialogClose(){
        this.$refs.addFormRef.resetFields();
        this.juese=[];
        this.bumen='';
      },
      async addUser(){
        this.$refs.addFormRef.validate(async valid =>{
          if(!valid)
            return
          const {data:res}= await this.$http.post('/v1.0/user/save', this.addForm)
          if(res.status!=="6000"){
            console.log(res)
           return this.$message.error('添加用户失败！'+res.message)
          }
          this.$message.success('添加用户成功！');
          this.getUserList();
          this.dialogVisible = false;
        })

      },
      async editDialog(id){
        //请求查询
        const {data:res}=await  this.$http.get('/v1.0/user/findById/'+id);
        if(res.status!=="3000"){
          return 0;
        }
        this.editForm=res.data;
        this.jueseEdit=[];
        for(let i=0;i<res.data.roles.length;i++){
          this.jueseEdit.push(res.data.roles[i].id);
        }
        console.log(this.jueseEdit)
        this.editdialogVisible = true
      },
      //重置修改表单
      editdialogClose(){
        this.$refs.editFormRef.resetFields()
      },
      editUserInfo(){
        this.$refs.editFormRef.validate(async valid =>{
          console.log(this.editForm)
          if(!valid) return
          const {data:res}= await this.$http.post('/v1.0/user/update', this.editForm)
          if(res.status!=="6000"){
           return this.$message.error('修改用户失败！')
          }
          this.$message.success('修改用户成功！');
          this.getUserList();
          this.editdialogVisible = false
        })
      },
      async deleteMsg(id){
        const confirmResult = await this.$confirm('此操作将永久删除该用户, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(confirmResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
        //请求
        const {data:res}= await this.$http.delete('/v1.0/user/delete/'+id)
        if(res.status!="4000"){
         return this.$message.error('删除用户失败！')
        }
        this.$message.success('删除用户成功！');
        this.getUserList();
      },
      jueseChange(e){
        this.addForm.roles=[];
        for(let i=0;i<e.length;i++){
          this.addForm.roles.push({id:e[i]})
        }
      },
      jueseChange2(e){
        this.editForm.roles=[];
        for(let i=0;i<e.length;i++){
          this.editForm.roles.push({id:e[i]})
        }
      },
      bumenChange(e){
        this.addForm.unitId=e
      },
      bumenChange3(e){
        this.addForm.departmentId=e
      },
      bumenChange2(e){
        this.editForm.unitId=e
      },
      bumenChange4(e){
        this.editForm.departmentId=e
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      }
    },
    components:{
      houLeft
    }
  }
</script>

<style scoped lang="less">
.btnright{
  padding-left: 570px !important;
}

</style>
