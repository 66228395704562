<template>
    <div class="bg">
        <work-left></work-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-input placeholder="请输入发起人名称查询" v-model="initiatingUserName" class="input-with-select" clearable @clear="getlcList">
                        <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getlcListByName" ></el-button>
                    </el-input>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                           >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="processName"
                                label="流程名称"
                                width="350"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="selfTaskToDealDto.name"
                                label="待办流程节点名称"
                                width="350"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="initiatingUserName"
                                label="发起人"
                                width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="selfTaskToDealDto.createTime"
                                label="发起时间"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="chakan(scope.row)" icon="el-icon-view" size="mini">查看</el-button>
                                <el-button  type="primary" @click="wancheng(scope.row)" icon="el-icon-video-play" size="mini">办理</el-button>
                                <!-- <el-tooltip effect="dark" content="删除公告" placement="top" :enterable="false">
                                     <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                 </el-tooltip>-->
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="流程查看"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                center
        >
            <div slot="footer" class="dialog-footer">
                <div id="sbgimg" >
                </div>
            </div>
            <el-table
                    :data="liuchengData"
                    border
                    height="240px"
                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    style="width: 100%;">
                <el-table-column
                        type="index"
                        label="序号"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="assigneeName"
                        label="办理人"
                        width="100"
                >
                </el-table-column>
                <el-table-column
                        prop="startDate"
                        label="开始办理时间"
                        width="220"
                >
                </el-table-column>
                <el-table-column
                        prop="endDate"
                        label="结束办理时间"
                        width="240"
                >
                </el-table-column>
                <el-table-column
                        prop="comments"
                        label="办理意见"
                >
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
                title="处理任务"
                :close-on-click-modal="false"
                :visible.sync="formDialogVisible"
                width="50%"
                center>
            <form-create v-model="fApi" :rule="rule" :option="option" :value.sync="value" style="border-bottom:1px solid #fffaaa"></form-create>
            <br style="border: 1px solid red">
            <el-form ref="addFormRef"  label-width="125px" :rules="addFormRule"  :model="chuliForm" >
            <el-form-item label="处理结果"  prop="clresult">
            <el-select v-model="chuliForm.clresult" placeholder="请选择处理结果" @change="isShow">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="交办人" v-show="show"  prop="recvUser">
                <el-cascader
                        :options="yonghu"
                        :props="props"
                        v-model="chuliForm.recvUser"
                        collapse-tags
                        clearable></el-cascader>
            </el-form-item>
            <el-form-item label="处理备注"  prop="clremake">
            <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入处理备注"
                    v-model="chuliForm.clremake">
            </el-input>
            </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <div id="sbgimg2" >
                </div>
    <el-button @click="queding()">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import workLeft from './workLeft'
    export default {
        name: "daiban",
        data(){
            return{
                initiatingUserName:'',
                total:0,
                tableData:[],
                dialogVisible:false,
                show:true,
                formDialogVisible:false,
                fApi: {},
                //表单数据
                value: {},
                rule: [
                ],
                //表单生成规则
                addFormRule:
                    {
                        clresult: [
                            { required: true, message: '请输入处理结果', trigger: 'change' },
                        ],
                        recvUser: [
                            { required: true, message: '请选择交办人', trigger: 'change' },
                        ],
                        clremake: [
                            { required: true, message: '请输入处理备注', trigger: 'blur' },
                        ],
                    },
                //组件参数配置
                option: {
                    submitBtn:{
                        show:false
                    }
                },
                options: [{
                    value: '1',
                    label: '同意'
                }, {
                    value: '2',
                    label: '驳回'
                }],

                chuliForm:{
                    clresult:'',
                    recvUser:[],
                    clremake:'',
                },
                yonghu:[],
                props: {
                    multiple: true,
                    children:'userList',
                    label:'name',
                    value:'id'
                },

                taskId:'',
                processInstanceId:'',
                liuchengData:[]
            }
        },
        methods:{
            async getlcList(){
                const {data:res}= await this.$http3.get('/v1.0/activiti/process/querySelfTaskByUserIdAndProjectId?projectId='+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error('查询待办列表失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async getlcListByName(){
                const {data:res}= await this.$http3.get('/v1.0/activiti/process/querySelfTaskByUserIdAndProjectId?projectId='+this.$root.proId+'&initiatingUserName='+this.initiatingUserName)
                if(res.status!='3000'){
                    return this.$message.error('查询待办列表失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            async chakan(row){
                console.log(row)
                const {data:result}=await this.$http3.get('/v1.0/activiti/process/queryFlowImgByProcInstId?procInstId='+row.selfTaskToDealDto.processInstanceId);
                var index=result.lastIndexOf("<svg");
                const {data:result2}=await this.$http3.get('/v1.0/activiti/process/queryHistoryProcessByInstanceIdAndBusinessId?processInstanceId='+row.selfTaskToDealDto.processInstanceId+'&businessKey='+row.selfTaskToDealDto.businessKey);
                this.liuchengData=result2.data;
                this.dialogVisible=true
                document.getElementById('sbgimg').innerHTML=(result.substring(index,result.length-1))
            },
            async wancheng(row){
                console.log(row)
                this.taskId=row.selfTaskToDealDto.taskId;
                this.processInstanceId=row.selfTaskToDealDto.processInstanceId;
                if(row.formJson){
                    this.rule=JSON.parse(row.formJson).list;
                }else {
                    this.rule=''
                }
                const {data:result1}=await this.$http3.get('/v1.0/activiti/form/findDynamicTableByProcessId?processInstanceId='+row.selfTaskToDealDto.processInstanceId+'&tableName='+row.tableName);
                this.value=result1.data;
                const {data:result}=await this.$http3.get('/v1.0/activiti/process/queryFlowImgByProcInstId?procInstId='+row.selfTaskToDealDto.processInstanceId);
                var index=result.lastIndexOf("<svg");
                console.log(result.substring(index,result.length-1))
                document.getElementById('sbgimg2').innerHTML=(result.substring(index,result.length-1))
                this.formDialogVisible=true
            },
            isShow(row){
                console.log(row)
                if(row=='2'){
                    this.chuliForm.recvUser=[0]
                    this.show=false
                }else {
                    this.show=true
                }
            },
           async queding(){
               this.$refs.addFormRef.validate(async valid =>{
                   if(!valid)
                       return
                   var userid=''
                   for(let i in this.chuliForm.recvUser){
                       if(i!=this.chuliForm.recvUser.length-1){
                           userid=userid+this.chuliForm.recvUser[i][1]+','
                       }else {
                           userid=userid+this.chuliForm.recvUser[i][1]
                       }

                   }
                   if(this.chuliForm.clresult=='1'){
                       const {data:result}=await this.$http3.get('/v1.0/activiti/process/completeProcess?userId='+userid+'&taskId='+this.taskId+'&remark='+this.chuliForm.clremake);
                       if(result.status!='8001'){
                           return this.$message.error('处理流程失败')
                       }
                       this.$message.success('处理流程成功！')
                       this.formDialogVisible=false
                       this.getlcList()
                   }else {
                       const {data:result2}=await this.$http3.get('/v1.0/activiti/process/backProcess?processInstanceId='+this.processInstanceId+'&remark='+this.chuliForm.clremake);
                       if(result2.status=='8003'){
                           this.$message.success('处理流程成功！')
                           this.formDialogVisible=false
                           this.getlcList()
                       }else if(result2.status=='8005'){
                           this.$confirm('流程到达起始节点无法回退, 是否删除该流程?', '提示', {
                               confirmButtonText: '删除',
                               cancelButtonText: '取消',
                               type: 'warning'
                           }).then(async() => {
                               const {data:res}= await this.$http3.delete('/v1.0/activiti/process/deleteRunningAndHistoryProcess?processInstanceId='+this.processInstanceId)
                               if(res.status!="4000"){
                                   return this.$message.error('删除流程失败！'+res.message)
                               }
                               this.$message.success('删除流程成功！');
                               this.formDialogVisible=false
                               this.getlcList()
                           }).catch(() => {
                               this.$message({
                                   type: 'info',
                                   message: '已取消删除'
                               });
                           });
                       }else {
                           return this.$message.error('处理流程失败')
                       }


                   }
               })


            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getlcList()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getlcList()
            },
        },
        created(){
            this.getlcList();
            this.getUsers();
        },
        components:{
            workLeft
        }
    }
</script>

<style scoped lang="less">

    .input-with-select{
        width: 280px;
        .el-button{
            padding: 10px 15px;
        }
    }
   #sbgimg{
       width: 100%;max-width: 950px;overflow:auto;max-height: 400px;
   }
    #sbgimg2{
        width: 100%;max-width: 950px;overflow:auto;max-height: 400px;
    }
</style>
