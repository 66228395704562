<template>
    <div class="site-wrapper" >
        <div class="content">
            <div class="leftMeau">
                <promeau></promeau>
            </div>
            <div class="rightCont">
                <div id="CategoryTree">
                    <el-input @clear="insertActBtCategory_clear" placeholder="输入关键字进行过滤" clearable v-model="filterText">
                    </el-input>
                    <el-tree class="filter-tree" :data="dataCategory" :props="defaultCategoryProps" default-expand-all
                        :filter-node-method="filterCategoryNode" ref="tree" show-checkbox node-key="categoryId"
                        :check-strictly="true" @check-change="handleCheckChange" @node-click="nodeClick">
                        <!-- 处理菜单内容过长 -->
                        <span class="custom-tree-node" slot-scope="{ node }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <span> {{ node.label | ellipsis(8) }} </span>
                            </el-tooltip>
                        </span>
                    </el-tree>
                </div>
                <div id="pageList">
                    <div class="addActBtCategory">
                        <div class="catleft">
                            <span class="title-left">发起流程</span>
                        </div>
                        <div style="display: flex;justify-content: flex-end">
                            <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                            </el-button>
                        </div>
                    </div>
                    <!-- 列表 -->
                    <div>
                        <el-table :data="tableData" border size="large" style="height: 100%"   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                            <template>
                                <el-empty :image-size="100" description="暂无数据"></el-empty>
                            </template>
                            <el-table-column prop="processDefinitionKey" label="流程编码" width="220">
                                <template #default="scope">
                                    <el-tag>{{ scope.row.processDefinitionKey }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="categoryName" label="流程类别" width="170" />
                            <el-table-column prop="processDefinitionName" label="流程名称" width="240" />
                            <el-table-column prop="version" label="流程版本" width="120">
                                <template #default="scope">
                                    <el-tag type="success"><span>V{{ scope.row.version }}</span></el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="suspended" label="流程状态" width="200">
                                <template #default="scope">
                                    <el-tag :type="scope.row.suspended == 1 ? 'success' : 'danger'">{{
                                        scope.row.suspended == 1 ? "激活状态" : "挂起状态"
                                    }}</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template #default="scope">
                                    <el-button size="small"  @click="getSvg(scope.row)">预览</el-button>
                                    <el-button size="small" type="success" plain 
                                        @click="deployForm(scope.row)">启动审批</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <div style="display: flex;justify-content: flex-end;margin-top: 8px;">
                            <el-pagination background @current-change="handleChangePageNum" @size-change="handleChangePageSize"
                                layout="sizes, prev, pager, next, jumper, ->, total, slot" :total="total">
                            </el-pagination>
                        </div>
                    </div>
                </div>
                <!-- 表单页面 -->
                <el-dialog :visible.sync="dialogVisible" title="流程预览" width="50%" >
                    <p v-html="dataSvg"></p>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="dialogVisible = false">关 闭</el-button>
                        </span>
                    </template>
                </el-dialog>
                <!-- <el-drawer :visible.sync="writeForm" title="填写表单" direction="rtl" size="30%">
                   <form-create :rule="rule" :option="option"></form-create> 
                   
                    <el-divider />
                    <div class="selectPerson">
                        审批人员
                        <el-cascader @change="userChange" ref="userRef" v-model="formJson.assignee" :show-all-levels="false"
                            :options="userOptions" :props="props2" class="cascader" placeholder="请选择审批人员">
                        </el-cascader>
                    </div>
                    <div class="footer">
                        <el-button @click="writeForm = false">取 消</el-button>
                        <el-button type="primary" @click="goForm">确 定</el-button>
                    </div>
                </el-drawer> -->
                <el-dialog :visible.sync="writeForm" title="填写表单" width="50%" ref="dia">
                    <div class="selectPerson">
                        审批人员
                        <el-cascader @change="userChange" ref="userRef" v-model="formJson.assignee" :show-all-levels="false"
                            :options="userOptions" :props="props2" class="cascader" placeholder="请选择审批人员">
                        </el-cascader>
                    </div>
                    <div class="leftWord" id='cediv'>
                    </div>
                    <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="writeForm = false">关 闭</el-button>
                            <el-button type="primary" @click="goForm">确 定</el-button>
                        </span>
                    </template>
                </el-dialog>
            </div>
        </div>

    </div>
</template>

<script>
  import Vue from 'vue'
  import promeau from "../proMeau.vue";
 const inputCom = Vue.component('input-component', {
    data() {
      return {
        inputvalue: "",
      }
    },
    mounted() {
    },
    template: '<el-input v-model="inputvalue" ref="asdasdas"></el-input>'
  })
  const selectCom = Vue.component('select-component', {
    data() {
      return {
        options: [{
        }],
        selectvalue: "",
      }
    },
    template: '<el-select v-model="selectvalue" ref="asdasdas"><el-option v-for="item in options" :key="item.value"   :label="item.label"  :value="item.value"></el-option></el-select>'
  })
  const radioCom = Vue.component('radio-component', {
    data() {
      return {
        radio: "",
        word1:'',
      }
    },
    template: '<el-radio v-model="radio" label="1" ref="asdasdas">{{word1}}</el-radio>'
  })
  const checkboxCom = Vue.component('checkbox-component', {
    data() {
      return {
        checked: "",
        word:'',
      }
    },
    template: '<el-checkbox v-model="checked" label="1" ref="asdasdas">{{word}}</el-checkbox>'
  })
  const dateCom = Vue.component('date-component', {
    data() {
      return {
        value: "",
        word:'',
      }
    },
    template: '<el-date-picker v-model="value" type="date" value-format="yyyy-MM-dd" placeholder="选择日期" ref="asdasdas">{{word}}</el-date-picker>'
  })
  const imgCom = Vue.component('img-component', {
    data() {
      return {
        url:"",
        fit: "fill",
      }
    },
    template: '<el-image  style="width: 100px; height: 40px" :src="url" :fit="fit"><div slot="error" class="image-slot">  <i class="el-icon-picture-outline"></i> </div></el-image>'
  })
  let inputel=[]

export default {
    data() {
        return {
            queryPage: {
                proDefCategory: "",
                pageNum: 1,
                pageSize: 10,
                proDefVersion: "",
                processDefinitionKey: "",
                processDefinitionName: "",
                suspended: "",
                whetherFilter: true,
            },
            total: 0,
            tableData: [],
            dialogVisible: false,
            dataSvg: '',
            writeForm: false,
            rule: [],
            option: {
                submitBtn: false,
            },
            userRef: '',
            userOptions: [],
            props2: {
                label: "realName",
                value: "assignee",
            },
            formJson: {
                assignee: "人名",
                formContent: "",
                notation: "",
                processDefinitionId: "",
                tableName: "",
            }

        }
    },
    methods: {
        async getInitiation() {
            const { data: res } = await this.$http2.post('/ActReProcDef/query/ProcessDefinitionPageList', this.queryPage)
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.total = res.data.total;
            }
        },
        async getSvg(row) {
            const { data: res } = await this.$http2.get('/extension/act-re-deployment/query/ProcessModelSvgImageByProcessDefinitionId/' + row.processDefinitionId)
            this.dataSvg = res;
            this.dialogVisible = true;
        },
        userChange(res) {
            this.formJson.assignee = res[res.length - 1];
        },
        async   deployForm(row) {
            // const { data: res } = await this.$http2.get('/ActReProcDef/query/FormContentByDeploymentId/' + row.deploymentId)
            // if (res.code == 200) {
            //     this.rule = JSON.parse(res.data.formContent);
            //     this.formJson.processDefinitionId = row.processDefinitionId;
            //     this.formJson.tableName = res.data.tableName;
            //     this.writeForm = true;
            // } else {
            //     this.$message.error(res.message);
            // }
            const { data: res2 } = await this.$http9.get('/stencils/query/FormContentByActReModelId/' + row.modelId)
            if (res2.businessCode == 3000) {
                this.formJson.processDefinitionId = row.processDefinitionId;
                this.formJson.tableName = res2.data.tableName;
                this.writeForm = true;
                let shouzhiForm=res2.data.htmls.htmlBody
                let  ce=document.getElementById('cediv')
                console.log(ce)
                ce.innerHTML = shouzhiForm;
                var spans=document.querySelectorAll(".s")
                for(let i in res2.data.componentList){
                    let div= document.createElement("div");
                    div.className='input-div'
                    div.id=spans[i].id
                    if(res2.data.componentList[i].type=='input'){
                        this.$nextTick(()=>{  
                            let inputcom=Vue.extend({
                                extends:inputCom,
                                })
                            let commSel=new inputcom()
                            let inputelx=commSel.$mount()
                            inputel.push({id:spans[i].id,input:inputelx})
                            div.appendChild(inputelx.$el);
                        })
                    }else if(res2.data.componentList[i].type=='select'){
                        var selecttcom=Vue.extend({
                        extends:selectCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        let zhidata=res2.data.componentList[i].optionalValue.split(',')
                        let optobj=[]
                        for(let i in zhidata){
                        optobj.push({
                            label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')
                        })
                        }
                        commSel.$set(commSel, 'options', optobj)
                    }else if(res2.data.componentList[i].type=='radio'){
                        var selecttcom=Vue.extend({
                        extends:radioCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                    }else if(res2.data.componentList[i].type=='check'){
                        var selecttcom=Vue.extend({
                        extends:checkboxCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                    }else if(res2.data.componentList[i].type=='date'){
                        var selecttcom=Vue.extend({
                        extends:dateCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        div.classList.add('hangnei');
                    }else if(res2.data.componentList[i].type=='img'){
                        var selecttcom=Vue.extend({
                        extends:imgCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        div.appendChild(selectel.$el);
                        div.classList.add('hangnei');
                    }
                    if(spans[i].parentNode){
                        spans[i].parentNode.replaceChild(div, spans[i]);
                    }
                }
      
            } else {
                this.$message.error(res2.message);
            }
        },
        async goForm() {
            let formj = {};
            for (let i in inputel) {
                formj[inputel[i].id] = inputel[i].input.$refs['asdasdas'].value
            }
            this.formJson.formContent = formj;
            console.log( this.formJson)
            const { data: res } = await this.$http2.post('/InitiateProcess/insert/GeneratorFormContentAndStartProcess', this.formJson)
            if (res.businessCode == 4000) {
                this.$message.success("提交成功");
                this.writeForm = false;
            } else {
                this.$message.error("提交失败");
            }
            // let formj = {};
            // for (let i in this.rule) {
            //     formj[this.rule[i].field] = this.rule[i].value;
            // }
            // this.formJson.formContent = formj;
            // const { data: res } = await this.$http2.post('/InitiateProcess/insert/GeneratorFormContentAndStartProcess', this.formJson)
            // if (res.businessCode == 4000) {
            //     this.$message.success("提交成功");
            //     this.writeForm = false;
            // } else {
            //     this.$message.error("提交失败");
            // }
            
        },
        handleChangePageSize (val) {
            this.queryPage.pageSize = val;
            this.getInitiation();
        },
        handleChangePageNum  (val)  {
            this.queryPage.pageNum = val;
            this.getInitiation();
        }

    },
    components: {
        promeau,
    },
    created() {
        this.getInitiation()
    },
    watch: {

    },
    mounted() {
        this.$refs.dia.rendered=true
    },
}

</script>

<style scoped lang="less">


.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .insertActBtCategory {
        width: 300px;
        margin-right: 8px;
    }

    .addActBtCategory {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .catleft {
            margin-top: 10px;
        }
    }

    #CategoryTree {
        width: 14%;
        border: 1px solid #E7E7E7;
        height: 96%
    }

    #pageList {
        width: 85%
    }

    .filter-tree {
        overflow: auto;
        flex: 1;
        width: 170px;
        height: 600px;
        margin: 10px;
    }

    .title-left {
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
        width: 140px;
        height: 22px;
        border-left: 5px solid #569ee3;
        text-align: left;
    }
}
</style>