<template>
    <div class="bg">
        <pro-left></pro-left>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                    <el-button   @click="dialogVisiblearea = true" v-has="'addarea'">新增区域</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            row-key="parent.id"
                            default-expand-all
                            highlight-current-row
                            @current-change="handleCurrentChange"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                        <el-table-column
                                prop="parent.name"
                                label="区域名称"
                                min-width="15%"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="parent.code"
                                label="区域编码"
                                min-width="15%"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="parent.totalArea"
                                label="区域总面积"
                                min-width="15%"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                min-width="13%"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="修改区域" placement="top" :enterable="false" v-has="'editarea'">
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.parent.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="删除区域" placement="top" :enterable="false" v-has="'delarea'">
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.parent.id)" ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="添加项目区域"
                :visible.sync="dialogVisiblearea"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormTypeRef" :model="addFormarea" label-width="140px" :rules="editFormareaRules" >
                <el-form-item label="项目区域编码" prop="code">
                    <el-input v-model="addFormarea.code"></el-input>
                </el-form-item>
                <el-form-item label="项目区域名称" prop="name">
                    <el-input v-model="addFormarea.name"></el-input>
                </el-form-item>
                <el-form-item label="项目区域总面积(㎡)" prop="totalArea">
                    <el-input v-model="addFormarea.totalArea" type="number"></el-input>
                </el-form-item>

            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisiblearea = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addproarea" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改项目区域"
                :visible.sync="editdialogVisiblearea"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="editFormRef" :model="editFormarea" label-width="140px" :rules="editFormareaRules">
                <el-form-item label="项目区域编码" prop="code">
                    <el-input v-model="editFormarea.code"></el-input>
                </el-form-item>
                <el-form-item label="项目区域名称" prop="name">
                    <el-input v-model="editFormarea.name"></el-input>
                </el-form-item>
                <el-form-item label="项目区域总面积(㎡)" prop="totalArea">
                    <el-input v-model="editFormarea.totalArea" type="number"> </el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisiblearea = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditProType">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import proLeft from './proInfoLeft'
    export default {
        name: "proarea",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                dialogVisiblearea:false,
                editdialogVisiblearea:false,
                addFormarea:{
                    code:'',
                    name:'',
                    totalArea:'',
                    parentAreaId:'',
                    isRoot:1,
                    projectId:this.$root.proId,
                },
                editFormarea:{},
                editFormareaRules:{
                    code:[{required:true,message:'请输入项目区域编码',trigger:'blur'}],
                    name:[{required:true,message:'请输入项目区域名称',trigger:'blur'}],
                },
            }
        },
        created(){
          this.getProarea()
        },
        methods:{
            async getProarea(){
                const {data:res}= await this.$http1.get('/v1.0/project/area/findProjectAreaTreeByProjectId?projectId='+this.$root.proId)
                if(res.businessCode!='3000'){
                    return this.$message.error('查询项目区域失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async addproarea(){
                const {data:res}= await this.$http1.post('/v1.0/project/area/save',this.addFormarea)
                if(res.businessCode!='6000'){
                    return this.$message.error('添加项目区域失败！'+res.message)
                }
                this.$message.success('添加项目区域成功！');
                this.dialogVisiblearea=false;
                this.getProarea();
            },
            async editDialog(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/area/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return 0;
                }
                this.editFormarea=res.data;
                this.editdialogVisiblearea=true
            },
            async iseditProType(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    const {data:res}=await  this.$http1.put('/v1.0/project/area/update',this.editFormarea);
                    if(res.businessCode!=="6000"){
                        return this.$message.error('修改项目区域失败！'+res.message)
                    }
                    this.$message.success('修改项目区域成功！');
                    this.getProarea();
                    this.editdialogVisiblearea = false
                })
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/area/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除项目区域失败！'+res.message)
                }
                this.$message.success('删除项目区域成功！');
                this.getProarea();
            },
            handleCurrentChange(val){
                this.addFormarea.isRoot=0
                this.addFormarea.parentAreaId=val.parent.id;
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            dialogClose(){
                this.$refs.addFormTypeRef.resetFields();
                this.$refs.editFormRef.resetFields();
            },
        },
        components:{
            proLeft
        }
    }
</script>

<style scoped lang="less">


</style>
