<template>
    <div style="width: 100%;height:100vh;">
        <dv-full-screen-container class="bg" style="background-color: #111224">
            <div  class="host-body">
                  <!--  <dv-decoration-10  class="lineleft" />-->
                <!--    <div class="jc-center titlecenter">-->
                      <!--  <dv-decoration-8 :color="['#568aea', '#000000']" class="centerleft" style="" />-->
                        <div class="title">
                            <span class="title-text">兵团设计院EPC项目管理平台</span>
                       <!--     <dv-decoration-6
                                    class="title-bototm"
                                    :reverse="true"
                                    :color="['#50e3c2', '#67a1e5']"
                            />-->
                        </div>
                      <!--  <dv-decoration-8
                                :reverse="true"
                                :color="['#568aea', '#000000']"
                                class="centerright"
                        />-->
                  <!--  </div>-->
                  <!--  <dv-decoration-10 class="lineright" style="" />-->
            </div>
            <div class="host-content">
              <!--  <dv-border-box-1>-->
                    <div style="padding-top: 10px">
                        <div class="bgleft">
                            <div class="touxiang">
                                <img src="../assets/img/logo.png">
                            </div>
                            <el-form class="login-form">
                                <el-form-item>
                                    <h3 class="formtitle">兵团设计院EPC平台登录</h3>
                                </el-form-item>
                                <el-form-item>
                                    <el-input
                                            placeholder="请输入您的账号"
                                            v-model="UserName"
                                            class="userNameInput">
                                        <i slot="prefix" class="el-icon-login-user"></i>
                                    </el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-input
                                            placeholder="请输入您的密码"
                                            v-model="PassWord"
                                            show-password
                                            class="passwordInput"
                                    >
                                        <i slot="prefix" class="el-icon-login-password"></i>
                                    </el-input>
                                </el-form-item>
                                <div style="padding-left: 67%;margin-top: 1%">
                                <el-checkbox v-model="checked">记 住 密 码</el-checkbox>
                                </div>
                                <el-button  type="primary" @click.native.prevent="subBtn()"  plain  round>登 录</el-button><!---->
                            </el-form>
                        </div>
                    </div>

              <!--  </dv-border-box-1>-->
            </div>
        </dv-full-screen-container>
    </div>
</template>

<script>
    export default {
        name: "login",
        data(){
            return{
                UserName: "",
                PassWord: "",
                checked:false,
            }
        },
        methods:{
          async  subBtn(){
                const {data:res}= await this.$http.post('http://localhost:8802/doLogin?username='+this.UserName+'&password='+this.PassWord)
                window.sessionStorage.setItem("token",res.data.token);
                window.sessionStorage.setItem("name",res.data.user.name);
                this.$router.push({ path: "/home" });
            }
        }
    }
</script>

<style scoped lang="less">
    .host-body{
            .title{
                height: 50px;
                position: relative;
                width:100%;
                text-align: center;
                margin-top: 0px;
                background-size: cover;
                background-repeat: no-repeat;
                .title-text {
                    font-size: 38px;
                    position: absolute;
                    bottom: 0;
                    color: white;
                    font-family: SourceHanSansCN-Bold;
                    font-weight: bold;
                    letter-spacing: 2px;
                    transform: translate(-50%);
                }
                .title-bototm {
                    position: absolute;
                    bottom: -10px;
                    left: 50%;
                    transform: translate(-50%);
                    width:300px;
                    height:10px;
                }
            }
    }
    .host-content{
        width: 100%;
        height: 870px;
    }
    .bg{
        background: url("../assets/img/ng2.jpg");
        background-repeat: no-repeat;
        background-size: 100% 100%;
    }
    .bgleft{
        text-align: center;
        background-color: rgba(61,56,79,0.5);
        width: 400px;
        height: 480px;
        margin-left: 760px;
        margin-top: 170px;
    }
    .touxiang{
        width: 130px;
        height: 130px;
        border: 1px solid white;
        border-radius: 50%;
        padding: 10px;
        box-shadow: 0 0 10px #000;
        left: 500px;
        position: absolute;
        left: 50%;
        transform: translate(-50%,-50%);
        z-index: 99999;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .login-form {
        position: relative;
        max-width: 100%;
        height: 100%;
        width: 100%;
        padding: 90px 20px 100px;
        box-sizing: border-box;
        overflow: hidden;
        border: 2px solid rgb(30,80,174);
        /*登录框透明背景颜色*/
        background-color: rgba(30,80,174,0.5);
      /*  !*边框荧光*!
        -moz-box-shadow:4px -4px 50px #57E7F4;
        -webkit-box-shadow:4px -2px 50px #57E7F4;
        box-shadow:4px -4px 50px #57E7F4;*/

    .el-button{
        width: 130px;
        height: 40px;
        font-size:16px;
        font-weight: bold;
        background-color: #1E50AE;
       // border: 3px #3bb6f4 #1E50AE;
        border: 0;
        margin-left: 15px;
        margin-top: 30px;
        color: white;
        border-radius: 0;
    }
    .el-button:hover{
        background-color: #156cd2;
        color: #fff;
    }
    .el-link{
        float:right;
        margin-top:70px ;
        margin-right: 40px;
    }
    }
    .el-icon-login-user{
        background: url(../assets/img/user.png) center no-repeat;
        background-size: contain;
        margin-top: 90%;
        margin-left: 80%;
    }
    .el-icon-login-user:before{
        content: "替";
        font-size: 16px;
        visibility: hidden;
    }
    .el-icon-login-password{
        background: url(../assets/img/pswd.png) center no-repeat;
        background-size: contain;
        margin-top: 90%;
        margin-left: 80%;
    }
    .el-icon-login-password:before{
        content: "替";
        font-size: 16px;
        visibility: hidden;
    }
    .passwordInput,.userNameInput{
        margin-top: 5%;
    }
    /deep/ .el-input__inner{
        background-color: #ffffff;
        height:45px;
        border-radius: 20px;
        padding-left:55px;
    }
    /deep/ .el-input--prefix .el-input__inner {
        padding-left:50px;
    }
    /deep/ .el-checkbox__label{
        color: white;
    }
    .formtitle{
        font-size: 26px;
        color: white;
        text-align: center;
        font-weight: bold;
        display: inline-block;
    }
</style>
