<template>
  <div class="bg">
    <work-left></work-left>
    <div class="mainpart">
      <el-card class="box-card" style="height: 100%">
        <div slot="header" class="clearfix">
          <el-input
            v-model="queryPage.processDefinitionName"
            placeholder="请输入流程名称"
            clearable
            @change="getInitiation"
          />
          <el-input
            v-model="queryPage.processDefinitionKey"
            placeholder="请输入流程关键字"
            clearable
            @change="getInitiation"
          />
        </div>
        <div class="rightCont">
          <div id="CategoryTree">
            <el-input placeholder="输入关键字进行过滤" v-model="filterText">
            </el-input>
            <el-tree
              class="filter-tree"
              :data="dataCategory"
              :props="defaultCategoryProps"
              default-expand-all
              :filter-node-method="filterCategoryNode"
              ref="treeRef"
              show-checkbox
              node-key="categoryId"
              @check="treeChange"
            >
              <!-- 处理菜单内容过长 -->
              <span class="custom-tree-node" slot-scope="{ node }">
                <el-tooltip
                  class="item"
                  effect="dark"
                  :content="node.label"
                  placement="top-start"
                >
                  <span> {{ node.label | ellipsis(8) }} </span>
                </el-tooltip>
              </span>
            </el-tree>
          </div>
          <div id="pageList">
            <div class="addActBtCategory">
              <div class="catleft">
                <span class="title-left">待办流程</span>
              </div>
            </div>
            <!-- 列表 -->
            <div>
              <el-table
                :data="tableData"
                border
                size="large"
                style="height: 100%"
                :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
              >
                <template>
                  <el-empty :image-size="100" description="暂无数据"></el-empty>
                </template>
                <el-table-column
                  prop="procdefInfoVO.processDefinitionName"
                  label="流程名称"
                  width="150"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <el-tag
                      @click="getInfo3(scope.row)"
                      style="cursor: pointer"
                      >{{
                        scope.row.procdefInfoVO.processDefinitionName
                      }}</el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="taskName"
                  label="任务名称"
                  width="120"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="processExecutionVO.processInstanceName"
                  label="实例名称"
                  width="150"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <span
                      v-if="scope.row.processExecutionVO.processInstanceName"
                      >{{
                        scope.row.processExecutionVO.processInstanceName.split(
                          "_"
                        )[1]
                      }}</span
                    >
                  </template>
                </el-table-column>
                <el-table-column prop="taskType" label="任务类型" width="102">
                  <template #default="scope">
                    <el-tag type="success"
                      ><span>{{ scope.row.taskType }}</span></el-tag
                    >
                  </template>
                </el-table-column>
                <el-table-column
                  prop="assignee"
                  label="签收者"
                  width="80"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <span v-if="scope.row.assignee">{{
                      scope.row.assignee.split("_")[1]
                    }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="businessType"
                  label="业务类型"
                  width="115"
                  show-overflow-tooltip
                />
                <el-table-column
                  prop="createTime"
                  label="开始时间"
                  width="180"
                  show-overflow-tooltip
                >
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <span>{{
                        times.convertUTCTimeToLocalTime(scope.row.createTime)
                      }}</span>
                    </div>
                  </template>
                </el-table-column>
                <!-- <el-table-column prop="dueDate" label="截止时间" width="180">
                  <template #default="scope">
                    <div style="display: flex; align-items: center">
                      <span>{{
                        times.convertUTCTimeToLocalTime(scope.row.dueDate)
                      }}</span>
                    </div>
                  </template>
                </el-table-column> -->
                <el-table-column label="操作" :fixed="'right'">
                  <template #default="scope">
                    <el-button
                      size="small"
                      @click="getInfo(scope.row)"
                      >流程详情</el-button
                    >
                    <el-button
                      size="small"
                      @click="getInfo3(scope.row)"
                      >办理详情</el-button
                    >
                    <!-- <el-button
                      size="small"
                      type="success"
                      plain
                      :disabled="scope.row.suspended == 2"
                      :title="
                        scope.row.suspended == 2
                          ? '审批流程挂起'
                          : '流程办理高级操作(驳回|终止|委派|转办)'
                      "
                      @click="dealProcess(scope.row)"
                      >办理</el-button
                    > -->
                  </template>
                </el-table-column>
              </el-table>
              <div
                style="
                  display: flex;
                  justify-content: flex-end;
                  margin-top: 8px;
                "
              >
                <el-pagination
                  background
                  @current-change="handleChangePageNum"
                  @size-change="handleChangePageSize"
                  layout="sizes, prev, pager, next, jumper, ->, total, slot"
                  :total="total"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </el-card>
    </div>
    <el-dialog
      :visible.sync="dialogVisible"
      title="流程详情"
      width="70%"
      center
      :close-on-click-modal="false"
    >
      <p v-html="dataSvg" style="text-align: center"></p>
      <el-table :data="formData" border size="large" style="height: 80%">
        <template>
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>
        <el-table-column prop="taskId" label="任务编号" width="180">
          <template #default="scope">
            <el-tag>{{ scope.row.taskId }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="审批人员" width="120" />
        <el-table-column prop="taskName" label="审批节点" width="120" />
        <el-table-column prop="startTime" label="开始时间" width="210">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{
                times.convertUTCTimeToLocalTime(scope.row.startTime)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="taskName" label="审批进度" width="120">
          <template #default="scope">
            <span>{{ scope.row.endTime != null ? "结束" : "审批中" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="审批类型" width="120" />
        <el-table-column prop="time" label="审批时间" width="210">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{
                times.convertUTCTimeToLocalTime(scope.row.time)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="message" label="批注"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          type="goon"
          size="medium"
          @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogPassVisible"
      title="流程通过"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <el-form :model="passForm" ref="ruleFormRef" :rules="rules">
        <el-form-item
          label="下一节点"
          label-width="100px"
          prop="assignee"
          v-show="isEnd"
        >
          <el-cascader
            @change="userChange"
            ref="userRef"
            v-model="passForm.assignee"
            :show-all-levels="false"
            :options="userOptions"
            :props="props"
            class="cascader"
            placeholder="请选择下一节点审批人员"
          >
          </el-cascader>
        </el-form-item>
        <el-form-item label="批注意见" label-width="100px" prop="notation">
          <el-input
            v-model="passForm.notation"
            :rows="3"
            type="textarea"
            placeholder=""
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogPassVisible = false">关 闭</el-button>
          <el-button type="primary" @click="submitForm('ruleFormRef')">
            提交
          </el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :visible.sync="dialogDealVisible"
      title="流程办理"
      width="70%"
      top="5%"
      @closed="getInitiation"
      center
      :close-on-click-modal="false"
    >
      <el-timeline class="time">
        <el-timeline-item
          center
          :timestamp="times.convertUTCTimeToLocalTime(item.startTime)"
          placement="top"
          v-for="(item, index) in taskCommentList"
          :key="index"
          :type="item.typePage"
          :color="item.color"
          :size="item.size"
        >
          <!-- 时间线流程图 -->
          <el-card
            class="el-card-custom"
            v-if="item.type == 'init_type'"
            style="background-color: #f4f4f4; color: #909399"
          >
            <div style="float: left; margin-top: 3px; margin-right: 10px">
              <el-button
                @click="viewProcess(item)"
                type="info"
                circle
              ></el-button>
            </div>
            <div style="float: left">
              <h3 align="left">{{ item.taskName }}</h3>
              <h4 align="left">{{ item.message }}</h4>
            </div>
          </el-card>
          <!-- 历史批注 -->
          <el-card class="el-card-custom" v-if="item.time != null">
            <div style="float: left; margin-top: 30px; margin-right: 10px">
              <el-button type="success" circle></el-button>
            </div>
            <div style="float: left">
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                审批节点：{{ item.taskName }}
              </h4>
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                审批进度：结束
              </h4>
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                审批类型：{{ item.type }}
              </h4>
              <h4 align="left" style="margin: 0; padding: 0; margin-top: 4px">
                批注信息：{{ item.message }}
              </h4>
              <h4
                align="left"
                style="
                  margin: 0;
                  padding: 0;
                  margin-top: 4px;
                  margin-bottom: 4px;
                "
              >
                {{ item.userId }} 提交于
                {{ times.convertUTCTimeToLocalTime(item.time) }}
              </h4>
            </div>
          </el-card>
          <!-- 当前任务 -->
          <el-card
            class="el-card-custom"
            v-if="item.time == null && item.type != 'init_type'"
          >
            <div class="current_task">
              <!-- 审批选项 -->
              <el-tabs type="border-card" v-model="activeName">
                <el-tab-pane name="first"
                  ><!-- 父组件传参数递给子组件 -->
                  <template #label>
                    <span class="audit_pass"
                      ><el-icon><SuccessFilled /></el-icon>审批通过</span
                    >
                  </template>
                  <auditPass
                    :taskId="item.taskId"
                    :isEndTrue="isEnd"
                    :assig="goUser"
                    :users="userOptions"
                    @passShow="passShow"
                  ></auditPass>
                </el-tab-pane>
                <!-- <el-tab-pane  name="second">
                    <template #label>
                      <span class="audit_flow"
                        ><el-icon><Sort /></el-icon>审批流转</span
                      >
                    </template>
                    <auditFlow :taskId="item.taskId" :users='userOptions'></auditFlow>
                  </el-tab-pane> -->
                <el-tab-pane name="third">
                  <template #label>
                    <span class="audit_overrule"
                      ><el-icon><Sort /></el-icon>审批驳回</span
                    >
                  </template>
                  <auditOverrule
                    :taskId="item.taskId"
                    :users="userOptions"
                  ></auditOverrule>
                </el-tab-pane>
                <!-- <el-tab-pane name="fourth">
                    <template #label>
                      <span class="audit_back"
                        ><el-icon><Back /></el-icon>审批回退</span
                      >
                    </template>
                    <auditBack :taskId="item.taskId" :users='userOptions'></auditBack>
                  </el-tab-pane> -->
                <el-tab-pane name="fifth">
                  <template #label>
                    <span class="audit_delegate"
                      ><el-icon><UserFilled /></el-icon>审批委派</span
                    >
                  </template>
                  <auditDelegate
                    :taskId="item.taskId"
                    :users="userOptions"
                  ></auditDelegate>
                </el-tab-pane>
                <el-tab-pane name="sixth">
                  <template #label>
                    <span class="audit_turn"
                      ><el-icon><Avatar /></el-icon>审批转办</span
                    >
                  </template>
                  <auditTurn
                    :taskId="item.taskId"
                    :users="userOptions"
                  ></auditTurn>
                </el-tab-pane>
                <el-tab-pane label="审批终止" name="seventh">
                  <template #label>
                    <span class="audit_termination"
                      ><el-icon><CircleClose /></el-icon
                      >审批终止（审批不通过）</span
                    >
                  </template>
                  <auditTermination :taskId="item.taskId"></auditTermination>
                </el-tab-pane>
              </el-tabs>
            </div>
          </el-card>
        </el-timeline-item>
      </el-timeline>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogDealVisible = false">关 闭</el-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      title="表单详情"
      :visible.sync="dialogVisibleForm"
      width="50%"
      center
      :close-on-click-modal="false"
    >
      <form-create
        :rule="rule"
        :option="option"
        v-if="!isCustomProcess"
        v-model="fromvalue"
      ></form-create>
      <!-- <Suspense v-else>
        <component :is="AsyncComp" :id="id"></component>
      </Suspense> -->
    </el-dialog>
    <el-dialog :visible.sync="writeForm" title="内容详情" width="50%" ref="dia" class="neirongxx">
      <div class="selectPerson">
                        <div class="fujian">
                            <el-upload
                                class="upload-demo"
                                ref="upload"
                                action=""
                                :on-change="filechange"
                                :on-remove="handleRemove"
                                :file-list="fileList"
                                :auto-upload="false">
                                <el-button slot="trigger" size="small" type="primary">附件上传</el-button>
                            </el-upload>
                        </div>
                 <div class="buttons">
                      <el-button size="small" @click="saveWord">保存</el-button>
                       <el-button @click="dealProcess(row)" size="small"  :disabled="row.suspended == 2">提交</el-button>
                </div>
           </div>
           <div class="leftWord" id='cediv'>
            </div>
            <el-table
              :data="fileData"
              border
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
              style="width: 100%">
              <el-table-column
                prop="fileNeme"
                label="文件名"
                width="480">
                <template #default="scope">
                  <span class="filename" @click="downFile(scope.row.filePath,scope.row.fileNeme)">{{scope.row.fileNeme}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createUser"
                label="上传人"
                width="180">
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="上传时间">
              </el-table-column>
            </el-table>
            <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="writeForm = false">关 闭</el-button>
                        </span>
            </template>
     </el-dialog>
  </div>
</template>

<script>
import workLeft from "./workLeft";
import auditPass from "./dealprocess/auditPass.vue";
import auditFlow from "./dealprocess/auditFlow.vue";
import auditOverrule from "./dealprocess/auditOverrule.vue";
import auditBack from "./dealprocess/auditBack.vue";
import auditDelegate from "./dealprocess/auditDelegate.vue";
import auditTurn from "./dealprocess/auditTurn.vue";
import auditTermination from "./dealprocess/auditTermination.vue";
import Vue  from 'vue'
const inputCom = Vue.component('input-component', {
    data() {
      return {
        inputvalue: "",
      }
    },

    template: '<el-input v-model="inputvalue" ref="asdasdas" ></el-input>'
  })
  const selectCom = Vue.component('select-component', {
    data() {
      return {
        options: [{
        }],
        selectvalue: "",
      }
    },
    template: '<el-select v-model="selectvalue" ref="asdasdas"><el-option v-for="item in options" :key="item.value"   :label="item.label"  :value="item.value"></el-option></el-select>'
  })
  const radioCom = Vue.component('radio-component', {
    data() {
      return {
        radio: "",
        word1:'',
      }
    },
    template: '<el-radio v-model="radio" label="1" ref="asdasdas">{{word1}}</el-radio>'
  })
  const checkboxCom = Vue.component('checkbox-component', {
    data() {
      return {
        checked: "",
        word:'',
      }
    },
    template: '<el-checkbox v-model="checked" ref="asdasdas">{{word}}</el-checkbox>'
  })
  const dateCom = Vue.component('date-component', {
    data() {
      return {
        value: "",
        word:'',
      }
    },
    template: '<el-date-picker v-model="value" type="date" placeholder="选择日期" ref="asdasdas" value-format="yyyy-MM-dd">{{word}}</el-date-picker>'
  })
  const imgCom = Vue.component('img-component', {
    data() {
      return {
        url:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG0AAAA2CAYAAADNsRdiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAJjSURBVHhe7ZdRbtNAEIZzAqReAmhDKlVV2zfu0BNUHAScN3jhBj1N2xcEr1HoIxIH4BGhxMPOKlu2o/HaCZJ3Rv4/der17NhW59Ou3RkBd0CaQyDNIZDmEEhzCKQ5BNIcAmkOgTSHQJpDIM0hkOYQSHs/p23zmujDgtrlmzA+CeM50fJsV0D08/ptDCtMXhqLauMg/LQ82oZ4DL83nKXl51tarVamxFWRNps9f2x+fugcc8h92mVYVe0mOFsHad9jJH7dv4iRi7PA879sioStkBfY5u78KZgkjGP96SiKs8LkpfE7jDfCuNJCMFIYhyWqSJNbV034nRaPDxfxaF0YM/mV1januw8Qot8/PsawLIyBtCZ86ndIY769O45HS0xeGvH/Ze2fOLw8nT9tjQlI22HpnUbNq/ABwtL4U18GpNmkeUlfb87oy80iCtLCGpDmEGyPDkH3HFJF2sXl1W70Dy2X0zfPHHJfZkiNJUaRxk0ZGl2U5hLavbScRNaksErVlZY3RjYpNa4UOek8z2s5iTZXqrfAaNL6mtnXqNJ8370Z7XrODamzxqgrLTVEHpm+Zu3TTO3+Eu3Z+zyjJqNvj7IxWsN4PDQkMtdVJxlSYwWT0jRK8zxXirxGI6/NwypVt8eE1qC+mtI1Q2oZzpfmrFL1Q4TpapzM9Z0nUj6f167V6nK68hYYRdqQxsiafc8TKS+PXfTdxyKjv9MYbojWlDyXakqR0Oa6QqLVpLBKFWng/4A0h0CaQyDNIZDmEEhzCKQ5BNIcAmkOgTSHQJpDIM0hkOYQSHMIpDkE0hwCae4g+gtK73o1AaNm4wAAAABJRU5ErkJggg==',
        fit: "fill",
      }
    },
    methods:{
        async qianzi(){
            this.url = 'http://8.130.180.224:8834/api/v1.0/userinfos/account-user-signature/query/LoadElectronicSignature/OU2006280010'
        }
    },
    template: '<el-image @dblclick="qianzi" ref="asdasdas"  style="width:100px;height:50px;cursor: pointer;" :src="url" :fit="fit"><div slot="error" class="image-slot">  <i class="el-icon-picture-outline"></i> </div></el-image>'
  })
  
  let inputel=[]
export default {
  name: "daiban",
  data() {
    return {
      queryPage: {
        deploymentCategory: [],
        pageNum: 1,
        pageSize: 10,
        proDefVersion: "",
        processDefinitionKey: "",
        processDefinitionName: "",
        taskName: "",
      },
      userOptions:[],
      total: 0,
      tableData: [],
      filterText: "",
      dataCategory: [],
      defaultCategoryProps: {
        children: "children",
        label: "categoryName",
      },
      dataSvg: "",
      formData: [],
      rule: [],
      fromvalue: {},
      option: {
        submitBtn: false,
      },
      isCustomProcess: false,
      dialogVisibleForm:false,
      writeForm:false,
      dialogVisible: false,
      dialogPassVisible: false,
      passForm: {
        assignee: "",
        taskId: "",
        type: "",
        other: {},
        notation: "情况属实，同意！",
      },
      rules: {
        notation: [
          { required: true, message: "请输入批注意见", trigger: "blur" },
        ],
        assignee: [
          { required: true, message: "请选择审批人", trigger: "change" },
        ],
      },
      taskCommentList:[],
      dialogDealVisible:false,
      activeName:'first',
      goUser:'',
      isEnd:false,
      id:null,
      props:{
      label: "realName",
      value: "assignee",
      },
      fileData:[],
      fileList:[],
      row:{}
    };
  },
  methods: {
    async getInitiation() {
      const { data: res } = await this.$http2.post(
        "/WaitingDealWithTask/query/WaitingDealWithTaskOptimizePageList",
        this.queryPage
      );
      if (res.code == 200) {
        this.tableData = res.data.pageResult.list;
        this.total = res.data.pageResult.total;
      }
    },
    async getInitiation2() {
      const { data: res } = await this.$http2.post( "/WaitingDealWithTask/query/WaitingDealWithTaskOptimizePageList", this.queryPage );
        if (res.businessCode == 3000) {
          this.dataCategory = res.data.categoryTreeVOList;
          this.tableData = res.data.pageResult.list;
          this.total = res.data.pageResult.total;
        }
    },  
    filterCategoryNode(value, data) {
      if (!value) return true;
      return data.categoryName.indexOf(value) !== -1;
    },
    treeChange(data, checked, node) {
      if (checked.checkedKeys.length !== 0) {
        //treeRef.value!.setCheckedNodes([data], false);
        this.queryPage.deploymentCategory = checked.checkedKeys;
      } else {
        this.queryPage.deploymentCategory = [];
        //treeRef.value!.setCheckedNodes([], false)
      }
      this.getInitiation();
    },
    async getInfo(row) {
      const { data: res } = await this.$http2.get(
        "/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/" +
          row.processExecutionVO.processInstanceId
      );
      this.dataSvg = res;
      const { data: res2 } = await this.$http2.get(
        "/ActRuTask/query/TaskCommentByProcessInstanceId/" +
          row.processExecutionVO.processInstanceId
      );
      this.formData = res2.data;
      this.dialogVisible = true;
    },
    //表单详情
    async getInfo2 (row)  {
      this.isCustomProcess = row.customProcess;
      if (!row.customProcess) {
          const { data: res } = await this.$http2.post(
          "/OperateTable/query/OperateTable",{
            businessKey: row.historyProcessInstanceVO.businessKey,
            tableName: row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.tableName,
          }
          );
          this.rule = JSON.parse(
            row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.formContent
          );
          this.fromvalue = res.data;
      } else {
        this.id = row.activitiInstanceVO.instanceId;
        await this.getAsyncComp(row.procdefInfoVO.processDeploymentPageVO.actBtCategory.componentAddress);
      }
      this.dialogVisibleForm = true;
    },
    //word详情
    async getInfo3 (row)  {
      this.row=row
      this.fileData=row.processInstanceFiles
      const { data: res } = await this.$http2.post(
          "/OperateTable/query/OperateTable",{
            businessKey: row.historyProcessInstanceVO.businessKey,
            tableName: row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.tableName,
          }
          );
      this.writeForm=true;
      let shouzhiForm=row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.formContent
                let  ce=document.getElementById('cediv')
                ce.innerHTML = shouzhiForm;
                var spans=document.querySelectorAll(".s")
                for(let i in row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList){
                    let div= document.createElement("div");
                    div.className='input-div'
                    div.id=spans[i].id
                    if(row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].type=='input'){
                        this.$nextTick(()=>{  
                            let inputcom=Vue.extend({
                                extends:inputCom,
                                })
                            let commSel=new inputcom()
                            let inputelx=commSel.$mount()
                            inputel.push({id:spans[i].id,input:inputelx})
                            div.appendChild(inputelx.$el);
                            commSel.$set(commSel, 'inputvalue', res.data[spans[i].id])
                        })
                    }else if(row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].type=='select'){
                        var selecttcom=Vue.extend({
                        extends:selectCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        let zhidata=row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].optionalValue.split(',')
                        let optobj=[]
                        for(let i in zhidata){
                        optobj.push({
                            label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')
                        })
                        }
                        commSel.$set(commSel, 'options', optobj)
                    }else if(row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].type=='radio'){
                        var selecttcom=Vue.extend({
                        extends:radioCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        commSel.$set(commSel, 'radio', res.data[spans[i].id])
                    }else if(row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].type=='check'){
                        var selecttcom=Vue.extend({
                        extends:checkboxCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        if(res.data[spans[i].id]==1){
                          commSel.$set(commSel, 'checked', true)
                        }else{
                          commSel.$set(commSel, 'checked', false)
                        }
                       
                    }else if(row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].type=='date'){
                        var selecttcom=Vue.extend({
                        extends:dateCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        div.classList.add('hangnei');
                        commSel.$set(commSel, 'value', res.data[spans[i].id])
                    }else if(row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.componentList[i].type=='img'){
                        var selecttcom=Vue.extend({
                        extends:imgCom,
                        })
                        let commSel=new selecttcom()
                        commSel.$set(commSel, 'url', res.data[spans[i].id])
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        div.classList.add('hangnei');
                    }
                    if(spans[i].parentNode){
                        spans[i].parentNode.replaceChild(div, spans[i]);
                    }
                  }
    },
   async downFile(path,name){
      const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
            if(res){
                var elink = document.createElement('a');
                    elink.download = name;
                    elink.style.display = 'none';
                    var blob = new Blob([res]);
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
    },
    getAsyncComp (path){
     // this.AsyncComp= defineAsyncComponent(() => import(`@/views${path}`));
    },
    submitForm(formName) {
      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const { data: res } = await this.$http2.put(
            "/WaitingDealWithTask/update/CompleteTaskAndNoSpecifiedExecutorAssign",
            this.passForm
          );
          if (res.businessCode == 4300) {
            this.$message.success("通过成功");
            this.dialogPassVisible = false;
            this.getInitiation();
          } else {
            this.$message.error("通过失败");
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    passShow(data){
      this.dialogDealVisible=data
    },
    async dealProcess(row){
      const { data: res } = await this.$http2.get(
        "/ActRuTask/query/TaskCommentByProcessInstanceIdFilter/" +
          row.processExecutionVO.processInstanceId
      );
        if (res.code == 200) {
          this.isEnd = row.endEvent;
          console.log(row.assignee);
          this.goUser = row.assignee;
          this.taskCommentList = res.data;
          this.taskCommentList.forEach((item, index) => {
            item["size"] = "large";
            item["typePage"] = "primary";
            if (item.time != null) {
              item["icon"] = "Bottom";
              item["color"] = "#0bbd87";
            } else {
              item["color"] = "#FFB340";
              item["icon"] = "Right";
            }
          });
          let process = {
            taskName: "审批流程图",
            message: "点击查看审批流程图",
            size: "large",
            typePage: "primary",
            icon: "",
            color: "",
            type: "init_type",
            startTime: this.taskCommentList[0].startTime,
            processInstanceId: this.taskCommentList[0].processInstanceId,
          };
          this.taskCommentList.push(process);
          this.taskCommentList = this.taskCommentList.reverse();
          this.dialogDealVisible = true;
        } else {
          this.$message.error("办理前失败");
        }
    },
    async viewProcess (row) {
      const { data: res } = await this.$http2.get(
        "/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/" +
          row.processInstanceId
      );
      this.dataSvg = res;
      const { data: res2 } = await this.$http2.get(
        "/ActRuTask/query/TaskCommentByProcessInstanceId/" +
          row.processInstanceId
      );
      this.formData = res2.data;
      this.dialogVisible = true;
    },
    async saveWord() {
            var formData = new FormData();
            for(let i in this.fileList){
                formData.append("files", this.fileList[i].raw);
            }
            const { data: res2 } = await this.$http2.post('/extension/attache-activity/upload/AttacheActivityBatch', formData)
            if(res2.businessCode==1000){
              let formj = {};
              for (let i in inputel) {
                  formj[inputel[i].id] = inputel[i].input.$refs['asdasdas'].value
              }
              let attacheIds=""
            for(let j in res2.data){
                if(j!=res2.data.length-1){
                    attacheIds+=res2.data[j].attacheId+','
                }else{
                    attacheIds+=res2.data[j].attacheId
                }
              }
              const { data: res } = await this.$http2.post('/OperateTable/update/OperateTable', {
                 businessKey: this.row.historyProcessInstanceVO.businessKey,
                 tableName:  this.row.procdefInfoVO.processDeploymentPageVO.generatorFormVO.tableName,
                 tableFieldsValue:formj,
                 taskId:this.row.taskId,
                 processInstanceId:this.row.processInstanceId,
                 attacheIds:attacheIds
              })
              if (res.businessCode == 4000) {
                  this.$message.success("保存成功");
              } else {
                  this.$message.error("保存失败");
              }
            }
    },
   
    filechange(file,List){
          this.fileList=List
     },
    handleRemove(file, List) {
          this.fileList=List
     },
    diaShow (params) {
      this.dialogDealVisibl = params;
    },
     userChange  (res) {
      this.passForm.assignee = res[res.length - 1];
    },
    handleChangePageSize(val) {
      this.queryPage.pageSize = val;
      this.getInitiation();
    },
    handleChangePageNum(val) {
      this.queryPage.pageNum = val;
      this.getInitiation();
    },
  },
  mounted() {
        this.$refs.dia.rendered=true
    },
  created() {
    this.getInitiation2();
  },
  components: {
    auditPass,
    auditFlow,
    auditOverrule,
    auditBack,
    auditTurn,
    auditDelegate,
    auditTermination,
    workLeft,
  },
};
</script>

<style scoped lang="less">
.clearfix{
  /deep/ .el-input {
  width: 220px;
}
}

.rightCont {
  height: 100%;
  width: calc(100vw - 230px);
  height: calc(92vh);
  display: flex;
  justify-content: space-between;
  /deep/ .el-table__body-wrapper{
  height: 650px;
}
  #CategoryTree {
    width: 14%;
    border: 1px solid #e7e7e7;
    height: 96%;
  }

  #pageList {
    width: 85%;
  }

  .filter-tree {
    overflow: auto;
    flex: 1;
    width: 170px;
    height: 600px;
    margin: 10px;
  }

  .title-left {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    width: 140px;
    height: 22px;
    border-left: 5px solid #569ee3;
    text-align: left;
  }
}
/deep/ .el-card__body{
  padding-top: 0;
}
.filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
.neirongxx{
  /deep/ .el-dialog__body{
    padding-top: 0;
  }
  .selectPerson{
    display: flex;
    justify-content: space-between;
    .buttons{
      height: 40px;
      display: flex;
    }
}
}

</style>
