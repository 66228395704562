<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="uploadModel">下载模板</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column 
                                type="index"
                                label="序号"
                                width="50"
                        >
                        </el-table-column>
                        <el-table-column v-for="(item,index) in generatorBusinessList" :key="index" :prop="item.fieldName"  :label="item.showName"  width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                width="250"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="getInfo(scope.row)"  size="mini">详情</el-button>
                                <el-button  type="primary" @click="getInfo3(scope.$index,scope.row)"  size="mini" >办理详情</el-button >
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
      :visible.sync="dialogVisible"
      title="流程详情"
      width="70%"
      center
      :close-on-click-modal="false"
    >
      <p v-html="dataSvg" style="text-align: center"></p>
      <el-table :data="formData" border size="large" style="height: 80%">
        <template>
          <el-empty :image-size="100" description="暂无数据"></el-empty>
        </template>
        <el-table-column prop="taskId" label="任务编号" width="180">
          <template #default="scope">
            <el-tag>{{ scope.row.taskId }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column prop="userId" label="审批人员" width="120" />
        <el-table-column prop="taskName" label="审批节点" width="120" />
        <el-table-column prop="startTime" label="开始时间" width="210">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{
                times.convertUTCTimeToLocalTime(scope.row.startTime)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="taskName" label="审批进度" width="120">
          <template #default="scope">
            <span>{{ scope.row.endTime != null ? "结束" : "审批中" }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="审批类型" width="120" />
        <el-table-column prop="time" label="审批时间" width="210">
          <template #default="scope">
            <div style="display: flex; align-items: center">
              <el-icon>
                <timer />
              </el-icon>
              <span style="margin-left: 10px">{{
                times.convertUTCTimeToLocalTime(scope.row.time)
              }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="message" label="批注"> </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button
          round
          type="goon"
          size="medium"
          @click="dialogVisible = false"
          >关 闭</el-button
        >
      </span>
       </el-dialog>
        <el-dialog :visible.sync="writeForm" title="内容详情" width="50%" ref="dia" class="neirongxx">
           <div class="leftWord" id='cediv'>
            </div>
            <el-table
              :data="fileData"
              border
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
              style="width: 100%">
              <el-table-column
                prop="fileNeme"
                label="文件名"
                width="480">
                <template #default="scope">
                  <span class="filename" @click="downFile(scope.row.filePath,scope.row.fileNeme)">{{scope.row.fileNeme}}</span>
                </template>
              </el-table-column>
              <el-table-column
                prop="createUser"
                label="上传人"
                width="180">
              </el-table-column>
              <el-table-column
                prop="createTime"
                label="上传时间">
              </el-table-column>
            </el-table>
            <template #footer>
                        <span class="dialog-footer">
                            <el-button @click="writeForm = false">关 闭</el-button>
                        </span>
            </template>
        </el-dialog>
    </div>
  </template>
  
  <script>
  import Vue  from 'vue'
const inputCom = Vue.component('input-component', {
    data() {
      return {
        inputvalue: "",
      }
    },

    template: '<el-input v-model="inputvalue" ref="asdasdas" ></el-input>'
  })
  const selectCom = Vue.component('select-component', {
    data() {
      return {
        options: [{
        }],
        selectvalue: "",
      }
    },
    template: '<el-select v-model="selectvalue" ref="asdasdas"><el-option v-for="item in options" :key="item.value"   :label="item.label"  :value="item.value"></el-option></el-select>'
  })
  const radioCom = Vue.component('radio-component', {
    data() {
      return {
        radio: "",
        word1:'',
      }
    },
    template: '<el-radio v-model="radio" label="1" ref="asdasdas">{{word1}}</el-radio>'
  })
  const checkboxCom = Vue.component('checkbox-component', {
    data() {
      return {
        checked: "",
        word:'',
      }
    },
    template: '<el-checkbox v-model="checked" ref="asdasdas">{{word}}</el-checkbox>'
  })
  const dateCom = Vue.component('date-component', {
    data() {
      return {
        value: "",
        word:'',
      }
    },
    template: '<el-date-picker v-model="value" type="date" placeholder="选择日期" ref="asdasdas" value-format="yyyy-MM-dd">{{word}}</el-date-picker>'
  })
  const imgCom = Vue.component('img-component', {
    data() {
      return {
        url:'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAG0AAAA2CAYAAADNsRdiAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAJjSURBVHhe7ZdRbtNAEIZzAqReAmhDKlVV2zfu0BNUHAScN3jhBj1N2xcEr1HoIxIH4BGhxMPOKlu2o/HaCZJ3Rv4/der17NhW59Ou3RkBd0CaQyDNIZDmEEhzCKQ5BNIcAmkOgTSHQJpDIM0hkOYQSHs/p23zmujDgtrlmzA+CeM50fJsV0D08/ptDCtMXhqLauMg/LQ82oZ4DL83nKXl51tarVamxFWRNps9f2x+fugcc8h92mVYVe0mOFsHad9jJH7dv4iRi7PA879sioStkBfY5u78KZgkjGP96SiKs8LkpfE7jDfCuNJCMFIYhyWqSJNbV034nRaPDxfxaF0YM/mV1januw8Qot8/PsawLIyBtCZ86ndIY769O45HS0xeGvH/Ze2fOLw8nT9tjQlI22HpnUbNq/ABwtL4U18GpNmkeUlfb87oy80iCtLCGpDmEGyPDkH3HFJF2sXl1W70Dy2X0zfPHHJfZkiNJUaRxk0ZGl2U5hLavbScRNaksErVlZY3RjYpNa4UOek8z2s5iTZXqrfAaNL6mtnXqNJ8370Z7XrODamzxqgrLTVEHpm+Zu3TTO3+Eu3Z+zyjJqNvj7IxWsN4PDQkMtdVJxlSYwWT0jRK8zxXirxGI6/NwypVt8eE1qC+mtI1Q2oZzpfmrFL1Q4TpapzM9Z0nUj6f167V6nK68hYYRdqQxsiafc8TKS+PXfTdxyKjv9MYbojWlDyXakqR0Oa6QqLVpLBKFWng/4A0h0CaQyDNIZDmEEhzCKQ5BNIcAmkOgTSHQJpDIM0hkOYQSHMIpDkE0hwCae4g+gtK73o1AaNm4wAAAABJRU5ErkJggg==',
        fit: "fill",
      }
    },
    methods:{
        async qianzi(){
            this.url = 'http://8.130.180.224:8834/api/v1.0/userinfos/account-user-signature/query/LoadElectronicSignature/OU2006280010'
        }
    },
    template: '<el-image @dblclick="qianzi" ref="asdasdas"  style="width:100px;height:50px;cursor: pointer;" :src="url" :fit="fit"><div slot="error" class="image-slot">  <i class="el-icon-picture-outline"></i> </div></el-image>'
  })
  
  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                allData:[],
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                row:{},
                tableName:'',
                htmlBody:'',
                dialogVisible: false,
                componentList:[],
                writeForm:false,
                fileData:[],
                dataSvg:'',
                formData: [],
                menuId:window.sessionStorage.getItem('menuId'),
                modelfiles:{}
            }
        },
        created(){
            this.getProApp()
            this.getLeftMenu()
        },
        methods:{
            async getProApp(){
              if(window.sessionStorage.getItem('isPar')==1){
                window.sessionStorage.setItem('menuId',window.sessionStorage.getItem('parentmenuId').substring(0,window.sessionStorage.getItem('parentmenuId').length-1)+1)
              }
              const {data:res}= await this.$http9.get('/stencils/findStencilsFromByMenuId?menuId='+window.sessionStorage.getItem('menuId'))
              //    const {data:res}= await this.$http9.get('/stencils/findStencilsFromByMenuId?menuId='+index)
                if(res.businessCode!='3000'){
                  this.modelfiles={}
                    this.tableData=[]
                    this.generatorBusinessList=[]
                    return this.$message.error('查询失败！'+res.message)
                }
                this.modelfiles=res.data.file
                this.tableName=res.data.tableName
                this.htmlBody=res.data.htmls.htmlBody
                this.componentList=res.data.componentList
                this.generatorBusinessList=[]
                for(let i in res.data.generatorBusinessList){
                    if(res.data.generatorBusinessList[i].showStatus==1){
                        this.generatorBusinessList.push(res.data.generatorBusinessList[i])
                    }
                }
                this.tableData=[]
                this.allData=res.data.objectMap
                for(let i in res.data.objectMap){
                    this.tableData.push(res.data.objectMap[i].objectMap)
                }
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId='+window.sessionStorage.getItem('parentmenuId'))
                this.menuTable=res.data
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
                window.sessionStorage.setItem('isPar',2)
            },
            async getInfo(row) {
                const { data: res } = await this.$http2.get(
                    "/ActRuExecution/query/HighLightedProcessDiagramByProcessInstanceId/" +
                    row.process_instance_id
                );
                this.dataSvg = res;
                const { data: res2 } = await this.$http2.get(
                    "/ActRuTask/query/TaskCommentByProcessInstanceId/" +
                    row.process_instance_id
                );
                this.formData = res2.data;
                this.dialogVisible = true;
            },
            async getInfo3 (index,row)  {
                this.fileData=this.allData[index].processInstanceFiles
                console.log(this.fileData)
                this.row=row
                const { data: res } = await this.$http2.post(
                    "/OperateTable/query/OperateTable",{
                        businessKey: row.business_key,
                        tableName: this.tableName,
                    }
                    );
                this.writeForm=true;
                let shouzhiForm=this.htmlBody
                let  ce=document.getElementById('cediv')
                ce.innerHTML = shouzhiForm;
                var spans=document.querySelectorAll(".s")
                for(let i in this.componentList){
                    let div= document.createElement("div");
                    div.className='input-div'
                    div.id=spans[i].id
                    if(this.componentList[i].type=='input'){
                        this.$nextTick(()=>{  
                            let inputcom=Vue.extend({
                                extends:inputCom,
                                })
                            let commSel=new inputcom()
                            let inputelx=commSel.$mount()
                            inputel.push({id:spans[i].id,input:inputelx})
                            div.appendChild(inputelx.$el);
                            commSel.$set(commSel, 'inputvalue', res.data[spans[i].id])
                        })
                    }else if(this.componentList[i].type=='select'){
                        var selecttcom=Vue.extend({
                        extends:selectCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        let zhidata=this.componentList[i].optionalValue.split(',')
                        let optobj=[]
                        for(let i in zhidata){
                        optobj.push({
                            label:zhidata[i].replace(/"/g, ''),  value:zhidata[i].replace(/"/g, '')
                        })
                        }
                        commSel.$set(commSel, 'options', optobj)
                    }else if(this.componentList[i].type=='radio'){
                        var selecttcom=Vue.extend({
                        extends:radioCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        commSel.$set(commSel, 'radio', res.data[spans[i].id])
                    }else if(this.componentList[i].type=='check'){
                        var selecttcom=Vue.extend({
                        extends:checkboxCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        if(res.data[spans[i].id]==1){
                          commSel.$set(commSel, 'checked', true)
                        }else{
                          commSel.$set(commSel, 'checked', false)
                        }
                    }else if(this.componentList[i].type=='date'){
                        var selecttcom=Vue.extend({
                        extends:dateCom,
                        })
                        let commSel=new selecttcom()
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        commSel.$set(commSel, 'value', res.data[spans[i].id])
                        div.classList.add('hangnei');
                    }else if(this.componentList[i].type=='img'){
                        var selecttcom=Vue.extend({
                        extends:imgCom,
                        })
                        let commSel=new selecttcom()
                        commSel.$set(commSel, 'url', res.data[spans[i].id])
                        let selectel= commSel.$mount() 
                        inputel.push({id:spans[i].id,input:selectel})
                        div.appendChild(selectel.$el);
                        div.classList.add('hangnei');
                    }
                    if(spans[i].parentNode){
                        spans[i].parentNode.replaceChild(div, spans[i]);
                    }
                  }
             },
            async  downFile(path,name){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
           async uploadModel(){
              const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:this.modelfiles.filePath },{ responseType: 'blob'})
            if(res){
                var elink = document.createElement('a');
                    elink.download = this.modelfiles.fileName;
                    elink.style.display = 'none';
                    var blob = new Blob([res]);
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
            }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        this.$refs.dia.rendered=true
    },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
  </style>
  