<template>
    <div class="bg">
        <div id="asiderBar">
        <el-menu
                text-color="black"
                active-text-color="#fff"
                :collapse="isZhank"
                :collapse-transition="false"
                :router="true"
                :default-active="$route.path"
        >
            <el-menu-item v-for="item in menuTable" :index="item.path" :key="item.path" @click="getMenuId(item)">
                <i :class="item.meta.icon"></i>
                <span>{{item.name}}</span>
            </el-menu-item>
        </el-menu>
      </div>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="btnheader" >
                  <el-button  icon="el-icon-circle-plus-outline" @click="beforeuploadModel">上传文件</el-button>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            height="100%"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                          >
                        <el-table-column 
                                type="index"
                                label="序号"
                                width="50"
                        >
                        </el-table-column>
                        <el-table-column prop="fileNeme"  label="附件名"  width="550"
                        >  <template #default="scope">
                          <span class="filename" @click="yulan(scope.row.filePath)">{{scope.row.fileNeme}}</span>
                        </template>
                        </el-table-column>
                        <el-table-column prop="createUser"  label="上传人"  width="150" >  
                        </el-table-column>
                        <el-table-column prop="createTime"  label="上传时间"  width="180" >  
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="downFile(scope.row.filePath,scope.row.fileNeme)"  size="mini">下载</el-button>
                                <el-button  type="primary" @click="shanchu(scope.row)"  size="mini" >删除</el-button >
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
   
        <el-dialog :visible.sync="writeForm" title="附件上传" width="50%" >
            <div class="fujian">
                              <el-upload
                                  class="upload-demo"
                                  ref="upload"
                                  action=""
                                  :on-change="filechange"
                                  :on-remove="handleRemove"
                                  :file-list="fileList"
                                  :auto-upload="false"
                                  multiple>
                                  <el-button slot="trigger" size="small" type="primary">选择附件</el-button>
                                  <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                              </el-upload>
                              <el-progress :percentage="percentage" v-if="percentage > 0"></el-progress>
                          </div>
                  <template #footer>
                              <span class="dialog-footer">
                                  <el-button @click="writeForm = false">关 闭</el-button>
                                  <el-button @click="uploadModel" :loading="loadbut">{{loadbutext}}</el-button>
                              </span>
                  </template>
        </el-dialog>
    </div>
  </template>
  
  <script>

  let inputel=[]
    export default {
        name: "commonTable",
        data(){
            return{
                loadbut:false,
                loadbutext:"上 传",
                tableData:[],
                generatorBusinessList:[],
                menuTable:[],
                isZhank: false,
                dialogVisible: false,
                writeForm:false,
                fileList:[],
                menuId:window.sessionStorage.getItem('menuId'),
                progress: 0,
                percentage:0,
            }
        },
        created(){
            this.getLeftMenu()
            this.getProApp()
            const load = this.$loading({
            lock:true,
            text:'Loading',
            spinner:'el-icon-loading',
            background:'rgba(0,0,0,0.7)'
            });
            load.close();
        },
        methods:{
            async getProApp(){
              const {data:res}= await this.$http2.post('/extension/attache-activity/query/AttacheActivityBatchByMenuId',{
                menuId:'10014',
                instanceId:this.$root.proId,
                pageNum:0,
                pageSize:999
              })
              this.tableData=res.list
              console.log(this.tableData)
            },
            async getLeftMenu(){
                const {data:res}= await this.$http0.get('/v1/account/menu/query/findAllByParentId?parentId=2409262CZG9R130H')
                this.menuTable=[]
                for(let i in res.data){
                  if(res.data[i].belongOrg==1){
                    this.menuTable.push(res.data[i])
                  }
                }
            },
            getMenuId(item){
                window.sessionStorage.setItem('menuId',item.menuId)
            },
            filechange(file,List){
            this.fileList=List
            },
            handleRemove(file, List) {
                    this.fileList=List
              },
            async shanchu (row)  {
              const confirmResult = await this.$confirm('此操作将永久删除该文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http2.delete('/extension/attache-activity/delete/AttacheActivityByAttacheId?attacheId='+row.attacheId)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除失败！'+res.message)
                }
                this.$message.success('删除成功！');
                this.getProApp();
             },
             async yulan(path){
                window.open('http://8.130.180.224:9000/epc/'+path)
             },
            async downFile(path,name){
                  const {data:res}= await this.$http8.post('/v1/file/common/download',{  filePath:path },{ responseType: 'blob'})
              if(res){
                  var elink = document.createElement('a');
                      elink.download = name;
                      elink.style.display = 'none';
                      var blob = new Blob([res]);
                      elink.href = URL.createObjectURL(blob);
                      document.body.appendChild(elink);
                      elink.click();
                      document.body.removeChild(elink);
                  }
            },
            beforeuploadModel(){
                this.fileList=[]
                this.writeForm=true
            },
           async uploadModel(){
            this.loadbut = true
            this.loadbutext = "提交中..."
            var formData = new FormData();
            for(let i in this.fileList){
                formData.append("files", this.fileList[i].raw);
            }
            formData.append("menuId",'10014');
            formData.append("instanceId", this.$root.proId);
            const config = {
                    onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        this.percentage = Math.round((progressEvent.loaded / progressEvent.total) * 100);
                        console.log(this.percentage)
                    }
                    }
                };
            const { data: res2 } = await this.$http2.post('/extension/attache-activity/upload/AttacheActivityBatchByInstanceId', formData,config)
            if(res2.businessCode==1000){
                this.$message.success("提交成功");
                this.getProApp()
                this.percentage = 0
                this.loadbut = false
                this.loadbutext = "上 传"
                this.writeForm = false;
            }else{
              this.$message.error("提交失败！");
            }
        }
        },
        watch: {
            $route(to, from) {
                this.getProApp()
                this.getLeftMenu()
            },
           
        },
        mounted() {
        },
    }
  </script>
  
  <style scoped lang="less">
    .serverTable{
        position: absolute;
        width: 97%;
        padding: 0%;
        /*height: 100%;*/
        height: calc(100% - 140px);
    }
   .upload{
      color: #0000ff;
      cursor: pointer;
   }
  /deep/ .el-table__body-wrapper{
   height: 92.6% !important;
   }
   .toggle-button {
        background-color: #4a5064;
        font-size: 10px;
        line-height: 24px;
        color: #eef5ff;
        text-align: center;
        letter-spacing: 0.2em;
        cursor: pointer;
    }
    .el-aside {
        background-color: #333744;
    }
    .filename{
  cursor: pointer;
  text-decoration: underline;
  color: #569ee3;
}
#asiderBar .el-menu-item{
  left: 2%;
  width: 96%;
  padding-left: 5px !important;
}
  </style>
  