<template>
  <div>
    <table class="formtable">
      <tr>
        <td class="td450" colspan="4">项目名称: <el-input v-model="addFrom.projectName" class='tonghang'></el-input></td>
        <td class="td270">阶段：  <el-input v-model="addFrom.section"  class='tonghang3'></el-input></td>
      </tr>
      <tr>
        <td class="td450" colspan="4">业主名称：<el-input v-model="addFrom.ownerName" class='tonghang'></el-input></td>
        <td class="td270">联系人：<el-input v-model="addFrom.associates" class='tonghang3'></el-input> </td>
      </tr>
      <tr>
        <td class="td225" colspan="2">主持人：<el-input v-model="addFrom.moderator" class='tonghang4'></el-input></td>
        <td class="td225" colspan="2">会议地点：<el-input v-model="addFrom.conferenceVenue" class='tonghang4'></el-input> </td>
        <td class="td270">日期：<el-date-picker v-model="addFrom.time"  type="date"  ></el-date-picker> </td>
      </tr>
      <tr>
        <td colspan="5" rowspan="5">
          评审内容：<el-input
            type="textarea"
            :rows="5"
            placeholder="（项目质量计划内容摘要。）"
            v-model="addFrom.contentReview"
          >
          </el-input>
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td class="td80" rowspan="7">评<br>审<br>意<br>见</td>
        <td colspan="4" rowspan="7">
          <el-input
            type="textarea"
            :rows="5"
            placeholder=""
            v-model="textarea"
          >
          </el-input>
          <p style="margin-left:450px">主持人：</p>
          <p style="margin-left:450px">日期：</p>
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td class="td80" rowspan="6">评<br>审<br>人<br>员<br>签<br>字</td>
        <td class="td125" >姓名 </td>
        <td class="td225 center">部门 </td>
        <td colspan="2" class="td125">签字 </td>
      </tr>
      <tr>
        <td class="td125" ><el-input></el-input> </td>
        <td class="td225 center">项目部工程管理部 </td>
        <td class="td125" colspan="2"><el-input></el-input>  </td>
      </tr>
      <tr>
        <td class="td125" ><el-input></el-input> </td>
        <td class="td225 center"> </td>
        <td class="td125" colspan="2"><el-input  ></el-input> </td>
      </tr>
      <tr>
        <td class="td125" ><el-input  ></el-input> </td>
        <td class="td225 center"> </td>
        <td class="td125" colspan="2"><el-input  ></el-input> </td>
      </tr>
      <tr>
        <td class="td125" ><el-input  ></el-input> </td>
        <td class="td225 center"> </td>
        <td class="td125" colspan="2"><el-input  ></el-input> </td>
      </tr>
      <tr>
        <td class="td125" ><el-input  ></el-input> </td>
        <td class="td225 center"> </td>
        <td class="td125" colspan="2"><el-input  ></el-input> </td>
      </tr>
      <tr>
        <td colspan="5" rowspan="3">
          项目经理：<el-input
            type="textarea"
            :rows="2"
          >
          </el-input>
          <div>
         <span style="margin-left:200px">签字</span> <el-input   class="tonghang3"></el-input> 
         日期
          </div>
        
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    someData: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdd:{
      type: Boolean,
    }
  },
  name: "",
  data() {
    return {
      addFrom: {
        projectName: '',
        section: "",
        ownerName: "",
        associates: '',
        moderator: "",
        conferenceVenue: "",
        time: "",
        projectId:"",
        contentReview: "",
        attacheIds:[]
      },
    };
  },
  created() {
    if(!this.isAdd){
      this.addFrom=this.someData
    }
    this.addFrom.projectName=window.sessionStorage.getItem("proName"),
    this.addFrom.projectId=window.sessionStorage.getItem("proId")
  },
  methods: {},
  components: {},
  watch: {
    someData(newVal, oldVal) {
      //对引用类型的值无效
      this.addFrom = newVal;
      this.addFrom.projectName = window.sessionStorage.getItem("proName");
      this.addFrom.projectId = window.sessionStorage.getItem("proId");
    },
  },
};
</script>

<style scoped lang="less">
.formtable {
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  border-collapse: collapse;
  border-spacing: 0;
  tr td {
    line-height: 30px;
    font-size: 16px;
    padding: 0 4px;
    border: 1px solid #000;
    //border-width: 1px 0 0 1px;
  }
 .td80{
  width: 80px;
  text-align: center;
 }
 .td125{
  text-align: center;
  width: 125px;
 }
 .center{
  text-align: center;
 }
 .td225{
  width: 225px;
 }
  .td450 {
    width: 450px;
  }
  .td270 {
    width: 270px;
  }
}
/deep/ .el-input__inner {
  padding: 0 !important;
  border: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: center;
}
.tonghang {
  display: inline-block;
  width: 350px;
  height: 30px !important;
}
.tonghang2 {
  display: inline-block;
  width: 100px;
  height: 30px !important;
  border-bottom: 1px solid #000;
}
.tonghang3 {
  display: inline-block;
  width: 180px;
  height: 30px !important;
}
.tonghang4 {
  display: inline-block;
  width: 140px;
  height: 30px !important;
}
.td30 {
  width: 30px;
}
/deep/ .el-date-editor {
  padding-left: 25px;
  width: 100px !important;
}
/deep/ .el-input__prefix {
  top: -5px !important;
}
</style>
