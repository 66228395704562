<template>
  <el-container class="contain">
    <el-header>
      <daohangnav></daohangnav>
    </el-header>
    <el-container>
     <!-- <tianbleft></tianbleft>-->
      <router-view name='left'></router-view>
      <el-main>
        <router-view name='main'></router-view>
      </el-main>
    </el-container>

  </el-container>
</template>

<script>
  import daohang from '../daohanglan.vue';
  import tbleft from './houTaiLeft.vue';
  export default {
    data () {
      return {
      }
    },
    methods: {

    },
    components:{
      daohangnav: daohang,
      tianbleft:tbleft,
    },

  }
</script>

<style lang="less" scoped>
  .contain {
    height: 100vh;
   background-color: #05081B;
  }
  .el-main {
    background-color: #eff6fb ;
    padding: 0px !important;
  }
  /deep/ .el-container{
    background-color: #eff6fb !important;
  }

</style>
