<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <div id="kaoqinghome">
            <table data-v-3286e6af="" class="content">
                <tr data-v-3286e6af="">
                    <td data-v-3286e6af="" class="cor4" @click="kaoqing">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">考勤列表</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">在线模型更专业</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">缺勤管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">工作任务跟踪</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">请假管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">工作任务跟踪</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">加班管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">工作任务跟踪</span>
                            </div>
                        </div>
                    </td>
                </tr>
                <tr data-v-3286e6af="">
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title"><span data-v-3286e6af="">考勤计算</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">在线模型更专业</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">设计管理</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">工作任务跟踪</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">汇总报表</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">工作任务跟踪</span>
                            </div>
                        </div>
                    </td>
                    <td data-v-3286e6af="" class="cor4">
                        <div data-v-3286e6af="" class="grid-content">
                            <div data-v-3286e6af="" class="icon-btn">
                                <img data-v-3286e6af="" src="../../assets/img/daohang/project-info-selected.png">
                            </div>
                            <div data-v-3286e6af="" class="title">
                                <span data-v-3286e6af="">考勤统计</span>
                            </div>
                            <div data-v-3286e6af="" class="slogan"><span data-v-3286e6af="">工作任务跟踪</span>
                            </div>
                        </div>
                    </td>
                </tr>
            </table>
        </div>
        <x-dbottom></x-dbottom>
    </div>
</template>

<script>
    export default {
        name: "kaoqinghome",
        methods:{
            kaoqing(){
                this.$router.push({ path: "/kaoList" });
            }
        }
    }
</script>

<style scoped lang="less">
    .bg{
        background-color: #05081B;
        height: 100vh;
    }
    #kaoqinghome{
        background-color: #111224;
        position: absolute;
        left: 10px;
        top: 70px;
        bottom: 72px;
        right: 10px;
        width: 1900px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    .content[data-v-3286e6af] {
        width: 83.35%;
        margin: 0 auto;
        -webkit-box-shadow: 0 12px 24px -6px rgba(9, 30, 66, .25);
        box-shadow: 0 12px 24px -6px rgba(9, 30, 66, .25);
        border: none;
        border-collapse: collapse;
        margin-top: 70px;
    }
     .cor4 {
        background-image: url('../../assets/img/kaoqing/kq1.png'), url('../../assets/img/kaoqing/kq2.png'), url('../../assets/img/kaoqing/kq3.png'), url('../../assets/img/kaoqing/kq4.png') !important;
        background-repeat: no-repeat, no-repeat, no-repeat, no-repeat !important;
        background-position: 0 0, 100% 0, 0 100%, 100% 100% !important;
        padding: 2px !important;
        border: 0 !important;
        background-color: transparent !important;
    }
    .content tr td[data-v-3286e6af] {
        width: 25%;
    }
    .content tr td .grid-content[data-v-3286e6af] {
        height: 168px;
        -webkit-box-sizing: content-box;
        box-sizing: content-box;
        padding: 63px 5px;
        color: #fff;
        background-color: #212641;
        text-align: center;
    }
    .content tr td .grid-content .icon-btn[data-v-3286e6af] {
        height: 52px;
        margin-bottom: 20px;
    }
    .content tr td .grid-content .title[data-v-3286e6af] {
        font-weight: 700;
        margin-bottom: 16px;
        span{
            font-size: 15px;
        }

    }
    .content tr td .grid-content .slogan[data-v-3286e6af] {
        font-size: 16px;
        color: #ccc;
    }
</style>
