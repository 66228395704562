<template>
    <div class="bg">
        <work-left></work-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-input placeholder="请输入标题查询" v-model="queryInfo.userName" class="input-with-select" clearable @clear="getlcList">
                        <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getlcList" ></el-button>
                    </el-input>
                    <!--    <el-button  icon="el-icon-search" style="margin-left: 10px" @click="dialogVisible=true">发起公告</el-button>
                        <el-button icon="el-icon-search">编辑公告</el-button>
                        <el-button  icon="el-icon-search">删除公告</el-button>
                        <el-button  icon="el-icon-search">下载公告</el-button>-->
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="processName"
                                label="流程名称"
                                width="350"
                        >
                        </el-table-column>

                        <el-table-column
                                prop="selfTaskToDealDto.name"
                                label="待办流程节点名称"
                                width="350"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="initiatingUserName"
                                label="发起人"
                                width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="selfTaskToDealDto.createTime"
                                label="发起时间"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <!--<el-tooltip effect="dark" content="查看流程" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-view" size="mini" @click="chakan(scope.row)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="拾取任务" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-video-play" size="mini" @click="shiqu(scope.row)" ></el-button>
                                </el-tooltip>-->
                                <el-button  type="primary" @click="chakan(scope.row)" icon="el-icon-view" size="mini">查看</el-button>
                                <el-button  type="primary" @click="shiqu(scope.row)" icon="el-icon-video-play" size="mini">拾取</el-button>
                                <!-- <el-tooltip effect="dark" content="删除公告" placement="top" :enterable="false">
                                     <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                 </el-tooltip>-->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="流程查看"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                center
        >
            <div slot="footer" class="dialog-footer">
                <div id="sbgimg">
                </div>
            </div>
            <el-table
                    :data="liuchengData"
                    border
                    height="240px"
                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
                    style="width: 100%;">
                <el-table-column
                        type="index"
                        label="序号"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="assigneeName"
                        label="办理人"
                        width="100"
                >
                </el-table-column>
                <el-table-column
                        prop="startDate"
                        label="开始办理时间"
                        width="240"
                >
                </el-table-column>
                <el-table-column
                        prop="endDate"
                        label="结束办理时间"
                        width="240"
                >
                </el-table-column>
                <el-table-column
                        prop="comments"
                        label="办理意见"
                >
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
                title="处理任务"
                :close-on-click-modal="false"
                :visible.sync="formDialogVisible"
                width="50%"
                center>
            <form-create v-model="fApi" :rule="rule" :option="option" :value.sync="value" style="border-bottom:1px solid #fffaaa"></form-create>
            <br style="border: 1px solid red">
            <el-form ref="addFormRef"  label-width="125px" >
            <el-form-item label="处理结果">
            <el-select v-model="clresult" placeholder="请选择处理结果" @change="isShow">
                <el-option
                        v-for="item in options"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value">
                </el-option>
            </el-select>
            </el-form-item>
            <el-form-item label="交办人" v-show="show">
                <el-cascader
                        :options="yonghu"
                        :props="props"
                        v-model="recvUser"
                        collapse-tags
                        clearable></el-cascader>
            </el-form-item>
            <el-form-item label="处理备注">
            <el-input
                    type="textarea"
                    :autosize="{ minRows: 2}"
                    placeholder="请输入处理备注"
                    v-model="clremake">
            </el-input>
            </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <div id="sbgimg2" >
                </div>
    <el-button @click="queding">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import workLeft from './workLeft'
    export default {
        name: "daiban",
        data(){
            return{
                queryInfo:{
                    processName:'',
                    processDescription:'',
                    pageNumber:1,
                    pageSize:10,
                },
                total:0,
                tableData:[],
                dialogVisible:false,
                show:true,
                formDialogVisible:false,
                fApi: {},
                //表单数据
                value: {},
                //表单生成规则
                rule: [
                ],
                //组件参数配置
                option: {
                    submitBtn:{
                        show:false
                    }
                },
                options: [{
                    value: '1',
                    label: '同意'
                }, {
                    value: '2',
                    label: '驳回'
                }],
                clresult:'',
                yonghu:[],
                clremake:'',
                props: {
                    multiple: true,
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                recvUser:[],
                taskId:'',
                processInstanceId:'',
                liuchengData:[]
            }
        },
        methods:{
            async getlcList(){
                const {data:res}= await this.$http3.get('/v1.0/activiti/process/queryGroupTaskByUserIdAndProjectId?projectId='+this.$root.proId)
                if(res.status!='3000'){
                    return  this.tableData=[];
                    //return this.$message.error('查询待办列表失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            async chakan(row){
                console.log(row)
                const {data:result}=await this.$http3.get('/v1.0/activiti/process/queryFlowImgByProcInstId?procInstId='+row.selfTaskToDealDto.processInstanceId);
                var index=result.lastIndexOf("<svg");
                const {data:result2}=await this.$http3.get('/v1.0/activiti/process/queryHistoryProcessByInstanceIdAndBusinessId?processInstanceId='+row.selfTaskToDealDto.processInstanceId+'&businessKey='+row.selfTaskToDealDto.businessKey);
                this.liuchengData=result2.data;
                this.dialogVisible=true
                document.getElementById('sbgimg').innerHTML=(result.substring(index,result.length-1))
            },
            async shiqu(row){
                console.log(row)
                const {data:result}=await this.$http3.get('/v1.0/activiti/process/claimTask?taskId='+row.selfTaskToDealDto.taskId);
                if(result.status=='8050'){
                    this.$message.success('拾取任务成功！请到个人待办办理任务')
                    this.getlcList()
                }else {
                    this.$message.error('拾取任务失败！')
                }

            },
            isShow(row){
                console.log(row)
                if(row=='2'){
                    this.show=false
                }else {
                    this.show=true
                }
            },
           async queding(){
                console.log(this.recvUser[0][1])
                if(this.clresult=='1'){
                    const {data:result}=await this.$http3.get('/v1.0/activiti/process/completeProcess?userId='+this.recvUser[0][1]+'&taskId='+this.taskId+'&remark='+this.clremake);
                    if(result.status!='8001'){
                        return this.$message.error('处理流程失败')
                    }
                    this.$message.success('处理流程成功！')
                    this.formDialogVisible=false
                    this.getlcList()
                }else {
                    const {data:result2}=await this.$http3.get('/v1.0/activiti/process/backProcess?processInstanceId='+this.processInstanceId);
                    if(result2.status!='8003'){
                        return this.$message.error('处理流程失败')
                    }
                    this.$message.success('处理流程成功！')
                    this.formDialogVisible=false
                    this.getlcList()
                }
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getlcList()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getlcList()
            },
        },
        created(){
            this.getlcList();
            this.getUsers();
        },
        components:{
            workLeft
        }
    }
</script>

<style scoped lang="less">
    .input-with-select{
        width: 280px;
        .el-button{
            padding: 10px 15px;
        }
    }
    #sbgimg{
        width: 100%;max-width: 950px;overflow:auto;max-height: 400px;
    }
    #sbgimg2{
        width: 100%;max-width: 950px;overflow:auto;max-height: 400px;
    }
</style>
