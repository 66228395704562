<template>
  <div>
    <table class="formtable">
      <tr>
        <td class="td120">合同名称</td>
        <td class="td220">
          <el-input v-model="addFrom.contractName"></el-input>
        </td>
        <td class="td120">合同金额<br />（万元）</td>
        <td class="td220">
          <el-input v-model="addFrom.contractAmount"></el-input>
        </td>
      </tr>
      <tr>
        <td class="td120">合同序号</td>
        <td class="td220">
          <el-input v-model="addFrom.contractSerial"></el-input>
        </td>
        <td class="td120">合同签订日期</td>
        <td class="td220">
          <el-date-picker
            v-model="addFrom.dateSign"
            type="date"
          ></el-date-picker>
        </td>
      </tr>
      <tr>
        <td class="td120">所属项目名称</td>
        <td class="td220">
          <el-input v-model="addFrom.projectName"></el-input>
        </td>
        <td class="td120">合同类型</td>
        <td class="td220">
          <el-radio v-model="addFrom.contractType" label="收入">收入</el-radio>
          <el-radio v-model="addFrom.contractType" label="支出">支出</el-radio>
        </td>
      </tr>
      <tr>
        <td class="td120">甲方单位</td>
        <td class="td220">
          <el-input v-model="addFrom.partyUnit"></el-input>
        </td>
        <td class="td120">乙方单位</td>
        <td class="td220">
          <el-input v-model="addFrom.reciprocity"></el-input>
        </td>
      </tr>
      <tr>
        <td colspan="4" class="td120">合同内容简要</td>
      </tr>
      <tr>
        <td colspan="4" class="td120" rowspan="4">
          <el-input
            type="textarea"
            :rows="4"
            placeholder="合同范围、履行期限、支付条款、其他事项说明等内容。"
            v-model="addFrom.contractConcise"
          >
          </el-input>
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td colspan="4" class="td120">审核意见</td>
      </tr>
      <tr>
        <td colspan="4" rowspan="6">
            &emsp;<el-radio  label="1">同意</el-radio><br>
            &emsp;<el-radio  label="1">其他意见，请填写：</el-radio> <el-input
            type="textarea"
            :rows="4"
          >
          </el-input>
           <p style="margin-left:200px">工程总承包分公司合同管理部主任签字：</p>
           <p style="margin-left:300px">日期：</p>
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td colspan="4" rowspan="5">
          &emsp;<el-radio  label="1">同意</el-radio><br>
            &emsp;<el-radio  label="1">其他意见，请填写：</el-radio> <el-input
            type="textarea"
            :rows="4"
          >
          </el-input>
           <p style="margin-left:220px">工程总承包分公司负责人签字：</p>
           <p style="margin-left:300px">日期：</p>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    someData: {
      type: Object,
      default() {
        return {
        };
      },
    },
    isAdd:{
      type: Boolean,
    }
  },
  name: "",
  data() {
    return {
      addFrom: {
        projectName: "",
        contractAmount: "",
        contractConcise: "",
        projectId: "",
        contractSerial: "",
        contractName: "",
        contractType: "",
        dateSign: "",
        partyUnit: "",
        reciprocity: "",
        attacheIds:[]
      },
    };
  },
  created() {
    if(!this.isAdd){
      this.addFrom=this.someData
    }
    this.addFrom.projectName = window.sessionStorage.getItem("proName"),
    this.addFrom.projectId = window.sessionStorage.getItem("proId");
    // this.input=this.someData.input
    // this.input1=this.someData.input1
  },
  methods: {},
  components: {},
  watch: {
    someData(newVal, oldVal) {
      //对引用类型的值无效
      this.addFrom = newVal;
      (this.addFrom.projectName =
        window.sessionStorage.getItem("proName")),
        (this.addFrom.projectId = window.sessionStorage.getItem("proId"));
    },
  },
};
</script>

<style scoped lang="less">
.formtable {
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  border-collapse: collapse;
  border-spacing: 0;
  tr td {
    line-height: 30px;
    font-size: 16px;
    padding: 0 4px;
    border: 1px solid #000;
    //border-width: 1px 0 0 1px;
  }
  .td120 {
    width: 120px;
    text-align: center;
  }
  .td150 {
    width: 150px;
  }
  .td220 {
    width: 220px;
  }
}
/deep/ .el-input__inner {
  padding: 0 !important;
  border: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: center;
}
.tonghang {
  display: inline-block;
  width: 100px;
  height: 30px !important;
  border-bottom: 1px solid #000;
}
.tonghang2 {
  display: inline-block;
  width: 100px;
  height: 30px !important;
}
.tonghang3 {
  display: inline-block;
  width: 200px;
  height: 30px !important;
}
.td30 {
  width: 30px;
}
/deep/ .el-date-editor {
  padding-left: 25px;
  width: 100px !important;
}
/deep/ .el-input__prefix {
  top: -5px !important;
}
</style>
