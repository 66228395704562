<!--
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2023-01-09 16:23:13
 * @LastEditors: @zhang-hongZ 15681179637@163.com
 * @LastEditTime: 2023-01-11 16:52:44
 * @FilePath: \pages-demo\src\views\process\pages\work\dealprocess\auditOverrule.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<!--
 * @Author: 龙雨欢 932838577@qq.com
 * @Date: 2023-01-09 16:23:13
 * @LastEditors: @zhang-hongZ 15681179637@163.com
 * @LastEditTime: 2023-01-11 16:53:05
 * @FilePath: \pages-demo\src\views\process\pages\work\dealprocess\auditPass.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="containers">
    <el-form :model="passForm" ref="ruleFormRef" :rules="rules" status-icon>
      <el-form-item label="驳回意见" label-width="100px" prop="notation">
        <el-input
          v-model="passForm.notation"
          :rows="3"
          type="textarea"
          placeholder=""
        />
      </el-form-item>
    </el-form>
    <span class="footer">
      <el-button type="primary" @click="submitForm(ruleFormRef)">
        提交
      </el-button>
    </span>
  </div>
</template>
<script>
export default {
  props:{
    taskId:{},
    users:{}
  },
  data() {
    return {
      passForm :{
        taskId: this.taskId,
        notation: "情况属实，同意！",
      },
      props2 :{
        label: "realName",
        value: "assignee",
      },
      rules:{
        notation: [{ required: true, message: "请输入驳回意见", trigger: "blur" }],
      }
    }
  },
  methods:{
    userChange(res){
      passForm.value.assignee = res[res.length - 1];
    },
    submitForm(formName){
        this.$refs[formName].validate(async (valid) => {
          if (valid) {
            taskDismissReturnPreviousNode(passForm.value).then((res) => {
        if (res.businessCode == 4300) {
          ElMessage.success("驳回成功");
        } else {
          ElMessage.error("驳回失败");
        }
      });
          } else {
            console.log("error submit!");
          }
        });
    }
  }

}

</script>
<style lang="less">
.containers {
  text-align: center;
}
</style>
