<template>
  <div class="bg">
     <div class="mainpart">
    <el-card class="box-card"  style="height: 100%;">
      <div slot="header" class="clearfix">
        <el-button size="medium" type="goon"  @click="dialogVisible = true" style="" >创建部门</el-button>
        <span style="" class="fanhui" type="text" @click="goback">返回</span>
      </div>
      <div class="serverTable">
      <el-table
        :data="tableData"
        height="100%"
        :header-cell-style="{background:'#e3ecfb',color:'black'}"
        style="width: 100%;background-color: #142245">
        <el-table-column
          type="selection"
        >
        </el-table-column>
        <el-table-column
          type="index"
          label="序号"
         width="50"
        >
          <template v-slot="scope">
            {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
          </template>
        </el-table-column>
        <el-table-column
          prop="name"
          label="部门名称"
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          prop="principalName"
          label="部门负责人"
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          label="所属单位"
          prop='unitName'
          min-width="25%"
        >
        </el-table-column>
        <el-table-column
          label="操作"
          align="center"
          min-width="13%"
        >
          <template v-slot="scope">
            <el-tooltip effect="dark" content="修改部门" placement="top" :enterable="false" v-has="'editbumen'">
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
            </el-tooltip>
            <el-tooltip effect="dark" content="删除部门" placement="top" :enterable="false" v-has="'delbumen'">
              <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>

      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryInfo.pageNumber"
        :page-sizes="[5,10, 20, 50]"
        :page-size="queryInfo.pageSize"
        layout="total"
        :total="total">
      </el-pagination>
      </div>
    </el-card>
    <el-dialog
      title="添加部门"
      :visible.sync="dialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="dialogClose"
    >
      <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addFormRule">
        <el-form-item label="部门名称" prop="name">
          <el-input size="medium" v-model="addForm.name"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人" prop="principalName">
          <el-input size="medium" v-model="addForm.principalName"></el-input>
        </el-form-item>
        <el-form-item label="负责人电话" prop="principalTel">
          <el-input size="medium" v-model="addForm.principalTel"></el-input>
        </el-form-item>
        <el-form-item label="所属单位" prop="unitId">
          <el-select v-model="addForm.unitName" clearable   placeholder="请选择所属单位" style="width: 100%" @change="unitChange">
            <el-option
                    v-for="item in addUnitData"
                    :key="item.id"
                    :label="item.unitName"
                    :value="{value:item.id,label:item.unitName}">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button size="medium" type="goon" round @click="dialogVisible = false">取 消</el-button>
    <el-button size="medium" type="goon" round @click="addBumen" >确 定</el-button>
  </span>
    </el-dialog>
    <el-dialog
      title="修改部门信息"
      :visible.sync="editdialogVisible"
      width="25%"
      center
      :close-on-click-modal="false"
      @close="editdialogClose"
    >
      <el-form ref="editFormRef" :model="editForm" label-width="100px" :rules="editFormRules">
        <el-form-item label="部门名称" prop="name">
          <el-input size="medium" v-model="editForm.name"></el-input>
        </el-form-item>
        <el-form-item label="部门负责人" prop="principalName">
          <el-input size="medium" v-model="editForm.principalName"></el-input>
        </el-form-item>
        <el-form-item label="负责人电话" prop="principalTel">
          <el-input size="medium" v-model="editForm.principalTel"></el-input>
        </el-form-item>
          <el-form-item label="所属单位" prop="unitName">
            <el-select v-model="editForm.unitName" clearable   placeholder="请选择所属单位" style="width: 100%" @change="unitChange2">
              <el-option
                      v-for="item in addUnitData"
                      :key="item.id"
                      :label="item.unitName"
                      :value="{value:item.id,label:item.unitName}">
              </el-option>
            </el-select>
          </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
    <el-button type="goon" size="medium" round @click="editdialogVisible = false">取 消</el-button>
    <el-button type="goon" size="medium" round @click="editBumenInfo">确 定</el-button>
  </span>
    </el-dialog>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'buMenList',
    data(){
      var validatorPhone = function (rule, value, callback) {
        if (value === '') {
          callback(new Error('手机号不能为空'))
        } else if (!/^1\d{10}$/.test(value)) {
          callback(new Error('手机号格式错误'))
        } else {
          callback()
        }
      }
      return{
        value:[],
        queryInfo:{
          unitName:'',
          pageNumber:1,
          pageSize:10,
          projectId:''
        },
        total:10,
        tableData:[],
        dialogVisible:false,
        addForm:{
          name:'',
          principalTel:'',
          principalName:'',
          unitId:'',
          unitName:'',
          projectId:'1'
        },
        addUnitData:[],
        addFormRule:{
          name:[{required:true,message:'请输入部门名',trigger:'blur'}],
          unitName:[{required:true,message:'请选择所属单位',trigger:'blur'}],
          principalName:[{required:true,message:'请输入负责人名字',trigger:'blur'}],
          principalTel:[{required:true,validator: validatorPhone,rigger:'blur'}]
        },
        editdialogVisible:false,
        editForm:{
        },
        editFormRules:{
          name:[{required:true,message:'请输入部门名',trigger:'blur'}],
          unitName:[{required:true,message:'请选择所属单位',trigger:'blur'}],
          principalName:[{required:true,message:'请输入负责人名字',trigger:'blur'}],
          principalTel:[{required:true,validator: validatorPhone,rigger:'blur'}]
        },
        optionProps:{
          checkStrictly: true,
          value: 'id',
          label: 'name',
          children: 'children'
        },
      }
    },
    created () {
      this.getBumenList();
      this.getUnitList();
    },
    methods:{
      async getBumenList(){
        const {data:res}=await  this.$http1.get('/v1.0/department/findAll')
        if(res.businessCode!==3000){
           this.$message.error('获取部门列表失败！' +res.message);
          return this.tableData=[];
        }
        this.tableData=res.data;
        this.total=res.data.length
      },
      async getUnitList(){
        const {data:res}=await  this.$http1.get('/v1.0/unit/findAll')
        if(res.businessCode!==3000){
          return this.addUnitData=[];
        }
        this.addUnitData=res.data;
      },
      async getDanweiName(id){
        const {data:res}=await  this.$http1.get('/v1.0/unit/findById/'+id)
        console.log(res.data.unitName)
        return res.data.unitName
      },
      async addBumen(){
        this.$refs.addFormRef.validate( async valid =>{
          if(!valid)
            return
          const {data:res}= await this.$http1.post('/v1.0/department/save', this.addForm)
          if(res.businessCode!==6000){
            return  this.$message.error('添加部门失败！')
          }
          this.$message.success('添加部门成功！');
          this.getBumenList();
          this.dialogVisible = false;
        })
      },
      dialogClose(){
        this.$refs.addFormRef.resetFields();
      },
      unitChange(e){
        const { value, label } = e;
        this.addForm.unitId=value;
        this.addForm.unitName=label
      },
      unitChange2(e){
        const { value, label } = e;
        this.editForm.unitId=value;
        this.editForm.unitName=label
      },
      async editDialog(id){
        //请求查询
        const {data:res}=await  this.$http1.get('/v1.0/department/findById/'+id);
        if(res.businessCode!==3000){
          this.$message.error('获取部门失败！' +res.message);
          return 0;
        }
        this.editForm=res.data;
        this.editdialogVisible = true
      },
      editdialogClose(){
        this.$refs.editFormRef.resetFields()
      },
      editBumenInfo(){
        this.$refs.editFormRef.validate(async valid =>{
          if(!valid) return
          const {data:res}= await this.$http1.put('/v1.0/department/update', this.editForm)
          if(res.businessCode!=="6000"){
            return  this.$message.error('修改部门失败！')
          }
          this.$message.success('修改部门成功！');
          this.editdialogVisible = false;
          this.getBumenList();
        })
      },
      async deleteMsg(id){
        const confirmResult = await this.$confirm('此操作将永久删除该部门, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).catch((err) => {
          return err
        });
        if(confirmResult !== 'confirm'){
          return this.$message({
            type: 'info',
            message: '已取消删除'
          });
        }
        //请求
        const {data:res}= await this.$http.delete('/v1.0/department/delete/'+id)
        if(res.businessCode!="4000"){
          return  this.$message.error('删除部门失败！')
        }
        this.$message.success('删除部门成功！');
        this.getBumenList();
      },
      handleClose(done) {
        this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
      },
      handleSizeChange(newSize){
        this.queryInfo.pageSize=newSize;
        this.getBumenList()
      },
      handleCurrentChange(newPage){
        this.queryInfo.pageNumber=newPage;
        this.getBumenList()
      },
      goback(){
        this.$router.push({ path: "/system" });
      },
    },

  }
</script>

<style scoped lang="less">
  .bg{
    height: 100vh;
  }
  .mainpart{
    left: 10px;
    top: 70px;
    bottom: 10px;
    right: 10px;
    width: 1900px;

  }
  .fanhui{
    float: right; padding:  0;border: 0 !important;color: black !important;margin-top: 10px;cursor: pointer;
  }

  /deep/ .cell{
    max-height: 70px;
    overflow-y: auto !important;
  }
</style>
