<template>
  <div class="bg">
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div class="title">
          <div>
            <span class="tableName">表名称:{{ tableName }}</span>
          </div>
          <div class="tablebtn">
            <el-button
              type="primary"
              @click="
                fieldTitle = '新增字段';
                dialogFieldVisible = true;
              "
              :disabled="isRelease == 2"
              :title="isRelease == 2 ? '表单已经部署无法操作' : ''"
              >新增字段</el-button
            >
            <el-button
              type="success"
              @click="getTableStructure"
              :disabled="isRelease != 2"
              :title="isRelease == 2 ? '' : '表单没有部署无法操作'"
              >表结构</el-button
            >
            <el-button
              type="success"
              @click="deployForm"
              :disabled="isRelease == 2"
              :title="isRelease == 2 ? '表单已经部署无法操作' : ''"
              >表单部署</el-button
            >
            <el-button type="primary" @click="getBack">返回</el-button>
          </div>
        </div>
        <div class="fieldTable">
          <el-table :data="tableData" border style="width: 100%" size="large"     :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
            <el-table-column type="index" width="70" />
            <el-table-column prop="fieldName" label="字段代码" width="240">
              <template #default="scope">
                <el-tag>{{ scope.row.fieldName }}</el-tag>
              </template>
            </el-table-column>
            <el-table-column prop="showName" label="字段描述" width="240" />
            <el-table-column
              prop="generatorDictionary.dictionaryName"
              label="字典代码"
              width="240"
            />
            <el-table-column prop="typeLength" label="类型长度" width="240" />
            <el-table-column label="操作">
              <template #default="scope">
                <el-button
                  size="small"
                  @click="editField(scope.row)"
                 
                  :title="
                    isRelease != 1 ? '表单已经部署无法操作' : '编辑该表单'
                  "
                  >编辑</el-button
                >
                <el-button
                  size="small"
                  type="danger"
                  plain
                  :title="
                    isRelease != 1 ? '表单已经部署无法操作' : '删除该表单'
                  "
                  @click="deleteForm(scope.row)"
                  >删除字段</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <el-dialog
      :visible.sync="dialogFieldVisible"
      :title="fieldTitle"
      @close="beforeCloseDialog"
    >
      <el-form :model="form" ref="ruleFormRef">
        <el-form-item label="字段代码" prop="fieldName" label-width="100px">
          <el-input v-model="form.fieldName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="字段描述" prop="showName" label-width="100px">
          <el-input v-model="form.showName" autocomplete="off" />
        </el-form-item>
        <el-form-item label="是否显示" prop="showStatus" label-width="100px">
          <el-radio v-model="form.showStatus" :label=1>显示</el-radio>
           <el-radio v-model="form.showStatus" :label=0>隐藏</el-radio>
        </el-form-item>
        <el-form-item label="字段类型" prop="dictionaryId" label-width="100px">
          <el-select v-model="form.dictionaryId" placeholder="请选择字段类型">
            <el-option
              v-for="item in categoryOptions"
              :key="item.dictionaryId"
              :label="item.dictionaryName"
              :value="item.dictionaryId"
            >
              <span style="float: left">{{ item.dictionaryName }}</span>
              <span
                style="
                  float: right;
                  color: var(--el-text-color-secondary);
                  font-size: 13px;
                "
              >
                {{ item.dictionaryRemarks }}</span
              >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字段长度" prop="typeLength" label-width="100px">
          <el-input-number v-model="form.typeLength" :min="0" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="dialogFieldVisible = false">取消</el-button>
          <el-button type="primary" @click="insertForm()"> 确定</el-button>
        </span>
      </template>
    </el-dialog>
    <el-drawer
      v-model="tableStructure"
      title="业务表结构"
      direction="rtl"
      size="30%"
    >
      <el-table :data="gridData">
        <el-table-column property="COLUMN_NAME" label="字段名称" width="150" />
        <el-table-column property="COLUMN_TYPE" label="字段类型" width="150" />
        <el-table-column property="IS_NULLABLE" label="能否为空" width="150" />
        <el-table-column property="COLUMN_COMMENT" label="字段含义" />
      </el-table>
    </el-drawer>
  </div>
</template>

<script>
import promeau from "../proMeau.vue";
export default {
  name: "formHome",
  data() {
    return {
        form: {
          showStatus:1,
                fieldName: "",
                showName: "",
                typeLength: 1,
                dictionaryId: "",
                generatorId: this.$route.query.generatorId,
                businessId: "",
            },
            tableData: [],
            tableName: '',
            isRelease: null,
            dialogFieldVisible: false,
            categoryOptions: [],
            fieldTitle: '新增字段',
            tableStructure: false,
            gridData: []
    };
  },
  created() {
    this.getFieldList();
        this.getGeneratorDictionaryList()
  },
  methods: {
    async getFieldList() {
            const { data: res } = await this.$http2.get('/extension/generator-business/query/GeneratorBusinessByGeneratorId?generatorId='+ this.$route.query.generatorId )
            if (res.code == 200) {
                this.tableData = res.data;
            }
        },
        async getGeneratorDictionaryList() {
            const { data: res } = await this.$http2.get('/extension/generator-dictionary/query/GeneratorDictionaryList')
            if (res.code == 200) {
                this.categoryOptions = res.data;
            }
        },
        async getTableStructure() {
            this.tableStructure = true;
            const { data: res } = await this.$http2.get('/extension/generator-form/query/BusinessTableStructure', { tableName: this.tableName })
            if (res.code == 200) {
                this.gridData = res.data;
            }
        },
        async insertForm() {
            if (this.fieldTitle == "新增字段") {
                const { data: res } = await this.$http2.post('/extension/generator-business/insert/GeneratorBusiness', this.form)
                if (res.businessCode == 4000) {
                    this.$message.success('保存成功！')
                    this.dialogFieldVisible = false;
                    this.getFieldList();
                } else {
                    this.$message.error('保存失败！')
                }
            } else {
                const { data: res } = await this.$http2.put('/extension/generator-business/update/GeneratorBusiness', this.form)
                if (res.businessCode == 4300) {
                    this.$message.success('保存成功！')
                    this.dialogFieldVisible = false;
                    this.getFieldList();
                } else {
                    this.$message.error('保存失败！')
                }
            }
        },
        beforeCloseDialog() {
            this.form = {
              showStatus:1,
                fieldName: "",
                showName: "",
                typeLength: 1,
                dictionaryId: "",
                generatorId: this.$route.query.generatorId,
                businessId: "",
            };
        },
        async deployForm() { 
            let fieldsList = [];
            for (let i in this.tableData) {
                fieldsList.push({
                    fieldCode: this.tableData[i].fieldName,
                    fieldLength: this.tableData[i].typeLength,
                    fieldType: this.tableData[i].generatorDictionary.dictionaryName,
                    showName: "'" + this.tableData[i].showName + "'",
                });
            }
            const { data: res } = await this.$http9.put('/stencils/create/DeployStencils', {
                fieldsList: fieldsList,
                id: this.form.generatorId,
            })
            if (res.businessCode == 4000) {
                this.$message.success('部署成功')
                this.isRelease = 2;
            } else {
                this.$message.error('部署失败!' + res.message)
            }
        },
        editField(row) {
            this.fieldTitle = "编辑字段";
            this.form = JSON.parse(JSON.stringify(row));
            this.dialogFieldVisible = true;
        },
        deleteForm(row) {
            this.$confirm('此操作将永久删除字段【' + row.fieldName + '】, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                const { data: res } = await this.$http2.delete('/extension/generator-business/delete/GeneratorBusinessByBusinessId?businessId='+row.businessId+'&generatorId='+row.generatorId)
                if (res.businessCode == 5000) {
                    this.$message.success('删除成功！')
                    this.getFieldList();
                } else {
                    this.$message.error('删除失败！')
                }
            }).catch(() => {
                this.$message({
                    type: 'info',
                    message: '已取消删除'
                });
            });
        },
        getBack() {
            this.$router.push({ path: "/wordList" });
        },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },

  components: {
    promeau,
  },
  mounted() {
        this.tableName = this.$route.query.tableName
        this.isRelease = this.$route.query.isRelease
    },
};
</script>

<style scoped lang="less">
.content {
  padding-top: 50px;
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(92vh);
    padding-top: 20px;
  }

  .title {
        display: flex;
        justify-content: space-between;
        padding: 20px 10px 0 30px;

        .tableName {
            text-align: center;
            font-size: 42px;
            font-family: 宋体;
            color: #409eff;
        }

        .tablebtn {
            margin-top: 20px;
        }
    }

    .fieldTable {
        padding: 20px 40px;
    }
}
</style>
