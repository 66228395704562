<template>
  <div>
    <table class="formtable">
      <tr>
        <td class="td120">项目名称</td>
        <td class="td600">
          <el-input v-model="addFrom.projectSerialName"></el-input>
        </td>
      </tr>
     <tr>
        <td colspan="4" rowspan="10">
          <el-input  v-model="addFrom.department" class="tonghang"></el-input>项目部：<br>
          &emsp;  兹上报<el-input  v-model="addFrom.engineering" class="tonghang" ></el-input>
          工程进度计划。该进度计划审核情况如下，请予以审批。<br />
          <div class="flexdiv">
            <span> 1、进度计划是否符合工程项目的总进度目标和阶段性目标要求</span>
            <span>
              <el-radio v-model="addFrom.firstSelect" :label="1">是</el-radio>
              <el-radio v-model="addFrom.firstSelect" :label="2">否</el-radio> 
            </span>
          </div>
          <div class="flexdiv">
            <span> 2、施工顺序的安排是否符合施工工艺的要求 </span>
            <span>
              <el-radio v-model="addFrom.secondSelect" :label="1">是</el-radio>
              <el-radio v-model="addFrom.secondSelect" :label="2">否</el-radio> 
            </span>
          </div>
          <div class="flexdiv">
            <span> 3、劳动力的安排是否能保证该进度计划的实现  </span>
            <span>
              <el-radio v-model="addFrom.thirdSelect" :label="1">是</el-radio>
              <el-radio v-model="addFrom.thirdSelect" :label="2">否</el-radio> 
            </span>
          </div>
          <div class="flexdiv">
            <span> 4、施工设备、施工用水、电等生产要素的供应是否能够保证该进度计划的实现</span>
            <span>
              <el-radio v-model="addFrom.fourthSelect" :label="1">是</el-radio>
              <el-radio v-model="addFrom.fourthSelect" :label="2">否</el-radio> 
            </span>
          </div>
          <div class="flexdiv">
            <span> 5、工程材料（设备）的供应是否能够保证该进度计划的实现</span>
            <span>
              <el-radio v-model="addFrom.fifthSelect" :label="1">是</el-radio>
              <el-radio v-model="addFrom.fifthSelect" :label="2">否</el-radio> 
            </span>
          </div>
          上传附件《进度计划》（包括说明、图表、工程量、机械、劳动力计划等）
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td colspan="4" rowspan="5">
          评审意见：<el-input
            type="textarea"
            :rows="5"
            v-model="addFrom.reviewComment"
          >
          </el-input>
        </td>
      </tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr></tr>
      <tr>
        <td class="td120">评审组组长签字</td>
        <td class="td650">
         <span style="margin-left:200px">签字</span> <el-input  class="tonghang3"></el-input> 
         日期 <el-date-picker  class="tonghang3"   type="date"  ></el-date-picker>
        </td>
      </tr>
      <tr>
        <td class="td120">项目经理</td>
        <td class="td650">
         <span style="margin-left:200px">签字</span> <el-input class="tonghang3"></el-input> 
         日期 <el-date-picker  class="tonghang3"  type="date"  ></el-date-picker>
        </td>
      </tr>
      <tr>
        <td class="td120">工程总承包分公司工程管理部</td>
        <td class="td650">
         <span style="margin-left:200px">签字</span> <el-input class="tonghang3"></el-input> 
         日期 <el-date-picker  class="tonghang3"   type="date"  ></el-date-picker>
        </td>
      </tr>
      <tr>
        <td class="td120">工程总承包分公司总工程师</td>
        <td class="td650">
         <span style="margin-left:200px">签字</span> <el-input class="tonghang3"></el-input> 
         日期 <el-date-picker  class="tonghang3"  type="date"  ></el-date-picker>
        </td>
      </tr>
    </table>
  </div>
</template>

<script>
export default {
  props: {
    someData: {
      type: Object,
      default() {
        return {};
      },
    },
    isAdd:{
      type: Boolean,
    }
  },
  name: "",
  data() {
    return {
      addFrom: {
        department: '',
        engineering: "",
        firstSelect: "",
        projectId: '',
        secondSelect: "",
        thirdSelect: "",
        fourthSelect: "",
        fifthSelect: "",
        reviewComment: "",
      },
    };
  },
  created() { if(!this.isAdd){
      this.addFrom=this.someData
    }
    this.addFrom.projectSerialName=window.sessionStorage.getItem("proName"),
    this.addFrom.projectId=window.sessionStorage.getItem("proId")
    // this.input=this.someData.input
    // this.input1=this.someData.input1
  },
  methods: {},
  components: {},
  watch: {
    someData(newVal, oldVal) {
      //对引用类型的值无效
      this.addFrom = newVal;
      this.addFrom.projectSerialName = window.sessionStorage.getItem("proName");
      this.addFrom.projectId = window.sessionStorage.getItem("proId")
    },
  },
};
</script>

<style scoped lang="less">
.formtable {
  border: 1px solid #000;
  border-width: 0 1px 1px 0;
  border-collapse: collapse;
  border-spacing: 0;
  tr td {
    line-height: 30px;
    font-size: 16px;
    padding: 0 4px;
    border: 1px solid #000;
    //border-width: 1px 0 0 1px;
  }
  .td120 {
    width: 120px;
    text-align: center;
  }
  .td600 {
    width: 600px;
  }
}
.flexdiv{
  display: flex;
  justify-content: space-between;
}
/deep/ .el-input__inner {
  padding: 0 !important;
  border: 0 !important;
  height: 30px !important;
  line-height: 30px !important;
  font-size: 16px !important;
  text-align: center;
}
.tonghang {
  display: inline-block;
  width: 250px;
  border-bottom:1px solid black ;
  height: 30px !important;
}
.tonghang3 {
  display: inline-block;
  width: 150px;
  height: 30px !important;
}

/deep/ .el-date-editor {
  padding-left: 25px;
  width: 100px !important;
}
/deep/ .el-input__prefix {
  top: -5px !important;
}
</style>
