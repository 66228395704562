<template>
    <div class="bg">
        <work-left></work-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="4.5">
                            <el-input placeholder="请输入流程名查询" v-model="queryInfo.processName" class="input-with-select" clearable @clear="getlcList">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getlcList" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="4.5">
                            <el-input placeholder="请输入流程描述查询" v-model="queryInfo.processDescription" class="input-with-select" clearable @clear="getlcList">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getlcList" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="5">
                            <el-select size="medium" v-model="queryInfo.categoryName"  clearable placeholder="请选择流程类型" @change="getlcList">
                                <el-option
                                        v-for="item in categoryOptions"
                                        :key="item.id"
                                        :label="item.name"
                                        :value="item.name">
                                </el-option>
                            </el-select>
                        </el-col>
                    </el-row>

                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                           >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                            <template v-slot="scope">
                                {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                            </template>
                        </el-table-column>

                        <el-table-column
                                prop="processName"
                                label="流程名称"
                                width="250"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="categoryName"
                                label="流程所属类别"
                                width="160"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="processDescription"
                                label="流程描述"
                                width="400"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createUserName"
                                label="创建人"
                                width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createDate"
                                label="创建时间"
                                width="150"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                             <!--   <el-tooltip effect="dark" content="查看表单" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-view" size="mini" @click="showFrom(scope.row)" ></el-button>
                                </el-tooltip>-->
                             <!--   <el-tooltip effect="dark" content="发起流程" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-video-play" size="mini" @click="qidong(scope.row)" ></el-button>
                                </el-tooltip>-->
                                <el-button  type="primary" @click="qidong(scope.row)" icon="el-icon-video-play" size="mini">发起流程</el-button>
                                <!-- <el-tooltip effect="dark" content="删除公告" placement="top" :enterable="false">
                                     <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                 </el-tooltip>-->
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="业务表单"
                :visible.sync="formDialogVisible"
                :close-on-click-modal="false"
                width="30%"
                center>
            <form-create v-model="fApi" :rule="rule" :option="option" :value.sync="value"></form-create>
        </el-dialog>
    </div>
</template>

<script>
    import workLeft from './workLeft'
    export default {
        name: "liuchengList",
        data(){
            return{
                queryInfo:{
                    processName:'',
                    processDescription:'',
                    categoryName:'',
                    pageNumber:1,
                    pageSize:10,
                },
                total:0,
                tableData:[],
                formDialogVisible:false,
                fApi: {},
                //表单数据
                value: {},
                //表单生成规则
                rule: [
                ],
                categoryOptions:[],
                //组件参数配置
                option: {
                    resetBtn: true,
                    //表单提交事件
                }
            }
        },
        methods:{
            async getALLcategory(){
                const {data:result}=await this.$http2.get('/v1.0/activiti/category/findAll');
                this.categoryOptions=result.data;
            },
            async getlcList(){
                const {data:res}= await this.$http2.post('/v1.0/activiti/process/execute/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询流程列表失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            getLiucheng(){

            },
           async qidong(row){
              console.log(row)
               this.option.onSubmit= async (formData)=> {
                   var FiledData=[]
                   for(let x in formData){
                       if(formData[x]){
                           FiledData.push({'fieldKey':x,'fieldValue':formData[x]})
                       }else {
                           FiledData.push({'fieldKey':x,'fieldValue':null})
                       }
                   }
                   var js={
                       "tableName": row.tableName,
                       "projectId":this.$root.proId,
                       "processDefinitionKey": row.processDefinitionKey,
                       "tableField": FiledData
                   }
                   console.log(js)
                  /* var form = new FormData();
                   form.append("dynamic",JSON.stringify(js))
                   form.append("processId",row.processKey)*/
                   const {data:result2}=await this.$http2.post('/v1.0/activiti/form/save/dynamicFormData',js);
                   console.log(result2)
                   this.$message.success('提交成功！')
                   this.formDialogVisible = false
                   // alert(JSON.stringify(formData))
               }
               this.generateFormFn(row.formDefinitionId)
             },
            showFrom(row){
                this.option.onSubmit=''
                this.generateFormFn(row.formDefinitionId)
            },
            async generateFormFn(id) {
                //   this.rule = [{}];
                const {data:result}=await this.$http2.get('/v1.0/activiti/form/findById?id='+id);
                console.log(JSON.parse(result.data.formJson))
                var rul=[]
                rul=JSON.parse(result.data.formJson).list
                console.log(rul)
                this.rule=rul
                this.formDialogVisible=true
                /*       this.rule.push(
                           {
                               type: "slider",
                               field: "volume",
                               title: "音量大小：",
                               value: 60,
                               //value: [25, 80],  // 和range: true 时一起使用
                               props: {
                                   min: 0,
                                   max: 100,
                                   showTip: "always",
                                   range: false // 起始分段
                               }
                           },

                           {
                               type: "InputNumber",
                               field: "jiajie",
                               title: "商品增减：",
                               value: 0,
                               col: {
                                   md: { span: 12 }
                               },
                               validate: [
                                   {
                                       required: true,
                                       //   min: 1,
                                       message: "商品数里至少为1",
                                       trigger: "change"
                                   }
                               ]
                           },

                           {
                               type: "ColorPicker",
                               field: "color",
                               title: "喜欢颜色：",
                               value: "#00b102",
                               col: {
                                   md: { span: 12 }
                               },
                               props: {
                                   alpha: true
                               }
                           },

                           {
                               type: "DatePicker",
                               field: "dateTime",
                               title: "起止日期：",
                               value: ["2020-04-20", new Date()],
                               props: {
                                   type: "daterange",
                                   // format: "yyyy-MM-dd HH:mm:ss",
                                   format: "yyyy-MM-dd",
                                   placeholder: "请选择起止日期"
                               }
                           },

                           {
                               type: "rate",
                               field: "rateNumber",
                               title: "评分效果：",
                               value: 3,
                               props: {
                                   count: 8,
                                   allowHalf: true // 是否可选半星
                               },
                               validate: [
                                   {
                                       required: true,
                                       type: "number",
                                       min: 4,
                                       message: "请大于3颗星",
                                       trigger: "change"
                                   }
                               ],
                               control: [
                                   {
                                       handle: function(val) {
                                           console.log(val + "颗星");
                                       }
                                   }
                               ]
                           },

                           {
                               type: "switch",
                               field: "offon",
                               title: "打开关闭：",
                               value: true,
                               col: {
                                   md: { span: 6 }
                               },
                               props: {
                                   trueValue: true,
                                   falseValue: false,
                                   slot: {
                                       open: "开启",
                                       close: "关闭"
                                   }
                               }
                           },

                           {
                               type: "upload",
                               field: "imgFile",
                               title: "图片上传：",
                               value: [
                                   "https://inews.gtimg.com/newsapp_ls/0/11673471712_295195/0",
                                   "http://file.lotkk.com/form-create.png",
                                   "https://inews.gtimg.com/newsapp_ls/0/11673643537_295195/0"
                               ],
                               col: {
                                   md: { span: 18 }
                               },
                               validate: [
                                   {
                                       required: true,
                                       type: "array",
                                       min: 1,
                                       message: "请上传1张图片",
                                       trigger: "change"
                                   }
                               ],
                               props: {
                                   type: "select",
                                   uploadType: "image", // file
                                   name: "userPhoto", // name属性
                                   multiple: true, // 是否可多选
                                   allowRemove: true,
                                   accept: "image/!*", // 上传文件类型
                                   format: ["jpg", "jpeg", "png", "gif"], // 上传文件格式
                                   maxSize: 2048, // 上传文件大小最大值
                                   maxLength: 5, // 上传文件数量最大值
                                   action: "http://www.upimage.com/imguploadApi", // 上传后端接收API接口
                                   onSuccess: function(res) {
                                       return ""; // 上传成功回调函数
                                   }
                               }
                           }
                       );*/
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getlcList()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getlcList()
            },
        },
        created(){
            this.getlcList();
            this.getALLcategory();
        },
        components:{
            workLeft
        }
    }
</script>

<style scoped lang="less">

    .input-with-select{
        width: 280px;
        .el-button{
            padding: 10px 15px;
        }
    }

</style>
