<template>
    <div style="width: 100%;height: 100%;" id="bottomEchartsTouzi">

    </div>
</template>

<script>
    import * as echarts from 'echarts';
    export default {
        name: "bottomEchartsTouzi",
        mounted(){
            this.echart();
        },
        methods:{
          async  echart(){
                var xdata=[];
                const {data:res}= await this.$http1.get('/v1.0/project/statistics/findAllGroupByType')
                if(res.status!='3000'){
                    return
                }
               for(let i in res.data){
                   xdata.push({'value':res.data[i].totalInvestment,'name':res.data[i].typeName})
               }
               console.log(xdata)
                var myChart1 = echarts.init(document.getElementById('bottomEchartsTouzi'));
                var option = {
                    grid: {
                        top:'2%',
                        left: '2%',
                        right: '2%',
                        bottom: '2%',
                        containLabel: true
                    },
                    series: [
                        {
                            name: 'touzi',
                            type: 'pie',
                            radius: '90%',
                            data:xdata,
                            label: {
                                position: 'inner',
                                formatter: '{b|{b}}:{c|{c}} ',
                                rich: {
                                    b: {
                                        color: '#4C5058',
                                        fontSize: 14,
                                        fontWeight: 'bold',
                                        lineHeight: 33
                                    },

                                },
                                fontSize: 14
                            },
                            emphasis: {
                                itemStyle: {
                                    shadowBlur: 10,
                                    shadowOffsetX: 0,
                                    shadowColor: 'rgba(0, 0, 0, 0.5)'
                                }
                            }
                        }
                    ]
                };
                myChart1.setOption(option);
                window.addEventListener("resize", function () {
                    myChart1.resize();
                });
            }
        }
    }
</script>

<style scoped>

</style>
