<template>
    <div class="probom">
        <ul>
            <li class="dhome" @click="gohome" :class="{activiehome:current==1}" >
                <a>
                    <span>首页</span>
                </a>
            </li>
            <li class="dproinfo" @click="goPro" :class="{activiepro:current==2}" >
                <a>
                    <span>项目详情</span>
                </a>
            </li>
            <li class="dprowork" @click="goWork" :class="{activiework:current==11}" >
                <a>
                    <span>工作台</span>
                </a>
            </li>
            <li class="dprocess" @click="goprocess" :class="{activieprocess:current==5}" >
                <a>
                    <span>进度管理</span>
                </a>
            </li>
            <li class="dquality"  @click="goquality"  :class="{activiequality:current==4}" >
                <a>
                    <span>质量管理</span>
                </a>
            </li>
            <li class="dhetong" @click="gohetong" :class="{activiehetong:current==8}">
                <a>
                    <span>合同管理</span>
                </a>
            </li>
            <li class="dcost" @click="gocost" :class="{activiecost:current==7}">
                <a>
                    <span>费用控制</span>
                </a>
            </li>
            <li class="dsafe" :class="{activiesafe:current==3}" >
                <a>
                    <span>安全管理</span>
                </a>
            </li>
            <li class="djiankong" :class="{activiemonitor:current==12}" @click="gomonitor">
                <a>
                    <span>视频监控</span>
                </a>
            </li>
            <li class="dkaoqing" @click="gokaoqing" :class="{activiekaoqing:current==9}">
                <a>
                    <span>考勤管理</span>
                </a>
            </li>
            <li class="dsystem" @click="backmanage" :class="{activieback:current==10}">
                <a>
                    <span>后台管理</span>
                </a>
            </li>
        </ul>
    </div>
</template>

<script>
    export default {
        name: "dhbootm",
        data(){
          return{
              current:1,
          }
        },
        methods:{
            gohome(){
                window.sessionStorage.setItem("current",1)
                this.$router.push({ path: "/prohome" });
                this.current=1;
            },
            goPro(){
                window.sessionStorage.setItem("current",2)
                this.$router.push({ path: "/proInfo" });
            },
            goquality(){
                window.sessionStorage.setItem("current",4)
                this.$router.push({ path: "/quality" });
            },
            goprocess(){
                window.sessionStorage.setItem("current",5)
                this.$router.push({ path: "/process" });
            },
            gocost(){
                window.sessionStorage.setItem("current",7)
                this.$router.push({ path: "/cost" });
            },
            gohetong(){
                window.sessionStorage.setItem("current",8)
                this.$router.push({ path: "/hetong" });
            },
            gokaoqing(){
                window.sessionStorage.setItem("current",9)
                this.$router.push({ path: "/kaoList" });
            },
            backmanage(){
                window.sessionStorage.setItem("current",10)
                this.$router.push({ path: "/yhList" });
            },
            goWork(){
                window.sessionStorage.setItem("current",11)
                this.$router.push({ path: "/todo" });
            },
            gomonitor(){
                window.sessionStorage.setItem("current",12)
                this.$router.push({ path: "/monitor" });
            }
        },
        mounted() {
            this.current=window.sessionStorage.getItem("current")
        }
    }
</script>

<style scoped lang="less">
    .probom{
        bottom: 0;
        position: absolute;
        width: 100%;
        height: 64px;
        background-color: #1e50ae;
    ul{
        display: flex;
        height: 100%;
        margin: 0;
        padding: 0;
        list-style: none;
    li{
        position: relative;
        flex: 1;
        height: 100%;
        text-align: center;
        background-repeat: no-repeat;
        background-position: center 5px;
        cursor: pointer;
    a{
        display: inline-block;
        width: 100%;
        padding-top: 40px;
        color: #eef5ff;
        text-decoration: none;
        touch-action: manipulation;
        background-color: transparent;
        outline: 0;
        cursor: pointer;
        transition: color .3s;
    span{
        font-size: 15px;
    }
    }
    }
    li:hover{
    a{
        color: #00e2fe;
    }
    }
    .dhome{
        background-image: url("../assets/img/daohang/home.png");
    }
    .dhome:hover, .activiehome{
        background-image: url("../assets/img/daohang/home-selected.png");
    }
    .dproinfo{
        background-image: url("../assets/img/daohang/project-info.png");
    }
    .dproinfo:hover, .activiepro{
        background-image: url("../assets/img/daohang/project-info-selected.png");
    }
    .dsystem{
        background-image: url("../assets/img/daohang/system-manage.png");
    }
    .dsystem:hover,.activieback{
        background-image: url("../assets/img/daohang/system-manage-selected.png");
    }
    .dsafe{
        background-image: url("../assets/img/daohang/safe-manage.png");
    }
    .dsafe:hover{
        background-image: url("../assets/img/daohang/safe-manage-selected.png");
    }
    .dquality{
        background-image: url("../assets/img/daohang/quality-manage.png");
    }
    .dquality:hover,.activiequality{
        background-image: url("../assets/img/daohang/quality-manage-selected.png");
    }
    .dprocess{
        background-image: url("../assets/img/daohang/process-manage.png");
    }
    .dprocess:hover,.activieprocess{
        background-image: url("../assets/img/daohang/process-manage-selected.png");
    }
    .djiankong{
        background-image: url("../assets/img/daohang/jiankong.png");
    }
    .djiankong:hover,.activiemonitor{
        background-image: url("../assets/img/daohang/jiankong-select.png");
    }
    .dcost{
        background-image: url("../assets/img/daohang/cost-manage.png");
    }
    .dcost:hover,.activiecost{
        background-image: url("../assets/img/daohang/cost-manage-selected.png");
    }
    .dhetong{
        background-image: url("../assets/img/daohang/hetong.png");
    }
    .dhetong:hover,.activiehetong{
        background-image: url("../assets/img/daohang/hetong-select.png");
    }
    .dkaoqing{
        background-image: url("../assets/img/daohang/kaoqing.png");
    }
    .dkaoqing:hover,.activiekaoqing{
         background-image: url("../assets/img/daohang/kaoqing-select.png");
    }
    .dprowork{
        background-image: url("../assets/img/daohang/work.png");
    }
    .dprowork:hover,.activiework{
       background-image: url("../assets/img/daohang/work-select.png");
     }
    }
    }
</style>
