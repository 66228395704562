<template>
  <div class="bg">
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
                <fc-designer class="form-build" ref="designer">
                    <template slot="handle">
                        <!--<ElButton @click="creatreform">创建表单</ElButton>-->
                        <ElButton @click="beforeSaveForm">创建表单</ElButton>
                    </template>
                </fc-designer>
            </div>
    </div>
    <el-dialog title="请输入业务动态表单相关信息" :visible.sync="dialogFormVisible" width="30%" center>
            <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addFormRule">
                <el-form-item label="表单名称" prop="tableName">
                    <el-input v-model="addForm.tableName" placeholder="请输入内容(只能2个字母以上)"
                        @keyup.native="addForm.tableName = addForm.tableName.replace(/[^a-zA-Z]/g, '')"></el-input>
                </el-form-item>
                <el-form-item label="表单描述">
                    <el-input type="textarea" :rows="2" placeholder="请输入内容" v-model="addForm.formDescription">
                    </el-input>
                </el-form-item>
                <el-form-item label="所属类别" prop="categoryName">
                    <el-cascader v-model="addForm.categoryId" :options="categoryOptions" :props="props" class="cascader"
                        @change="changeCategoryId" placeholder="请选择所属类别" />
                </el-form-item>
            </el-form>

            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="saveForm">确 定</el-button>
            </span>
        </el-dialog>
  </div>
</template>

<script>
import promeau from "../proMeau.vue";
export default {
  name: "formHome",
  data() {
    return {
        addForm: {
                categoryName: '',
                tableName: '',
                categoryId: '',
                formDescription: ''
            },
            dialogFormVisible: false,
            isChange: false,
            addFormRule: {
                tableName: [{ required: true, message: '请输入表单名称', trigger: 'blur' }, { min: 2, max: 40, message: "长度再2-40个字符之间", trigger: 'blur' }],
                categoryName: [{ required: true, message: '请输入表单名称', trigger: 'blur' }]
            },
            categoryOptions: [],
            props: {
                label: "categoryName",
                value: "categoryId",
                checkStrictly: true,
            },

    };
  },
  created() {
    this.getActBtCategoryTree()
  },
  methods: {
    async getActBtCategoryTree() {
            const { data: res } = await this.$http2.get('/extension/act-bt-category/query/ActBtCategoryTree')
            if (res.code == 200) {
                this.categoryOptions = res.data;
            }
        },
        beforeSaveForm() {
            this.dialogFormVisible = true;
            if (localStorage.getItem("formRow")) {
                this.addForm.tableName = JSON.parse(
                    localStorage.getItem("formRow") || ""
                ).tableName;
                this.addForm.categoryId = JSON.parse(
                    localStorage.getItem("formRow") || ""
                ).categoryId;
                this.addForm.formDescription = JSON.parse(
                    localStorage.getItem("formRow") || ""
                ).formDescription;
                this.addForm.generatorId = JSON.parse(
                    localStorage.getItem("formRow") || ""
                ).generatorId;
            }
        },
        changeCategoryId() {
            this.isChange = true;
        },
        async saveForm() {
            this.addForm.formContent = this.$refs.designer.getJson();
            if (this.isChange) {
                this.addForm.categoryId =
                    this.addForm.categoryId[this.addForm.categoryId.length - 1];
            }
            if (localStorage.getItem("formRow")) {
                const { data: res } = await this.$http2.put('/extension/generator-form/update/GeneratorForm', this.addForm)
                if (res.code == 200) {
                    this.$message.success('保存成功！')
                    this.dialogFormVisible = false;
                } else {
                    this.$message.error('保存失败！')
                }
            } else {
                const { data: res } = await this.$http2.post('/extension/generator-form/insert/GeneratorForm', this.addForm)
                if (res.code == 200) {
                    this.$message.success('保存成功！')
                    this.dialogFormVisible = false;
                } else {
                    this.$message.error('保存失败！')
                }
            }
        },
    goback() {
      this.$router.push({ path: "/system" });
    },
  },

  components: {
    promeau,
  },
  mounted() {
        if (localStorage.getItem("formRow")) {
            this.$refs.designer.setRule(
                JSON.parse(localStorage.getItem("formRow") || "").formContent
            );
        }
    },
};
</script>

<style scoped lang="less">
.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
        /deep/ .el-header {
            background: transparent !important
        }
    }

   
}
._fc-m-tools {
    button {
        z-index: 999999999;
    }
}
</style>
