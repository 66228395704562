<template>
   <div class="bg">
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
            <div class="rightCont">
                <div id="CategoryTree">
                    <el-input placeholder="输入关键字进行过滤" clearable v-model="filterText">
                    </el-input>
                    <el-tree class="filter-tree" :data="typedata" :props="defaultCategoryProps" default-expand-all
                        :filter-node-method="filterNode" ref="tree" show-checkbox node-key="categoryId"
                        :check-strictly="true" @check="treeChange">
                        <!-- 处理菜单内容过长 -->
                        <span class="custom-tree-node" slot-scope="{ node }">
                            <el-tooltip class="item" effect="dark" :content="node.label" placement="top-start">
                                <span> {{ node.label | ellipsis(8) }} </span>
                            </el-tooltip>
                        </span>
                    </el-tree>
                </div>
                <div id="pageList">
                    <div class="addActBtCategory">
                        <div class="catleft">
                            <span class="title-left">模型类别</span>
                        </div>
                        <div style="display: flex;justify-content: flex-end;z-index: 999999999;">
                            <el-button type="primary" @click="resetCondition"><i class="el-icon-remove"></i><span>重置</span>
                            </el-button>
                            <el-button type="primary" @click="addModel"><i
                                    class="el-icon-add-location"></i><span>新增类别</span>
                            </el-button>
                        </div>
                    </div>
                    <!-- 列表 -->
                    <div>
                        <el-table :data="ActBtCategoryList" stripe border   :header-cell-style="{ background: '#e3ecfb', color: 'black' }">
                            <el-table-column type="selection" width="55">
                            </el-table-column>
                            <el-table-column label="类别编号" width="250">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.categoryId }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="类别名称" width="250">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.categoryName }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="类别状态" width="100">
                                <template slot-scope="scope">
                                    <el-tag type="success" v-if="scope.row.categoryStatus == 2">启用</el-tag>
                                    <el-tag type="danger" v-else>禁用</el-tag>
                                </template>
                            </el-table-column>
                            <el-table-column prop="businessCode" label="事件代码" width="180" />
                            <el-table-column prop="fieldName" label="字段代码" width="130" />
                            <el-table-column prop="tableName" label="表代码" width="140" />
                            <el-table-column label="类别排序" width="100">
                                <template slot-scope="scope">
                                    <span>{{ scope.row.categorySort }}</span>
                                </template>
                            </el-table-column>
                            <el-table-column label="操作">
                                <template slot-scope="scope">
                                    <el-button size="mini" @click="editRow(scope.row)"><i
                                            class="el-icon-edit"></i>编辑</el-button>
                                    <el-button size="mini" type="danger" @click="deleteRow(scope.row)"><i
                                            class="el-icon-delete"></i>删除</el-button>
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-pagination background layout="prev, pager, next" :total="total" 
                        @size-change="handleSizeChange"
                        @current-change="handleCurrentChange">
                        </el-pagination>
                    </div>
                </div>

            </div>
        </div>
        <el-dialog :visible.sync="dialogVisible" :title="typeTitle" width="30%">
            <el-form :model="typeForm" ref="ruleFormRef">
                <el-form-item label="类别名称" prop="categoryName" label-width="100px">
                    <el-input v-model="typeForm.categoryName" autocomplete="off" />
                </el-form-item>
                <el-form-item label="类别状态" prop="categoryStatus" label-width="100px">
                    <el-switch v-model="typeForm.categoryStatus" class="mb-2" style="
              --el-switch-on-color: #13ce66;
              --el-switch-off-color: #ff4949;
            " active-text="启用" :active-value="2" inactive-text="禁用" :inactive-value="1" />
                </el-form-item>
                <el-form-item label="所属类别" prop="parentId" label-width="100px">
                    <el-cascader v-model="typeForm.parentId" check-strictly :options="modelCategoryTreeSelect"
                        :props="props" placeholder="请选择所属类别" @change="changeParent" />
                </el-form-item>
                <el-form-item label="事件代码" prop="businessCode" label-width="100px">
                    <el-input v-model="typeForm.businessCode" autocomplete="off" />
                </el-form-item>
                <el-form-item label="字段代码" prop="fieldName" label-width="100px">
                    <el-input v-model="typeForm.fieldName" autocomplete="off" />
                </el-form-item>
                <el-form-item label="表代码" prop="tableName" label-width="100px">
                    <el-input v-model="typeForm.tableName" autocomplete="off" />
                </el-form-item>
                <el-form-item label="实体名" prop="primaryName" label-width="100px">
                    <el-input v-model="typeForm.primaryName" autocomplete="off" />
                </el-form-item>
                <el-form-item label="组件地址" prop="componentAddress" label-width="100px">
                    <el-input v-model="typeForm.componentAddress" autocomplete="off" />
                </el-form-item>
                <el-form-item label="类别排序" prop="categorySort" label-width="100px">
                    <el-input-number v-model="typeForm.categorySort" :min="1" clearable prefix-icon="el-icon-edit" />
                </el-form-item>
            </el-form>
            <template #footer>
                <span class="dialog-footer">
                    <el-button @click="dialogVisible = false">取消</el-button>
                    <el-button type="primary" @click="saveForm()">
                        确定</el-button>
                </span>
            </template>
        </el-dialog>
    </div>
</template>

<script>
import promeau from "./proMeau.vue";
export default {
    data() {
        return {
            filterText: '',
            // 查询目录属性设置
            defaultCategoryProps: {
                children: 'children',
                label: 'categoryName'
            },
            // 初始化目录
            typedata: [],
            loading: false,
            queryPage: {
                categoryId: "",
                pageNum: 1,
                pageSize: 10,
                categoryName: "",
                categoryStatus: "",
            },
            ActBtCategoryList: [],
            typeTitle: '',
            dialogVisible: false,
            typeForm: {
                categoryId: "",
                categoryName: "",
                parentId: "",
                categorySort: 1,
                categoryStatus: 2,
                businessCode: "",
                componentAddress: "",
                fieldName: "",
                tableName: "",
                primaryName: "",
            },
            props: {
                children: "children",
                value: "categoryId",
                label: "categoryName",
                checkStrictly: true,
            },
            total: 0,
            modelCategoryTreeSelect: [],
            // 校验规则
            rules: {
                categoryName: [{ required: true, message: '请输入类别名称', trigger: 'blur' }],
                parentId: [{ required: true, message: '请选择所属类别', trigger: 'blur' }]
            },
        }
    },
    methods: {
        async getInitiation() {
            const { data: res } = await this.$http2.post('/extension/act-bt-category/query/ActBtCategoryPageList', this.queryPage)
            if (res.code == 200) {
                this.ActBtCategoryList = res.data.list;
                this.total = res.data.total;
            }
        },
        async queryCatTree() {
            const { data: res } = await this.$http2.get('/extension/act-bt-category/query/ActBtCategoryTree')
            if (res.code == 200) {
                this.modelCategoryTreeSelect = [];
                this.modelCategoryTreeSelect.push({
                    children: res.data,
                    categoryId: "init_level",
                    categoryName: "所有类别",
                });
                console.log(this.modelCategoryTreeSelect)
            }
        },
        resetCondition() {
            this.queryPage={
                categoryId: "",
                pageNum: 1,
                pageSize: 10,
                categoryName: "",
                categoryStatus: "",
            }
            this.getInitiation()
        },
        async getTree() {
            const { data: res } = await this.$http2.get('/extension/act-bt-category/query/ActBtCategoryTree')
            if (res.businessCode == 3000) {
                this.typedata = res.data;
            }
        },

        treeChange(data, checked, node) {

        },
        changeParent(e) {
            console.log(e)
            
            this.typeForm.parentId=e[e.length-1]
        },
        addModel() {
            this.typeForm = {
                categoryId: "",
                categoryName: "",
                parentId: "",
                categorySort: 1,
                categoryStatus: 2,
                businessCode: "",
                fieldName: "",
                tableName: "",
                primaryName: "",
                componentAddress: "",
            };
            this.typeTitle = "新增模型类别";
            this.dialogVisible = true;
        },
        editRow(row) {
            this.typeTitle = "修改模型类别";
            this.dialogVisible = true;
            this.typeForm = JSON.parse(JSON.stringify(row));
        },
        deleteRow(row) {
            this.$confirm('此操作将永久删除【'+row.categoryName+'】, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    const { data: res } = await this.$http2.delete('/extension/act-bt-category/delete/ActBtCategory/'+row.categoryId)
                        if (res.businessCode == 5000) {
                            this.$message.success('删除成功！')
                            this.getTree();
                            this.getInitiation();
                            this.queryCatTree();
                        } else {
                            this.$message.error('删除失败！')
                        }
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                });
            });
        },
        async saveForm() {
            if (this.typeForm.categoryId) {
                const { data: res } = await this.$http2.put('/extension/act-bt-category/update/ActBtCategory', this.typeForm)
                if (res.businessCode == 4300) {
                    this.$message.success('保存成功！')
                    this.getTree();
                    this.getInitiation();
                    this.queryCatTree();
                    this.dialogVisible = false;
                } else {
                    this.$message.error('保存失败！')
                }
            } else {
                const { data: res2 } = await this.$http2.post('/extension/act-bt-category/insert/ActBtCategory', this.typeForm)
                if (res2.businessCode == 4000) {
                    this.$message.success('保存成功！')
                    this.getTree();
                    this.getInitiation();
                    this.queryCatTree();
                    this.dialogVisible = false;
                } else {
                    this.$message.error('保存失败！')
                }
            }
        },
        handleCurrentChange(val) {
            this.queryPage.pageNum = val;
            this.getInitiation();
        },
        handleSizeChange(val) {
            this.queryPage.pageSize = val;
            this.getInitiation();
        },
        filterNode(value, data) {
            if (!value) return true;
            return data.label.indexOf(value) !== -1;
        }

    },
    components: {
        promeau,
    },
    created() {
        this.getInitiation();
        this.queryCatTree();
        this.getTree();
    },
    watch: {
        filterText(val) {
            this.$refs.tree.filter(val);
        }
    },
    mounted() {
    },
}

</script>

<style scoped lang="less">

.content {
    padding-top: 50px;
    height: calc(100vh - 50px);
    background-color: #fff;
    display: flex;
    justify-content: space-between;

    .leftMeau {
        height: 100%;
        width: 200px;
    }

    /deep/ .el-menu {
        height: 100%;
    }

    .rightCont {
        height: 100%;
        width: calc(100vw - 230px);
        height: calc(92vh);
        padding-top: 20px;
        display: flex;
        justify-content: space-between;
    }

    .insertActBtCategory {
        width: 300px;
        margin-right: 8px;
    }

    .addActBtCategory {
        display: flex;
        justify-content: space-between;
        margin-bottom: 6px;

        .catleft {
            margin-top: 10px;
        }
    }

    #CategoryTree {
        width: 14%;
        border: 1px solid #E7E7E7;
        height: 96%
    }

    #pageList {
        width: 85%
    }

    .filter-tree {
        overflow: auto;
        flex: 1;
        width: 170px;
        height: 600px;
        margin: 10px;
    }

    .title-left {
        font-size: 18px;
        font-weight: 700;
        padding-left: 10px;
        width: 140px;
        height: 22px;
        border-left: 5px solid #569ee3;
        text-align: left;
    }
}
</style>