<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <div id="kaoList">
            <el-card class="box-card" >
                <div slot="header" class="clearfix">
                    <el-button  icon="el-icon-search" @click="addPro"   v-show="protype">添加项目</el-button>
                    <el-button  icon="el-icon-search" @click="addProtype"   v-show="!protype">添加项目类型</el-button>
                    <el-button  icon="el-icon-search" @click="protype=!protype">切换到项目类型</el-button>
                </div>
                <div  class="text item">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            v-show="protype"
                            height="100%"
                            :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="projectName"
                                label="项目名称"
                                width="150">
                        </el-table-column>
                        <el-table-column
                                prop="projectType.typeName"
                                label="项目类型"
                                sortable
                                width="100">
                        </el-table-column>
                        <el-table-column
                                prop="totalInvestment"
                                label="项目总投资"
                                sortable
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="principal"
                                label="项目负责人"
                                sortable
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="principalTel"
                                label="负责人电话"
                                sortable
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="projectDetails"
                                label="项目详情"
                                sortable
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="projectStatus"
                                label="项目现状"
                                sortable
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="buildingHeight"
                                label="建筑高度"
                                sortable
                                width="100">
                        </el-table-column>
                        <el-table-column
                                prop="buildingArea"
                                label="建筑面积"
                                sortable
                                width="100">
                        </el-table-column>
                        <el-table-column
                                prop="projectAddress"
                                label="项目地址"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="longitude"
                                label="经度"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="latitude"
                                label="纬度"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="planStartDate"
                                label="计划开始时间"
                                width="120">

                        </el-table-column>
                        <el-table-column
                                prop="planEndDate"
                                label="计划结束时间"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="actualStartDate"
                                label="实际开始时间"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="actualEndDate"
                                label="实际结束时间"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="projectImagePath"
                                label="项目照片"
                                width="120">
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="修改用户" placement="top" :enterable="false">
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="删除项目" placement="top" :enterable="false" >
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            :data="protypeData"
                            style="width: 100%"
                            v-show="!protype"
                            height="100%"
                            :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="code"
                                label="项目类型编码"
                                width="500">
                        </el-table-column>
                        <el-table-column
                                prop="typeName"
                                label="项目类型名称"
                                sortable
                                width="500">
                        </el-table-column>
                        <el-table-column
                                label="操作"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="修改项目类型" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialogType(scope.row.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="删除项目类型" placement="top" :enterable="false" >
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg2(scope.row.id)" ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <x-dbottom></x-dbottom>
        <el-dialog
                title="添加项目信息"
                :visible.sync="dialogVisible"
                width="45%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="100px" >
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目名称">
                            <el-input v-model="addForm.projectName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目类型">
                            <el-select v-model="addForm.projectTypeId" clearable    style="width: 100%" @change="protypeChange" popper-class="select">
                                <el-option
                                        v-for="item in protypeData"
                                        :key="item.id"
                                        :label="item.typeName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="8">
                        <el-form-item label="项目总投资">
                            <el-input v-model="addForm.totalInvestment"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="项目负责人">
                            <el-input v-model="addForm.principal"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="8">
                        <el-form-item label="负责人电话">
                            <el-input v-model="addForm.principalTel"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目现状">
                            <el-input   type="textarea" :rows="2" v-model="addForm.projectStatus"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目详情">
                            <el-input  type="textarea" :rows="2"  v-model="addForm.projectDetails"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="建筑高度">
                            <el-input v-model="addForm.buildingHeight"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="建筑面积">
                            <el-input v-model="addForm.buildingArea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目地址">
                            <el-input v-model="addForm.projectAddress"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="经度">
                            <el-input v-model="addForm.longitude"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="6">
                        <el-form-item label="纬度">
                            <el-input v-model="addForm.latitude"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="计划开始时间">
                            <el-input v-model="addForm.planStartDate"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计划结束时间">
                            <el-input v-model="addForm.planEndDate"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="计划开始时间">
                            <el-input v-model="addForm.actualStartDate"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计划结束时间">
                            <el-input v-model="addForm.actualEndDate"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="上传项目照片">
                            <el-upload
                                    action="#"
                                    list-type="picture-card"
                                    :on-preview="handlePreview"
                                    :on-change="filechange"
                                    :on-remove="handleRemove"
                                    :auto-upload="false">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible1">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>

                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="add" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="添加项目类型"
                :visible.sync="dialogVisibletype"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormTypeRef" :model="addFormtype" label-width="100px" >
                        <el-form-item label="项目类型编码">
                            <el-input v-model="addFormtype.code"></el-input>
                        </el-form-item>
                <el-form-item label="项目类型名称">
                    <el-input v-model="addFormtype.typeName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibletype = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addtype" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改项目类型"
                :visible.sync="editdialogVisibleType"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editFormType" label-width="120px" :rules="editFormTypeRules">
                <el-form-item label="项目类型编码" prop="code">
                    <el-input v-model="editFormType.code" ></el-input>
                </el-form-item>
                <el-form-item label="项目类型名称" prop="typeName">
                    <el-input v-model="editFormType.typeName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditProType">确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    export default {
        name: "proMangage",
        data(){
            return{
                tableData:[
                    {id:'TM-89757',name:'小龙',date:'2021-07-15',bumen:'研发中心',start:'2021-07-15 01:32',end:'2021-07-15 01:34',status:'早退',yingqin:'8.0h',shiqin:'0.1h',remake:'测试'}
                ],
                protypeData:[],
                dialogVisible:false,
                dialogVisibletype:false,
                editdialogVisibleType:false,
                addForm:{
                    projectName:'',
                    projectTypeId:'',
                    totalInvestment:'',
                    principal:'',
                    principalTel:'',
                    projectDetails:'',
                    projectStatus:'',
                    buildingHeight:'',
                    buildingArea:'',
                    projectAddress:'',
                    longitude:'',
                    latitude:'',
                    projectImagePath:'',
                    planStartDate:'',
                    planEndDate:'',
                    actualStartDate:'',
                    actualEndDate:'',
                },
                addFormtype:{
                    code:'',
                    typeName:''
                },
                editFormType:{},
                editFormTypeRules:{
                    code:[{required:true,message:'请输入项目类型编码',trigger:'blur'}],
                    typeName:[{required:true,message:'请输入项目类型名称',trigger:'blur'}],
                },
                protype:true,
                dialogImageUrl: '',
                dialogVisible1: false,
                filelist:[],

            }
        },
        created(){
            this.getProList()
          this.getProtype()
        },
        methods:{
            addPro(){
                this.dialogVisible=true
            },
            addProtype(){
                this.dialogVisibletype=true
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            async getProList(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAll')
                if(res.status!='3000'){
                    return this.$message.error('查询项目列表失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async getProtype(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
                if(res.status!='3000'){
                    return this.$message.error('查询项目类型失败！'+res.message)
                }
                this.protypeData=res.data;
            },
            async add(){
               var formData=new FormData();
                for(let x in this.filelist){
                    formData.append("multipartFiles",this.filelist[x].raw);
                }
               formData.append("projectName",this.addForm.projectName);
               formData.append("projectTypeId",this.addForm.projectTypeId);
               formData.append("totalInvestment",this.addForm.totalInvestment);
               formData.append("principal",this.addForm.principal);
               formData.append("principalTel",this.addForm.principalTel);
               formData.append("projectDetails",this.addForm.projectDetails);
               formData.append("projectStatus",this.addForm.projectStatus);
               formData.append("buildingHeight",this.addForm.buildingHeight);
               formData.append("buildingArea",this.addForm.buildingArea);
               formData.append("projectAddress",this.addForm.projectAddress);
               formData.append("longitude",this.addForm.longitude);
               formData.append("latitude",this.addForm.latitude);
               formData.append("projectImagePath",this.addForm.projectImagePath);
               formData.append("planStartDate",this.addForm.planStartDate);
               formData.append("planEndDate",this.addForm.planEndDate);
               formData.append("actualStartDate",this.addForm.actualStartDate);
               formData.append("actualEndDate",this.addForm.actualEndDate);
               const {data:res}= await this.$http1.post('/v1.0/project/basicInfo/save', formData)
                if(res.status!='6002'){
                    return this.$message.error('添加项目失败！'+res.message)
                }
                this.$message.success('添加项目成功！');
                this.dialogVisible=false;
                this.getProList();
            },
            async addtype(){
                const {data:res}= await this.$http1.post('/v1.0/project/type/save', this.addFormtype)
                console.log(res)
                if(res.status!='6002'){
                    return this.$message.error('添加项目类型失败！'+res.message)
                }
                this.$message.success('添加项目类型成功！');
                this.dialogVisibletype=false;
                this.getProtype();
            },
            async editDialogType(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/type/findById?id='+id);
                if(res.status!=="3000"){
                    return 0;
                }
                this.editFormType=res.data;
                this.editdialogVisibleType=true
            },
            async iseditProType(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    const {data:res}=await  this.$http1.put('/v1.0/project/type/update',this.editFormType);
                    if(res.status!=="6002"){
                        return this.$message.error('修改项目类型失败！'+res.message)
                    }
                    this.$message.success('修改项目类型成功！');
                    this.getProtype();
                    this.editdialogVisibleType = false
                })
            },
            protypeChange(e){
                this.addForm.projectTypeId=e
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=filelist
            },
            handlePreview(file) {
                console.log(file)
               this.dialogImageUrl = file.url;
                this.dialogVisible1 = true;
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/basicInfo/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除项目失败！'+res.message)
                }
                this.$message.success('删除项目成功！');
                this.getProList();
            },
            async deleteMsg2(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/type/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除项目类型失败！'+res.message)
                }
                this.$message.success('删除项目类型成功！');
                this.getProtype();
            },
            renderTime(date) {
                var dateee = new Date(date).toJSON();
                return new Date(new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            }
        }
    }
</script>

<style scoped lang="less">

    .bg{
        background-color: #05081B;
        height: 100vh;
    }
    #kaoList{
        background-color: #111224;
        position: absolute;
        left: 10px;
        top: 70px;
        bottom: 72px;
        right: 10px;
        width: 1900px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
    /deep/ .el-card{
        background-color: #061840;
        color: #cadcf6;
        border: 1px solid #2862b7;
        width: 100%;
        height: 100%;
    }
    /deep/ .el-card__header{
        border-bottom:1px solid #2862b7;
        background-color: #061840;
        height: 50px;
        padding-left: 10px !important;
        .clearfix{
            margin-top: -12px;
            padding-left: 10px;
            /deep/ .el-button{
                background-color: #273F76;
                color: white;
                border: 1px solid #273F76;
                height: 34px;
                padding: 8px 20px;
            }
        }
        .item{
            background-color: #061840;
        }
        span{
            color: #cadcf6;
            font-size: 14px;
        }
    }
    /deep/ .el-table th, /deep/.el-table tr{
        background-color: #142245;
    }
    /deep/ .cell{
        color: #cadcf6;
    }
    /deep/ .el-table, /deep/.el-table__expanded-cell{
        background-color: #142245;
    }
    /deep/ .el-table td,   /deep/.el-table th.is-leaf{
        border-bottom: 1px solid #273F76;
    }
    /deep/ .el-table::before{
        height: 0;
    }
    /deep/ .el-table__header-wrapper{
        height:54px;
    }
    /deep/ .el-table tbody tr:hover>td {
        background-color: #3B5988;
    }
    /deep/ .el-dialog{
        background-color: #142245 !important;
        color: white;
        border-radius: 8px;
    }
    /deep/ .el-dialog__header {
        //background: -webkit-linear-gradient(left, #3ee8c2, #25e4a3);
        border-radius: 8px 8px 0 0 ;
    }
    /deep/ .el-dialog__title{
        font-weight: bold;
        font-size: 20px;
        color: #fff;
    }
    /deep/ .el-form-item__label{
        color: #fff;
    }
    /deep/ .el-input__inner{
        border-radius: 18px;
        height: 36px;
        background-color: #142245 !important;
        color: #eef5ff !important;
        border: 1px solid #2862b7 !important;
    }
    /deep/ .el-textarea__inner{
        background-color: #142245 !important;
        color: #eef5ff !important;
        border: 1px solid #2862b7 !important;
    }
    /deep/ .el-table__body-wrapper{
        height: 100% !important;
    }
    /deep/ .el-table__body-wrapper {
        /*height: 200px; !* 滚动条整体高 必须项 *!*/
        border-right: none;
        overflow-y: scroll;/* overflow-y为了不出现水平滚动条*/
    }
    /deep/ .el-dialog--center .el-dialog__body{
        padding: 10px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
        width:6px;/* 滚动条的宽高 必须项 */
        height: 5px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
        background-color: #bfcbd9;/* 滚动条的宽 */
        border-radius: 3px;
    }
    /deep/ .el-button{
        background-color: #142245 !important;
        color: #eef5ff !important;
        border: 1px solid #2862b7 !important;
    }
</style>
<style lang="less">

</style>
