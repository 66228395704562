<template>
  <div id="asiderBar">
    <!--    <div class="toggle-button" @click="toggleZhankai">|||</div>-->
    <el-menu
      class="el-menu-vertical-demo"
      text-color="#000"
      active-text-color="#fff"
      :collapse="isZhank"
      :collapse-transition="false"
      :router="true"
      :default-active="$route.path"
    >
   <el-menu-item v-for="item in menuLevel2" :index="item.path" :key="item.path">
        <i :class="item.iconCls"></i>
        <span>{{item.name}}</span>
      </el-menu-item>
   <!-- <el-menu-item index="/userList">
        <i class="el-icon-user"></i>
        <span>用户管理</span>
      </el-menu-item>
      <el-menu-item index="/roleList">
        <i class="el-icon-s-custom"></i>
        <span>角色管理</span>
      </el-menu-item>
      <el-menu-item index="/companyList">
            <i class="el-icon-s-flag"></i>
            <span>单位管理</span>
      </el-menu-item>
      <el-menu-item index="/departmentList">
        <i class="el-icon-s-flag"></i>
        <span>部门管理</span>
      </el-menu-item>
      <el-menu-item index="/resourcesList">
        <i class="el-icon-s-tools"></i>
        <span>资源管理</span>
      </el-menu-item>
      <el-menu-item index="/journalList">
        <i class="el-icon-s-order"></i>
        <span>日志管理</span>
      </el-menu-item>
        <el-menu-item index="/activiti">
            <i class="el-icon-s-order"></i>
            <span>流程管理</span>
        </el-menu-item>
        <el-menu-item index="/equipment">
            <i class="el-icon-s-order"></i>
            <span>设备管理</span>
        </el-menu-item>-->
    </el-menu>
  </div>
</template>

<script>
  export default {
    name: 'tianBaoLeft',
    data(){
      return {
        isZhank: false,
        shiList: [],
        menuLevel2:[]
      }
    },
    created () {
     this.menuLevel2=JSON.parse(window.sessionStorage.getItem('menulevel2'))[4];
      console.log(this.menuLevel2)
      //this.getShiMeau();
    },
    methods: {
      async getShiMeau () {
        /* const { data: res } = await this.$http.get('qhCode/loginUserAuthCode')
         console.log(res)
         if (res.status !== '1000') {
           return this.$message.error(res.msg)
         }
         this.shiList = res.data*/
      },
      toggleZhankai () {
        this.isZhank = !this.isZhank
      },

    },
  }
</script>

<style lang="less" scoped>

  .toggle-button {
    background-color: #4a5064;
    font-size: 10px;
    line-height: 24px;
    color: #eef5ff;
    text-align: center;
    letter-spacing: 0.2em;
    cursor: pointer;
  }
  .el-aside {
    background-color: #333744;
  }

</style>
