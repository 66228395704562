<template>
    <div class="bg">
        <div class="tableleft">
        <!--    <el-table
                    :data="data"
                    style="width: 100%;margin-bottom: 20px;"
                    row-key="id"
                    height="100%"
                    default-expand-all
                    :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                    :tree-props="{children: 'children', hasChildren: 'hasChildren'}">
                <el-table-column
                        prop="text"
                        label="任务名"
                        width="120">
                    <template v-slot="scope">
                        <span v-if="scope.row.children"> <i class="el-icon-folder-opened"></i> {{ scope.row.text }}</span>
                        <span v-else><i class="el-icon-document"></i> {{  scope.row.text  }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                        prop="start_date"
                        label="开始时间"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="end_date"
                        label="完成时间"
                        width="120">
                </el-table-column>
                <el-table-column
                        prop="zeren"
                        label="责任人">
                </el-table-column>
            </el-table>-->
            <el-tree
                    :data="data"
                    node-key="id"
                    ref="tree"
                    :props="defaultProps"
                    @node-click="handleCheckChange"
                    default-expand-all
                    :expand-on-click-node="false">
                <span class="custom-tree-node" slot-scope="{ node, data }">
                <el-tooltip v-if="data.parent" class="item" effect="dark" :content="data.parent.name" placement="top-start">
                    <span><i class="el-icon-folder-opened"></i> {{ data.parent.name | ellipsis(12) }}</span>
                </el-tooltip>
         </span>
            </el-tree>
        </div>
        <div class="mainpart">
            <el-card class="box-card" style="height: 100%;" >
             <!--   <div slot="header" class="clearfix">
                    <el-button  icon="el-icon-search">新增同级</el-button>
                    <el-button icon="el-icon-search">新增下级</el-button>
                    <el-button  icon="el-icon-search">删除</el-button>
                    <el-button  icon="el-icon-search">成本明细</el-button>
                    <el-button  icon="el-icon-search">成本分析</el-button>
                    <el-button icon="el-icon-search">导出excle</el-button>
                </div>-->
                <div class="tableleft2">
                    <el-input
                            placeholder="输入关键字进行过滤"
                            v-model="filterText">
                    </el-input>
                    <el-tree
                            class="filter-tree"
                            :data="hetongData"
                            :props="defaultProps"
                            default-expand-all
                            @node-click="hetongclick"
                            :filter-node-method="filterNode"
                            node-key="id"
                            :current-node-key="expandDefault"
                            ref="tree">
                    </el-tree>
                </div>
                <div class="serverTable">
                  <!-- -->
                    <el-descriptions class="margin-top" title="合同基本信息" :column="3"  border>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                合同名称
                            </template>
                            {{jibenform.name}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                签订日期
                            </template>
                            {{jibenform.signDate}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                总金额(元)
                            </template>
                            {{jibenform.totalAmount}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-location-outline"></i>
                                合作单位
                            </template>
                            {{jibenform.cooperationUnit}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                履约保证金(元)
                            </template>
                            {{jibenform.securityDeposit}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                质保金(元)
                            </template>
                            {{jibenform.warrantyAmount}}
                        </el-descriptions-item>
                        <!--<el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                预算金额(元)
                            </template>
                            {{jibenform.budgetAmount}}
                        </el-descriptions-item>-->
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                合同文件
                            </template>
                            <a style="text-decoration: underline;cursor: pointer"  v-for="item in jibenform.files" target="_blank"  @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions class="margin-top" title="费控基本信息" :column="4"  border>
                        <template slot="extra">
                            <el-button type="primary" size="small" v-show="jibenShow" @click="beforeaddjiben" v-has="'editcost'">新增</el-button>
                            <el-button type="primary" size="small" v-show="!jibenShow" @click="beforeeditjiben" v-has="'editcost'">修改</el-button>
                             <el-button type="warning" size="small"  @click="tongjiCost" >统计</el-button>
                        </template>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                预算(元)
                            </template>
                            {{jibenform.budgetAmount}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                收款开票总额(元)
                            </template>
                            {{shouzhiForm.totalReceipt}}
                        </el-descriptions-item>
                           <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                实际到账总额(元)
                            </template>
                            {{shouzhiForm.actualTotalReceipt}}
                        </el-descriptions-item>
                           <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                 应收总额(元)
                            </template>
                            {{shouzhiForm.shouldTotalReceipt}}
                        </el-descriptions-item>
                          <el-descriptions-item>
                            <template slot="label">
                            </template>
                           </el-descriptions-item>
                         <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                支付开票总额(元)
                            </template>
                            {{shouzhiForm. totalPayment}}
                        </el-descriptions-item>
                           <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                实际打款总额(元)
                            </template>
                            {{shouzhiForm.actualTotalPayment}}
                        </el-descriptions-item>
                           <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                应付总额(元)
                            </template>
                            {{shouzhiForm.shouldTotalPayment}}
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions class="margin-top" title="收款信息" >
                        <template slot="extra">
                            <el-button type="primary" size="small" @click="beforeaddshoukuan" v-has="'addsk'" >新增</el-button>
                        </template>
                    </el-descriptions>
                    <!--  <el-descriptions class="margin-top" title="收款信息" :column="3"  border>
                        <template slot="extra">
                            <el-button type="primary" size="small" v-show="shoukuanShow" @click="beforeaddshoukuan">新增</el-button>
                            <el-button type="primary" size="small" v-show="!shoukuanShow" @click="beforeeditshoukuan">修改</el-button>
                        </template>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                收款金额
                            </template>
                            {{shoukuanform.receiptAmount}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                收款日期
                            </template>
                            {{shoukuanform.receiptDate}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-location-outline"></i>
                                收款类别
                            </template>
                            {{shoukuanform.receiptType}}
                        </el-descriptions-item>
                        <el-descriptions-item :span="2">
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                收款描述
                            </template>
                            {{shoukuanform.receiptDescription}}
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                收款文件
                            </template>
                            <a style="text-decoration: underline"  v-for="item in shoukuanform.files" target="_blank" :href=" ['http://localhost:8807/'+item.filePath]">{{item.fileName}}<br> </a>
                        </el-descriptions-item>
                    </el-descriptions>
                    <el-descriptions class="margin-top" title="支付信息" :column="3"  border>
                        <template slot="extra">
                            <el-button type="primary" size="small">修改</el-button>
                        </template>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-user"></i>
                                收款金额
                            </template>
                            收款金额
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-mobile-phone"></i>
                                收款日期
                            </template>
                            收款日期
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-location-outline"></i>
                                收款类别
                            </template>
                            收款类别
                        </el-descriptions-item>
                        <el-descriptions-item :span="3">
                            <template slot="label">
                                <i class="el-icon-tickets"></i>
                                收款描述
                            </template>
                            <el-tag size="small">收款描述</el-tag>
                        </el-descriptions-item>
                        <el-descriptions-item>
                            <template slot="label">
                                <i class="el-icon-office-building"></i>
                                收款文件
                            </template>
                        </el-descriptions-item>
                    </el-descriptions>-->
                    <el-table
                            :data="shoukuanData"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                             @expand-change="handledetail"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="60"
                        >
                        </el-table-column>
                         <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table
                                        :data="tableDataShouru"
                                        border
                                        class="monthTable"
                                        :header-cell-style="{color:'black'}"
                                >
                                    <el-table-column
                                            type="index"
                                            label="序号"
                                            width="60">
                                    </el-table-column>
                                    <el-table-column
                                            prop="receiptDescription"
                                            label="收款描述"
                                            width="200"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="receiptDate"
                                            label="到账时间"
                                            width="110"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="receiptAmount"
                                            label="到账金额(元)"
                                            width="120"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                prop="files"
                                label="开票文件"
                                width="300">
                            <template v-slot="scope">
                                <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files" target="_blank"  @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                            </template>
                        </el-table-column>
                                    <el-table-column
                                            label="操作"
                                            align="center"
                                    >
                                        <template v-slot="scope">
                                              <el-button  type="primary" @click="beforeeditShouruShiji(scope.row.id)" icon="el-icon-delete" size="mini">修改</el-button>
                                            <el-button  type="primary" @click="deleteMsg2(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                         <el-table-column
                                prop="receiptDescription"
                                label="收款描述"
                                width="200">
                        </el-table-column>
                           <el-table-column
                                prop="receiptDate"
                                label="开票日期"
                                width="110">
                        </el-table-column>
                        <el-table-column
                                prop="receiptAmount"
                                label="开票金额(元)"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="files"
                                label="开票文件"
                                width="250">
                            <template v-slot="scope">
                                <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files" target="_blank"  @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                               <el-button  type="primary"  icon="el-icon-circle-plus-outline"  @click="addShijiShouru(scope.row.id)"  size="mini" v-has="'editsk'">新增实际收款</el-button>
                                <el-button  type="primary"  icon="el-icon-edit"  @click="beforeeditShoukuan(scope.row.id)"  size="mini" v-has="'editsk'">修改</el-button>
                                <el-button  type="primary" icon="el-icon-delete" @click="deleteShoukuan(scope.row.id)" size="mini" v-has="'delsk'">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-descriptions class="margin-top" title="支付信息" >
                        <template slot="extra">
                        <el-button type="primary" size="small" @click="beforeaddzhifu" v-has="'addzf'">新增</el-button>
                        </template>
                    </el-descriptions>
                    <el-table
                            :data="zhifuData"
                            border
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                             @expand-change="handledetail2"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="60"
                        >
                        </el-table-column>
                         <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table
                                        :data="tableDataZhichu"
                                        border
                                        class="monthTable"
                                        :header-cell-style="{color:'black'}"
                                >
                                    <el-table-column
                                            type="index"
                                            label="序号"
                                            width="60">
                                    </el-table-column>
                                    <el-table-column
                                            prop="paymentDescription"
                                            label="支付描述"
                                            width="200"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="paymentDate"
                                            label="支付日期"
                                            width="110"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="paymentAmount"
                                            label="支付金额(元)"
                                            width="120"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                prop="files"
                                label="支付文件"
                                width="300">
                            <template v-slot="scope">
                                <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files" target="_blank"  @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                            </template>
                        </el-table-column>
                                    <el-table-column
                                            label="操作"
                                            align="center"
                                    >
                                        <template v-slot="scope">
                                              <el-button  type="primary" @click="beforeeditZhifuShiji(scope.row.id)" icon="el-icon-delete" size="mini">修改</el-button>
                                            <el-button  type="primary" @click="deleteMsg3(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                          <el-table-column
                                prop="paymentDescription"
                                label="支付描述"
                                width="200">
                        </el-table-column>
                            <el-table-column
                                prop="paymentDate"
                                label="支付日期"
                                width="110">
                        </el-table-column>
                        <el-table-column
                                prop="paymentAmount"
                                label="支付金额(元)"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="files"
                                label="支付文件"
                                width="250">
                            <template v-slot="scope">
                                <a style="text-decoration: underline;cursor: pointer"  v-for="item in scope.row.files" target="_blank"  @click="fileupload(item.filePath)">{{item.fileName}}<br> </a>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary"  icon="el-icon-circle-plus-outline"  @click="addShijiZhichu(scope.row.id)"  size="mini" v-has="'editsk'">新增实际支付</el-button>
                                <el-button  type="primary"  icon="el-icon-edit"  @click="beforeeditZhifu(scope.row.id)"  size="mini" v-has="'editzf'">修改</el-button>
                                <el-button  type="primary" icon="el-icon-delete" @click="deleteZhifu(scope.row.id)" size="mini" v-has="'delzf'">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <div class="echartsCost" v-show="echartsShow">
            <a class="closeX" @click="cloaseYear">X</a>
            <div class="echartsCostDiv" id="cost">

            </div>
            <div class="yearChoose" >
                <p v-for="item in yearList" :class="{'activeyear':isActive==item}" @click="changeYear(item)">{{item}}年</p>
            </div>
        </div>
        <el-dialog
                title="新增费控基本信息"
                :visible.sync="dialogVisiblejiben"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="addjibenForm" label-width="110px" >
                <el-form-item label="预算金额(元)">
                    <el-input v-model="addjibenForm.budgetAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisiblejiben = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="addjiben" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改费控基本信息"
                :visible.sync="dialogVisiblejibenEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="editjibenForm" label-width="110px" >
                <el-form-item label="预算金额(元)">
                    <el-input v-model="editjibenForm.budgetAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisiblejibenEdit = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="editjiben" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增费控预收款信息"
                :visible.sync="dialogVisibleshoukuan"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="addshoukuanform" label-width="110px" :rules="shoukuanFormRule" >
                <el-form-item label="收款金额(元)" prop="receiptAmount">
                    <el-input v-model="addshoukuanform.receiptAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="收款日期" prop="receiptDate">
                    <el-date-picker
                            v-model="addshoukuanform.receiptDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收款描述" prop="receiptDescription">
                    <el-input type="textarea" :rows="3" v-model="addshoukuanform.receiptDescription"></el-input>
                </el-form-item>
                <el-form-item label="收款文件" prop="files">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileListShoukuan"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                             </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisibleshoukuan = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="addshoukuan" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改费控收款信息"
                :visible.sync="dialogVisibleshoukuanEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="editshoukuanform" label-width="110px" :rules="shoukuanFormRule">
                <el-form-item label="收款金额(元)" prop="receiptAmount">
                    <el-input v-model="editshoukuanform.receiptAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="收款日期" prop="receiptDate">
                    <el-date-picker
                            v-model="editshoukuanform.receiptDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收款描述">
                    <el-input type="textarea" :rows="3" v-model="editshoukuanform.receiptDescription"></el-input>
                </el-form-item>
                <el-form-item label="收款文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit"
                            :on-change="filechangeEdit"
                            :file-list="fileListShoukuanEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：文件日期+资料简述;文件格式:PDF;如有需要审批的文件,需要上传审批通过后的pdf文件。</div>
                             </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisibleshoukuanEdit = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="editShoukuan" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增费控支付信息"
                :visible.sync="dialogVisiblezhifu"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="addzhifuform" label-width="110px" :rules="zhifuFormRule">
                <el-form-item label="支付金额(元)" prop="paymentAmount">
                    <el-input v-model="addzhifuform.paymentAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="支付日期"  prop="paymentDate">
                    <el-date-picker
                            v-model="addzhifuform.paymentDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="支付描述" prop="paymentDescription">
                    <el-input type="textarea" :rows="3" v-model="addzhifuform.paymentDescription"></el-input>
                </el-form-item>
                <el-form-item label="支付文件"  prop="files">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemove2"
                            :on-change="filechange2"
                            :file-list="fileListZhifu"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：发生日期+资料简述</div>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">* 文件格式：PDF，且文件大小不超过500KB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisiblezhifu = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="addzhifu" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改费控支付信息"
                :visible.sync="dialogVisiblezhifuEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="editzhifuform" label-width="110px" :rules="zhifuFormRule">
                <el-form-item label="支付金额(元)" prop="paymentAmount">
                    <el-input v-model="editzhifuform.paymentAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="支付日期"  prop="paymentDate">
                    <el-date-picker
                            v-model="editzhifuform.paymentDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="支付描述" prop="paymentDescription">
                    <el-input type="textarea" :rows="3" v-model="editzhifuform.paymentDescription"></el-input>
                </el-form-item>
                <el-form-item label="支付文件"  prop="files">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveEdit2"
                            :on-change="filechangeEdit2"
                            :file-list="fileListZhifuEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：发生日期+资料简述</div>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">* 文件格式：PDF，且文件大小不超过500KB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisiblezhifu = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="editZhifu" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增实际收款信息"
                :visible.sync="dialogVisibleshoukuanShiji"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="shijishoukuanForm" label-width="110px" :rules="shoukuanFormRule" >
                <el-form-item label="收款金额(元)" prop="receiptAmount">
                    <el-input v-model="shijishoukuanForm.receiptAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="收款日期" prop="receiptDate">
                    <el-date-picker
                            v-model="shijishoukuanForm.receiptDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收款描述" prop="receiptDescription">
                    <el-input type="textarea" :rows="3" v-model="shijishoukuanForm.receiptDescription"></el-input>
                </el-form-item>
                <el-form-item label="收款文件" >
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveSj"
                            :on-change="filechangeSj"
                            :file-list="fileListShoukuanShiji"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：发生日期+资料简述</div>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">* 文件格式：PDF，且文件大小不超过500KB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisibleshoukuanShiji = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="addshoukuanShiji" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改实际收款信息"
                :visible.sync="dialogVisibleshoukuanShijiEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="shijishoukuanFormEdit" label-width="110px" :rules="shoukuanFormRule" >
                <el-form-item label="收款金额(元)" prop="receiptAmount">
                    <el-input v-model="shijishoukuanFormEdit.receiptAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="收款日期" prop="receiptDate">
                    <el-date-picker
                            v-model="shijishoukuanFormEdit.receiptDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="收款描述" prop="receiptDescription">
                    <el-input type="textarea" :rows="3" v-model="shijishoukuanFormEdit.receiptDescription"></el-input>
                </el-form-item>
                <el-form-item label="收款文件" prop="files">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveSjEdit"
                            :on-change="filechangeSjEdit"
                            :file-list="fileListShoukuanShijiEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：发生日期+资料简述</div>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">* 文件格式：PDF，且文件大小不超过500KB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisibleshoukuanShijiEdit = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="editshoukuanShiji" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增实际支付信息"
                :visible.sync="dialogVisibleZhifuShiji"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="shijiZhifuForm" label-width="110px" :rules="zhifuFormRule" >
                <el-form-item label="支付金额(元)" prop="paymentAmount">
                    <el-input v-model="shijiZhifuForm.paymentAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="支付日期" prop="paymentDate">
                    <el-date-picker
                            v-model="shijiZhifuForm.paymentDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="支付描述" prop="paymentDescription">
                    <el-input type="textarea" :rows="3" v-model="shijiZhifuForm.paymentDescription"></el-input>
                </el-form-item>
                <el-form-item label="支付文件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveSjZhichu"
                            :on-change="filechangeSjZhichu"
                            :file-list="fileListZhichuShiji"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：发生日期+资料简述</div>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">* 文件格式：PDF，且文件大小不超过500KB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisibleZhifuShiji = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="addzhichuShiji" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改实际收款信息"
                :visible.sync="dialogVisibleZhifuShijiEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormReffile" :model="shijiZhifuFormEdit" label-width="110px" :rules="zhifuFormRule" >
                <el-form-item label="支付金额(元)" prop="paymentAmount">
                    <el-input v-model="shijiZhifuFormEdit.paymentAmount" type="number"  oninput ="value=value.replace(/[^0-9.]/g,'')"></el-input>
                </el-form-item>
                <el-form-item label="支付日期" prop="paymentDate">
                    <el-date-picker
                            v-model="shijiZhifuFormEdit.paymentDate"
                            value-format="yyyy-MM-dd"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="支付描述" prop="paymentDescription">
                    <el-input type="textarea" :rows="3" v-model="shijiZhifuFormEdit.paymentDescription"></el-input>
                </el-form-item>
                <el-form-item label="支付文件" prop="files">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :on-remove="handleRemoveSjEdit2"
                            :on-change="filechangeSjEdit2"
                            :file-list="fileListZhichuShijiEdit"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">*上传资料的文件名格式：发生日期+资料简述</div>
                        <div slot="tip" class="el-upload__tip" style="color: #eb1515">* 文件格式：PDF，且文件大小不超过500KB</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
        <el-button round type="goon" size="medium" @click="dialogVisibleZhifuShijiEdit = false">取 消</el-button>
        <el-button round type="goon" size="medium"  @click="editZhifuShiji" >确 定</el-button>
  </span>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...</div>
        </div>
    </div>
</template>

<script>
    import costLeft from "./costLeft";
    import * as echarts from 'echarts';
    export default {
        name: "chengben",
        data(){
          return{
              tableData: [],
              data: [{
                  name:'图木舒克市Epc项目',
                  children:[
                      {
                          name: '无',
                      }]
              }],
              hetongData:[{
                  name:'合同列表',
              }],
              proshow:false,
              defaultProps: {
                  children: 'children',
                  label: 'name'
              },
              filterText:'',
              jibenShow:true,
              jibenform:{
                  name:'',
                  signDate:'',
                  totalAmount:'',
                  cooperationUnit:'',
                  securityDeposit:'',
                  warrantyAmount:'',
                  totalPayment:0,
                  totalReceipt:0,
                  budgetAmount:'',
                  files:[]
              },
              shouzhiForm:{

              },
              dialogVisiblejiben:false,
              dialogVisiblejibenEdit:false,
              addjibenForm:{
                  budgetAmount:'',

                  contractId:'',
                  projectId:this.$root.proId
              },
              editjibenForm:{},
              shoukuanFormRule:{
                  receiptAmount:[{required:true,message:'请输入收款金额',trigger:'blur'}],
                  receiptDate:[{required:true,message:'请输入收款日期',trigger:'blur'}],
              },
              addshoukuanform:{
                  costId:'',
                  receiptAmount:'',
                  receiptDate:'',
                  receiptDescription:'',
                  files:[]
              },
              shijishoukuanForm:{
                  receiptAmount:"",
                  receiptDate:'',
                  receiptDescription:'',
                  receiptCostId:''
              },
              shijishoukuanFormEdit:{

              },
              shijiZhifuForm:{
                  paymentAmount:"",
                  paymentDate:'',
                  paymentDescription:'',
                  paymentCostId:''
              },
              shijiZhifuFormEdit:{

              },
              fileListShoukuanShijiEdit:[],
              fileListShoukuanShiji:[],
              fileListZhichuShiji:[],
              fileListZhichuShijiEdit:[],
              editshoukuanform:{},
              dialogVisibleshoukuan:false,
              dialogVisibleshoukuanEdit:false,
              dialogVisibleshoukuanShiji:false,
              dialogVisibleshoukuanShijiEdit:false,
              dialogVisibleZhifuShiji:false,
              dialogVisibleZhifuShijiEdit:false,
              costId:'',
              shoukuanData:[],
              tableDataShouru:[],
              tableDataZhichu:[],
              fileListShoukuan:[],
              fileListShoukuanEdit:[],
              fileListShoukuanEditDeleteID:[],
              fileListShoukuanEditDeleteIDSj:[],
              fileListZhichuEditDeleteIDSj:[],
              fileLisShoukuantEditAdd:[],
              fileLisShoukuantEditAddSj:[],
              fileLisZhichuEditAddSj:[],
              dialogVisiblezhifu:false,
              zhifuFormRule:{
                  paymentAmount:[{required:true,message:'请输入支付金额',trigger:'blur'}],
                  paymentDate:[{required:true,message:'请输入支付日期',trigger:'blur'}],
              },
              zhifuData:[],
              addzhifuform:{
                  costId:'',
                  paymentAmount:'',
                  paymentDate:'',
                  paymentDescription:'',
                  files:[]
              },
              editzhifuform:{},
              fileListZhifu:[],
              fileListZhifuEdit:[],
              fileListZhifuEditDeleteID:[],
              fileListZhifuEditAdd:[],
              dialogVisiblezhifuEdit:false,
              queryInfo:{
                  pageNumber:1,
                  pageSize:9999,
                  projectId:this.$root.proId,
                  categoryId:'',
                  name:'',
                  contractNum:'',
                  cooperationUnit:''
              },
              echartsShow:false,
              yearList:[],
              isActive:'',
              zhichuData:[],
              zhichu:[],
              shouruData:[],
              shouru:[],
              yearData:[],
              httongId:"",
              expandDefault:'',
              row:'',
              expandedRows:'',
              row2:'',
              expandedRows2:'',
              treeId:''
          }
        },
        methods:{
            async getHtTree(){
                const {data:res}=await  this.$http5.get('/v1.0/cost/contract/category/findContractCategoryTree?projectId='+this.$root.proId)
                if(res.businessCode!='3000'){
                    this.data=[]
                    return this.$message.error('获取合同类别失败'+res.message)
                }
                this.data=res.data
                const {data:res2}=await  this.$http5.post('/v1.0/cost/contract/manage/findPages',this.queryInfo)
                this.hetongData=[];
                this.hetongData.push({name:'合同列表',children:res2.data.result})
                this.expandDefault=res2.data.result[0].id
                this.hetongclick(res2.data.result[0])

            },
            async handleCheckChange(data) {
                const {data:res}=await  this.$http5.get('/v1.0/cost/contract/manage/findAllByCategoryId?categoryId='+data.parent.id)
                if(res.businessCode!='3000'){
                    return  this.hetongData=[]
                }
                this.hetongData=[];
                this.hetongData.push({name:'合同列表',children:res.data})
            },
            hetongclick(data){
                this.httongId=data.id
                this.checkclick(data.id);
            },
            async checkclick(id){
                    this.treeId=id
                    this.getJiben(id)
                    const {data:res}=await  this.$http5.get('/v1.0/cost/basicInfo/findByContractId?contractId='+id);
                    if(res.businessCode!=="3000") {
                        return this.$message.error('获取费控基本信息失败！');
                    }else {
                        this.jibenform=res.data.contract;
                        this.addjibenForm.contractId=res.data.contract.id
                        this.editjibenForm.contractId=res.data.contract.id
                        if(res.data.cost){
                            this.jibenShow=false
                            this.jibenform.budgetAmount=res.data.cost.budgetAmount
                            if(this.jibenform.budgetAmount){
                                this.jibenform.budgetAmount=this.jibenform.budgetAmount.toLocaleString()
                            }
                            this.jibenform.totalReceipt=res.data.cost.totalReceipt
                            if(this.jibenform.totalReceipt){
                                this.jibenform.totalReceipt=this.jibenform.totalReceipt.toLocaleString()
                            }
                            this.jibenform.totalPayment=res.data.cost.totalPayment
                            if(this.jibenform.totalPayment){
                                this.jibenform.totalPayment=this.jibenform.totalPayment.toLocaleString()
                            }
                            this.costId=res.data.cost.id
                        }else {
                            this.jibenform.budgetAmount='';
                            this.jibenform.totalReceipt=''
                            this.jibenform.totalPayment=''
                            this.jibenShow=true;
                            this.costId=''
                        }
                        this.shoukuanData=res.data.receiptCosts;
                        this.zhifuData=res.data.paymentCosts;
                    }
            },
            async getJiben(id){
                const {data:res2}=await  this.$http5.get('/v1.0/cost/statistics/queryCostAmountStatistics/'+this.$root.proId+'/'+id);
                if(res2.businessCode!=="3000") {
                    this.$message.error('获取费控收支信息失败！');
                }
                this.shouzhiForm=res2.data
            },
            async checkclick2(id){
                const {data:res1}=await  this.$http5.get('/v1.0/cost/receipt/findAllByCostId?contractId='+id);
                if(res1.businessCode!=="3000") {
                    return this.$message.error('获取费控收款信息失败！');
                }else {
                    if(res1.data.length!=0){
                        this.shoukuanShow=false
                        this.shoukuanform=res1.data
                    }else {
                        for (let key in this.shoukuanform) {
                            this.shoukuanform[key]=''
                        }
                        this.shoukuanShow=true
                    }
                }
            },
            async fileupload(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)  ;
            },
            beforeaddjiben(){
                    if(this.addjibenForm.contractId){
                        this.dialogVisiblejiben=true
                    }else {
                        this.$message.error('请先点击合同列表！')
                    }
            },
            async addjiben(){
                const {data:res}=await  this.$http5.post('/v1.0/cost/basicInfo/save',this.addjibenForm);
                if(res.businessCode!='6000'){
                    return this.$message.error('添加费控基本信息失败！'+res.message)
                }
                this.$message.success('添加费控基本信息成功！');
                this.dialogVisiblejiben=false;
                this.checkclick(this.addjibenForm.contractId)
            },
            async beforeeditjiben(){
                if(this.editjibenForm.contractId){
                    const {data:res}=await  this.$http5.get('/v1.0/cost/basicInfo/findByContractId?contractId='+this.editjibenForm.contractId);
                    if(res.businessCode!=="3000"){
                        return this.$message.error('获取费控基本信息失败！');
                    }else {
                        this.editjibenForm=res.data.cost
                    }
                    this.dialogVisiblejibenEdit=true
                }else {
                    this.$message.error('请先点击合同列表！')
                }
            },
            async editjiben(){
                const {data:res}=await  this.$http5.put('/v1.0/cost/basicInfo/update',this.editjibenForm);
                if(res.businessCode!='6010'){
                    return this.$message.error('修改费控基本信息失败！'+res.message)
                }
                const {data:res1}=await  this.$http5.get('/v1.0/cost/basicInfo/findByContractId?contractId='+this.editjibenForm.contractId);
                if(res1.businessCode!=="3000") {
                    return this.$message.error('获取费控基本信息失败！');
                }else {
                    this.jibenform.budgetAmount=res1.data.cost.budgetAmount
                    this.costId=res1.data.cost.id
                }
                this.$message.success('修改费控基本信息成功！');
                this.dialogVisiblejibenEdit=false;
            },
            beforeaddshoukuan(){
                if(this.addjibenForm.contractId && this.costId){
                    this.dialogVisibleshoukuan=true
                }else {
                    this.$message.error('请先点击合同列表或者该合同没有费控基本信息！')
                }
            },
            beforeaddzhifu(){
                if(this.addjibenForm.contractId && this.costId){
                    this.dialogVisiblezhifu=true
                }else {
                    this.$message.error('请先点击合同列表或者该合同没有费控基本信息！')
                }
            },
            async addshoukuan(){
                this.proshow=true
                var formData=new FormData();
                for(let i in this.fileListShoukuan){
                    formData.append("multipartFiles",this.fileListShoukuan[i].raw);
                }
                this.addshoukuanform.costId=this.costId;
                for (let key in this.addshoukuanform) {
                    if(key!='files') {
                        formData.append(key, this.addshoukuanform[key]);
                    }
                }
                const {data:res}=await  this.$http5.post('/v1.0/cost/receipt/save',formData);
                if(res.businessCode!='6000'){
                    return this.$message.error('添加费控收款信息失败！'+res.message)
                }
                this.$message.success('添加费控收款信息成功！');
                this.getShoukuanList(this.costId)
                this.proshow=false
                this.dialogVisibleshoukuan=false;
            },
            async addzhifu(){
                this.proshow=true
                var formData=new FormData();
                for(let i in this.fileListZhifu){
                    formData.append("multipartFiles",this.fileListZhifu[i].raw);
                }
                this.addzhifuform.costId=this.costId;
                for (let key in this.addzhifuform) {
                    if(key!='files') {
                        formData.append(key, this.addzhifuform[key]);
                    }
                }
                const {data:res}=await  this.$http5.post('/v1.0/cost/payment/save',formData);
                if(res.businessCode!='6000'){
                    return this.$message.error('添加费控支付信息失败！'+res.message)
                }
                this.$message.success('添加费控支付信息成功！');
                this.getZhifuList(this.costId)
                this.proshow=false
                this.dialogVisiblezhifu=false;
            },
            async beforeeditShoukuan(id){
                const {data:res}=await  this.$http5.get('/v1.0/cost/receipt/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return this.$message.error('获取收款信息失败！');
                }
                this.fileListShoukuanEdit=[];
                for(let i in res.data.files){
                    this.fileListShoukuanEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8807/'+res.data.files[i].filePath})
                }
                this.editshoukuanform=res.data
                this.fileLisShoukuantEditAdd=[]
                this.fileListShoukuanEditDeleteID=[]
                this.dialogVisibleshoukuanEdit=true
            },
            async beforeeditZhifu(id){
                const {data:res}=await  this.$http5.get('/v1.0/cost/payment/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return this.$message.error('获取支付信息失败！');
                }
                this.fileListZhifuEdit=[];
                for(let i in res.data.files){
                    this.fileListZhifuEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8807/'+res.data.files[i].filePath})
                }
                this.editzhifuform=res.data
                this.fileListZhifuEditAdd=[]
                this.fileListZhifuEditDeleteID=[]
                this.dialogVisiblezhifuEdit=true
            },
            async beforeeditShouruShiji(id){
                const {data:res}=await  this.$http5.get('/v1.0/cost/receipt/actual/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return this.$message.error('获取实际收款信息失败！');
                }
                this.shijishoukuanFormEdit=res.data
                this.fileListShoukuanShijiEdit=[];
                for(let i in res.data.files){
                    this.fileListShoukuanShijiEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8807/'+res.data.files[i].filePath})
                }
                this.fileLisShoukuantEditAddSj=[]
                this.fileListShoukuanEditDeleteIDSj=[]
                this.dialogVisibleshoukuanShijiEdit=true
            },
            async deleteMsg2(id){
                const confirmResult = await this.$confirm('此操作将永久删除该实际收款信息及其文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http5.delete('/v1.0/cost/receipt/actual/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除实际收款信息及其文件失败！'+res.message)
                }
                this.$message.success('删除实际收款信息及其文件成功！');
                this.handledetail(this.row,this.expandedRows);
                this.getJiben(this.treeId)
            },
            async beforeeditZhifuShiji(id){
                const {data:res}=await  this.$http5.get('/v1.0/cost/payment/actual/findById?id='+id);
                if(res.businessCode!=="3000"){
                    return this.$message.error('获取实际支付信息失败！');
                }
                this.shijiZhifuFormEdit=res.data
                this.fileListZhichuShijiEdit=[];
                for(let i in res.data.files){
                    this.fileListZhichuShijiEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:'http://localhost:8807/'+res.data.files[i].filePath})
                }
                this.fileLisZhichuEditAddSj=[]
                this.fileListZhichuEditDeleteIDSj=[]
                this.dialogVisibleZhifuShijiEdit=true
            },
            async deleteMsg3(id){
                const confirmResult = await this.$confirm('此操作将永久删除该实际支付信息及其文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http5.delete('/v1.0/cost/payment/actual/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除实际支付信息及其文件失败！'+res.message)
                }
                this.$message.success('删除实际支付信息及其文件成功！');
                this.handledetail2(this.row2,this.expandedRows2);
                this.getJiben(this.treeId)
            },
             addShijiShouru(id){
                  this.shijishoukuanForm.receiptCostId=id
                  this.dialogVisibleshoukuanShiji=true
            },
            addShijiZhichu(id){
                this.shijiZhifuForm.paymentCostId=id
                this.dialogVisibleZhifuShiji=true
            },
            async addshoukuanShiji(){
                this.proshow=true
                var formData=new FormData();
                for(let i in this.fileListShoukuanShiji){
                    formData.append("multipartFiles",this.fileListShoukuanShiji[i].raw);
                }
                console.log(this.shijishoukuanForm)
                for (let key in this.shijishoukuanForm) {
                        formData.append(key, this.shijishoukuanForm[key]);
                }
                const {data:res}=await  this.$http5.post('/v1.0/cost/receipt/actual/save',formData);
                if(res.businessCode!='6000'){
                    return this.$message.error('添加费控实际收款信息失败！'+res.message)
                }
                this.$message.success('添加费控实际收款信息成功！');
                this.dialogVisibleshoukuanShiji=false;
                if(this.row.id==this.shijishoukuanForm.receiptCostId){
                    this.handledetail(this.row,this.expandedRows);
                }
                this.proshow=false
                this.getJiben(this.treeId)
            },
            async addzhichuShiji(){
                this.proshow=true
                var formData=new FormData();
                for(let i in this.fileListZhichuShiji){
                    formData.append("multipartFiles",this.fileListZhichuShiji[i].raw);
                }
                for (let key in this.shijiZhifuForm) {
                    formData.append(key, this.shijiZhifuForm[key]);
                }
                const {data:res}=await  this.$http5.post('/v1.0/cost/payment/actual/save',formData);
                if(res.businessCode!='6000'){
                    return this.$message.error('添加费控实际支付信息失败！'+res.message)
                }
                this.$message.success('添加费控实际支付信息成功！');
                this.dialogVisibleZhifuShiji=false;
                if(this.row2.id==this.shijiZhifuForm.paymentCostId){
                    this.handledetail2(this.row2,this.expandedRows2);
                }
                this.proshow=false
                this.getJiben(this.treeId)
            },
            async editShoukuan(){
                this.proshow=true
                this.editshoukuanform.files=[];
                const {data:res}= await this.$http5.put('/v1.0/cost/receipt/update', this.editshoukuanform)
                if(res.businessCode!='6010'){
                    return this.$message.error('修改收款信息失败！'+res.message)
                }
                for(let i in this.fileListShoukuanEditDeleteID){
                    const {data:res2}= await this.$http5.delete('/v1.0/cost/cost/receipt/file/delete/'+this.fileListShoukuanEditDeleteID[i])
                    if(res2.businessCode!='4000'){
                        return this.$message.error('删除收款信息文件失败！'+res2.message)
                    }
                }
                if(this.fileLisShoukuantEditAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileLisShoukuantEditAdd){
                        formData2.append("multipartFiles",this.fileLisShoukuantEditAdd[j].raw);
                    }
                    formData2.append("receiptCostId",this.editshoukuanform.id)
                    const {data:res3}= await this.$http5.post('/v1.0/cost/cost/receipt/file/saveAll', formData2)
                    if(res3.businessCode!='6000'){
                        return this.$message.error('添加收款信息文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改收款信息成功！');
                this.getShoukuanList(this.costId)
                this.getJiben(this.treeId)
                this.proshow=false
                this.dialogVisibleshoukuanEdit=false;
            },
            async editshoukuanShiji(){
                this.proshow=true
                this.shijishoukuanFormEdit.files=[];
                const {data:res}= await this.$http5.put('/v1.0/cost/receipt/actual/update', this.shijishoukuanFormEdit)
                if(res.businessCode!='6010'){
                    return this.$message.error('修改实际收款信息失败！'+res.message)
                }
                this.getJiben(this.treeId)
                for(let i in this.fileListShoukuanEditDeleteIDSj){
                    const {data:res2}= await this.$http5.delete('/v1.0/cost/cost/receipt/actual/file/delete/'+this.fileListShoukuanEditDeleteIDSj[i])
                    if(res2.businessCode!='4000'){
                        return this.$message.error('删除实际收款信息文件失败！'+res2.message)
                    }
                }
                if(this.fileLisShoukuantEditAddSj.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileLisShoukuantEditAddSj){
                        formData2.append("multipartFiles",this.fileLisShoukuantEditAddSj[j].raw);
                    }
                    formData2.append("actualReceiptCostId",this.shijishoukuanFormEdit.id)
                    const {data:res3}= await this.$http5.post('/v1.0/cost/cost/receipt/actual/file/saveAll', formData2)
                    if(res3.businessCode!='6000'){
                        return this.$message.error('添加实际收款信息文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改实际收款信息成功！');
                this.handledetail(this.row,this.expandedRows);
                this.proshow=false
                this.dialogVisibleshoukuanShijiEdit=false;
            },
            async editZhifuShiji(){
                this.proshow=true
                this.shijiZhifuFormEdit.files=[];
                const {data:res}= await this.$http5.put('/v1.0/cost/payment/actual/update', this.shijiZhifuFormEdit)
                if(res.businessCode!='6010'){
                    return this.$message.error('修改实际支付信息失败！'+res.message)
                }
                this.getJiben(this.treeId)
                for(let i in this.fileListZhichuEditDeleteIDSj){
                    const {data:res2}= await this.$http5.delete('/v1.0/cost/cost/payment/actual/file/delete/'+this.fileListZhichuEditDeleteIDSj[i])
                    if(res2.businessCode!='4000'){
                        return this.$message.error('删除实际支付信息文件失败！'+res2.message)
                    }
                }
                if(this.fileLisZhichuEditAddSj.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileLisZhichuEditAddSj){
                        formData2.append("multipartFiles",this.fileLisZhichuEditAddSj[j].raw);
                    }
                    formData2.append("actualPaymentCostId",this.shijiZhifuFormEdit.id)
                    const {data:res3}= await this.$http5.post('/v1.0/cost/cost/payment/actual/file/saveAll', formData2)
                    if(res3.businessCode!='6000'){
                        return this.$message.error('添加实际支付信息文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改实际支付信息成功！');
                this.handledetail2(this.row2,this.expandedRows2);
                this.getJiben(this.treeId)
                this.dialogVisibleZhifuShijiEdit=false;
                this.proshow=false
            },
            async editZhifu(){
                this.proshow=true
                this.editzhifuform.files=[];
                const {data:res}= await this.$http5.put('/v1.0/cost/payment/update', this.editzhifuform)
                if(res.businessCode!='6010'){
                    return this.$message.error('修改支付信息失败！'+res.message)
                }
                for(let i in this.fileListZhifuEditDeleteID){
                    const {data:res2}= await this.$http5.delete('/v1.0/cost/cost/payment/file/delete/'+this.fileListZhifuEditDeleteID[i])
                    if(res2.businessCode!='4000'){
                        return this.$message.error('删除支付信息文件失败！'+res2.message)
                    }
                }
                if(this.fileListZhifuEditAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileListZhifuEditAdd){
                        formData2.append("multipartFiles",this.fileListZhifuEditAdd[j].raw);
                    }
                    formData2.append("paymentCostId",this.editzhifuform.id)
                    const {data:res3}= await this.$http5.post('/v1.0/cost/cost/payment/file/saveAll', formData2)
                    if(res3.businessCode!='6000'){
                        return this.$message.error('添加支付信息文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改支付信息成功！');
                this.getZhifuList(this.costId)
                this.dialogVisiblezhifuEdit=false;
                this.proshow=false
            },
            async deleteShoukuan(id){
                const confirmResult = await this.$confirm('此操作将永久删除该收款信息及其文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http5.delete('/v1.0/cost/receipt/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除收款信息及其文件失败！'+res.message)
                }
                this.$message.success('删除收款信息及其文件成功！');
                this.getShoukuanList(this.costId);
                this.getJiben(this.treeId)
            },
            async deleteZhifu(id){
                const confirmResult = await this.$confirm('此操作将永久删除该支付信息及其文件, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http5.delete('/v1.0/cost/payment/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除支付信息及其文件失败！'+res.message)
                }
                this.$message.success('删除支付信息及其文件成功！');
                this.getZhifuList(this.costId);
                this.getJiben(this.treeId)
            },
            async getShoukuanList(id){
                const {data:res1}=await  this.$http5.get('/v1.0/cost/receipt/findAllByCostId?costId='+id);
                if(res1.businessCode!=="3000") {
                    return this.$message.error('获取更新后收款信息失败！');
                }
                 this.shoukuanData=res1.data
            },
            async getZhifuList(id){
                const {data:res1}=await  this.$http5.get('/v1.0/cost/payment/findAllByCostId?costId='+id);
                if(res1.businessCode!=="3000") {
                    return this.$message.error('获取更新后收款信息失败！');
                }
                this.zhifuData=res1.data
            },
            async handledetail(row,expandedRows){
                this.row=row
                this.expandedRows=expandedRows
                if(expandedRows.length>0){
                    const {data:res1}=await  this.$http5.get('/v1.0/cost/receipt/actual/findAllByReceiptCostId?receiptCostId='+row.id);
                    if(res1.businessCode!='3000'){
                        return  this.tableDataShouru=[]
                    }else {
                        this.tableDataShouru=res1.data
                    }
                }else{
                    this.tableDataShouru=[]
                }
            },
            async handledetail2(row,expandedRows){
                this.row2=row
                this.expandedRows2=expandedRows
                if(expandedRows.length>0){
                    const {data:res1}=await  this.$http5.get('/v1.0/cost/payment/actual/findAllByPaymentCostId?paymentCostId='+row.id);
                    if(res1.businessCode!='3000'){
                        return  this.tableDataZhichu=[]
                    }else {
                        this.tableDataZhichu=res1.data
                    }
                }else{
                    this.tableDataZhichu=[]
                }
            },
            async getCostEcharts(){
                const {data:res}= await this.$http5.get('/v1.0/cost/statistics/queryProjectCostStatistics/'+this.$root.proId+'/'+this.httongId)
                console.log(res)
                this.yearData=res.data
                this.yearList=[]
                for(let i in res.data.payment){
                     this.yearList.push(res.data.payment[i].年度)
                }
                for(let j in res.data.receipt){
                    this.yearList.push(res.data.receipt[j].年度)
                }
                this.yearList=[...new Set(this.yearList)]
                this.isActive=this.yearList[this.yearList.length-1]
                this.getYearyuanData(res.data.payment,res.data.receipt)
                this.getYearData()
            },
            getYearyuanData(arr1,arr2){
                for(let x in arr1){
                    if(arr1[x].年度==this.isActive){
                        this.zhichuData=arr1[x]
                    }
                }
                for(let y in arr2){
                    if(arr2[y].年度==this.isActive){
                        this.shouruData=arr2[y]
                    }
                }
            },
            getYearData(){
                console.log(233)
                var cnum = ['一月', '二月', '三月', '四月', '五月', '六月', '七月', '八月', '九月', '十月', '十一月', '十二月'];
                //var arr=Object.values(this.zhichuData)
                this.zhichu=[];
                this.shouru=[]
                for(let i in cnum){
                     this.zhichu.push(this.zhichuData[cnum[i]])
                     this.shouru.push(this.shouruData[cnum[i]])
                    }
                this.echart();
            },
            changeYear(item){
               this.isActive=item
               this.getYearyuanData(this.yearData.payment,this.yearData.preceipt)
               this.getYearData()
            },
            tongjiCost(){
                this.getCostEcharts();
               this.echartsShow=true
            },
            cloaseYear(){
                this.echartsShow=false
            },

            echart(){
                console.log(2)
                var xdata=[];
                var cnum = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十', '十一', '十二'];
                for(var i=0;i<=11;i++){
                    xdata.push(cnum[i]+'月')
                }
                var myChart1 = echarts.init(document.getElementById('cost'));
                var option = {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {},
                    grid: {
                        left: '3%',
                        right: '4%',
                        bottom: '3%',
                        containLabel: true
                    },
                    xAxis: {
                        type: 'category',
                        data: xdata,
                        axisLabel: {
                            show: true,
                            textStyle: {
                                color: '#000'
                            }
                        }
                    },
                    yAxis: {
                        type: 'value',
                        axisLabel : {
                            textStyle: {
                                color: '#000'
                            }
                        }
                    },
                    series: [
                        {
                            name: '收入情况',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            itemStyle:{
                                color: 'green',
                            },
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            data: this.shouru,
                        },
                        {
                            name: '支出情况',
                            type: 'bar',
                            emphasis: {
                                focus: 'series'
                            },
                            itemStyle:{
                                color: '#F43368',
                            },
                            label: {
                                show: true,
                                position: 'inside'
                            },
                            data: this.zhichu
                        }
                    ]
                };
                myChart1.setOption(option);
            },
            handleRemoveSj(file, fileList) {
                this.fileListShoukuanShiji=fileList
            },
            filechangeSj(file,fileList){
                this.fileListShoukuanShiji=fileList
            },
            handleRemoveSjZhichu(file, fileList) {
                this.fileListZhichuShiji=fileList
            },
            filechangeSjZhichu(file,fileList){
                this.fileListZhichuShiji=fileList
            },
            handleRemove(file, fileList) {
                this.fileListShoukuan=fileList
            },
            filechange(file,fileList){
                this.fileListShoukuan=fileList
            },
            handleRemove2(file, fileList) {
                this.fileListZhifu=fileList
            },
            filechange2(file,fileList){
                this.fileListZhifu=fileList
            },
            handleRemoveEdit(file, fileList) {
                if(file.id){
                    this.fileListShoukuanEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileLisShoukuantEditAdd.length; i++) {
                        if (this.fileLisShoukuantEditAdd[i] == file) {
                            this.fileLisShoukuantEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListShoukuanEdit=fileList
            },
            handleRemoveSjEdit(file, fileList){
                if(file.id){
                    this.fileListShoukuanEditDeleteIDSj.push(file.id);
                }else {
                    for (let i = 0; i < this.fileLisShoukuantEditAddSj.length; i++) {
                        if (this.fileLisShoukuantEditAddSj[i] == file) {
                            this.fileLisShoukuantEditAddSj.splice(i,1)
                        }
                    }
                }
                this.fileListShoukuanShijiEdit=fileList
            },
            handleRemoveSjEdit2(file, fileList){
                if(file.id){
                    this.fileListZhichuEditDeleteIDSj.push(file.id);
                }else {
                    for (let i = 0; i < this.fileLisZhichuEditAddSj.length; i++) {
                        if (this.fileLisZhichuEditAddSj[i] == file) {
                            this.fileLisZhichuEditAddSj.splice(i,1)
                        }
                    }
                }
                this.fileListZhichuShijiEdit=fileList
            },
            filechangeEdit(file,fileList){
                this.fileLisShoukuantEditAdd.push(file)
                this.fileListShoukuanEdit=fileList
            },
            filechangeSjEdit(file,fileList){
                this.fileLisShoukuantEditAddSj.push(file)
                this.fileListShoukuanShijiEdit=fileList
            },
            filechangeSjEdit2(file,fileList){
                this.fileLisZhichuEditAddSj.push(file)
                this.fileListZhichuShijiEdit=fileList
            },
            handleRemoveEdit2(file, fileList) {
                if(file.id){
                    this.fileListZhifuEditDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListZhifuEditAdd.length; i++) {
                        if (this.fileListZhifuEditAdd[i] == file) {
                            this.fileListZhifuEditAdd.splice(i,1)
                        }
                    }
                }
                this.fileListZhifuEdit=fileList
            },
            filechangeEdit2(file,fileList){
                this.fileListZhifuEditAdd.push(file)
                this.fileListZhifuEdit=fileList
            },
            filterNode(value, data) {
                if (!value) return true;
                return data.name.indexOf(value) !== -1;
            },
            dialogClose(){
                this.$refs.addFormReffile.resetFields();
                this.fileListShoukuan=[]
                this.fileListZhifu=[]
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
        },
        created(){
            this.getHtTree();

        },
        mounted(){

        },
        watch: {
            filterText(val) {
                this.$refs.tree.filter(val);
            }
        },
        components:{
            costLeft
        }
    }
</script>

<style scoped lang="less">

    .tableleft{
        background-color: #fff;
        position: absolute;
        width: 272px;
        top: 80px;
        bottom: 20px;
        left: 220px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }
    .tableleft2{
        background-color: #fff;
        position: absolute;
        width: 232px;
        top: 0px;
        bottom: 10px;
        left: 5px;
        padding: 0%;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
    }
    .serverTable {
        left: 245px;
        top: 10px;
        bottom: 10px;
        border-radius: 8px;
        width: calc(97% - 220px);
    }
    .echartsCost{
        width: 960px;
        height: 640px;
        z-index: 9999;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        background-color: #fff;
        border: 1px solid #000000;
        position: absolute;
        .closeX{
            position: absolute;
            z-index: 999999;
            cursor: pointer;
            right: 2px;
            top:0px;
            font-size: 14px;
        }
        .echartsCostDiv{
            width: 960px;
            height: 640px;
        }
        .yearChoose{
           position: absolute;
            right: 10px;
            top:12px;
            font-size: 14px;
            cursor: pointer;
            .activeyear{
                color: red;
            }
        }
    }
    /deep/ .el-descriptions__header{
        margin-bottom: 0 !important;
    }
    /deep/ .el-table__body-wrapper{
        height: 170px;
        max-height: 170px;
        overflow-y: auto;
    }
    /deep/ .el-descriptions-item__label{
        font-size: 15px !important;
    }
    /deep/ .el-descriptions-item__content{
        font-size: 16px !important;
        color: black;
    }
    /deep/ .el-table__cell{
        font-size: 16px !important;
    }
    /deep/ .el-table__expanded-cell{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
</style>
