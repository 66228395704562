<template>
    <div class="bg">
        <x-daohang></x-daohang>
        <div class="mainpart">
            <el-card class="box-card" >
                <div slot="header" class="clearfix">
                    <el-button  icon="el-icon-circle-plus-outline" @click="addPro"   v-show="protype">添加项目</el-button>
                    <el-button  icon="el-icon-circle-plus-outline" @click="addProtype"   v-show="!protype">添加项目类型</el-button>
                    <el-button  icon="el-icon-s-fold" @click="protype=!protype" v-show="protype">切换到项目类型</el-button>
                    <el-button  icon="el-icon-download" @click="impotPro" v-show="protype">导入项目</el-button>
                    <el-button  icon="el-icon-upload2" @click="expotPro" v-show="protype">导出项目模板</el-button>
                    <span style="" class="fanhui" type="text" @click="goback">返回</span>
                </div>
                <div  class="text item alltable">
                    <el-table
                            :data="tableData"
                            style="width: 100%"
                            v-show="protype"
                            max-height="770"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="projectName"
                                label="项目名称"
                                width="170">
                        </el-table-column>
                        <el-table-column
                                prop="projectType.typeName"
                                label="项目类型"
                                sortable
                                width="100">
                        </el-table-column>
                        <el-table-column
                                prop="totalInvestment"
                                label="项目总投资"
                                sortable
                                width="120">
                            <template v-slot="scope">
                                {{scope.row.totalInvestment}}亿元
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="principal"
                                label="项目负责人"
                                sortable
                                width="115">
                        </el-table-column>
                        <el-table-column
                                prop="principalTel"
                                label="负责人电话"
                                sortable
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="ownerUnit"
                                label="业主单位"
                                sortable
                                width="140">
                        </el-table-column>
                        <el-table-column
                                prop="projectDetails"
                                label="项目详情"
                                sortable
                                :show-overflow-tooltip="true"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="projectStatus"
                                label="项目现状"
                                sortable
                                :show-overflow-tooltip="true"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                label="建筑高度"
                                sortable
                                width="100">
                            <template v-slot="scope">
                                {{scope.row.buildingHeight}}米
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="建筑面积"
                                sortable
                                width="100">
                            <template v-slot="scope">
                                {{scope.row.buildingArea}}平方米
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="projectAddress"
                                label="项目地址"
                                width="180">
                        </el-table-column>
                        <el-table-column
                                prop="longitude"
                                label="经度"
                                width="55">
                        </el-table-column>
                        <el-table-column
                                prop="latitude"
                                label="纬度"
                                width="55">
                        </el-table-column>
                        <el-table-column
                                prop="planStartDate"
                                label="计划开始时间"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="planEndDate"
                                label="计划结束时间"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="actualStartDate"
                                label="实际开始时间"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="actualEndDate"
                                label="实际结束时间"
                                width="120">
                        </el-table-column>
                     <!--   <el-table-column
                                prop="projectImagePath"
                                label="项目照片"
                                width="120">

                        </el-table-column>-->
                        <el-table-column
                        fixed="right"
                                label="操作"
                                width="240"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="查看项目" placement="top" :enterable="false">
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialog(scope.row.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="分配人员" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-setting" size="mini" @click="fenpei(scope.row.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="进入项目" placement="top" :enterable="false" >
                                    <el-button type="danger" icon="el-icon-s-promotion" size="mini" @click="proInfo(scope.row.id,scope.row.projectName)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="删除项目" placement="top" :enterable="false" >
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg(scope.row.id)" ></el-button>
                                </el-tooltip>
                             
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-table
                            :data="protypeData"
                            style="width: 100%"
                            v-show="!protype"
                            max-height="770"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            :default-sort = "{prop: 'date', order: 'descending'}"
                    >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="120">
                        </el-table-column>
                        <el-table-column
                                prop="code"
                                label="项目类型编码"
                                align="center"
                                width="600">
                        </el-table-column>
                        <el-table-column
                                prop="typeName"
                                label="项目类型名称"
                                align="center"
                                sortable
                                width="600">
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-tooltip effect="dark" content="修改项目类型" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-edit" size="mini" @click="editDialogType(scope.row.id)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="删除项目类型" placement="top" :enterable="false" >
                                    <el-button type="danger" icon="el-icon-delete" size="mini" @click="deleteMsg2(scope.row.id)" ></el-button>
                                </el-tooltip>
                            </template>
                        </el-table-column>
                    </el-table>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="添加项目信息"
                :visible.sync="dialogVisible"
                width="50%"
                center
                :close-on-click-modal="false"

                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="editproInfoRules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目名称" prop="projectName">
                            <el-input v-model="addForm.projectName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目类型" prop="projectTypeId">
                            <el-select v-model="addForm.projectTypeId" clearable    style="width: 100%" @change="protypeChange" popper-class="select">
                                <el-option
                                        v-for="item in protypeData"
                                        :key="item.id"
                                        :label="item.typeName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目总投资(亿)" prop="totalInvestment">
                            <el-input type="number" v-model="addForm.totalInvestment"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同签订日期" prop="signContractDate">
                            <el-date-picker
                                    v-model="addForm.signContractDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目负责人" prop="principal">
                            <el-input v-model="addForm.principal"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人电话" prop="principalTel">
                            <el-input v-model="addForm.principalTel"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="业主单位" prop="ownerUnit">
                            <el-input v-model="addForm.ownerUnit"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="总包单位" prop="epcUnit">
                            <el-input v-model="addForm.epcUnit"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目现状" prop="projectStatus">
                            <el-input   type="textarea" :rows="2" v-model="addForm.projectStatus"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目详情" prop="projectDetails">
                            <el-input  type="textarea" :rows="2"  v-model="addForm.projectDetails"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="建筑高度(米)" >
                            <el-input type="number" v-model="addForm.buildingHeight"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="建筑面积(m²)" >
                            <el-input type="number" v-model="addForm.buildingArea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目地址" prop="projectAddress">
                            <el-input v-model="addForm.projectAddress"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目经度"  prop="longitude" >
                            <div style="display: flex;justify-content: space-between;">
                                <el-input type="number" v-model="addForm.longitude" style="width: 30%"></el-input>°
                                <el-input type="number" v-model="addForm.longitudeMinute" style="width: 30%"></el-input>′
                                <el-input type="number" v-model="addForm.longitudeSecond" style="width: 30%"></el-input>″
                            </div>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目纬度" prop="latitude" >
                            <div style="display: flex;justify-content: space-between;">
                                <el-input type="number" v-model="addForm.latitude"  style="width: 30%"></el-input>°
                                <el-input type="number" v-model="addForm.latitudeMinute"  style="width: 30%"></el-input>′
                                <el-input type="number" v-model="addForm.latitudeSecond"  style="width: 30%"></el-input>″
                            </div>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="计划开始时间" prop="planStartDate">
                            <el-date-picker
                                    v-model="addForm.planStartDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计划结束时间" prop="planEndDate">
                            <el-date-picker
                                    v-model="addForm.planEndDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="实际开始时间" prop="actualStartDate">
                            <el-date-picker
                                    v-model="addForm.actualStartDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实际结束时间" prop="actualEndDate">
                            <el-date-picker
                                    v-model="addForm.actualEndDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <!-- <el-row>
                    <el-col :span="24">
                        <el-form-item label="上传项目照片">
                            <el-upload
                                    action="#"
                                    list-type="picture-card"
                                    :on-preview="handlePreview"
                                    :on-change="filechange"
                                    :on-remove="handleRemove"
                                    :auto-upload="false">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible1">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>

                </el-row> -->
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="add" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改项目信息"
                :visible.sync="dialogVisibleEdit"
                width="45%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="120px"  :rules="editproInfoRules">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目名称" prop="projectName">
                            <el-input v-model="editForm.projectName"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="项目类型" prop="projectTypeId">
                            <el-select v-model="editForm.projectTypeId" clearable    style="width: 100%" @change="protypeChange" popper-class="select">
                                <el-option
                                        v-for="item in protypeData"
                                        :key="item.id"
                                        :label="item.typeName"
                                        :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目总投资(亿)" prop="totalInvestment">
                            <el-input type="number" v-model="editForm.totalInvestment"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="合同签订日期" prop="signContractDate">
                            <el-date-picker
                                    v-model="editForm.signContractDate"
                                    type="date"
                                    value-format="yyyy-MM-dd"
                                    placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目负责人" prop="principal">
                            <el-input v-model="editForm.principal"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="负责人电话" prop="principalTel">
                            <el-input v-model="editForm.principalTel"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                <el-col :span="12">
                    <el-form-item label="业主单位" prop="ownerUnit">
                        <el-input v-model="editForm.ownerUnit"></el-input>
                    </el-form-item>
                </el-col>
                <el-col :span="12">
                    <el-form-item label="总包单位" prop="epcUnit">
                        <el-input v-model="editForm.epcUnit"></el-input>
                    </el-form-item>
                </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目现状" prop="projectStatus">
                            <el-input   type="textarea" :rows="2" v-model="editForm.projectStatus"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="项目详情" prop="projectDetails">
                            <el-input  type="textarea" :rows="2"  v-model="editForm.projectDetails"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="建筑高度(米)" >
                            <el-input v-model="editForm.buildingHeight"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="建筑面积(m²)" >
                            <el-input v-model="editForm.buildingArea"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="项目地址" prop="projectAddress">
                            <el-input v-model="editForm.projectAddress"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="项目经度"  prop="longitude" >
                                <div style="display: flex;justify-content: space-between;">
                                    <el-input type="number" v-model="editForm.longitude" style="width: 30%"></el-input>°
                                    <el-input type="number" v-model="editForm.longitudeMinute" style="width: 30%"></el-input>′
                                    <el-input type="number" v-model="editForm.longitudeSecond" style="width: 30%"></el-input>″
                                </div>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="项目纬度" prop="latitude" >
                                <div style="display: flex;justify-content: space-between;">
                                    <el-input type="number" v-model="editForm.latitude"  style="width: 30%"></el-input>°
                                    <el-input type="number" v-model="editForm.latitudeMinute"  style="width: 30%"></el-input>′
                                    <el-input type="number" v-model="editForm.latitudeSecond"  style="width: 30%"></el-input>″
                                </div>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="计划开始时间" prop="planStartDate">
                            <el-input v-model="editForm.planStartDate"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="计划结束时间" prop="planEndDate">
                            <el-input v-model="editForm.planEndDate"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="实际开始时间" prop="actualStartDate">
                            <el-input v-model="editForm.actualStartDate"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="实际结束时间" prop="actualEndDate">
                            <el-input v-model="editForm.actualEndDate"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                  <!--  <el-col :span="24">
                        <el-form-item label="项目照片">
                                <el-image
                                        v-for="item in editForm.projectImagePath"
                                        style="width: 100px; height: 100px"
                                        :src=item
                                        :preview-src-list="editForm.projectImagePath">
                                </el-image>
                        </el-form-item>
                    </el-col>-->
                    <el-col :span="24">
                        <el-form-item label="上传项目照片">
                            <el-upload
                                    action="#"
                                    list-type="picture-card"
                                    :on-preview="handlePreviewEdit"
                                    :on-change="filechangeEdit"
                                    :on-remove="handleRemoveEdit"
                                    :file-list="fileListEdit"
                                    :auto-upload="false">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible1">
                                <img width="100%" :src="dialogImageUrl" alt="">
                            </el-dialog>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                 <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
                 <el-button round type="goon" size="medium"  @click="editProinfo" >确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
                title="添加项目类型"
                :visible.sync="dialogVisibletype"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormTypeRef" :model="addFormtype" label-width="110px" :rules="addproTypeRules">
                        <el-form-item label="项目类型编码" prop="code">
                            <el-input v-model="addFormtype.code"></el-input>
                        </el-form-item>
                <el-form-item label="项目类型名称" prop="typeName">
                    <el-input v-model="addFormtype.typeName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibletype = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addtype" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改项目类型"
                :visible.sync="editdialogVisibleType"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogCloseType"
        >
            <el-form ref="editFormRefType" :model="editFormType" label-width="120px" :rules="editFormTypeRules">
                <el-form-item label="项目类型编码" prop="code">
                    <el-input v-model="editFormType.code" ></el-input>
                </el-form-item>
                <el-form-item label="项目类型名称" prop="typeName">
                    <el-input v-model="editFormType.typeName"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditProType">确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="上传项目文件"
                :visible.sync="dialogVisibleFile"
                width="25%"
                center
                @close="editdialogCloseType"
        >
        <el-upload
  class="upload-demo"
  ref="upload"
  action="#"
  :limit="1"
  :on-change="changfile"
  :file-list="fileList"
  :auto-upload="false">
  <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
</el-upload>
            <span slot="footer" class="dialog-footer">
          <el-button round type="goon" size="medium" @click="dialogVisibleFile = false">取 消</el-button>
          <el-button round type="goon" size="medium" @click="shangchuan">上 传</el-button>
  </span>
        </el-dialog>
        <el-dialog
            title="分配项目组人员"
            :visible.sync="fenpeidia"
            width="40%"
            :before-close="handleClose">
         <el-transfer
            filterable
            :filter-method="filterMethod"
            :titles="['待选人员', '已选人员']"
            filter-placeholder="请输入人名"
            v-model="fenpeivalue"
            :data="userdata">
        </el-transfer>
            <span slot="footer" class="dialog-footer">
                <el-button @click="fenpeidia = false">取 消</el-button>
                <el-button type="primary" @click="isfenpei">确 定</el-button>
            </span>
        </el-dialog>

    </div>
</template>

<script>
    export default {
        name: "proMangage",
        data(){
            return{
                tableData:[
                    {id:'TM-89757',name:'小龙',date:'2021-07-15',bumen:'研发中心',start:'2021-07-15 01:32',end:'2021-07-15 01:34',status:'早退',yingqin:'8.0h',shiqin:'0.1h',remake:'测试'}
                ],
                protypeData:[],
                fileList:[],
                dialogVisibleFile:false,
                dialogVisible:false,
                dialogVisibleEdit:false,
                dialogVisibletype:false,
                editdialogVisibleType:false,
                addForm:{
                    projectName:'',
                    signContractDate:'',
                    projectTypeId:'',
                    totalInvestment:'',
                    principal:'',
                    principalTel:'',
                    projectDetails:'',
                    projectStatus:'',
                    buildingHeight:'',
                    buildingArea:'',
                    projectAddress:'',
                    longitude:'',
                    longitudeMinute:'',
                    longitudeSecond:'',
                    latitudeMinute:'',
                    latitudeSecond:'',
                    latitude:'',
                    projectImagePath:'',
                    planStartDate:'',
                    planEndDate:'',
                    actualStartDate:'',
                    actualEndDate:'',
                    ownerUnit:'',
                    epcUnit:"",
                },
                editForm:{

                },
                addFormtype:{
                    code:'',
                    typeName:''
                },
                editFormType:{},
                editFormTypeRules:{
                    code:[{required:true,message:'请输入项目类型编码',trigger:'blur'}],
                    typeName:[{required:true,message:'请输入项目类型名称',trigger:'blur'}],
                },
                protype:true,
                dialogImageUrl: '',
                dialogVisible1: false,
                filelist:[],
                fileListEdit: [],
                editproInfoRules:{
                    projectName:[{required:true,message:'请输入项目名称',trigger:'blur'}],
                    projectTypeId:[{required:true,message:'请选择项目类型',trigger:'blur'}],
                    totalInvestment:[{required:true,message:'项目总投资只能为数字',trigger:'blur'}],
                    signContractDate:[{required:true,message:'请输入合同签订日期',trigger:'blur'}],
                    principal:[{required:true,message:'请输入项目负责人',trigger:'blur'}],
                    principalTel:[{required:true,message:'请输入项目负责人电话',trigger:'blur'}],
                    ownerUnit:[{required:true,message:'请输入业主单位',trigger:'blur'}],
                   // epcUnit:[{required:true,message:'请输入总包单位',trigger:'blur'}],
                    projectStatus:[{required:true,message:'请输入项目现状',trigger:'blur'}],
                    projectDetails:[{required:true,message:'请输入项目详情',trigger:'blur'}],
                    projectAddress:[{required:true,message:'请输入项目地址',trigger:'blur'}],
                    longitude:[{required:true,message:'请输入项目经度',trigger:'blur'}],
                    longitudeMinute:[{required:true,message:'请输入项目经度的分',trigger:'blur'}],
                    longitudeSecond:[{required:true,message:'请输入项目经度的秒',trigger:'blur'}],
                    latitude:[{required:true,message:'请输入项目纬度',trigger:'blur'}],
                    latitudeMinute:[{required:true,message:'请输入项目经度的分',trigger:'blur'}],
                    latitudeSecond:[{required:true,message:'请输入项目经度的秒',trigger:'blur'}],
                    planStartDate:[{required:true,message:'请输入计划开始时间',trigger:'blur'}],
                    planEndDate:[{required:true,message:'请输入计划结束时间',trigger:'blur'}],
                    actualStartDate:[{required:true,message:'请输入实际开始时间',trigger:'blur'}],
                },
                addproTypeRules:{
                    code:[{required:true,message:'请输入项目类型编码',trigger:'blur'}],
                    typeName:[{required:true,message:'请选择项目类型名称',trigger:'blur'}],
                },
                fenpeidia:false,
                fenpeiproid:'',
                fenpeivalue:[],
                userdata:[],
                pageFrom:{
                    pageNum: 1,
                    pageSize: 99999,
                    username: "",
                    realName: "",
                    phone: "",
                    orgIdList: [],
                    roleGroupId: "",
                    department: "",
                },
                filterMethod(query, item) {
                return item.label.indexOf(query) > -1;
                }
          }
        },
        created(){
          this.getProList()
          this.getProtype()
          this.getUsers()
        },
        methods:{
            async getUsers(){
                const { data: res } = await this.$http0.post(
                    "/v1/account/user/query/findPageList",
                    this.pageFrom
                );
                if (res.businessCode !== 3000) {
                    return 
                }
                this.userdata=[]
                for(let i in res.data.list){
                    this.userdata.push({
                        label:res.data.list[i].realName,
                        key: res.data.list[i].userId,
                        name:res.data.list[i].realName,
                    })
                }
            },
            async fenpei(id){
                const { data: res} = await this.$http0.get("/v1/account/user/query/findUserProjectByProjectId?projectId="+id);
                this.fenpeivalue=[]
                if(res.businessCode==3000){
                    for(let i in res.data){
                        this.fenpeivalue.push(res.data[i].userId)
                    }
                }
                this.fenpeidia=true
                this.fenpeiproid=id
            },
            async isfenpei(){
                 console.log(this.fenpeivalue)
                 let userlist=[]
                 for(let i in this.fenpeivalue){
                    userlist.push({
                        projectId:this.fenpeiproid,
                        userId:this.fenpeivalue[i]
                    })
                 }
                 if(userlist.length==0){
                    userlist.push({
                        projectId:this.fenpeiproid,
                        userId:''
                    })
                 }
                 const { data: res } = await this.$http0.put(
                    "/v1/account/user/update/updateUserProject",{
                        list:userlist
                    }
                );
                if (res.businessCode == 4300) {
                    this.$message.success('添加成功！');
                    this.fenpeidia=false
                }else{
                     this.$message.error('添加失败！'+res.message)
                }
            },
            addPro(){
                this.dialogVisible=true
            },
            addProtype(){
                this.dialogVisibletype=true
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            editdialogCloseType(){
                this.$refs.editFormRefType.resetFields()
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            changfile(file,fileList){
                console.log(file)
                this.fileList=[]
                this.fileList.push(file)
            },
            impotPro(){
               this.fileList=[]
               this.dialogVisibleFile=true
            },
            async shangchuan(){
                var formData=new FormData();
                formData.append("file",this.fileList[0].raw);
                const {data:res}= await this.$http1.post('/v1.0/project/basicInfo/batchImport', formData)
                if(res.businessCode!='6000'){
                    return this.$message.error('添加项目失败！'+res.message)
                }
                this.$message.success('添加项目成功！');
                this.getProList()
                this.dialogVisibleFile=false;
            },
            async expotPro(){
                const { data: res } = await this.$http1.post('/v1.0/project/basicInfo/exportProjectTemplate',{},{
                    responseType: 'blob'
                })
                if(res){
                var elink = document.createElement('a');
                    elink.download = '项目模板.xlsx';
                    elink.style.display = 'none';
                    var blob = new Blob([res], { type: 'data:application/vnd.ms-excel;base64; charset=utf-8' });
                    elink.href = URL.createObjectURL(blob);
                    document.body.appendChild(elink);
                    elink.click();
                    document.body.removeChild(elink);
                }
            },
            async getProList(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAll')
                if(res.businessCode!='3000'){
                    return this.$message.error('查询项目列表失败！'+res.message)
                }
                this.tableData=res.data;
            },
            async getProtype(){
                const {data:res}= await this.$http1.get('/v1.0/project/basicInfo/findAllType')
                if(res.businessCode!='3000'){
                    return this.$message.error('查询项目类型失败！'+res.message)
                }
                this.protypeData=res.data;
            },
            async add(){
                this.$refs.addFormRef.validate(async valid =>{
                    if(!valid) return

            //    var formData=new FormData();
            //     for(let x in this.filelist){
            //         formData.append("multipartFiles",this.filelist[x].raw);
            //     }
            //    formData.append("ownerUnit",this.addForm.ownerUnit);
            //    formData.append("projectName",this.addForm.projectName);
            //    formData.append("projectTypeId",this.addForm.projectTypeId);
            //    formData.append("totalInvestment",this.addForm.totalInvestment);
            //    formData.append("principal",this.addForm.principal);
            //    formData.append("principalTel",this.addForm.principalTel);
            //    formData.append("projectDetails",this.addForm.projectDetails);
            //    formData.append("projectStatus",this.addForm.projectStatus);
            //    formData.append("buildingHeight",this.addForm.buildingHeight);
            //    formData.append("buildingArea",this.addForm.buildingArea);
            //    formData.append("projectAddress",this.addForm.projectAddress);
            //    formData.append("longitude",this.addForm.longitude);
            //    formData.append("longitudeMinute",this.addForm.longitudeMinute);
            //    formData.append("longitudeSecond",this.addForm.longitudeSecond);
            //    formData.append("latitude",this.addForm.latitude);
            //    formData.append("latitudeMinute",this.addForm.latitudeMinute);
            //    formData.append("latitudeSecond",this.addForm.latitudeSecond);
            //    formData.append("projectImagePath",'aa');
            //    formData.append("planStartDate",this.addForm.planStartDate);
            //    formData.append("planEndDate",this.addForm.planEndDate);
            //    formData.append("actualStartDate",this.addForm.actualStartDate);
            //    formData.append("actualEndDate",this.addForm.actualEndDate);
            //     formData.append("signContractDate",this.addForm.signContractDate);
            //   this.addForm.files=[]
            //   console.log(this.filelist)
            //   for(let x in this.filelist){
            //     this.addForm.files.push(this.filelist[x].raw);
            //    }
               const {data:res}= await this.$http1.post('/v1.0/project/basicInfo/save', this.addForm)
                if(res.businessCode!='4000'){
                    return this.$message.error('添加项目失败！'+res.message)
                }
                this.$message.success('添加项目成功！');
                this.dialogVisible=false;
                this.getProList();
                })
            },
            async addtype(){
                const {data:res}= await this.$http1.post('/v1.0/project/type/save', this.addFormtype)
                console.log(res)
                if(res.businessCode!='4000'){
                    return this.$message.error('添加项目类型失败！'+res.message)
                }
                this.$message.success('添加项目类型成功！');
                this.dialogVisibletype=false;
                this.getProtype();
            },
            async editDialog(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/basicInfo/findById?id='+id);
                if(res.businessCode!==3000){
                    return 0;
                }
                this.editForm=res.data;
            /*    this.editForm.projectImagePath=this.editForm.projectImagePath.split(';')
                for(let i in this.editForm.projectImagePath){
                    this.editForm.projectImagePath[i]='http://localhost:8809/'+this.editForm.projectImagePath[i]
                }*/
                // this.fileListEdit=[];
                // for(let i in res.data.files){
                //     const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+res.data.files[i].filePath)
                //     this.fileListEdit.push({id:res.data.files[i].id,name:res.data.files[i].fileName,url:res2.data})
                // }
                this.fileListEdit=[];
               for(let i in this.editForm.files){
                if(this.editForm.files[i].fileType=='OTHER'){
                    this.fileListEdit.push({id:this.editForm.files[i].id,url:'http://8.130.180.224:9000/epc/'+this.editForm.files[i].filePath})
                }
               }
                this.dialogVisibleEdit=true
            },
            async editDialogType(id){
                const {data:res}=await  this.$http1.get('/v1.0/project/type/findById?id='+id);
                if(res.businessCode!==3000){
                    return 0;
                }

                this.editFormType=res.data;

                this.editdialogVisibleType=true
            },
            async editProinfo(){

                const {data:res}= await this.$http1.put('/v1.0/project/basicInfo/update', this.editForm)
                if(res.businessCode!='4300'){
                    return this.$message.error('修改项目失败！'+res.message)
                }
                console.log(this.fileListDeleteID)
                for(let i in this.fileListDeleteID){
                    const {data:res2}= await this.$http1.delete('/v1.0/project/basicInfo/file/delete/'+this.fileListDeleteID[i])
                    if(res2.businessCode!='5000'){
                        return this.$message.error('删除项目文件失败！'+res2.message)
                    }
                }
                if(this.fileListAdd.length>0){
                    var formData2=new FormData();
                    for(let j in this.fileListAdd){
                        formData2.append("multipartFiles",this.fileListAdd[j].raw);
                    }
                    formData2.append("fileType",'OTHER');
                    formData2.append("projectId",this.editForm.id)
                    const {data:res3}= await this.$http1.post('/v1.0/project/basicInfo/file/saveAll', formData2)
                    if(res3.businessCode!='1000'){
                        return this.$message.error('添加项目文件失败！'+res3.message)
                    }
                }
                this.$message.success('修改项目成功！');
                this.dialogVisibleEdit=false;
                this.getProList();
            },
            async iseditProType(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    const {data:res}=await  this.$http1.put('/v1.0/project/type/update',this.editFormType);
                    if(res.businessCode!==6002){
                        return this.$message.error('修改项目类型失败！'+res.message)
                    }
                    this.$message.success('修改项目类型成功！');
                    this.getProtype();
                    this.editdialogVisibleType = false
                })
            },
            protypeChange(e){
                this.addForm.projectTypeId=e
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=fileList
            },
            handlePreview(file) {
               this.dialogImageUrl = file.url;
                this.dialogVisible1 = true;
            },
            filechange(file,fileList){
                console.log(file,fileList)
                this.filelist=fileList
            },
            handleRemoveEdit(file, fileList) {
                console.log(file, fileList);
                if(file.id){
                    this.fileListDeleteID.push(file.id);
                }else {
                    for (let i = 0; i < this.fileListAdd.length; i++) {
                        if (this.fileListAdd[i] == file) {
                            this.fileListAdd.splice(i,1)
                        }
                    }
                }
                this.fileListEdit=fileList
            },
            handlePreviewEdit(file) {
                this.dialogImageUrl = file.url;
                this.dialogVisible1 = true;
            },
            filechangeEdit(file,filelist){
                this.fileListAdd.push(file)
                this.filelistEdit=filelist
            },
            proInfo(id,projectName){
                window.sessionStorage.setItem('proId',id)
                window.sessionStorage.setItem('proName',projectName)
                this.$root.proId=id
                //  window.sessionStorage.setItem('current',JSON.parse(window.sessionStorage.getItem("menulevel1"))[0].path)
                window.sessionStorage.setItem('current','/project')
                this.$router.push({ path: "/project" });
                //  this.$router.push({ path:  JSON.parse(window.sessionStorage.getItem("menulevel2"))[0][0].path});

            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/basicInfo/delete/'+id)
                if(res.businessCode!="5000"){
                    return this.$message.error('删除项目失败！'+res.message)
                }
                this.$message.success('删除项目成功！');
                this.getProList();
            },
            async deleteMsg2(id){
                const confirmResult = await this.$confirm('此操作将永久删除该项目, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http1.delete('/v1.0/project/type/delete/'+id)
                if(res.businessCode!="4000"){
                    return this.$message.error('删除项目类型失败！'+res.message)
                }
                this.$message.success('删除项目类型成功！');
                this.getProtype();
            },
            renderTime(date) {
                var dateee = new Date(date).toJSON();
                return new Date(new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
            },
            goback(){
                this.$router.push({ path: "/system" });
            }
        }
    }
</script>

<style scoped lang="less">
    .bg{
    }
    .mainpart{
        left: 10px;
        top: 70px;
        bottom: 10px;
        right: 10px;
        width: 1900px;

    }
    .fanhui{
        float: right; padding:  0;border: 0 !important;color: black !important;margin-top: 10px;cursor: pointer;
    }

   /deep/ .cell{
       max-height: 70px;
       overflow-y: auto !important;
   }

</style>

