<template>
    <div class="bg">
        <work-left></work-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="4.5">
                            <el-input placeholder="请输入标题查询" v-model="queryInfo.title" class="input-with-select" clearable @clear="getnotice">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getnotice" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="16">
                            <el-select size="medium" v-model="queryInfo.isShow"  clearable placeholder="请选择查询是否显示" @change="getnotice">
                                <el-option
                                        v-for="item in options"
                                        :key="item.value"
                                        :label="item.label"
                                        :value="item.value">
                                </el-option>
                            </el-select>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-search" style="margin-left: 10px" @click="sendNotice">发起公告</el-button>
                           <!-- <el-button icon="el-icon-search">编辑公告</el-button>
                            <el-button  icon="el-icon-search">删除公告</el-button>
                            <el-button  icon="el-icon-search">下载公告</el-button>-->
                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                           >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column
                                prop="title"
                                label="标题"
                                width="240"
                                :show-overflow-tooltip="true"
                        >
                        </el-table-column>
                        <el-table-column
                            prop="description"
                            label="描述"
                            width="300"
                            :show-overflow-tooltip="true"
                    >
                    </el-table-column>
                        <el-table-column
                                prop="createUserName"
                                label="创建人"
                                width="80"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="createDate"
                                label="创建时间"
                                width="160"
                        >
                        </el-table-column>

                        <el-table-column
                                prop="filePath"
                                label="附件"
                               width="250"
                        >
                            <template v-slot="scope">
                                <a style="cursor: pointer" v-if="scope.row.filePath" @click="uploadfile(scope.row.filePath)">   {{scope.row.filePath.substring(scope.row.filePath.lastIndexOf("_")+1)}}</a>
                                <span v-else>
                                    未上传附件
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="isShow"
                                align="center"
                                label="是否显示"
                                width="80"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.isShow" @click="isShow(scope.row.id,scope.row.isShow)" ><i class="el-icon-view" style="font-size: 22px"></i></span>
                                <span v-else @click="isShow(scope.row.id,scope.row.isShow)" >
                                   <i class="el-icon-noshowyincang iconfont" style="font-size: 22px"></i>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                            <!--    <el-tooltip effect="dark" content="是否显示" placement="top" :enterable="false" >
                                    <el-button v-if="scope.row.isShow" type="primary"  size="small" @click="isShow(scope.row.id,scope.row.isShow)" > <i class="el-icon-video-pause"></i></el-button>
                                    <el-button v-else type="primary" icon="el-icon-video-play" size="small" @click="isShow(scope.row.id,scope.row.isShow)" ></el-button>
                                </el-tooltip>-->
                                <el-button  type="primary" @click="editDialog(scope.row.id)" icon="el-icon-edit" size="mini">修改</el-button>
                            <!--    <el-tooltip effect="dark" content="修改公告" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-edit" size="small" @click="editDialog(scope.row.id)" ></el-button>
                                </el-tooltip>-->
                               <!-- <el-tooltip effect="dark" content="删除公告" placement="top" :enterable="false">
                                    <el-button type="danger" icon="el-icon-delete" size="small" @click="deleteMsg(scope.row.id)" ></el-button>
                                </el-tooltip>-->
                                <el-button  type="primary" @click="deleteMsg(scope.row.id)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="发起通知公告"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"

                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="80px" :rules="addRules">
                <el-form-item label="标题" prop="title">
                    <el-input v-model="addForm.title"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input   type="textarea" :rows="3" v-model="addForm.description"></el-input>
                </el-form-item>
                <el-form-item label="是否显示" prop="isShow">
                    <el-select v-model="addForm.isShow" placeholder="请选择是否显示">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="附件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :limit="1"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addnotice" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改通知公告"
                :visible.sync="editdialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="80px" :rules="addRules" >
                <el-form-item label="标题" prop="title">
                    <el-input v-model="editForm.title"></el-input>
                </el-form-item>
                <el-form-item label="描述" prop="description">
                    <el-input   type="textarea" :rows="3" v-model="editForm.description"></el-input>
                </el-form-item>
                <el-form-item label="是否显示" prop="isShow">
                    <el-select v-model="editForm.isShow" placeholder="请选择是否显示">
                        <el-option
                                v-for="item in options"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="附件">
                    <el-upload
                            class="upload-demo"
                            ref="upload"
                            action="#"
                            :limit="1"
                            :on-preview="handlePreview"
                            :on-remove="handleRemove"
                            :on-change="filechange"
                            :file-list="fileList"
                            :auto-upload="false">
                        <el-button slot="trigger" size="small" type="primary">重新上传</el-button>
                    </el-upload>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="editdialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium" @click="iseditnotice">确 定</el-button>
  </span>
        </el-dialog>
        <div class="proloader" v-show="proshow">
            <div class="loading-progress"></div>
            <div class="loading-text">上传中，请稍后...
            </div>
        </div>
    </div>
</template>

<script>
    import workLeft from './workLeft'
    export default {
        name: "notice",
        data(){
            return{
                tableData:[],
                queryInfo:{
                    unitName:'',
                    pageNumber:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    title:'',
                    isShow:"",
                },
                total:0,
                proshow:false,
                dialogVisible:false,
                editdialogVisible:false,
                addForm:{
                    title:'',
                    description:'',
                    projectId:this.$root.proId,
                    isShow:null
                },
                editForm:{},
                fileList:[],
                options:[{
                    value: 1,
                    label: '是'
                },{
                    value: 0,
                    label: '否'
                }],
                addRules:{
                    title:[{required:true,message:'请输入标题',trigger:'blur'}],
                    description:[{required:true,message:'请输入描述',trigger:'blur'}],
                }
            }
        },
        created(){
          this.getnotice()
        },
        methods:{
            async getnotice(){
             //   const {data:res}= await this.$http3.get('/v1.0/activiti/announcement/findAll?projectId=123')
                const {data:res}= await this.$http3.post('/v1.0/activiti/announcement/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询通知公告失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addnotice(){
                this.proshow=false
                var formData=new FormData();
                if(this.filelist){
                    formData.append("multipartFile",this.filelist[0].raw);
                }else {
                    formData.append("multipartFile",'');
                }
                formData.append("title",this.addForm.title);
                formData.append("description",this.addForm.description);
                formData.append("projectId",this.addForm.projectId);
                formData.append("isShow",this.addForm.isShow);

                const {data:res}= await this.$http3.post('/v1.0/activiti/announcement/save',formData)
                if(res.status!='6000'){
                    return this.$message.error('发送通知公告失败！'+res.message)
                }
                this.$message.success('发送通知公告成功！');
                this.dialogVisible=false;
                this.getnotice();
                this.proshow=true
            },
            async editDialog(id){
                const {data:res}=await  this.$http3.get('/v1.0/activiti/announcement/findById?id='+id);
                if(res.status!=="3000"){
                    return 0;
                }
                this.fileList=[];
                if(res.data.filePath){
                    this.fileList.push({id:res.data.id,name:res.data.filePath.substring(res.data.filePath.lastIndexOf("_")+1),url:'http://localhost:8805/'+res.data.filePath})
                }
               this.editForm=res.data;
                this.editdialogVisible=true
            },
            async iseditnotice(){
                this.$refs.editFormRef.validate(async valid =>{
                    if(!valid) return
                    this.proshow=false
                    var formData=new FormData();
                    if(this.filelist){
                        formData.append("multipartFile",this.filelist[0].raw);
                    }
                    for (let key in this.editForm) {
                        if(key!='files') {
                            formData.append(key, this.editForm[key]);
                        }
                    }
                    const {data:res}=await  this.$http3.put('/v1.0/activiti/announcement/update',formData);
                    if(res.status!=="6000"){
                        return this.$message.error('修改通知公告失败！'+res.message)
                    }
                    this.$message.success('修改通知公告成功！');
                    this.getnotice();
                    this.editdialogVisible = false
                    this.proshow=true
                })
            },
            async isShow(id,isShow){
                var isShowupdate;
                if(isShow==0){
                    isShowupdate=1
                }else if(isShow==1){
                    isShowupdate=0
                }
                const {data:res}=await  this.$http3.put('/v1.0/activiti/announcement/update/isShow?id='+id+'&isShow='+isShowupdate)
                if(res.status!=="6010"){
                    return this.$message.error('修改显示状态失败！'+res.message)
                }
                this.$message.success('修改显示状态成功！');
                this.getnotice();
            },
            async  uploadfile(path){
                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+path)
                window.open(res2.data)

            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该通知公告, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http3.delete('/v1.0/activiti/announcement/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除通知公告失败！'+res.message)
                }
                this.$message.success('删除通知公告成功！');
                this.getnotice();
            },
            handleRemove(file, fileList) {
                console.log(file, fileList);
                this.filelist=filelist
            },
            handlePreview(file) {
                console.log(file)
            },
            filechange(file,filelist){
                this.filelist=filelist
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getnotice()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getnotice()
            },
        },
        components:{
            workLeft
        }
    }
</script>

<style scoped lang="less">


</style>
