<template>
    <div class="bg">
        <security-left></security-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-row :gutter="20">
                        <el-col :span="5">
                            <el-input placeholder="请输入年度计划培训名查询" v-model="queryInfo.annual" class="input-with-select" clearable @clear="getFiles">
                                <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getFiles" ></el-button>
                            </el-input>
                        </el-col>
                        <el-col :span="15">
                            <span></span>
                        </el-col>
                        <el-col :span="1">
                            <el-button  icon="el-icon-upload" style="margin-left: 10px" @click="sendNotice">添加年度培训计划</el-button>
                        </el-col>
                    </el-row>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                            @expand-change="handledetail"
                    >

                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                        </el-table-column>
                        <el-table-column type="expand">
                            <template slot-scope="props">
                                <el-table
                                        :data="tableData2[props.row.trainingPlanId]"
                                        border
                                        class="monthTable"
                                        :key="keybolen"
                                        :header-cell-style="{color:'black'}"
                                >
                                    <el-table-column
                                            type="index"
                                            label="序号"
                                            width="50">
                                    </el-table-column>
                                    <el-table-column
                                            prop="planName"
                                            label="月度计划名称"
                                            width="300"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="planTime"
                                            label="计划时间"
                                            width="260"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            prop="userId"
                                            label="培训负责人"
                                            width="260"
                                    >
                                    </el-table-column>
                                    <el-table-column
                                            label="操作"
                                            align="center"
                                    >
                                        <template v-slot="scope">
                                            <el-button  type="primary" @click="deleteMsg2(scope.row.trainingPlanTableId)" icon="el-icon-delete" size="mini">删除</el-button>
                                        </template>
                                    </el-table-column>
                                </el-table>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="annual"
                                label="培训计划名称"
                                width="400"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="yearAnnual"
                                label="培训计划年度"
                                sortable
                                width="200"
                        >
                            <template v-slot="scope">
                                <span>{{Number(scope.row.yearAnnual.substring(0,scope.row.yearAnnual.indexOf('-')))+1}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="createTime"
                                label="创建时间"
                                width="200"
                        >
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                                <el-button  type="primary" @click="addMoth(scope.row.trainingPlanId)" icon="el-icon-document-add" size="mini">新增月度培训计划</el-button>
                                <el-button  type="primary" @click="beforeEdit(scope.row.trainingPlanId)" icon="el-icon-edit" size="mini">更新</el-button>
                                <el-button  type="primary" @click="deleteMsg(scope.row.trainingPlanId)" icon="el-icon-delete" size="mini">删除</el-button>
                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>
        <el-dialog
                title="新增年度培训计划"
                :visible.sync="dialogVisible"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm" label-width="120px" :rules="addRules">
                <el-form-item label="年度计划名称" prop="annual">
                    <el-input v-model="addForm.annual"></el-input>
                </el-form-item>
                <el-form-item label="计划年度" prop="yearAnnual">
                    <el-date-picker
                            v-model="addForm.yearAnnual"
                            type="year"
                            placeholder="选择计划年度">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="修改年度培训计划"
                :visible.sync="dialogVisibleEdit"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="editForm" label-width="120px" :rules="addRules">
                <el-form-item label="年度计划名称" prop="annual">
                    <el-input v-model="editForm.annual"></el-input>
                </el-form-item>
                <el-form-item label="计划年度" prop="yearAnnual">
                    <el-date-picker
                            v-model="editForm.yearAnnual"
                            type="year"
                            placeholder="选择计划年度">
                    </el-date-picker>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisibleEdit = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="editFile" >确 定</el-button>
  </span>
        </el-dialog>
        <el-dialog
                title="新增月度计划"
                :visible.sync="dialogVisible2"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="dialogClose"
        >
            <el-form ref="addFormRef" :model="addForm2" label-width="110px" :rules="addRules">
                <el-form-item label="月度计划名称" prop="planName">
                    <el-input v-model="addForm2.planName"></el-input>
                </el-form-item>
                <el-form-item label="月度计划时间" prop="planTime">
                    <el-date-picker
                            v-model="addForm2.planTime"
                            type="date"
                            placeholder="选择日期">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="培训负责人" prop="userId">
                    <el-cascader
                            :options="yonghu"
                            :props="props"
                            v-model="addForm2.userId"
                            @change="handleChange"
                            clearable>
                    </el-cascader>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
       <el-button round type="goon" size="medium" @click="dialogVisible2 = false">取 消</el-button>
    <el-button round type="goon" size="medium"  @click="addFile2" >确 定</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import SecurityLeft from "./securityLeft";
    export default {
        name: "trainPlan",
        data(){
            return{
                tableData:[],
                tableData2:[],
                keybolen:false,
                queryInfo:{
                    annual:'',
                    pageNum:1,
                    pageSize:10,
                    projectId:this.$root.proId,
                    departmentId:"",
                },
                total:0,
                dialogVisible:false,
                dialogVisible2:false,
                dialogVisibleEdit:false,
                addForm:{
                    annual:"",
                    yearAnnual:'',
                    projectId:this.$root.proId,
                },
                addForm2:{
                    trainingPlanId:'',
                    planName:'',
                    planTime:"",
                    userId:''
                },
                editForm:{},
                yonghu:[],
                props: {
                    children:'userList',
                    label:'name',
                    value:'id'
                },
                fileList:[],
                addRules:{

                },
                row:'',
                expandedRows:''
            }
        },
        created(){
            this.getFiles()
            this.getUsers()
        },
        methods:{
            async getFiles(){
                const {data:res}= await this.$http14.post('/v1.0/security/training/plan/findPages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询年度培训计划失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            async getUsers(){
                const {data:res}= await this.$http.get('/v1.0/user/findDepartmentUser/'+this.$root.proId)
                if(res.status!='3000'){
                    return this.$message.error(res.message)
                }
                for(let i in res.data){
                    res.data[i]['name']=res.data[i].departmentName
                    delete res.data[i].departmentName
                    this.yonghu.push(res.data[i])
                }
            },
            async handledetail(row,expandedRows){
                this.row=row
                this.expandedRows=expandedRows
                if(expandedRows.length>0){
                    const {data:res}= await this.$http14.post('/v1.0/security/training/planTable/findAllByTrainingPlanId?trainingPlanId='+row.trainingPlanId )
                    if(res.status!='3000'){
                        return  this.tableData2[row.trainingPlanId]=[]
                    }else {
                        this.tableData2[row.trainingPlanId]=res.data
                    }
                }else{
                    this.tableData2[row.trainingPlanId]=[]
                }
                this.keybolen=!this.keybolen
            },
            handleChange(value){
                this.addForm2.userId=value[value.length-1]
            },
            sendNotice(){
                this.fileList=[];
                this.dialogVisible=true
            },
            async addFile(){
                const {data:res}= await this.$http14.post('/v1.0/security/training/plan/save',this.addForm)
                if(res.status!='6000'){
                    return this.$message.error('新增年度培训计划失败！'+res.message)
                }
                this.$message.success('新增年度培训计划成功！');
                this.dialogVisible=false;
                this.getFiles();
            },
            async addFile2(){
                const {data:res}= await this.$http14.post('/v1.0/security/training/planTable/save',this.addForm2)
                if(res.status!='6000'){
                    return this.$message.error('新增月度培训计划失败！'+res.message)
                }
                this.$message.success('新增月度培训计划成功！');
                this.dialogVisible2=false;
                if(this.row.meetingPlanId==this.addForm2.meetingPlanId){
                    this.handledetail(this.row,this.expandedRows);
                }

            },
            addMoth(id){
                this.addForm2.trainingPlanId=id;
                this.dialogVisible2=true
            },
            async beforeEdit(id){
                const {data:res}= await this.$http14.post('/v1.0/security/training/plan/findById?id='+id)
                if(res.status!=='3000'){
                    this.$message.error('查询培训计划失败！'+res.message)
                }
                this.editForm=res.data
                this.dialogVisibleEdit=true
            },
            async editFile(){
                const {data:res}= await this.$http14.put('/v1.0/security/training/plan/update',this.editForm)
                if(res.status!=='6010'){
                    this.$message.error('更新培训计划失败！'+res.message)
                }
                this.$message.success('更新培训计划成功！')
                this.dialogVisibleEdit=false
                this.getFiles()
            },
            async deleteMsg(id){
                const confirmResult = await this.$confirm('此操作将永久删除该年度培训计划, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/training/plan/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除年度培训计划失败！'+res.message)
                }
                this.$message.success('删除年度培训计划成功！');
                this.getFiles();
            },
            async deleteMsg2(id){
                const confirmResult = await this.$confirm('此操作将永久删除该月度培训计划, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).catch((err) => {
                    return err
                });
                if(confirmResult !== 'confirm'){
                    return this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });
                }
                //请求
                const {data:res}= await this.$http14.delete('/v1.0/security/training/planTable/delete/'+id)
                if(res.status!="4000"){
                    return this.$message.error('删除月度培训计划失败！'+res.message)
                }
                this.$message.success('删除月度培训计划成功！');
                this.handledetail(this.row,this.expandedRows);
            },
            dialogClose(){
                this.$refs.addFormRef.resetFields();
            },
            handleClose(done) {
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getFiles()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getFiles()
            },
        },
        components:{
            SecurityLeft,

        }
    }
</script>

<style scoped lang="less">
    /deep/ .el-table__expanded-cell{
        padding-left: 50px;
        padding-right: 50px;
    }
    /deep/ .el-date-editor{
        width: 100% !important;
    }
    /deep/ .el-table--border,  /deep/.el-table--group{
        border: 2px solid #ccc;
    }
    .monthTable{
        max-height: 420px;
        overflow-y: auto;
    }
</style>
