<template>
  <div class="bg">
    <daohanglan></daohanglan>
    <div class="content">
      <div class="leftMeau">
        <promeau></promeau>
      </div>
      <div class="rightCont">
        <div id="pageList">
          <div class="addActBtCategory">
            <div class="catleft">
              <span class="title-left">报表字典管理</span>
            </div>
            <div
              style="display: flex; justify-content: flex-end; z-index: 999999"
            >
              <el-button type="primary" @click="addWord"
                ><i class="el-icon-remove"></i><span>新增报表字典</span>
              </el-button>
            </div>
          </div>
          <!-- 列表 -->
          <div>
            <el-table
              :data="tableData"
              border
              row-key="id"
              style="width: 100%; height: 100%"
              :tree-props="{children: 'childList'}"
              size="large"
              :header-cell-style="{ background: '#e3ecfb', color: 'black' }"
            >
              <template>
                <el-empty :image-size="100" description="暂无数据"></el-empty>
              </template>
              <el-table-column prop="name" label="模板名称" width="250">
              </el-table-column>
              <el-table-column
                prop="name"
                label="字典名称"
                width="350"
              />
              <el-table-column
                prop="code"
                label="字典编码"
                width="320"
              />
              <el-table-column
                prop="init_level"
                label="上级编码"
                width="300"
              />
              <el-table-column label="操作">
                <template #default="scope">
                  <el-button
                    size="small"
                    type="primary"
                    plain
                    @click="deleteForm(scope.row)"
                    >删除字典</el-button
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>
    </div>
    <el-dialog title="新增字典" :visible.sync="dialogFormVisible" width="30%" center>
            <el-form ref="addFormRef" :model="addForm" label-width="100px" :rules="addFormRule">
                <el-form-item label="字典编码" prop="code">
                    <el-input v-model="addForm.code" placeholder="请输入字典编码" ></el-input>
                </el-form-item>
                <el-form-item label="字典名称" prop="name">
                    <el-input v-model="addForm.name" placeholder="请输入字典名称" ></el-input>
                </el-form-item>
                <el-form-item label="模版编码" prop="parentId">
                  <el-cascader
                    v-model="addForm.parentId"
                    :options="treeData"
                    :props="defaultProps"
                    @change="handleChange"
                    clearable></el-cascader>
                </el-form-item>
                <el-form-item label="排序" prop="sort">
                  <el-input-number v-model="addForm.sort"  :min="0" :max="1000" label="排序"></el-input-number>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="faqiFrom">确 定</el-button>
            </span>
    </el-dialog>
  </div>
</template>

<script>
import promeau from "../proMeau.vue";
import daohanglan from "../../../daohanglan.vue";
export default {
  name: "formHome",
  data() {
    return {
      tableData: [],
      total: 0,
      dialogFormVisible: false,
      addForm:{
          code: "",
          name: "",
          sort:0,
          parentId:"",
      },
      props: {
          label: "categoryName",
          value: "categoryId",
          checkStrictly: true,
      },
      fileList:[],
      addFormRule: {
            name: [{ required: true, message: '请输入名称', trigger: 'blur' }, { min: 2, max: 40, message: "长度再2-40个字符之间", trigger: 'blur' }],
            code: [{ required: true, message: '请输入编码', trigger: 'blur' }],
            parentId: [{ required: true, message: '请选择类别', trigger: 'blur' }],
        },
      categoryOptions:[],
      menuData:[],
      defaultProps: {
        children: "childList",
        label: "name",
        value:'id',
        checkStrictly: true
      },
      treeData:[]
    };
  },
  methods: {
    async getForm() {
      const { data: res } = await this.$http9.get("/dictionary/query/DictionaryTreeListByCode?code=file_type");
        this.tableData = res.data;
        this.treeData=[{
          "id": "init_level",
          "name": "顶级节点",
          "code": "dingji",
          "parentId": "",
          "sort": 0,
          "operationInfo": null,
          childList:res.data
        }]
    },
    async deleteForm(row){
      this.$confirm('此操作将删除表单, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(async () => {
          const { data: res } = await this.$http9.delete('/dictionary/delete/DictionaryById?id='+row.id)
          if (res.businessCode == 5000) {
                  this.$message.success('删除成功！')
                  this.getForm()
              } else {
                  this.$message.error('删除失败！')
              }    
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消'
          });          
        });
    },
    addWord() {
      this.dialogFormVisible=true
    },
    handleChange(val) {
      this.addForm.parentId=val[val.length-1]
    },
    async faqiFrom(){
          const {data:res}= await this.$http9.post('/dictionary/insert/Dictionary',this.addForm)
              if (res.businessCode == 4000) {
                  this.$message.success('保存成功！')
                  this.dialogFormVisible = false;
              } else {
                  this.$message.error('保存失败！')
          }    
          this.getForm()
    },
    menuChange(e){
      console.log(this.addForm.menuId)
    }
  },

  components: {
    promeau,
    daohanglan
  },
  created() {
    this.getForm();
  },
};
</script>

<style scoped lang="less">
.content {
  height: calc(100vh - 50px);
  background-color: #fff;
  display: flex;
  justify-content: space-between;

  .leftMeau {
    height: 100%;
    width: 200px;
  }

  /deep/ .el-menu {
    height: 100%;
  }

  .rightCont {
    height: 100%;
    width: calc(100vw - 230px);
    height: calc(92vh);
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
  }

  .insertActBtCategory {
    width: 300px;
    margin-right: 8px;
  }

  .addActBtCategory {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;

    .catleft {
      margin-top: 10px;
    }
  }

  #pageList {
    width: 98%;
  }

  .title-left {
    font-size: 18px;
    font-weight: 700;
    padding-left: 10px;
    width: 140px;
    height: 22px;
    border-left: 5px solid #569ee3;
    text-align: left;
  }
}
</style>
