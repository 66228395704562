<template>
    <div class="bg">
        <kaoqing-left></kaoqing-left>
        <div class="mainpart">
          <!--  <div id="klheader"  v-show="!fals">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>上班打卡列表</span>
                       &lt;!&ndash; <el-button style="float: right; padding: 3px 0" type="text" @click="fals=!fals">切换单表</el-button>&ndash;&gt;
                    </div>
                    <div  class="text item">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="100%"
                                :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="id"
                                    label="工号"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="考勤人"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="date"
                                    label="考勤日期"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="bumen"
                                    label="所在部门"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="start"
                                    label="上班打卡时间"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    label="状态"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="yingqin"
                                    label="应勤"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="shiqin"
                                    label="实勤"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="remake"
                                    label="备注"
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>
            <div id="klbottom"  v-show="!fals">
                <el-card class="box-card">
                    <div slot="header" class="clearfix">
                        <span>下班打卡列表</span>
                    </div>
                    <div  class="text item">
                        <el-table
                                :data="tableData"
                                style="width: 100%"
                                height="100%"
                                :header-cell-style="{background:'#273F76',color:'#eef5ff'}"
                                :default-sort = "{prop: 'date', order: 'descending'}"
                        >
                            <el-table-column
                                    type="index"
                                    label="序号"
                                    width="50">
                            </el-table-column>
                            <el-table-column
                                    prop="id"
                                    label="工号"
                                    width="100">
                            </el-table-column>
                            <el-table-column
                                    prop="name"
                                    label="考勤人"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="date"
                                    label="考勤日期"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="bumen"
                                    label="所在部门"
                                    sortable
                                    width="180">
                            </el-table-column>

                            <el-table-column
                                    prop="end"
                                    label="下班打卡时间"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="status"
                                    label="状态"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="yingqin"
                                    label="应勤"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="shiqin"
                                    label="实勤"
                                    sortable
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    prop="remake"
                                    label="备注"
                                    width="180">
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                            >
                            </el-table-column>
                        </el-table>
                    </div>
                </el-card>
            </div>-->
                <el-card class="box-card" >
                  <!--  <div slot="header" class="clearfix">
                        <span style="color: black">打卡列表</span>
                    </div>-->
                    <div  class="text item" style="display: flex;justify-content: space-between;">
                        <div class="tableleft">
                                <el-calendar  v-model="value">
                                    <template
                                            slot="dateCell"
                                            slot-scope="{date, data}">
                                        <div style="width: 100%;height: 100%" @click="riqiclick(data)">
                                            <p :class="data.isSelected ? 'is-selected' : ''" >
                                                {{ data.day.split('-').slice(1).join('-') }} {{ data.isSelected ? '✔️' : ''}}
                                            </p>
                                        </div>

                                    </template>
                                </el-calendar>
                        </div>
                        <div class="tableright">
                            <el-table
                                    :data="tableData"
                                    style="width: 100%"
                                    height="100%"
                                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
                                    :default-sort = "{prop: 'date', order: 'descending'}"
                            >
                                <el-table-column
                                        type="index"
                                        label="序号"
                                        width="50">
                                </el-table-column>
                                <el-table-column
                                        prop="attendanceUserName"
                                        label="考勤人"
                                        sortable
                                        align="center"
                                        width="90">
                                </el-table-column>
                                <el-table-column
                                        prop="startDate"
                                        label="上班打卡时间"
                                        align="center"
                                        sortable
                                        width="160">
                                </el-table-column>
                                <el-table-column
                                        prop="endDate"
                                        label="下班打卡时间"
                                        align="center"
                                        sortable
                                        width="160">
                                </el-table-column>
                                <el-table-column
                                        prop="status"
                                        label="状态"
                                        align="center"
                                        width="100">
                                </el-table-column>
                                <el-table-column
                                        prop="remark"
                                        label="备注"
                                        align="center"
                                        width="150">
                                </el-table-column>
                                <el-table-column
                                        label="操作"
                                        align="center"
                                >
                                    <template v-slot="scope">
                                        <el-button  type="primary" @click="Dialog(scope.row.id)" icon="el-icon-view" size="mini">查看详情</el-button>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </div>
                    </div>
                </el-card>
        </div>
        <el-dialog
                title="查看打卡详情"
                :visible.sync="dialog"
                width="25%"
                center
                :close-on-click-modal="false"
                @close="editdialogClose"
        >
            <el-form ref="editFormRef" :model="editForm" label-width="120px" >
                <el-form-item label="考勤人">
                    <el-input v-model="editForm.attendanceUserName"></el-input>
                </el-form-item>
                <el-form-item label="上班打卡时间">
                    <el-input v-model="editForm.startDate"></el-input>
                </el-form-item>
                <el-form-item label="下班打卡时间">
                    <el-input v-model="editForm.endDate"></el-input>
                </el-form-item>
                <el-form-item label="上班打卡地点">
                    <el-input v-model="editForm.firstLocation"></el-input>
                </el-form-item>
                <el-form-item label="下班打卡地点">
                    <el-input v-model="editForm.secondLocation"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="editForm.remark"></el-input>
                </el-form-item>
                <el-form-item label="打卡照片">
                    <el-image
                            v-for="item in editForm.projectImagePath"
                            style="width: 100px; height: 100px"
                            :src=item
                            :preview-src-list="editForm.projectImagePath">
                    </el-image>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
     <el-button round type="goon" size="medium" @click="dialog = false">取 消</el-button>
  </span>
        </el-dialog>
    </div>
</template>

<script>
    import kaoqingLeft from './kaoqingLeft'
    import * as echarts from 'echarts';
    var pieRadius = 30;
    export default {
        name: "kaoList",
        data(){
            return{
                fals:true,
                tableData:[
                   // {id:'TM-89757',name:'小龙',date:'2021-07-15',bumen:'研发中心',start:'2021-07-15 01:32',end:'2021-07-15 01:34',status:'早退',yingqin:'8.0h',shiqin:'0.1h',remake:'测试'}
                ],
                value: new Date(),
                selectdate:new Date(),
                pickerOptions: {
                    disabledDate(time) {
                        return time.getTime() > Date.now();
                    },
                    shortcuts: [{
                        text: '今天',
                        onClick(picker) {
                            picker.$emit('pick', new Date());
                        }
                    }, {
                        text: '昨天',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit('pick', date);
                        }
                    }, {
                        text: '一周前',
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', date);
                        }
                    }]
                },
                dialog:false,
                editForm:{

                }
            }
        },
        methods:{
            async getList(){
              var datetime=this.selectdate.getFullYear()+"-"+(this.selectdate.getMonth()+1)+"-"+this.selectdate.getDate()+" 00:00:00"
              const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectAttendanceRecord?projectId='+this.$root.proId+'&day='+datetime);
              if(res.status!='3000'){
                  return this.$message.error('考勤列表查询失败！'+res.message)
              }
              this.tableData=res.data
              for(let i in this.tableData){
                  var status=[];
                  if(this.tableData[i].status.indexOf("LATE")!=-1){
                      status.push('迟到')
                  }
                  if(this.tableData[i].status.indexOf("LEAVEEARLY")!=-1){
                      status.push('早退')
                  }
                  if(this.tableData[i].status.indexOf("FORGET")!=-1){
                      status.push('缺卡')
                  }
                  if(this.tableData[i].status.indexOf("ABSENTEEISM")!=-1){
                      status.push('旷工')
                  }
                  if(this.tableData[i].status.indexOf("VACATION")!=-1){
                      status.push('休假')
                  }
                  if(this.tableData[i].status.indexOf("NORMAL")!=-1){
                      status.push('正常')
                  }
                  this.tableData[i].status=status.toString()
              }
          },
            async datechange(selectdate){
              var datetime=selectdate.getFullYear()+"-"+(selectdate.getMonth()+1)+"-"+selectdate.getDate()+" 00:00:00";
              const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectAttendanceRecord?projectId='+this.$root.proId+'&day='+datetime);
              if(res.status!='3000'){
                  return this.$message.error('考勤列表查询失败！'+res.message)
              }
              this.tableData=res.data
              for(let i in this.tableData){
                  var status=[];
                  if(this.tableData[i].status.indexOf("LATE")!=-1){
                      status.push('迟到')
                  }
                  if(this.tableData[i].status.indexOf("LEAVEEARLY")!=-1){
                      status.push('早退')
                  }
                  if(this.tableData[i].status.indexOf("FORGET")!=-1){
                      status.push('缺卡')
                  }
                  if(this.tableData[i].status.indexOf("ABSENTEEISM")!=-1){
                      status.push('旷工')
                  }
                  if(this.tableData[i].status.indexOf("VACATION")!=-1){
                      status.push('休假')
                  }
                  if(this.tableData[i].status.indexOf("NORMAL")!=-1){
                      status.push('正常')
                  }
                  this.tableData[i].status=status.toString()
              }
          },
            echart(){
                var app = {};
                var chartDom = document.getElementById('echartrili');
                var myChart = echarts.init(chartDom);
                var scatterData = this.getVirtulData();
                var cellSize = [80, 80];
                var option = {
                    tooltip : {},
                    legend: {
                        data: ['工作', '娱乐', '睡觉'],
                        bottom: 20
                    },
                    calendar: {
                        top: 'middle',
                        left: 'center',
                        orient: 'vertical',
                        cellSize: cellSize,
                        yearLabel: {
                            show: false,
                            fontSize: 30
                        },
                        dayLabel: {
                            margin: 20,
                            firstDay: 1,
                            nameMap: ['星期日', '星期一', '星期二', '星期三', '星期四', '星期五', '星期六']
                        },
                        monthLabel: {
                            show: false
                        },
                        range: ['2021-07']
                    },
                    series: [{
                        id: 'label',
                        type: 'scatter',
                        coordinateSystem: 'calendar',
                        symbolSize: 1,
                        label: {
                            show: true,
                            formatter: function (params) {
                                return echarts.format.formatTime('dd', params.value[0]);
                            },
                            offset: [-cellSize[0] / 2 + 10, -cellSize[1] / 2 + 10],
                            fontSize: 14
                        },
                        data: scatterData
                    }]
                };
                var pieInitialized;
                setTimeout( ()=> {
                    pieInitialized = true;
                    myChart.setOption({
                        series: this.getPieSeries(scatterData, myChart)
                    });
                }, 10);
                app.onresize =  ()=> {
                    if (pieInitialized) {
                        myChart.setOption({
                            series: this.getPieSeriesUpdate(scatterData, myChart)
                        });
                    }
                };
                option && myChart.setOption(option);
                myChart.on('click', function (params) {
                    // 控制台打印数据的名称
                    console.log(params)
                    var  year_month = params.event.event.path[8].startDate;
                    var day = params.seriesIndex;
                    if(day<10){
                        day = "0"+day;
                    }
                    var year_month_day = year_month+"-"+day;
                    console.log(year_month_day)
                });
            },
            getVirtulData() {
                 var date = +echarts.number.parseDate('2021-07-01');
                 var end = +echarts.number.parseDate('2021-07-31');
                 var dayTime = 3600 * 24 * 1000;
                 var data = [];
                 for (var time = date; time < end; time += dayTime) {
                 data.push([
                 echarts.format.formatTime('yyyy-MM-dd', time),
                 Math.floor(Math.random() * 10000)
                 ]);
             }
              return data;
            },
            getPieSeries(scatterData, chart) {
                return scatterData.map(function (item, index) {
                    var center = chart.convertToPixel('calendar', item);
                    return {
                        id: index + 'pie',
                        type: 'pie',
                        center: center,
                        label: {
                            normal: {
                                formatter: '{c}',
                                position: 'inside'
                            }
                        },
                        radius: pieRadius,
                        data: [
                            {name: '工作', value: Math.round(Math.random() * 24)},
                            {name: '娱乐', value: Math.round(Math.random() * 24)},
                            {name: '睡觉', value: Math.round(Math.random() * 24)}
                        ]
                    };
                });
            },
            getPieSeriesUpdate(scatterData, chart) {
                return scatterData.map(function (item, index) {
                    var center = chart.convertToPixel('calendar', item);
                    return {
                        id: index + 'pie',
                        center: center
                    };
                });
            },
            async riqiclick(data){
                var datetime=data.day+" 00:00:00";
                const {data:res}=await  this.$http2.get('/v1.0/attendance/statistics/queryProjectAttendanceRecord?projectId='+this.$root.proId+'&day='+datetime);
                if(res.status!='3000'){
                    return this.$message.error('考勤列表查询失败！'+res.message)
                }
                this.tableData=res.data
                for(let i in this.tableData){
                    var status=[];
                    if(this.tableData[i].status.indexOf("LATE")!=-1){
                        status.push('迟到')
                    }
                    if(this.tableData[i].status.indexOf("LEAVEEARLY")!=-1){
                        status.push('早退')
                    }
                    if(this.tableData[i].status.indexOf("FORGET")!=-1){
                        status.push('缺卡')
                    }
                    if(this.tableData[i].status.indexOf("ABSENTEEISM")!=-1){
                        status.push('旷工')
                    }
                    if(this.tableData[i].status.indexOf("VACATION")!=-1){
                        status.push('休假')
                    }
                    if(this.tableData[i].status.indexOf("NORMAL")!=-1){
                        status.push('正常')
                    }
                    this.tableData[i].status=status.toString()
                }
            },
            async Dialog(id){
                const {data:res}=await  this.$http2.get('/v1.0/attendance/record/findById?id='+id);
                if(res.status=='3000'){
                    this.editForm=res.data;
                    if(res.data.fileGroupId==''){
                        this.editForm.projectImagePath=[]
                    }else {
                        const {data:res1}=await  this.$http2.get('/v1.0/attendance/evidence/findAllByGroupId/'+res.data.fileGroupId);
                        if(res1.status=='3000'){
                            this.editForm.projectImagePath=[];

                            for(let i in res1.data){
                                console.log(res1.data[i].file_path)
                                const {data:res2}= await this.$http8.get('/v1.0/ossFile/getFileUrl?filePath='+res1.data[i].file_path)
                                this.editForm.projectImagePath.push(res2.data)
                            }
                         //   this.editForm.projectImagePath.push("http://localhost:8809/"+res1.data.file_path)
                        }
                    }
                    this.dialog=true
                }else {
                    this.$message.error('查询详情失败'+res.message)
                }
            },
            editdialogClose(){
                this.$refs.editFormRef.resetFields()
            },
            handleClose(){
                this.$confirm('确认关闭？')
                    .then(_ => {
                        done();
                    })
                    .catch(_ => {});
            }
        },
        created(){
          this.getList();
            this.$nextTick(() => {                      //给点击日历绑定绑定监听事件,效果：只有点击日期才去请求新价格并关闭弹窗，点击下个月，上个月等等不变化
                var prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(1)')		//给tbody添加事件，也就是日期那部分
                prevBtn.addEventListener('click',(e) => {
                    this.datechange(this.value)
                })
            let nextBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(3)"
            );
            nextBtn.addEventListener("click", () => {
                this.datechange(this.value)
            });
            //点击今天
            let todayBtn = document.querySelector(
                ".el-calendar__button-group .el-button-group>button:nth-child(2)"
            );
            todayBtn.addEventListener("click", () => {
                this.datechange(this.value)
            });
        });
        },
        mounted(){
           // this.echart();
        },
        components:{
            kaoqingLeft
        }
    }
</script>

<style scoped lang="less">

   /* #kaoList{
        background-color: #111224;
        position: absolute;
        left: 290px;
        top: 70px;
        bottom: 72px;
        right: 10px;
        width: 1620px;
        border-radius: 8px;
        box-shadow: 0 0 8px #acc4fe;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }
*/
    /*#klheader{
        width: 99%;
        height: 48%;
        padding-top: 5px;
        border-bottom: 1px solid #273F76   ;
        padding-left: 15px;
    }
    #klbottom{
        width: 99%;
        height: 50%;
        padding-top: 5px;
        border-bottom: 1px solid #273F76   ;
        padding-left: 15px;
     }*/
    .tableleft{
        width: 500px;
        height: 100%;
    }
    .tableright{
        width: 850px;
        height: 770px
    }

    /deep/ .el-date-editor{
        margin-left: 10px;
        margin-top: -5px;
    }
    /deep/ .el-input__icon{
        line-height: 30px;
    }


    /deep/ .el-calendar-table{
        width: 100%;
        height: 100%;
        &:not(.is-range){
            //使不是本月的日期不可点击，不会跳转到其他月份
            td.next{
                pointer-events: none;
            }
            td.prev{
                pointer-events: none;
            }
        }
    }
        .yingdao{
        float: left;
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #268DF6;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }

    }
    .shidao{
        width: 120px;
        height: 65px;
        float: left;
        border-radius: 5px;
        background-color: #909399;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .queqin{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #DE494D;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .chidao{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .zaotui{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #ff9934;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .zhengc{
        width: 120px;
        height: 65px;
        border-radius: 5px;
        background-color: #67c23a;
        text-align: center;
        padding-top: 22px;
        p{
            font-size: 18px;
        }
    }
    .tongji{
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 105px;
    }

</style>
