<template>
    <div class="bg">
        <work-left></work-left>
        <div class="mainpart">
            <el-card class="box-card"  style="height: 100%;">
                <div slot="header" class="clearfix" >
                    <el-input placeholder="请输入发起人查询" v-model="queryInfo.initiatingName" class="input-with-select" clearable @clear="getjoinlcList">
                        <el-button  type="goon"  slot="append" icon="el-icon-search" @click="getjoinlcList" ></el-button>
                    </el-input>

                    <el-select v-model="queryInfo.status"  class="input-with-select1"  clearable placeholder="请选择流程状态" @change="changeStatus">
                        <el-option
                                v-for="item in statusoptions"
                                :key="item.value"
                                :label="item.label"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <div class="serverTable">
                    <el-table
                            :data="tableData"
                            border
                            height="100%"
                            :header-cell-style="{background:'#e3ecfb',color:'black'}"
                           >
                        <el-table-column
                                type="index"
                                label="序号"
                                width="50">
                            <template v-slot="scope">
                                {{ (queryInfo.pageNumber-1) * queryInfo.pageSize+ scope.$index + 1}}
                            </template>
                        </el-table-column>
                        <el-table-column
                                prop="processName"
                                label="流程名称"
                                width="350"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="initiatingName"
                                label="办理人"
                                width="250"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="initiatingDate"
                                label="创建时间"
                                width="250"
                        >
                        </el-table-column>
                        <el-table-column
                                prop="status"
                                label="状态"
                                align="center"
                                width="120"
                        >
                            <template v-slot="scope">
                                <span v-if="scope.row.status">
                                   <el-tag type="success">已完成</el-tag>
                                </span>
                                <span v-else>
                                    <el-tag type="danger">未完成</el-tag>
                                </span>
                            </template>
                        </el-table-column>
                        <el-table-column
                                label="操作"
                                align="center"
                        >
                            <template v-slot="scope">
                               <!-- <el-tooltip effect="dark" content="查看表单" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-view" size="mini" @click="chakanForm(scope.row)" ></el-button>
                                </el-tooltip>
                                <el-tooltip effect="dark" content="查看流程" placement="top" :enterable="false" >
                                    <el-button type="primary" icon="el-icon-view" size="mini" @click="chakan(scope.row)" ></el-button>
                                </el-tooltip>-->
                                <el-button  type="primary" @click="chakanForm(scope.row)" icon="el-icon-view" size="mini">查看表单</el-button>
                                <el-button  type="primary" @click="chakan(scope.row)" icon="el-icon-view" size="mini">查看流程</el-button>

                            </template>
                        </el-table-column>
                    </el-table>
                    <el-pagination
                            @size-change="handleSizeChange"
                            @current-change="handleCurrentChange"
                            :current-page="queryInfo.pageNumber"
                            :page-sizes="[5,10, 20, 50]"
                            :page-size="queryInfo.pageSize"
                            layout="total, sizes, prev, pager, next, jumper"
                            :total="total">
                    </el-pagination>
                </div>
            </el-card>
        </div>

        <el-dialog
                title="流程图查看"
                :visible.sync="dialogVisible"
                :close-on-click-modal="false"
                center
        >
            <el-table
                    :data="liuchengData"
                    border
                    height="240px"
                    :header-cell-style="{background:'#e3ecfb',color:'black'}"
                  >
                <el-table-column
                        type="index"
                        label="序号"
                        width="50">
                </el-table-column>
                <el-table-column
                        prop="assigneeName"
                        label="办理人"
                        width="100"
                >
                </el-table-column>
                <el-table-column
                        prop="startDate"
                        label="开始办理时间"
                        width="240"
                >
                </el-table-column>
                <el-table-column
                        prop="endDate"
                        label="结束办理时间"
                        width="240"
                >
                </el-table-column>
                <el-table-column
                        prop="comments"
                        label="办理意见"
                >
                </el-table-column>
            </el-table>
            <div slot="footer" class="dialog-footer">
                <div id="sbgimg" >
                </div>
            </div>
        </el-dialog>
        <el-dialog
                title="业务表单查看"
                :visible.sync="formDialogVisible"
                :close-on-click-modal="false"
                center
        >
            <form-create v-model="fApi" :rule="rule" :option="option" :value.sync="value"></form-create>
        </el-dialog>
    </div>
</template>

<script>
    import workLeft from './workLeft'
    export default {
        name: "mylc",
        data(){
            return{
                queryInfo:{
                    processName:'',
                    initiatingName:'',
                    projectId:this.$root.proId,
                    pageNumber:1,
                    pageSize:10,
                    status:null
                },
                total:0,
                tableData:[],
                dialogVisible:false,
                show:true,
                formDialogVisible:false,
                fApi: {},
                //表单数据
                value: {},
                //表单生成规则
                rule: [
                ],
                //组件参数配置
                option: {
                    submitBtn:{
                        show:false
                    }
                },
                clresult:'',
                yonghu:[],
                clremake:'',
                props: {
                    multiple: true,
                    children:'userList',
                    label:'name',
                    value:'id',

                },
                recvUser:[],
                taskId:'',
                processInstanceId:'',
                liuchengData:[],
                statusoptions:[{
                    value: 0,
                    label: '执行中'
                }, {
                    value: 1,
                    label: '已完成'
                }]
            }
        },
        methods:{
            async getjoinlcList(){
                const {data:res}= await this.$http3.post('/v1.0/activiti/process/queryHistoryProcessByAssigneePages',this.queryInfo)
                if(res.status!='3000'){
                    this.tableData=[]
                    return this.$message.error('查询参与列表失败！'+res.message)
                }
                this.total=res.data.totalCount
                this.tableData=res.data.result;
            },
            async chakan(row){
                console.log(row)
                const {data:result}=await this.$http3.get('/v1.0/activiti/process/queryFlowImgByProcInstId?procInstId='+row.processInstanceId);
                var index=result.lastIndexOf("<svg");
                const {data:result2}=await this.$http3.get('/v1.0/activiti/process/queryHistoryProcessByInstanceIdAndBusinessId?processInstanceId='+row.processInstanceId+'&businessKey='+row.businessKey);
                this.liuchengData=result2.data;
                this.dialogVisible=true
                document.getElementById('sbgimg').innerHTML=(result.substring(index,result.length-1))
            },
            async chakanForm(row){
                this.rule=JSON.parse(row.formJson).list;
                const {data:result1}=await this.$http3.get('/v1.0/activiti/form/findDynamicTableByProcessId?processInstanceId='+row.processInstanceId+'&tableName='+row.tableName);
                this.value=result1.data;
                this.formDialogVisible=true
            },
            changeStatus(row){
              console.log(this.queryInfo)
                this.getjoinlcList()
            },
            handleSizeChange(newSize){
                this.queryInfo.pageSize=newSize;
                this.getjoinlcList()
            },
            handleCurrentChange(newPage){
                this.queryInfo.pageNumber=newPage;
                this.getjoinlcList()
            },
        },
        created(){
            this.getjoinlcList();
        },
        components:{
            workLeft
        }
    }
</script>

<style scoped lang="less">

    .input-with-select{
        width: 280px;
        .el-button{
            padding: 10px 15px;
        }
    }
    .input-with-select1{
        margin-left: 15px;
        width: 220px !important;
        .el-button{
            padding: 10px 15px;
        }
    }
    #sbgimg{
        width: 100%;max-width: 950px;overflow:auto;max-height: 400px;
    }
</style>
